import * as Yup from "yup";
import { FormElementType } from "../../../../../../../../components/shared/data-entry/FormBuilder/types";

import errorMessages from "../../../../../../../../constants/errors";
import { QuestionInfo } from "../../../../../../../../models/Survey/SurveyMasterDetail";
import { SURVEY_PREFIX_OPTIONS } from "../../../../../../../../constants/enums";

export const createInitialValues = () => {
  return {
    srveQitmCont: "",
    headClsCd: null,
    replyAvailCnt: "",
  };
};

export const createInitialValuesFromQuestion = (question: QuestionInfo) => {
  return {
    headClsCd: question.headClsCd,
    srveQitmCont: question.srveQitmCont,
    replyAvailCnt: question.replyAvailCnt || "",
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    srveQitmCont: Yup.string().required(errorMessages.REQUIRED_FIELD),
    headClsCd: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createFormInfo = () => {
  return [
    // 말머리 구분
    {
      key: "headClsCd",
      type: FormElementType.Select,
      label: "말머리구분",
      options: SURVEY_PREFIX_OPTIONS,
      required: true,
      placeholder: "말머리구분을 선택해주세요",
    },
    {
      // 설문내용
      key: "srveQitmCont",
      type: FormElementType.TextArea,
      label: "설문내용",
      required: true,
      placeholder: "설문내용을 입력해주세요",
    },
    // 답변가능수
    {
      key: "replyAvailCnt",
      type: FormElementType.InputNumber,
      label: "답변가능수",
      placeholder: "답변가능수를 입력해주세요 (미입력 또는 0은 제한없음)",
    },
  ];
};
