import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import {
  createAddCarManagerParam,
  createFormInfo,
  createInitialValues,
  createValidationSchema,
} from "./utils/form-utils";
import {
  renderLoading,
  alertError,
  alertSuccess,
} from "../../../../../utils/render-utils";
import {
  addCarManager,
  fetchCarManagerCossUsers,
  fetchCarManagerSiteOptions,
} from "../../../../../apis/car-managers";
import { ModalProps } from "../../../../types";
import { getErrorMessage } from "../../../../../utils/common-utils";

interface Props extends ModalProps {}

const CarManagerAddModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(createInitialValues());
  const [forms, setForms] = useState<any>([]);
  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setInitialValues(createInitialValues());
    await requestCossUsers();
  };

  /**
   * Private Functions
   */

  const requestCossUsers = async () => {
    setDataFetching(true);
    try {
      const { items } = await fetchCarManagerCossUsers();
      const { list } = await fetchCarManagerSiteOptions();

      const userOptions = items.map((user) => ({
        label: `${user.userNm}(${user.deptNm})`,
        value: user.userId,
      }));

      const siteOptions = list.map(({ siteNm, siteCd }) => ({
        label: siteNm,
        value: siteCd,
      }));

      setForms(createFormInfo(siteOptions, userOptions));
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  const requestAddCarManager = async (values: FormikValues) => {
    setConfirmLoading(true);

    try {
      await addCarManager(createAddCarManagerParam(values));
      alertSuccess("카매니저가 등록되었습니다");
      if (onDataChange) onDataChange();
      if (onCancel) onCancel();
    } catch (e) {
      if (e.getMessage) alertError(e.getMessage());
      else {
        alertError(e.toString());
      }
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    await requestAddCarManager(values);
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="카매니저 등록"
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={forms}
          validationSchema={createValidationSchema()}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

CarManagerAddModal.defaultProps = {};
export default CarManagerAddModal;
