import CossMaster from "../../../../../../models/CossCarInfo/CossMaster";
import { formatCurrency } from "../../../../../../utils/common-utils";
import NiceCarInfo from "../../../../../../models/CossCarInfo/NiceCarInfo";
import NiceInfoColor, {
  MODE_COLOR_CLS_EX,
  MODE_COLOR_CLS_I,
} from "../../../../../../models/CossCarInfo/NiceInfoColor";
import CossColor, {
  COSS_COLOR_CLS_EX,
  COSS_COLOR_CLS_IN,
} from "../../../../../../models/CossCarInfo/CossColor";
import React from "react";

const NO_OPTION = {
  label: "선택없음",
  value: "",
};

/**
 * COSS 트림명 + 가격 라벨
 * @param cossMaster
 */
export const getCossTrimLabel = (cossMaster?: CossMaster) => {
  if (!cossMaster) return "";
  return `${cossMaster.modlNm} - ${formatCurrency(cossMaster.carAmt)}`;
};

/**
 * NICE 색상명 + 가격 라벨
 */
export const getNiceColorOptionLabel = (colorInfo: NiceInfoColor) => {
  return `[${colorInfo.modeColorClsNm}]${
    colorInfo.modeColorNm
  } - ${formatCurrency(colorInfo.modeColorPrice)}`;
};

/**
 * 차량이미지
 */
export const getNiceCarImage = (
  trims: NiceCarInfo[],
  modeGrdIdParam: string
) => {
  const filtered = trims.filter(({ modeGrdId }) => {
    return modeGrdIdParam === modeGrdId;
  });

  if (filtered.length > 0) {
    return filtered[0].repImageUrl;
  }

  return "";
};

/**
 * COSS 색상명 + 가격 라벨
 */
export const getCossColorOptionLabel = (
  colorInfo: CossColor,
  niceInfoColors: NiceInfoColor[],
  niceColorId: string
) => {
  const filtered = niceInfoColors.filter(({ modeColorId }) => {
    return modeColorId === niceColorId;
  });
  let imgUrl = "";
  if (filtered.length > 0) {
    imgUrl = filtered[0].modeColorImgUrl;
  }
  const optionName = `[${colorInfo.cossColorClsNm}]${
    colorInfo.cossColorNm
  } - ${formatCurrency(colorInfo.cossColorAmt)}`;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {optionName}{" "}
      {imgUrl && (
        <img
          src={imgUrl}
          alt="차량색상컬러칩"
          style={{
            marginLeft: "8px",
          }}
        />
      )}
    </div>
  );
};

/**
 * COSS 색상옵션 -> 콤보박스 옵션 변환
 *
 */
export const getCossColorOptions = (
  cossColors: CossColor[],
  isExColor: boolean
) => {
  const colorOptions = cossColors
    .filter(({ cossColorCls }) => {
      if (isExColor) return cossColorCls === COSS_COLOR_CLS_EX;
      return cossColorCls === COSS_COLOR_CLS_IN;
    })
    .map((colorInfo) => {
      const { cossColorNm, cossColorId, cossColorAmt } = colorInfo;
      return {
        label: `${cossColorNm} - ${formatCurrency(cossColorAmt || 0)}`,
        value: cossColorId,
      };
    });

  if (colorOptions) {
    return [NO_OPTION, ...colorOptions];
  }
  return [NO_OPTION];
};

/**
 * NICE 색상옵션 -> 콤보박스 옵션 변환
 *
 */
export const getNiceColorOptions = (
  niceColors: NiceInfoColor[],
  isExColor: boolean
) => {
  const colorOptions = niceColors
    .filter(({ modeColorCls }) => {
      if (isExColor) return modeColorCls === MODE_COLOR_CLS_EX;
      return modeColorCls === MODE_COLOR_CLS_I;
    })
    .map((colorInfo) => {
      const {
        modeColorNm,
        modeColorId,
        modeColorPrice,
        modeColorAddSeq,
      } = colorInfo;
      return {
        label: `${modeColorNm} ${
          modeColorAddSeq ? `(누락색상추가-${modeColorAddSeq})` : ""
        }- ${formatCurrency(modeColorPrice || 0)}`,
        value: modeColorId,
      };
    });

  if (colorOptions) {
    return [NO_OPTION, ...colorOptions];
  }
  return [NO_OPTION];
};

/**
 * Nice 차량정보 -> 콤보박스 옵션 변환
 * @param niceCarInfos
 */
export const getNiceTrimOptions = (niceCarInfos: NiceCarInfo[]) => {
  return niceCarInfos.map((niceCarInfo) => {
    const carName = `${niceCarInfo.yearType} ${niceCarInfo.repCarClassNm} ${
      niceCarInfo.carGradeNm
    } - ${formatCurrency(niceCarInfo.carGradePrice)}`;
    return {
      label: carName,
      value: niceCarInfo.modeGrdId,
    };
  });
};
