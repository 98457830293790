import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import {
  ExhibitionEditParams,
  ExhibitionMaster,
} from "../../../../../../../apis/ex/types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";

import {
  createForms,
  createInitialValues,
  createInitialValuesFromExhibitionMaster,
  createValidationSchema,
} from "./form-utils";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import { editExhibition } from "../../../../../../../apis/ex";
import { getParamFromDateRange } from "../../../../../../../utils/form-utils";

/**
 * 기획전 마스터 수정 모달
 */

interface Props extends ModalProps {
  exhibitionMaster?: ExhibitionMaster;
}

const ExhibitionMasterInfoEditModal: React.FC<Props> = (props: Props) => {
  let formik: FormikProps<FormikValues>;
  const forms = createForms();
  const validationSchema = createValidationSchema();

  const { visible, onDataChange, onCancel, exhibitionMaster } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  const onModalOpen = () => {
    if (exhibitionMaster) {
      setInitialValues(
        createInitialValuesFromExhibitionMaster(exhibitionMaster)
      );
    }
  };

  const requestEditExhibitionMaster = async (params: ExhibitionEditParams) => {
    setConfirmLoading(true);
    try {
      await editExhibition(params);
      alertSuccess("기획전정보가 변경되었습니다");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleSubmit = async (values: FormikValues) => {
    const { exhTitle, displayTerm, exhMstViewSeq, exhUseYn, exhDesc } = values;
    let startDateParam = "";
    let endDateParam = "";
    getParamFromDateRange(displayTerm);
    if (displayTerm && displayTerm.length > 1) {
      const [start, end] = displayTerm;
      startDateParam = start.format("YYYYMMDDHHmmss");
      endDateParam = end.format("YYYYMMDDHHmmss");
    }

    const params: ExhibitionEditParams = {
      exhMstSeq: exhibitionMaster?.exhMstSeq,
      exhTitle,
      exhDesc,
      exhMstViewSeq,
      useYn: exhUseYn ? "Y" : "N",
      exhStDt: startDateParam,
      exhEndDt: endDateParam,
    };
    await requestEditExhibitionMaster(params);
  };

  return (
    <>
      <Modal
        size="small"
        visible={visible}
        onCancel={onCancel}
        title="기획전 정보수정 (신차장기)"
        onOpen={onModalOpen}
        confirmLoading={confirmLoading}
        onOk={() => {
          formik?.handleSubmit();
        }}
      >
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          validationSchema={validationSchema}
          initialValues={initialValues}
          forms={forms}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default ExhibitionMasterInfoEditModal;
