import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import SkuReport from "../../models/SkuReport";
import { SkuReportThumbnailResponse } from "../../pages/reports/SkuReport/@types";
/**
 * sku-report 조회
 */
export const fetchSkuReport = async (
  selDt: string,
  srvcId: string,
  ncarClsFlag: string,
  carMakerNm: string,
  carClssNm: string,
  carModlNm: string
): Promise<ListItemResponse<SkuReport>> => {
  const url = `${ROOT_URL}/admin/sku-report`;
  const res = await request(HttpMethod.Get, url, {
    selDt,
    srvcId,
    ncarClsFlag,
    carMakerNm,
    carClssNm,
    carModlNm,
  });

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(SkuReport, list as Array<JSON>),
    count: Number(totalCnt),
  };
};

/**
 * sku-report thumbnail 조회
 */
export const fetchSkuReportThumbnail = async (
  selDt: string
): Promise<SkuReportThumbnailResponse> => {
  const url = `${ROOT_URL}/admin/sku-report-thumbnail`;
  const res = await request(HttpMethod.Get, url, {
    selDt,
  });

  const { data } = res;
  const { response } = data;

  return response;
};
