import React from "react";
import { DatePicker as AntDatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import moment, { Moment } from "moment";

interface DatePickerProps extends PickerDateProps<Moment> {
  disablePast?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
  const { style, disablePast, ...rest } = props;
  /**
   * Private Functions
   */

  function disabledDate(current: Moment) {
    return current && current < moment().subtract(1, "day").endOf("day");
  }

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <AntDatePicker
      locale={locale}
      disabledDate={disablePast ? disabledDate : undefined}
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

DatePicker.defaultProps = {};
export default DatePicker;
