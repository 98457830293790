import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { ModalProps } from "../../../../../../types";
import RentContractDetail from "../../../../../../../models/RentContractDetail";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";

import {
  FormElementType,
  FormOption,
} from "../../../../../../../components/shared/data-entry/FormBuilder/types";

import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import {
  addContractCSUser,
  editContractCSUser,
  fetchCSUsers,
} from "../../../../../../../apis/rent-contracts";
import errorMessages from "../../../../../../../constants/errors";

interface Props extends ModalProps {
  rentContractDetail?: RentContractDetail;
  originCnslUserId?: string;
}

const CSUserSetupModal: React.FC<Props> = (props: Props) => {
  const {
    visible,
    onCancel,
    onDataChange,
    rentContractDetail,
    originCnslUserId,
  } = props;
  const formInitialValues = {
    cnslUserId: null,
  };
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const createForms = (options: FormOption[]) => {
    return [
      {
        key: "cnslUserId",
        type: FormElementType.Select,
        label: "상담원",
        required: true,
        options,
        placeholder: "계약 상담원을 선택해주세요",
      },
    ];
  };
  const [forms, setForms] = useState<any>(createForms([]));
  const validationSchema = Yup.object().shape({
    cnslUserId: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    await requestFetchCsUserOptions();
    if (originCnslUserId) {
      setInitialValues({
        cnslUserId: originCnslUserId,
      });
    } else {
      setInitialValues(formInitialValues);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchCsUserOptions = async () => {
    setDataFetching(true);
    try {
      const list = await fetchCSUsers();
      const options = list.map(({ cnslUserId, cnslUserNm }) => {
        return {
          label: cnslUserNm,
          value: cnslUserId,
        };
      });
      setForms(createForms(options));
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  const requestAddCSUser = async (
    cntrId: string,
    cntrSeq: number,
    cnslUserId: string
  ) => {
    setConfirmLoading(true);
    try {
      await addContractCSUser(cntrId, cntrSeq, cnslUserId);
      alertSuccess("계약 상담원이 배정되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditCSUser = async (
    cntrId: string,
    cntrSeq: number,
    cnslUserId: string
  ) => {
    setConfirmLoading(true);
    try {
      await editContractCSUser(cntrId, cntrSeq, cnslUserId);
      alertSuccess("계약 상담원이 변경되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const getModalTitle = () => {
    if (originCnslUserId) {
      return "계약 상담원 변경";
    }
    return "계약 상담원 배정";
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (rentContractDetail) {
      const { cntrId, cntrSeq } = rentContractDetail;
      const { cnslUserId } = values;
      // 수정
      if (originCnslUserId) {
        await requestEditCSUser(cntrId, cntrSeq, cnslUserId);
      }
      // 등록
      else {
        await requestAddCSUser(cntrId, cntrSeq, cnslUserId);
      }
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      title={getModalTitle()}
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          validationSchema={validationSchema}
          initialValues={initialValues}
          forms={forms}
          formRef={(ref) => {
            formik = ref;
          }}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

CSUserSetupModal.defaultProps = {};
export default CSUserSetupModal;
