import React, { useState } from "react";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import {FormElementType, FormInfo} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {alertSuccess, renderLoading} from "../../../../../../../utils/render-utils";
import {FormikProps, FormikValues} from "formik";
import {updateUcCooperCompanyCond} from "../../../../../../../apis/auc-master";
import AucCooperDetail from "../../../../../../../models/AucCooperDetail";
import {  
  CooperDetailRequest,
  commCdList
} from "../../../../../../../apis/auc-master/types";

interface Props extends ModalProps {
  aucCooperDetail?: AucCooperDetail;
}

const CrdGrdListModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, aucCooperDetail, onDataChange } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [defaultCompCd, setDefaultCompCd] = useState<string>();
  const [default1, setDefault1] = useState<string>();
  const [default2, setDefault2] = useState<string>();
  const [default3, setDefault3] = useState<string>();
  const [default4, setDefault4] = useState<string>();
  const [default5, setDefault5] = useState<string>();
  const [default6, setDefault6] = useState<string>();
  const [default7, setDefault7] = useState<string>();


  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);

    if (aucCooperDetail) {
      setDefaultCompCd(aucCooperDetail.compCd);
      setDefault1(aucCooperDetail.crdGrdList[0].condVal);
      setDefault2(aucCooperDetail.crdGrdList[1].condVal);
      setDefault3(aucCooperDetail.crdGrdList[2].condVal);
      setDefault4(aucCooperDetail.crdGrdList[3].condVal);
      setDefault5(aucCooperDetail.crdGrdList[4].condVal);
      setDefault6(aucCooperDetail.crdGrdList[5].condVal);
      setDefault7(aucCooperDetail.crdGrdList[6].condVal);
    } else {
      setDefaultCompCd("");
      setDefault1("");
      setDefault2("");
      setDefault3("");
      setDefault4("");
      setDefault5("");
      setDefault6("");
      setDefault7("");
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const forms: Array<FormInfo> = [
    {      key: "A59001",   type: FormElementType.InputNumber,   style: { width: "100px", },  required: false,   label: "1등급",        }, 
    {      key: "A59002",   type: FormElementType.InputNumber,   style: { width: "100px", },  required: false,   label: "2등급",        },
    {      key: "A59003",   type: FormElementType.InputNumber,   style: { width: "100px", },  required: false,   label: "3등급",        },
    {      key: "A59004",   type: FormElementType.InputNumber,   style: { width: "100px", },  required: false,   label: "4등급",        },
    {      key: "A59005",   type: FormElementType.InputNumber,   style: { width: "100px", },  required: false,   label: "5등급",        },
    {      key: "A59006",   type: FormElementType.InputNumber,   style: { width: "100px", },  required: false,   label: "6등급",        },
    {      key: "A59007",   type: FormElementType.InputNumber,   style: { width: "100px", },  required: false,   label: "7등급",        },
  ]

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
      setConfirmLoading(true);
      const commCdList: commCdList[] = [];
      const commCd1 = { condCd: "A59001" as string, condVal: values.A59001 as string  }; commCdList.push(commCd1);
      const commCd2 = { condCd: "A59002" as string, condVal: values.A59002 as string  }; commCdList.push(commCd2);
      const commCd3 = { condCd: "A59003" as string, condVal: values.A59003 as string  }; commCdList.push(commCd3);
      const commCd4 = { condCd: "A59004" as string, condVal: values.A59004 as string  }; commCdList.push(commCd4);
      const commCd5 = { condCd: "A59005" as string, condVal: values.A59005 as string  }; commCdList.push(commCd5);
      const commCd6 = { condCd: "A59006" as string, condVal: values.A59006 as string  }; commCdList.push(commCd6);
      const commCd7 = { condCd: "A59007" as string, condVal: values.A59007 as string  }; commCdList.push(commCd7);

      const params: CooperDetailRequest = {
        condClsCd: "A17009",
        condCdList: commCdList,
      };

      if (aucCooperDetail) {
        try {
          await updateUcCooperCompanyCond(defaultCompCd as string, params);
          alertSuccess("변경되었습니다."); 
          if (onDataChange) onDataChange();
          if (onCancel) onCancel();
        } catch (e) {          
          console.log(e);
        } finally {
          setConfirmLoading(false);
        }
      } else {
        setConfirmLoading(false);
        if (onCancel) onCancel();
      }
  }; 

  /**
   * Render Helpers
   */


  return (
    <Modal
      size="small"
      title=" 신용등급 변경"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? (
          renderLoading()
      ) : (
          <FormBuilder
              formRef={(ref) => {
                formik = ref;
              }}
              initialValues={
                {
                  A59001: default1,
                  A59002: default2,
                  A59003: default3,
                  A59004: default4,
                  A59005: default5,
                  A59006: default6,
                  A59007: default7,
                }
              }
              forms={forms}
              onSubmit={handleSubmit}
          />
      )}
    </Modal>
  );
};

CrdGrdListModal.defaultProps = {
  visible: true,
};
export default CrdGrdListModal;
