import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
`;

export const Label = styled.div`
  width: 108px;
`;

export const Content = styled.div`
  flex: 1;
  color: #626dff;
`;
