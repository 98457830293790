import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_HEADER_DEFAULT_OPTION_LIST_MANAGER: "메인상품 가격기준 관리",
    TABLE_HEADER_DEFAULT_OPTION_LIST: "가격기준 리스트",

    TITLE_ACTION_ADD: "신규 등록",

    MODAL_TITLE_ADD: "가격 기준정보 등록",
    MODAL_TITLE_DETAIL: "가격 기준정보 수정",
  },
});

