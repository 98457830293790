import React, { useCallback, useState } from "react";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import {
  EditExhibitionEtcVideo,
  ExhibitionEtcVideo,
} from "../../../../../../../apis/ex/types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";

import {
  createForms,
  createInitialValues,
  createValidationSchema,
} from "./form-utils";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import {
  editExhibition,
  editExhibitionEtcVideo,
} from "../../../../../../../apis/ex";
import { NC_EXHIBITION_SHORTS } from "../../../../../../../constants/enums";

/**
 * 쇼츠 기획전 기타 동영상 추가 모달
 */

interface Props extends ModalProps {
  etcVideoInfo?: ExhibitionEtcVideo | null;
  exhMstSeq: string;
}

const EtcVideoAddModal: React.FC<Props> = (props: Props) => {
  let formik: FormikProps<FormikValues>;
  const forms = createForms();
  const validationSchema = createValidationSchema();

  const { visible, onDataChange, onCancel, etcVideoInfo, exhMstSeq } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  const onModalOpen = () => {
    if (etcVideoInfo) {
      setInitialValues({
        ...etcVideoInfo,
        pcThumbnail: [{ imageUrl: etcVideoInfo.pcThumbnail }],
        tabThumbnail: [{ imageUrl: etcVideoInfo.tabThumbnail }],
        mobThumbnail: [{ imageUrl: etcVideoInfo.mobThumbnail }],
      });
    } else {
      setInitialValues(createInitialValues());
    }
  };

  const requestEditExhibitionMaster = async (
    params: EditExhibitionEtcVideo
  ) => {
    setConfirmLoading(true);
    try {
      if (etcVideoInfo) {
        await editExhibitionEtcVideo(params, etcVideoInfo.videoSeq);
        alertSuccess("기획전 동영상이 변경되었습니다");
      } else {
        await editExhibitionEtcVideo(params);
        alertSuccess("기획전 동영상이 등록되었습니다");
      }
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleSubmit = async (values: FormikValues) => {
    await requestEditExhibitionMaster({
      ...values,
      exhMstSeq,
      pcThumbnail: values.pcThumbnail[0].imageUrl,
      tabThumbnail: values.tabThumbnail[0].imageUrl,
      mobThumbnail: values.mobThumbnail[0].imageUrl,
      useYn: values.useYn ? "Y" : "N",
      exhGbnCd: NC_EXHIBITION_SHORTS,
    } as EditExhibitionEtcVideo);
  };

  const renderModalTitle = useCallback(() => {
    return etcVideoInfo ? "기타 동영상 수정" : "기타 동영상 추가";
  }, [etcVideoInfo]);

  return (
    <>
      <Modal
        size="small"
        visible={visible}
        onCancel={onCancel}
        title={renderModalTitle()}
        description="섬네일 이미지 규격은 PC 1184 X 522 / Tablet 688 X 586 / Mobile 343 X 522 (가로형) 입니다."
        onOpen={onModalOpen}
        confirmLoading={confirmLoading}
        onOk={() => {
          formik?.handleSubmit();
        }}
      >
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          validationSchema={validationSchema}
          initialValues={initialValues}
          forms={forms}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default EtcVideoAddModal;
