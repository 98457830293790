import React from "react";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { FormElementType } from "../../../../../../components/shared/data-entry/FormBuilder/types";
import {
  getInitialDateRangeFromRaw,
  getParamFromDateRange,
} from "../../../../../../utils/form-utils";
import DiscountMaster from "../../../../../../models/DiscountMaster";
import commonStrings from "../../../../../../constants/strings";
import errorMessages from "../../../../../../constants/errors";
import {
  ENUM_DC_KIND_OPTIONS,
  ENUM_DC_TYPE_OPTIONS,
  ENUM_DC_TYPE_PERCENT,
} from "../../../../../../constants/enums";
import FormItem from "../../../../../../components/shared/data-entry/antd/FormItem";
import Radio from "../../../../../../components/shared/data-entry/antd/Radio";
import { defaultFormItemLayout } from "../../../../../../components/shared/data-entry/FormBuilder";
import InputNumber from "../../../../../../components/shared/data-entry/antd/InputNumber";

export const createInitialValues = () => {
  return {
    // 할인 항목
    dcClsCd: null,
    // 적용 기간
    apyTerm: null,

    // 할인 타입 (%, 금액)
    dcTypeCd: ENUM_DC_TYPE_PERCENT,
    dcVal: "",
    useYn: true,
  };
};

export const createInitialValuesFromDcMaster = (
  discountMaster: DiscountMaster
) => {
  const { apyStDt, apyEndDt, dcClsCd, dcTypeCd, dcVal, useYn } = discountMaster;

  let initialApyTerm = null;
  if (apyStDt && apyEndDt) {
    initialApyTerm = getInitialDateRangeFromRaw(apyStDt, apyEndDt);
  }

  return {
    // 할인 항목
    dcClsCd,
    // 기간
    apyTerm: initialApyTerm,
    dcTypeCd,
    dcVal,
    useYn: useYn === "Y",
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    dcClsCd: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    apyTerm: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(2, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    dcVal: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createDiscountMasterParam = (values: FormikValues) => {
  const { apyTerm, dcClsCd, dcTypeCd, dcVal, useYn } = values;

  let apyStDt;
  let apyEndDt;
  if (apyTerm && apyTerm.length > 0) {
    const { startDateParam, endDateParam } = getParamFromDateRange(apyTerm);
    apyStDt = startDateParam;
    apyEndDt = endDateParam;
  }
  return {
    dcClsCd,
    apyStDt: apyStDt as string,
    apyEndDt: apyEndDt as string,
    dcTypeCd,
    dcVal,
    useYn: useYn ? "Y" : "N",
  };
};

export const createFormInfo = (editMode = false) => {
  return [
    // 할인 항목
    {
      key: "dcClsCd",
      required: true,
      disabled: editMode,
      type: FormElementType.Select,
      label: commonStrings.DC_KIND,
      options: [],
      placeholder: commonStrings.HINT_DC_KIND,
    },
    // 적용 기간
    {
      key: "apyTerm",
      required: true,
      type: FormElementType.DateRangePicker,
      label: commonStrings.APY_TERM,
    },
    // 할인 유형
    {
      key: "dcTypeCd",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { setFieldValue, values } = renderProps;
        const { dcTypeCd } = values;
        return (
          <FormItem
            label={commonStrings.DC_TYPE}
            required
            {...defaultFormItemLayout}
            style={{
              marginBottom: "16px",
            }}
          >
            <Radio
              options={ENUM_DC_TYPE_OPTIONS}
              value={dcTypeCd}
              onChange={(value) => {
                setFieldValue("dcTypeCd", value);
                setFieldValue("dcVal", "");
              }}
            />
          </FormItem>
        );
      },
    },
    // 할인율(액)
    {
      key: "dcVal",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { setFieldValue, values, errors, touched } = renderProps;
        const { dcTypeCd, dcVal } = values;
        let err;
        if (errors.dcVal && touched.dcVal) {
          err = errors.dcVal;
        }
        return (
          <FormItem
            label={
              dcTypeCd === ENUM_DC_TYPE_PERCENT
                ? commonStrings.DC_VALUE_PERCENT
                : commonStrings.DC_VALUE_AMT
            }
            required
            help={err}
            validateStatus={err && "error"}
            {...defaultFormItemLayout}
            style={{
              marginBottom: "16px",
            }}
          >
            <InputNumber
              min={0}
              max={dcTypeCd === ENUM_DC_TYPE_PERCENT ? 100 : 10000000}
              value={dcVal}
              onChange={(value) => {
                setFieldValue("dcVal", value);
              }}
              style={{
                width: "100%",
              }}
              placeholder={
                dcTypeCd === ENUM_DC_TYPE_PERCENT
                  ? commonStrings.HINT_DC_PERCENT
                  : commonStrings.HINT_DC_VALUE
              }
            />
          </FormItem>
        );
      },
    },
    // 사용 유무
    {
      key: "useYn",
      required: true,
      type: FormElementType.Checkbox,
      label: commonStrings.USE_YN,
    },
  ];
};
