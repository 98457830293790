import React, { useState } from "react";

// types
import { ModalProps } from "../../../../../../types";

// utils
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "@utils/render-utils";
import { getErrorMessage } from "@utils/common-utils";
import { createTagSetupCols } from "./utils/table-utils";
import {
  MOBILE_TAG_RENDERER,
  PC_TAG_RENDERER,
} from "../../../../../CatalogTagManager/utils/table-utils";

// components
import Modal from "@components/shared/feedback/antd/Modal";
import ModalTableWrapper from "@components/shared/layout/ModalTableWrapper";
import ClientRowTable from "@components/shared/data-display/Tables/components/ClientRowTable";
import {
  mobileTagRenderer,
  pcTagCellRenderer,
} from "@components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
// import TagPreviewModal from "../TagPreviewModal";

// models
import TagMaster from "@models/TagMaster";
import { McProdNmTagDetail } from "@models/McProdNmTag";

// apis
import { fetchTagMasters } from "@apis/uc-tag-master";
import { linkTagsToNm, unlinkTags } from "@apis/uc-catalogs";

// constants
import { TAG_STYLE_CD_11, MC_TAG_LIST } from "@constants/enums";

/**
 * 태그 등록/등록해제 모달
 */
interface Props extends ModalProps {
  prodDetail?: McProdNmTagDetail;
}

/**
 * 상품명 태그관리
 * 
 * @param props 
 * @returns 
 */
const CatalogTagSetupModal: React.FC<Props> = (props: Props) => {
  const cols = createTagSetupCols();

  const { visible, onCancel, onDataChange, prodDetail } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoadng] = useState(false);
  const [tagMasters, setTagMasters] = useState<TagMaster[]>();
  const [checkedRow, setCheckedRows] = useState<TagMaster[]>([]);
  // TODO: 미리보기 일단 구현 skip - 상품 이미지, 제조사 이미지, 최저가 등 정보 필요
  // const [tagPreviewModalVisible, setTagPreviewModalVisible] = useState(false);

  /**
   * modal open event
   */
  const onModalOpen = async () => {
    setDataFetching(true);
    setCheckedRows([]);
    await requestFetchTagMasters();
    setDataFetching(false);
  };

  /**
   * Tag List 조회 및 설정
   */
  const requestFetchTagMasters = async () => {
    try {
      const { items } = await fetchTagMasters();
      setTagMasters(items.filter(v => [TAG_STYLE_CD_11, ...MC_TAG_LIST].includes(v.pcTagStyl))); // 신규 월렌트 Tag만 적용
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * 상품명으로 태그 제어
   */
  const requestLinkCatalogs = async () => {
    setConfirmLoadng(true);
    try {
      // 기존에 연결된 태그 전체 unlink
      if (prodDetail) {
        const { mainTagList: tagList, prodNm } = prodDetail;

        if (tagList) {
          const tagSeqs = tagList.map(({ tagSeq }) => tagSeq);
          await unlinkTags(tagSeqs, true); // 월렌트 상품명 태그 unlink
        }

        // 체크된 태그 전체 link
        const tagIds = checkedRow.map(({ tagId }) => tagId);
        await linkTagsToNm(prodNm, tagIds);
        alertSuccess("상품 태그정보가 변경되었습니다.");
      }

      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoadng(false);
    }
  };

  const handleModalOk = async () => {
    await requestLinkCatalogs();
  };

  const handleCheckRowChanged = (rows: Array<TagMaster>) => {
    setCheckedRows(rows);
  };

  /**
   * 상품명 태그 리스트
   * 
   * @returns 
   */
  const renderTagList = () => {

    return (
      <ModalTableWrapper
        headerTitle="태그 리스트"
        style={{
          position: "relative",
        }}
      >
        <ClientRowTable
          apiRef={(api) => {
            api.forEachNode((node) => {
              if (prodDetail && prodDetail.mainTagList) {
                const data = node.data as TagMaster;
                // eslint-disable-next-line react/prop-types
                const filtered = (prodDetail.mainTagList).filter(({ tagId }) => {
                  return tagId === data.tagId;
                });
                if (filtered.length > 0) {
                  node.setSelected(true);
                }
              }
            });
          }}
          onCheckRowChanged={handleCheckRowChanged}
          cols={cols}
          rowData={tagMasters}
          frameworkComponents={{
            [PC_TAG_RENDERER]: pcTagCellRenderer,
            [MOBILE_TAG_RENDERER]: mobileTagRenderer,
          }}
        />
        {/* <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Button
            onClick={() => {
              setTagPreviewModalVisible(true);
            }}
          >
            미리보기
          </Button>
        </div> */}
      </ModalTableWrapper>
    );
  };

  return (
    <>
      <Modal
        size="large"
        onOpen={onModalOpen}
        visible={visible}
        onCancel={onCancel}
        title="태그 설정"
        confirmLoading={confirmLoading}
        onOk={handleModalOk}
      >
        {dataFetching ? renderLoading() : renderTagList()}
      </Modal>
      {/* <TagPreviewModal
        visible={tagPreviewModalVisible}
        onCancel={() => {
          setTagPreviewModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        tagMasters={checkedRow}
      /> */}
    </>
  );
};

export default CatalogTagSetupModal;
