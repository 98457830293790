import React, { useState } from "react";
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import moment from "moment";
import { createActionCellRenderers } from "../../../utils/table-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import {
  getPageIndex,
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import { createSMSTemplateCols } from "./utils/table-utils";
import { fetchSMSSendHistories } from "../../../apis/sms-template";
import SMSSendFormModal from "./pages/SMSSendFormModal";
import SMSSendHistory from "../../../models/SMSSendHistory";
import { get1MonthDateRange } from "../../../utils/date-utils";
import SMSSendHistoryDetailModal from "./pages/SMSSendHistoryDetailModal";

const CarManagerSMSSender: React.FC = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [sendModalVisible, setSendModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<
    SMSSendHistory | undefined
  >();
  const cols = createSMSTemplateCols();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [logInfo, setLogInfo] = useState<string>("");

  const filterInitialValues = {
    searchText: "",
    sendDt: get1MonthDateRange(),
  };

  const filterForms = [
    {
      key: "sendDt",
      type: FilterElementType.DateRangePicker,
      label: "전송기간",
    },
    {
      key: "searchText",
      type: FilterElementType.Input,
      label: "템플릿명",
      placeholder: "템플릿명을 입력해주세요",
    },
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const { searchText, sendDt } = appliedFilterValues;

    try {
      const stDtParam = moment(sendDt[0]).format("YYYYMMDD");
      const endDtParam = moment(sendDt[1]).format("YYYYMMDD");
      const { items, count } = await fetchSMSSendHistories(
        getPageIndex(startRow, paginationPageSize),
        paginationPageSize,
        stDtParam,
        endDtParam,
        searchText
      );
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
    } catch (e) {
      if (e.status !== 404) {
        setTotalCount(0);
        success({
          rowData: [],
          rowCount: 0,
        });
      }
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const sendHistory = data as SMSSendHistory;
    setSelectedData(sendHistory);
    setDetailModalVisible(true);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <PaginationTableLayout
        rightActions={[
          {
            title: "문자 전송",
            onClick: () => {
              setSelectedData(undefined);
              setSendModalVisible(true);
            },
          },
        ]}
        apiRef={(ref) => {
          setGridApi(ref);
        }}
        headerTitle="템플릿 문자 전송"
        tableHeaderTitle="전송내역 리스트"
        onDataLoad={onDataLoad}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        totalCount={totalCount}
        hideFilterAction
        frameworkComponents={{
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
        }}
        methodName="/admin/carman/sms-send-histories"
        logInfo={logInfo}
      />
      <SMSSendFormModal
        visible={sendModalVisible}
        onCancel={() => {
          setSendModalVisible(false);
        }}
        onDataChange={() => {
          refreshServerSideTable(gridApi);
        }}
      />
      <SMSSendHistoryDetailModal
        visible={detailModalVisible}
        onCancel={() => {
          setDetailModalVisible(false);
        }}
        sendHistory={selectedData}
        onDataChange={() => {
          refreshServerSideTable(gridApi);
        }}
      />
    </>
  );
};

CarManagerSMSSender.defaultProps = {};
export default CarManagerSMSSender;
