import React, { useState } from "react";

import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import UsedCarCatalogDetail from "../../../../../../../models/UsedCarCatalogDetail";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import { renderLoading } from "../../../../../../../utils/render-utils";
import { updateUsdCarGradeNmUpd } from "../../../../../../../apis/uc-catalogs";

interface Props extends ModalProps {
  catalogDetail?: UsedCarCatalogDetail;
}

const UsedCarGradeNmModifyModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, catalogDetail, onDataChange } = props;

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [defaultCarGradeNm, setDefaultCarGradeNm] = useState<string>();

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);
    if (catalogDetail) {
      setDefaultCarGradeNm(catalogDetail.carGradeNm);
    } else {
      setDefaultCarGradeNm("");
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const forms: Array<FormInfo> = [    
    {
      key: "carGradeNm",
      type: FormElementType.Input,
      label: "트림명",
      required: true,
    },
  ];

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    setConfirmLoading(true);

    const { carGradeNm } = values;

    if (catalogDetail && carGradeNm !== defaultCarGradeNm) {
      const mdGrdId = catalogDetail?.modeGrdId;
      const updCarGradeNm = values.carGradeNm;

      try {
        await updateUsdCarGradeNmUpd({
          modeGrdId: mdGrdId,
          carGradeNm: updCarGradeNm,
        });
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
      } catch (e) {
        console.log(e);
      } finally {
        setConfirmLoading(false);
      }
    } else if (onCancel) onCancel();
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      title="트림명 변경"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={{ carGradeNm: defaultCarGradeNm }}
          forms={forms}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

UsedCarGradeNmModifyModal.defaultProps = {
  visible: true,
};
export default UsedCarGradeNmModifyModal;
