import styled from "styled-components";

export const StatusTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusDot = styled.div<any>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 12px;
  background: ${(props) =>
    props.active
      ? (props.isRed ? props.theme.common.colors.red : props.theme.common.colors.point)
      : props.theme.common.colors.gray};
`;

export const StatusText = styled.span<any>`
  color: ${(props) =>
    props.active
      ? (props.isRed ? props.theme.common.colors.red : props.theme.common.colors.point)
      : props.theme.common.colors.gray};
`;
