import { FormikValues } from "formik";
import * as Yup from "yup";
import { FormElementType } from "../../../../../../../../components/shared/data-entry/FormBuilder/types";

import errorMessages from "../../../../../../../../constants/errors";
import { ReplyInfo } from "../../../../../../../../models/Survey/SurveyMasterDetail";
import { SURVEY_REPLY_GROUP_CD_OPTIONS } from "../../../../../../../../constants/enums";

export const createInitialValues = () => {
  return {
    srveReplyItemNm: "",
    txtInsYn: false,
    replyClsCd: null,
  };
};

export const createInitialValuesFromReply = (reply: ReplyInfo) => {
  return {
    srveReplyItemNm: reply.srveReplyItemNm,
    txtInsYn: reply.txtInsYn === "Y",
    replyClsCd: reply.replyClsCd,
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    srveReplyItemNm: Yup.string().required(errorMessages.REQUIRED_FIELD),
    replyClsCd: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createFormInfo = () => {
  return [
    {
      // 답변 내용
      key: "srveReplyItemNm",
      type: FormElementType.TextArea,
      label: "답변내용",
      required: true,
      placeholder: "답변 내용을 입력해주세요",
    },
    {
      // 답변 내용
      key: "replyClsCd",
      type: FormElementType.Select,
      options: SURVEY_REPLY_GROUP_CD_OPTIONS,
      label: "고객 만족도",
      required: true,
      placeholder: "만족도 구분을 선택해주세요",
    },
    {
      // 텍스트 입력가능 여부
      key: "txtInsYn",
      type: FormElementType.Checkbox,
      label: "텍스트입력",
      required: true,
    },
  ];
};
