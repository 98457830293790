import React from "react";
import { Image as AntImage, ImageProps as AntImageProps } from "antd";

interface ImageProps extends AntImageProps {}

const Image: React.FC<ImageProps> = (props: ImageProps) => {
  const { style, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <div
      style={{
        display: "inline-block",
        ...style,
      }}
    >
      <AntImage {...rest} />
    </div>
  );
};

Image.defaultProps = {
  preview: true,
};
export default Image;
