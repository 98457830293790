import React from "react";
import { Input as AntInput } from "antd";
import { TextAreaProps as AntTextAreaProps } from "antd/lib/input/TextArea";

const { TextArea: AntTextArea } = AntInput;

interface TextAreaProps extends AntTextAreaProps {}

const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
  const { children, style, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <AntTextArea
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

TextArea.defaultProps = {
  rows: 4,
};
export default TextArea;
