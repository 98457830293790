import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import strings from "./constants/strings";
import { createFAQCols } from "./utils/table-utils";
import FAQ from "../../../models/FAQ";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import FAQModal from "./pages/FAQModal";
import { editFAQUseYn, fetchFAQs } from "../../../apis/faqs";
import { createActionCellRenderers } from "../../../utils/table-utils";
import TableLayout from "../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { USAGE_STATE_SWITCH_RENDERER } from "../NoticeManager/utils/table-utils";
import { renderSwitchCol } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../constants/strings";

const FAQManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [faqModalVisible, setFaqModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const cols = createFAQCols();
  const [rows, setRows] = useState<Array<FAQ> | undefined>(undefined);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    (async function fetchData() {
      await requestFAQList();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestFAQList = async () => {
    try {
      const faqs = await fetchFAQs();
      const { items, count } = faqs;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      if (e.status !== 404) {
        alertError(getErrorMessage(e));
      }
      setRows([]);
    }
  };

  const requestEditFAQUseYn = async (faqNo: string, checked: boolean) => {
    try {
      await editFAQUseYn(faqNo, checked);
      await requestFAQList();
      alertSuccess(commonStrings.FEEDBACK_USE_YN_CHANGE);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const createActions = () => {
    return [
      {
        title: strings.TITLE_ACTION_ADD,
        onClick: handleAddFAQClick,
      },
    ];
  };

  /**
   * Event Actions
   */

  const handleAddFAQClick = () => {
    setSelectedId(undefined);
    setFaqModalVisible(true);
  };

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { faqNo } = data;
    setSelectedId(faqNo);
    setFaqModalVisible(true);
  };

  const handleUseYnChange = (checked: boolean, data: FAQ) => {
    showConfirm(
      checked ? commonStrings.CONFIRM_USE_ON : commonStrings.CONFIRM_USE_OFF,
      checked
        ? commonStrings.CONFIRM_CONTENT_USE_ON
        : commonStrings.CONFIRM_CONTENT_USE_OFF,
      async () => {
        await requestEditFAQUseYn(data.faqNo, checked);
      }
    );
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout headerTitle={strings.TITLE_HEADER_FAQ_MANAGER}>
        <TableLayout
          gridApi={gridApi}
          rightActions={createActions()}
          title={strings.TABLE_HEADER_FAQ_LIST}
          totalCount={totalCount}
          methodName="/admin/custcnte/faqs"
        >
          <ClientRowTable
            rowData={rows}
            cols={cols}
            frameworkComponents={{
              [USAGE_STATE_SWITCH_RENDERER]: renderSwitchCol(
                "useYn",
                handleUseYnChange
              ),
              ...createActionCellRenderers(undefined, handleEditClick, {
                hideDeleteButton: true,
              }),
            }}
            apiRef={(api) => {
              setGridApi(api);
            }}
          />
        </TableLayout>
      </ContentLayout>
      <FAQModal
        faqNo={selectedId}
        visible={faqModalVisible}
        onCancel={() => {
          setFaqModalVisible(false);
        }}
        onDataChange={async () => {
          gridApi?.showLoadingOverlay();
          await requestFAQList();
          gridApi?.hideOverlay();
        }}
      />
    </>
  );
};

FAQManager.defaultProps = {};
export default FAQManager;
