import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_LOGIN: "로그인",

    LABEL_ID:"아이디",
    HINT_ID:"아이디를 입력해주세요",
    LABEL_EMPLOYEE_NO: "사번",
    HINT_EMPLOYEE_NO: "숫자 입력",

    ERR_EMPLOYEE_NO_REQUIRED: "필수입력 필드 입니다",

    LABEL_PASSWORD: "비밀번호",
    HINT_PASSWORD: "비밀번호를 입력해주세요",
    DESC_PASSWORD: "영문, 특수문자 포함 6글자 이상",
    ERR_PASSWORD_REQUIRED: "필수입력 필드 입니다",

    TITLE_LOGO_PRIMARY: "SK Mode",
    TITLE_LOGO_SECONDARY: "관리자 로그인",
  },
});
