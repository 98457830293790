import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import NewCarCatalogCarGrade from "../../../../../../../models/NewCarCatalogCarGrade";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import { FormElementType } from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import errorMessages from "../../../../../../../constants/errors";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import { updateStopProductionInfo } from "../../../../../../../apis/nc-catalogs";

interface Props extends ModalProps {
  carGrade?: NewCarCatalogCarGrade;
}

const DansanReasonInfoModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, carGrade } = props;

  const [initialValues, setInitialValues] = useState<any>({});
  const [confirmLoading, setConfirmLoading] = useState(false);

  let formik: FormikProps<FormikValues>;

  const forms = [
    {
      // 공지 고정
      key: "dnsnYn",
      type: FormElementType.Checkbox,
      label: "단산여부",
      required: true,
    },
    {
      // 공지 고정
      key: "dnsnRsn",
      type: FormElementType.TextArea,
      label: "단산사유",
      required: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    dnsnRsn: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });

  const onModalOpen = () => {
    if (carGrade) {
      setInitialValues({
        dnsnRsn: carGrade.dnsnRsn || "",
        dnsnYn: carGrade.dnsnYn === "Y",
      });
    } else {
      setInitialValues({
        dnsnRsn: "",
        dnsnYn: false,
      });
    }
  };

  /**
   * Private Functions
   */
  const requestEditDansanState = async (
    modeGrdId: string,
    dnsnRsn: string,
    dnsnYn: string
  ) => {
    setConfirmLoading(true);
    try {
      await updateStopProductionInfo(modeGrdId, dnsnYn, dnsnRsn);
      alertSuccess("해당 트림의 단산정보가 변경되었습니다.");
      if (onDataChange) onDataChange();
      if (onCancel) onCancel();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (carGrade) {
      const { dnsnRsn, dnsnYn } = values;
      await requestEditDansanState(
        carGrade.modeGrdId,
        dnsnRsn,
        dnsnYn ? "Y" : "N"
      );
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      confirmLoading={confirmLoading}
      title="단산여부 관리"
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        formik?.handleSubmit();
      }}
      size="small"
      onOpen={onModalOpen}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
        forms={forms}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

DansanReasonInfoModal.defaultProps = {};
export default DansanReasonInfoModal;
