import styled from "styled-components";

const getHeaderPadding = (size: string) => {
  if (size === "large") return "16px 24px";

  if (size === "small") return "16px 0px";

  return "16px 0px";
};

export const HeaderWrapper = styled.div<any>`
  padding: ${(props) => getHeaderPadding(props.size)};
`;

export const HeaderTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  color: ${(props) => props.theme.common.colors.black};
`;
