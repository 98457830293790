export default class SMSTemplate {
  // SMS메시지ID
  public smsMsgId	 = "";

  // 상품구분
  public prodId	 = "";

  // SMS제목
  public smsTitle	 = "";

  // SMS메시지
  public smsMsg	 = "";

  // SMS요약
  public msgSmry	 = "";

  // 입력사용자
  public insUser	 = "";

  // 입력일시
  public insDtm	 = "";

  // 수정사용자
  public modUser	 = "";

  // 수정일시
  public modDtm	 = "";

  // EM_TRAN-TRANREFKEY
  public tranRefkey	 = "";

  // 메시지 종류
  public msgTypeCd	 = "";

  // 알림톡 발신자코드
  public senderCd	 = "";

  // 알림톡 메시지 유형 템플릿 코드
  public templateCd	 = "";

  // 발신자 번호
  public callbackNo	 = "";

  // 업무구분
  public jobCls	 = "";

  // 업무구분2
  public jobCls2	 = "";

  // 시스템구분
  public sysCls	 = "";

  // 버튼명
  public btnNm	 = "";

  // 사용여부
  public useYn	 = "";
}
