import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, UploadProps as AntUploadProps } from "antd";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import { uploadFile } from "../../../../apis/upload";
import { alertError } from "../../../../utils/render-utils";
import { getErrorMessage } from "../../../../utils/common-utils";
import UploadCode from "../../../../models/UploadCode";
import Button from "../../general/antd/Button";

export interface FileInfo {
  fileId?: string;
  imageUrl: string;
}

interface FileUploadProps extends AntUploadProps {
  buttonName?: string;
  isNotCheckFileExt?: boolean;
  onFileChange?: (imageInfos: Array<FileInfo>) => void;
  uploadCodeInfo: UploadCode;
  imageInfos?: Array<FileInfo>;
  maxUploadCount?: 1;
  onUploadStateChange?: (loading: boolean) => void;
}

const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
  const {
    buttonName,
    isNotCheckFileExt,
    onFileChange,
    uploadCodeInfo,
    imageInfos,
    maxUploadCount = 1,
    onUploadStateChange,
  } = props;
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (onUploadStateChange) onUploadStateChange(uploading);
  }, [uploading]);

  const getInitialImageFile = (): Array<any> => {
    if (imageInfos) {
      return imageInfos.map(({ fileId, imageUrl }) => ({
        uid: fileId || imageUrl,
        url: imageUrl,
        status: "done",
        name: imageUrl,
        // size: 0,
        // type: "image/*",
        response: {
          uploadResult: {
            fileId,
            imageUrl,
          },
        },
      }));
    }

    return [];
  };

  const [fileList, setFileList] = useState<Array<UploadFile>>(
    getInitialImageFile()
  );

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  const handleChange = async (info: UploadChangeParam) => {
    const updated = info.fileList.filter((file) => {
      const { status } = file;
      return !!status && status !== "error";
    });
    setFileList(updated);
    if (onFileChange) {
      onFileChange(
        updated
          .filter((file) => {
            return !!file.response;
          })
          .map(({ response }) => {
            const { uploadResult } = response;
            const { fileUrl, fileId } = uploadResult;
            return {
              imageUrl: fileUrl,
              fileId,
            };
          })
      );
    }
  };

  const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    const isPdf = file.type.indexOf("pdf") !== -1;

    // 확장자를 확인하지 않거나, pdf인 경우만 pass
    const isPass = isNotCheckFileExt || (!isNotCheckFileExt && isPdf);

    // ? 확장자 확인이 필요한 경우에만 pdf를 확인한다.
    if (!isNotCheckFileExt && !isPdf) {
      alertError("PDF 파일만 등록가능합니다.");
    }

    return isPass;
  };

  const handleUpload = async (options: any) => {
    // TODO Progress
    // const { onProgress } = options;
    const { onSuccess, onError, file } = options;

    setUploading(true);
    const formData = new FormData();
    formData.append("uploadfile", file);
    try {
      const uploadResult = await uploadFile(
        uploadCodeInfo.jobClsCd,
        uploadCodeInfo.idtfClsCd,
        formData
      );
      onSuccess({
        uploadResult,
      });
    } catch (e) {
      alertError(getErrorMessage(e));
      onError("Error");
    } finally {
      setUploading(false);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <Upload
        beforeUpload={handleBeforeUpload}
        maxCount={maxUploadCount}
        fileList={fileList}
        onChange={handleChange}
        customRequest={handleUpload}
      >
        <Button type="default" icon={<UploadOutlined />}>
          {buttonName || "파일 업로드"}
        </Button>
      </Upload>
    </>
  );
};

FileUpload.defaultProps = {};
export default FileUpload;
