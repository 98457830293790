import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { ModalProps } from "../../../../../../types";

import Modal from "../../../../../../../components/shared/feedback/antd/Modal";

import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import {
  fetchCarManagers,
  transferCarmanager,
} from "../../../../../../../apis/car-managers";
import FormBuilder, {
  defaultFormItemLayout,
} from "../../../../../../../components/shared/data-entry/FormBuilder";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import FormItem from "../../../../../../../components/shared/data-entry/antd/FormItem";
import Select from "../../../../../../../components/shared/data-entry/antd/Select";
import { CAR_MANAGER_TRANSFER_REASON_OPTIONS } from "../../../../constants/enums";
import errorMessages from "../../../../../../../constants/errors";

interface Props extends ModalProps {
  carmanId?: string;
  custId?: string;
}

const CarManagerTransferRequestModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, custId, carmanId, onDataChange } = props;

  const initialFormValues = {
    tgtCarmanId: "",
    reqCd: "",
  };
  const [initialValues, setInitialValues] = useState<any>(initialFormValues);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [carManagerOptions, setCarManagerOptions] = useState<any>([]);
  const [dataFetching, setDataFetching] = useState(false);

  let formik: FormikProps<FormikValues>;

  const validationSchema = Yup.object().shape({
    tgtCarmanId: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    reqCd: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });

  const forms: Array<FormInfo> = [
    {
      key: "tgtCarmanId",
      type: FormElementType.Custom,
      render: (renderProps) => {
        const { values, errors, touched, setFieldValue } = renderProps;

        let errMsg = "";
        if (errors.tgtCarmanId && touched.tgtCarmanId) {
          errMsg = errors.tgtCarmanId as string;
        }
        return (
          <FormItem
            {...defaultFormItemLayout}
            label="이관카매니저"
            required
            help={errMsg}
            validateStatus={errMsg && "error"}
          >
            <Select
              value={values.tgtCarmanId}
              options={carManagerOptions}
              onChange={(value) => {
                setFieldValue("tgtCarmanId", value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "reqCd",
      type: FormElementType.Select,
      label: "이관사유",
      required: true,
      options: CAR_MANAGER_TRANSFER_REASON_OPTIONS,
    },
  ];

  const onModalOpen = async () => {
    setInitialValues(initialFormValues);
    await requestCarManagers();
  };

  /**
   * Private Functions
   */

  const requestCarManagers = async () => {
    setDataFetching(true);
    try {
      const { items } = await fetchCarManagers();
      setCarManagerOptions(
        items.map(({ carmanId, userNm }) => {
          return {
            label: userNm,
            value: carmanId,
          };
        })
      );
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  const requestTransferCarManager = async (values: FormikValues) => {
    if (carmanId && custId) {
      setConfirmLoading(true);
      const { tgtCarmanId, reqCd } = values;
      try {
        await transferCarmanager(carmanId, tgtCarmanId, custId, reqCd);
        alertSuccess("카매니저 이관 요청이 등록되었습니다");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    await requestTransferCarManager(values);
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      title="카매니저 이관요청"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
      okButtonProps={{
        disabled: dataFetching,
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          validationSchema={validationSchema}
          initialValues={initialValues}
          forms={forms}
          formRef={(ref) => {
            formik = ref;
          }}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

CarManagerTransferRequestModal.defaultProps = {};
export default CarManagerTransferRequestModal;
