import React, { useState } from "react";
import {
  GridApi,
  LoadSuccessParams,
  ICellRendererParams
} from "ag-grid-community";
import {
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import { createEstCols } from "./utils/table-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import { selectUcTagobuyWrthCeftList } from "../../../apis/tago-ceft";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import YearCeftDetailModal from "./pages/YearCeftDetailModal";
import TagoCeftDetail from "../../../models/TagoCeftDetail";

const YearCeftManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>(); 
  const [totalCount, setTotalCount] = useState(0);
  const [selectedData, setSelectedData] = useState<TagoCeftDetail>();
  const [modalVisible, setModalVisible] = useState(false);

  const cols = createEstCols();

  const filterInitialValues = {
    
  };

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,    
  ) => {    
    try {
      const { items, count } = await selectUcTagobuyWrthCeftList();
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
    } catch (e) {
      setTotalCount(0);
      success({
        rowData: [],
        rowCount: 0,
      });
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const replacement = data as TagoCeftDetail;
    setSelectedData(replacement);
    setModalVisible(true);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <PaginationTableLayout
        headerTitle={"(타고바이) 미래가치계수 관리"}
        tableHeaderTitle={"년도별 미래가치계수"}
        apiRef={(ref) => {
          setGridApi(ref);
        }}
        onDataLoad={onDataLoad}
        cols={cols}
        filterInitialValues={filterInitialValues}
        hidePagination
        totalCount={totalCount}
        hideFilterAction
        frameworkComponents={{          
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
        }}
        methodName="/admin/tb-wrth-ceft"
      />
      <YearCeftDetailModal
        visible={modalVisible}        
        ceftInfo={selectedData}
        onCancel={() => {
          setModalVisible(false);
          refreshServerSideTable(gridApi);
        }}
      />
    </>
  );
};

YearCeftManager.defaultProps = {};
export default YearCeftManager;
