import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { alertSuccess } from "../../utils/render-utils";

/**
 * 제휴 중고차 이미지 벌크 등록
 */
 export const uploadFiles = async (apiurl: string, files: any[]) => {
  //const url = `${ROOT_URL}/admin/auc-catalogs`;
  const url = `${ROOT_URL}${apiurl}`;
  const originFormData = new FormData();

  originFormData.append("uploadfile", files[0]);

  const res = await request(HttpMethod.Post, url, undefined, originFormData, undefined, true);

  const { data } = res;
  const { message } = data;
  alertSuccess(message);
};

