import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  fetchNCCatalogs,
  updateCatalogOrder,
} from "../../../../../apis/nc-catalogs";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import NewCarCatalog from "../../../../../models/NewCarCatalog";
import CatalogListItem, {
  CW_BRAND,
  CW_IMG,
  CW_MODEL,
  CW_NO,
  CW_PROD_NM,
  CW_PROD_TYPE,
  CW_REG_DATE,
} from "./components/CatalogListItem";
import { ColumnHeaderLabel } from "./components/CatalogListItem/styles";

interface Props extends ModalProps {}

const CatalogSortOrderModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel } = props;

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [catalogs, setCatalogs] = useState<NewCarCatalog[]>([]);

  const onModalOpen = async () => {
    await requestFetchCatalogs();
  };

  /**
   * Private Functions
   */
  const requestFetchCatalogs = async () => {
    setDataFetching(true);
    try {
      const { items } = await fetchNCCatalogs();

      const filtered = items.filter(({ useYn }) => {
        return useYn === "Y";
      });
      setCatalogs(filtered);
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  /**
   * Event Actions
   */
  const handleSubmit = async () => {
    setConfirmLoading(true);
    const params = catalogs.map(({ prodId }, index) => {
      return {
        prodId,
        viewSeq: `${index}`,
      };
    });
    try {
      await updateCatalogOrder(params);
      alertSuccess("상품 게시순서가 변경되었습니다.");
      if (onCancel) onCancel();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    const dragCard = catalogs[dragIndex];
    setCatalogs(
      update(catalogs, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
    );
  };

  /**
   * Render Helpers
   */
  const renderLayout = () => {
    return (
      <DndProvider backend={HTML5Backend}>
        <div
          style={{
            padding: "16px 8px",
            width: "100%",
            display: "flex",
            height: "60px",
            alignItems: "center",
          }}
        >
          <ColumnHeaderLabel width={CW_NO}>No.</ColumnHeaderLabel>
          <ColumnHeaderLabel width={CW_REG_DATE}>등록일</ColumnHeaderLabel>
          <ColumnHeaderLabel width={CW_IMG}>이미지</ColumnHeaderLabel>
          <ColumnHeaderLabel width={CW_PROD_TYPE}>상품타입</ColumnHeaderLabel>
          <ColumnHeaderLabel width={CW_BRAND}>제조사</ColumnHeaderLabel>
          <ColumnHeaderLabel width={CW_PROD_NM}>상품명</ColumnHeaderLabel>
          <ColumnHeaderLabel width={CW_MODEL}>모델</ColumnHeaderLabel>
          <ColumnHeaderLabel>세부모델</ColumnHeaderLabel>
        </div>

        <div
          style={{
            height: "60vh",
            overflow: "auto",
            position: "relative",
          }}
        >
          {catalogs.map((catalog, index) => {
            return (
              <CatalogListItem
                id={catalog.prodId}
                key={catalog.prodId}
                catalog={catalog}
                index={index}
                moveCard={handleMoveItem}
              />
            );
          })}
        </div>
      </DndProvider>
    );
  };

  return (
    <Modal
      size="large"
      title="상품 게시순번 관리"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={handleSubmit}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? renderLoading() : renderLayout()}
    </Modal>
  );
};

CatalogSortOrderModal.defaultProps = {
  visible: true,
};
export default CatalogSortOrderModal;
