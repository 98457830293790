import { plainToClass } from "class-transformer";
import CarManager from "../../models/CarManager";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import {
  CarManagerAddParam,
  CarManagerEditParam,
  CarManagerReplacementAddParam,
  CarManagerScheduleEditParam,
} from "./types";
import CarManagerDetail, { SiteListInfo } from "../../models/CarManagerDetail";
import CossUser from "../../models/CossUser";
import CarManagerDeliverySchedule from "../../models/CarManagerDeliverySchedule";
import CarManagerDeliveryScheduleDetail from "../../models/CarManagerDeliveryScheduleDetail";
import CarManagerReplacement from "../../models/CarManagerReplacement";
import CarManagerReplacementDetail from "../../models/CarManagerReplacementDetail";
import CarManagerTransferRequest from "../../models/CarManagerTransferRequest";

const apiRootUrl = `${ROOT_URL}/admin/carman`;

/**
 * 인도 스케줄 조회
 */
export const fetchCarManagerDeliverySchedules = async (
  startDt: string,
  endDt: string,
  carmanNm?: string,
  ncarClsFlag?: string
) => {
  const url = `${apiRootUrl}/schd`;
  const res = await request(HttpMethod.Get, url, {
    schdSDt: startDt,
    schdEDt: endDt,
    carmanNm,
    ncarClsFlag,
  });
  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(CarManagerDeliverySchedule, list as Array<JSON>),
    count: Number(listCnt),
  };
  // return {
  //   items: plainToClass(CarManagerDeliverySchedule, MOCK_SCHEDULES),
  //   count: Number(MOCK_SCHEDULES.length),
  // };
};

/**
 * 인도 스케줄 상세 조회
 */
export const fetchCarManagerDeliveryScheduleDetail = async (
  cntrId: string,
  consiId: string,
  consiClsCd: string,
  consiSeq: string
) => {
  const url = `${apiRootUrl}/schd/${cntrId}/${consiId}/${consiClsCd}/${consiSeq}`;
  const res = await request(HttpMethod.Get, url);
  const { data } = res;
  const { response } = data;
  return plainToClass(CarManagerDeliveryScheduleDetail, response);
};

/**
 * 인도 스케줄 수정
 */

export const editCarManagerDeliveryScheduleDetail = async (
  consiId: string,
  consiClsCd: string,
  consiSeq: string,
  params: CarManagerScheduleEditParam
) => {
  const url = `${apiRootUrl}/schd/${consiId}/${consiClsCd}/${consiSeq}`;
  await request(HttpMethod.Put, url, undefined, params);
};

/**
 * 카매니저 등록용 COSS 유저 리스트 조회
 */
export const fetchCarManagerCossUsers = async () => {
  const url = `${apiRootUrl}/fulltimeemp`;
  const res = await request(HttpMethod.Get, url, {
    pageIndex: 1,
    pageSize: 100,
  });

  const { data } = res;
  const { response } = data;
  const { totalCnt, list } = response;
  return {
    items: plainToClass(CossUser, list as Array<JSON>),
    count: Number(totalCnt),
  };
};

/**
 * 카매니저 지역 정보 조회
 */

export const fetchCarManagerSiteOptions = async () => {
  const url = `${apiRootUrl}/allsitelist`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    list: plainToClass(SiteListInfo, list as Array<JSON>),
    listCnt: Number(listCnt)
  };
}


/**
 * 카매니저 리스트 조회
 */
export const fetchCarManagers = async (): Promise<
  ListItemResponse<CarManager>
> => {
  const res = await request(HttpMethod.Get, apiRootUrl);

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(CarManager, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 카매니저 상세 조회
 */

export const fetchCarManagerDetail = async (
  carmanId: string
): Promise<CarManagerDetail> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/${carmanId}`);
  const { data } = res;
  const { response } = data;
  return plainToClass(CarManagerDetail, response);
};

/**
 * 카매니저 등록
 */
export const addCarManager = async (params: CarManagerAddParam) => {
  await request(HttpMethod.Post, apiRootUrl, undefined, params);
};

/**
 * 카매니저 수정
 */
export const editCarManager = async (
  carmanId: string,
  params: CarManagerEditParam
) => {
  await request(HttpMethod.Put, `${apiRootUrl}/${carmanId}`, undefined, params);
};

/**
 * 카매니저 삭제
 */
export const deleteCarManager = async (carmanId: string) => {
  await request(HttpMethod.Get, `${apiRootUrl}/${carmanId}`);
};

/**
 * 카매니저 이관 요청
 */
export const transferCarmanager = async (
  carmanId: string,
  tgtCarmanId: string,
  custId: string,
  reqCd: string
) => {
  await request(
    HttpMethod.Post,
    `${apiRootUrl}/tran/${carmanId}/${tgtCarmanId}`,
    undefined,
    {
      custId,
      reqCd,
    }
  );
};

/**
 * 카매니저 이관 요청 리스트
 */
export const fetchCarManagerTransferRequests = async (): Promise<
  ListItemResponse<CarManagerTransferRequest>
> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/tran`);
  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(CarManagerTransferRequest, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 카매니저 이관상태 변경
 */
export const editCarManagerTransferRequestState = async (
  tranSeq: string,
  tranSts: string,
  rejtCd?: string
) => {
  await request(HttpMethod.Put, `${apiRootUrl}/tran`, undefined, {
    tranSeq,
    tranSts,
    rejtCd,
  });
};

/**
 * 카매니저 대무 정보 리스트
 */

export const fetchCarManagerReplacementInfos = async (): Promise<
  ListItemResponse<CarManagerReplacement>
> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/dtyagcy`);
  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(CarManagerReplacement, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 카매니저 대무 정보 상세
 */

export const fetchCarManagerReplacementInfoDetail = async (
  carmanId: string,
  seq: string
): Promise<CarManagerReplacementDetail> => {
  const res = await request(
    HttpMethod.Get,
    `${apiRootUrl}/dtyagcy/${carmanId}`,
    { seq }
  );
  const { data } = res;
  const { response } = data;
  return plainToClass(CarManagerReplacementDetail, response);
};

/**
 * 카매니저 대무 정보 등록
 */

export const addCarManagerReplacementInfo = async (
  carmanId: string,
  params: CarManagerReplacementAddParam
) => {
  await request(HttpMethod.Post, `${apiRootUrl}/dtyagcy/save`, undefined, {
    ...params,
    carmanId,
  });
};

/**
 * 카매니저 대무 정보 수정
 */

export const editCarManagerReplacementInfo = async (
  carmanId: string,
  seq: string,
  stDt: string,
  endDt: string,
  daCarmanId: string,
  rsnCd: string,
  rsn?: string
) => {
  await request(
    HttpMethod.Put,
    `${apiRootUrl}/dtyagcy/detail/${carmanId}`,
    {
      seq,
    },
    {
      stDt,
      endDt,
      daCarmanId,
      rsnCd,
      rsn,
    }
  );
};

/**
 * 카매니저 대무 사용 유무 변경
 */

export const editCarManagerReplacementUseYn = async (
  carmanId: string,
  seq: string,
  isUsing: boolean
) => {
  await request(HttpMethod.Put, `${apiRootUrl}/dtyagcy/useyn`, {
    carmanId,
    seq,
    useYn: isUsing ? "Y" : "N",
  });
};
