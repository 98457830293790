import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import strings from "./constants/strings";
import {
  createNoticeCols,
  USAGE_STATE_SWITCH_RENDERER,
} from "./utils/table-utils";
import { editNotice, fetchNotices } from "../../../apis/notices";
import Notice from "../../../models/Notice";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import NoticeModal from "./pages/NoticeModal";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { renderSwitchCol } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../constants/strings";

const NoticeManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [checkedRows, setCheckedRows] = useState<Array<Notice>>([]);
  const [noticeModalVisible, setNoticeModalVisible] = useState(false);
  const [selectedSeq, setSelectedSeq] = useState<string | undefined>();
  const [rows, setRows] = useState<Array<Notice> | undefined>(undefined);
  const [totalCount, setTotalCount] = useState(0);
  const cols = createNoticeCols();

  useEffect(() => {
    (async function fetchData() {
      await requestNoticeList();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestNoticeList = async () => {
    try {
      const noticeList = await fetchNotices();
      const { items, count } = noticeList;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
      setRows([]);
      setTotalCount(0);
    }
  };

  const requestEditNoticeUseYn = async (
    noticeSeq: string,
    checked: boolean
  ) => {
    try {
      await editNotice(noticeSeq, {
        useYn: checked ? "Y" : "N",
      });
      alertSuccess(commonStrings.FEEDBACK_USE_YN_CHANGE);
      await requestNoticeList();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  const handleAddNoticeClick = () => {
    setSelectedSeq(undefined);
    setNoticeModalVisible(true);
  };

  const handleCheckRowChanged = (rows: Array<Notice>) => {
    setCheckedRows(rows);
  };

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { noticeSeq } = data;
    setSelectedSeq(noticeSeq);
    setNoticeModalVisible(true);
  };

  const handleUseYnChange = (checked: boolean, data: Notice) => {
    showConfirm(
      checked ? commonStrings.CONFIRM_USE_ON : commonStrings.CONFIRM_USE_OFF,
      checked
        ? commonStrings.CONFIRM_CONTENT_USE_ON
        : commonStrings.CONFIRM_CONTENT_USE_OFF,
      async () => {
        await requestEditNoticeUseYn(data.noticeSeq, checked);
      }
    );
  };

  const createActions = () => {
    return [
      {
        title: strings.TITLE_ACTION_ADD,
        onClick: handleAddNoticeClick,
      },
    ];
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout
        filterable={false}
        headerTitle={strings.TITLE_HEADER_NOTICE_MANAGER}
      >
        <TableLayout
          gridApi={gridApi}
          title={strings.TABLE_HEADER_NOTICE_LIST}
          totalCount={totalCount}
          selectionCount={checkedRows.length}
          rightActions={createActions()}
          methodName="/admin/notices"
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            onCheckRowChanged={handleCheckRowChanged}
            cols={cols}
            frameworkComponents={{
              [USAGE_STATE_SWITCH_RENDERER]: renderSwitchCol(
                "useYn",
                handleUseYnChange
              ),
              ...createActionCellRenderers(undefined, handleEditClick, {
                hideDeleteButton: true,
              }),
            }}
          />
        </TableLayout>
      </ContentLayout>
      <NoticeModal
        onDataChange={async () => {
          await requestNoticeList();
        }}
        seq={selectedSeq}
        isOpen={noticeModalVisible}
        onCancel={() => {
          setNoticeModalVisible(false);
        }}
      />
    </>
  );
};

NoticeManager.defaultProps = {};
export default NoticeManager;
