import React from "react";
import { Select as AntSelect, SelectProps as AntSelectProps } from "antd";

export interface SelectProps extends AntSelectProps<any> {}

const Select: React.FC<SelectProps> = (props: SelectProps) => {
  const { style, showSearch, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <AntSelect
      style={{
        ...style,
      }}
      showSearch={showSearch}
      filterOption={(input, option: any) => {
        if (input && option) {
          const { label } = option;
          return (
            typeof label === "string" &&
            label.toLowerCase().indexOf(input.toLowerCase()) !== -1
          );
        }

        return false;
      }}
      {...rest}
    />
  );
};

Select.defaultProps = {
  showSearch: true,
};
export default Select;
