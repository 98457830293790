export default class AucCooperDetail {
  // 제휴사 코드
  public compCd = "";

  // 제휴사명
  public compNm = "";

  // 대표 메일
  public compMail = "";

  // 사용 여부
  public useYn = "";

  // 계약기간 리스트 prefix
  public cntrTermMm = "";

  // 계약기간 리스트
  public cntrTermMmList: UcCooperCompanyCondResVO[] = [];

  // 약정주행거리 리스트 prefix
  public prmsDtc = "";

  // 약정주행거리 리스트
  public prmsDtcList: UcCooperCompanyCondResVO[] = [];

  // 대물보험 리스트 prefix
  public impsnl = "";

  // 대물보험 리스트
  public impsnlList: UcCooperCompanyCondResVO[] = [];

  // 신용등급 리스트 prefix
  public crdGrd = "";

  // 신용등급 리스트
  public crdGrdList: UcCooperCompanyCondResVO[] = [];
}


export class UcCooperCompanyCondResVO {
  // 조건 코드
  public condCd = "";

  // 조건 코드명
  public condCdNm = "";

  // 조건 값
  public condVal = "";

  // 사용여부
  public useYn = "";
}