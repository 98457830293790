import React, { useState } from "react";
import { StarFilled } from "@ant-design/icons";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import DetailSection, {
  FieldType,
} from "../../../../../components/shared/layout/DetailSection";
import { getErrorMessage } from "../../../../../utils/common-utils";
import ReviewDetail from "../../../../../models/ReviewDetail";
import { ModalProps } from "../../../../types";
import { editReview, fetchReviewDetail } from "../../../../../apis/reviews";
import { formatDateString } from "../../../../../utils/date-utils";
import Switch from "../../../../../components/shared/data-entry/antd/Switch";
import { showConfirm } from "../../../../../components/shared/feedback/Confirm";
import { ReviewEditParam } from "../../../../../apis/reviews/types";
import Image from "../../../../../components/shared/data-display/antd/Image";
import Button from "../../../../../components/shared/general/antd/Button";
import ReviewImageEditModal from "./pages/ReviewImageEditModal";

interface Props extends ModalProps {
  cntrId?: string;
}

const ReviewDetailModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, cntrId, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [reviewDetail, setReviewDetail] = useState<ReviewDetail>();
  const [
    reviewImageEditModalVisible,
    setReviewImageEditModalVisible,
  ] = useState(false);

  const onModalOpen = async () => {
    if (cntrId) {
      const review = await requestFetchReviewDetail(cntrId);
      setReviewDetail(review);
    } else {
      setReviewDetail(undefined);
    }
  };

  /**
   * Private Functions
   */

  const requestUpdateReviewState = async (
    params: ReviewEditParam,
    successMessage?: string
  ) => {
    if (reviewDetail) {
      try {
        await editReview(reviewDetail.cntrId, params);
        const review = await requestFetchReviewDetail(
          reviewDetail.cntrId,
          false
        );
        setReviewDetail(review);
        alertSuccess(successMessage || "리뷰 정보가 변경되었습니다.");
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      }
    }
  };

  const requestFetchReviewDetail = async (
    cntrId: string,
    dataFetching = true
  ) => {
    setDataFetching(dataFetching);
    try {
      return await fetchReviewDetail(cntrId);
    } catch (e) {
      alertError(getErrorMessage(e));
      return undefined;
    } finally {
      setDataFetching(false);
    }
  };

  /**
   * Event Actions
   */

  const handleReviewDisplayState = (checked: boolean) => {
    showConfirm(
      checked ? "노출상태로 변경" : "숨김상태로 변경",
      checked
        ? "해당 리뷰가 전체 후기리스트에 노출됩니다."
        : "해당 리뷰가 전체 후기리스트에 숨김 처리됩니다.",
      async () => {
        await requestUpdateReviewState(
          {
            viewYn: checked ? "Y" : "N",
          },
          "후기 노출상태가 변경되었습니다."
        );
      }
    );
  };

  const handleNCDisplayState = (checked: boolean) => {
    showConfirm(
      checked ? "노출상태로 변경" : "숨김상태로 변경",
      checked
        ? "해당 리뷰가 신차장기 메인에 노출됩니다."
        : "해당 리뷰가 신차장기 메인에서 숨김 처리됩니다.",
      async () => {
        await requestUpdateReviewState(
          {
            newcarViewYn: checked ? "Y" : "N",
          },
          "신차장기 메인 노출상태가 변경되었습니다."
        );
      }
    );
  };

  const handleUCDisplayState = (checked: boolean) => {
    showConfirm(
      checked ? "노출상태로 변경" : "숨김상태로 변경",
      checked
        ? "해당 리뷰가 타보중 메인에 노출됩니다."
        : "해당 리뷰가 타보중 메인에서 숨김 처리됩니다.",
      async () => {
        await requestUpdateReviewState(
          {
            usdcarViewYn: checked ? "Y" : "N",
          },
          "타보중 메인 노출상태가 변경되었습니다."
        );
      }
    );
  };

  /**
   * Render Helpers
   */

  const renderDetail = () => {
    if (reviewDetail) {
      // 후기정보
      const reviewInfoSection = [
        {
          label: "후기차량정보",
          type: FieldType.LabelOnly,
          content: "",
        },
        {
          label: "고객명",
          type: FieldType.Text,
          content: reviewDetail.custNm,
        },
        {
          label: "등록일",
          type: FieldType.Text,
          content: formatDateString(reviewDetail.regDt),
        },
        {
          label: "상품유형",
          type: FieldType.Text,
          content: reviewDetail.prodPatternNm,
        },
        {
          label: "계약유형",
          type: FieldType.Text,
          content: reviewDetail.cntrPatternNm,
        },
        {
          label: "제조사",
          type: FieldType.Text,
          content: reviewDetail.brandNm,
        },
        {
          label: "모델",
          type: FieldType.Text,
          content: reviewDetail.repCartypeNm,
        },
        {
          label: "세부모델",
          type: FieldType.Text,
          content: reviewDetail.carClassNm,
        },
        {
          label: "트림",
          type: FieldType.Text,
          content: reviewDetail.carGradeNm,
        },
        {
          type: FieldType.Divider,
        },
        {
          label: "후기정보",
          type: FieldType.LabelOnly,
        },
        {
          label: "전체 후기 노출",
          type: FieldType.Custom,
          render: () => {
            return (
              <Switch
                checked={reviewDetail.viewYn === "Y"}
                onChange={handleReviewDisplayState}
              />
            );
          },
        },
        {
          label: "신차장기 메인 후기 노출",
          type: FieldType.Custom,
          render: () => {
            return (
              <Switch
                checked={reviewDetail.newcarViewYn === "Y"}
                onChange={handleNCDisplayState}
              />
            );
          },
        },
        {
          label: "타보중 메인 후기 노출",
          type: FieldType.Custom,
          render: () => {
            return (
              <Switch
                checked={reviewDetail.usdcarViewYn === "Y"}
                onChange={handleUCDisplayState}
              />
            );
          },
        },
        {
          label: "별점",
          type: FieldType.Custom,
          content: reviewDetail.crmScore,
          span: 24,
          render: () => {
            const score = reviewDetail.crmScore || 0;
            const jsx = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < score; i++) {
              jsx.push(
                <StarFilled
                  style={{
                    color: "#626DFF",
                    marginRight: "4px",
                  }}
                />
              );
            }

            return jsx;
          },
        },
        {
          label: "후기 이미지",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div>
                <div
                  style={{
                    marginBottom: "12px",
                  }}
                >
                  {reviewDetail.fileInfoList.map(({ fileUrl }, index) => {
                    return (
                      <Image
                        style={{
                          marginRight: "16px",
                        }}
                        src={fileUrl}
                        alt={fileUrl}
                        key={index}
                        width={120}
                        height={120}
                      />
                    );
                  })}
                </div>
                <Button
                  onClick={() => {
                    setReviewImageEditModalVisible(true);
                  }}
                >
                  이미지 수정
                </Button>
              </div>
            );
          },
        },
        {
          label: "후기내용",
          type: FieldType.Text,
          content: reviewDetail.crmCntn,
        },
      ];

      return (
        <div>
          <DetailSection
            label="후기 정보"
            fieldInfos={reviewInfoSection}
            hideDivider
          />
        </div>
      );
    }

    return "";
  };

  return (
    <>
      <Modal
        size="large"
        title="후기 상세"
        visible={visible}
        onCancel={onCancel}
        onOpen={onModalOpen}
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      <ReviewImageEditModal
        reviewDetail={reviewDetail}
        visible={reviewImageEditModalVisible}
        onCancel={() => {
          setReviewImageEditModalVisible(false);
        }}
        onDataChange={async () => {
          if (cntrId) {
            const review = await requestFetchReviewDetail(cntrId, false);
            setReviewDetail(review);
          }
        }}
      />
    </>
  );
};

ReviewDetailModal.defaultProps = {
  cntrId: undefined,
};

export default ReviewDetailModal;
