import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import RentContract from "../../models/RentContract";
import ContractUpdateRequest from "../../models/ContractUpdateRequest";
import RentContractDetail from "../../models/RentContractDetail";
import CSUser from "../../models/CSUser";
import { CooperType, TkvRtrnYnCd } from "../../constants/enums";

const apiRootUrl = `${ROOT_URL}/admin/rent-cntr-list`;

/**
 * 렌탈형 계약 리스트 조회
 */
export const fetchRentContracts = async (
  pageIndex: number,
  pageSize: number,
  cntrStDt: string,
  cntrEndDt: string,
  busiNo?: string,
  estStDt?: string,
  estEndDt?: string,
  custNm?: string,
  custClsCd?: string,
  ncarClsFlag?: string,
  consiStsCd?: string,
  cntrStsCd?: string,
  carmanNm?: string,
  carNo?: string,
  pldgAmtPaymentYn?: string,
  hpNo?: string,
  carmanAsgnYn?: string,
  addVrfYn?: string,
  kisAuthYn?: string,
  cnslUserNm?: string,
  skGrpYn?: string,
  srvcId?: string,
  cooperCd?: CooperType | "",
  tkvRtrnYnCd?: TkvRtrnYnCd | "",
  tkvReqYn?: string,
  cnclStDt?: string,
  cnclEndDt?: string,
  cnslEndYn?: string,
  erpCustCd?: string,
): Promise<ListItemResponse<RentContract>> => {
  const res = await request(HttpMethod.Get, apiRootUrl, {
    pageIndex,
    pageSize,
    cntrStDt,
    cntrEndDt,
    busiNo,
    estStDt,
    estEndDt,
    custNm,
    custClsCd,
    ncarClsFlag,
    consiStsCd,
    cntrStsCd,
    carmanNm,
    carNo,
    pldgAmtPaymentYn,
    hpNo,
    carmanAsgnYn,
    addVrfYn,
    kisAuthYn,
    cnslUserNm,
    skGrpYn,
    srvcId,
    cooperCd,
    tkvRtrnYnCd,
    tkvReqYn,
    cnclStDt,
    cnclEndDt,
    cnslEndYn,
    erpCustCd
  });

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(RentContract, list as Array<JSON>),
    count: Number(totalCnt),
  };
};

/**
 * 렌탈형 계약 상세 조회
 */

export const fetchRentContractDetail = async (
  cntrId: string,
  cntrSeq: string
): Promise<RentContractDetail> => {
  const url = `${ROOT_URL}/admin/rent-cntr-dtl`;
  const res = await request(HttpMethod.Get, url, {
    cntrId,
    cntrSeq,
  });
  const { data } = res;
  const { response } = data;

  return plainToClass(RentContractDetail, response);
};

/**
 * 계약 변경 요청 리스트 조회
 */
export const fetchContractUpdateRequestList = async (
  changeReqStDt: string,
  changeReqEndDt: string
): Promise<ListItemResponse<ContractUpdateRequest>> => {
  const url = `${ROOT_URL}/admin/cntr-chg-req-list`;
  const res = await request(HttpMethod.Get, url, {
    changeReqStDt,
    changeReqEndDt,
  });

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(ContractUpdateRequest, list as Array<JSON>),
    count: listCnt,
  };
};

/**
 * 단일 계약 변경 요청 리스트 조회
 */
export const fetchSingleContractUpdateRequestList = async (
  cntrId: string,
  cntrSeq: string
): Promise<ListItemResponse<ContractUpdateRequest>> => {
  const url = `${ROOT_URL}/admin/cntr-chg-req/${cntrId}/${cntrSeq}`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(ContractUpdateRequest, list as Array<JSON>),
    count: listCnt,
  };
};

/**
 * 계약 상담메모 등록
 */
export const addContractMemo = async (
  cntrId: string,
  cntrSeq: number,
  memoCntn: string,
  cnslEndYn: string,
  custId?: string,
) => {
  const url = `${ROOT_URL}/admin/cntr-memo`;
  await request(HttpMethod.Post, url, undefined, {
    cntrId,
    cntrSeq,
    memoCntn,
    cnslEndYn,
    custId,
  });
};

/**
 * 계약 상담메모 수정
 */
export const editContractMemo = async (
  cntrId: string,
  cntrSeq: number,
  memoSeq: number,
  memoCntn?: string,
  cnslEndYn?: string,
  custId?: string,
  useYn?: string,
) => {
  const url = `${ROOT_URL}/admin/cntr-memo/${cntrId}/${cntrSeq}/${memoSeq}`;
  await request(HttpMethod.Put, url, undefined, {
    memoCntn,
    useYn,
    cnslEndYn,
    custId,
  });
};

/**
 * 계약 상담원 지정
 */
export const addContractCSUser = async (
  cntrId: string,
  cntrSeq: number,
  cnslUserId: string
) => {
  const url = `${ROOT_URL}/admin/cntr-cnsl-user`;
  await request(HttpMethod.Post, url, undefined, {
    cntrId,
    cntrSeq,
    cnslUserId,
  });
};

/**
 * 계약 상담원 지정 변경
 */
export const editContractCSUser = async (
  cntrId: string,
  cntrSeq: number,
  cnslUserId: string
) => {
  const url = `${ROOT_URL}/admin/cntr-cnsl-user/${cntrId}/${cntrSeq}`;
  await request(HttpMethod.Put, url, undefined, {
    cnslUserId,
  });
};

/**
 * 계약 상담원 조회
 */

export const fetchCSUsers = async (): Promise<CSUser[]> => {
  const url = `${ROOT_URL}/admin/cnsl-users`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  const { list } = response;
  return plainToClass(CSUser, list as Array<JSON>);
};

/**
 * 카매니저 배정
 */
export const setupCarManager = async (
  cntrId: string,
  cntrSeq: string,
  carmanId: string
) => {
  const url = `${ROOT_URL}/admin/carman-asgn`;
  await request(HttpMethod.Post, url, undefined, {
    cntrId,
    cntrSeq,
    carmanId,
  });
};


/**
 * 타고바이(TAGOBUY) - 인수 요청 취소 업데이트
 */
 export const updateUcTagobuyCntrTkvReq = async (
  cntrId: string,
  cntrSeq: string,
  tkvReqYn: string
) => {
  const url = `${ROOT_URL}/admin/tb-tkv-req-yn/${cntrId}/${cntrSeq}`;
  await request(HttpMethod.Post, url, undefined, {
    tkvReqYn,
  });
};
