import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import GradeRate from "../../models/GradeRate";
import { GradeRateAddParam, GradeRateEditParam } from "./types";

const apiRootUrl = `${ROOT_URL}/admin/grade-rate-manager`;

/**
 * 보증보험 수수료율 리스트 조회
 */
export const fetchGradeRates = async (): Promise<
  ListItemResponse<GradeRate>
> => {
  const res = await request(HttpMethod.Get, apiRootUrl);

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(GradeRate, list as Array<JSON>),
    count: listCnt,
  };
};

/**
 * 보증보험 수수료율 등록
 */
export const addGradeRate = async (params: GradeRateAddParam) => {
  await request(HttpMethod.Post, ` ${apiRootUrl}`, undefined, params);
};

/**
 * 보증보험 수수료율 수정
 */

export const updateGradeRate = async (
  insrGrtRtId: string,
  params: GradeRateEditParam
) => {
  await request(HttpMethod.Put, ` ${apiRootUrl}/${insrGrtRtId}`, undefined, {
    ...params
  });
};

export const deleteGradeRate = async (insrGrtRtId: string) => {
  await request(HttpMethod.Delete, `${apiRootUrl}/${insrGrtRtId}`);
};
