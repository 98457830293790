import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_HEADER_NC_CATALOG_MANAGER: "온라인 상품관리(신차장기)",
    TABLE_HEADER_NC_CATALOGS: "신차장기 상품 리스트",
    DESCRIPTION_HEADER_NC_CATALOG_MANAGER:
      "신차장기 렌터카 상품 정보와 게시순서를 관리 합니다.",

    COL_REGISTERED_AT: "등록일",
    COL_DISPLAY_STATE: "노출상태",
    COL_DISPLAY_SEQ: "게시순번",
    COL_CATALOG_NAME: "상품명",
    COL_CAR_NO: "차량번호",
    COL_CATALOG_TYPE: "상품타입",
    COL_BRAND: "제조사",
    COL_MODEL: "모델",
    COL_DETAIL_MODEL: "세부모델",
    COL_PROD_NM: "상품명",
    COL_CAR_YEAR: "연식",
    COL_TRIM: "트림",
    COL_DANSAN_SETTINGS: "단산관리",
    COL_DANSAN_TRIM_COUNT: "단산 트림 수",
    COL_REG_TRIM_COUNT: "등록 트림 수",
    COL_STOCK_COUNT: "재고 수",
    COL_TRIM_COUNT: "노출 트림 수",
    COL_REQUIRED: "필수값 입력완료",

    TITLE_MODAL_NC_CATALOG_DETAIL: "상품 상세 정보",
    FEEDBACK_DISPLAY_STATE_CHANGE: "상품 노출 여부가 변경되었습니다",
    MODEL_DISPLAY_STATE_CHANGE: "모델 노출 여부가 변경되었습니다",
  },
});