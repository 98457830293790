class BannerGroup {
  // 등록일
  public regDtm = "";

  // 배너아이디
  public bnnrId = "";

  // 배너상세영역
  public bnnrDmnId = "";

  // 사용유무
  public useYn = "";

  // 배너 총 개수
  public bnnrCnt = 0;

  // 유효 배너 개수
  public bnnrYnCnt = 0;

  // 배너 코드
  public bnnrSeq = "";

  // 배너 영역 명
  public bnnrDmn = "";
}

export default BannerGroup;
