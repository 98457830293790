import TableColumn, {
  createServerTableCol,
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

export const createStockReportDetailCols = (): Array<TableColumn> => {
  return [
    // 차량번호
    createTableCol("carNo", "차량번호", FilterType.Text,{
      width: CW_MEDIUM,
      flex: 1,
    }),
    // 입고일자
    createTableCol("inDt", "입고일자", FilterType.Date,{
      width: CW_MEDIUM,
      flex: 1,
      cellRenderer: dateFormatRenderer("inDt"),
    }),
    // 출고일자
    createTableCol("outDt", "출고일자", FilterType.Date, {
      width: CW_MEDIUM,
      flex: 1,
      cellRenderer: dateFormatRenderer("outDt"),
    }),
    // 보유일수
    createTableCol("hldDays", "보유일수", FilterType.Text,{
      width: CW_MEDIUM,
      flex: 1,
    }),
  ];
};
