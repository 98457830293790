import React from "react";
import { Tag } from "antd";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import Button from "../../../../../general/antd/Button";
import { createTableCol, FilterType } from "../../../model/TableColumn";
import { CW_MEDIUM } from "@utils/table-utils";
import { formatDateString } from "@utils/date-utils";
import {
  formatCurrency,
  formatNumber,
} from "@utils/common-utils";
import Switch from "../../../../../data-entry/antd/Switch";
import TagMaster from "@models/TagMaster";
import {
  getMobileTagStyles,
  getPcTagStyles,
  TagStyleCode,
} from "../../../../../composition/TagMasterSelect/utils/utils";
import { TAG_STYLE_CD_11 } from "@constants/enums";
import Image from "@components/shared/data-display/antd/Image";
import PromotionIcon from "@assets/images/promotion-icon.png";

export const USER_DETAIL_ACTION = "userDetailAction";
export const CAR_MANAGER_DETAIL_ACTION = "carManagerDetailAction";

/**
 * 컬럼 스위치 Renderer
 */

export const renderSwitchCol = (
  valueField: string,
  onChange: (checked: boolean, data: any, api?: GridApi) => void
) => {
  return (props: ICellRendererParams) => {
    const { data, api } = props;
    const useYn = data[valueField];

    return (
      <Switch
        checked={useYn === "Y"}
        onClick={(checked) => {
          onChange(checked, data, api);
        }}
      />
    );
  };
};

/**
 * 회원 상세 컬럼
 */

export const renderUserDetailButton = (
  onClick: (custId?: string, cntrId?: string, cntrSeq?: number) => void,
  idField = "custId",
  nameField = "custNm"
) => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    return (
      <Button
        type="default"
        size="small"
        onClick={() => {
          onClick(data[idField], data?.cntrId, data?.cntrSeq);
        }}
      >
        {data[nameField]}
      </Button>
    );
  };
};

const renderCarManagerDetailButton = (onClick: (carmanId?: string) => void) => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    const { carmanNm, carmanId } = data;

    return (
      <Button
        type="default"
        size="small"
        onClick={() => {
          onClick(carmanId);
        }}
      >
        {carmanNm}
      </Button>
    );
  };
};

/**
 * 회원명 테이블 버튼
 */
export const createUserDetailRenderer = (
  onClick: (custId?: string, cntrId?: string, cntrSeq?: number) => void,
  idField = "custId"
) => ({
  [USER_DETAIL_ACTION]: renderUserDetailButton(onClick, idField),
});

export const createUserDetailButtonCol = (field = "custNm") =>
  createTableCol(field, "회원명", FilterType.Text, {
    width: CW_MEDIUM,
    cellRenderer: USER_DETAIL_ACTION,
  });

/**
 * 카매니저 테이블 버튼
 */
export const createCarManagerDetailRenderer = (
  onClick: (carmanId?: string) => void
) => ({
  [CAR_MANAGER_DETAIL_ACTION]: renderCarManagerDetailButton(onClick),
});

export const createCarManagerDetailButtonCol = (field = "carmanNm") =>
  createTableCol(field, "담당 카매니저", FilterType.Text, {
    width: CW_MEDIUM,
    cellRenderer: CAR_MANAGER_DETAIL_ACTION,
  });

/**
 * 날짜 변환 포맷
 */

export const dateFormatRenderer = (key: string, withTime = false) => {
  return (params: any) => {
    const { data } = params;
    if (data[key]) return formatDateString(data[key], withTime);
    return "";
  };
};

/**
 * 원화 변환 포맷
 */

export const currencyFormatRenderer = (key: string) => {
  return (params: any) => {
    const { data } = params;

    if (data[key]) return formatCurrency(data[key]);
    return "";
  };
};

/**
 * 숫자 변환 포맷
 */

export const numberFormatRenderer = (key: string) => {
  return (params: any) => {
    const { data } = params;

    if (data[key] !== null || data[key] !== undefined)
      return formatNumber(data[key]);
    return "";
  };
};

/**
 * % 포맷
 */
export const rateFormatRenderer = (key: string) => {
  return (params: any) => {
    const { data } = params;
    if (data[key]) return `${data[key]}%`;
    return "";
  };
};

/**
 * TAG 관리 렌더러 (PC)
 */

export const pcTagCellRenderer = ({ data }: ICellRendererParams) => {
  const tagMaster = data as TagMaster;
  const { pcTagTxt, pcTagStyl } = tagMaster;
  return (
    <Tag
      style={{
        ...getPcTagStyles(pcTagStyl as TagStyleCode),
      }}
    >
      {[TAG_STYLE_CD_11].includes(pcTagStyl) && <Image src={PromotionIcon} alt={"프로모션 로고"} width={12} height={12}  style={{marginRight:"4px",position:"relative",top:"1px"}} preview={false} />}
      {pcTagTxt}
    </Tag>
  );
};

export const mobileTagRenderer = ({ data }: ICellRendererParams) => {
  const tagMaster = data as TagMaster;
  const { mobTagTxt, mobTagStyl } = tagMaster;
  return (
    <Tag
      style={{
        ...getMobileTagStyles(mobTagStyl as TagStyleCode),
      }}
    >
      {[TAG_STYLE_CD_11].includes(mobTagStyl) && <Image src={PromotionIcon} alt={"프로모션 로고"} width={12} height={12}  style={{marginRight:"4px",position:"relative",top:"1px"}} preview={false} />}
      {mobTagTxt}
    </Tag>
  );
};

/**
 * iamge render
 * 
 * @param imgUrl image URL
 * @param size image height px
 * @returns 
 */
export const imgRenderer = (imgUrl: string, size: number) => {
  return (
    <img src={imgUrl} style={{
      height: `${size}px`,
      objectFit: "cover"
    }} />
  );
};