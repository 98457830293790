import React, { useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import BannerGroup from "../../../../../models/BannerGroup";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import {
  editBannerUseYn,
  fetchBannerDetail,
} from "../../../../../apis/banners";
import BannerDetail from "../../../../../models/BannerDetail";
import { getErrorMessage } from "../../../../../utils/common-utils";
import TabLayout from "../../../../../components/shared/layout/TabLayout";
import TableLayout from "../../../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import BannerInfo from "../../../../../models/BannerDetail/BannerInfo";
import { createBannerCols, USE_YN_RENDERER } from "./utils/table-utils";
import {
  BANNER_POSITION_CD_MAIN,
  BANNER_POSITION_CD_SL_MAIN,
  BANNER_POSITION_CD_TP_MAIN,
  BANNER_POSITION_CD_UC_MAIN,
  BANNER_POSITION_CD_TB_MAIN,
  BANNER_POSITION_CD_TOSS_MAIN,
  BANNER_POSITION_CD_AUC_MAIN,
  BANNER_POSITION_CD_MC_MAIN,
  ENUM_BANNER_DMN_MOBILE,
  ENUM_BANNER_DMN_PC,
  ENUM_BANNER_DMN_TABLET,
} from "../../../../../constants/enums";
import { createActionCellRenderers } from "../../../../../utils/table-utils";
import BannerFormModal from "./pages/BannerFormModal";
import { renderSwitchCol } from "../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { showConfirm } from "../../../../../components/shared/feedback/Confirm";
import Image from "../../../../../components/shared/data-display/antd/Image";
import commonStrings from "../../../../../constants/strings";

interface Props extends ModalProps {
  banner?: BannerGroup;
}

const BannerDetailModal: React.FC<Props> = (props: Props) => {
  const { banner, visible, onCancel, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState<boolean>();
  const [bannerDetail, setBannerDetail] = useState<BannerDetail>();
  const [bannerPlatformCode, setBannerPlatformCode] = useState<string>();
  const [bannerFormModalVisible, setBannerFormModalVisible] = useState<
    boolean
  >();
  const [selectedBannerInfo, setSelectedBannerInfo] = useState<BannerInfo>();
  const [isMainBanner, setIsMainBanner] = useState(false);
  const [cols, setCols] = useState(createBannerCols());

  const onModalOpen = async () => {
    if (banner) {
      const isMainBanner =
        banner.bnnrId === BANNER_POSITION_CD_MAIN ||
        banner.bnnrId === BANNER_POSITION_CD_SL_MAIN || // TODO: 신차 12개월 상품
        banner.bnnrId === BANNER_POSITION_CD_UC_MAIN ||
        banner.bnnrId === BANNER_POSITION_CD_TP_MAIN ||
        banner.bnnrId === BANNER_POSITION_CD_TB_MAIN ||
        banner.bnnrId === BANNER_POSITION_CD_TOSS_MAIN ||
        banner.bnnrId === BANNER_POSITION_CD_MC_MAIN ||
        banner.bnnrId === BANNER_POSITION_CD_AUC_MAIN;
      setIsMainBanner(isMainBanner);
      setCols(createBannerCols(isMainBanner));
      await requestFetchBannerDetail(banner.bnnrId);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchBannerDetail = async (
    bnnrId: string,
    dataFetching = true
  ) => {
    setDataFetching(dataFetching);
    try {
      const bannerDetail = await fetchBannerDetail(bnnrId);
      setBannerDetail(bannerDetail);
    } catch (e) {
      // alertError(getErrorMessage(e));
      setBannerDetail(new BannerDetail());
    } finally {
      setDataFetching(false);
    }
  };

  const requestUpdateUseYn = async (bnnrSeq: string, useYn: string) => {
    try {
      await editBannerUseYn(bnnrSeq, useYn);
      if (banner) await requestFetchBannerDetail(banner.bnnrId, false);
      alertSuccess("배너 사용 상태가 변경되었습니다");
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const getModalTitle = () => {
    if (banner) {
      return banner.bnnrDmn;
    }
    return "";
  };

  /**
   * Event Actions
   */

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    const bannerInfo = data as BannerInfo;
    setSelectedBannerInfo(bannerInfo);
    setBannerFormModalVisible(true);
  };

  const handleUseYnChange = (checked: boolean, data: BannerInfo) => {
    showConfirm(
      checked ? commonStrings.CONFIRM_USE_ON : commonStrings.CONFIRM_USE_OFF,
      checked
        ? commonStrings.CONFIRM_CONTENT_USE_ON
        : commonStrings.CONFIRM_CONTENT_USE_OFF,
      async () => {
        await requestUpdateUseYn(data.bnnrSeq, checked ? "Y" : "N");
      }
    );
  };

  const renderImageCol = () => {
    return (props: ICellRendererParams) => {
      const { data } = props;
      const banner = data as BannerInfo;

      return <Image src={banner.fileUrl} height={50} />;
    };
  };

  /**
   * Render Helpers
   */

  const renderBannerList = (type: "pc" | "mobile" | "tablet") => {
    if (bannerDetail) {
      let rows: Array<BannerInfo> = [];
      let totalCount = 0;
      if (type === "pc") {
        rows = bannerDetail.webList;
        totalCount = bannerDetail.webListCnt;
      }
      if (type === "mobile") {
        rows = bannerDetail.mobileList;
        totalCount = bannerDetail.mobileListCnt;
      }
      if (type === "tablet") {
        rows = bannerDetail.tabletList;
        totalCount = bannerDetail.tabletListCnt;
      }

      return (
        <TableLayout
          hideExcelAction
          hideFilterAction
          title="배너 리스트"
          totalCount={totalCount}
          rightActions={[
            {
              title: "배너 추가",
              onClick: () => {
                setSelectedBannerInfo(undefined);
                if (type === "pc") setBannerPlatformCode(ENUM_BANNER_DMN_PC);
                else if (type === "tablet")
                  setBannerPlatformCode(ENUM_BANNER_DMN_TABLET);
                else if (type === "mobile")
                  setBannerPlatformCode(ENUM_BANNER_DMN_MOBILE);

                setBannerFormModalVisible(true);
              },
            },
          ]}
          methodName={`/admin/banners/${banner?.bnnrId}`}
        >
          <ClientRowTable
            cols={cols}
            rowData={rows}
            rowHeight={50}
            frameworkComponents={{
              [USE_YN_RENDERER]: renderSwitchCol("useYn", handleUseYnChange),
              ...createActionCellRenderers(undefined, handleEditClick, {
                hideDeleteButton: true,
              }),
              imageRenderer: renderImageCol(),
            }}
          />
        </TableLayout>
      );
    }
    return "";
  };
  const renderTabs = () => {
    if (bannerDetail) {
      const tabs = [
        {
          title: `PC(${bannerDetail.webListCnt})`,
          key: "1",
          content: renderBannerList("pc"),
        },
        {
          title: `태블릿(${bannerDetail.tabletListCnt})`,
          key: "2",
          content: renderBannerList("tablet"),
        },
        {
          title: `모바일(${bannerDetail.mobileListCnt})`,
          key: "3",
          content: renderBannerList("mobile"),
        },
      ];
      return (
        <div>
          <TabLayout
            tabInfos={tabs}
            style={{
              paddingTop: "16px",
              minHeight: "65vh",
            }}
          />
        </div>
      );
    }

    return "";
  };

  return (
    <Modal
      size="large"
      bodyStyle={{
        height: "80vh",
        maxHeight: "80vh",
      }}
      footer={null}
      title={getModalTitle()}
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
    >
      {dataFetching ? renderLoading() : renderTabs()}
      <BannerFormModal
        isMainBanner={isMainBanner}
        bannerDmnId={bannerPlatformCode}
        bannerId={banner?.bnnrId}
        bannerInfo={selectedBannerInfo}
        visible={bannerFormModalVisible}
        onCancel={() => {
          setBannerFormModalVisible(false);
        }}
        onDataChange={async () => {
          if (banner) await requestFetchBannerDetail(banner.bnnrId, false);
          if (onDataChange) onDataChange();
        }}
      />
    </Modal>
  );
};

BannerDetailModal.defaultProps = {};
export default BannerDetailModal;
