import React, { useState } from "react";
import { Col, DatePicker, Row } from "antd";
import { useFormik } from "formik";
import { Collapse } from "react-collapse";
import Button from "../../general/antd/Button";
import FormItem from "../../data-entry/antd/FormItem";
import { FormOption } from "../../data-entry/FormBuilder/types";
import Input from "../../data-entry/antd/Input";
import RadioDateRangeFilter from "../../data-display/Tables/components/ServerFilters/RadioDateRangeFilter";
import { FilterElementType, FilterFormInfo } from "./types";
import {
  AppliedFilterTag,
  FilterActionWrapper,
  FilterFooter,
  FilterFormLayout,
  filterLayout,
  FilterResultWrapper,
} from "./style";
import Select from "../../data-entry/antd/Select";
import InputNumber from "../../data-entry/antd/InputNumber";
import DateRangePicker from "../../data-entry/antd/DateRangePicker";
import moment, { isDate } from "moment";

interface StyleInfo {
  span?: number;
  isModalLabel?: boolean;
}

interface TableLayoutFilterProps {
  children?: React.ReactNode | React.FC;
  forms?: Array<FilterFormInfo>;
  formInitialValues?: any;
  onSearchSubmit?: (values: any) => void;
  filterValues?: Record<string, any>;
  styleInfo?: StyleInfo;
}

const TableFilterLayout: React.FC<TableLayoutFilterProps> = (
  props: TableLayoutFilterProps
) => {
  const {
    forms,
    formInitialValues = {},
    onSearchSubmit,
    filterValues = {},
    styleInfo,
  } = props;
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: (values) => {
      if (onSearchSubmit) onSearchSubmit(values);
    },
    onReset: (values) => {
      if (onSearchSubmit) onSearchSubmit(values);
    },
  });

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  const handleReset = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    formik.setValues(formInitialValues);
    formik.handleSubmit();
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  /**
   * Render Helpers
   */

  const renderFormElement = (
    key: string,
    type: FilterElementType,
    onChange?: (value: any) => void,
    options?: Array<FormOption>,
    placeholder?: string,
    label?: string,
    extraOptions = {}
  ) => {
    const { values, setFieldValue } = formik;
    const value = values[key];

    switch (type) {
      case FilterElementType.Input: {
        return (
          <Input
            value={value}
            onChange={(e) => {
              const { target } = e;
              const { value } = target;
              setFieldValue(key, value);
            }}
            placeholder={placeholder}
          />
        );
      }

      case FilterElementType.NumberInput:
        return (
          <InputNumber
            style={{
              width: "100%",
            }}
            value={value}
            onChange={(value) => {
              setFieldValue(key, value);
            }}
            placeholder={placeholder}
          />
        );

      case FilterElementType.RadioDateRangePicker:
        return (
          <RadioDateRangeFilter
            key={key}
            label={label}
            options={options}
            value={value}
            onDateChange={(value) => {
              setFieldValue(key, value);
            }}
          />
        );

      case FilterElementType.Select:
        return (
          <Select
            key={key}
            options={options}
            value={value}
            onChange={(value) => {
              setFieldValue(key, value);
            }}
          />
        );

      case FilterElementType.DateRangePicker: {
        let allowClear = false;
        // @ts-ignore
        if (extraOptions && extraOptions.allowClear) {
          // @ts-ignore
          allowClear = extraOptions.allowClear;
        }

        return (
          <DateRangePicker
            style={{
              width: "100%",
            }}
            key={key}
            value={value}
            onChange={(value) => {
              setFieldValue(key, value);
            }}
            allowClear={allowClear}
            {...extraOptions}
          />
        );
      }

      case FilterElementType.DatePicker: {
        let allowClear = false;
        // @ts-ignore
        if (extraOptions && extraOptions.allowClear) {
          // @ts-ignore
          allowClear = extraOptions.allowClear;
        }

        return (
          <DatePicker
            style={{
              width: "100%",
            }}
            key={key}
            value={value}
            onChange={(value) => {
              setFieldValue(key, value);
            }}
            allowClear={allowClear}
            {...extraOptions}
          />
        );
      }

      case FilterElementType.NumberRange: {
        let start: string | number = "";
        let end: string | number = "";

        if (value && value.length > 1) {
          const [startValue, endValue] = value;
          start = startValue;
          end = endValue;
        }

        return (
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <InputNumber
              style={{
                borderEndEndRadius: 0,
                borderStartEndRadius: 0,
                // borderRight: 0,
                flex: 1,
              }}
              min={0}
              placeholder="최소"
              value={start}
              onChange={(value) => {
                let updatedValue = [value, end];
                setFieldValue(key, updatedValue);
              }}
            />
            <Input
              disabled
              style={{
                width: 30,
                borderLeft: 0,
                borderRight: 0,
                pointerEvents: "none",
                borderRadius: 0,
                backgroundColor: "white",
              }}
              placeholder="~"
            />
            <InputNumber
              style={{
                borderStartStartRadius: 0,
                borderEndStartRadius: 0,
                // borderLeft: 0,
                flex: 1,
              }}
              min={0}
              value={end}
              placeholder="최대"
              onChange={(value) => {
                let updatedValue = [start, value];
                setFieldValue(key, updatedValue);
              }}
              onBlur={(e) => {
                let value: string | number = e.target.value;
                // 빈 텍스트시 그대로 입력
                if (!value) setFieldValue(key, [start, value]);
                else {
                  const numberValue = Number(value);
                  let updatedValue: Array<number | string> = [
                    start,
                    numberValue,
                  ];
                  if (numberValue < start) {
                    updatedValue = [numberValue, numberValue];
                  }
                  setFieldValue(key, updatedValue);
                }
              }}
            />
          </div>
        );
      }

      default:
        return "";
    }
  };

  const renderFilterForms = () => {
    if (forms) {
      return forms.map(
        ({
          key,
          label,
          type,
          onChange,
          options,
          placeholder,
          span = 6,
          render,
          extraOptions,
        }) => {
          if (type === FilterElementType.Custom && render) {
            return (
              <Col span={span} key={key}>
                {render(formik)}
              </Col>
            );
          }

          if (type === FilterElementType.RadioDateRangePicker) {
            return renderFormElement(
              key,
              type,
              onChange,
              options,
              placeholder,
              label
            );
          }

          return (
            <Col span={styleInfo?.span ?? 6} key={key}>
              <FormItem label={label} {...filterLayout} isModalLabel={styleInfo?.isModalLabel}>
                {renderFormElement(
                  key,
                  type,
                  onChange,
                  options,
                  placeholder,
                  undefined,
                  extraOptions
                )}
              </FormItem>
            </Col>
          );
        }
      );
    }
    return "";
  };

  const renderAppliedFilterResult = () => {
    if (filterValues) {
      return Object.keys(filterValues).map((key) => {
        const value = filterValues[key];
        if (typeof value === "string" && value) {
          let valueVm = value;
          if (forms) {
            const form = forms.filter((form) => {
              return form.key === key;
            })[0];
            if (form) {
              if (form.type === FilterElementType.Select) {
                if (form.options) {
                  const filteredOption = form.options.filter(({ value }) => {
                    return value === valueVm;
                  })[0];
                  if (filteredOption) {
                    valueVm = filteredOption.label;
                  }
                }
              }
            }
          }

          return <AppliedFilterTag key={key}>{valueVm}</AppliedFilterTag>;
        }

        if (typeof value === "number") {
          return <AppliedFilterTag key={key}>{value}</AppliedFilterTag>;
        }

        if (Array.isArray(value) && value.length > 1) {
          const [startValue, endValue] = value;

          // Date Type
          if (startValue && startValue.format && endValue && endValue.format) {
            return (
              <AppliedFilterTag key={key}>{`${startValue.format(
                "YY.MM.DD"
              )}~${endValue.format("YY.MM.DD")}`}</AppliedFilterTag>
            );
          }

          // Number Range
          if (typeof startValue === "number" && typeof endValue === "number") {
            return (
              <AppliedFilterTag
                key={key}
              >{`${startValue}~${endValue}`}</AppliedFilterTag>
            );
          }
        }

        if (typeof value === "object" && moment(value) && value) {
          return (
            <AppliedFilterTag key={key}>
              {moment(value).format("YYYY-MM-DD")}
            </AppliedFilterTag>
          );
        }
        return "";
      });
    }
    return "";
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Collapse isOpened={isFilterOpen}>
          <FilterFormLayout>
            <Row gutter={[40, 16]} align="middle">
              {renderFilterForms()}
            </Row>
          </FilterFormLayout>
        </Collapse>
        <FilterFooter>
          <FilterResultWrapper>
            <span
              style={{
                marginRight: "16px",
              }}
            >
              적용필터
            </span>
            {renderAppliedFilterResult()}
          </FilterResultWrapper>
          <FilterActionWrapper>
            <Button
              type="default"
              onClick={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
            >
              {isFilterOpen ? "필터 숨김" : "필터 열기"}
            </Button>
            <Button
              style={{
                marginLeft: "8px",
              }}
              type="default"
              onClick={handleReset}
            >
              필터 초기화
            </Button>
            <Button
              htmlType="submit"
              style={{
                marginLeft: "8px",
              }}
              onClick={handleSubmit}
            >
              검색하기
            </Button>
          </FilterActionWrapper>
        </FilterFooter>
      </form>
    </div>
  );
};

TableFilterLayout.defaultProps = {
  forms: [],
};
export default TableFilterLayout;
