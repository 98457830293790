import UploadCode from "../models/UploadCode";

export const SEARCH_OPTION_ALL = {
  label: "전체",
  value: "",
};

/**
 * 사용자 권한
 */

// 사업부
export const ENUM_DTY_ID_BIZ = "202104190001";

// 카매니저

// 고객센터

/**
 * 사업자 인증상태 옵션
 */
// 미인증
export const ENUM_BIZ_AUTH_N = "N";
// 인증 요청
export const ENUM_BIZ_AUTH_R = "R";
// 인증 완료
export const ENUM_BIZ_AUTH_Y = "Y";
// KIS 인증 VM
export const ENUM_KIS_OPT_VALUE_VM = "kis";

export const BIZ_AUTH_TRANSLATION: Record<string, string> = {
  [ENUM_KIS_OPT_VALUE_VM]: "인증성공",
  [ENUM_BIZ_AUTH_N]: "요청전",
  [ENUM_BIZ_AUTH_R]: "인증요청",
  [ENUM_BIZ_AUTH_Y]: "인증완료",
};

export const ENUM_BIZ_AUTH_OPTIONS = [
  {
    label: BIZ_AUTH_TRANSLATION[ENUM_KIS_OPT_VALUE_VM],
    value: ENUM_KIS_OPT_VALUE_VM,
  },
  {
    label: BIZ_AUTH_TRANSLATION[ENUM_BIZ_AUTH_N],
    value: ENUM_BIZ_AUTH_N,
  },
  {
    label: BIZ_AUTH_TRANSLATION[ENUM_BIZ_AUTH_R],
    value: ENUM_BIZ_AUTH_R,
  },
  {
    label: BIZ_AUTH_TRANSLATION[ENUM_BIZ_AUTH_Y],
    value: ENUM_BIZ_AUTH_Y,
  },
];

/**
 * 카매니저 요청 및 거절 상태 코드
 */

// 요청
export const ENUM_CAR_MANAGER_TRANSFER_STATE_REQUEST = "A31001";
// 승인
export const ENUM_CAR_MANAGER_TRANSFER_STATE_ACCEPT = "A31002";
// 거절
export const ENUM_CAR_MANAGER_TRANSFER_STATE_REJECT = "A31003";
// 취소
export const ENUM_CAR_MANAGER_TRANSFER_STATE_CANCEL = "A31004";

// 인도일정 미확보
export const ENUM_CAR_MANAGER_TRANSFER_REJECT_CD_NO_AVAIL_SCHEDULE = "A30001";
// 휴가
export const ENUM_CAR_MANAGER_TRANSFER_REJECT_CD_VACATION = "A30002";
// 업무변경
export const ENUM_CAR_MANAGER_TRANSFER_REJECT_CD_CHANGE_ROLE = "A30003";

export const ENUM_CAR_MANAGER_TRANSFER_STATE_OPTIONS = [
  // {
  //   label: "요청",
  //   value: ENUM_CAR_MANAGER_TRANSFER_STATE_REQUEST,
  // },
  {
    label: "승인",
    value: ENUM_CAR_MANAGER_TRANSFER_STATE_ACCEPT,
  },
  {
    label: "거절",
    value: ENUM_CAR_MANAGER_TRANSFER_STATE_REJECT,
  },
  {
    label: "취소",
    value: ENUM_CAR_MANAGER_TRANSFER_STATE_CANCEL,
  },
];

export const ENUM_CAR_MANAGER_TRANSFER_REJECT_CD_OPTIONS = [
  {
    label: "인도일정 미확보",
    value: ENUM_CAR_MANAGER_TRANSFER_REJECT_CD_NO_AVAIL_SCHEDULE,
  },
  {
    label: "휴가",
    value: ENUM_CAR_MANAGER_TRANSFER_REJECT_CD_VACATION,
  },
  {
    label: "업무변경",
    value: ENUM_CAR_MANAGER_TRANSFER_REJECT_CD_CHANGE_ROLE,
  },
];

/**
 * 공지사항 공통 코드
 */
// 공지/안내 사항
export const ENUM_NOTICE_CD_NOTICE = "A20000";
// 주요 상품 안내
export const ENUM_NOTICE_CD_PRODUCT = "A20100";
// 변경 사항 안내
export const ENUM_NOTICE_CD_UPDATES = "A20200";
// 시스템 점검 안내
export const ENUM_NOTICE_CD_SYSTEM = "A20300";
// 일반 안내
export const ENUM_NOTICE_CD_COMMON = "A20400";
// 이벤트 안내
export const ENUM_NOTICE_CD_EVENT = "A20500";
// 기타 안내
export const ENUM_NOTICE_CD_ETC = "A20600";

export const ENUM_NOTICE_CD_OPTIONS = [
  {
    label: "주요 상품 안내",
    value: ENUM_NOTICE_CD_PRODUCT,
  },
  {
    label: "변경 사항 안내",
    value: ENUM_NOTICE_CD_UPDATES,
  },
  {
    label: "시스템 점검 안내",
    value: ENUM_NOTICE_CD_SYSTEM,
  },
  {
    label: "일반 안내",
    value: ENUM_NOTICE_CD_COMMON,
  },
  {
    label: "이벤트 안내",
    value: ENUM_NOTICE_CD_EVENT,
  },
  {
    label: "기타 안내",
    value: ENUM_NOTICE_CD_ETC,
  },
];

/**
 * 메뉴 권한 아이디
 */
export const ENUM_MENU_PRV_READ = "P10001";
export const ENUM_MENU_PRV_CREATE = "P10002";
export const ENUM_MENU_PRV_UPDATE = "P10003";
export const ENUM_MENU_PRV_DELETE = "P10004";
/**
 * 메뉴 아이디 - 1차
 */

// 회원
export const ENUM_UPPER_MENU_ID_CUSTOMER = "MENU_010000";
// 견적 계약
export const ENUM_UPPER_MENU_ID_EST_CONTRACT = "MENU_020000";
// 차량판매
export const ENUM_UPPER_MENU_ID_SELLING_CAR = "MENU_030000";
// 마케팅
export const ENUM_UPPER_MENU_ID_MARKETING = "MENU_040000";
// 고객지원
export const ENUM_UPPER_MENU_ID_CS = "MENU_050000";
// 카매니저
export const ENUM_UPPER_MENU_ID_CAR_MANAGER = "MENU_060000";
// 서비스 운영 관리
export const ENUM_UPPER_MENU_ID_SERVICE_MANAGER = "MENU_070000";
// 보고서
export const ENUM_UPPER_MENU_ID_REPORT = "MENU_080000";

/**
 * 메뉴 아이디 - 2차
 */

// 회원관리
export const ENUM_MENU_ID_CUSTOMER_MANAGER = "MENU_010100";
// 회원이관
export const ENUM_MENU_ID_CAR_MANAGER_TRANSFER = "MENU_010200";
// 견적관리
export const ENUM_MENU_ID_ESTIMATION_MANAGER = "MENU_020300";
// 계약관리(렌탈형)
export const ENUM_MENU_ID_RENT_CONTRACT_MANAGER = "MENU_020400";
// 계약관리(인수형)
export const ENUM_MENU_ID_TAKE_OVER_CONTRACT_MANAGER = "MENU_020500";
// 계약변경요청관리
export const ENUM_MENU_ID_CONTRACT_UPDATE_REQUEST_MANAGER = "MENU_020600";
// 할인마스터관리
export const ENUM_MENU_ID_DC_MASTER = "MENU_020700";
// 차량정보 관리
export const ENUM_MENU_ID_CAR_INFO_MANAGER = "MENU_030800";
// 온라인 상품관리(신차장기)
export const ENUM_MENU_ID_NC_CATALOG_MANAGER = "MENU_030900";
// 온라인 상품관리(타보중)
export const ENUM_MENU_ID_UC_CATALOG_MANAGER = "MENU_031000";
// 보중보험 수수료율 관리
export const ENUM_MENU_ID_INS_FEE_MANAGER = "MENU_031100";
// 신차발주 납기 관리
export const ENUM_MENU_ID_NC_ORDER_PLACE_DELIVERY_INFO_MANAGER = "MENU_031200";
// 메인상품 가격기준 관리
export const ENUM_MENU_ID_DEFAULT_RENT_OPTION_MANAGER = "MENU_031300";
// 이벤트 관리
export const ENUM_MENU_ID_EVENT_MANAGER = "MENU_041400";
// 배너 관리
export const ENUM_MENU_ID_BANNER_MANAGER = "MENU_041500";
// 후기 관리
export const ENUM_MENU_ID_REVIEW_MANAGER = "MENU_041600";
// EzyDirect 친구초대 프로그램
export const ENUM_MENU_ID_SK_FRIENDS = "MENU_041700";
// 지능형 상담
export const ENUM_MENU_ID_CONSULTING_DATA_MANAGER = "MENU_041800";
// 상담신청 관리
export const ENUM_MENU_ID_CONSULTING_REQUEST_MANAGER = "MENU_041900";
// 공지사항
export const ENUM_MENU_ID_NOTICE_MANAGER = "MENU_052000";
// FAQ
export const ENUM_MENU_ID_FAQ_MANAGER = "MENU_052100";
// 인도스케줄 관리
export const ENUM_MENU_ID_CAR_MANAGER_SCHEDULER = "MENU_062200";
// 대무 설정
export const ENUM_MENU_ID_CAR_MANAGER_REPLACEMENT_MANAGER = "MENU_062300";
// 템플릿 문자 전송
export const ENUM_MENU_ID_CAR_MANAGER_SMS_TEMPLATE_MANAGER = "MENU_062400";
// 권한 관리
export const ENUM_MENU_ID_AUTHORIZATION_MANAGER = "MENU_072500";
// 카매니저 관리
export const ENUM_MENU_ID_CAR_MANAGER_MANAGER = "MENU_072600";
// 문자전송 템플릿 관리
export const ENUM_MENU_ID_SMS_TEMPLATE_MANAGER = "MENU_072700";
// 만족도 조사 관리
export const ENUM_MENU_ID_CUSTOMER_SURVEY_DATA_MANAGER = "MENU_072800";
// 취소 위약금 관리
export const ENUM_MENU_ID_CANCEL_PENALTY_MANAGER = "MENU_072900";
// 온라인 회원 분석
export const ENUM_MENU_ID_CUSTOMER_STATISTICS_REPORT = "MENU_083000";
// 견적 분석
export const ENUM_MENU_ID_ESTIMATION_STATISTICS_REPORT = "MENU_083100";
// 렌탈 상품 계약실적
export const ENUM_MENU_ID_RENT_PRODUCT_SALES_PERFORMANCE = "MENU_083200";
// 인수형 상품 계약실적
export const ENUM_MENU_ID_SELLING_PRODUCT_SALES_PERFORMANCE = "MENU_083300";
// 내차 팔기 실적 상품 계약실적
export const ENUM_MENU_ID_SELLING_CAR_SALES_PERFORMANCE = "MENU_083400";
// 포인트 사용 실적
export const ENUM_MENU_ID_SELLING_POINT_USAGE_REPORT = "MENU_083500";
// 신용등급 분석
export const ENUM_MENU_ID_CREDIT_RATING_RESEARCH_REPORT = "MENU_083600";
// 소개프로그램 효과 분석
export const ENUM_MENU_ID_SK_FRIENDS_REPORT = "MENU_083700";
// 만족도 조사 분석(평가)
export const ENUM_MENU_ID_CUSTOMER_SURVEY_REPORT = "MENU_083800";

// 세션 만료 오류 코드
export const ERR_CD_SESSION_EXPIRED = "240003";

// 고객 구분 코드
export const ENUM_MODE_CUST_CLS_NORMAL = "A02001";
export const ENUM_MODE_CUST_CLS_PRIATE_BUSINESS = "A02002";
export const ENUM_MODE_CUST_CLS_CORP = "A02003";

export const CUST_CLS_OPTIONS = [
  {
    label: "개인",
    value: ENUM_MODE_CUST_CLS_NORMAL,
  },
  {
    label: "개인사업자",
    value: ENUM_MODE_CUST_CLS_PRIATE_BUSINESS,
  },
  {
    label: "법인",
    value: ENUM_MODE_CUST_CLS_CORP,
  },
];

// 휴대폰번호 인증 목적 코드
export const ENUM_AIM_CD_ADMIN = "A22006";

// 견적 상태 코드
export const ENUM_EST_AVAIL = "A05001";
export const ENUM_EST_DELETE = "A05002";
export const ENUM_EST_EXPIRED = "A05003";

// 신차/ 중고차 판매 유형 코드
export const ENUM_PROD_TYPE_ORDER_PLACE = "A45001";
export const ENUM_PROD_TYPE_TODAY_DELIVERY = "A45002";
export const ENUM_PROD_TYPE_FAST_DELIVERY = "A45003";
export const ENUM_PROD_TYPE_TAGO_PAY = "A45005";
export const ENUM_PROD_TYPE_UC_SELECT = "A45006";
export const ENUM_PROD_TYPE_UC_RETURN = "A45007";
export const ENUM_PROD_TYPE_MC = "A45011";
export const ENUM_PROD_TYPE_UC_JEJU = "A45008";
export const ENUM_PROD_TYPE_TAGO_BUY = "A45009";
export const ENUM_PROD_TYPE_UC_TAGO_PAY = "A45010";
/** TODO: 신차 12개월 상품 */
export const ENUM_PROD_TYPE_TODAY_SLIM = "A45012";
export const ENUM_PROD_TYPE_FAST_SLIM = "A45013";

export const OPTIONS_PROD_TYPE = [
  {
    label: "신차",
    value: ENUM_PROD_TYPE_ORDER_PLACE,
  },
  {
    label: "빠른배송",
    value: ENUM_PROD_TYPE_FAST_DELIVERY,
  },
  {
    label: "당일배송",
    value: ENUM_PROD_TYPE_TODAY_DELIVERY,
  },
  {
    label: "타고페이",
    value: ENUM_PROD_TYPE_TAGO_PAY,
  },
  {
    label: "[중고차]선택형",
    value: ENUM_PROD_TYPE_UC_SELECT,
  },
  {
    label: "[중고차]반납형",
    value: ENUM_PROD_TYPE_UC_RETURN,
  },
  {
    label: "월렌트",
    value: ENUM_PROD_TYPE_MC,
  },
  {
    label: "[중고차]제주전용",
    value: ENUM_PROD_TYPE_UC_JEJU,
  },
  {
    label: "[중고차]타고페이",
    value: ENUM_PROD_TYPE_UC_TAGO_PAY,
  },
];

export const OPTIONS_CUST_CLASS = [
  {
    label: "개인",
    value: "105001",
  },
  {
    label: "개인사업자",
    value: "105004",
  },
  {
    label: "법인",
    value: "105002",
  },
];

export const OPTIONS_YN = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "Y",
    value: "Y",
  },
  {
    label: "N",
    value: "N",
  },
];

export const OPTION_TB_YN = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "타고바이",
    value: "Y",
  },
  {
    label: "-",
    value: "N",
  },
];

// 월렌트 등록 여부
export const OPTION_MC_YN = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "월렌트",
    value: "Y",
  },
  {
    label: "-",
    value: "N",
  },
];

export const OPTIONS_MC_RSV_YN = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "예약가능",
    value: "Y",
  },
  {
    label: "불가능",
    value: "N",
  },
];

// TODO 월렌트 상품 타이틀 추가
export const PROD_TYPE_TRANSLATIONS: Record<string, string> = {
  [ENUM_PROD_TYPE_ORDER_PLACE]: "신차",
  [ENUM_PROD_TYPE_FAST_DELIVERY]: "빠른",
  [ENUM_PROD_TYPE_TODAY_DELIVERY]: "당일",
  [ENUM_PROD_TYPE_TAGO_PAY]: "타고페이",
  [ENUM_PROD_TYPE_UC_SELECT]: "[중고차]선택형",
  [ENUM_PROD_TYPE_UC_RETURN]: "[중고차]반납형",
  [ENUM_PROD_TYPE_MC]: "[월렌트]",
  [ENUM_PROD_TYPE_UC_JEJU]: "[중고차]제주전용",
  [ENUM_PROD_TYPE_UC_TAGO_PAY]: "[중고차]타고페이",
};

export const PROD_TYPE_COLOR_TRANSLATIONS: Record<string, string> = {
  [ENUM_PROD_TYPE_ORDER_PLACE]: "#4483FD",
  [ENUM_PROD_TYPE_FAST_DELIVERY]: "#FF7A00",
  [ENUM_PROD_TYPE_TODAY_DELIVERY]: "#F2434A",
};

// 날짜 범위 코드
export const ENUM_DATE_FILTER_TODAY = "today";
export const ENUM_DATE_FILTER_1W = "1w";
export const ENUM_DATE_FILTER_1M = "1m";
export const ENUM_DATE_FILTER_3M = "3m";
export const ENUM_DATE_FILTER_YESTERDAY = "1dAgo";
export const ENUM_DATE_FILTER_1Y = "1y";

// 계약관리 차량구분 코드
export const ENUM_CAR_TYPE_NC = "S0001";
export const ENUM_CAR_TYPE_UC = "S0002";

export const FILTER_OPTIONS_CONTRACT_CAR_TYPE = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "신차",
    value: ENUM_CAR_TYPE_NC,
  },
  {
    label: "중고차",
    value: ENUM_CAR_TYPE_UC,
  },
];

export const FILTER_OPTIONS_SRVC_ID = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "신차",
    value: ENUM_CAR_TYPE_NC,
  },
  {
    label: "중고차",
    value: ENUM_CAR_TYPE_UC,
  },
];

/* 판매유형 목록 */
export const FILTER_OPTIONS_NC_PROD_TYPE = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "신차",
    value: ENUM_PROD_TYPE_ORDER_PLACE,
  },
  {
    label: "빠른배송",
    value: ENUM_PROD_TYPE_FAST_DELIVERY,
  },
  {
    label: "당일배송",
    value: ENUM_PROD_TYPE_TODAY_DELIVERY,
  },
  {
    label: "1년-당일배송(가칭)",
    value: ENUM_PROD_TYPE_TODAY_SLIM,
  },
  {
    label: "1년-빠른배송(가칭)",
    value: ENUM_PROD_TYPE_FAST_SLIM,
  },
  {
    label: "타고페이",
    value: ENUM_PROD_TYPE_TAGO_PAY,
  },
  {
    label: "[중고차]선택형",
    value: ENUM_PROD_TYPE_UC_SELECT,
  },
  {
    label: "[중고차]반납형",
    value: ENUM_PROD_TYPE_UC_RETURN,
  },
  {
    label: "[중고차]월렌트",
    value: ENUM_PROD_TYPE_MC,
  },
  {
    label: "[중고차]제주전용",
    value: ENUM_PROD_TYPE_UC_JEJU,
  },
  {
    label: "[중고차]타고바이",
    value: ENUM_PROD_TYPE_TAGO_BUY,
  },
  {
    label: "[중고차]타고페이",
    value: ENUM_PROD_TYPE_UC_TAGO_PAY,
  },
];

export const FILTER_OPTIONS_EST_STATES = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "유효견적",
    value: ENUM_EST_AVAIL,
  },
  {
    label: "삭제견적",
    value: ENUM_EST_DELETE,
  },
  {
    label: "만료견적",
    value: ENUM_EST_EXPIRED,
  },
];

// Mode 운전자 범위 코드

export enum DriverRange {
  // 가족한정
  AllFamily = "A13001",
  // 1인 지정
  WithOne = "A13002",
  // 누구나
  AnyOne = "A13003",
  // 임직원
  Employees = "A13004",
  // 가족 (직계, 형제자매)
  Family = "A13005",
  // 가족(직계, 형제자매) + 1인추가
  FamilyWithOne = "A13006",
  // 가족(직계, 형제자매) + 2인추가
  FamilyWithTwo = "A13007",
  // 가족(직계, 형제자매) / 직원
  FamilyEmployees = "A13008",
  // 가족(직계, 형제자매) / 직원 + 1인추가
  FamilyEmployeesWithOne = "A13009",
  // 가족(직계, 형제자매) / 직원 + 2인추가
  FamilyEmployeesWithTwo = "A13010",
  // 임직원한정 (특약)
  EmployeesSpecial = "A13011",
  // 임직원한정 (특약미가입)
  EmployeesNonSpecial = "A13012",
  // 임직원/임원가족 + 1인 (특약미가입)
  EmployeesFamilyWithOne = "A13013",
  // 임직원/임원가족 + 2인 (특약미가입)
  EmployeesFamilyWithTwo = "A13014",
}

// Mode 약정주행거리 코드
export enum DrivingMileage {
  // 1만 Km 이하
  L10000KM = "A06001",
  L15000KM = "A06002",
  L20000KM = "A06003",
  L25000KM = "A06004",
  L30000KM = "A06005",
  L35000KM = "A06006",
  NO_LIMIT = "A06007",
  L40000KM = "A06008",
  L50000KM = "A06009",
  L60000KM = "A06010",
}

// Mode 계약기간 코드
export enum ContractTerm {
  // 18개월
  M18 = "A19001",
  M24 = "A19002",
  M30 = "A19003",
  M36 = "A19004",
  M48 = "A19005",
}

export const TABLE_DATE_FILTER_OPTIONS = [
  {
    label: "오늘",
    value: ENUM_DATE_FILTER_TODAY,
  },
  {
    label: "1주",
    value: ENUM_DATE_FILTER_1W,
  },
  {
    label: "1개월",
    value: ENUM_DATE_FILTER_1M,
  },
  {
    label: "3개월",
    value: ENUM_DATE_FILTER_3M,
  },
  {
    label: "1년",
    value: ENUM_DATE_FILTER_1Y,
  },
];

export const TABLE_DATE_FILTER_LIGHT_OPTIONS = [
  {
    label: "오늘",
    value: ENUM_DATE_FILTER_TODAY,
  },
  {
    label: "어제",
    value: ENUM_DATE_FILTER_YESTERDAY,
  },
  {
    label: "1주",
    value: ENUM_DATE_FILTER_1W,
  },
  {
    label: "1개월",
    value: ENUM_DATE_FILTER_1M,
  },
];

/**
 * Server Code
 */

// 개인사업자
export const ENUM_PRIVATE_BUSINESS_CODE = "A02002";
// 법인
export const ENUM_CORP_BUSINESS_CODE = "A02003";

export const UPLOAD_CODES: Record<string, UploadCode> = {
  NOTICE: {
    jobClsCd: "687000",
    idtfClsCd: "687001",
  },
  EVENT: {
    jobClsCd: "687000",
    idtfClsCd: "687099",
  },
  BANNER: {
    jobClsCd: "687000",
    idtfClsCd: "687097",
  },
  CAR_OPTION_PDF: {
    jobClsCd: "687102",
    idtfClsCd: "687102",
  },
  BIZ_IMAGE: {
    jobClsCd: "687103",
    idtfClsCd: "687103",
  },
};

/**
 * 카매니저 담당 지역 코드
 */

// 서울 강북
export const ENUM_SITE_CD_SEOUL_KB = "A23001";
// 서울 동대문
export const ENUM_SITE_CD_SEOUL_DDM = "A23002";
// 서울 서대문
export const ENUM_SITE_CD_SEOUL_SDM = "A23003";
// 서울 종로
export const ENUM_SITE_CD_SEOUL_JR = "A23004";
// 서울 강동
export const ENUM_SITE_CD_SEOUL_KD = "A23005";
// 서울 강남
export const ENUM_SITE_CD_SEOUL_KN = "A23006";
// 서울 강서
export const ENUM_SITE_CD_SEOUL_KS = "A23007";
// 서울 관악
export const ENUM_SITE_CD_SEOUL_KW = "A23008";
// 경기 고양
export const ENUM_SITE_CD_GG_GY = "A23009";
// 경기 북부
export const ENUM_SITE_CD_GG_NORTH = "A23010";
// 경기 동부
export const ENUM_SITE_CD_GG_EAST = "A23011";
// 경기 남부
export const ENUM_SITE_CD_GG_SOUTH = "A23012";
// 경기 서부
export const ENUM_SITE_CD_GG_WEST = "A23013";
// 경기 오산
export const ENUM_SITE_CD_GG_OS = "A23014";
// 인천
export const ENUM_SITE_CD_INCHEON = "A23015";
// 경북
export const ENUM_SITE_CD_KB = "A23016";
// 대구
export const ENUM_SITE_CD_DK = "A23017";
// 울산
export const ENUM_SITE_CD_WS = "A23018";
// 부산
export const ENUM_SITE_CD_BS = "A23019";
// 경남
export const ENUM_SITE_CD_KN = "A23020";
// 강원
export const ENUM_SITE_CD_GW = "A23021";
// 충청
export const ENUM_SITE_CD_CHCH = "A23022";
// 호남
export const ENUM_SITE_CD_HN = "A23023";
// 제주
export const ENUM_SITE_CD_JJ = "A23024";

export const OPTIONS_CAR_MANAGER_SITE_CD = [
  { label: "서울 강북", value: ENUM_SITE_CD_SEOUL_KB },
  { label: "서울 동대문", value: ENUM_SITE_CD_SEOUL_DDM },
  { label: "서울 서대문", value: ENUM_SITE_CD_SEOUL_SDM },
  { label: "서울 종로", value: ENUM_SITE_CD_SEOUL_JR },
  { label: "서울 강동", value: ENUM_SITE_CD_SEOUL_KD },
  { label: "서울 강남", value: ENUM_SITE_CD_SEOUL_KN },
  { label: "서울 강서", value: ENUM_SITE_CD_SEOUL_KS },
  { label: "서울 관악", value: ENUM_SITE_CD_SEOUL_KW },
  { label: "경기 고양", value: ENUM_SITE_CD_GG_GY },
  { label: "경기 북부", value: ENUM_SITE_CD_GG_NORTH },
  { label: "경기 동부", value: ENUM_SITE_CD_GG_EAST },
  { label: "경기 남부", value: ENUM_SITE_CD_GG_SOUTH },
  { label: "경기 서부", value: ENUM_SITE_CD_GG_WEST },
  { label: "경기 오산", value: ENUM_SITE_CD_GG_OS },
  { label: "인천", value: ENUM_SITE_CD_INCHEON },
  { label: "경북", value: ENUM_SITE_CD_KB },
  { label: "대구", value: ENUM_SITE_CD_DK },
  { label: "울산", value: ENUM_SITE_CD_WS },
  { label: "부산", value: ENUM_SITE_CD_BS },
  { label: "경남", value: ENUM_SITE_CD_KN },
  { label: "강원", value: ENUM_SITE_CD_GW },
  { label: "충청", value: ENUM_SITE_CD_CHCH },
  { label: "호남", value: ENUM_SITE_CD_HN },
  { label: "제주", value: ENUM_SITE_CD_JJ },
];

/**
 * 배너 플랫폼 코드
 */
export const ENUM_BANNER_DMN_PC = "A36001";
export const ENUM_BANNER_DMN_MOBILE = "A36002";
export const ENUM_BANNER_DMN_TABLET = "A36003";

/**
 * 할인 마스터
 */

// 정률 할인
export const ENUM_DC_TYPE_PERCENT = "A40001";
// 정액 할인
export const ENUM_DC_TYPE_VALUE = "A40002";

export const ENUM_DC_TYPE_OPTIONS = [
  {
    label: "할인(%)",
    value: ENUM_DC_TYPE_PERCENT,
  },
  {
    label: "할인(₩)",
    value: ENUM_DC_TYPE_VALUE,
  },
];

/**
 * 렌탈료조회 렌트옵션 코드
 */

export const ENUM_VM_DEPOSIT_INSURANCE = "보증보험";
export const ENUM_VM_DEPOSIT = "보증금";
export const ENUM_VM_F_DEPOSIT = "선수금";

// 계약 기간
export const ENUM_RENT_OPTION_CONTRACT_TERM = "A17001";

// 약정 주행거리
export const ENUM_RENT_OPTION_MILEAGE = "A17002";

// 운전자 범위
export const ENUM_RENT_OPTION_DRIVER_RANGE = "A17003";

// 대물
export const ENUM_RENT_OPTION_LIABILITY_COVERAGE = "A17007";

// 운전자 연령
export const ENUM_RENT_OPTION_DRIVER_AGE = "A17004";

// 정비서비스
export const ENUM_RENT_OPTION_FIX_SERVICE = "A17005";

// 대차서비스
export const ENUM_RENT_OPTION_RENT_SERVICE = "A17006";

/**
 * 렌탈료 할인 코드
 */

// 온라인 기본 할인
export const ENUM_DC_DEFAULT = "A16001";

export const ENUM_DC_1_CREDIT = "A16002";

export const ENUM_DC_2_CREDIT = "A16003";

export const ENUM_DC_3_CREDIT = "A16004";

export const ENUM_DC_4_CREDIT = "A16005";

export const ENUM_DC_5_CREDIT = "A16006";

export const ENUM_DC_6_CREDIT = "A16007";

export const ENUM_DC_7_CREDIT = "A16008";

export const ENUM_DC_RE_ORDER = "A16009";

export const ENUM_DC_NO_ACC = "A16010";

export const ENUM_DC_PROMOTION = "A16011";

export const ENUM_DC_EMPLOYEE = "A16012";

export const ENUM_DC_KIND_OPTIONS = [
  {
    label: "온라인 기본할인",
    value: ENUM_DC_DEFAULT,
  },
  {
    label: "온라인 특별할인 - 1등급",
    value: ENUM_DC_1_CREDIT,
  },
  {
    label: "온라인 특별할인 - 2등급",
    value: ENUM_DC_2_CREDIT,
  },
  {
    label: "온라인 특별할인 - 3등급",
    value: ENUM_DC_3_CREDIT,
  },
  {
    label: "온라인 특별할인 - 4등급",
    value: ENUM_DC_4_CREDIT,
  },
  {
    label: "온라인 특별할인 - 5등급",
    value: ENUM_DC_5_CREDIT,
  },
  {
    label: "온라인 특별할인 - 6등급",
    value: ENUM_DC_6_CREDIT,
  },
  {
    label: "온라인 특별할인 - 7등급",
    value: ENUM_DC_7_CREDIT,
  },
  {
    label: "추가 계약할인",
    value: ENUM_DC_RE_ORDER,
  },
  {
    label: "무사고 할인",
    value: ENUM_DC_NO_ACC,
  },
  {
    label: "프로모션 할인",
    value: ENUM_DC_PROMOTION,
  },
  {
    label: "임직원 할인",
    value: ENUM_DC_EMPLOYEE,
  },
];

/**
 * 차량 정보 관리
 */
export const ENUM_CAR_INFO_TYPE_OPTIONS = [
  {
    label: "신차",
    value: "N",
  },
  {
    label: "특판",
    value: "Y",
  },
];

/**
 * 대무 관리
 */
export const ENUM_CAR_MANAGER_OFF_REASON_BIZ_TRIP = "A38001";
export const ENUM_CAR_MANAGER_OFF_REASON_SICK = "A38002";
export const ENUM_CAR_MANAGER_OFF_REASON_EDUCATION = "A38003";
export const ENUM_CAR_MANAGER_OFF_REASON_ETC = "A38004";
//  [ [A38001 : 출장], [A38002 : 병가], [A38003 : 교육], [A38004 : 기타] ]
export const ENUM_CAR_MANAGER_OFF_REASON_OPTIONS = [
  {
    label: "출장",
    value: ENUM_CAR_MANAGER_OFF_REASON_BIZ_TRIP,
  },
  {
    label: "병가",
    value: ENUM_CAR_MANAGER_OFF_REASON_SICK,
  },
  {
    label: "교육",
    value: ENUM_CAR_MANAGER_OFF_REASON_EDUCATION,
  },
  {
    label: "기타",
    value: ENUM_CAR_MANAGER_OFF_REASON_ETC,
  },
];

/**
 * 만족도 조사
 */
export const SURVEY_PREFIX_CD_USER = "U";
export const SURVEY_PREFIX_CD_CAR_MANAGER = "M";
export const SURVEY_PREFIX_CD_COMPANY = "C";
export const SURVEY_PREFIX_CD_NONE = "N";

export const SURVEY_PREFIX_TRANSLATION: Record<string, string> = {
  [SURVEY_PREFIX_CD_USER]: "고객명",
  [SURVEY_PREFIX_CD_CAR_MANAGER]: "카매니저명",
  [SURVEY_PREFIX_CD_COMPANY]: "사업자명",
  [SURVEY_PREFIX_CD_NONE]: "없음",
};

export const SURVEY_PREFIX_OPTIONS = [
  {
    label: "없음",
    value: SURVEY_PREFIX_CD_NONE,
  },
  {
    label: "고객명",
    value: SURVEY_PREFIX_CD_USER,
  },
  {
    label: "카매니저명",
    value: SURVEY_PREFIX_CD_CAR_MANAGER,
  },
  {
    label: "사업자명",
    value: SURVEY_PREFIX_CD_COMPANY,
  },
];

// 불만
export const SURVEY_REPLY_GROUP_CD_1 = "A50001";
export const SURVEY_REPLY_GROUP_CD_1_NM = "불만이에요(1점)";

// 아쉬움
export const SURVEY_REPLY_GROUP_CD_2 = "A50002";
export const SURVEY_REPLY_GROUP_CD_2_NM = "아쉬워요(2점)";

// 괜찮음
export const SURVEY_REPLY_GROUP_CD_3 = "A50003";
export const SURVEY_REPLY_GROUP_CD_3_NM = "괜찮아요(3점)";

// 좋음
export const SURVEY_REPLY_GROUP_CD_4 = "A50004";
export const SURVEY_REPLY_GROUP_CD_4_NM = "좋아요(4점)";

// 최고
export const SURVEY_REPLY_GROUP_CD_5 = "A50005";
export const SURVEY_REPLY_GROUP_CD_5_NM = "최고에요(5점)";

export const SURVEY_REPLY_GROUP_CD_OPTIONS = [
  {
    label: SURVEY_REPLY_GROUP_CD_1_NM,
    value: SURVEY_REPLY_GROUP_CD_1,
  },
  {
    label: SURVEY_REPLY_GROUP_CD_2_NM,
    value: SURVEY_REPLY_GROUP_CD_2,
  },
  {
    label: SURVEY_REPLY_GROUP_CD_3_NM,
    value: SURVEY_REPLY_GROUP_CD_3,
  },
  {
    label: SURVEY_REPLY_GROUP_CD_4_NM,
    value: SURVEY_REPLY_GROUP_CD_4,
  },
  {
    label: SURVEY_REPLY_GROUP_CD_5_NM,
    value: SURVEY_REPLY_GROUP_CD_5,
  },
];

export const SURVEY_REPLY_GROUP_CD_TRANSLATIONS: Record<string, string> = {
  [SURVEY_REPLY_GROUP_CD_1]: SURVEY_REPLY_GROUP_CD_1_NM,
  [SURVEY_REPLY_GROUP_CD_2]: SURVEY_REPLY_GROUP_CD_2_NM,
  [SURVEY_REPLY_GROUP_CD_3]: SURVEY_REPLY_GROUP_CD_3_NM,
  [SURVEY_REPLY_GROUP_CD_4]: SURVEY_REPLY_GROUP_CD_4_NM,
  [SURVEY_REPLY_GROUP_CD_5]: SURVEY_REPLY_GROUP_CD_5_NM,
};

/**
 * 지능형 상담
 */
// 트림
export const INT_CONSULTING_CATEGORY_TRIM = "A37001";
// 옵션
export const INT_CONSULTING_CATEGORY_OPTION = "A37002";
// 계약 기간
export const INT_CONSULTING_CATEGORY_RENT_TERM = "A37003";
// 약정 주행 거리
export const INT_CONSULTING_CATEGORY_MILEAGE = "A37004";
// 운전자 범위
export const INT_CONSULTING_CATEGORY_DRIVER_RANGE = "A37005";
// 정비
export const INT_CONSULTING_CATEGORY_REPAIR = "A37006";

// 개인
export const INT_RENT_OPT_COND1_CD_PERSONAL = "개인";
// 개인사업자
export const INT_RENT_OPT_COND1_CD_PRIVATE_BIZ = "개인사업자";
// 법인
export const INT_RENT_OPT_COND1_CD_CORP = "법인";

// 휘발유
export const INT_TRIM_FUEL_GASOLINE = "Gasoline";

// 경유
export const INT_TRIM_FUEL_DIESEL = "Diesel";

// LPG
export const INT_TRIM_FUEL_LPG = "LPG";

// 전기
export const INT_TRIM_FUEL_ELEC = "Electric";

/**
 * 견적 시뮬레이션
 */

export const DEPOSIT_RATE_OPTIONS = [
  { label: "면제", value: 0 },
  { label: "10%", value: 10 },
  { label: "20%", value: 20 },
  { label: "30%", value: 30 },
  { label: "40%", value: 40 },
  { label: "50%", value: 50 },
  { label: "60%", value: 60 },
  { label: "70%", value: 70 },
  { label: "80%", value: 80 },
  { label: "90%", value: 90 },
];

export const F_DEPOSIT_RATE_OPTIONS = [
  { label: "10%", value: 10 },
  { label: "20%", value: 20 },
  { label: "30%", value: 30 },
  { label: "40%", value: 40 },
  { label: "50%", value: 50 },
];

export const CORP_DEPOSIT_RATE_OPTIONS = [
  { label: "면제", value: 0 },
  { label: "5%", value: 5 },
  { label: "10%", value: 10 },
  { label: "15%", value: 15 },
  { label: "20%", value: 20 },
  { label: "25%", value: 25 },
  { label: "30%", value: 30 },
  { label: "35%", value: 35 },
  { label: "40%", value: 40 },
  { label: "50%", value: 50 },
  { label: "60%", value: 60 },
  { label: "70%", value: 70 },
  { label: "80%", value: 80 },
  { label: "90%", value: 90 },
];

export const DIRECT_SURVEY_QUESTION_TYPE_SUBJECTIVE = "A51001";
export const DIRECT_SURVEY_QUESTION_TYPE_RADIO = "A51002";
export const DIRECT_SURVEY_QUESTION_TYPE_CHECK = "A51003";

export enum CooperType {
  ELEVEN = "A57001",
  GETCHA = "A57002",
  GALAXY = "A57003",
  OK_CASHBAG = "A57004",
  RIDE_NOW = "A57005",
  JEJU_SHORT = "A57006",
  NONE = "NONE",
}

export const COOPER_SEARCH_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "채널전체",
    value: "cooperAll",
  },
  {
    label: "11번가",
    value: CooperType.ELEVEN,
  },
  {
    label: "겟차",
    value: CooperType.GETCHA,
  },
  {
    label: "SKT T우주",
    value: CooperType.GALAXY,
  },
  {
    label: "OK캐쉬백",
    value: CooperType.OK_CASHBAG,
  },
  {
    label: "라이드나우",
    value: CooperType.RIDE_NOW,
  },
  {
    label: "미적용",
    value: CooperType.NONE,
  },
];

// 인수옵션
export enum TkvRtrnYnCd {
  // 선택형
  SELECT = "467001",
  // 반납형
  RETURN = "467002",
}

export const TKVRTRN_SEARCH_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "선택형",
    value: TkvRtrnYnCd.SELECT,
  },
  {
    label: "반납형",
    value: TkvRtrnYnCd.RETURN,
  },
];

export const SK_GRP_DISCOUNT_SEARCH_OPTIONS = [
  SEARCH_OPTION_ALL,
  {
    label: "채널전체",
    value: "outerAll",
  },
  {
    label: "SKR 임직원",
    value: "R",
  },
  {
    label: "그룹사 임직원",
    value: "Y",
  },
  {
    label: "SKT 임직원",
    value: "SKT",
  },
  {
    label: "My LG ID",
    value: "LG",
  },
  {
    label: "TOSS",
    value: "TOSS",
  },
  {
    label: "제주단기",
    value: "JEJUSQ",
  },
  {
    label: "2023-resale",
    value: "RS2023",
  },
  {
    label: "아시아나",
    value: "ASIANA",
  },
  {
    label: "EV sale",
    value: "EVPRMT",
  },
  {
    label: "미적용",
    value: "N",
  },
];

// Nc 메인배너 코드
export const BANNER_POSITION_CD_MAIN = "A42004";

// Uc 메인배너 코드
export const BANNER_POSITION_CD_UC_MAIN = "A42008";

// Tp 메인배너 코드
export const BANNER_POSITION_CD_TP_MAIN = "A42012";

// TB 메인배너 코드
export const BANNER_POSITION_CD_TB_MAIN = "A42016";

// TOSS 메인배너 코드
export const BANNER_POSITION_CD_TOSS_MAIN = "A42019";

// 제휴 메인배너 코드
export const BANNER_POSITION_CD_AUC_MAIN = "A42022";

// 월렌트 메인배너 코드
export const BANNER_POSITION_CD_MC_MAIN = "A42024";

// TODO: 신차 12개월 메인배너 코드
export const BANNER_POSITION_CD_SL_MAIN = "A42026";

// 상품 태그 상단 코드
export const TAG_POS_CD_TOP = "A55001";
// 상품 태그 하단 코드
export const TAG_POS_CD_BOTTOM = "A55002";

export const TAG_STYLE_CD_1       = "A54001"; // Grey + Fill
export const TAG_STYLE_CD_2       = "A54002"; // Red + Fill
export const TAG_STYLE_CD_3       = "A54003"; // Red + Line
export const TAG_STYLE_CD_4       = "A54004"; // Blue + Line
export const TAG_STYLE_CD_5       = "A54005"; // Black + Line
export const TAG_STYLE_CD_6       = "A54006"; // Red + Line
export const TAG_STYLE_CD_7       = "A54007"; // Default + Line
/** ! 프로모션 Tag */
export const TAG_STYLE_CD_11      = "A54011"; // 프로모션 Tag
/** ! 신차 / 중고차 Tag Type A 1 */
export const TAG_STYLE_CD_A54021  = "A54021"; // type A 1 - Pink + Line
/** ! 신차 / 중고차 Tag Type A 2 */
export const TAG_STYLE_CD_A54022  = "A54022"; // type A 2 - Purple + Line
/** ! 신차 / 중고차 Tag Type A 3 */
export const TAG_STYLE_CD_A54023  = "A54023"; // type A 3 - Blue + Line
/** ! 신차 / 중고차 Tag Type A 4 */
export const TAG_STYLE_CD_A54024  = "A54024"; // type A 4 - Grey + Line
/** ! 신차 / 중고차 Tag Type A 5 */
export const TAG_STYLE_CD_A54025  = "A54025"; // type A 5 - Light Green + Line
/** ! 신차 / 중고차 Tag Type A 6 */
export const TAG_STYLE_CD_A54026  = "A54026"; // type A 6 - Green + Line
/** ! 신차 / 중고차 Tag Type A 7 */
export const TAG_STYLE_CD_A54027  = "A54027"; // type A 7 - Orange + Line
/** ! 신차 / 중고차 Tag Type B 1 */
export const TAG_STYLE_CD_A54031  = "A54031"; // type B 1 - Pink(Fill) + Line
/** ! 신차 / 중고차 Tag Type B 2 */
export const TAG_STYLE_CD_A54032  = "A54032"; // type B 2 - Purple(Fill) + Line
/** ! 신차 / 중고차 Tag Type B 3 */
export const TAG_STYLE_CD_A54033  = "A54033"; // type B 3 - Blue(Fill) + Line
/** ! 신차 / 중고차 Tag Type B 4 */
export const TAG_STYLE_CD_A54034  = "A54034"; // type B 4 - Grey(Fill) + Line
/** ! 신차 / 중고차 Tag Type B 5 */
export const TAG_STYLE_CD_A54035  = "A54035"; // type B 5 - Light(Fill) Green + Line
/** ! 신차 / 중고차 Tag Type B 6 */
export const TAG_STYLE_CD_A54036  = "A54036"; // type B 6 - Green(Fill) + Line
/** ! 신차 / 중고차 Tag Type B 7 */
export const TAG_STYLE_CD_A54037  = "A54037"; // type B 7 - Orange(Fill) + Line
/** ! 신차 / 중고차 Tag Type C 1 */
export const TAG_STYLE_CD_A54041  = "A54041"; // type C 1 - Pink + Fill
/** ! 신차 / 중고차 Tag Type C 2 */
export const TAG_STYLE_CD_A54042  = "A54042"; // type C 2 - Purple + Fill
/** ! 신차 / 중고차 Tag Type C 3 */
export const TAG_STYLE_CD_A54043  = "A54043"; // type C 3 - Blue + Fill
/** ! 신차 / 중고차 Tag Type C 4 */
export const TAG_STYLE_CD_A54044  = "A54044"; // type C 4 - Grey + Fill
/** ! 신차 / 중고차 Tag Type C 5 */
export const TAG_STYLE_CD_A54045  = "A54045"; // type C 5 - Light Green + Fill
/** ! 신차 / 중고차 Tag Type C 6 */
export const TAG_STYLE_CD_A54046  = "A54046"; // type C 6 - Green + Fill
/** ! 신차 / 중고차 Tag Type C 7 */
export const TAG_STYLE_CD_A54047  = "A54047"; // type C 7 - Orange + Fill
/** ! 신차 / 중고차 Tag Type D 1 */
export const TAG_STYLE_CD_A54051  = "A54051"; // type D 1 - Pink + None
/** ! 신차 / 중고차 Tag Type D 2 */
export const TAG_STYLE_CD_A54052  = "A54052"; // type D 2 - Purple + None
/** ! 신차 / 중고차 Tag Type D 3 */
export const TAG_STYLE_CD_A54053  = "A54053"; // type D 3 - Blue + None
/** ! 신차 / 중고차 Tag Type D 4 */
export const TAG_STYLE_CD_A54054  = "A54054"; // type D 4 - Grey + None
/** ! 신차 / 중고차 Tag Type D 5 */
export const TAG_STYLE_CD_A54055  = "A54055"; // type D 5 - Light Green + None
/** ! 신차 / 중고차 Tag Type D 6 */
export const TAG_STYLE_CD_A54056  = "A54056"; // type D 6 - Green + None
/** ! 신차 / 중고차 Tag Type D 7 */
export const TAG_STYLE_CD_A54057  = "A54057"; // type D 7 - Orange + None
export const TAG_STYLE_CD_62      = "A66002"; // Red2 + Line
export const TAG_STYLE_CD_63      = "A66003"; // Blue2 + Line
export const TAG_STYLE_CD_64      = "A66004"; // Lightblue + Line
export const TAG_STYLE_CD_65      = "A66005"; // Green + Line
export const TAG_STYLE_CD_66      = "A66006"; // Orange + Line
/** ! 월렌트 Tag 1 */
export const TAG_STYLE_CD_661     = "A66011"; // Orange + Fill
/** ! 월렌트 Tag 2 */
export const TAG_STYLE_CD_662     = "A66012"; // Pink + Fill
/** ! 월렌트 Tag 3 */
export const TAG_STYLE_CD_663     = "A66013"; // Green + Fill
/** ! 월렌트 Tag 4 */
export const TAG_STYLE_CD_664     = "A66014"; // Light Green + Fill
/** ! 월렌트 Tag 5 */
export const TAG_STYLE_CD_665     = "A66015"; // Blue + Fill
/** ! 월렌트 Tag 6 */
export const TAG_STYLE_CD_666     = "A66016"; // Purple + Fill
/** ! 월렌트 Tag 7 */
export const TAG_STYLE_CD_667     = "A66017"; // Red + Fill

/** 월렌트 신규 태그 목록 */
export const MC_TAG_LIST = [TAG_STYLE_CD_661, TAG_STYLE_CD_662, TAG_STYLE_CD_663, TAG_STYLE_CD_664, TAG_STYLE_CD_665, TAG_STYLE_CD_666, TAG_STYLE_CD_667];

export enum GENDER {
  MALE = "1",
  FEMALE = "2",
}

export const UC_PROD_TYPE_SELECT = "A45006";
export const UC_PROD_TYPE_RETURN = "A45007";
export const UC_PROD_TYPE_JEJU = "A45008";
export const UC_PROD_TYPE_TAGO_PAY = "A45010";
export const MC_PROD_TYPE = "A45011";

export const OPTIONS_UC_PROD_TYPE = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "선택형",
    value: UC_PROD_TYPE_SELECT,
  },
  {
    label: "반납형",
    value: UC_PROD_TYPE_RETURN,
  },
  {
    label: "제주전용",
    value: UC_PROD_TYPE_JEJU,
  },
  {
    label: "타고페이",
    value: UC_PROD_TYPE_TAGO_PAY,
  },
  {
    label: "월렌트",
    value: MC_PROD_TYPE,
  },
];

export const OPTIONS_MC_PROD_TYPE = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "월렌트",
    value: MC_PROD_TYPE,
  },
];

export const OPTIONS_UC_MODIFY_PROD_TYPE = [
  {
    label: "선택형",
    value: UC_PROD_TYPE_SELECT,
  },
  {
    label: "반납형",
    value: UC_PROD_TYPE_RETURN,
  },
  {
    label: "제주전용",
    value: UC_PROD_TYPE_JEJU,
  },
  {
    label: "타고페이",
    value: UC_PROD_TYPE_TAGO_PAY,
  },
  {
    label: "월렌트",
    value: MC_PROD_TYPE,
  },
];

export const getUcProdType = (code: string) => {
  switch (code) {
    case UC_PROD_TYPE_SELECT:
      return "선택형";
    case UC_PROD_TYPE_RETURN:
      return "반납형";
    case UC_PROD_TYPE_JEJU:
      return "제주전용";
    case UC_PROD_TYPE_TAGO_PAY:
      return "타고페이";
    case MC_PROD_TYPE:
      return "월렌트";
    default:
      return "-";
  }
};

export const getMcProdType = (code: string) => {
  switch (code) {
    case MC_PROD_TYPE:
      return "월렌트";
    default:
      return "-";
  }
};

export const OPTIONS_COOPER_TYPE = [
  {
    label: "11번가",
    value: CooperType.ELEVEN,
  },
  {
    label: "겟차",
    value: CooperType.GETCHA,
  },
  {
    label: "T우주",
    value: CooperType.GALAXY,
  },
  {
    label: "OK캐쉬백",
    value: CooperType.OK_CASHBAG,
  },
  {
    label: "Ride Now",
    value: CooperType.RIDE_NOW,
  },
  {
    label: "제주단기",
    value: CooperType.JEJU_SHORT,
  },
];

export const AUC_COM_CD_BNK = "100001";
export const AUC_COM_CD_VANCAR = "100002";
export const AUC_COM_CD_HARMONY = "100003";

export const AUC_COM_CD = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "BNK",
    value: AUC_COM_CD_BNK,
  },
  {
    label: "반카",
    value: AUC_COM_CD_VANCAR,
  },
  {
    label: "하모니",
    value: AUC_COM_CD_HARMONY,
  },
];

export const COMP_CNTR_STS_OPTIONS = [
  SEARCH_OPTION_ALL,
  {
    label: "요청",
    value: "A61001",
  },
  {
    label: "반려",
    value: "A61002",
  },
  {
    label: "완료",
    value: "A61003",
  },
  {
    label: "전송실패",
    value: "A61004",
  },
];

/*
  신차 기획전 유형 코드
*/
export const NC_EXHIBITION_NORMAL = "A64001";
export const NC_EXHIBITION_TITLE = "A64002";
export const NC_EXHIBITION_STAGE = "A64003";
export const NC_EXHIBITION_ELEC_CAR = "A64004";
export const NC_EXHIBITION_SHORTS = "A64005";
export const NC_EXHIBITION_TIMEDEAL = "A64006";

export const NC_EXHIBITION_GBN_CD_OPTIONS = [
  {
    label: "리스트 기본형",
    value: NC_EXHIBITION_NORMAL,
  },
  {
    label: "리스트 타이틀형",
    value: NC_EXHIBITION_TITLE,
  },
  {
    label: "리스트 4단형",
    value: NC_EXHIBITION_STAGE,
  },
  {
    label: "리스트 전기차형",
    value: NC_EXHIBITION_ELEC_CAR,
  },
  {
    label: "쇼츠",
    value: NC_EXHIBITION_SHORTS,
  },
  {
    label: "타임딜",
    value: NC_EXHIBITION_TIMEDEAL,
  },
];

export const CNSL_YN_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "완료",
    value: "Y",
  },
  {
    label: "미완료",
    value: "N",
  },
];

export const CNSL_YN_NO_ALL_OPTIONS = [
  {
    label: "완료",
    value: "Y",
  },
  {
    label: "미완료",
    value: "N",
  },
];
