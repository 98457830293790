import { useDispatch, useSelector } from "react-redux";
import {
  push as pushAction,
  replace as replaceAction,
} from "connected-react-router";
import { LocationState, Path } from "history";
import { useCallback } from "react";
import { RootState } from "../redux/reducers";

export default function useRouter() {
  const dispatch = useDispatch();
  const router = useSelector((state: RootState) => state.router);

  const push = useCallback(
    (path: Path, state?: LocationState) => dispatch(pushAction(path, state)),
    [dispatch]
  );

  const replace = useCallback((path: Path) => dispatch(replaceAction(path)), [
    dispatch,
  ]);

  return {
    push,
    replace,
    router,
  };
}
