import React, { CSSProperties } from "react";
import { Tabs as AntTabs } from "antd";

const { TabPane } = AntTabs;

export interface TabInfo {
  key: string;
  title: string;
  content: any;
}

interface TabLayoutProps {
  tabInfos?: Array<TabInfo>;
  style?: CSSProperties;
}

const TabLayout: React.FC<TabLayoutProps> = (props: TabLayoutProps) => {
  const { tabInfos, style } = props;
  const defaultOpenKey = tabInfos && tabInfos.length > 0 ? tabInfos[0].key : "";
  /**
   * Render Helpers
   */

  const renderTabs = () => {
    if (tabInfos) {
      return tabInfos.map(({ key, title, content }) => {
        return (
          <TabPane
            tab={title}
            key={key}
            style={{
              minHeight: "50vh",
              maxHeight: "55vh",
              overflowY: "auto",
              overflowX: "hidden",
              paddingTop: "32px",
              paddingBottom: "32px",
              ...style,
            }}
          >
            {content}
          </TabPane>
        );
      });
    }

    return "";
  };

  return (
    <AntTabs defaultActiveKey={defaultOpenKey} tabBarGutter={48}>
      {renderTabs()}
    </AntTabs>
  );
};

TabLayout.defaultProps = {};
export default TabLayout;
