import React, { useState } from "react";
import { omit } from "lodash";
import { Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { Divider } from "antd";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  FUEL_TYPE_NICE_MAPPER,
  NICE_CONNECT_BRAND_FILTER_OPTIONS,
} from "../../constants/enums";
import Select from "../../../../../components/shared/data-entry/antd/Select";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import { SectionTitle } from "./style";
import CossMaster from "../../../../../models/CossCarInfo/CossMaster";
import NiceCarInfo from "../../../../../models/CossCarInfo/NiceCarInfo";
import {
  connectNiceCarInfo,
  fetchNiceCarInfoOptions,
  fetchNiceCarInfos,
} from "../../../../../apis/coss-car-infos";
import Radio from "../../../../../components/shared/data-entry/antd/Radio";
import { ENUM_CAR_INFO_TYPE_OPTIONS } from "../../../../../constants/enums";
import commonStrings from "../../../../../constants/strings";
import {
  getCossColorOptionLabel,
  getCossTrimLabel,
  getNiceCarImage,
  getNiceColorOptions,
  getNiceTrimOptions,
} from "./utils";
import OptionDetail from "../../../../../models/CossCarInfo/OptionDetail";
import errorMessages from "../../../../../constants/errors";
import Input from "../../../../../components/shared/data-entry/antd/Input";
import Button from "../../../../../components/shared/general/antd/Button";
import { defaultFormItemLayout } from "../../../../../components/shared/data-entry/FormBuilder";
import AddNiceTrimModal from "./pages/AddNiceTrimModal";
import { COSS_COLOR_CLS_EX } from "../../../../../models/CossCarInfo/CossColor";
import AddNiceColorModal from "./pages/AddNiceColorModal";
import Image from "../../../../../components/shared/data-display/antd/Image";

interface Props extends ModalProps {
  cossMaster?: CossMaster;
}

const NiceCarInfoConnectModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, cossMaster } = props;

  const formItemLayoutVertical = {
    wrapperCol: { span: 24 },
    labelCol: { span: 24 },
  };

  const formItemLayoutHorizontal = {
    wrapperCol: { span: 12 },
    labelCol: { span: 12 },
  };

  const sectionStyle = {
    paddingBottom: "16px",
  };
  const formItemStyle = {
    marginBottom: "16px",
  };

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [niceTrimFetching, setNiceTrimFetching] = useState<boolean>();
  const [niceOptionFetching, setNiceOptionFetching] = useState<boolean>();
  const [niceCarInfos, setNiceCarInfos] = useState<Array<NiceCarInfo>>([]);
  const [optionDetail, setOptionDetail] = useState<OptionDetail>();
  const [addNiceTrimModalVisible, setAddNiceTrimModalVisible] = useState(false);
  const [addNiceColorModalVisible, setAddNiceColorModalVisible] = useState(
    false
  );
  const [selectedModeGrdId, setSelectedModeGrdId] = useState<string>();

  const createFormInitialValues = () => {
    return {
      aheadYn: null,
      modeGrdId: null,
      carGradeCustomNm: "",
    };
  };
  const [aheadYn, setAheadYn] = useState<string | null>(
    createFormInitialValues().aheadYn
  );
  const initialValidationSchemaShape = {
    aheadYn: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    modeGrdId: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  };
  const [initialValues, setInitialValues] = useState<any>(
    createFormInitialValues()
  );
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape(initialValidationSchemaShape)
  );
  let formik: FormikProps<FormikValues>;

  // 재검색 state
  const [searchFormVisible, setSearchFormVisible] = useState<boolean>(false);
  const [niceCarName, setNiceCarName] = useState<string>("");
  const [niceBrand, setNiceBrand] = useState<string>("");

  const onModalOpen = async () => {
    setDataFetching(true);
    if (cossMaster) {
      const initialValues = createFormInitialValues();
      setAheadYn(initialValues.aheadYn);
      setNiceCarInfos([]);
      setOptionDetail(undefined);
      setNiceCarName(cossMaster.cartypeNm);
      setNiceBrand("");
      setInitialValues(createFormInitialValues());
    } else {
      setNiceCarInfos([]);
      setOptionDetail(undefined);
      setNiceCarName("");
      setNiceBrand("");
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */

  // NICE 트림 선택 -> 옵션정보 조회에 따른 폼 초기값 설정
  const updateInitialValues = (
    optionDetail: OptionDetail,
    renderProps: FormikProps<FormikValues>
  ) => {
    const { values } = renderProps;
    const { aheadYn } = values;

    const updatedInitialValues: Record<string, any> = {
      ...createFormInitialValues(),
      aheadYn,
    };
    // optionDetail.niceInfoColorList.forEach(({ modeColorId }) => {
    //   updatedInitialValues[modeColorId] = null;
    // });
    optionDetail.cossColorList.forEach(({ cossColorId }) => {
      updatedInitialValues[cossColorId] = null;
    });
    setInitialValues(updatedInitialValues);
  };

  // NICE 트림 선택 -> 옵션정보 조회에 따른 유효성검사 스키마 설정
  const updateValidationSchema = (optionDetail: OptionDetail) => {
    const validationSchema: any = {
      ...initialValidationSchemaShape,
    };
    // optionDetail.niceInfoColorList.forEach(({ modeColorId }) => {
    //   validationSchema[modeColorId] = Yup.string().typeError(
    //     errorMessages.REQUIRED_FIELD
    //   );
    // });
    optionDetail.cossColorList.forEach(({ cossColorId }) => {
      validationSchema[cossColorId] = Yup.string().typeError(
        errorMessages.REQUIRED_FIELD
      );
    });
    setValidationSchema(Yup.object().shape(validationSchema));
  };

  // COSS 차량 매핑 요청
  const requestConnectCossCarInfo = async (values: FormikValues) => {
    if (cossMaster) {
      const { modeGrdId, aheadYn, carGradeCustomNm } = values;
      const colorListParam: Array<any> = [];

      const colorKeyInfos = omit(values, [
        "modeGrdId",
        "aheadYn",
        "carGradeCustomNm",
      ]);

      Object.keys(colorKeyInfos).forEach((key: string) => {
        const cossId = key;
        const modeId = values[key];

        if (modeId) {
          colorListParam.push({
            modeColorId: modeId,
            cossColorId: cossId,
          });
        }
      });

      if (colorListParam.length === 0) {
        alertError("색상옵션은 최소 1개이상 연결되어야합니다");
      } else {
        setConfirmLoading(true);
        try {
          await connectNiceCarInfo(
            aheadYn,
            modeGrdId,
            cossMaster.modlId,
            colorListParam,
            carGradeCustomNm
          );
          alertSuccess("NICE 차량정보가 연결되었습니다");
          if (onCancel) onCancel();
          if (onDataChange) onDataChange(modeGrdId);
        } catch (e) {
          alertError(getErrorMessage(e));
        } finally {
          setConfirmLoading(false);
        }
      }
    } else {
      alertError("COSS 차량정보가 없습니다.");
    }
  };

  // NICE 차량정보 리스트 조회
  const requestFetchNiceTrimInfos = async (
    aheadYn: string,
    carName: string,
    brand: string
  ) => {
    if (cossMaster) {
      setNiceTrimFetching(true);
      try {
        const { items } = await fetchNiceCarInfos(
          carName,
          FUEL_TYPE_NICE_MAPPER[cossMaster.fuelCd],
          aheadYn,
          brand
        );
        setNiceCarInfos(items);
      } catch (e) {
        setNiceCarInfos([]);
        alertError(getErrorMessage(e));
      } finally {
        setNiceTrimFetching(false);
      }
    }
  };

  // 하위 옵션 초기화
  const resetOptions = (renderProps: FormikProps<FormikValues>) => {
    const { setFieldValue } = renderProps;
    // 트림 초기화
    setFieldValue("modeGrdId", null);

    // 커스텀 옵션명 초기화
    setFieldValue("carGradeCustomNm", "");

    // 입력된 색상옵션 초기화
    if (optionDetail) {
      optionDetail.niceInfoColorList.forEach(({ modeColorId }) => {
        setFieldValue(modeColorId, null);
      });
    }

    // 옵션정보 초기화
    setOptionDetail(undefined);
  };

  // NICE 차량 선택에 따른 옵션 정보 조회
  const requestFetchOptionDetail = async (
    modeGrdId: string,
    renderProps: FormikProps<FormikValues>
  ) => {
    if (cossMaster) {
      setNiceOptionFetching(true);
      try {
        const cossOptionDetailInfo = await fetchNiceCarInfoOptions(
          cossMaster.modlId,
          modeGrdId
        );
        setOptionDetail(cossOptionDetailInfo);
        setSelectedModeGrdId(modeGrdId);
        updateInitialValues(cossOptionDetailInfo, renderProps);
        updateValidationSchema(cossOptionDetailInfo);
      } catch (e) {
        setOptionDetail(undefined);
        alertError(getErrorMessage(e));
      } finally {
        setNiceOptionFetching(false);
      }
    }
  };

  // 폼 오류 메시지 리턴
  const getFormErrorMsg = (
    renderProps: FormikProps<FormikValues>,
    field: string
  ): string | undefined => {
    const { errors, touched } = renderProps;
    if (errors[field] && touched[field]) {
      return errors[field] as string;
    }

    return undefined;
  };

  /**
   * Event Actions
   */

  const toggleNiceSearchForm = () => {
    setSearchFormVisible(!searchFormVisible);
  };

  const handleSubmit = async (values: FormikValues) => {
    await requestConnectCossCarInfo(values);
  };

  // 상품 유형 선택
  const handleProductTypeChange = async (
    renderProps: FormikProps<FormikValues>,
    value: string
  ) => {
    const { setFieldValue } = renderProps;
    await requestFetchNiceTrimInfos(value, niceCarName, niceBrand);

    setFieldValue("aheadYn", value);
    setAheadYn(value);

    // 하위 옵션 초기화
    resetOptions(renderProps);
  };

  // NICE 트림 선택
  const handleNiceTrimChange = async (
    renderProps: FormikProps<FormikValues>,
    value: string
  ) => {
    const { setFieldValue } = renderProps;
    await requestFetchOptionDetail(value, renderProps);
    setFieldValue("modeGrdId", value);

    // 커스텀 옵션명 자동 입력
    const filtered = niceCarInfos.filter(({ modeGrdId }) => {
      return modeGrdId === value;
    })[0];
    if (filtered) {
      setFieldValue("carGradeCustomNm", filtered.carGradeNm);
    }
  };

  // 커스텀 옵션명 입력
  const handleCustomTrimChange = (
    renderProps: FormikProps<FormikValues>,
    value: string
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("carGradeCustomNm", value);
  };

  // COSS <-> NICE 컬러 매핑
  const handleNiceColorChange = (
    renderProps: FormikProps<FormikValues>,
    cossColorId: string,
    niceColorId: string
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue(cossColorId, niceColorId);
  };

  const handleSearchSubmit = async (aheadYn: string) => {
    if (!niceCarName && !niceBrand) {
      alertError("제조사, 차명중 하나의 검색조건은 필수입니다.");
    } else {
      await requestFetchNiceTrimInfos(aheadYn, niceCarName, niceBrand);
    }
  };

  /**
   * Render Helpers
   */

  const renderSearchForm = (renderProps: FormikProps<FormikValues>) => {
    const { values } = renderProps;
    return (
      <div
        style={{
          borderRadius: "8px",
          backgroundColor: "#FAFAFC",
          padding: "16px",
          marginBottom: "24px",
          display: searchFormVisible ? "block" : "none",
        }}
      >
        <SectionTitle
          style={{
            ...sectionStyle,
          }}
        >
          NICE 트림 검색조건
        </SectionTitle>
        <FormItem
          label="제조사"
          {...defaultFormItemLayout}
          style={{
            marginBottom: "8px",
          }}
        >
          <Select
            options={NICE_CONNECT_BRAND_FILTER_OPTIONS}
            value={niceBrand}
            onChange={(value) => {
              setNiceBrand(value);
            }}
          />
        </FormItem>
        <FormItem label="차명" {...defaultFormItemLayout}>
          <Input
            value={niceCarName}
            placeholder="NICE 차명을 입력해주세요"
            onChange={(event) => {
              setNiceCarName(event.target.value);
            }}
          />
        </FormItem>
        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "12px",
          }}
        />
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            loading={niceTrimFetching}
            onClick={async () => {
              await handleSearchSubmit(values.aheadYn);
            }}
          >
            재검색
          </Button>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        innerRef={(ref: FormikProps<FormikValues>) => {
          formik = ref;
        }}
      >
        {(renderProps) => {
          if (cossMaster) {
            const { handleSubmit, values, errors, touched } = renderProps;
            const { aheadYn, modeGrdId, carGradeCustomNm } = values;
            const carImageUrl = getNiceCarImage(niceCarInfos, modeGrdId || "");

            let niceTrimErr;
            if (errors.modeGrdId && touched.modeGrdId) {
              niceTrimErr = errors.modeGrdId;
            }

            let aheadYnErr;
            if (errors.aheadYn && touched.aheadYn) {
              aheadYnErr = errors.aheadYn;
            }

            return (
              <form onSubmit={handleSubmit}>
                {/* 상품 유형 (신차, 특판) */}
                <SectionTitle style={sectionStyle}>상품유형 선택</SectionTitle>
                <FormItem
                  style={formItemStyle}
                  required
                  {...formItemLayoutVertical}
                  help={aheadYnErr}
                  validateStatus={aheadYnErr && "error"}
                >
                  <Radio
                    disabled={niceTrimFetching}
                    value={aheadYn}
                    buttonStyle={false}
                    options={ENUM_CAR_INFO_TYPE_OPTIONS}
                    onChange={async (value) => {
                      await handleProductTypeChange(renderProps, value);
                    }}
                  />
                </FormItem>
                {/* 트림 연결 */}
                <SectionTitle
                  style={{
                    ...sectionStyle,
                    paddingTop: "16px",
                  }}
                >
                  {commonStrings.LABEL_CONNECT_CAR_INFO}
                  <Button
                    type="default"
                    size="small"
                    style={{
                      marginLeft: "8px",
                    }}
                    onClick={() => {
                      if (aheadYn) setAddNiceTrimModalVisible(true);
                      else {
                        alertError("상품유형을 먼저 선택해주세요");
                      }
                    }}
                  >
                    NICE 트림 추가
                  </Button>
                </SectionTitle>
                {renderSearchForm(renderProps)}
                <FormItem
                  required
                  help={niceTrimErr}
                  validateStatus={niceTrimErr && "error"}
                  label={getCossTrimLabel(cossMaster)}
                  {...formItemLayoutVertical}
                  style={formItemStyle}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "middle",
                    }}
                  >
                    <Select
                      style={{
                        flex: 1,
                      }}
                      disabled={
                        niceOptionFetching ||
                        niceTrimFetching ||
                        niceCarInfos.length === 0 ||
                        !aheadYn
                      }
                      loading={niceOptionFetching || niceTrimFetching}
                      value={modeGrdId}
                      placeholder={
                        aheadYn
                          ? commonStrings.HINT_NICE_CAR_INFOS
                          : commonStrings.HINT_PROD_TYPE_REQUIRED
                      }
                      options={getNiceTrimOptions(niceCarInfos)}
                      onChange={async (value) => {
                        await handleNiceTrimChange(renderProps, value);
                      }}
                    />
                    <Button
                      style={{
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        toggleNiceSearchForm();
                      }}
                    >
                      검색조건변경
                    </Button>
                  </div>
                </FormItem>
                {modeGrdId && carImageUrl && (
                  <FormItem
                    label="차량 이미지"
                    {...formItemLayoutVertical}
                    style={formItemStyle}
                  >
                    <Image src={carImageUrl} alt={carImageUrl} width={140} />
                  </FormItem>
                )}
                {modeGrdId && (
                  <FormItem
                    label="커스텀 트림명"
                    {...formItemLayoutVertical}
                    style={formItemStyle}
                  >
                    <Input
                      style={{
                        width: "100%",
                      }}
                      disabled={niceOptionFetching || niceTrimFetching}
                      value={carGradeCustomNm}
                      placeholder={commonStrings.HINT_NICE_CAR_INFOS}
                      onChange={async (e) => {
                        handleCustomTrimChange(renderProps, e.target.value);
                      }}
                    />
                  </FormItem>
                )}
                {renderOptionsForm(renderProps)}
              </form>
            );
          }

          return "";
        }}
      </Formik>
    );
  };

  const renderOptionsForm = (renderProps: FormikProps<FormikValues>) => {
    const { values } = renderProps;
    return (
      <div
        style={{
          display: optionDetail ? "block" : "none",
        }}
      >
        {optionDetail && (
          <>
            <SectionTitle
              style={{
                padding: "16px 0",
              }}
            >
              색상정보
              {/*<Button*/}
              {/*  type="default"*/}
              {/*  size="small"*/}
              {/*  style={{*/}
              {/*    marginLeft: "8px",*/}
              {/*  }}*/}
              {/*  onClick={() => {*/}
              {/*    setAddNiceColorModalVisible(true);*/}
              {/*  }}*/}
              {/*>*/}
              {/*  NICE 내징색상 추가*/}
              {/*</Button>*/}
            </SectionTitle>
            {optionDetail.cossColorList.map((colorInfo) => {
              const { cossColorId, cossColorCls } = colorInfo;
              const value = values[cossColorId];
              const errMsg = getFormErrorMsg(renderProps, cossColorId);
              return (
                <FormItem
                  style={{
                    marginBottom: "16px",
                  }}
                  key={cossColorId}
                  help={errMsg}
                  validateStatus={errMsg && "error"}
                  required
                  label={getCossColorOptionLabel(
                    colorInfo,
                    optionDetail?.niceInfoColorList || [],
                    value
                  )}
                  {...formItemLayoutHorizontal}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={value}
                    placeholder={commonStrings.HINT_NICE_COLOR}
                    options={getNiceColorOptions(
                      optionDetail?.niceInfoColorList || [],
                      cossColorCls === COSS_COLOR_CLS_EX
                    )}
                    onChange={(value) => {
                      handleNiceColorChange(renderProps, cossColorId, value);
                    }}
                  />
                </FormItem>
              );
            })}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Modal
        maskClosable={false}
        keyboard={false}
        size="default"
        confirmLoading={confirmLoading}
        title="COSS 기준 NICE 모델 연결"
        visible={visible}
        onOpen={onModalOpen}
        onCancel={onCancel}
        onOk={() => {
          if (formik) formik.handleSubmit();
        }}
      >
        {dataFetching ? renderLoading() : renderForm()}
      </Modal>
      <AddNiceTrimModal
        cossMaster={cossMaster}
        carName={niceCarName}
        brandNm={niceBrand}
        fuel={cossMaster && FUEL_TYPE_NICE_MAPPER[cossMaster.fuelCd]}
        visible={addNiceTrimModalVisible}
        onCancel={() => {
          setAddNiceTrimModalVisible(false);
        }}
        onDataChange={async () => {
          if (aheadYn) {
            await requestFetchNiceTrimInfos(aheadYn, niceCarName, niceBrand);
          }
        }}
      />
      <AddNiceColorModal
        modeGrdId={selectedModeGrdId}
        modlId={cossMaster?.modlId}
        visible={addNiceColorModalVisible}
        onCancel={() => {
          setAddNiceColorModalVisible(false);
        }}
        optionDetail={optionDetail}
        onColorDataChange={(optionDetail) => {
          setOptionDetail(optionDetail);
        }}
      />
    </>
  );
};

NiceCarInfoConnectModal.defaultProps = {};
export default NiceCarInfoConnectModal;
