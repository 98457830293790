import React, { useState } from "react";
import { GridApi } from "ag-grid-community";
import { ReactSVG } from "react-svg";
import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions, InteractionItem } from "chart.js";
import DirectSurveyResult from "../../../../../models/DirectSurvey/DirectSurveyResult";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { fetchDirectSurveyReportResultDetail } from "../../../../../apis/survey";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  convertChartData,
  createDirectSurveyResultCols,
  createExcelExportCols,
  ReportResultVM,
} from "./utils/table-utils";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import DirectSurveyResultDetail from "../../../../../models/DirectSurvey/DirectSurveyResultDetail";
import ChartDetailModal from "./ChartDetailModal";
import excelIcon from "../../../../../assets/svgs/excel-btn.svg";
import Button from "../../../../../components/shared/general/antd/Button";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import { formatDateString } from "../../../../../utils/date-utils";
import DirectSurveyInfoEditModal from "./ChartDetailModal/pages/DirectSurveyInfoEditModal";

interface Props extends ModalProps {
  directSurveyResult?: DirectSurveyResult;
}

const SurveyReportResultDetailModal: React.FC<Props> = ({
  visible,
  onCancel,
  onDataChange,
  directSurveyResult,
}: Props) => {
  const [dataFetching, setDataFetching] = useState(false);
  const [resultDetails, setResultDetails] = useState<ReportResultVM[]>([]);
  const [rawList, setRawList] = useState<DirectSurveyResultDetail[]>([]);

  const [selectedChartData, setSelectedChartData] = useState<
    DirectSurveyResultDetail[]
  >([]);
  const [selectedChartLabel, setSelectedChartLabel] = useState("");
  const [chartDetailModalVisible, setChartDetailModalVisible] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [masterInfoEditModalVisible, setMasterInfoEditModalVisible] = useState(
    false
  );

  const cols = createDirectSurveyResultCols();
  const excelCols = createExcelExportCols();

  const onModalOpen = async () => {
    if (directSurveyResult) {
      await requestFetchSurveyResultDetail(directSurveyResult.qstnOrdId);
    }
  };

  const requestFetchSurveyResultDetail = async (qstnId: string) => {
    setDataFetching(true);
    try {
      const { items } = await fetchDirectSurveyReportResultDetail(qstnId);
      const resultDetails = convertChartData(items);
      setRawList(items);
      setResultDetails(resultDetails);
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  const getModalTitle = () => {
    if (directSurveyResult) {
      return `설문결과 - ${directSurveyResult.qstnOrdNm}`;
    }
    return "";
  };

  const exportExcel = () => {
    if (gridApi) gridApi.exportDataAsExcel();
  };

  const renderInfo = () => {
    const surveyInfoSection: SectionFieldInfo[] = [
      {
        label: "설문명",
        content: directSurveyResult?.qstnOrdNm,
        span: 24,
      },
      {
        label: "설문기간",
        content: `${formatDateString(
          directSurveyResult?.qstnStDt,
          true
        )} ~ ${formatDateString(directSurveyResult?.qstnEndDt, true)}`,
        span: 12,
      },
      {
        label: "",
        type: FieldType.Custom,
        span: 24,
        render: () => {
          return (
            <Button
              onClick={() => {
                setMasterInfoEditModalVisible(true);
              }}
            >
              정보수정
            </Button>
          );
        },
      },
    ];
    return <DetailSection label="기본 정보" fieldInfos={surveyInfoSection} />;
  };

  const renderDetail = () => {
    if (resultDetails.length > 0) {
      return resultDetails.map((resultDetail) => {
        if (resultDetail.type === "chart") {
          const labels = resultDetail.chartInfos.map(({ label }) => {
            return label;
          });
          const chartData: ChartData = {
            labels,
            datasets: [
              {
                backgroundColor: ["#626DFF", "#447FF0", "#93D8FF", "grey"],
                data: resultDetail.chartInfos.map(({ data }) => {
                  return data;
                }),
              },
            ],
          };
          const chartOptions: ChartOptions = {
            plugins: {
              legend: {
                display: false,
              },
              title: {
                align: "start",
                display: true,
                text: resultDetail.questionTitle,
                font: {
                  size: 14,
                },
                color: "black",
                padding: 16,
              },
            },
          };

          return (
            <div
              key={resultDetail.questionTitle}
              style={{
                marginBottom: "24px",
              }}
            >
              <Bar
                getElementAtEvent={(element: InteractionItem[]) => {
                  const [first] = element;
                  if (first) {
                    const { index } = first;

                    const chartInfo = resultDetail.chartInfos[index];
                    if (chartInfo) {
                      const { replyList } = chartInfo;
                      setSelectedChartData(replyList);
                      setSelectedChartLabel(labels[index]);
                      setChartDetailModalVisible(true);
                    }
                  }
                }}
                height={100}
                width={360}
                data={chartData}
                options={chartOptions}
              />
            </div>
          );
        }

        return (
          <div
            key={resultDetail.questionTitle}
            style={{
              marginBottom: "24px",
            }}
          >
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {resultDetail.questionTitle}
              </span>
            </div>
            <ClientRowTable
              floatingFilter={false}
              height={400}
              cols={cols}
              autoHeight
              rowData={resultDetail.rawList}
            />
          </div>
        );
      });
    }

    return "";
  };

  return (
    <>
      <Modal
        title={getModalTitle()}
        visible={visible}
        onCancel={onCancel}
        onOpen={onModalOpen}
        size="large"
        footer={null}
      >
        {dataFetching ? (
          renderLoading()
        ) : (
          <div>
            {/* {renderInfo()} */}
            {/* Excel 추출을 위한 Hidden 테이블 */}
            <Button
              type="default"
              icon={<ReactSVG src={excelIcon} />}
              onClick={exportExcel}
            />
            <div
              style={{
                display: "none",
              }}
            >
              <ClientRowTable
                floatingFilter={false}
                height={0}
                cols={excelCols}
                rowData={rawList}
                apiRef={(api) => {
                  setGridApi(api);
                }}
              />
            </div>
            {renderDetail()}
          </div>
        )}
      </Modal>
      <DirectSurveyInfoEditModal
        visible={masterInfoEditModalVisible}
        onCancel={() => setMasterInfoEditModalVisible(false)}
        directSurveyResult={directSurveyResult}
        onDataChange={onDataChange}
      />
      <ChartDetailModal
        visible={chartDetailModalVisible}
        onCancel={() => {
          setChartDetailModalVisible(false);
        }}
        details={selectedChartData}
        chartLabel={selectedChartLabel}
      />
    </>
  );
};

SurveyReportResultDetailModal.defaultProps = {};
export default SurveyReportResultDetailModal;
