import {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LONG_TEXT,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../../utils/table-utils";
import strings from "../../../../../car-sales/NewCarCatalogManager/constants/strings";
import commonStrings from "../../../../../../constants/strings";

export const USE_YN_RENDERER = "useYnRenderer";

export const createShortsVideoListCols = () => {
  return [
    // 타이틀
    createTableCol("title", "타이틀", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 설명
    createTableCol("explain", "설명", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 동영상
    createTableCol("videoLink", "동영상", FilterType.Text, {
      width: CW_MEDIUM,
      flex: 1,
    }),
    // 썸네일
    createTableCol("thumbnail", "썸네일", undefined, {
      width: CW_MEDIUM,
      cellRenderer: "imageRenderer",
    }),
    // 쇼츠 사용 유무
    createTableCol("useYn", "사용유무", FilterType.Text, {
      width: CW_SMALL,
      cellRenderer: USE_YN_RENDERER,
    }),
    {
      ...createActionCol(152),
    },
  ];
};

export const createEtcVideoListCols = () => {
  return [
    // 타이틀
    createTableCol("title", "타이틀", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 설명
    createTableCol("explain", "설명", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 동영상
    createTableCol("videoLink", "동영상", FilterType.Text, {
      width: CW_MEDIUM,
      flex: 1,
    }),
    // 썸네일
    createTableCol("pcThumbnail", "썸네일", undefined, {
      width: CW_MEDIUM,
      cellRenderer: "imageRenderer",
    }),
    // 쇼츠 사용 유무
    createTableCol("useYn", "사용유무", FilterType.Text, {
      width: CW_SMALL,
      cellRenderer: USE_YN_RENDERER,
    }),
    {
      ...createActionCol(152),
    },
  ];
};
