import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import CossMaster from "../../models/CossCarInfo/CossMaster";
import NiceCarInfo from "../../models/CossCarInfo/NiceCarInfo";
import OptionDetail from "../../models/CossCarInfo/OptionDetail";
import CossCarInfoDetail from "../../models/CossCarInfo/CossCarInfoDetail";

const apiRootUrl = `${ROOT_URL}/admin/car-infos`;

/**
 * 차량 정보 리스트 조회
 */
export const fetchCarInfos = async (
  pageIndex: number,
  pageSize: number,
  cossInsSDt: string,
  cossInsEDt: string,
  cossModlId?: string,
  cossModlNm?: string,
  brandNm?: string,
  cartypeNm?: string,
  yearType?: string,
  fuel?: string,
  niceMappingYn?: string
): Promise<ListItemResponse<CossMaster>> => {
  const res = await request(HttpMethod.Get, apiRootUrl, {
    pageIndex,
    pageSize,
    cossInsSDt,
    cossInsEDt,
    cossModlId,
    cossModlNm,
    brandNm,
    cartypeNm,
    yearType,
    fuel,
    niceMappingYn,
  });

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(CossMaster, list as Array<JSON>),
    count: totalCnt,
  };
};

/**
 * 차량 정보 상세 조회
 */

export const fetchCossCarInfoDetail = async (
  cossModlId: string
): Promise<CossCarInfoDetail> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/${cossModlId}`);
  const { data } = res;
  const { response } = data;
  return plainToClass(CossCarInfoDetail, response);
};

/**
 * NICE 차량 목록 조회
 */
export const fetchNiceCarInfos = async (
  carName: string,
  fuel: string,
  aheadYn: string,
  brandNm?: string
): Promise<ListItemResponse<NiceCarInfo>> => {
  try {
    const res = await request(HttpMethod.Get, `${apiRootUrl}/nice-sync-list`, {
      carName,
      fuel,
      aheadYn,
      brandNm,
    });
    const { data } = res;
    const { response } = data;
    const { listCnt, list } = response;
    return {
      items: plainToClass(NiceCarInfo, list as Array<JSON>),
      count: listCnt,
    };
  } catch (e) {
    return {
      items: [],
      count: 0,
    };
  }
};

/**
 * COSS & NICE 차량 색상 옵션 리스트 조회
 */
export const fetchNiceCarInfoOptions = async (
  cossModlId: string,
  modeGrdId: string
): Promise<OptionDetail> => {
  const res = await request(
    HttpMethod.Get,
    `${apiRootUrl}/nice-sync-list/${cossModlId}/${modeGrdId}`
  );
  const { data } = res;
  const { response } = data;
  return plainToClass(OptionDetail, response);
};

/**
 * NICE 차량 연결
 */
export const connectNiceCarInfo = async (
  aheadYn: string,
  modeGrdId: string,
  cossModlId: string,
  colorList: Array<any>,
  carGradeCustomNm?: string
) => {
  await request(HttpMethod.Post, `${apiRootUrl}/coss-sync`, undefined, {
    aheadYn,
    modeGrdId,
    cossModlId,
    colorList,
    carGradeCustomNm,
  });
};

/**
 * NICE 차량 연결 초기화
 */
export const unlinkNiceCarInfo = async (cossModlId: string) => {
  await request(HttpMethod.Put, `${apiRootUrl}/coss-unsync/${cossModlId}`);
};

/**
 * COSS 부가 옵션 정보 입력
 */
export const updateOptionInfo = async (
  cossOptId: string,
  optDescUrl?: string,
  optDesc?: string,
  optCustomNm?: string,
  optDescFileId?: string
) => {
  await request(
    HttpMethod.Put,
    `${apiRootUrl}/opt/${cossOptId}`,
    {
      optDescUrl: optDescUrl || null,
      optDesc: optDesc || null,
      optCustomNm: optCustomNm || null,
      optDescFileId,
    },
    undefined
  );
};

/**
 * NICE 추가 대상 차량 리스트 조회
 */
export const fetchCopyTargetNiceCarInfos = async (
  carName: string,
  fuel: string,
  brandNm?: string
) => {
  const res = await request(
    HttpMethod.Get,
    `${apiRootUrl}/coss-sync/new-trim/nice-sync-list`,
    {
      carName,
      fuel,
      brandNm,
    }
  );
  try {
    const { data } = res;
    const { response } = data;
    const { listCnt, list } = response;
    return {
      items: plainToClass(NiceCarInfo, list as Array<JSON>),
      count: listCnt,
    };
  } catch (e) {
    return {
      items: [],
      count: 0,
    };
  }
};

/**
 * NICE 트림 추가
 */

export const addNiceTrim = async (
  modeGrdId: string,
  carGradeCustomNm: string
) => {
  const url = `${apiRootUrl}/coss-sync/new-trim`;
  await request(HttpMethod.Post, url, undefined, {
    modeGrdId,
    carGradeCustomNm,
  });
};

/**
 * NICE 컬러 복사
 */
export const copyNiceColor = async (modeColorId: string, colorNm: string) => {
  const url = `${apiRootUrl}/coss-sync/new-color`;
  await request(HttpMethod.Post, url, undefined, {
    modeColorId,
    colorNm,
  });
};

/**
 * 색상연결정보 변경
 */

export interface ColorEditParam {
  modeColorId: string;
  cossColorId: string;
}

export const editColorConnectionInfo = async (
  modeGrdId: string,
  editInfo: ColorEditParam[]
) => {
  const url = `${apiRootUrl}/coss-sync/upd-color`;
  await request(HttpMethod.Post, url, undefined, {
    modeGrdId,
    colorListVO: editInfo,
  });
};
