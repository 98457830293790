import React, { useState } from "react";
import { Divider } from "antd";
import * as Yup from "yup";
import { Formik, FormikProps, FormikValues } from "formik";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../../../types";
import commonStrings from "../../../../../../../constants/strings";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import {
  addNiceTrim,
  fetchCopyTargetNiceCarInfos,
} from "../../../../../../../apis/coss-car-infos";
import NiceCarInfo from "../../../../../../../models/CossCarInfo/NiceCarInfo";
import { SectionTitle } from "../../style";
import FormItem from "../../../../../../../components/shared/data-entry/antd/FormItem";
import Button from "../../../../../../../components/shared/general/antd/Button";
import { getCossTrimLabel, getNiceTrimOptions } from "../../utils";
import Select from "../../../../../../../components/shared/data-entry/antd/Select";
import Input from "../../../../../../../components/shared/data-entry/antd/Input";
import { defaultFormItemLayout } from "../../../../../../../components/shared/data-entry/FormBuilder";
import { NICE_CONNECT_BRAND_FILTER_OPTIONS } from "../../../../constants/enums";
import errorMessages from "../../../../../../../constants/errors";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import CossMaster from "../../../../../../../models/CossCarInfo/CossMaster";

interface Props extends ModalProps {
  fuel?: string;
  brandNm?: string;
  carName?: string;
  cossMaster?: CossMaster;
}

const AddNiceTrimModal: React.FC<Props> = (props: Props) => {
  const {
    visible,
    onCancel,
    onDataChange,
    fuel,
    carName,
    brandNm,
    cossMaster,
  } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [niceTrimFetching, setNiceTrimFetching] = useState(false);

  const [niceTrims, setNiceTrims] = useState<Array<NiceCarInfo>>([]);
  const [niceCarName, setNiceCarName] = useState<string>(carName || "");
  const [niceBrand, setNiceBrand] = useState<string>(brandNm || "");

  const formInitialValues = {
    modeGrdId: null,
    carGradeCustomNm: "",
  };
  const validationSchema = Yup.object().shape({
    modeGrdId: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    carGradeCustomNm: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);
  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setNiceCarName(carName || "");
    setNiceBrand(brandNm || "");
    setNiceTrims([]);
    setInitialValues(formInitialValues);
    if (fuel) await requestFetchNiceTrims(carName || "", fuel, brandNm || "");
  };

  /**
   * Private Functions
   */

  const requestFetchNiceTrims = async (
    carName: string,
    fuel: string,
    brandNm: string
  ) => {
    setNiceTrimFetching(true);
    try {
      const { items } = await fetchCopyTargetNiceCarInfos(
        carName,
        fuel,
        brandNm
      );
      setNiceTrims(items);
    } catch (e) {
      alertError(e.getErrorMessage(e));
      setNiceTrims([]);
    } finally {
      setNiceTrimFetching(false);
    }
  };

  const requestAddNiceTrim = async (values: FormikValues) => {
    setConfirmLoading(true);
    const { modeGrdId, carGradeCustomNm } = values;
    try {
      await addNiceTrim(modeGrdId, carGradeCustomNm);
      alertSuccess(commonStrings.FEEDBACK_ADD_NICE_TRIM);
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    await requestAddNiceTrim(values);
  };

  const handleSearchSubmit = async () => {
    if (!niceCarName && !niceBrand) {
      alertError("제조사, 차명중 하나의 검색조건은 필수입니다.");
    } else {
      await requestFetchNiceTrims(niceCarName, fuel || "", niceBrand);
    }
  };

  const handleNiceTrimChange = async (
    renderProps: FormikProps<FormikValues>,
    value: string
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("modeGrdId", value);

    // 커스텀 옵션명 자동 입력
    const filtered = niceTrims.filter(({ modeGrdId }) => {
      return modeGrdId === value;
    })[0];
    if (filtered) {
      setFieldValue("carGradeCustomNm", filtered.carGradeNm);
    }
  };

  /**
   * Render Helpers
   */

  const renderSearchForm = () => {
    return (
      <div
        style={{
          borderRadius: "8px",
          backgroundColor: "#FAFAFC",
          padding: "16px",
          marginBottom: "24px",
        }}
      >
        <SectionTitle
          style={{
            paddingBottom: "16px",
          }}
        >
          NICE 트림 검색조건
        </SectionTitle>
        <FormItem
          label="제조사"
          {...defaultFormItemLayout}
          style={{
            marginBottom: "8px",
          }}
        >
          <Select
            options={NICE_CONNECT_BRAND_FILTER_OPTIONS}
            value={niceBrand}
            onChange={(value) => {
              setNiceBrand(value);
            }}
          />
        </FormItem>
        <FormItem label="차명" {...defaultFormItemLayout}>
          <Input
            value={niceCarName}
            placeholder="NICE 차명을 입력해주세요"
            onChange={(event) => {
              setNiceCarName(event.target.value);
            }}
          />
        </FormItem>
        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "12px",
          }}
        />
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            loading={niceTrimFetching}
            onClick={async () => {
              await handleSearchSubmit();
            }}
          >
            재검색
          </Button>
        </div>
      </div>
    );
  };

  const renderForms = () => {
    return (
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        innerRef={(ref: FormikProps<FormikValues>) => {
          formik = ref;
        }}
      >
        {(renderProps) => {
          const {
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          } = renderProps;
          const { modeGrdId, carGradeCustomNm } = values;
          let niceTrimErr;
          if (errors.modeGrdId && touched.modeGrdId) {
            niceTrimErr = errors.modeGrdId;
          }

          let customNmErr;
          if (errors.carGradeCustomNm && touched.carGradeCustomNm) {
            customNmErr = errors.carGradeCustomNm;
          }

          const formItemLayout = {
            wrapperCol: {
              span: 24,
            },
            labelCol: {
              span: 24,
            },
            style: {
              marginBottom: "16px",
            },
          };

          return (
            <form onSubmit={handleSubmit}>
              {renderSearchForm()}
              {/* 트림 연결 */}
              <FormItem
                required
                help={niceTrimErr}
                validateStatus={niceTrimErr && "error"}
                label={getCossTrimLabel(cossMaster)}
                {...formItemLayout}
              >
                <Select
                  disabled={niceTrimFetching || niceTrims.length === 0}
                  loading={niceTrimFetching}
                  value={modeGrdId}
                  placeholder={commonStrings.HINT_NICE_COPY_CAR_INFOS}
                  options={getNiceTrimOptions(niceTrims)}
                  onChange={async (value) => {
                    await handleNiceTrimChange(renderProps, value);
                  }}
                />
              </FormItem>
              {modeGrdId && (
                <FormItem
                  required
                  label="커스텀 트림명"
                  {...formItemLayout}
                  help={customNmErr}
                  validateStatus={customNmErr && "error"}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                    value={carGradeCustomNm}
                    placeholder={commonStrings.HINT_NICE_CAR_INFOS}
                    onChange={async (e) => {
                      setFieldValue("carGradeCustomNm", e.target.value);
                    }}
                  />
                </FormItem>
              )}
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      title={commonStrings.MODAL_TITLE_ADD_NICE_TRIM}
      size="small"
      onOpen={onModalOpen}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      {renderForms()}
    </Modal>
  );
};

AddNiceTrimModal.defaultProps = {};
export default AddNiceTrimModal;
