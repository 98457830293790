import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import { formatDateString } from "../../../../utils/date-utils";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
} from "../../../../utils/table-utils";

export const createSMSTemplateCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createServerTableCol("insDtm", "등록일", {
      width: CW_MEDIUM,
      cellRenderer: (data) => {
        const { value } = data;
        if (value) {
          return formatDateString(value);
        }
        return "";
      },
    }),
    // 템플릿명
    createServerTableCol("smsTitle", "템플릿명", {
      width: CW_LARGE,
      flex: 1,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
