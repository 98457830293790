export default class DiscountMaster {
  // 할인등급ID
  public dcGrdId = "";

  // 할인구분코드
  public dcClsCd = "";

  // 적용시작일
  public apyStDt = "";

  // 적용종료일
  public apyEndDt = "";

  // 변경자
  public updUser = "";

  // 변경일시
  public updDtm = "";

  // 등록자
  public regUser = "";

  // 등록일시
  public regDtm = "";

  // 할인타입코드 ( A40001:정률, A40002:정액 )
  public dcTypeCd = "";

  // 할인값
  public dcVal = 0;

  // 사용유무
  public useYn = "";

  // 할인구분코드명
  public dcClsNm = "";

  // 할인타입코드명
  public dcTypeNm = "";
}
