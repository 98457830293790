export default class CarBooking {
  // 예약번호
  reservNo = "";

  // 예약순번
  preRsvId = "";

  // 신청일
  regDtm = "";

  // 예약차량
  reservCarNm = "";

  // 상품 ID
  prodId = "";

  // 상품명
  prodNm = "";

  // 거주지코드
  useAreaCd = "";

  // 거주지명
  useAreaNm = "";

  // 동의여부
  agreYn = "";

  // 고객ID
  custId = "";

  // 고객명
  custNm = "";

  // 휴대폰번호
  hpNo = "";

  // 대표 차량 외관 이미지
  repCarImg = "";

  // 대표 차량명
  repMakerNm = "";

  // 메모
  picMemo = "";

  // 통화여부
  callYn = "";
}
