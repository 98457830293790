import styled from "styled-components";

export const ListItemWrapper = styled.div`
  border: 1px solid #eeeff5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 60px;
  cursor: move;
  margin-bottom: 8px;
  padding: 8px 16px;
`;

export const ColumnWrapper = styled.div<any>`
  width: ${(props: any) => `${props.width}px`};
`;

export const ColumnHeaderLabel = styled.div<any>`
  font-weight: bold;
  width: ${(props: any) => `${props.width}px`};
`;
