class TagoCeftDetail {
  
  public yr = "";
  public ceft1 = "";
  public ceft2 = "";
  public ceft3 = "";
  public ceft4 = "";
  public ceft5 = "";
  public ceft6 = "";
  public ceft7 = "";
  public ceft8 = "";
  public ceft9 = "";
  public ceft10 = "";
  public ceft11 = "";
  public ceft12 = "";
  public ceft13 = "";
  public regUser = "";
  public regDtm = "";
  public updUser = "";
  public updDtm = "";

}

export default TagoCeftDetail;
