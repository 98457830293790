import React, { useState } from "react";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import ModalTableWrapper from "../../../../../../../components/shared/layout/ModalTableWrapper";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import TagMaster from "../../../../../../../models/TagMaster";
import ClientRowTable from "../../../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { fetchTagMasters } from "../../../../../../../apis/tag-master";
import {
  linkExhibitionTags,
  unlinkExhibitionTags,
  linkTags,
  unlinkTags,
  linkTagsToModl,
  unlinkTagsToModl,
} from "../../../../../../../apis/nc-catalogs";
import NewCarCatalogDetail from "../../../../../../../models/NewCarCatalogDetail";
import { createTagSetupCols } from "./utils/table-utils";
import {
  MOBILE_TAG_RENDERER,
  PC_TAG_RENDERER,
} from "../../../../../CatalogTagManager/utils/table-utils";
import {
  mobileTagRenderer,
  pcTagCellRenderer,
} from "../../../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import TagPreviewModal from "../TagPreviewModal";
import Button from "../../../../../../../components/shared/general/antd/Button";
import { TAG_POS_CD_TOP } from "../../../../../../../constants/enums";

/**
 * 신차상품 <-> 태그 등록/등록해제 모달
 */

interface Props extends ModalProps {
  catalogDetail?: NewCarCatalogDetail;
  isExhibition?: boolean;
  modeGrdId?: string;
}

const CatalogTagSetupModal: React.FC<Props> = (props: Props) => {
  const cols = createTagSetupCols();

  const {
    visible,
    onCancel,
    onDataChange,
    catalogDetail,
    isExhibition,
    modeGrdId,
  } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoadng] = useState(false);
  const [tagMasters, setTagMasters] = useState<TagMaster[]>();
  const [checkedRow, setCheckedRows] = useState<TagMaster[]>([]);
  const [tagPreviewModalVisible, setTagPreviewModalVisible] = useState(false);

  const onModalOpen = async () => {
    setDataFetching(true);
    setCheckedRows([]);
    await requestFetchTagMasters();
    setDataFetching(false);
  };

  const requestFetchTagMasters = async () => {
    try {
      const { items } = await fetchTagMasters();
      setTagMasters(items);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * 태그 설정 by 모달 확인 버튼
   */
  const requestLinkCatalogs = async () => {
    setConfirmLoadng(true);
    try {
      if (catalogDetail) {
        if (!isExhibition) {

          // grid 내 check Tag
          const tagIds = checkedRow.map(({ tagId }) => tagId);

          if (modeGrdId) {
            const model = catalogDetail?.list?.find(v => v.modeGrdId === modeGrdId);
            const tagSeqs = model?.tagGrdList.map(({ tagSeq }) => tagSeq) ?? [];
            // ! 모델 태그
            if (tagSeqs) {
              // 1. 기존에 연결된 태그 전체 unlink
              await unlinkTagsToModl(tagSeqs);
            }
  
            // 2. 체크된 태그 전체 link
            await linkTagsToModl(modeGrdId, tagIds);
          } else {
            const { tagList, prodId } = catalogDetail;
            // ! 상품 태그
            // 1. 기존에 연결된 태그 전체 unlink
            // 기존 Tag seq
            const tagSeqs = tagList.map(({ tagSeq }) => tagSeq);
            await unlinkTags(tagSeqs);
  
            // 2. 체크된 태그 전체 link
            await linkTags(prodId, tagIds);
          }
          alertSuccess(`${modeGrdId ? "모델" : "상품"} 태그정보가 변경되었습니다.`);
        } else {
          // 기존에 연결된 태그 전체 unlink
          const { tagList, prodId } = catalogDetail;
  
          const tagSeqs = tagList.map(({ tagSeq }) => tagSeq);
          await unlinkExhibitionTags(tagSeqs);
  
          // 체크된 태그 전체 link
          const tagIds = checkedRow.map(({ tagId }) => tagId);
          await linkExhibitionTags(
            prodId,
            tagIds,
            (catalogDetail as any).exhGbnCd || ""
          );
          alertSuccess("기획전 태그정보가 변경되었습니다.");
        }
      }

      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoadng(false);
    }
  };

  const handleModalOk = async () => {
    await requestLinkCatalogs();
  };

  const handleCheckRowChanged = (rows: Array<TagMaster>) => {
    setCheckedRows(rows);
  };

  const renderDetail = () => {
    return (
      <ModalTableWrapper
        headerTitle="태그 리스트"
        style={{
          position: "relative",
        }}
      >
        <ClientRowTable
          apiRef={(api) => {
            api.forEachNode((node) => {
              if (catalogDetail) {
                const data = node.data as TagMaster;
                // eslint-disable-next-line react/prop-types
                const model = catalogDetail?.list?.find(v => v.modeGrdId === modeGrdId);
                const filtered = (model ? model.tagGrdList : catalogDetail.tagList || []).filter(
                  ({ tagId }) => {
                    return tagId === data.tagId;
                  }
                );
                if (filtered.length > 0) {
                  node.setSelected(true);
                }
              }
            });
          }}
          onCheckRowChanged={handleCheckRowChanged}
          cols={cols}
          rowData={
            isExhibition
              ? tagMasters?.filter(
                  (item) =>
                    !(
                      item.mobTagPos === TAG_POS_CD_TOP ||
                      item.pcTagPos === TAG_POS_CD_TOP
                    )
                )
              : (
                modeGrdId ? tagMasters?.filter((item) => item.tagLvlCd !== "A69001")
                          : tagMasters?.filter((item) => item.tagLvlCd !== "A69002")
              )
          }
          frameworkComponents={{
            [PC_TAG_RENDERER]: pcTagCellRenderer,
            [MOBILE_TAG_RENDERER]: mobileTagRenderer,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Button
            onClick={() => {
              setTagPreviewModalVisible(true);
            }}
          >
            미리보기
          </Button>
        </div>
      </ModalTableWrapper>
    );
  };

  return (
    <>
      <Modal
        size="large"
        onOpen={onModalOpen}
        visible={visible}
        onCancel={onCancel}
        title="태그 설정"
        confirmLoading={confirmLoading}
        onOk={handleModalOk}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      <TagPreviewModal
        visible={tagPreviewModalVisible}
        modeGrdId={modeGrdId}
        onCancel={() => {
          setTagPreviewModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        tagMasters={checkedRow}
      />
    </>
  );
};

export default CatalogTagSetupModal;
