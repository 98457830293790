import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import { formatDateString } from "../../../../utils/date-utils";
import {
  createActionCol,
  CW_LARGE,
  CW_SMALL,
} from "../../../../utils/table-utils";

export const createSMSTemplateCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createServerTableCol("sndDtm", "전송일시", {
      width: CW_LARGE,
      cellRenderer: (data) => {
        const { value } = data;
        if (value) {
          return formatDateString(value, true);
        }
        return "";
      },
    }),
    // 카매니저
    createServerTableCol("carmanNm", "카매니저명", {
      width: CW_SMALL,
    }),
    // 템플릿명
    createServerTableCol("smsTitle", "템플릿명", {
      width: CW_LARGE,
      flex: 1,
    }),
    // 발송 고객 수
    createServerTableCol("custCnt", "전송 고객 수", {
      width: CW_SMALL,
    }),
    // 담당 고객 수
    createServerTableCol("totCustCnt", "담당 고객 수", {
      width: CW_SMALL,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
