import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_SMALL,
} from "../../../../utils/table-utils";

export const createEstCols = (): Array<TableColumn> => {
  return [
    createServerTableCol("yr", "년도", {
      width: CW_SMALL,
    }),
    createServerTableCol("ceft1", "0-30 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft2", "31-60 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft3", "61-90 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft4", "91-120 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft5", "121-150 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft6", "151-180 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft7", "181-210 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft8", "211-240 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft9", "241-270 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft10", "271-300 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft11", "301-330 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft12", "331-360 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("ceft13", "361-999 Days", {
      width: CW_LARGE,
    }),
    createServerTableCol("regUser", "등록자", {
      width: CW_LARGE,
    }),
    createServerTableCol("regDtm", "등록일자", {
      width: CW_LARGE,
    }),
    createServerTableCol("updUser", "수정자", {
      width: CW_LARGE,
    }),
    createServerTableCol("updDtm", "수정일자", {
      width: CW_LARGE,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
