import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import Estimation from "../../models/Estimation";
import EstimationDetail from "../../models/EstimationDetail";
import { CooperType, TkvRtrnYnCd } from "../../constants/enums";

/**
 * 견적 리스트 조회
 */
export const fetchEstimations = async (
  pageIndex: number,
  pageSize: number,
  estStDt: string,
  estEndDt: string,
  custNm?: string,
  custClsCd?: string,
  srvcId?: string,
  ncarClsFlag?: string,
  hpNo?: string,
  estStsCd?: string,
  repCartypeNm?: string,
  skGrpYn?: string,
  cooperCd?: CooperType | "",
  tkvRtrnYnCd?: TkvRtrnYnCd | "",
  carNo?: string
): Promise<ListItemResponse<Estimation>> => {
  const url = `${ROOT_URL}/admin/est-list`;
  const res = await request(HttpMethod.Get, url, {
    pageSize,
    pageIndex,
    estStDt,
    estEndDt,
    custNm,
    custClsCd,
    srvcId,
    ncarClsFlag,
    hpNo,
    estStsCd,
    repCartypeNm,
    skGrpYn,
    cooperCd,
    tkvRtrnYnCd,
    carNo,
  });

  const { data } = res;
  const { response } = data;
  const { totalCnt, list, custCnt } = response;

  return {
    items: plainToClass(Estimation, list as Array<JSON>),
    count: Number(totalCnt),
    extraData: Number(custCnt),
  };
};

/**
 * 견적 삭제
 */
export const deleteEstimation = async (
  carNo: string,
) => {
  await request(HttpMethod.Put, `${ROOT_URL}/admin/est-del`, undefined, { carNo });
};

/**
 * 견적 상세 조회
 */

export const fetchEstimationDetail = async (
  estReqNo: string
): Promise<EstimationDetail> => {
  const url = `${ROOT_URL}/admin/est-detail/${estReqNo}`;
  const res = await request(HttpMethod.Get, url);
  const { data } = res;
  const { response } = data;
  const { custList, detailRes, odsDcList } = response;

  let resConverted = {};

  if (custList && custList.length > 0) {
    const [custInfo] = custList;

    resConverted = {
      ...custInfo,
    };
  }
  if (detailRes && detailRes.length > 0) {
    const [estInfo] = detailRes;
    resConverted = {
      ...resConverted,
      ...estInfo,
      odsDcList,
    };
  }

  return plainToClass(EstimationDetail, resConverted);
};
