import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import { formatDateString } from "../../../../utils/date-utils";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import {
  currencyFormatRenderer,
  dateFormatRenderer,
} from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../../constants/strings";
import Estimation from "../../../../models/Estimation";
import {
  ENUM_CAR_TYPE_NC,
  ENUM_CAR_TYPE_UC,
  ENUM_PROD_TYPE_TAGO_PAY,
  ENUM_PROD_TYPE_UC_SELECT,
  GENDER,
  CooperType,
} from "../../../../constants/enums";

export const createEstCols = (): Array<TableColumn> => {
  return [
    // 견적일
    createServerTableCol("estDtm", "견적일시", {
      width: 170,
      cellRenderer: dateFormatRenderer("estDtm", true),
    }),
    // 만료일
    createServerTableCol(
      "cntrAvailTermMm",
      commonStrings.COL_EST_EXPIRED_DATE,
      {
        width: CW_SMALL_M,
        cellRenderer: (data) => {
          const { value } = data;
          if (value) {
            return formatDateString(value);
          }
          return "";
        },
      }
    ),
    // 회원명
    createServerTableCol("cossCustNm", commonStrings.COL_CUSTOMER_NAME, {
      width: CW_SMALL,
    }),
    // 성별
    createServerTableCol("gndr", "성별", {
      width: CW_SMALL,
      valueGetter: ({ data }) => {
        switch (data.gndr) {
          case GENDER.MALE:
            return "남";
          case GENDER.FEMALE:
            return "여";
          default:
            return null;
        }
      },
    }),
    // 생년월일
    createServerTableCol("brthDt", "생년월일", {
      cellRenderer: dateFormatRenderer("brthDt"),
      width: CW_MEDIUM,
    }),
    // 휴대폰번호
    createServerTableCol("hpNo", commonStrings.COL_HP_NO, {
      width: CW_MEDIUM,
    }),
    // 차량구분
    createServerTableCol("srvcId", commonStrings.COL_SRVC_ID, {
      width: CW_MEDIUM,
      valueGetter: ({ data }) => {
        switch (data.srvcId) {
          case ENUM_CAR_TYPE_NC:
            return "신차";
          case ENUM_CAR_TYPE_UC:
            return "중고차";
          default:
            return "-";
        }
      },
    }),
    // 인수옵션
    createServerTableCol("tkvRtrnYnCd", "인수옵션", {
      width: CW_SMALL,
      valueGetter: (params) => {
        const { data } = params;
        return data.tkvRtrnYnNm ?? "-";
      },
    }),
    // 상품타입
    createServerTableCol("ncarClsFlagNm", commonStrings.COL_SELLING_TYPE, {
      width: CW_MEDIUM,
    }),
    // 타고바이
    createServerTableCol("tgbyYn", "타고바이", {
      width: CW_SMALL,
    }),
    // 타고바이 상품유형
    createServerTableCol("tbProductGbn", "타고바이 상품유형", {
      width: CW_LARGE,
      valueGetter: (params) => {
        const { data } = params;
        const { tbProductGbn } = data;
        if (tbProductGbn === "A65001") return "바로 인수";
        if (tbProductGbn === "A65002") return "타보고 인수";
        return "";
      },
    }),
    // 차량번호
    createServerTableCol("carNo", commonStrings.COL_CAR_NO, {
      width: CW_MEDIUM,
    }),
    // 제조사
    createServerTableCol("repMakerNm", commonStrings.COL_CAR_BRAND, {
      width: CW_SMALL,
    }),
    // 모델
    createServerTableCol("repCartypeNm", commonStrings.COL_MODEL, {
      width: CW_SMALL,
    }),
    // 세부 모델
    createServerTableCol("carClassNm", commonStrings.COL_DETAIL_MODEL, {
      width: CW_MEDIUM,
    }),
    // 트림
    createServerTableCol("carGradeNm", commonStrings.COL_TRIM, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 외장색상
    createServerTableCol("xtnlColorNm", commonStrings.COL_CAR_EX_COLOR, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // 내장색상
    createServerTableCol("innrColorNm", commonStrings.COL_CAR_INNER_COLOR, {
      width: CW_MEDIUM,
    }),
    // 차량 옵션
    createServerTableCol("carGoodsNm1", commonStrings.COL_CAR_OPTION, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 추천차량 견적
    createServerTableCol("recClsDeg", "추천차량", {
      width: CW_MEDIUM,
      cellRenderer: "recCarDetail",
    }),
    // 월 렌탈료(부가세포함)
    createServerTableCol("mmRentAmtV", "월 렌탈료(부가세포함)", {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: currencyFormatRenderer("mmRentAmtV"),
    }),
    // 계약기간
    createServerTableCol("cntrTermMm", commonStrings.COL_CONTRACT_TERM, {
      width: CW_SMALL,
      valueGetter: (params) => {
        const { data } = params;
        return `${data.cntrTermMm}개월`;
      },
    }),
    // 약정주행거리
    createServerTableCol("prmsDtcClsNm", commonStrings.COL_MILEAGE_LIMIT, {
      width: CW_MEDIUM,
    }),
    // 운전자범위
    createServerTableCol("drvSoeNm", commonStrings.COL_DRIVER_RANGE, {
      width: CW_LARGE,
    }),
    // 그룹사 할인 여부
    createServerTableCol("skGrpYn", "외부채널", {
      width: CW_SMALL,
      valueGetter: (params) => {
        const { data } = params;
        const { skGrpYn } = data;
        if (skGrpYn === "Y") return "그룹사 임직원";
        if (skGrpYn === "R") return "SKR 임직원";
        if (skGrpYn === "SKT") return "SKT 임직원";
        if (skGrpYn === "LG") return "My LG ID";
        if (skGrpYn === "TOSS1") return "TOSS1";
        if (skGrpYn === "TOSS2") return "TOSS2";
        if (skGrpYn === "TOSS3") return "TOSS3";
        if (skGrpYn === "TOSS4") return "TOSS4";
        if (skGrpYn === "TOSS5") return "TOSS5";
        if (skGrpYn === "TOSS6") return "TOSS6";
        if (skGrpYn === "TOSS7") return "TOSS7";
        if (skGrpYn === "TOSS8") return "TOSS8";
        if (skGrpYn === "JEJUSQ") return "제주단기";
        if (skGrpYn === "ASIANA") return "아시아나";
        if (skGrpYn === "RS2023") return "2023-resale";
        if (skGrpYn === "EVPRMT") return "EV sale";
        return "미적용";
      },
    }),
    // 제휴채널
    createServerTableCol("cooperCd", "제휴채널", {
      width: CW_SMALL,
      valueGetter: (params) => {
        const { data } = params;
        const { cooperCd } = data;
        if (cooperCd === CooperType.ELEVEN) return "11번가";
        if (cooperCd === CooperType.GETCHA) return "겟차";
        if (cooperCd === CooperType.GALAXY) return "SKT T우주";
        if (cooperCd === CooperType.OK_CASHBAG) return "OK캐쉬백";
        if (cooperCd === CooperType.RIDE_NOW) return "라이드나우";
        return "";
      },
    }),
    // 담보조건
    createServerTableCol("pldgCondNm", commonStrings.COL_DEPOSIT_WAY, {
      width: CW_MEDIUM,
    }),
    // 견적유형
    createServerTableCol("custClsNm", commonStrings.COL_EST_TYPE, {
      width: CW_SMALL,
    }),
    // 견적상태
    createServerTableCol("estStsNm", commonStrings.COL_EST_STATE, {
      width: CW_MEDIUM,
    }),
    // 요금제
    createServerTableCol("amtCondNm", "요금제", {
      valueGetter: ({ data }) => {
        const estimation = data as Estimation;
        const { ncarClsFlag, amtCondNm, tkvAmtRtFlag } = estimation;

        if (ncarClsFlag === ENUM_PROD_TYPE_TAGO_PAY) {
          return amtCondNm;
        }

        if (ncarClsFlag === ENUM_PROD_TYPE_UC_SELECT) {
          if (tkvAmtRtFlag === "H") return "렌탈료 SAVE";
          if (tkvAmtRtFlag === "L") return "인수가 SAVE";
        }
        return "";
      },
    }),
    {
      ...createActionCol(80),
    },
  ];
};
