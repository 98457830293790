import moment from "moment";
import { FormikValues } from "formik";
import * as Yup from "yup";
import { FormElementType } from "../../../../../../components/shared/data-entry/FormBuilder/types";
import CarManagerDeliveryScheduleDetail from "../../../../../../models/CarManagerDeliveryScheduleDetail";
import errorMessages from "../../../../../../constants/errors";

export const createInitialValues = () => {
  return {
    /**
     * 출발
     */

    /**
     * 도착
     */
    arvDtm: null,
  };
};

export const createInitialValuesFromScheduleDetail = (
  scheduleDetail: CarManagerDeliveryScheduleDetail
) => {
  const { arvDtm } = scheduleDetail;

  return {
    arvDtm: arvDtm ? moment(arvDtm, "YYYYMMDD") : null,
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    arvDtm: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createScheduleEditParam = (values: FormikValues) => {
  const { arvDtm } = values;

  return {
    arvDtm: moment(arvDtm).format("YYYYMMddHHmmSS"),
  };
};

export const createFormInfo = () => {
  return [
    // 인도 예정일
    {
      key: "arvDtm",
      required: true,
      type: FormElementType.DatePicker,
      label: "인도 예정일",
      config: {
        disablePast: true,
      },
    },
  ];
};
