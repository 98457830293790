import { Modal } from "antd";
// import { createConfirmation } from "react-confirm";
// import ConfirmDialog from "./ConfirmDialog";

// const confirm = createConfirmation(ConfirmDialog);

// export const showConfirm = (
//   title: string,
//   message: string,
//   onConfirm: () => void
// ) => {
//   confirm({
//     confirmation: title,
//     message,
//   }).then(() => {
//     onConfirm();
//   });
// };

export const showConfirm = (
  title: string,
  message: string,
  onConfirm: () => void
) => {
  Modal.confirm({
    title,
    content: message,
    onOk: () => {
      onConfirm();
    },
    okText: "확인",
    cancelText: "취소",
    centered: true,
  });
};
