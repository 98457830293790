import React, { useState } from "react";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../../../types";
import AucCatalogDetail from "../../../../../../../models/AucCatalogDetail";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import {
  unlinkAUCImages,
  uploadAUCImages,
} from "../../../../../../../apis/auc-catalogs";
import { AucCatalogImage } from "../../../../../../../models/AucCatalogImage";

const { Dragger } = Upload;

interface Props extends ModalProps {
  catalogDetail?: AucCatalogDetail;
}

const AucImageManagerModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, catalogDetail } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [unlinkedImages, setUnlinkedImages] = useState<any[]>([]);

  const onModalOpen = () => {
    setUnlinkedImages([]);
    setFileList([]);
    if (catalogDetail) {
      const initialFileList = catalogDetail.carImgList.map((image) => {
        return {
          uid: image.imgId,
          status: "success",
          url: image.fileUrl,
          thumbUrl: image.fileUrl,
          name: image.imgNm,
          response: image,
        };
      });
      setFileList(initialFileList);
    }
  };

  const handleUpload = async () => {
    setConfirmLoading(true);
    // 새로 업로드할 이미지
    const newUploadImages = fileList.filter((file) => {
      return !file.status;
    });

    if (catalogDetail) {
      try {
        if (newUploadImages.length > 0) {
          await uploadAUCImages(catalogDetail.prodId, newUploadImages);
        }
        if (unlinkedImages.length > 0) {
          const params = unlinkedImages.map(({ response }) => {
            const data = response as AucCatalogImage;
            const { imgId } = data;
            const { rltIdtfId } = data;
            return {
              imgId,
              carId: catalogDetail.prodId,
              rltIdtfId,
            };
          });
          await unlinkAUCImages(catalogDetail.prodId, params);
        }

        if (unlinkedImages.length > 0 || newUploadImages.length > 0) {
          if (onDataChange) onDataChange();
          alertSuccess("상품이미지정보가 변경되었습니다.");
        }

        if (onCancel) onCancel();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const handleRemoveImage = (file: UploadFile<RcFile>) => {
    // 연결해제할 이미지 저장
    if (file.response) {
      setUnlinkedImages([...unlinkedImages, file]);
    }

    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleBeforeUpload = (file: RcFile, files: RcFile[]) => {
    const newFiles = files.map((file) => {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      file.url = URL.createObjectURL(file);
      return file;
    });

    setFileList([...fileList, ...newFiles]);
    return false;
  };

  const renderDetail = () => {
    return (
      <div>
        <Dragger
          accept="image/*"
          isImageUrl={() => {
            return true;
          }}
          listType="picture-card"
          fileList={fileList}
          onRemove={handleRemoveImage}
          beforeUpload={handleBeforeUpload}
          multiple
          style={{
            marginBottom: "10px",
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">이미지 업로드</p>
          <p className="ant-upload-hint">
            이곳에 이미지를 드래그하거나 PC에서 업로드 하세요
          </p>
        </Dragger>
      </div>
    );
  };

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="상품이미지 관리"
      onOk={handleUpload}
    >
      {renderDetail()}
    </Modal>
  );
};

export default AucImageManagerModal;
