import React, { useState } from "react";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { fetchSMSSendHistoryDetail } from "../../../../../apis/sms-template";
import DetailSection from "../../../../../components/shared/layout/DetailSection";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../../../components/shared/layout/TableLayout";
import SMSSendHistory from "../../../../../models/SMSSendHistory";
import SMSSendHistoryDetail from "../../../../../models/SMSSendHistoryDetail";
import { createSendHistoryCustomerCols } from "./utils/table-utils";

interface Props extends ModalProps {
  sendHistory?: SMSSendHistory;
}

const SMSSendHistoryDetailModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, sendHistory } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [detail, setDetail] = useState<SMSSendHistoryDetail>();
  const [logInfo, setLogInfo] = useState<string>("");

  const onModalOpen = async () => {
    setDataFetching(true);
    if (sendHistory) {
      await requestFetchSMSHistoryDetail(sendHistory);
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */

  const requestFetchSMSHistoryDetail = async (history: SMSSendHistory) => {
    try {
      // 검색조건 설정
      const appliedFilters = JSON.stringify(history);
      setLogInfo(appliedFilters);
      const detail = await fetchSMSSendHistoryDetail(
        history.sndMainId,
        history.smsMsgId,
        history.carmanId
      );
      setDetail(detail);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const getModalTitle = () => {
    return "전송내역 상세";
  };

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  const renderForms = () => {
    if (detail) {
      return (
        <div>
          <DetailSection
            label="템플릿 정보"
            fieldInfos={[
              {
                label: "템플릿 명",
                span: 24,
                content: detail.smsTitle,
              },
              {
                label: "템플릿 내용",
                span: 24,
                content: detail.smsMsg,
              },
            ]}
          />
          <div
            style={{
              height: "500px",
            }}
          >
            <TableLayout
              headerHorizontalPadding={0}
              hideExcelAction
              hideFilterAction
              title="발송 고객"
              totalCount={detail.custList.length}
              methodName={`/admin/carman/sms-send-histories/${sendHistory?.sndMainId}`}
              logInfo={logInfo}
            >
              <ClientRowTable
                cols={createSendHistoryCustomerCols()}
                rowData={detail.custList}
                floatingFilter={false}
              />
            </TableLayout>
          </div>
        </div>
      );
    }

    return "";
  };

  return (
    <Modal
      size="large"
      title={getModalTitle()}
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      footer={null}
    >
      {dataFetching ? renderLoading() : renderForms()}
    </Modal>
  );
};

SMSSendHistoryDetailModal.defaultProps = {};
export default SMSSendHistoryDetailModal;
