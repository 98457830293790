import React, { useCallback, useState } from "react";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import {
  EditExhibitionShorts,
  ExhibitionCatalog,
  ExhibitionEditParams,
} from "../../../../../../../apis/ex/types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";

import {
  createForms,
  createInitialValues,
  createValidationSchema,
} from "./form-utils";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import {
  editExhibition,
  editExhibitionShorts,
} from "../../../../../../../apis/ex";
import { getParamFromDateRange } from "../../../../../../../utils/form-utils";
import { NC_EXHIBITION_SHORTS } from "../../../../../../../constants/enums";

/**
 * 쇼츠 기획전 쇼츠 추가 모달
 */

interface Props extends ModalProps {
  shortsInfo?: EditExhibitionShorts | null;
  exhMstSeq: string;
}

const ShortsAddModal: React.FC<Props> = (props: Props) => {
  let formik: FormikProps<FormikValues>;
  const forms = createForms();
  const validationSchema = createValidationSchema();

  const { visible, onDataChange, onCancel, shortsInfo, exhMstSeq } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  const onModalOpen = () => {
    if (shortsInfo) {
      setInitialValues({
        ...shortsInfo,
        thumbnail: [{ imageUrl: shortsInfo.thumbnail }],
      });
    } else {
      setInitialValues(createInitialValues());
    }
  };

  const requestEditExhibitionShorts = async (params: EditExhibitionShorts) => {
    setConfirmLoading(true);

    try {
      if (shortsInfo) {
        await editExhibitionShorts(params, shortsInfo.shortsSeq);
        alertSuccess("쇼츠 정보가 변경되었습니다");
      } else {
        await editExhibitionShorts(params);
        alertSuccess("쇼츠가 등록되었습니다");
      }
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleSubmit = async (values: FormikValues) => {
    await requestEditExhibitionShorts({
      ...values,
      exhMstSeq,
      thumbnail: values.thumbnail[0].imageUrl,
      useYn: values.useYn ? "Y" : "N",
      exhGbnCd: NC_EXHIBITION_SHORTS,
    } as EditExhibitionShorts);
  };

  const renderModalTitle = useCallback(() => {
    return shortsInfo ? "쇼츠 수정" : "쇼츠 추가";
  }, [shortsInfo]);

  return (
    <>
      <Modal
        size="small"
        visible={visible}
        onCancel={onCancel}
        title={renderModalTitle()}
        description="섬네일 이미지 규격은 278 X 433 (세로형) 입니다. (쇼츠 썸네일은 하나의 소스로 활용 합니다.)"
        onOpen={onModalOpen}
        confirmLoading={confirmLoading}
        onOk={() => {
          formik?.handleSubmit();
        }}
      >
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          validationSchema={validationSchema}
          initialValues={initialValues}
          forms={forms}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default ShortsAddModal;
