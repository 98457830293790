export default class StockInfoReportDetail {
  // 차량번호
  public carNo = "";

  // 입고일자
  public inDt = "";

  // 출고일자
  public outDt = "";

  // 보유일수
  public hldDays = "";
}
