export default class CarManagerSurveyReportDetail {
  // 설문응답ID
  public srveReplyId = "";

  // 카매니저ID
  public carmanId = "";

  // 고객ID
  public custId = "";

  // 고객명
  public custNm = "";

  // 점수
  public point = "";

  // 설문응답항목
  public srveReplyItem = "";
}
