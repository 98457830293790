import { ColGroupDef } from "ag-grid-community";
import {
  ColDef,
  ValueGetterParams,
} from "ag-grid-community/dist/lib/entities/colDef";
import moment from "moment";

export enum FilterType {
  Number = "agNumberColumnFilter",
  Text = "agTextColumnFilter",
  Date = "agDateColumnFilter",
}

interface TableColumn extends ColDef {
  headerName: string;
  field?: string;
  valueGetter?: ((params: ValueGetterParams) => any) | string;
  minWidth?: number;
  flex?: number;
  children?: any[];
}

interface TableColumnOptions extends ColDef {
  valueGetter?: ((params: ValueGetterParams) => any) | string;
  minWidth?: number;
  flex?: number;
}

const dateFilterParams = {
  comparator: (filterLocalDateAtMidNight: Date, cellValue: any) => {
    if (!cellValue) return 0;
    const inputDate = filterLocalDateAtMidNight;
    const cellDate = moment(cellValue).toDate();
    if (cellDate < inputDate) {
      return -1;
    }
    if (cellDate > inputDate) {
      return 1;
    }

    return 0;
  },
};

export const createTableCol = (
  field: string,
  headerName: string,
  filterType?: FilterType,
  options?: TableColumnOptions
): TableColumn => {
  const col: TableColumn = {
    headerName,
    field,
  };
  if (filterType) {
    col.filter = filterType;
    switch (filterType) {
      case FilterType.Number:
        col.floatingFilterComponent = "numberFilter";
        break;
      case FilterType.Text:
        col.floatingFilterComponent = "textFilter";
        break;
      case FilterType.Date:
        col.floatingFilterComponent = "dateRangeFilter";
        col.filterParams = dateFilterParams;

        break;
    }
  }
  if (options) {
    return {
      ...col,
      ...options,
    };
  }
  return col;
};

export const createServerTableCol = (
  field: string,
  headerName: string,
  options?: TableColumnOptions
): TableColumn => {
  const col: TableColumn = {
    headerName,
    field,
    filter: FilterType.Text,
  };
  if (options) {
    return {
      ...col,
      ...options,
    };
  }
  return col;
};

export default TableColumn;
