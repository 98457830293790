import styled from "styled-components";

export const filterLayout = {
  wrapperCol: {
    xl: {
      span: 16,
    },
    md: {
      span: 24,
    },
    sm: {
      span: 24,
    },
    xs: {
      span: 24,
    },
  },
  labelCol: {
    xl: {
      span: 8,
    },
    md: {
      span: 24,
    },
    sm: {
      span: 24,
    },
    xs: {
      span: 24,
    },
  },
};

export const FilterFormLayout = styled.div`
  padding: 16px 24px;
  background: #fafafc;
  border-top: 1px solid ${(props) => props.theme.common.colors.border};
`;

export const FilterFooter = styled.div`
  border-top: 1px solid ${(props) => props.theme.common.colors.border};
  background: white;
  display: flex;
  align-items: center;
  padding: 16px 24px;
`;

export const FilterResultWrapper = styled.div`
  flex: 1;
`;

export const FilterActionWrapper = styled.div`
  text-align: right;
`;

export const AppliedFilterTag = styled.span`
  padding: 6px 12px;
  border-radius: 16px;
  background-color: #f6f6fa;
  margin-right: 8px;
`;
