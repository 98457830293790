import React, { useState } from "react";
import moment from "moment";

//types
import { ModalProps } from "../../../../../../../../types";

// apis
import { fetchCustomers } from "@apis/customers";

// ag-grid
import { GridApi, ICellRendererParams } from "ag-grid-community";

// utils
import { alertError } from "@utils/render-utils";
import { getPageIndex } from "@utils/ag-grid-utils";
import {
  get1YearDateRange,
} from "@utils/date-utils";

// components
import Modal from "@components/shared/feedback/antd/Modal";
import Button from "@components/shared/general/antd/Button";
import ClientRowTable from "@components/shared/data-display/Tables/components/ClientRowTable";
import ModalTableWrapper from "@components/shared/layout/ModalTableWrapper";

// TableFilterLayout
import { FilterElementType } from "@components/shared/layout/TableFilterLayout/types";
import TableFilterLayout from "@components/shared/layout/TableFilterLayout";

// table, cols
import { createUserInfoListCols } from "./utils/table-utils";
import { SELECT_USER_BUTTON } from "./utils/table-utils";

interface Props extends ModalProps {
  onSelect: (user: UserInfo) => void;
}
export interface UserInfo {
  // 회원번호
  custId: string,

  // 회원구분
  // custCls: string,

  // 회원명
  custNm: string,

  // 휴대폰번호
  hpNo: string,

  // 사업자구분
  // busrClsNm: string,
}

/**
 * 사용자 목록 조회 모달
 * 
 * @param props 
 * @returns 
 */
const UserInfoSearchModal: React.FC<Props> = (props: Props) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const { visible, onSelect, onCancel } = props;
  const [rows, setRows] = useState<Array<UserInfo>>([]);

  const cols = createUserInfoListCols();
  const paginationPageSize = 10;

  // TableFilterLayout
  const [filterValues, setFilterValues] = useState<any>({});
  const filterInitialValues = {
    stDt: get1YearDateRange(), // 기본 조회 조건 - 1년 치
    custNm: "",
    hpNo: "",
  };

  /**
   * 사용자 조회 필터
   */
  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "가입일",
    },
    {
      key: "custNm",
      type: FilterElementType.Input,
      label: "사용자명",
      // placeholder: commonStrings.HINT_CUSTOMER_NAME,
    },
    {
      key: "hpNo",
      type: FilterElementType.Input,
      label: "휴대전화번호",
      // placeholder: commonStrings.HINT_HP_NO,
    },
  ];

  return (
    <Modal
      title="사용자 목록 조회"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" type="default" onClick={onCancel}>
          닫기
        </Button>
      ]}
    >
      {/* 필터 영역 */}
      <TableFilterLayout
        forms={filterForms}
        formInitialValues={filterInitialValues}
        filterValues={filterValues}
        onSearchSubmit={async (filterValues: any) => {
          setFilterValues(filterValues);

          const schCustNm = filterValues.custNm;
          const schHpNo = filterValues.hpNo;
          if (schCustNm && schHpNo) {
            gridApi?.showLoadingOverlay(); // 로딩 시작
            const { items } = await fetchCustomers(
              getPageIndex(0, paginationPageSize),
              paginationPageSize,
              moment(filterValues.stDt[0]).format("YYYYMMDD"),
              moment(filterValues.stDt[1]).format("YYYYMMDD"),
              schCustNm, "",
              schHpNo,
            );
            setRows(items);
          } else {
            alertError("사용자명과 휴대전화번호는 필수값입니다."); // ! 사용자명과 휴대전화번호는 필수 값
            setRows([]);
          }
          gridApi?.hideOverlay(); // 로딩 종료
        }}
        styleInfo={{ span: 8 }}
      />
      {/* 컨텐츠 영역 */}
      <ModalTableWrapper headerTitle="목록">
        <ClientRowTable
          apiRef={(ref) => {
            setGridApi(ref);
          }}
          cols={cols}
          rowData={rows}
          frameworkComponents={{
            [SELECT_USER_BUTTON]: (props: ICellRendererParams) => {
              const { data } = props;

              return (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button
                    type="primary"
                    ghost
                    size="small"
                    onClick={() => {
                      onSelect(data);
                      if (onCancel) onCancel();
                    }}
                  >
                    선택
                  </Button>
                </div>
              );
            },
          }}
        />
      </ModalTableWrapper>
    </Modal>
  );
};

UserInfoSearchModal.defaultProps = {};
export default UserInfoSearchModal;