import { ROOT_URL } from "../../network/api-urls";
import { HttpMethod, request } from "../../network/request";
import { DirectPickEditParam } from "./types";

const apiRootUrl = `${ROOT_URL}/admin/direct-pickup`;

/**
 * 직집인수 수정
 */
export const editDirectPick = async (
  cntrId?: string,
  cntrSeq?: number,
  params?: DirectPickEditParam
) => {
  await request(
    HttpMethod.Put,
    `${apiRootUrl}/${cntrId}/${cntrSeq}`,
    undefined,
    params
  );
};
