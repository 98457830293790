export default class CossMaster {
  // 등록일
  public insDtm = "";

  // NICE 연동일
  public niceMpnDt = "";

  // 차량메이커ID
  public carMakerId = "";

  // 차량메이커명
  public carMakerNm = "";

  // 차종ID
  public cartypeId = "";

  // 차종명
  public cartypeNm = "";

  // 차정ID
  public cartypeDtlId = "";

  // 차정명
  public cartypeDtlNm = "";

  // 모델ID
  public modlId = "";

  // 모델명
  public modlNm = "";

  // 연료코드
  public fuelCd = "";

  // 연료명
  public fuelNm = "";

  // COSS 모델 차량가격
  public carAmt = 0;

  // 특판여부
  public aheadYn = "";

  // 특판여부명
  public aheadNm = "";

  // MODE 등급 ID
  public modeGrdId = "";

  // NICE 등급ID
  public carGradeNbr = "";

  // 연형
  public yearType = "";

  // 대표 차종명
  public carClassNm = "";

  // 트림(등급)
  public carGradeNm = "";

  // 미매핑 내장 색상수
  public noMpngInnrColorCnt = 0;

  // 미매핑 외장 색상수
  public noMpngXtnlColorCnt = 0;
}
