import moment from "moment";
import { formatDateString } from "../utils/date-utils";

class CarManagerDeliverySchedule {
  // 카매니저ID
  public carmanId = "";

  // 사용자ID
  public userId = "";

  // 계약번호
  public cntrId = "";

  // 사용자명
  public userNm = "";

  // 고객명
  public custNm = "";

  // 탁송 출발 일자
  public dptDtm = "";

  // 탁송 도착 일자
  public arvDtm = "";

  // 차량번호
  public carNo = "";

  // 차종명
  public cartypeNm = "";

  // 모델명
  public modlNm = "";

  // 탁송번호
  public consiId = "";

  // 탁송구분
  public consiClsCd = "";

  // 탁송구분명
  public consiClsNm = "";

  // 탁송차수
  public consiSeq = "";

  // 탁송상태코드
  public consiStsCd = "";

  // 탁송상태명
  public consiStsNm = "";

  // 출발지
  public podAddr = "";

  // 도착지
  public poaAddr = "";

  // 도착지 상세
  public poaDtladdr = "";

  get formattedDepartDate() {
    const date = moment(this.dptDtm, "YYYYMMDDHHmmSS");
    return formatDateString(date, true);
  }
  get formattedArrivalDate() {
    const date = moment(this.arvDtm, "YYYYMMDDHHmmSS");
    return formatDateString(date, true);
  }
}

export default CarManagerDeliverySchedule;
