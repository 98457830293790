class ContractUpdateRequest {
  // 변경요청일자
  public changeReqDt = "";

  // 계약ID
  public cntrId = "";

  // 계약순번
  public cntrSeq = 0;

  // 계약번호
  public cntrNo = "";

  // 고객명
  public custNm = "";

  // 차량번호
  public carNo = "";

  // 계약구분코드
  public custClsCd = "";

  // 계약구분명
  public custClsNm = "";

  // 카매니저명
  public carmanNm = "";

  // 변경진행상태코드
  public changePrgsStsCd = "";

  // 변경진행상태명
  public changePrgsStsNm = "";

  // 변경접수코드
  public changeRecptCd = "";

  // 변경접수명
  public changeRecptNm = "";

  // 변경전내용
  public changeBfCntn = "";

  // 변경후내용
  public changeAfCntn = "";
}

export default ContractUpdateRequest;
