import { FormikValues } from "formik";
import * as Yup from "yup";
import { FormElementType } from "../../../../../../components/shared/data-entry/FormBuilder/types";
import strings from "../../../constants/strings";

import NewCarDeliveryTermInfo from "../../../../../../models/NewCarDeliveryTermInfo";
import {
  getInitialDateRangeFromRaw,
  getParamFromDateRange,
} from "../../../../../../utils/form-utils";
import errorMessages from "../../../../../../constants/errors";

export const createInitialValues = () => {
  return {
    // 납기정보
    modeGrdId: null,
    apyTerm: null,
    dlvTerm: "",
  };
};

export const createInitialValuesFromData = (
  deliveryTermInfo: NewCarDeliveryTermInfo
) => {
  const { modeGrdId, dlvTerm, apyStDt, apyEndDt } = deliveryTermInfo;

  return {
    // 납기정보
    modeGrdId,
    apyTerm: getInitialDateRangeFromRaw(apyStDt, apyEndDt),
    dlvTerm,
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    modeGrdId: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    dlvTerm: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD)
      .min(0, errorMessages.REQUIRED_FIELD),
    apyTerm: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(2, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createAddDeliveryTermInfoParam = (values: FormikValues) => {
  const { dlvTerm, apyTerm, modeGrdId } = values;
  const { startDateParam, endDateParam } = getParamFromDateRange(apyTerm);
  return {
    modeGrdId,
    dlvTerm,
    apyStDt: startDateParam || "",
    apyEndDt: endDateParam || "",
  };
};

export const createEditDeliveryTermInfoParam = (values: FormikValues) => {
  const { dlvTerm, apyTerm } = values;
  const { startDateParam, endDateParam } = getParamFromDateRange(apyTerm);
  return {
    dlvTerm,
    apyStDt: startDateParam || "",
    apyEndDt: endDateParam || "",
  };
};

export const createFormInfo = (prodOptions: Array<any>, disabled = false) => {
  return [
    {
      key: "modeGrdId",
      type: FormElementType.Select,
      options: prodOptions,
      label: "상품명",
      placeholder: "상품을 선택해주세요",
      required: true,
      config: {
        disabled,
      },
    },
    {
      key: "apyTerm",
      type: FormElementType.DateRangePicker,
      label: "유효기간",
      required: true,
    },
    {
      // 납기 정보
      key: "dlvTerm",
      type: FormElementType.InputNumber,
      label: strings.LABEL_DELIVERY_TERM_INFO,
      placeholder: strings.HINT_DELIVERY_TERM_INFO,
      required: true,
      config: {
        min: 1,
        formatter: (value: any) => {
          return `${value}주`;
        },
      },
    },
  ];
};
