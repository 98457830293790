import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";

import {
  createForms,
  createInitialValues,
  createInitialValuesFromDirectSurvey,
  createValidationSchema,
} from "./form-utils";
import { ModalProps } from "../../../../../../../types";
import { SurveyEditParams } from "./types";
import { editSurvey } from "../../../../../../../../apis/survey";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../../utils/common-utils";
import { getParamFromDateRange } from "../../../../../../../../utils/form-utils";
import FormBuilder from "../../../../../../../../components/shared/data-entry/FormBuilder";
import Modal from "../../../../../../../../components/shared/feedback/antd/Modal";
import DirectSurveyResult from "../../../../../../../../models/DirectSurvey/DirectSurveyResult";

/**
 * 설문 회차 수정 모달
 */

interface Props extends ModalProps {
  directSurveyResult?: DirectSurveyResult;
}

const DirectSurveyInfoEditModal: React.FC<Props> = (props: Props) => {
  let formik: FormikProps<FormikValues>;
  const forms = createForms();
  const validationSchema = createValidationSchema();

  const { visible, onDataChange, onCancel, directSurveyResult } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  const onModalOpen = () => {
    if (directSurveyResult) {
      setInitialValues(createInitialValuesFromDirectSurvey(directSurveyResult));
    }
  };

  const requestEditSurveyMaster = async (
    qstnOrdId: string,
    params: SurveyEditParams
  ) => {
    setConfirmLoading(true);
    try {
      await editSurvey(qstnOrdId, params);
      alertSuccess("설문회차 정보가 변경되었습니다");
      if (onCancel) onCancel();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      if (onDataChange) onDataChange();
      setConfirmLoading(false);
    }
  };

  const handleSubmit = async (values: FormikValues) => {
    const { qstnOrdId, qstnGrpCd, qstnOrdNm, displayTerm } = values;
    let startDateParam = "";
    let endDateParam = "";
    getParamFromDateRange(displayTerm);
    if (displayTerm && displayTerm.length > 1) {
      const [start, end] = displayTerm;
      startDateParam = start.format("YYYYMMDD");
      endDateParam = end.format("YYYYMMDD");
    }

    const params: SurveyEditParams = {
      qstnGrpCd,
      qstnOrdNm,
      qstnStDt: startDateParam,
      qstnEndDt: endDateParam,
    };
    await requestEditSurveyMaster(qstnOrdId, params);
  };

  return (
    <>
      <Modal
        size="small"
        visible={visible}
        onCancel={onCancel}
        title="설문회차 정보수정"
        onOpen={onModalOpen}
        confirmLoading={confirmLoading}
        onOk={() => {
          formik?.handleSubmit();
        }}
      >
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          validationSchema={validationSchema}
          initialValues={initialValues}
          forms={forms}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default DirectSurveyInfoEditModal;
