export const getErrorMessage = (e: any) => {
  if (e.getMessage) return e.getMessage();
  return e.toString();
};

export const formatNumber = (input: string | number) => {
  let result = 0;
  if (typeof input === "string" && input) {
    if (input) result = Number(input);
    return new Intl.NumberFormat("ko-KR", {
      maximumFractionDigits: 0,
    }).format(result);
  }
  if (typeof input === "number") {
    return new Intl.NumberFormat("ko-KR", {
      maximumFractionDigits: 0,
    }).format(input);
  }
  return "";
};

export const formatCurrency = (input: string | number) => {
  const formatted = formatNumber(input);

  if (formatted) return `₩ ${formatted}`;
  return "";
};

export const isProduction = () => {
  return process.env.REACT_APP_ENV === "production";
};
