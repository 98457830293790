import { AucCatalogImage, AucCatalogRentAmt } from "./AucCatalogImage";

export class AucCatalog {
  // 상품번호
  public prodId = "";

  // 제휴사 코드
  public compCd = "";

  // 제휴사명
  public compNm = "";

  // 노츌상태
  public viewYn = "";

  // 차량번호
  public carNo = "";

  // 제조사명
  public brandNm = "";
  
  // 세부모델
  public carClassNm = "";

  // 트림
  public carGradeNm = "";

  // 연형
  public yearType = "";

  // 주행거리
  public lastDtc = "";

  // 대표 연료
  public fuelNm = "";

  // 장착 변속기
  public istdTrans = "";

  // 배기량
  public engdisp = "";

  // 승차정원
  public seaterCapa = "";

  // 차량가격
  public carGradePrice = "";

  // 등록일시
  public regDtm = "";

  // 차량정보_차량색상(내)
  public innrCarColorNm = "";

  // 차량정보_차량색상(외)
  public xtnlCarColorNm = "";

  // 자산번호
  public assetNo = "";

  // 노출순번
  public viewSeq = "";
}

export class AucCatalogDetail {
  // 상품번호
  public prodId = "";
  
  // 제휴사 코드
  public compCd = "";

  // 차량번호
  public carNo = "";

  // 제조사명
  public brandNm = "";
  
  // 세부모델
  public carClassNm = "";

  // 트림
  public carGradeNm = "";

  // 연형
  public yearType = "";

  // 주행거리
  public lastDtc = "";

  // 대표 연료
  public fuelNm = "";

  // 장착 변속기
  public istdTrans = "";

  // 배기량
  public engdisp = "";

  // 승차정원
  public seaterCapa = "";

  // 차량정보_차량색상(내)
  public innrCarColorNm = "";

  // 차량정보_차량색상(외)
  public xtnlCarColorNm = "";

  // 차량소개
  public mdComment = "";

  // 옵션정보
  public mainOptNms = "";

  // 등록자
  public regUser = "";

  // 변경자
  public updUser = "";

  // 차량 이미지 리스트 prefix
  public carImgListNm = "";

  // 차량 이미지 리스트
  public carImgList: AucCatalogImage[] = [];

  // 계약 기간별 렌탈료 prefix
  public rentAmtListNm = "";

  // 차량 이미지 리스트
  public rentAmtList: AucCatalogRentAmt[] = [];

  // 차량가격
  public carGradePrice = "";

  // 차량등록일
  public carRegDtm = "";

  // 태그코드
  public tagCd = "";
}

export class AucCatalogRentAmtParam {
  // 차량번호
  public carNo = "";

  // 계약기간 코드
  public cntrTermNm = "";

  // 담보율
  public pldgRt = "";

  // 기본 렌탈료 (1만km)
  public rglRentAmt1 = "";

  // 기본 렌탈료 (2만km)
  public rglRentAmt2 = "";

  // 기본 렌탈료 (3만km)
  public rglRentAmt3 = "";

  // 기본 렌탈료 (4만km)
  public rglRentAmt4 = "";
}


export class AucComtractList {
  // 계약ID
  public cntrId = "";

  // 표준 렌탈료
  public rglRentAmt = "";

  // 제휴 중고차 계약요청 상태코드
  public cntrReqSts = "";

  // 상태코드명
  public cntrReqStsNm = "";

  // 상품ID
  public prodId = "";

  // 제휴사 코드
  public compCd = "";

  // 제휴사명
  public compNm = "";

  // 차량번호
  public carNo = "";

  // 제조사명
  public brandNm = "";

  // 모델명(차종명)
  public carClassNm = "";

  // 등급(트림)
  public carGradeNm = "";

  // 연형
  public yearType = "";

  // 등록일
  public regDtm = "";

  // 대표 차량 외관 이미지
  public repCarImg = "";
}