import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LARGE,
  CW_MEDIUM,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

export const createNoticeCols = (): Array<TableColumn> => {
  return [
    // // 등록일
    createTableCol("regDtm", "등록일", FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // 배너 영억
    createTableCol("bnnrDmn", "배너 영역", FilterType.Text, {
      width: CW_LARGE,
      flex: 1,
    }),
    // 배너 총 개수
    createTableCol("bnnrCnt", "배너 총 개수", undefined, {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 유효배너 개수
    createTableCol("bnnrYnCnt", "유효 배너 개수", undefined, {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
    }),
    {
      ...createActionCol(80),
    },
  ];
};
