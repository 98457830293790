export default class EstimationReport {
  // 견적순위
  public rank = "";

  // 판매유형
  public ncarClsFlagNm = "";

  // 계약유형
  public custClsNm = "";

  // 제조사
  public brandNm = "";

  // 모델
  public repCarClassNm = "";

  // 세부모델
  public carClassNm = "";

  // 트림
  public carGradeNm = "";

  // 견적산출 건수
  public rntFeeCnt = "";

  // 견적저장 건수
  public estSaveCnt = "";
}
