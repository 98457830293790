export default class CarManagerSurveyReportDetailForExcel {
  // 계약번호
  public cntrId = "";

  // 고객번호
  public custId = "";

  // 고객명
  public custNm = "";

  // 카매니저ID
  public carmanId = "";

  // 카매니저명
  public carmanNm = "";

  // 평가일자
  public valnDt = "";

  // 친절도 평점
  public avgPoint03 = "";

  // 전문성 평점
  public avgPoint04 = "";

  // 종합 평점
  public avgPoint05 = "";

  // 설문응답항목코드명(답변)
  public srveReplyItem = "";

  // 계약일자
  public cntrDt = "";

  // 대표차명
  public repCarTypeNm = "";

  // 성별
  public gndr = "";

  // 생년월일
  public brthDt = "";

  // 판매구분명
  public ncarClsFlagNm = "";

  // 사용지역명
  public poaSiteNm = "";

  // 연료타입
  public fuelNm = "";

  // 차형
  public extShape = "";
}
