import React, { useState } from "react";

import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import UsedCarCatalogDetail from "../../../../../../../models/UsedCarCatalogDetail";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import { OPTIONS_UC_MODIFY_PROD_TYPE } from "../../../../../../../constants/enums";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import { renderLoading } from "../../../../../../../utils/render-utils";
import { editUcProdType } from "../../../../../../../apis/uc-catalogs";

interface Props extends ModalProps {
  catalogDetail?: UsedCarCatalogDetail;
}

const UsedCarProdTypeModifyModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, catalogDetail, onDataChange } = props;

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [defaultProdType, setDefaultProdType] = useState<string>();

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);
    if (catalogDetail) {
      setDefaultProdType(catalogDetail.ncarClsFlag);
    } else {
      setDefaultProdType("");
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const forms: Array<FormInfo> = [
    {
      key: "ncarClsFlag",
      type: FormElementType.Select,
      label: "상품유형",
      required: true,
      options: OPTIONS_UC_MODIFY_PROD_TYPE,
    },
  ];

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    setConfirmLoading(true);

    const { prodType } = values;

    if (catalogDetail && prodType !== defaultProdType) {
      const body = {
        prodId: catalogDetail.prodId,
        ncarClsFlag: values.ncarClsFlag,
      };

      try {
        await editUcProdType(body);
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
      } catch (e) {
        console.log(e);
      } finally {
        setConfirmLoading(false);
      }
    } else if (onCancel) onCancel();
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      title="상품유형 변경"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={{ ncarClsFlag: defaultProdType }}
          forms={forms}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

UsedCarProdTypeModifyModal.defaultProps = {
  visible: true,
};
export default UsedCarProdTypeModifyModal;
