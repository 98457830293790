import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_MEDIUM,
  CW_LARGE,
  CW_LONG_TEXT,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import Switch from "../../../../components/shared/data-entry/antd/Switch";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { formatNumber } from "../../../../utils/common-utils";

export const AR_DISPLAY_STATE_SWITCH = "displayStateSwitchRenderer";

const renderSwitch = (
  onChange: (checked: boolean, prodId: string) => void,
  key = "useYn"
) => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    const { prodId } = data;
    return (
      <Switch
        checked={data[key] === "Y"}
        onClick={(checked) => {
          onChange(checked, prodId);
        }}
      />
    );
  };
};

export const createSwitchRenderer = (
  onChange: (checked: boolean, prodId: string) => void,
  key = "viewYn"
) => ({
  [AR_DISPLAY_STATE_SWITCH]: renderSwitch(onChange, key),
});

export const createNewCarCatalogCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", "등록일", FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // 제휴사명
    createTableCol("compNm", "제휴사명", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 노출상태
    createTableCol("viewYn", "노출상태", FilterType.Text, {
      cellRenderer: AR_DISPLAY_STATE_SWITCH,
      width: CW_SMALL,
    }),
    // 차량번호
    createTableCol("carNo", "차량번호", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 제조사
    createTableCol("brandNm", "제조사", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 모델
    createTableCol("carClassNm", "모델", FilterType.Text, {
      width: CW_LONG_TEXT,
    }),
    // 연식
    createTableCol("yearType", "연식", FilterType.Text, {
      width: CW_SMALL,
    }),
    // TAG
    createTableCol("tagCd", "TAG", FilterType.Text, {
      width: CW_SMALL,
      cellRenderer: ({ data }) => {
        if(data.tagCd==="A66002") { return "New !"; }
        if(data.tagCd==="A66003") { return "신차급"; }
        if(data.tagCd==="A66004") { return "월렌트"; }
        if(data.tagCd==="A66005") { return "가성비"; }
        if(data.tagCd==="A66006") { return "할인특가"; }
        else { return "미적용"; }
      },
    }),
    // 차량등록일
    createTableCol("carRegDtm", "차량등록일", FilterType.Date, {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("carRegDtm"),
    }),
    // 주행거리
    createTableCol("lastDtc", "주행거리", FilterType.Text, {
      width: CW_SMALL_M,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.lastDtc)}km`;
      },
    }),
    // 연료
    createTableCol("fuelNm", "연료", FilterType.Text, {
      width: CW_SMALL,
    }),  
    // 배기량
    createTableCol("engdisp", "배기량", FilterType.Text, {
      width: CW_SMALL,
    }),  
    // 인승
    createTableCol("seaterCapa", "인승", FilterType.Text, {
      width: CW_SMALL,
    }), 
    // 변속
    createTableCol("istdTrans", "변속기", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 색상(외부)
    createTableCol("xtnlCarColorNm", "색상(외부)", FilterType.Text, {
      width: CW_LARGE,
    }),
    // 색상(내부)
    createTableCol("innrCarColorNm", "색상(내부)", FilterType.Text, {
      width: CW_LARGE,
    }),
    // 차량가격    
    createTableCol("carGradePrice", "차량가격", FilterType.Text, {
      width: CW_SMALL_M,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.carGradePrice)}`;
      },
    }),
    // 자산번호
    createTableCol("assetNo", "자산번호", FilterType.Text, {
      width: CW_LARGE,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
