import LocalizedStrings from "react-localization";

const commonStrings = new LocalizedStrings({
  ko: {
    FEEDBACK_ADD_ESTIMATION: "견적이 등록되었습니다",
    LABEL_OFF_CAR_MANAGER: "부재자",
    LABEL_REPLACE_CAR_MANAGER: "대무자",
    HINT_CAR_MANAGER_SELECT: "카매니저를 선택해주세요",
    LABEL_OFF_DATE_RANGE: "부재기간",
    LABEL_OFF_REASON: "부재사유",
    HINT_OFF_REASON: "부재사유를 선택해주세요",
    LABEL_OFF_ETC_REASON: "기타사유",
    HINT_OFF_REASON_ETC: "부재사유를 입력해주세요",
    FEEDBACK_ADD_CAR_MANAGER_REPLACEMENT: "대무정보가 등록되었습니다",
    FEEDBACK_EDIT_CAR_MANAGER_REPLACEMENT: "대무정보가 변경되었습니다",

    FEEDBACK_ADD_NICE_TRIM: "NICE 트림이 등록되었습니다.",
    FEEDBACK_ADD_NICE_COLOR: "NICE 색상이 등록되었습니다.",
    HINT_COSS_MODEL_ID: "COSS 모델 ID를 입력해주세요",
    HINT_COSS_MODEL_NAME: "COSS 모델명을 입력해주세요",
    HINT_COSS_BRAND_NAME: "COSS 제조사명을 입력해주세요",
    HINT_COSS_CAR_NAME: "COSS 차량명을 입력해주세요",
    HINT_CAR_YEAR: "연식을 입력해주세요(NICE 연동기준)",
    HINT_YEAR_TYPE: "연식을 입력해주세요",
    LABEL_CONNECT_CAR_INFO: "차량정보 (COSS -> NICE 연동)",
    HINT_NICE_CAR_INFOS:
      "NICE 트림을 선택해주세요. (선택불가시 검색조건을 변경해주세요.)",
    HINT_PROD_TYPE_REQUIRED: "상품유형을 선택해주세요",
    HINT_NICE_COPY_CAR_INFOS:
      "등록할 NICE 트림을 선택해주세요. (선택불가시 검색조건을 변경해주세요.)",
    HINT_COSS_COLOR: "COSS 색상을 선택해주세요",
    HINT_NICE_COLOR: "NICE 색상을 선택해주세요",
    HINT_LAST_DTC: "주행거리를 입력해주세요",

    CAR_MANAGER_REPLACEMENT_MANAGER: "대무 설정",
    CAR_MANAGER_REPLACEMENT_LIST: "대무 리스트",
    FETCH_RENT_FEE: "렌탈료 조회",
    CUSTOMER_NOTE_SAVE: "비고 저장",
    DC_MASTER_MANAGER: "할인마스터 관리",
    DC_MASTER_LIST: "할인마스터 리스트",
    USE_YN: "사용유무",
    MODAL_TITLE_EST_SIM: "견적내기",
    MODAL_TITLE_EST_RESULT: "견적조회",
    LABEL_CUSTOMER_TYPE: "사업자구분",
    HINT_CUSTOMER_TYPE: "사업자유형을 선택해주세요",
    LABEL_INSURANCE_SIGN_IN_PRICE: "보증금액",
    HINT_INSURANCE_SIGN_IN_PRICE: "금액을 입력해주세요",
    LABEL_INSURANCE_FEE: "보증보험 수수료(연간)",
    FEEDBACK_EDIT_CAR_MAN_SCHEDULE: "인도스케줄 정보가 변경되었습니다",
    COL_MAIN_DISPLAY: "메인 추가 노출",

    MODAL_TITLE_ADD_CAR_MANAGER_REPLACEMENT: "대무자 등록",
    MODAL_TITLE_EDIT_CAR_MANAGER_REPLACEMENT: "대무자 수정",

    START_DATE: "시작일",
    OFF_CAR_MANAGER: "부재자",
    REPLACE_CAR_MANAGER: "대무자",
    OFF_DAY_RANGE: "부재기간",
    END_DATE: "종료일",
    REG_DATE: "등록일",
    DC_TYPE: "할인타입",
    DC_KIND: "할인항목",
    DC_VALUE: "할인율(액)",
    SAVE_ESTIMATION: "견적 저장",
    NICE_CONNECTED_AT: "NICE 연동일",
    MANUFACTURER: "제조사",
    COSS_ID: "COSS ID",
    COSS_MODEL: "COSS 모델",
    NICE_MODEL: "NICE 모델",
    NICE_TRIM: "NICE 트림",
    FUEL: "연료",
    AHEAD_YN: "상품유형",
    COSS_CAR_PRICE: "COSS 차량가격",

    DC_VALUE_PERCENT: "할인율(%)",
    DC_VALUE_AMT: "할인액(₩)",
    HINT_DC_KIND: "할인항목을 선택해주세요",
    TITLE_ADD_NEW: "신규등록",

    HINT_DC_PERCENT: "할인율(%)을 입력해주세요",
    HINT_DC_VALUE: "할인금액(₩)을 입력해주세요",

    MODAL_TITLE_DC_MASTER_EDIT: "할인마스터 수정",
    MODAL_TITLE_DC_MASTER_ADD: "할인마스터 등록",
    FEEDBACK_EDIT_DC_MASTER: "할인마스터 정보가 변경되었습니다.",
    FEEDBACK_ADD_DC_MASTER: "할인마스터 정보가 등록되었습니다.",
    APY_TERM: "적용기간",

    HINT_BIZ_NO: "사업자번호를 입력해주세요",
    HINT_BIZ_NAME: "사업자명을 입력해주세요",
    HINT_CUSTOMER_NAME: "회원명을 입력해주세요",
    HINT_CONTRACTOR_NAME: "계약자명을 입력해주세요",
    HINT_CAR_MANAGER_NAME: "카매니저명을 입력해주세요",
    HINT_HP_NO: "휴대폰번호를 입력해주세요",
    HINT_CONTRACT_COUNT: "계약건수를 입력해주세요",
    HINT_CAR_NO: "차량번호를 입력해주세요",
    HINT_CAR_CLASS_NM: "세부모델을 입력해주세요",
    HINT_CAR_GRADE_NM: "트림을 입력해주세요",

    LABEL_BIZ_NAME: "사업자명",
    LABEL_CUSTOMER_NAME: "회원명",
    LABEL_HP_NO: "휴대폰번호",
    LABEL_CONTRACT_COUNT: "계약건수",
    LABEL_CAR_MANAGER_NAME: "카매니저명",
    LABEL_MARKETING_AGREEMENT: "마케팅활용동의",
    LABEL_MGM_JOIN_YN: "추천프로그램 가입",
    LABEL_SIGNED_AT: "가입일",
    LABEL_CUSTOMER_KIND: "회원구분",
    LABEL_LAST_CERTIFIED: "최신본인인증일",

    CONFIRM_USE_ON: "사용상태 변경",
    CONFIRM_USE_OFF: "미사용 변경",
    CONFIRM_CONTENT_USE_ON: "사용상태로 변경하시겠습니까?",
    CONFIRM_CONTENT_USE_OFF: "미사용상태로 변경하시겠습니까?",

    CONFIRM_DISPLAY_ON: "노출상태로 변경",
    CONFIRM_DISPLAY_OFF: "숨김상태로 변경",
    CONFIRM_TITLE_REVIEW_UC_DISPLAY_ON: "해당 리뷰가 타보중 메인에 노출됩니다.",
    CONFIRM_TITLE_REVIEW_UC_DISPLAY_OFF:
      "해당 리뷰가 타보중 메인에서 숨김 처리됩니다.",
    CONFIRM_TITLE_REVIEW_NC_DISPLAY_ON:
      "해당 리뷰가 신차장기 메인에 노출됩니다.",
    CONFIRM_TITLE_REVIEW_NC_DISPLAY_OFF:
      "해당 리뷰가 신차장기 메인에서 숨김 처리됩니다.",
    CONFIRM_TITLE_REVIEW_ALL_DISPLAY_ON:
      "해당 리뷰가 전체 후기리스트에 노출됩니다.",
    CONFIRM_TITLE_REVIEW_ALL_DISPLAY_OFF:
      "해당 리뷰가 전체 후기리스트에 숨김 처리됩니다.",

      CONFIRM_RSVIBILITY_ON: "예약 가능상태로 변경",
      CONFIRM_RSVIBILITY_OFF: "예약 불가능상태로 변경",
      CONFIRM_STATE_RSVIBILITY_ON:
        "해당 차량이 예약 가능상태로 변경됩니다.",
      CONFIRM_STATE_RSVIBILITY_OFF:
        "해당 차량이 예약 불가능상태로 변경됩니다.",

    FEEDBACK_REVIEW_UC_DISPLAY_STATE_CHANGE:
      "타보중 메인 노출상태가 변경되었습니다.",
    FEEDBACK_REVIEW_NC_DISPLAY_STATE_CHANGE:
      "신차장기 메인 노출상태가 변경되었습니다.",
    FEEDBACK_REVIEW_ALL_DISPLAY_STATE_CHANGE:
      "전체후기 노출상태가 변경되었습니다.",

    FEEDBACK_USE_YN_CHANGE: "사용상태가 변경되었습니다.",

    LABEL_PROD_TYPE: "상품유형",
    LABEL_MODEL: "모델",
    HINT_REP_CAR_CLASS_NM: "모델을 선택해주세요",
    LABEL_PRODUCT: "상품",
    LABEL_BRAND: "제조사",
    HINT_BRAND: "제조사를 선택해주세요",

    HINT_PRODUCT: "상품을 선택해주세요",
    LABEL_TRIM: "트림",
    HINT_TRIM: "트림을 선택해주세요",
    LABEL_EX_COLOR: "외장색상",
    HINT_EX_COLOR: "외장색상을 선택해주세요",
    LABEL_IN_COLOR: "내장색상",
    HINT_IN_COLOR: "내장색상을 선택해주세요",
    LABEL_EXTRA_OPTIONS: "추가옵션",
    HINT_EXTRA_OPTION: "추가옵션을 선택해주세요",

    LABEL_CONTRACT_TYPE: "계약유형",
    HINT_CONTRACT_TYPE: "계약유형을 선택해주세요",

    LABEL_CONTRACT_TERM: "계약기간",
    HINT_CONTRACT_TERM: "계약기간을 선택해주세요",

    LABEL_INSR_AGE: "보험연령",
    HINT_INSR_AGE: "보험연령을 선택해주세요",

    LABEL_MILEAGE: "약정주행거리",
    HINT_MILEAGE: "약정주행거리를 선택해주세요",

    LABEL_DRIVER_RANGE: "운전자범위",
    HINT_DRIVER_RANGE: "운전자범위를 선택해주세요",

    LABEL_FIX_SERVICE: "정비서비스",
    HINT_FIX_SERVICE: "정비서비스를 선택해주세요",

    LABEL_RENT_SERVICE: "대차서비스",
    HINT_RENT_SERVICE: "대차서비스를 선택해주세요",

    LABEL_RENT_LAB_COV: "대물",
    HINT_RENT_LAV_COV: "대물한도를 선택해주세요",

    LABEL_CREDIT_RATE: "신용등급",
    HINT_CREDIT_RATE: "신용등급을 선택해주세요",

    LABEL_DEPOSIT_WAY: "초기납부조건",
    HINT_DEPOSIT_WAY: "초기납부조건을 선택해주세요",

    LABEL_DEPOSIT: "최소 담보율",
    HINT_DEPOSIT: "담보율을 선택해주세요",

    LABEL_F_DEPOSIT: "선수금",
    HINT_F_DEPOSIT: "선수금을 선택해주세요",

    LABEL_DEFAULT_RENT_FEE: "기본 월 렌탈료(표준렌탈료)",
    LABEL_DEFAULT_DC: "다이렉트 기본할인",
    LABEL_CREDIT_DC: "신용등급 특별할인",
    LABEL_NO_ACC_DC: "무사고 할인",
    LABEL_RE_ORDER_DC: "추가계약 할인",
    LABEL_PROMOTION_DC: "프로모션 할인",
    LABEL_EMPLOYEE_DC: "임직원 할인",
    LABEL_FINAL_RENT_FEE: "다이렉트 전용 월 렌탈료",

    LABEL_NICE_INNER_COLOR: "NICE 내장 색상",
    HINT_NICE_COLOR_COPY_TARGET: "복사할 NICE 색상을 선택해주세요",
    LABEL_NICE_CUSTOM_COLOR_NAME: "커스텀 컬러명",
    HINT_NICE_CUSTOM_COLOR_NAME: "색상명을 입력해주세요",

    /**
     * Table column
     */
    COL_REQ_DATE: "요청일",
    COL_TRANSFER_REQUEST_CAR_MANAGER_NAME: "이관요청자 성명",
    COL_TRANSFER_RECEIVE_CAR_MANAGER_NAME: "이관받는자 성명",
    COL_TRANSFER_CONTRACT_COUNT: "이관 계약건수",
    COL_TRANSFER_REASON: "이관사유",
    COL_TRANSFER_REJECT_REASON: "반려사유",
    COL_TRANSFER_STATE: "이관상태",
    COL_TRANSFER_UPDATED_AT: "처리일",
    COL_EST_DATE: "견적일",
    COL_EST_EXPIRED_DATE: "만료일",
    COL_CUSTOMER_NAME: "회원명",
    COL_SRVC_ID: "차량구분",
    COL_SELLING_TYPE: "판매유형",
    COL_PROD_TYPE: "상품유형",
    COL_CAR_BRAND: "제조사",
    COL_MODEL: "모델",
    COL_DETAIL_MODEL: "세부모델",
    COL_TRIM: "트림",
    COL_CAR_OPTION: "선택옵션",
    COL_CONTRACT_TERM: "계약기간",
    COL_MILEAGE_LIMIT: "약정주행거리",
    COL_DRIVER_RANGE: "운전자범위",
    COL_DEPOSIT_WAY: "담보조건",
    COL_EST_TYPE: "견적유형",
    COL_EST_STATE: "견적상태",
    COL_CONTRACT_DATE: "계약일",
    COL_CAR_NO: "차량번호",
    COL_HP_NO: "휴대폰번호",
    COL_CAR_EX_COLOR: "외장색상",
    COL_CAR_INNER_COLOR: "내장색상",

    /**
     * Modal Titles
     */
    MODAL_TITLE_ADD_NICE_TRIM: "NICE 트림 등록",
    MODAL_TITLE_ADD_NICE_INNER_COLOR: "NICE 내장색상 등록",
    MODAL_TITLE_GRADE_RATE_SIMULATOR: "보증보험 수수료 산출",
    MODAL_DESC_GRADE_RATE_SIMULATOR:
      "산출되는 보증보험 수수료는 연간 수수료입니다.",

    /**
     * List Page Header
     */
    HEADER_CAR_MANAGER_TRANSFER_MANAGER: "회원 카매니저 이관",
    DESC_CAR_MANAGER_TRANSFER_MANAGER:
      "회원이관 요청상태는 우측의 관리버튼을 통해 '반려'와 '승인'처리를 진행해주세요. 반려 처리시 반드시 '반려사유'를 작성해야합니다.",
    T_HEADER_CAR_MANAGER_TRANSFER_MANAGER: "카매니저 회원이관 요청 리스트",

    /** 
     * 중고차 시뮬레이션
    */
    REQUIRED_CAR_NO: "차량번호를 입력 후 [확인] 버튼을 클릭해주세요.",
    SUCCESS_CAR_NO: "시뮬레이션 가능한 차량입니다.\n견적조건을 설정한 후 견적내기를 진행하세요.",
    FAIL_CAR_NO: "시뮬레이션 불가능한 차량입니다.\n차량번호를 확인해주세요.",
  },
});

export default commonStrings;
