// 견적관리 컬럼
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_MEDIUM,
} from "../../../../../../../../utils/table-utils";

export const createUserInfoHistoryCols = (): Array<TableColumn> => {
  return [
    // 변경일자
    createTableCol("regDtm", "변경일자", FilterType.Date, {
      width: CW_MEDIUM,
    }),
    // 변경주체
    createTableCol("updUser", "변경주체", FilterType.Date, {
      width: CW_MEDIUM,
      flex: 1,
    }),
    // TODO TEST
    createTableCol("b", "내역(TBD)", FilterType.Date, {
      width: CW_MEDIUM,
      flex: 1,
      valueGetter: (params) => {
        const { data } = params;
        return JSON.stringify(data);
      },
    }),
  ];
};
