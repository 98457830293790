import React from "react";
import { FormikProps, FormikValues } from "formik";
import FormItem from "../../../../../../components/shared/data-entry/antd/FormItem";
import Input from "../../../../../../components/shared/data-entry/antd/Input";
import { FormElementType } from "../../../../../../components/shared/data-entry/FormBuilder/types";
import CustomerConsultingRequestDetail from "../../../../../../models/CustomerConsultingRequestDetail";
import useAuth from "../../../../../../hooks/useAuth";

export const createInitialValues = () => {
  return {
    // 상담완료여부
    cnslFinYn: false,

    // 상담사메모
    cnslPicMemo: "",
  };
};

export const createInitialValuesFromConsultingDetail = (
  consultingDetail: CustomerConsultingRequestDetail
) => {
  const { cnslFinYn, cnslPicMemo,  } = consultingDetail;

  return {
    // 상담완료여부
    cnslFinYn: cnslFinYn === "Y",

    // 상담사메모
    cnslPicMemo: cnslPicMemo || "",
  };
};

export const createConsultingRequestParam = (values: FormikValues) => {
  const { cnslFinYn, cnslPicMemo } = values;

  return {
    cnslFinYn: cnslFinYn ? "Y" : "N",
    cnslPicMemo: cnslPicMemo || "",
  };
};

export const createFormInfo = () => {
  return [
    // 상담완료 여부
    {
      key: "cnslFinYn",
      type: FormElementType.Checkbox,
      label: "상담완료 여부",
    },
    // 상담사 메모
    {
      key: "cnslPicMemo",
      type: FormElementType.TextArea,
      label: "상담사 메모",
      placeholder: "메모를 입력해주세요",
    },
  ];
};
