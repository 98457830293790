class CarManager {
  // 카매니저ID
  public carmanId = "";

  // 회사ID
  public homeClsCd = "";

  // 회사명
  public homeClsNm = "";

  // 부서ID
  public deptId = "";

  // 부서명
  public deptNm = "";

  // 사용자ID
  public userId = "";

  // 사용자명
  public userNm = "";

  // 카매니저 핸드폰번호
  public carmanHpNo = "";

  // 이메일
  public email = "";

  // 담당권역코드
  public siteCd = "";

  // 담당권역명
  public siteNm = "";

  // 카매니저별 유효 계약 건 수
  public cntrCnt = "";

  // 등록자
  public regUser = "";

  // 등록일자
  public regDtm = "";

  // 변경자
  public updUser = "";

  // 변경일자
  public updDtm = "";
}

export default CarManager;
