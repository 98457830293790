import styled from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const SectionTitle = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.common.colors.black};
  font-size: 18px;
  flex: 0 0 160px;
  margin-right: 24px;
`;

export const SectionDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.common.colors.border};
  margin: 24px 0;
`;

export const ContentText = styled.div`
  color: #9698a8;
  font-weight: 400;
  font-size: 14px;
`;

export const InnerSectionTitle = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.common.colors.black};
  font-size: 14px;
`;
