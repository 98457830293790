import React, { useState } from "react";
import { groupBy } from "lodash";
import { Formik, FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  ColorEditParam,
  editColorConnectionInfo,
  fetchCossCarInfoDetail,
  fetchNiceCarInfoOptions,
} from "../../../../../apis/coss-car-infos";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import {
  formatCurrency,
  getErrorMessage,
} from "../../../../../utils/common-utils";
import CossCarInfoDetail from "../../../../../models/CossCarInfo/CossCarInfoDetail";
import CossMaster from "../../../../../models/CossCarInfo/CossMaster";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import { getNiceColorOptions } from "../NiceCarInfoConnectModal/utils";
import Select from "../../../../../components/shared/data-entry/antd/Select";
import commonStrings from "../../../../../constants/strings";
import OptionDetail from "../../../../../models/CossCarInfo/OptionDetail";
import NiceInfoColor from "../../../../../models/CossCarInfo/NiceInfoColor";
import { COSS_COLOR_CLS_EX } from "../../../../../models/CossCarInfo/CossColor";

interface Props extends ModalProps {
  cossMaster?: CossMaster;
}

const ColorConnectionEditModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, cossMaster } = props;
  const [dataFetching, setDataFetching] = useState<boolean>(false);
  const [carInfoDetail, setCarInfoDetail] = useState<CossCarInfoDetail>();
  const [initialValues, setInitialValues] = useState<any>({});
  const [optionDetail, setOptionDetail] = useState<OptionDetail>();
  const [confirmLoading, setConfirmLoading] = useState(false);

  let formik: FormikProps<FormikValues>;

  const formItemLayoutHorizontal = {
    wrapperCol: { span: 12 },
    labelCol: { span: 12 },
  };

  /**
   * Private Functions
   */

  const onModalOpen = async () => {
    if (cossMaster) {
      await requestFetchCarInfoDetail(cossMaster.modlId);
    } else {
      setCarInfoDetail(undefined);
    }
  };

  const requestFetchCarInfoDetail = async (
    cossModlId: string,
    dataFetching = true
  ) => {
    setDataFetching(dataFetching);
    try {
      const carInfoDetail = await fetchCossCarInfoDetail(cossModlId);

      if (carInfoDetail && carInfoDetail.niceInfo) {
        const cossOptionDetailInfo = await fetchNiceCarInfoOptions(
          cossModlId,
          carInfoDetail.niceInfo.modeGrdId
        );
        setCarInfoDetail(carInfoDetail);

        // 옵션 조회
        setOptionDetail(cossOptionDetailInfo);

        // Form 초기값 설정
        const updatedInitialValues = {};
        if (carInfoDetail && carInfoDetail.niceInfoColorList) {
          carInfoDetail.niceInfoColorList.forEach((colorInfo) => {
            const { cossColorId, modeColorId } = colorInfo;
            if (cossColorId && modeColorId) {
              if (
                cossOptionDetailInfo.cossColorList
                  .map(({ cossColorId }) => cossColorId)
                  .indexOf(cossColorId) !== -1
              ) {
                // @ts-ignore
                updatedInitialValues[cossColorId] = modeColorId;
              }
            }
          });
        }
        setInitialValues(updatedInitialValues);
      }
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  const requestEditColorConnectionInfo = async (values: FormikValues) => {
    setConfirmLoading(true);
    if (carInfoDetail && carInfoDetail.niceInfo) {
      try {
        const params: ColorEditParam[] = [];
        Object.keys(values).forEach((cossColorId) => {
          const modeColorId = values[cossColorId];
          if (modeColorId) {
            params.push({
              modeColorId,
              cossColorId,
            });
          }
        });

        const grouped = groupBy(params, "modeColorId");
        let dupError = false;
        let dupModeColorId = "";
        Object.keys(grouped).forEach((modeColorId) => {
          if (grouped[modeColorId].length > 1) {
            dupModeColorId = modeColorId;
          }
        });

        // 해당 컬러 아이디에 seq 있는지 확인
        if (dupModeColorId) {
          if (optionDetail) {
            const filtered = optionDetail.niceInfoColorList.filter(
              ({ modeColorId }) => {
                return modeColorId === dupModeColorId;
              }
            );
            if (filtered.length > 0) {
              dupError = filtered[0].modeColorAddSeq !== null;
            }
          }
        }

        if (dupError) {
          alertError("동일한 색상정보로 연결된 데이터가 존재합니다.");
        } else {
          await editColorConnectionInfo(
            carInfoDetail.niceInfo.modeGrdId,
            params
          );
          alertSuccess("색상 연결 정보가 변경되었습니다.");
          if (onCancel) onCancel();
          if (onDataChange) onDataChange();
        }
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    await requestEditColorConnectionInfo(values);
  };

  /**
   * Render Helpers
   */

  const getCossColorOptionLabel = (
    colorName: string,
    niceInfoColors: NiceInfoColor[],
    niceColorId: string,
    price: string
  ) => {
    const filtered = niceInfoColors.filter(({ modeColorId }) => {
      return modeColorId === niceColorId;
    });
    let imgUrl = "";
    if (filtered.length > 0) {
      imgUrl = filtered[0].modeColorImgUrl;
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {colorName}
        {imgUrl && (
          <img
            src={imgUrl}
            alt="차량색상컬러칩"
            style={{
              marginLeft: "8px",
              marginRight: "4px",
            }}
          />
        )}
        - {formatCurrency(price)}
      </div>
    );
  };

  const renderOptionsForm = (renderProps: FormikProps<FormikValues>) => {
    const { values, errors, touched, setFieldValue } = renderProps;
    if (optionDetail && optionDetail.cossColorList) {
      return optionDetail.cossColorList.map((colorInfo) => {
        const {
          cossColorId,
          cossColorNm,
          cossColorCls,
          cossColorClsNm,
          cossColorAmt,
        } = colorInfo;
        const value = values[cossColorId];
        let errMsg;

        if (errors[cossColorId] && touched[cossColorId]) {
          errMsg = errors[cossColorId] as string;
        }

        const colorName = `[${cossColorClsNm}]${cossColorNm}`;

        return (
          <FormItem
            style={{
              marginBottom: "16px",
            }}
            key={cossColorId}
            help={errMsg}
            validateStatus={errMsg && "error"}
            required
            label={getCossColorOptionLabel(
              colorName,
              optionDetail?.niceInfoColorList || [],
              value,
              cossColorAmt
            )}
            {...formItemLayoutHorizontal}
          >
            <Select
              style={{
                width: "100%",
              }}
              value={value}
              placeholder={commonStrings.HINT_NICE_COLOR}
              options={getNiceColorOptions(
                optionDetail?.niceInfoColorList || [],
                cossColorCls === COSS_COLOR_CLS_EX
              )}
              onChange={(value) => {
                setFieldValue(cossColorId, value);
              }}
            />
          </FormItem>
        );
      });
    }
    return "";
  };

  const renderForm = () => {
    const trimName = `${cossMaster?.modlNm} - ${formatCurrency(
      cossMaster?.carAmt || ""
    )}`;
    return (
      <Formik
        enableReinitialize
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        innerRef={(ref: FormikProps<FormikValues>) => {
          formik = ref;
        }}
      >
        {(renderProps) => {
          return (
            <div>
              <FormItem
                label="트림"
                required
                style={{
                  marginBottom: "16px",
                }}
                {...formItemLayoutHorizontal}
              >
                {trimName}
              </FormItem>
              {renderOptionsForm(renderProps)}
            </div>
          );
        }}
      </Formik>
    );
  };

  return (
    <Modal
      onOk={() => {
        formik?.handleSubmit();
      }}
      confirmLoading={confirmLoading}
      title="색상 연결 수정"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
    >
      {dataFetching ? renderLoading() : renderForm()}
    </Modal>
  );
};

ColorConnectionEditModal.defaultProps = {};
export default ColorConnectionEditModal;
