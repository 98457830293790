import strings from "../constants/strings";
import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import { formatDateString } from "../../../../utils/date-utils";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";

export const createCustomerCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createServerTableCol("regDtm", strings.COL_REG_DATE, {
      width: CW_MEDIUM,
      cellRenderer: (data) => {
        const { value } = data;
        if (value) {
          return formatDateString(value);
        }
        return "";
      },
    }),
    // 등록일
    createServerTableCol("authDtm", strings.COL_AUTH_DATE, {
      width: CW_MEDIUM,
      cellRenderer: (data) => {
        const { value } = data;
        if (value) {
          return formatDateString(value);
        }
        return "";
      },
    }),
    // 회원구분
    createServerTableCol("custCls", strings.COL_CUSTOMER_KIND, {
      width: CW_SMALL,
    }),
    // 본인인증 여부
    createServerTableCol("ciYn", strings.COL_IS_PASS_AUTHED, {
      width: CW_SMALL_M,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "완료" : "미완료",
          },
        };
      },
    }),
    // 회원명
    createServerTableCol("custNm", strings.COL_NAME, {
      minWidth: CW_SMALL,
      flex: 1,
    }),
    // 사업자명
    createServerTableCol("busrCustNm", strings.COL_BIZ_NAME, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // // 개인사업자 인증상태
    // createServerTableCol("addVrfCnt", strings.COL_BIZ_AUTH_STATE, {
    //   minWidth: CW_MEDIUM,
    //   flex: 1,
    // }),
    // 휴대폰번호
    createServerTableCol("hpNo", strings.COL_HP_NO, {
      width: CW_LARGE,
    }),
    //사용자 비고
    createServerTableCol("remark", strings.COL_USERNOTE, {
      width: CW_MEDIUM,
    }),

    // 보유 포인트
    createServerTableCol("holdPoint", strings.COL_POINT, {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 사업자구분
    createServerTableCol("busrClsNm", strings.COL_BIZ_KIND, {
      width: CW_LARGE,
    }),
    // 계약건수
    createServerTableCol("cntrCnt", strings.COL_CONTRACT_COUNT, {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 담당카매니저
    // createCarManagerDetailButtonCol(),
    createServerTableCol("carmanNm", strings.COL_CAR_MANAGER, {
      width: CW_MEDIUM,
    }),
    // 소개프로그램 가입여부
    createServerTableCol("skFrnsYn", strings.COL_IS_SK_INVITATION_SIGNED, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "가입" : "미가입",
          },
        };
      },
    }),

    // 마케팅 활용 동의
    createServerTableCol("smsAgreYn", strings.COL_SMS_MARKETING_AGREEMENT, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "동의" : "미동의",
          },
        };
      },
    }),
    createServerTableCol("tmAgreYn", strings.COL_TM_MARKETING_AGREEMENT, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "동의" : "미동의",
          },
        };
      },
    }),
    createServerTableCol("mktAgreYn", strings.COL_OVERALL_MARKETING_AGREEMENT, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "동의" : "미동의",
          },
        };
      },
    }),
    {
      ...createActionCol(80),
    },
  ];
};
