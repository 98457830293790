import moment, { Moment, MomentInput } from "moment";
import { RangeValue } from "rc-picker/lib/interface";

export const formatDateStringWithTime = (date: MomentInput) => {
  return moment(date).format("YY.MM.DD HH:mm");
};

export const formatDateString = (date: MomentInput, withTime = false) => {
  if (date) {
    if (!withTime) return moment(date, "YYYYMMDDHHmmss").format("YY/MM/DD");

    return moment(date, "YYYYMMDDHHmmss").format("YY/MM/DD HH:mm");
  }
  return "";
};

export const getTodayDateRange = () => {
  const startDate = moment().startOf("day");
  const endDate = moment().startOf("day");
  return [startDate, endDate] as RangeValue<Moment>;
};

export const getWeekDateRange = () => {
  const startDate = moment().subtract(1, "week");
  const endDate = moment().startOf("day");
  return [startDate, endDate] as RangeValue<Moment>;
};

export const get1MonthDateRange = () => {
  const startDate = moment().subtract(1, "M");
  const endDate = moment().startOf("day");
  return [startDate, endDate] as RangeValue<Moment>;
};

export const get3MonthDateRange = () => {
  const startDate = moment().subtract(3, "M");
  const endDate = moment().startOf("day");
  return [startDate, endDate] as RangeValue<Moment>;
};

export const get1YearDateRange = () => {
  const startDate = moment().subtract(1, "year");
  const endDate = moment().startOf("day");
  return [startDate, endDate] as RangeValue<Moment>;
};

export const getYesterdayDateRange = () => {
  const startDate = moment().subtract(1, "day");
  const endDate = moment().subtract(1, "day");
  return [startDate, endDate] as RangeValue<Moment>;
};
