import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LARGE,
  CW_LONG_TEXT,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import Switch from "../../../../components/shared/data-entry/antd/Switch";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { formatNumber } from "../../../../utils/common-utils";
import { getUcProdType } from "../../../../constants/enums";

export const AR_DISPLAY_STATE_SWITCH = "displayStateSwitchRenderer";

const renderSwitch = (
  onChange: (checked: boolean, prodId: string, carId: string, ncarClsFlag: string) => void,
  key = "viewYn"
) => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    const { prodId, carId, ncarClsFlag } = data;
    return (
      <Switch
        checked={data[key] === "Y"}
        onClick={(checked) => {
          onChange(checked, prodId, carId, ncarClsFlag);
        }}
      />
    );
  };
};

export const createSwitchRenderer = (
  onChange: (checked: boolean, prodId: string, carId: string, ncarClsFlag: string) => void,
  key = "viewYn"
) => ({
  [AR_DISPLAY_STATE_SWITCH]: renderSwitch(onChange, key),
});

export const createNewCarCatalogCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", "등록일", FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // NICE 연동일
    createTableCol("niceConYn", "NICE 연동여부", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 상품유형
    createTableCol("ncarClsFlag", "상품유형", FilterType.Text, {
      width: CW_SMALL_M,
      valueGetter: ({ data }) => {
        const { ncarClsFlag } = data;

        return getUcProdType(ncarClsFlag) || "-";
      },
    }),
    // 노출상태
    createTableCol("viewYn", "노출상태", FilterType.Text, {
      cellRenderer: AR_DISPLAY_STATE_SWITCH,
      width: CW_SMALL,
    }),
    // cossId
    createTableCol("cossId", "COSS_ID", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 차량번호
    createTableCol("carNo", "차량번호", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 제조사
    createTableCol("brandNm", "제조사", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 모델
    createTableCol("repCarClassNm", "모델", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 세부 모델
    createTableCol("carClassNm", "세부모델", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 트림
    createTableCol("carGradeNm", "트림", FilterType.Text, {
      minWidth: CW_LONG_TEXT,
      flex: 1,
    }),
    // 연식
    createTableCol("yearType", "연식", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 주행거리
    createTableCol("lastDtc", "주행거리", FilterType.Text, {
      width: CW_SMALL_M,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.lastDtc)}km`;
      },
    }),
    // 연료
    createTableCol("repFuelNm", "연료", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 변속
    createTableCol("istdTrans", "변속", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 차량정보
    createTableCol("carNmInfo", "차량정보", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 정비이력
    createTableCol("fixCont", "정비이력", FilterType.Text, {
      width: CW_SMALL,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
