import React, { useCallback, useState } from "react";
import { FormikProps, FormikValues } from "formik";
import strings from "../../constants/strings";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import {
  createAddDeliveryTermInfoParam,
  createEditDeliveryTermInfoParam,
  createFormInfo,
  createInitialValues,
  createInitialValuesFromData,
  createValidationSchema,
} from "./utils/form-utils";
import {
  renderLoading,
  alertError,
  alertSuccess,
} from "../../../../../utils/render-utils";
import {
  addDeliveryTermInfo,
  editDeliveryTermInfo,
  fetchDeliveryTermTargetTrims,
} from "../../../../../apis/dlv-term";
import { ModalProps } from "../../../../types";
import { getErrorMessage } from "../../../../../utils/common-utils";
import NewCarDeliveryTermInfo from "../../../../../models/NewCarDeliveryTermInfo";

interface Props extends ModalProps {
  visible: boolean;
  onCancel?: () => void;
  deliveryTermInfo?: NewCarDeliveryTermInfo;
}

const NewCarDeliveryInfoModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, deliveryTermInfo, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );
  const [prodOptions, setProdOptions] = useState<Array<any>>([]);

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    await requestFetchProductOptions();
    if (deliveryTermInfo) {
      setInitialValues(createInitialValuesFromData(deliveryTermInfo));
    } else {
      setInitialValues(createInitialValues());
    }
  };

  /**
   * Private Functions
   */

  const requestFetchProductOptions = async () => {
    setDataFetching(true);
    try {
      const { items } = await fetchDeliveryTermTargetTrims();
      setProdOptions(
        items.map((catalog) => {
          return {
            label: `${catalog.yearType} ${catalog.prodNm} ${catalog.carGradeNm} - ${catalog.cossFuelNm}`,
            value: catalog.modeGrdId,
          };
        })
      );
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  const requestAddData = async (values: FormikValues) => {
    setConfirmLoading(true);
    try {
      await addDeliveryTermInfo(createAddDeliveryTermInfoParam(values));
      alertSuccess(strings.FEEDBACK_ADD);
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditData = async (values: FormikValues) => {
    if (deliveryTermInfo) {
      setConfirmLoading(true);
      try {
        await editDeliveryTermInfo(
          deliveryTermInfo?.dlvTermId,
          createEditDeliveryTermInfoParam(values)
        );
        alertSuccess(strings.FEEDBACK_ADD);
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const getModalTitle = useCallback(() => {
    if (deliveryTermInfo) {
      return strings.TITLE_MODAL_HEADER_EDIT;
    }
    return strings.TITLE_MODAL_HEADER_ADD;
  }, [deliveryTermInfo]);

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (deliveryTermInfo) {
      await requestEditData(values);
    } else {
      await requestAddData(values);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title={getModalTitle()}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={createFormInfo(prodOptions, !!deliveryTermInfo)}
          validationSchema={createValidationSchema()}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

NewCarDeliveryInfoModal.defaultProps = {};
export default NewCarDeliveryInfoModal;
