import moment from "moment";
import * as Yup from "yup";
import { ExhibitionMaster } from "../../../../../../../../apis/ex/types";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../../components/shared/data-entry/FormBuilder/types";
import { UPLOAD_CODES } from "../../../../../../../../constants/enums";
import errorMessages from "../../../../../../../../constants/errors";
import { getInitialDateRangeFromRaw } from "../../../../../../../../utils/form-utils";

export const createInitialValues = () => {
  return {
    title: "",
    explain: "",
    videoLink: "",
    videoRate: null,
    pcThumbnail: [],
    tabThumbnail: [],
    mobThumbnail: [],
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required(errorMessages.REQUIRED_FIELD),
    explain: Yup.string().required(errorMessages.REQUIRED_FIELD),
    videoLink: Yup.string().required(errorMessages.REQUIRED_FIELD),
    videoRate: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    pcThumbnail: Yup.array().min(1, errorMessages.REQUIRED_FIELD),
    tabThumbnail: Yup.array().min(1, errorMessages.REQUIRED_FIELD),
    mobThumbnail: Yup.array().min(1, errorMessages.REQUIRED_FIELD),
  });
};

export const createForms = (): FormInfo[] => {
  return [
    {
      key: "title",
      label: "타이틀",
      type: FormElementType.Input,
      placeholder: "기획전 제목을 입력해주세요",
      required: true,
    },
    {
      key: "explain",
      label: "설명",
      type: FormElementType.TextArea,
      placeholder: "제목 하단 설명을 입력해주세요",
      required: true,
    },
    {
      key: "videoLink",
      label: "동영상 링크",
      type: FormElementType.Input,
      placeholder: "동영상 링크 입력해주세요",
      required: true,
    },
    {
      key: "videoRate",
      label: "동영상 규격",
      type: FormElementType.Select,
      placeholder: "동영상 규격을 선택해주세요.",
      options: [
        { label: "가로형", value: "W" },
        { label: "세로형", value: "V" },
      ],
      required: true,
      config: {
        min: 1,
      },
    },
    {
      key: "pcThumbnail",
      label: "PC 썸네일",
      type: FormElementType.ImageUpload,
      uploadCodeInfo: UPLOAD_CODES.EVENT,
      required: true,
    },
    {
      key: "tabThumbnail",
      label: "Tablet 썸네일",
      type: FormElementType.ImageUpload,
      uploadCodeInfo: UPLOAD_CODES.EVENT,
      required: true,
    },
    {
      key: "mobThumbnail",
      label: "Mobile 썸네일",
      type: FormElementType.ImageUpload,
      uploadCodeInfo: UPLOAD_CODES.EVENT,
      required: true,
    },
  ];
};
