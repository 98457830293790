import React, { useState } from "react";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import Modal from "../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../types";
import {
  alertError,
} from "../../../../utils/render-utils";
import { getErrorMessage } from "../../../../utils/common-utils";
import {
  uploadFiles
} from "../../../../apis/fileUpload";

const { Dragger } = Upload;

interface Props extends ModalProps {
  saveThenApi?: string;
}

const ExcelUploadModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, saveThenApi } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [apiurl, setApiurl] = useState<string>();

  const onModalOpen = () => {
    setFileList([]);
    if (saveThenApi) {
      setApiurl(saveThenApi);
    }
  };

  const handleUpload = async () => {
    setConfirmLoading(true);
    // 새로 업로드할 이미지
    const newUploadFiles = fileList.filter((file) => {
      return !file.status;
    });

    if (saveThenApi) {
      try {
        if (newUploadFiles.length > 0) {
          await uploadFiles(apiurl as string, newUploadFiles);
        }
        if (onCancel) onCancel();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const handleRemoveFile = (file: UploadFile<RcFile>) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleBeforeUpload = (file: RcFile, files: RcFile[]) => {
    const newFiles = files.map((file) => {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      file.url = URL.createObjectURL(file);
      return file;
    });

    //setFileList([...fileList, ...newFiles]);
    setFileList(newFiles);
    return false;
  };

  const renderDetail = () => {
    return (
      <div>
        <Dragger
          accept=".xlsx, xls"
          isImageUrl={() => {
            return true;
          }}
          listType="text"
          fileList={fileList}
          onRemove={handleRemoveFile}
          beforeUpload={handleBeforeUpload}
          style={{
            marginBottom: "10px",
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">엑셀 업로드</p>
          <p className="ant-upload-hint">
            이곳에 엑셀파일을 드래그하거나 PC에서 업로드 하세요
          </p>
        </Dragger>
      </div>
    );
  };

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="엑셀업로드"
      onOk={handleUpload}
    >
      {renderDetail()}
    </Modal>
  );
};

export default ExcelUploadModal;
