import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { CustomerConsultingRequestEditParam } from "./types";
import CustomerConsultingRequest from "../../models/CustomerConsultingRequest";
import CustomerConsultingRequestDetail from "../../models/CustomerConsultingRequestDetail";

const apiRootUrl = `${ROOT_URL}/admin/counseling-recpt`;

/**
 * 고객상담요청 리스트 조회
 */
export const fetchCustomerConsultingRequests = async (
  stDt: string,
  endDt: string
): Promise<ListItemResponse<CustomerConsultingRequest>> => {
  const res = await request(HttpMethod.Get, apiRootUrl, {
    stDt,
    endDt,
  });

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(CustomerConsultingRequest, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 고객상담 상세 조회
 */

export const fetchCustomerConsultingRequestDetail = async (
  cnslRecptId: string
): Promise<CustomerConsultingRequestDetail> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/${cnslRecptId}`);
  const { data } = res;
  const { response } = data;
  return plainToClass(CustomerConsultingRequestDetail, response);
};

/**
 * 고객상담신청 수정
 */
export const editCustomerConsultingRequest = async (
  cnslRecptId: string,
  params: CustomerConsultingRequestEditParam
) => {
  await request(
    HttpMethod.Put,
    `${apiRootUrl}/${cnslRecptId}`,
    undefined,
    params
  );
};
