import styled from "styled-components";
import descriptionArrow from "../../../../assets/svgs/description-arrow.svg";

export const SubHeaderDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  background-color: ${(props) => props.theme.common.colors.point};
  width: 14px;
  height: 14px;
  line-height: 8px;
  vertical-align: middle;
  text-align: center;
  margin-right: 8px;
  border-radius: 7px;
`;

export const DescriptionArrow = styled.img.attrs({
  src: descriptionArrow,
})``;

export const SubHeaderDescription = styled.h3`
  font-size: 12px;
  color${(props) => props.theme.common.colors.subHeaderTitle};
  display: block;
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;
