export const OPTION_ALL = "";
export const OPTION_FUEL_TYPE_GASOLINE = "Gasoline";
export const OPTION_FUEL_TYPE_DIESEL = "Diesel";
export const OPTION_FUEL_TYPE_LPG = "LPG";
export const OPTION_FUEL_TYPE_HYBRID = "Gasoline/Hybrid";
export const OPTION_FUEL_TYPE_ELECTRIC = "Electric";
export const OPTION_FUEL_TYPE_HYDROGEN = "Hydrogen";

export const COSS_FUEL_TYPE_GASOLINE = "210001";
export const COSS_FUEL_TYPE_DIESEL = "210002";
export const COSS_FUEL_TYPE_LPG = "210003";
export const COSS_FUEL_TYPE_HYDROGEN = "210004";
export const COSS_FUEL_TYPE_HYBRID = "210005";
export const COSS_FUEL_TYPE_ELECTRIC = "210007";

export const FUEL_TYPE_COSS_MAPPER: Record<string, string> = {
  [OPTION_FUEL_TYPE_GASOLINE]: COSS_FUEL_TYPE_GASOLINE,
  [OPTION_FUEL_TYPE_DIESEL]: COSS_FUEL_TYPE_DIESEL,
  [OPTION_FUEL_TYPE_LPG]: COSS_FUEL_TYPE_LPG,
  [OPTION_FUEL_TYPE_HYBRID]: COSS_FUEL_TYPE_HYBRID,
  [OPTION_FUEL_TYPE_ELECTRIC]: COSS_FUEL_TYPE_ELECTRIC,
  // TODO 이슈 확인 필요 COSS에는 휘발유 + LPG, 나이스는 Hydrogen 으로 되어있음
  [OPTION_FUEL_TYPE_HYDROGEN]: COSS_FUEL_TYPE_HYDROGEN,
};

export const FUEL_TYPE_NICE_MAPPER: Record<string, string> = {
  [COSS_FUEL_TYPE_GASOLINE]: OPTION_FUEL_TYPE_GASOLINE,
  [COSS_FUEL_TYPE_DIESEL]: OPTION_FUEL_TYPE_DIESEL,
  [COSS_FUEL_TYPE_LPG]: OPTION_FUEL_TYPE_LPG,
  [COSS_FUEL_TYPE_HYBRID]: OPTION_FUEL_TYPE_HYBRID,
  [COSS_FUEL_TYPE_ELECTRIC]: OPTION_FUEL_TYPE_ELECTRIC,
  [COSS_FUEL_TYPE_HYDROGEN]: OPTION_FUEL_TYPE_ELECTRIC, // 수소 -> NICE 전기로 매핑
};

export const NICE_FUEL_TYPE_FILTER_OPTIONS = [
  {
    label: "전체",
    value: OPTION_ALL,
  },
  {
    label: "가솔린",
    value: OPTION_FUEL_TYPE_GASOLINE,
  },
  {
    label: "디젤",
    value: OPTION_FUEL_TYPE_DIESEL,
  },
  {
    label: "LPG",
    value: OPTION_FUEL_TYPE_LPG,
  },
  {
    label: "하이브리드",
    value: OPTION_FUEL_TYPE_HYBRID,
  },
  {
    label: "전기",
    value: OPTION_FUEL_TYPE_ELECTRIC,
  },
  {
    label: "수소",
    value: OPTION_FUEL_TYPE_HYDROGEN,
  },
];

export const COSS_FUEL_TYPE_FILTER_OPTIONS = [
  {
    label: "전체",
    value: OPTION_ALL,
  },
  {
    label: "휘발유",
    value: COSS_FUEL_TYPE_GASOLINE,
  },
  {
    label: "경유",
    value: COSS_FUEL_TYPE_DIESEL,
  },
  {
    label: "LPG",
    value: COSS_FUEL_TYPE_LPG,
  },
  {
    label: "하이브리드",
    value: COSS_FUEL_TYPE_HYBRID,
  },
  {
    label: "전기",
    value: COSS_FUEL_TYPE_ELECTRIC,
  },
];

export const NICE_CONNECT_FILTER_OPTIONS = [
  {
    label: "전체",
    value: OPTION_ALL,
  },
  {
    label: "연결",
    value: "Y",
  },
  {
    label: "미연결",
    value: "N",
  },
];

export const NICE_CONNECT_BRAND_FILTER_OPTIONS = [
  {
    label: "전체",
    value: OPTION_ALL,
  },
  {
    label: "현대",
    value: "현대",
  },
  {
    label: "제네시스",
    value: "제네시스",
  },
  {
    label: "기아",
    value: "기아",
  },
  {
    label: "쉐보레",
    value: "쉐보레",
  },
  {
    label: "쌍용",
    value: "쌍용",
  },
  {
    label: "테슬라",
    value: "테슬라",
  },
  {
    label: "BMW",
    value: "BMW",
  },
  {
    label: "벤츠",
    value: "벤츠",
  },
  {
    label: "아우디",
    value: "아우디",
  },
];
