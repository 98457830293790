import { GridApi } from "ag-grid-community";

export const applyFilterValues = (filterValues: any, api: GridApi) => {
  api.setFilterModel({
    filterDataStore: {
      type: "equals",
      filter: JSON.stringify(filterValues),
    },
  });
  api.paginationGoToPage(0);
};

export const getPageIndex = (startRow: number, limit: number) => {
  return (startRow + limit) / limit;
};

export const refreshServerSideTable = (api?: GridApi) => {
  api?.refreshServerSideStore({ purge: true });
};
