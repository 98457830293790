import React, { useState } from "react";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";

import TagoCarCatalogDetail from "../../../../../models/TagoCarCatalogDetail";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import {
  formatCurrency,
  formatNumber,
  getErrorMessage,
} from "../../../../../utils/common-utils";
import { ModalProps } from "../../../../types";
import {
  fetchTBCatalogDetail,
  updateUcTagobuyCatalogsPfmc,
  updateUcTagobuyCatalogsAccdHist,
  updateUcTagobuyCatalogsBlck,
  updateUcTagobuyRentAmt,
} from "../../../../../apis/tb-catalogs";

import { formatDateString } from "../../../../../utils/date-utils";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import {
  createTableCol,
  FilterType,
} from "../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_2X_LARGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../utils/table-utils";
import Button from "../../../../../components/shared/general/antd/Button";
import Image from "../../../../../components/shared/data-display/antd/Image";
import { getUcProdType } from "../../../../../constants/enums";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import TagoCarAmountModifyModal from "./pages/TagoCarAmountModifyModal";
import TagoCarMainOptionModifyModal from "./pages/TagoCarMainOptionModifyModal";
import {
  MOBILE_TAG_RENDERER,
  PC_TAG_RENDERER,
} from "../../../CatalogTagManager/utils/table-utils";
import {
  mobileTagRenderer,
  pcTagCellRenderer,
} from "../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { createCatalogTagCols } from "./utils/table-utils";

interface Props extends ModalProps {
  dataId?: string;
}

const TagoCarCatalogModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, dataId, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [catalogDetail, setCatalogDetail] = useState<TagoCarCatalogDetail>();
  const [carAmountModifyModalVisible, setCarAmountModifyModalVisible] = useState(false);
  const [mainOptionListModalVisible, setMainOptionListModalVisible] = useState(false);

  const tagCols = createCatalogTagCols();

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchCatalogDetail();
    setDataFetching(false);
  };

  const getUcTagobuyCatalogsPfmc = async (
    prodId: string,
    carId: string,
    carNo: string
  ) => {
    carCheckServicePost(carId, carNo);
  };

  const getUcTagobuyCatalogsPfmcSend = async (
    prodId: string,
    carId: string,
    carNo: string,
    checkId: string,
    exchange: string,
    sheetMetal: string,
    corrosion: string,
    scratch: string,
    crack: string,
    dent: string,
    frInPann: string,
    frReWhHous: string,
    fiDaFlPann: string,
    siQuPann: string,
    rePannTrFlor: string,
    roPannPaTray: string,
    hood: string,
    frFend: string,
    frDoor: string,
    reDoor: string,
    trLid: string
  ) => {
    await updateUcTagobuyCatalogsPfmc(
      prodId,
      carId,
      carNo,
      checkId,
      exchange,
      sheetMetal,
      corrosion,
      scratch,
      crack,
      dent,
      frInPann,
      frReWhHous,
      fiDaFlPann,
      siQuPann,
      rePannTrFlor,
      roPannPaTray,
      hood,
      frFend,
      frDoor,
      reDoor,
      trLid
    );
    setDataFetching(true);
    await requestFetchCatalogDetail();
    setDataFetching(false);
  };

  const getUcTagobuyCatalogsAccdHist = async (
    prodId: string,
    carId: string,
    carNo: string
  ) => {
    try {
      await updateUcTagobuyCatalogsAccdHist(prodId, carId, carNo);
      alertSuccess("사고이력 정보를 가져왔습니다.");

      setDataFetching(true);
      await requestFetchCatalogDetail();
      setDataFetching(false);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const getUcTagobuyCatalogsBlck = async (
    prodId: string,
    carId: string,
    carNo: string
  ) => {
    try {
      await updateUcTagobuyCatalogsBlck(prodId, carId, carNo);
      alertSuccess("블록오디세이 정보를 가져왔습니다.");

      setDataFetching(true);
      await requestFetchCatalogDetail();
      setDataFetching(false);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const getUcTagobuyMmRentAmt = async (
    prodId: string,
  ) => {
    try {
      await updateUcTagobuyRentAmt(prodId);
      alertSuccess("기본렌탈료를 가져왔습니다.");

      setDataFetching(true);
      await requestFetchCatalogDetail();
      setDataFetching(false);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };


  /**
   * Private Functions
   */
  const requestFetchCatalogDetail = async () => {
    if (dataId) {
      try {
        const catalogDetail = await fetchTBCatalogDetail(dataId);
        setCatalogDetail(catalogDetail);
      } catch (e) {
        alertError(getErrorMessage(e));
        setCatalogDetail(undefined);
      }
    }
  };

  /**
   * Event Actions
   */
  function carCheckServicePost(carId: string, carNo: string) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(`https://carcheckers.co.kr/sk/inspect`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        car_no: carNo,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        const { code } = response;
        if (code === "0000") {
          const partsDemandinfo = response.data.parts_demandinfo;
          const partsRecordinfo = response.data.parts_recordinfo;
          const checkId = response.data.check_id;
          let exchange = "없음";
          let sheetMetal = "없음";
          let corrosion = "없음";
          let scratch = "없음";
          let crack = "없음";
          let dent = "없음";

          let frInPann = "정상";
          let frReWhHous = "정상";
          let fiDaFlPann = "정상";
          let siQuPann = "정상";
          let rePannTrFlor = "정상";
          let roPannPaTray = "정상";
          let hood = "정상";
          let frFend = "정상";
          let frDoor = "정상";
          let reDoor = "정상";
          let trLid = "정상";
          let temp = "";

          for (let i = 1; i <= 37; i++) {
            if (partsDemandinfo[`${i}`] === "X" && exchange === "없음") {
              exchange = "있음";
            }
            if (partsDemandinfo[`${i}`] === "W" && sheetMetal === "없음") {
              sheetMetal = "있음";
            }
            if (partsDemandinfo[`${i}`] === "A" && corrosion === "없음") {
              scratch = "있음";
            }
            if (partsDemandinfo[`${i}`] === "U" && corrosion === "없음") {
              dent = "있음";
            }
            if (partsDemandinfo[`${i}`] === "C" && corrosion === "없음") {
              corrosion = "있음";
            }
            if (partsDemandinfo[`${i}`] === "T" && corrosion === "없음") {
              crack = "있음";
            }

            if (
              !(
                partsDemandinfo[`${i}`] === null ||
                partsDemandinfo[`${i}`] === ""
              )
            ) {
              if (partsDemandinfo[`${i}`] === "X") {
                temp = "교환";
              } else if (partsDemandinfo[`${i}`] === "W") {
                temp = "판금";
              } else if (partsDemandinfo[`${i}`] === "A") {
                temp = "긁힘";
              } else if (partsDemandinfo[`${i}`] === "U") {
                temp = "요철";
              } else if (partsDemandinfo[`${i}`] === "C") {
                temp = "부식";
              } else if (partsDemandinfo[`${i}`] === "T") {
                temp = "깨짐";
              }

              if (i === 1) {
                hood = temp;
              }
              if (i === 2 || i === 3) {
                frFend = temp;
              }
              if (i === 4 || i === 5) {
                frDoor = temp;
              }
              if (i === 6 || i === 7) {
                reDoor = temp;
              }
              if (i === 8) {
                trLid = temp;
              }
              if (i === 10 || i === 11 || i === 13 || i === 14) {
                siQuPann = temp;
              }
              if (i === 15 || i === 17 || i === 18) {
                frInPann = temp;
              }
              if (i === 19 || i === 20) {
                rePannTrFlor = temp;
              }
              if (
                i === 12 ||
                i === 21 ||
                i === 22 ||
                i === 23 ||
                i === 24 ||
                i === 29
              ) {
                roPannPaTray = temp;
              }
              if (i === 25 || i === 26 || i === 27 || i === 28) {
                frReWhHous = temp;
              }
              if (
                i === 30 ||
                i === 31 ||
                i === 33 ||
                i === 34 ||
                i === 36 ||
                i === 37
              ) {
                fiDaFlPann = temp;
              }
            }
          }

          for (let i = 1; i <= 37; i++) {
            if (partsRecordinfo[`${i}`] === "X" && exchange === "없음") {
              exchange = "있음";
            }
            if (partsRecordinfo[`${i}`] === "W" && sheetMetal === "없음") {
              sheetMetal = "있음";
            }
            if (partsRecordinfo[`${i}`] === "A" && corrosion === "없음") {
              scratch = "있음";
            }
            if (partsRecordinfo[`${i}`] === "U" && corrosion === "없음") {
              dent = "있음";
            }
            if (partsRecordinfo[`${i}`] === "C" && corrosion === "없음") {
              corrosion = "있음";
            }
            if (partsRecordinfo[`${i}`] === "T" && corrosion === "없음") {
              crack = "있음";
            }

            if (
              !(
                partsRecordinfo[`${i}`] === null ||
                partsRecordinfo[`${i}`] === ""
              )
            ) {
              if (partsRecordinfo[`${i}`] === "X") {
                temp = "교환";
              } else if (partsRecordinfo[`${i}`] === "W") {
                temp = "판금";
              } else if (partsRecordinfo[`${i}`] === "A") {
                temp = "긁힘";
              } else if (partsRecordinfo[`${i}`] === "U") {
                temp = "요철";
              } else if (partsRecordinfo[`${i}`] === "C") {
                temp = "부식";
              } else if (partsRecordinfo[`${i}`] === "T") {
                temp = "깨짐";
              }

              if (i === 1) {
                hood = temp;
              }
              if (i === 2 || i === 3) {
                frFend = temp;
              }
              if (i === 4 || i === 5) {
                frDoor = temp;
              }
              if (i === 6 || i === 7) {
                reDoor = temp;
              }
              if (i === 8) {
                trLid = temp;
              }
              if (i === 10 || i === 11 || i === 13 || i === 14) {
                siQuPann = temp;
              }
              if (i === 15 || i === 17 || i === 18) {
                frInPann = temp;
              }
              if (i === 19 || i === 20) {
                rePannTrFlor = temp;
              }
              if (
                i === 12 ||
                i === 21 ||
                i === 22 ||
                i === 23 ||
                i === 24 ||
                i === 29
              ) {
                roPannPaTray = temp;
              }
              if (i === 25 || i === 26 || i === 27 || i === 28) {
                frReWhHous = temp;
              }
              if (
                i === 30 ||
                i === 31 ||
                i === 33 ||
                i === 34 ||
                i === 36 ||
                i === 37
              ) {
                fiDaFlPann = temp;
              }
            }
          }

          getUcTagobuyCatalogsPfmcSend(
            dataId as string,
            carId,
            carNo,
            checkId,
            exchange,
            sheetMetal,
            corrosion,
            scratch,
            crack,
            dent,
            frInPann,
            frReWhHous,
            fiDaFlPann,
            siQuPann,
            rePannTrFlor,
            roPannPaTray,
            hood,
            frFend,
            frDoor,
            reDoor,
            trLid
          );
          alertSuccess("성능진단 내역을 가져왔습니다.");
        } else {
          alertError(response.msg);
        }
      })
      .catch(() => {
        console.log(
          "No internet connection found. App is running in offline mode."
        );
      });
  }

  /**
   * Render Helpers
   */

  const renderColorChip = (
    colorName: string,
    colorImgUrl: string,
    cossColorNm: string
  ) => {
    return (
      <div>
        <div>
          {cossColorNm && (
            <div
              style={{
                marginBottom: "8px",
              }}
            >
              {cossColorNm}
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {colorImgUrl && (
              <img
                style={{
                  borderRadius: "9px",
                  marginRight: "8px",
                  overflow: "hidden",
                  display: "inline-block",
                }}
                width={18}
                height={18}
                src={colorImgUrl}
                alt=""
              />
            )}

            <span
              style={{
                color: "#626BFF",
              }}
            >
              {colorName}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (catalogDetail) {
      const {
        prodId,
        yearType,
        curTravelDtc,
        modeFuelNm,
        engdisp,
        seaterClsNm,
        grbxNm,
        regDt,
        carId,
        carNo,
        xtnlCarColorNm,
        xtnlColorImgUrl,
        innrCarColorNm,
        innrColorImgUrl,
        optNms = "",
        fixContList,
        carImgList = [],
        totOptAmt,
        prodNm,
        cossXtnlCarColorNm,
        cossInnrCarColorNm,
        ncarClsFlag,
        tagList,
        tkvAmt,
        mainOptionsList,
        accdHistList,
        carCheckList,
        carCheck,
        accdHist,
        blckOdsy,
        qrCode,
        mmRentAmtV,
        avgMinTkvAmt,
        avgMaxTkvAmt,
      } = catalogDetail;

      /**
       * 차량정보
       */
      const carInfoSection = [
        {
          label: "차량이미지",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            if (carImgList && carImgList.length > 0) {
              return carImgList.map(({ fileUrl }) => {
                return (
                  <Image
                    style={{ marginRight: "8px" }}
                    key={fileUrl}
                    src={fileUrl}
                    width={102}
                    height={76}
                  />
                );
              });
            }
            return "-";
          },
        },
      ];

      /**
       * 태그 관리
       */
      const tagInfoSection: SectionFieldInfo[] = [
        {
          label: "",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <div
                  style={{
                    marginTop: "8px",
                    height: "300px",
                  }}
                >
                  <ClientRowTable
                    cols={tagCols}
                    rowData={tagList || []}
                    frameworkComponents={{
                      [PC_TAG_RENDERER]: pcTagCellRenderer,
                      [MOBILE_TAG_RENDERER]: mobileTagRenderer,
                    }}
                  />
                </div>
              </div>
            );
          },
        },
      ];

      /**
       * 정비이력
       */
      const fixInfoSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div
                style={{
                  height: "260px",
                }}
              >
                <ClientRowTable
                  cols={[
                    createTableCol("inDt", "입고일", undefined, {
                      width: CW_MEDIUM,
                      cellRenderer: formatDateString("inDt"),
                    }),
                    createTableCol("dtlNm", "정비명", FilterType.Text, {
                      width: CW_LARGE,
                      flex: 1,
                    }),
                  ]}
                  rowData={fixContList}
                />
              </div>
            );
          },
        },
      ];

      /**
       * 기본정보
       */

      const basicInfoSection = [
        {
          label: "차량명",
          type: FieldType.Text,
          content: prodNm,
        },
        {
          label: "연식",
          type: FieldType.Text,
          content: yearType,
        },
        {
          label: "주행거리",
          type: FieldType.Custom,
          render: () => {
            return `${formatNumber(curTravelDtc)}km`;
          },
        },
        {
          label: "연료",
          type: FieldType.Text,
          content: modeFuelNm,
        },
        {
          label: "배기량",
          type: FieldType.Custom,
          render: () => {
            return `${formatNumber(engdisp)}cc`;
          },
        },
        {
          label: "인승",
          type: FieldType.Text,
          content: seaterClsNm,
        },
        {
          label: "변속기",
          type: FieldType.Text,
          content: grbxNm,
        },
        {
          label: "제조사 신차가격",
          type: FieldType.Custom,
          render: () => {
            return formatCurrency(totOptAmt);
          },
        },
        {
          label: "최초 등록일",
          type: FieldType.Custom,
          render: () => {
            return formatDateString(regDt);
          },
        },
        {
          label: "차량번호",
          type: FieldType.Text,
          content: carNo,
        },
        {
          type: FieldType.Custom,
          render: () => {
            return (
              <FormItem label="상품유형" labelCol={{ span: 24, offset: 0 }}>
                <>
                  <p>{getUcProdType(ncarClsFlag)}</p>
                </>
              </FormItem>
            );
          },
        },
      ];

      const mainOptions = optNms.split("/");
      const mainOptionSectionElements = mainOptions.map((optionName, index) => {
        return {
          label: `옵션-${index + 1}`,
          type: FieldType.Text,
          content: optionName,
        };
      });

      /**
       * 옵션정보
       */
      const optionInfoSection = [
        {
          label: "색상정보",
          type: FieldType.LabelOnly,
          content: "",
        },
        {
          label: "외장색상",
          type: FieldType.Custom,
          render: () => {
            return renderColorChip(
              xtnlCarColorNm,
              xtnlColorImgUrl,
              cossXtnlCarColorNm
            );
          },
        },
        {
          label: "내장색상",
          type: FieldType.Custom,
          render: () => {
            return renderColorChip(
              innrCarColorNm,
              innrColorImgUrl,
              cossInnrCarColorNm
            );
          },
        },
        {
          type: FieldType.Divider,
        },
        {
          label: "주요옵션",
          type: FieldType.LabelOnly,
          content: "",
        },
        ...mainOptionSectionElements,
        {
          type: FieldType.Divider,
        },
      ];

      // 주요옵션
      const mainOptionListSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div
                style={{
                  height: "550px",
                }}
              >
                <ClientRowTable
                  cols={[
                    createTableCol("commCd", "옵션코드", FilterType.Text, {
                      width: CW_SMALL,
                    }),
                    createTableCol("commCdNm", "옵션명", FilterType.Text, {
                      width: CW_2X_LARGE,
                    }),
                    createTableCol("useYn", "유무", FilterType.Text, {
                      width: CW_SMALL,
                      flex: 1,
                    }),
                  ]}
                  rowData={mainOptionsList}
                />
              </div>
            );
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                <>
                  <Button
                    onClick={() => {
                      setMainOptionListModalVisible(true);
                    }}
                  >
                    수정
                  </Button>
                </>
              </FormItem>
            );
          },
        },
      ];

      /**
       * 차량인수가
       */
      const tkvInfoSection = [
        {
          label: "바로인수가",
          type: FieldType.Text,
          content: Number(tkvAmt).toLocaleString(),
        },
        {
          label: "최소인수가",
          type: FieldType.Text,
          content: Number(avgMinTkvAmt).toLocaleString(),
        },
        {
          label: "최대인수가",
          type: FieldType.Text,
          content: Number(avgMaxTkvAmt).toLocaleString(),
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                <>
                  <Button
                    onClick={() => {
                      setCarAmountModifyModalVisible(true);
                    }}
                  >
                    수정
                  </Button>
                </>
              </FormItem>
            );
          },
        },
      ];

      /**
       * 월 렌탈료
       */
       const rentAmtInfoSection = [
        {
          label: "기본렌탈료",
          type: FieldType.LabelOnly,
          content: "",
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                <>
                  <p>{Number(mmRentAmtV).toLocaleString()}</p>                
                  <Button
                    onClick={() => {
                      getUcTagobuyMmRentAmt(prodId);
                    }}
                  >
                    가져오기
                  </Button>
                </>
              </FormItem>
            );
          },
        },
      ];

      const pfmcInfoSection = [
        {
          label: `성능정보 전송여부 : ${carCheck}`,
          type: FieldType.LabelOnly,
        },
        {
          label: "프레임",
          type: FieldType.LabelOnly,
        },
        {
          label: "프론트/인사이드 패널",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].frInPann}`;
            }
            return `-`;
          },
        },
        {
          label: "앞/뒷 휠하우스",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].frReWhHous}`;
            }
            return `-`;
          },
        },
        {
          label: "필러(A,B)/대쉬/플로어 패널",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].fiDaFlPann}`;
            }
            return `-`;
          },
        },
        {
          label: "사이드실/쿼터패널",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].siQuPann}`;
            }
            return `-`;
          },
        },
        {
          label: "리어패널/트렁크 패널",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].rePannTrFlor}`;
            }
            return `-`;
          },
        },
        {
          label: "사이드멤버/루프패널/패키지트레이",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].roPannPaTray}`;
            }
            return `-`;
          },
        },
        {
          label: "외부패널",
          type: FieldType.LabelOnly,
        },
        {
          label: "후드",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].hood}`;
            }
            return `-`;
          },
        },
        {
          label: "프론트 휀더",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].frFend}`;
            }
            return `-`;
          },
        },
        {
          label: "앞문",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].frDoor}`;
            }
            return `-`;
          },
        },
        {
          label: "뒷문",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].reDoor}`;
            }
            return `-`;
          },
        },
        {
          label: "트렁크 리드",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].trLid}`;
            }
            return `-`;
          },
        },
        {
          label: "URL",
          type: FieldType.LabelOnly,
        },
        {
          label: "법적 진단",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].pfmcUrl}`;
            }
            return `-`;
          },
        },
        {
          label: "프리미엄 진단",
          type: FieldType.Custom,
          render: () => {
            if (carCheck === "Y") {
              return `${carCheckList[0].prmmUrl}`;
            }
            return `-`;
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                <>
                  <Button
                    onClick={() => {
                      getUcTagobuyCatalogsPfmc(prodId, carId, carNo);
                    }}
                  >
                    가져오기
                  </Button>
                </>
              </FormItem>
            );
          },
        },
      ];

      const accdInfoSection = [
        {
          label: `사고이력 전송여부 : ${accdHist}`,
          type: FieldType.LabelOnly,
        },
        {
          label: "용도이력",
          type: FieldType.Custom,
          render: () => {
            if (accdHist === "Y") {
              return `${accdHistList[0].carPurposeHist}`;
            }
            return `-`;
          },
        },
        {
          label: "자동차 번호 변경횟수",
          type: FieldType.Text,
          render: () => {
            if (accdHist === "Y") {
              return `${accdHistList[0].carNumberHist}`;
            }
            return `-`;
          },
        },
        {
          label: "소유자 변경횟수",
          type: FieldType.Text,
          render: () => {
            if (accdHist === "Y") {
              return `${accdHistList[0].carOwnerHist}`;
            }
            return `-`;
          },
        },
        {
          label: "전손",
          type: FieldType.Text,
          render: () => {
            if (accdHist === "Y") {
              return `${accdHistList[0].allDamage}`;
            }
            return `-`;
          },
        },
        {
          label: "침수",
          type: FieldType.Text,
          render: () => {
            if (accdHist === "Y") {
              return `${accdHistList[0].flooding}`;
            }
            return `-`;
          },
        },
        {
          label: "도난",
          type: FieldType.Text,
          render: () => {
            if (accdHist === "Y") {
              return `${accdHistList[0].theft}`;
            }
            return `-`;
          },
        },
        {
          label: "내차피해",
          type: FieldType.Text,
          render: () => {
            if (accdHist === "Y") {
              return `${accdHistList[0].myCarDamage}`;
            }
            return `-`;
          },
        },
        {
          label: "타차피해",
          type: FieldType.Text,
          render: () => {
            if (accdHist === "Y") {
              return `${accdHistList[0].otherCarDamage}`;
            }
            return `-`;
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                <>
                  <Button
                    onClick={() => {
                      getUcTagobuyCatalogsAccdHist(prodId, carId, carNo);
                    }}
                  >
                    가져오기
                  </Button>
                </>
              </FormItem>
            );
          },
        },
      ];

      const blckInfoSection = [
        {
          label: `블록 오디세이 : ${blckOdsy}`,
          type: FieldType.LabelOnly,
        },
        {
          label: "블록 오디세이 전송여부",
          type: FieldType.Text,
          content: blckOdsy,
        },
        {
          label: "URL",
          type: FieldType.Custom,
          render: () => {
            if (blckOdsy === "Y") {
              return `${qrCode}`;
            }
            return `-`;
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                <>
                  <Button
                    onClick={() => {
                      getUcTagobuyCatalogsBlck(prodId, carId, carNo);
                    }}
                  >
                    가져오기
                  </Button>
                </>
              </FormItem>
            );
          },
        },
      ];

      return (
        <div>
          <DetailSection label="차량정보" fieldInfos={carInfoSection} />
          <DetailSection label="태그 관리" fieldInfos={tagInfoSection} />
          <DetailSection label="정비이력" fieldInfos={fixInfoSection} />
          <DetailSection label="기본정보" fieldInfos={basicInfoSection} />
          <DetailSection label="옵션정보" fieldInfos={optionInfoSection} />
          <DetailSection label="주요옵션" fieldInfos={mainOptionListSection} />
          <DetailSection label="바로인수가" fieldInfos={tkvInfoSection} />
          <DetailSection label="기본렌탈료" fieldInfos={rentAmtInfoSection} />
          <DetailSection label="성능정보" fieldInfos={pfmcInfoSection} />
          <DetailSection label="사고이력" fieldInfos={accdInfoSection} />
          <DetailSection
            label="블록오디세이"
            fieldInfos={blckInfoSection}
            hideDivider
          />
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        onOpen={onModalOpen}
        visible={visible}
        onCancel={onCancel}
        title="상품 상세 정보"
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      <TagoCarAmountModifyModal
        visible={carAmountModifyModalVisible}
        onCancel={() => {
          setCarAmountModifyModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <TagoCarMainOptionModifyModal
        visible={mainOptionListModalVisible}
        onCancel={() => {
          setMainOptionListModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
    </>
  );
};

TagoCarCatalogModal.defaultProps = {
  onCancel: () => {},
  visible: false,
  dataId: undefined,
};
export default TagoCarCatalogModal;
