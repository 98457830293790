import React, { useState } from "react";
import { GridApi } from "ag-grid-community";
import { ReactSVG } from "react-svg";
import { Pagination } from "antd";
import { FormikProps, FormikValues } from "formik";
import {
  TableLayoutWrapper,
  TableFooter,
  HighLightText,
  TableHeaderWrapper,
  TableHeaderTitleWrapper,
  RightPanelWrapper,
  TableContentWrapper,
  LeftPanelWrapper,
} from "./style";
import TableDataActionButton from "../../composition/TableDataActionButton";
import Button, { ButtonProps } from "../../general/antd/Button";
import excelIcon from "../../../../assets/svgs/excel-btn.svg";
import filterResetIcon from "../../../../assets/svgs/filter-reset.svg";
import { DEFAULT_GRID_LIMIT } from "../../../../constants/values";
import useAuth from "../../../../hooks/useAuth";
import Modal from "../../feedback/antd/Modal";
import FormBuilder from "../../data-entry/FormBuilder";
import { alertError } from "../../../../utils/render-utils";
import { FormElementType, FormInfo } from "../../data-entry/FormBuilder/types";
import { setDownloadLogs } from "../../../../apis/logs";

export interface TableHeaderAction extends ButtonProps {
  title?: string;
  onClick?: () => void;
  disabled?: boolean;
  customView?: boolean;
  render?: any;
}

interface TableLayoutProps {
  children?: React.ReactNode | React.FC;
  onPageSizeChange?: (current: number, pageSize: number) => void;
  totalCount?: number;
  selectionCount?: number;
  hideSelectionCountText?: boolean;
  hideFilterAction?: boolean;
  hideExcelAction?: boolean;
  rightActions?: Array<TableHeaderAction>;
  leftActions?: Array<TableHeaderAction>;
  title?: string;
  gridApi?: GridApi;
  pagination?: boolean;
  page?: number;
  pageSize?: number;
  hideFooter?: boolean;
  headerHorizontalPadding?: number;
  methodName?: string;
  // 로그 정보
  logInfo?: string;
}

const TableLayout: React.FC<TableLayoutProps> = (props: TableLayoutProps) => {
  const {
    children,
    totalCount = 0,
    selectionCount,
    rightActions = [],
    leftActions = [],
    hideSelectionCountText,
    title = "",
    gridApi,
    hideFilterAction,
    hideExcelAction = false,
    pagination = false,
    page = 1,
    pageSize = DEFAULT_GRID_LIMIT,
    onPageSizeChange,
    hideFooter = false,
    headerHorizontalPadding,
    methodName,
    logInfo,
  } = props;

  const [downloadReason, setDownloadReason] = useState("");
  const [isVisbledownloadReason, setIsVisbledownloadReason] = useState(false);

  const { user } = useAuth();

  let formik: FormikProps<FormikValues>;

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  const resetFilter = () => {
    if (gridApi) {
      gridApi.setFilterModel(null);
    }
  };

  // const exportExcel = () => {
  //   if (gridApi) gridApi.exportDataAsExcel();
  // };

  const exportExcel = () => {
    setDownloadReason("");
    setIsVisbledownloadReason(true);
  };

  /**
   * Render Helpers
   */
  const renderHeader = () => {
    return (
      <TableHeaderWrapper headerHorizontalPadding={headerHorizontalPadding}>
        <TableHeaderTitleWrapper>{title}</TableHeaderTitleWrapper>
        <LeftPanelWrapper>
          {leftActions.map(
            ({
              customView = false,
              render,
              title,
              type = "primary",
              ...rest
            }) => {
              if (customView) {
                if (render) return render();
              }
              return (
                <TableDataActionButton
                  type={type}
                  key={title}
                  style={{
                    marginLeft: "8px",
                  }}
                  {...rest}
                >
                  {title}
                </TableDataActionButton>
              );
            }
          )}
        </LeftPanelWrapper>
        <RightPanelWrapper>
          <>
            {!hideExcelAction && (
              <Button
                type="default"
                icon={<ReactSVG src={excelIcon} />}
                onClick={exportExcel}
              />
            )}

            {!hideFilterAction && (
              <Button
                type="default"
                icon={<ReactSVG src={filterResetIcon} />}
                style={{
                  marginLeft: "8px",
                }}
                onClick={resetFilter}
              />
            )}
            {rightActions.map(
              ({
                customView = false,
                render,
                title,
                type = "primary",
                ...rest
              }) => {
                if (customView) {
                  if (render) return render();
                }

                return (
                  <TableDataActionButton
                    type={type}
                    key={title}
                    style={{
                      marginLeft: "8px",
                    }}
                    {...rest}
                  >
                    {title}
                  </TableDataActionButton>
                );
              }
            )}
          </>
        </RightPanelWrapper>
      </TableHeaderWrapper>
    );
  };

  const createFormInfo: () => FormInfo[] = () => [
    {
      key: "reason",
      type: FormElementType.TextArea,
      label: "사유",
      placeholder: "사유를 입력해주세요",
      required: true,
      onChange: (value) => {
        setDownloadReason(value?.trim());
      },
    },
  ];

  const handleSubmit = async (values: FormikValues) => {
    if (!downloadReason) {
      alertError("사유를 입력해주세요.");

      return;
    }

    try {
      await setDownloadLogs({
        userId: user.userId,
        userIp: user.clientIp,
        methodNm: methodName || "",
        menuName: title,
        downRsn: downloadReason,
        reqCntn: logInfo,
      });

      if (gridApi) gridApi.exportDataAsExcel();
    } catch (e) {
      alertError(e);
    }

    setIsVisbledownloadReason(false);
  };

  return (
    <>
      <TableLayoutWrapper>
        {renderHeader()}
        <TableContentWrapper>
          {children}
          {!hideFooter && (
            <TableFooter>
              <div
                style={{
                  marginRight: "16px",
                }}
              >
                총<HighLightText>{totalCount}</HighLightText>건
              </div>
              {!hideSelectionCountText && (
                <div>
                  선택<HighLightText>{selectionCount}</HighLightText>
                </div>
              )}
              {pagination && (
                <div
                  style={{
                    flex: 1,
                    textAlign: "right",
                  }}
                >
                  <Pagination
                    pageSize={pageSize}
                    onShowSizeChange={(current, size) => {
                      if (onPageSizeChange) onPageSizeChange(current, size);
                    }}
                    current={page}
                    total={totalCount}
                    onChange={(page) => {
                      if (gridApi) {
                        gridApi.paginationGoToPage(page - 1);
                      }
                    }}
                    showSizeChanger
                  />
                </div>
              )}
            </TableFooter>
          )}
        </TableContentWrapper>
      </TableLayoutWrapper>
      <Modal
        size="small"
        title="엑셀 다운로드 사유 입력"
        visible={isVisbledownloadReason}
        onCancel={() => {
          setIsVisbledownloadReason(false);
        }}
        onOk={() => {
          formik.handleSubmit();
        }}
        okButtonProps={{ disabled: downloadReason.length < 10 }}
        description="최소 10글자 이상 입력하셔야 합니다."
      >
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={{ reason: downloadReason }}
          forms={createFormInfo()}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

TableLayout.defaultProps = {
  totalCount: 0,
  selectionCount: 0,
  rightActions: [],
  leftActions: [],
  hideSelectionCountText: true,
  pagination: false,
  page: 1,
  headerHorizontalPadding: 24,
};
export default TableLayout;
