import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import { formatDateString } from "../../../../utils/date-utils";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import {
  currencyFormatRenderer,
  dateFormatRenderer,
  USER_DETAIL_ACTION,
} from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../../constants/strings";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";
import {
  BIZ_AUTH_TRANSLATION,
  GENDER,
  CooperType,
} from "../../../../constants/enums";

export const USE_YN_RENDERER = "useYnRenderer";

export const createRentContractCols = (isCS: boolean): Array<TableColumn> => {
  const cols = [
    // 1. 견적일
    createServerTableCol("estDt", "견적일", {
      width: CW_SMALL_M,
      cellRenderer: (data) => {
        const { value } = data;
        if (value) {
          return formatDateString(value);
        }
        return "";
      },
    }),
    // 2. 계약일
    createServerTableCol("cntrDtm", "계약일시", {
      width: CW_LARGE,
      cellRenderer: (data) => {
        const { value } = data;
        if (value) {
          return formatDateString(value, true);
        }
        return "";
      },
    }),
    // 3. 계약취소일
    createServerTableCol("cnclDt", "계약취소일", {
      width: CW_SMALL_M,
      cellRenderer: dateFormatRenderer("cnclDt"),
    }),
    // 4. 계약자명
    createServerTableCol("custNm", "계약자명", {
      width: CW_SMALL,
      cellRenderer: USER_DETAIL_ACTION,
    }),
    // 5. 계약 상담원
    createServerTableCol("cnslUserNm", "계약 상담원", {
      width: CW_MEDIUM,
    }),
    // 6. 담당 카매니저
    createServerTableCol("carmanNm", "담당 카매니저", {
      width: CW_MEDIUM,
    }),
    // 7. 카매니저 배정일
    createServerTableCol("carmanAssignDtm", "카매니저 배정일", {
      width: CW_SMALL_M,
      cellRenderer: (data) => {
        const { value } = data;
        if (value) {
          return formatDateString(value);
        }
        return "-";
      },
    }),
    // 8. 직접인수 선택
    createServerTableCol("dpRequestUser", "직접인수 선택", {
      width: CW_SMALL_M,
      cellRenderer: (data) => {
        const { value } = data;
        if (value) {
          return value;
        }
        return "-";
      },
    }),
    // 9. 계약유형
    createServerTableCol("custClsNm", "계약유형", {
      width: CW_SMALL,
    }),
    // 10. 사업자 인증
    createServerTableCol("addrVrfYn", "사업자 인증상태", {
      width: CW_MEDIUM,
      valueGetter: (params) => {
        const { data } = params;

        if (data.kisAuthYn === "Y") return "인증성공";

        return BIZ_AUTH_TRANSLATION[data.addVrfYn];
      },
    }),
    // 11. 차량구분
    createServerTableCol("srvcNm", "차량구분", {
      width: CW_SMALL,
    }),
    // 12. 인수옵션
    createServerTableCol("tkvRtrnYnCd", "인수옵션", {
      width: CW_SMALL,
      valueGetter: (params) => {
        const { data } = params;
        return data.tkvRtrnYnNm ?? "-";
      },
    }),
    // 13. 판매유형
    createServerTableCol("ncarClsFlagNm", commonStrings.COL_SELLING_TYPE, {
      width: CW_MEDIUM,
    }),
    // 14. 타고바이 여부
    createServerTableCol("tgbyYn", "타고바이 여부", {
      width: CW_MEDIUM,
    }),
    // ! (삭제) 타고바이 상품유형 by [13922]
    /*createServerTableCol("tbProductGbn", "타고바이 상품유형", {
      width: CW_LARGE,
      valueGetter: (params) => {
        const { data } = params;
        const { tbProductGbn } = data;
        if (tbProductGbn === "A65001") return "바로 인수";
        if (tbProductGbn === "A65002") return "타보고 인수";
        return "";
      },
    }),*/
    // 15. 추천 사번
    createServerTableCol("rcmEmpi", "추천 사번", {
      width: CW_MEDIUM,
    }),
    // 16. 모델
    createServerTableCol("repCartypeNm", "모델", {
      width: CW_MEDIUM,
    }),
    // 17. 세부모델
    createServerTableCol("carClassNm", "세부모델", {
      width: CW_MEDIUM,
    }),
    // 18. 트림
    createServerTableCol("carGradeNm", "트림", {
      flex: 1,
      minWidth: CW_LARGE,
    }),
    // 19. 담보조건
    createServerTableCol("pldgCondNm", "담보조건", {
      width: CW_SMALL,
    }),
    // 20. 담보율
    createServerTableCol("pldgRtNm", "담보율", {
      width: CW_SMALL,
    }),
    // 21. 담보납부여부
    createServerTableCol("pldgAmtPaymentYn", "담보금액 납부여부", {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "납부완료" : "미납부",
          },
        };
      },
    }),
    // 22. 출고여부
    createServerTableCol("consiStsNm", "출고여부", {
      width: CW_SMALL_M,
    }),
    // 23. 연료타입
    createServerTableCol("fuelNm", "연료타입", {
      width: CW_MEDIUM,
    }),
    // 24. 차량번호
    createServerTableCol("carNo", "차량번호", {
      width: CW_MEDIUM,
    }),
    // 25. 차형
    createServerTableCol("extShape", "차형", {
      width: CW_MEDIUM,
    }),
    // 26. 차량가
    createServerTableCol("totCarAmt", "차량가격", {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: currencyFormatRenderer("totCarAmt"),
    }),
    // 27. 월 렌탈료(부가세포함)
    createServerTableCol("mmRentAmtV", "월 렌탈료(부가세포함)", {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: currencyFormatRenderer("mmRentAmtV"),
    }),
    // 28. 계약시점 차령
    createServerTableCol("carAge", "계약시점 차령", {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 29. 계약시점 주행거리
    createServerTableCol("cntrStTravelDtc", "계약시점 주행거리", {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 30. 계약종료일
    createServerTableCol("cntrEndDt", "계약종료일", {
      width: CW_SMALL,
      cellRenderer: dateFormatRenderer("cntrEndDt"),
    }),
    // 31. 계약기간
    createServerTableCol("cntrTermMm", "계약기간", {
      width: CW_SMALL,
    }),
    // 32. 약정주행거리
    createServerTableCol("prmsDtcClsNm", "약정주행거리", {
      width: CW_MEDIUM,
    }),
    // 33. 바로인수가
    createServerTableCol("tkvAmt", "바로인수가", {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: currencyFormatRenderer("tkvAmt"),
    }),
    // 34. 타고바이 인수요청
    createServerTableCol("tkvReqYn", "인수요청", {
      width: CW_SMALL,
    }),
    // 35. 타고바이 인수요청일자
    createServerTableCol("tkvReqDtm", "인수요청일자", {
      width: CW_MEDIUM,
    }),
    // 36. 성별
    createServerTableCol("gndr", "성별", {
      width: CW_SMALL,
      valueGetter: ({ data }) => {
        switch (data.gndr) {
          case GENDER.MALE:
            return "남";
          case GENDER.FEMALE:
            return "여";
          default:
            return null;
        }
      },
    }),
    // 37. 생년월일
    createServerTableCol("brthdt", "생년월일", {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("brthdt"),
    }),
    // 38. 지역
    createServerTableCol("poaSiteNm", "지역", {
      width: CW_SMALL,
    }),
    // 39. 결제수단명
    createServerTableCol("stmtMeansNm", "결제수단", {
      width: CW_MEDIUM,
    }),
    // 40. 결제수단상세
    createServerTableCol("stmtMeansNmDetail", "결제계좌(카드)", {
      width: CW_MEDIUM,
      valueGetter: ({ data }) => {
        const { bankNm, cardCoNm } = data;
        return bankNm || cardCoNm || "";
      },
    }),
    // 41. 보증금 카드결제정보
    createServerTableCol("grtCardInfo", "보증금 카드결제정보", {
      width: CW_MEDIUM,
      valueGetter: ({ data }) => {
        const { grtCardStmtYn, grtCardStmtCo } = data;
        if (grtCardStmtYn === "Y") {
          return `카드결제${grtCardStmtCo && ` - ${grtCardStmtCo}`}`;
        }
        return "";
      },
    }),
    // 42. 회원구분
    createServerTableCol("modeCustClsNm", "회원구분", {
      width: CW_SMALL,
    }),
    // 43. 상품유형
    createServerTableCol("prodPatternNm", "상품유형", {
      width: CW_MEDIUM,
    }),
    // 44. 계약진행상태
    createServerTableCol("cntrStsNm", "계약진행상태", {
      width: CW_SMALL_M,
    }),
    // 45. 그룹사 할인 여부
    createServerTableCol("skGrpYn", "외부채널", {
      width: CW_SMALL,
      valueGetter: (params) => {
        const { data } = params;
        const { skGrpYn } = data;
        if (skGrpYn === "Y") return "그룹사 임직원";
        if (skGrpYn === "R") return "SKR 임직원";
        if (skGrpYn === "SKT") return "SKT 임직원";
        if (skGrpYn === "LG") return "My LG ID";
        if (skGrpYn === "TOSS1") return "TOSS1";
        if (skGrpYn === "TOSS2") return "TOSS2";
        if (skGrpYn === "TOSS3") return "TOSS3";
        if (skGrpYn === "TOSS4") return "TOSS4";
        if (skGrpYn === "TOSS5") return "TOSS5";
        if (skGrpYn === "TOSS6") return "TOSS6";
        if (skGrpYn === "TOSS7") return "TOSS7";
        if (skGrpYn === "TOSS8") return "TOSS8";
        if (skGrpYn === "JEJUSQ") return "제주단기";
        if (skGrpYn === "ASIANA") return "아시아나";
        if (skGrpYn === "RS2023") return "2023-resale";
        if (skGrpYn === "EVPRMT") return "EV sale";
        return "미적용";
      },
    }),
    // 46. 제휴채널
    createServerTableCol("cooperCd", "제휴채널", {
      width: CW_SMALL,
      valueGetter: (params) => {
        const { data } = params;
        const { cooperCd } = data;
        if (cooperCd === CooperType.ELEVEN) return "11번가";
        if (cooperCd === CooperType.GETCHA) return "겟차";
        if (cooperCd === CooperType.GALAXY) return "SKT T우주";
        if (cooperCd === CooperType.OK_CASHBAG) return "OK캐쉬백";
        if (cooperCd === CooperType.RIDE_NOW) return "라이드나우";
        return "";
      },
    }),
    // 47. 견적채널
    createServerTableCol("estCooperCdNm", "견적채널", {
      width: CW_SMALL,
    }),
    // 48. 추천차량 견적
    createServerTableCol("recClsDeg", "추천차량", {
      width: CW_MEDIUM,
      cellRenderer: "recCarDetail",
    }),
    // 49. 차량등록지
    createServerTableCol("useAreaNm", "차량등록지", {
      width: CW_MEDIUM,
    }),
    // 50. 정비등급
    createServerTableCol("fixGrdIdNm", "정비등급", {
      width: CW_SMALL,
    }),
    // 51. 거래처코드
    createServerTableCol("erpCustCd", "거래처코드", {
      width: CW_MEDIUM,
    }),
    {
      ...createActionCol(80),
    },
  ];
  if (isCS) {
    cols.splice(5, 0, 
      // 5~6. 계약상담
      createServerTableCol("cnslEndYn", "계약상담", {
        width: CW_SMALL,
        cellRendererSelector: (data) => {
          const { value } = data;
          return {
            component: CR_STATUS_TEXT,
            params: {
              active: value === "Y",
              text: value === "Y" ? "완료" : "미완료",
            },
          };
        },
      }));
  }
  return cols;
};
