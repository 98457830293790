/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import Button from "../../../../../components/shared/general/antd/Button";
import {
  formatCurrency,
  formatNumber,
  getErrorMessage,
} from "../../../../../utils/common-utils";
import { fetchEstimationDetail } from "../../../../../apis/estimations";
import EstimationDetail from "../../../../../models/EstimationDetail";
import CustomerModal from "../../../../customer/CustomerManager/pages/CustomerModal";
import { formatDateString } from "../../../../../utils/date-utils";

interface Props {
  visible: boolean;
  onCancel?: () => void;
  id?: string;
  disableCustomerSection?: boolean;
}

const EstimationDetailModal: React.FC<Props> = (props: Props) => {
  const [custId, setCustId] = useState<string>();
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const { visible, onCancel, id, disableCustomerSection = false } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [estimation, setEstimation] = useState<EstimationDetail>();

  const onModalOpen = async () => {
    if (id) {
      const estimationRes = await requestFetchEstimationDetail(id);
      setEstimation(estimationRes);
    } else {
      setEstimation(undefined);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchEstimationDetail = async (estReqNo: string) => {
    setDataFetching(true);
    try {
      return await fetchEstimationDetail(estReqNo);
    } catch (e) {
      alertError(getErrorMessage(e));
      return undefined;
    } finally {
      setDataFetching(false);
    }
  };

  /**
   * Event Actions
   */

  const handleUserDetailClick = (custId: string) => {
    setCustId(custId);
    setCustomerModalVisible(true);
  };

  /**
   * Render Helpers
   */

  const renderDetail = () => {
    if (estimation) {
      // 회원정보
      const customerInfoSection: Array<SectionFieldInfo> = [
        {
          label: "고객명",
          type: FieldType.Custom,
          render: () => {
            if (disableCustomerSection) {
              return estimation.custNm;
            }
            return (
              <Button
                type="default"
                onClick={() => {
                  handleUserDetailClick(estimation?.custId);
                }}
              >
                {estimation.custNm}
              </Button>
            );
          },
        },
      ];

      // 할인정보
      const dcList: SectionFieldInfo[] = estimation.odsDcList?.map(
        ({ odsDcAmt, odsDcNm }) => {
          return {
            label: odsDcNm,
            type: FieldType.Text,
            content: formatCurrency(odsDcAmt),
            visible: !isTagoPay,
          };
        }
      );

      const isTagoPay = !!estimation.amtCondCd;
      const isUC = !!estimation.tkvAmtRtFlag;

      const tkvRtrnYnNmVisible =
        !isTagoPay && !isUC && !!estimation.tkvRtrnYnNm;

      // 견적정보
      const estimationInfoSection: SectionFieldInfo[] = [
        {
          label: "차량정보",
          type: FieldType.LabelOnly,
          content: "",
        },
        {
          label: "모델",
          type: FieldType.Text,
          content: estimation.repCartypeNm,
        },
        {
          label: "세부모델",
          type: FieldType.Text,
          content: estimation.carClassNm,
        },
        {
          label: "트림",
          type: FieldType.Text,
          content: estimation.carGradeNm,
        },
        {
          label: "내장색상",
          type: FieldType.Text,
          content: estimation.innrCarColorNm,
        },
        {
          label: "외장색상",
          type: FieldType.Text,
          content: estimation.xtnlCarColorNm,
        },
        {
          label: "추가옵션",
          type: FieldType.Text,
          content: estimation.carGoodsNm1,
        },
        {
          label: "연식",
          type: FieldType.Text,
          visible: isUC,
          content: estimation.yearType,
        },
        {
          label: "주행거리",
          type: FieldType.Custom,
          visible: isUC,
          render: () => {
            return `${formatNumber(estimation.curTravelDtc)}km`;
          },
        },
        {
          label: "연료",
          type: FieldType.Text,
          content: estimation.fuelNm,
        },
        {
          label: "배기량",
          type: FieldType.Custom,
          visible: isUC,
          render: () => {
            return `${formatNumber(estimation.engdisp)}cc`;
          },
        },
        {
          label: "인승",
          type: FieldType.Text,
          visible: isUC,
          content: estimation.seaterClsNm,
        },
        {
          label: "최초 등록일",
          type: FieldType.Custom,
          visible: isUC,
          render: () => {
            return formatDateString(estimation.regDt);
          },
        },
        {
          label: "차량번호",
          type: FieldType.Text,
          visible: isUC,
          content: estimation.carNo,
        },
        {
          label: "썬팅(전면)",
          type: FieldType.Text,
          content: `${estimation.suntMakerNm2}(${estimation.suntConc2}%)`,
        },
        {
          label: "썬팅(측,후면)",
          type: FieldType.Text,
          content: `${estimation.suntMakerNm1}(${estimation.suntConc1}%)`,
        },
        {
          label: "렌트조건",
          type: FieldType.LabelOnly,
        },
        {
          label: "계약기간",
          type: FieldType.Text,
          content: `${estimation.cntrTermMm}개월`,
        },
        {
          label: "약정주행거리",
          type: FieldType.Text,
          content: estimation.prmsDtcClsNm,
        },
        {
          label: "운전자범위",
          type: FieldType.Text,
          content: estimation.drvSoeNm,
        },
        {
          label: "담보조건",
          type: FieldType.Text,
          content: estimation.rentInsuranceInfoText,
        },
        ...dcList,
        {
          label: "월 렌탈료",
          type: FieldType.Text,
          content: `${formatCurrency(estimation.mmRentAmtV)}원`,
          visible: !isTagoPay,
        },
        {
          label: "선수금 차감",
          type: FieldType.Text,
          content: `${formatCurrency(estimation.mmInitamt)}원`,
          visible: !isTagoPay,
        },
        {
          label: "실제 월 납입금",
          type: FieldType.Text,
          content: `${formatCurrency(estimation.realPaymentAmt)}원`,
          visible: !isTagoPay,
        },
        {
          label: "요금제",
          type: FieldType.Text,
          content: estimation.amtCondNm,
          visible: isTagoPay,
        },
        {
          label: "월 기본료",
          type: FieldType.Text,
          content: formatCurrency(estimation.stdRentAmt),
          visible: isTagoPay,
        },
        {
          label: "주행거리 요금",
          type: FieldType.Text,
          content: `${formatCurrency(estimation.amtPerMile)}/km`,
          visible: isTagoPay,
        },
        {
          label: "차량인수가",
          type: FieldType.Text,
          content: !Number.isNaN(Number(estimation.tkvAmt))
            ? `${formatCurrency(estimation.tkvAmt)}원`
            : estimation.tkvAmt,
        },
        {
          label: "보험대물조건",
          type: FieldType.Text,
          content: estimation.impsnlNm,
        },
        {
          label: "인수옵션",
          type: FieldType.Text,
          content: estimation.tkvRtrnYnNm,
          visible: tkvRtrnYnNmVisible,
        },
        {
          label: "계약진행상태",
          type: FieldType.LabelOnly,
        },
        {
          label: "계약상태",
          type: FieldType.Text,
          content: estimation.cntrStsNm,
        },
      ];

      return (
        <div>
          <DetailSection label="회원 정보" fieldInfos={customerInfoSection} />
          <DetailSection
            label="견적 정보"
            fieldInfos={estimationInfoSection}
            hideDivider
          />
        </div>
      );
    }

    return "";
  };

  return (
    <>
      <Modal
        size="large"
        title="견적정보"
        visible={visible}
        onCancel={onCancel}
        onOpen={onModalOpen}
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      {!disableCustomerSection && (
        <CustomerModal
          id={custId}
          onCancel={() => {
            setCustomerModalVisible(false);
          }}
          visible={customerModalVisible}
        />
      )}
    </>
  );
};

EstimationDetailModal.defaultProps = {
  onCancel: () => {},
  visible: false,
  id: undefined,
};

export default EstimationDetailModal;
