import * as Parallel from "async-parallel";
import { HttpMethod, request } from "../../network/request";
import {
  ExhibitionCatalog,
  ExhibitionMaster,
  ExhibitionResponse,
  ExhibitionEditParams,
  AddExhibitionParams,
  RequestUpdateExCatalogSeqParams,
  LinkExhibitionCatalogParams,
  ExhibitionEditUseYnParams,
} from "./types";
import { ROOT_URL } from "../../network/api-urls";

/**
 * 기획전 등록
 * @param params
 */
export const addExhibition = async (params: AddExhibitionParams) => {
  await request(HttpMethod.Post, `${ROOT_URL}/admin/tb-ex`, undefined, params);
};

/**
 * 기획전 리스트 조회
 */
export const fetchExhibitions = async (): Promise<
  ExhibitionResponse<ExhibitionMaster>
> => {
  const res = await request(HttpMethod.Get, `${ROOT_URL}/admin/tb-ex`);
  return res.data.response;
};

/**
 * 기획전 상세 조회 (실제 API는 없지만 데이터 갱신용으로 정의)
 */
export const fetchExhibitionDetail = async (
  mstSeq: string
): Promise<ExhibitionMaster> => {
  const res = await fetchExhibitions();
  const { list } = res;
  const filtered = list.filter(({ exhMstSeq }) => {
    return exhMstSeq === mstSeq;
  });
  return filtered[0];
};

/**
 * 기획전 마스터 수정
 * @param params
 */
export const editExhibition = async (params: ExhibitionEditParams) => {
  await request(HttpMethod.Post, `${ROOT_URL}/admin/tb-ex/${params.exhMstSeq}`, undefined, params);
};

/**
 * 기획전 마스터 노출 여부 수정
 * @param params
 */
 export const exhibitionEditUseYn = async (params: ExhibitionEditUseYnParams) => {
  await request(HttpMethod.Post, `${ROOT_URL}/admin/tb-ex-viewYn/${params.exhMstSeq}`, undefined, params);
};

/**
 * 기획전 상세 연결된 차량 조회
 */
export const fetchExhibitionCatalogs = async (
  exhMstSeq: string
): Promise<ExhibitionResponse<ExhibitionCatalog>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/tb-ex/catalogs-seq/${exhMstSeq}`
  );
  return res.data.response;
};

/**
 * 기획전 상세 선택가능 차량 조회
 */
 export const fetchExhibitionCatalogsAll = async (
  exhMstSeq: string
): Promise<ExhibitionResponse<ExhibitionCatalog>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/tb-ex/catalogs/${exhMstSeq}`
  );
  return res.data.response;
};

/**
 * 기획전 차량 벌크 연결
 */
export const linkExhibitionCatalogs = async (
  exhMstSeq: String,
  linkInfos: LinkExhibitionCatalogParams[]
) => {
  await request(HttpMethod.Post, `${ROOT_URL}/admin/tb-ex/catalogs/${exhMstSeq}`, undefined, linkInfos);
};

/**
 * 기획전 상품 게시순번 저장
 * @param list
 */
export const editExhibitionCatalogSeq = async (
  list: Array<RequestUpdateExCatalogSeqParams>,
  exhMstSeq: String
) => {
  await request(
    HttpMethod.Post,
    `${ROOT_URL}/admin/tb-ex/catalogs-seq/${exhMstSeq}`,
    undefined,
    list
  );
};
