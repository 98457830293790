import React, { ReactNode, useCallback, useEffect } from "react";
import { Modal as AntModal, ModalProps as AntModalProps } from "antd";
import { HeaderWrapper, HeaderTitle } from "./style";
import Description from "../../../typo/Description";

interface ModalProps extends AntModalProps {
  size?: "small" | "default" | "large";
  children?: ReactNode | React.FC;
  onOpen?: () => void;
  description?: string;
}

const MODAL_DEFAULT_WIDTH = 940;
const MODAL_SMALL_WIDTH = 528;
const MODAL_LARGE_WIDTH = 1240;

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    style,
    visible,
    children,
    size = "default",
    bodyStyle = {},
    onOpen,
    title,
    description,
    ...rest
  } = props;

  useEffect(() => {
    if (visible && onOpen) onOpen();
  }, [visible]);

  /**
   * Private Functions
   */

  const getModalWidth = useCallback(() => {
    let width: number | string = MODAL_DEFAULT_WIDTH;

    if (size === "small") width = MODAL_SMALL_WIDTH;
    else if (size === "large") width = MODAL_LARGE_WIDTH;

    if (style?.width) width = style?.width;

    return width;
  }, [bodyStyle, size]);

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */
  const renderHeaderTitle = () => {
    return (
      <HeaderWrapper size={size}>
        <HeaderTitle>{title}</HeaderTitle>
        {description && (
          <Description
            style={{
              marginTop: "12px",
            }}
          >
            {description}
          </Description>
        )}
      </HeaderWrapper>
    );
  };

  return (
    <AntModal
      title={renderHeaderTitle()}
      keyboard={false}
      destroyOnClose
      width={getModalWidth()}
      visible={visible}
      centered
      bodyStyle={{
        padding: `24px ${size === "large" ? "48px" : "24px"}`,
        maxHeight: "70vh",
        overflowY: "auto",
        overflowX: "hidden",
        ...bodyStyle,
      }}
      {...rest}
    >
      {children}
    </AntModal>
  );
};

Modal.defaultProps = {
  okText: "확인",
  cancelText: "취소",
  size: "default",
};
export default Modal;
