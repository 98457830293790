import React from "react";
import { Input as AntInput, InputProps } from "antd";

interface Props extends InputProps {}

const PasswordInput: React.FC<Props> = (props: Props) => {
  const { children, style, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <AntInput.Password
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

PasswordInput.defaultProps = {};
export default PasswordInput;
