import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_X_LARGE,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
  CW_2X_LARGE,
} from "../../../../utils/table-utils";
import Switch from "../../../../components/shared/data-entry/antd/Switch";
import commonStrings from "../../../../constants/strings";
import { CooperType } from "../../../../constants/enums";
import { numberFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

export const AR_DISPLAY_STATE_SWITCH = "displayStateSwitchRenderer";

const renderSwitch = (
  onChange: (
    checked: boolean,
    prodId: string,
    cooperCd: string,
    cooperProdNo: string
  ) => void,
  key = "useYn"
) => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    const { prodId, cooperCd, cooperProdNo } = data;
    return (
      <Switch
        checked={data[key] === "Y"}
        onClick={(checked) => {
          onChange(checked, prodId, cooperCd, cooperProdNo);
        }}
      />
    );
  };
};

export const createSwitchRenderer = (
  onChange: (
    checked: boolean,
    prodId: string,
    cooperCd: string,
    cooperProdNo: string
  ) => void,
  key = "useYn"
) => ({
  [AR_DISPLAY_STATE_SWITCH]: renderSwitch(onChange, key),
});

const AllCooperCarCatalogCols = [
  // 제휴사
  createTableCol("cooperCdNm", "제휴사", FilterType.Text, {
    width: CW_SMALL_M,
  }),
  // 상품 ID
  createTableCol("prodId", "상품 ID", FilterType.Text, {
    width: CW_MEDIUM,
  }),
  // 판매유형
  createTableCol(
    "ncarClsFlagNm",
    commonStrings.COL_SELLING_TYPE,
    FilterType.Text,
    {
      width: CW_SMALL_M,
    }
  ),
  // 제조사
  createTableCol("brandNm", "제조사", FilterType.Text, {
    width: CW_SMALL_M,
  }),
  // 모델
  createTableCol("prodNm", "모델", FilterType.Text, {
    width: CW_MEDIUM,
  }),
  // 세부 모델
  createTableCol("carClassNm", "세부모델", FilterType.Text, {
    minWidth: CW_LARGE,
  }),
  // 연료
  createTableCol("repFuelNm", "연료", FilterType.Text, {
    width: CW_SMALL,
  }),
  // 최소 차량 가격
  createTableCol("carAmt", "최소 차량가격", FilterType.Text, {
    width: CW_MEDIUM,
    cellRenderer: numberFormatRenderer("carAmt"),
  }),
  // 최소 렌탈료
  createTableCol("limitRentAmtV", "최소 렌탈료", FilterType.Text, {
    width: CW_MEDIUM,
    cellRenderer: numberFormatRenderer("limitRentAmtV"),
  }),
  // 재고수
  createTableCol("stockCnt", "재고 수", FilterType.Text, {
    width: CW_SMALL,
  }),
  // 차량 선택 url :: 11번가일때만 노출
  createTableCol("cooperProdUrl", "차량선택 URL", FilterType.Text, {
    width: CW_2X_LARGE,
  }),
  // 노출상태
  createTableCol("useYn", "노출상태", FilterType.Text, {
    cellRenderer: AR_DISPLAY_STATE_SWITCH,
    width: CW_SMALL,
  }),
  // 11번가 상품코드 :: 11번가일때만 노출
  createTableCol("cooperProdNo", "11번가 상품코드", FilterType.Text, {
    width: CW_LARGE,
    valueGetter: ({ data }) => {
      const { useYn, cooperProdNo } = data;
      if (useYn === "N") return "-";
      if (!cooperProdNo) return "상품코드 등록시 노출";
      return cooperProdNo;
    },
  }),

  // 설정 버튼 :: 11번가일때만 노출
  {
    ...createActionCol(80),
  },
];

export const createCooperCarCatalogCols = (
  partnerCode: CooperType
): Array<TableColumn> => {
  if (partnerCode === CooperType.ELEVEN) return AllCooperCarCatalogCols;

  return AllCooperCarCatalogCols.filter((col) => {
    if (col.field === "cooperProdNo") return false;
    if (col.field === "cooperProdUrl") return false;
    if (col.headerName === "관리") return false;
    return true;
  });
};
