// 개인 사업자
import {
  ENUM_PROD_TYPE_FAST_DELIVERY,
  ENUM_PROD_TYPE_ORDER_PLACE,
  ENUM_PROD_TYPE_TAGO_PAY,
  ENUM_PROD_TYPE_TODAY_DELIVERY,
} from "../../../../constants/enums";

const ENUM_PRIVATE_BUSINESS = "105004";
// 법인
const ENUM_CORP = "105002";
// 개인
const ENUM_NORMAL = "105001";

const ENUM_DELIVERY_STATE_REQ = "239001";
const ENUM_DELIVERY_STATE_SET = "239002";
const ENUM_DELIVERY_STATE_START = "239003";
const ENUM_DELIVERY_STATE_ARRIVAL = "239004";
const ENUM_DELIVERY_STATE_COMPLETE = "239005";
const ENUM_DELIVERY_STATE_CANCEL = "239006";
const ENUM_DELIVERY_STATE_CUST_CONFIRM = "239007";
const ENUM_DELIVERY_STATE_REJECT = "239008";
const ENUM_DELIVERY_STATE_TEMP = "239009";
const ENUM_DELIVERY_STATE_PENDING = "239010";
const ENUM_DELIVERY_STATE_WAIT_APPR = "239011";

// const ENUM_CONTRACT_STATE_PROGRESS = "417001";
export const ENUM_CONTRACT_STATE_DELETED = "417003";
export const ENUM_CONTRACT_STATE_APPROVAL = "417004";
export const ENUM_CONTRACT_STATE_EXPIRED = "463007";
// const ENUM_CONTRACT_STATE_REJECTED = "417006";
// const ENUM_CONTRACT_STATE_CANCEL = "417007";
// const ENUM_CONTRACT_STATE_HOLD_CAR_RELEASE = "417008";

const ENUM_EST_STATE_SAVE = "415002";
const ENUM_EST_STATE_DELETE = "415004";
const ENUM_EST_STATE_COMPLETE = "415007";

export const EST_STATE_FILTER_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "견적저장",
    value: ENUM_EST_STATE_SAVE,
  },
  {
    label: "견적삭제",
    value: ENUM_EST_STATE_DELETE,
  },
  {
    label: "견적완료",
    value: ENUM_EST_STATE_COMPLETE,
  },
];

export const CONTRACT_KIND_FILTER_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "개인",
    value: ENUM_NORMAL,
  },
  {
    label: "개인사업자",
    value: ENUM_PRIVATE_BUSINESS,
  },
  {
    label: "법인",
    value: ENUM_CORP,
  },
];

export const PLDG_YN_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "납부완료",
    value: "Y",
  },
  {
    label: "미납부",
    value: "N",
  },
];

export const CARMANAGER_ASSIGN_YN_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "배정",
    value: "Y",
  },
  {
    label: "미배정",
    value: "N",
  },
];

export const CONTRACT_STATE_FILTER_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  // {
  //   label: "계약진행중",
  //   value: ENUM_CONTRACT_STATE_PROGRESS,
  // },
  {
    label: "계약취소",
    value: ENUM_CONTRACT_STATE_DELETED,
  },
  // {
  //   label: "차량출고보류",
  //   value: ENUM_CONTRACT_STATE_HOLD_CAR_RELEASE,
  // },
  // {
  //   label: "계약반려",
  //   value: ENUM_CONTRACT_STATE_REJECTED,
  // },
  // {
  //   label: "구매취소",
  //   value: ENUM_CONTRACT_STATE_CANCEL,
  // },
  {
    label: "계약완료",
    value: ENUM_CONTRACT_STATE_APPROVAL,
  },
  {
    label: "계약종료",
    value: ENUM_CONTRACT_STATE_EXPIRED,
  },
];

export const DELIVERY_STATE_FILTER_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "탁송요청",
    value: ENUM_DELIVERY_STATE_REQ,
  },
  {
    label: "탁송배정",
    value: ENUM_DELIVERY_STATE_SET,
  },
  {
    label: "탁송출발",
    value: ENUM_DELIVERY_STATE_START,
  },
  {
    label: "탁송도착",
    value: ENUM_DELIVERY_STATE_ARRIVAL,
  },
  {
    label: "탁송완료",
    value: ENUM_DELIVERY_STATE_COMPLETE,
  },
  {
    label: "탁송취소",
    value: ENUM_DELIVERY_STATE_CANCEL,
  },
  {
    label: "고객확인",
    value: ENUM_DELIVERY_STATE_CUST_CONFIRM,
  },
  {
    label: "탁송반려",
    value: ENUM_DELIVERY_STATE_REJECT,
  },
  {
    label: "임시저장",
    value: ENUM_DELIVERY_STATE_TEMP,
  },
  {
    label: "기안대기",
    value: ENUM_DELIVERY_STATE_PENDING,
  },
  {
    label: "품의진행중",
    value: ENUM_DELIVERY_STATE_WAIT_APPR,
  },
];
