/* eslint-disable no-console */
import { plainToClass } from "class-transformer";
import { ListItemResponse, ListRequestParams } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { CooperType } from "../../constants/enums";
import { CooperCarCatalog } from "../../models/CooperCarCatalog";
import { ElevenCarCatalogDetail } from "../../models/ElevenCarCatalogDetail";

const apiRootUrl = `${ROOT_URL}/admin/cooper/nc-catalogs`;

export interface GetCooperCarCatalogRequestParams extends ListRequestParams {
  // 제휴사
  cooperCd?: CooperType;
  // 모델
  prodNm?: string;
}

/**
 * 제휴차량 상품 리스트 조회
 */
export const fetchCooperCarCatalogs = async (
  params?: GetCooperCarCatalogRequestParams
): Promise<ListItemResponse<CooperCarCatalog>> => {
  const res = await request(
    HttpMethod.Get,
    apiRootUrl,
    params as Record<string, any>
  );

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(CooperCarCatalog, list as Array<JSON>),
    count: listCnt,
  };
};

/**
 * 제휴상품 노출상태 변경
 */
export const turnOnCooperCarDisplayState = async (
  prodId: string,
  cooperCd: string,
  cooperProdNo = ""
) => {
  const url = `${ROOT_URL}/admin/cooper/nc-catalogs/${prodId}`;
  await request(HttpMethod.Post, url, undefined, {
    cooperCd,
    cooperProdNo,
  });
};
export const turnOffCooperCarDisplayState = async (
  prodId: string,
  cooperCd: string,
  cooperProdNo = ""
) => {
  const url = `${ROOT_URL}/admin/cooper/nc-catalogs/${prodId}`;
  await request(HttpMethod.Delete, url, undefined, {
    cooperCd,
    cooperProdNo,
  });
};

/**
 * 11번가 상품 상세 조회
 */
export const fetchElevenCatalogDetail = async (
  prodId: string
): Promise<ElevenCarCatalogDetail> => {
  const url = `${apiRootUrl}/${prodId}`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  return plainToClass(ElevenCarCatalogDetail, response);
};

/**
 * 11번가 상품 수정
 */
export const editElevenCatalogDetail = async (
  prodId: string,
  cooperCd: string,
  cooperProdNo: string
): Promise<any> => {
  const url = `${apiRootUrl}/${prodId}`;
  const res = await request(HttpMethod.Put, url, undefined, {
    cooperCd,
    cooperProdNo,
  });

  const { data } = res;
  const { response } = data;
  return response;
};
