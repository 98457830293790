import React, { useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";
import { 
  fetchExhibitions, 
  exhibitionEditUseYn 
} from "../../../apis/tb-ex";
import {
  ExhibitionEditUseYnParams,
  ExhibitionMaster,
  ExhibitionResponse,
} from "../../../apis/tb-ex/types";
import TableLayout from "../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { createExhibitionMasterCols } from "./utils/table-utils";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import commonStrings from "../../../constants/strings";
import { USE_YN_RENDERER } from "../BannerManager/pages/BannerDetailModal/utils/table-utils";
import { renderSwitchCol } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import AddExhibitionModal from "./pages/AddExhibitionModal";
import ExhibitionDetailModal from "./pages/ExhibitionDetailModal";

const TagoExhibitionManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();

  // 기획전 리스트 관련 State
  const [exhibitions, setExhibitions] = useState<
    ExhibitionResponse<ExhibitionMaster>
  >();
  const [exFetchProgress, setExFetchProgress] = useState<boolean>(false);

  // 기획전 추가 모달 관련 State
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  // 기획전 상세 관련 State
  const [selectedExhibition, setSelectedExhibition] = useState<
    ExhibitionMaster
  >();

  const [detailModalVisible, setDetailModalVisible] = useState(false);

  useEffect(() => {
    (async function fetchData() {
      await requestFetchExhibitionMasters();
    })();
  }, []);

  const requestFetchExhibitionMasters = async () => {
    setExFetchProgress(true);
    try {
      const { list, listCnt } = await fetchExhibitions();
      setExhibitions({
        listCnt,
        list,
      });
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setExFetchProgress(false);
    }
  };

  const requestEditExhibitionUseYn = async (params: ExhibitionEditUseYnParams) => {
    try {
      await exhibitionEditUseYn(params); 
      alertSuccess("기획전 사용상태가 변경되었습니다.");
      await requestFetchExhibitionMasters();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const handleDetail = ({ data }: { data: ExhibitionMaster }) => {
    setSelectedExhibition(data);
    setDetailModalVisible(true);
  };

  const handleUseYnChange = (checked: boolean, data: ExhibitionMaster) => {
    showConfirm(
      checked ? commonStrings.CONFIRM_USE_ON : commonStrings.CONFIRM_USE_OFF,
      checked ? commonStrings.CONFIRM_CONTENT_USE_ON : commonStrings.CONFIRM_CONTENT_USE_OFF,
      async () => {
        const params = {
          exhMstSeq: data.exhMstSeq,
          exhUseYn: checked ? "Y" : "N",
        };        
        await requestEditExhibitionUseYn(params);
      }
    );
  };

  const renderTableActions = () => {
    return [
      {
        title: "기획전 등록",
        onClick: () => setShowAddModal(true),
      },
    ];
  };

  return (
    <>
      <ContentLayout filterable={false} headerTitle="기획전 관리(타고바이)">
        {exFetchProgress && renderLoading()}
        {exhibitions && (
          <TableLayout
            gridApi={gridApi}
            title="기획전 리스트"
            totalCount={exhibitions.listCnt}
            rightActions={renderTableActions()}
            methodName="/admin/tb-ex"
          >
            <ClientRowTable
              apiRef={(api) => {
                setGridApi(api);
              }}
              rowData={exhibitions.list}
              cols={createExhibitionMasterCols()}
              frameworkComponents={{
                [USE_YN_RENDERER]: renderSwitchCol(
                  "exhUseYn",
                  handleUseYnChange
                ),
                ...createActionCellRenderers(undefined, handleDetail, {
                  hideDeleteButton: true,
                }),
              }}
            />
          </TableLayout>
        )}
      </ContentLayout>
      <AddExhibitionModal
        visible={showAddModal}
        onCancel={() => setShowAddModal(false)}
        onDataChange={async () => {
          await requestFetchExhibitionMasters();
        }}
      />
      <ExhibitionDetailModal
        visible={detailModalVisible}
        onCancel={() => {
          setDetailModalVisible(false);
        }}
        exhibitionMaster={selectedExhibition}
        onDataChange={async () => {
          await requestFetchExhibitionMasters();
        }}
      />
    </>
  );
};

TagoExhibitionManager.defaultProps = {};
export default TagoExhibitionManager;
