export default class EstimationNewCarCatalog {
  // MODE 상품ID
  public prodId = "";

  // COSS 상품ID
  public cossProdId = "";

  // 노출순번
  public viewSeq = "";

  // 상품명
  public prodNm = "";

  // 상품설명
  public prodDesc = "";

  // 신차장기 판매구분
  public ncarClsFlag = "";

  // 신차장기 판매구분명
  public ncarClsFlagNm = "";

  // 대표제조사명
  public repMakerNm = "";

  // 대표차명
  public repCarTypeNm = "";

  // 대표 차량 외관 이미지
  public repCarImg = "";

  // 재고
  public prodStock = 0;

  // 차량타입
  public shapeCd = "";

  // 차량타입명
  public shapeNm = "";
}
