import TagMaster from "./TagMaster";

/**
 * 월렌트 상품명 리스트
 */
export class McProdNmList {
  // 상품명 리스트
  public prodTagList: McProdNmTagDetail[] = [];
}

/**
 * 월렌트 상품명 리스트
 */
export class McProdNmTagDetail {

  // 상품명
  public prodNm = "";

  // 태그 리스트
  public mainTagList: TagMaster[] = [];
}