import React, { useEffect, useState } from "react";
import moment from "moment";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import strings from "./constants/strings";
import { CONSULTING_STATE_RENDERER, createCols } from "./utils/table-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { get1MonthDateRange } from "../../../utils/date-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import { TABLE_DATE_FILTER_LIGHT_OPTIONS } from "../../../constants/enums";
import CustomerConsultingRequest from "../../../models/CustomerConsultingRequest";
import {
  createUserDetailRenderer,
  renderSwitchCol,
} from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import {
  editCustomerConsultingRequest,
  fetchCustomerConsultingRequests,
} from "../../../apis/counseling-recpt";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import { getErrorMessage } from "../../../utils/common-utils";
import { CustomerConsultingRequestEditParam } from "../../../apis/counseling-recpt/types";
import CustomerConsultingRequestDetailModal from "./pages/CustomerConsultingRequestDetailModal";
import Checkbox from "../../../components/shared/data-entry/antd/Checkbox";

const CustomerConsultingRequestManager: React.FC = () => {
  const REFRESH_DURATION_MIN = 5;

  const [gridApi, setGridApi] = useState<GridApi>();
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [rows, setRows] = useState<
    Array<CustomerConsultingRequest> | undefined
  >(undefined);
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>();
  const [logInfo, setLogInfo] = useState<string>("");

  let refresher: any;

  const cols = createCols();

  const filterInitialValues = {
    stDt: get1MonthDateRange(),
  };

  const [filterValues, setFilterValues] = useState<any>(filterInitialValues);

  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "요청일",
      options: TABLE_DATE_FILTER_LIGHT_OPTIONS,
    },
  ];

  useEffect(() => {
    (async function fetchData() {
      await requestDataList(filterValues);
    })();
    return () => {};
  }, []);

  useEffect(() => {
    if (autoRefresh) {
      refresher = setInterval(async () => {
        await requestDataList(filterValues);
      }, REFRESH_DURATION_MIN * 60 * 1000);
    } else {
      clearTimeout(refresher);
    }

    return () => {
      if (refresher) {
        clearInterval(refresher);
      }
    };
  }, [autoRefresh]);

  /**
   * Private Functions
   */

  const requestDataList = async (values: any) => {
    try {
      // 검색조건 설정
      const appliedFilters = JSON.stringify(values);
      setLogInfo(appliedFilters);
      const { stDt } = values;
      const stDtParam = moment(stDt[0]).format("YYYYMMDD");
      const endDtParam = moment(stDt[1]).format("YYYYMMDD");

      const estimations = await fetchCustomerConsultingRequests(
        stDtParam,
        endDtParam
      );

      const { items, count } = estimations;
      setRows(items);
      setTotalCount(count);

    } catch (e) {
      alertError(getErrorMessage(e));
      setRows([]);
    }
  };

  const requestUpdateConsultingState = async (
    cnslRecptId: string,
    params: CustomerConsultingRequestEditParam
  ) => {
    try {
      await editCustomerConsultingRequest(cnslRecptId, params)
      .then(
        () => {
          alertSuccess("상담처리 상태가 변경되었습니다");
          requestDataList(filterValues);
        }
      );
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { cnslRecptId } = data as CustomerConsultingRequest;
    setSelectedDataId(cnslRecptId);
    setModalVisible(true);
  };

  const handleSearchSubmit = async (values: any) => {    
    setFilterValues(values);
    gridApi?.showLoadingOverlay();
    await requestDataList(values);
    gridApi?.hideOverlay();
  };

  const handleUserDetailCellClick = (custId?: string) => {
    alertError(`custId 필요 ${custId}`);
  };

  const handleConsultingStateCheckChange = (
    checked: boolean,
    data: CustomerConsultingRequest
  ) => {    
    showConfirm(
      checked ? "상담완료 처리" : "상담미완료 처리",
      checked
        ? "해당 상담신청을 완료 처리하시겠습니까?"
        : "해당 상담신청을 미완료 상태로 변경하시겠습니까?",
      async () => {
        await requestUpdateConsultingState(data.cnslRecptId, {
          cnslFinYn: checked ? "Y" : "N",
          cnslPicMemo: data.cnslPicMemo,
        });
      }
    );
  };

  /**
   * Render Helpers
   */

  const createActions = () => {
    return [
      {
        customView: true,
        render: () => {
          return (
            <Checkbox
              checked={autoRefresh}
              onChange={({ target }) => {
                const { checked } = target;
                setAutoRefresh(checked);
              }}
            >
              {`자동갱신(${REFRESH_DURATION_MIN}분)`}
            </Checkbox>
          );
        },
      },
    ];
  };

  return (
    <>
      <ContentLayout
        filterable
        headerTitle={strings.TITLE_HEADER}
        filterForms={filterForms}
        filterValues={filterValues}
        filterInitialValues={filterInitialValues}
        onSearchSubmit={handleSearchSubmit}
      >
        <TableLayout
          leftActions={createActions()}
          gridApi={gridApi}
          title={strings.TITLE_TABLE_HEADER}
          totalCount={totalCount}
          hideSelectionCountText
          methodName="/admin/counseling-recpt"
          logInfo={logInfo}
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            cols={cols}
            frameworkComponents={{
              [CONSULTING_STATE_RENDERER]: renderSwitchCol(
                "cnslFinYn",
                handleConsultingStateCheckChange
              ),
              ...createUserDetailRenderer(handleUserDetailCellClick),
              ...createActionCellRenderers(undefined, handleDetailClick, {
                hideDeleteButton: true,
              }),
            }}
          />
        </TableLayout>
        <CustomerConsultingRequestDetailModal
          cnslRecptId={selectedDataId}
          visible={modalVisible}
          onDataChange={async () => {
            gridApi?.showLoadingOverlay();
            await requestDataList(filterValues);
            gridApi?.hideOverlay();
          }}
          onCancel={() => {
            setModalVisible(false);
          }}
        />
      </ContentLayout>
    </>
  );
};

CustomerConsultingRequestManager.defaultProps = {};
export default CustomerConsultingRequestManager;
