import moment from "moment";
import { formatDateString } from "../utils/date-utils";

class CustomerConsultingRequestDetail {
  // 상품ID
  public prodId = "";

  // 서비스ID
  public srvcId = "";

  // 상품명
  public prodNm = "";

  // 상품상세
  public prodDesc = "";

  // 판매구분
  public ncarClsFlag = "";

  // 판매구분명
  public ncarClsFlagNm = "";

  // 상담신청ID
  public cnslRecptId = "";

  // 상품ID
  public cnslCls = "";

  // 지능형상담유형
  public cnslClsNm = "";

  // 상담예약일시
  public cnslRsvDtm = "";

  // 회원ID
  public custId = "";

  // 이름
  public custNm = "";

  // 가입여부
  public custEntryYn = "";

  // 휴대폰번호
  public hpNo = "";

  // 마케팅활용동의
  public mktAgreYn = "";

  // 고객문의
  public custAsk = "";

  // 상담완료여부
  public cnslFinYn = "";

  // 상담사메모
  public cnslPicMemo = "";

  // 변경자
  public updUser = "";

  // 변경자
  public updUserNm = "";

  // 변경일시
  public updDtm = "";

  // 등록자
  public regUser = "";

  // 등록일시
  public regDtm = "";

  // 상담완료 상담사 ID
  public finClslId = "";  

  // 상담완료 상담사 이름
  public finClslNm = "";  

  // 상담완료 일시
  public finClslDt = "";  

  get formattedFinClsDate() {
    if (this.finClslDt) {
      const date = moment(this.finClslDt, "YYYYMMDDHHmmss");
      return formatDateString(date, true);
    }

    return "";
  }

  get formattedUpdDate() {
    if (this.updDtm) {
      const date = moment(this.updDtm, "YYYYMMDDHHmmss");
      return formatDateString(date, true);
    }

    return "";
  }

  get formattedReqDate() {
    if (this.regDtm) {
      const date = moment(this.regDtm, "YYYYMMDDHHmmss");
      return formatDateString(date, true);
    }

    return "";
  }
}

export default CustomerConsultingRequestDetail;
