import React from "react";
import DateRangePicker from "../../../../../data-entry/antd/DateRangePicker";
import { IFilterComp } from "ag-grid-community/dist/lib/interfaces/iFilter";
import { GridApi } from "ag-grid-community";
import { RangeValue } from "rc-picker/lib/interface";
import { Moment } from "moment";

interface DateRangerFilterProps {
  onFloatingFilterChanged(type: string, value: any): void;
  parentFilterInstance: (
    callback: (filterInstance: IFilterComp) => void
  ) => void;
  api: GridApi;
}

class DateRangeFilter extends React.Component<
  DateRangerFilterProps,
  { value: RangeValue<Moment> }
> {
  state = {
    value: null,
  };
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  onParentModelChanged = (parentModel: any) => {
    if (!parentModel) {
      this.setState({
        value: null,
      });
    }
  };

  handleRangePickerChange = (values: RangeValue<Moment>) => {
    const { parentFilterInstance, api } = this.props;
    this.setState({
      value: values,
    });
    if (values && values.length > 1) {
      const [startDate, endDate] = values;
      parentFilterInstance((filterInstance) => {
        if (startDate && endDate) {
          filterInstance.setModel({
            type: "inRange",
            dateFrom: startDate.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            // 시간 제어가 안되어 UI 와 별개로 1일을 추가해줌
            // Ex) 원하는 동작  12.1 ~ 12.2 -> 12.1 00:00 ~ 12.2 23:59
            // Ag-grid 에서는 12.1 ~ 12.2 -> 12.1 00:00 ~ 12.2 00:00
            dateTo: endDate.clone().add(1, "day").format("YYYY-MM-DD HH:mm:ss"),
          });
          api.onFilterChanged();
        }
      });
    } else {
      parentFilterInstance((filterInstance) => {
        filterInstance.setModel(null);
        api.onFilterChanged();
      });
    }
  };

  /**
   * Render Helpers
   */

  render() {
    const { value } = this.state;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <DateRangePicker
          value={value}
          inputReadOnly
          size={"small"}
          format="YY.MM.DD"
          onChange={this.handleRangePickerChange}
        />
      </div>
    );
  }
}

export default DateRangeFilter;
