import React, { useState } from "react";
import moment from "moment";
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import strings from "./constants/strings";
import { createRentContractCols } from "./utils/table-utils";
import RentContractDetailModal from "./pages/RentContractDetailModal";
import { createActionCellRenderers } from "../../../utils/table-utils";
import {
  get1MonthDateRange,
  getTodayDateRange,
} from "../../../utils/date-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import {
  getPageIndex,
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { createUserDetailRenderer } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { fetchRentContracts } from "../../../apis/rent-contracts";
import CustomerModal from "../../customer/CustomerManager/pages/CustomerModal";
import {
  CARMANAGER_ASSIGN_YN_OPTIONS,
  CONTRACT_KIND_FILTER_OPTIONS,
  CONTRACT_STATE_FILTER_OPTIONS,
  DELIVERY_STATE_FILTER_OPTIONS,
  ENUM_CONTRACT_STATE_APPROVAL,
  PLDG_YN_OPTIONS,
} from "./constants/enums";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import commonStrings from "../../../constants/strings";
import {
  ENUM_BIZ_AUTH_OPTIONS,
  ENUM_DTY_ID_BIZ,
  ENUM_KIS_OPT_VALUE_VM,
  FILTER_OPTIONS_CONTRACT_CAR_TYPE,
  FILTER_OPTIONS_NC_PROD_TYPE,
  COOPER_SEARCH_OPTIONS,
  SK_GRP_DISCOUNT_SEARCH_OPTIONS,
  TKVRTRN_SEARCH_OPTIONS,
  OPTIONS_YN,
  CNSL_YN_OPTIONS,
} from "../../../constants/enums";
import Button from "../../../components/shared/general/antd/Button";
import RentContract from "../../../models/RentContract";
import RecCarInfoModal from "./pages/RecCarInfoModal";
import useAuth from "../../../hooks/useAuth";
import { setConnectLogs } from "@apis/logs";

const RentContractManager: React.FC = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>();
  const [selectedDataSeq, setSelectedDataSeq] = useState<string | undefined>();
  const [customerModalVisible, setCustomerModalVisible] = useState<boolean>(
    false
  );
  const [custId, setCustId] = useState<string>();
  const [cntrId, setCntrId] = useState<string>();
  const [cntrSeq, setCntrSeq] = useState<number>();
  const [gridApi, setGridApi] = useState<GridApi>();
  const { user } = useAuth();

  // 고객센터
  // if (user.dtyId === "202104190003" || user.loginId === "70000337" || user.loginId === "79000545") {
  //   cols = createRentContractCols2();
  // }
  const isCS = user.dtyId === "202104190003" || user.loginId === "70000337" || user.loginId === "79000545";

  const [recCarModalVisible, setRecCarModalVisible] = useState(false);
  const [selectedProdId, setSelectedProdId] = useState<string>();
  const [selectedModeGrdId, setSelectedModeGrdId] = useState<string>();
  const [logInfo, setLogInfo] = useState<string>("");

  const getInitialDateRange = () => {
    if (user.dtyId === ENUM_DTY_ID_BIZ) return getTodayDateRange();
    return get1MonthDateRange();
  };

  const getInitialContractState = () => {
    if (user.dtyId === ENUM_DTY_ID_BIZ) return ENUM_CONTRACT_STATE_APPROVAL;
    return "";
  };

  const filterInitialValues = {
    stDt: getInitialDateRange(),
    estDt: null,
    custNm: "",
    busiNo: "",
    hpNo: "",
    custClsCd: "",
    srvcId: "",
    ncarClsFlag: "",
    consiStsCd: "",
    cntrStsCd: getInitialContractState(),
    carmanNm: "",
    carmanAsgnYn: "",
    carNo: "",
    pldgAmtPaymentYn: "",
    addVrfYn: "",
    cnslUserNm: "",
    skGrpYn: "",
    cooperCd: "",
    tkvRtrnYnCd: "",
    tkvReqYn: "",
    cnslEndYn: "",
    erpCustCd: "",
  };

  let filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "계약기간",
    },
    {
      key: "estDt",
      type: FilterElementType.DateRangePicker,
      label: "견적기간",
      extraOptions: {
        allowClear: true,
      },
    },
    {
      key: "busiNo",
      type: FilterElementType.Input,
      label: "사업자번호",
      placeholder: commonStrings.HINT_BIZ_NO,
    },
    {
      key: "custNm",
      type: FilterElementType.Input,
      label: "계약자명",
      placeholder: commonStrings.HINT_CONTRACTOR_NAME,
    },
    {
      key: "hpNo",
      type: FilterElementType.NumberInput,
      label: "휴대폰번호",
      placeholder: "휴대폰번호를 입력해주세요",
    },
    {
      key: "custClsCd",
      type: FilterElementType.Select,
      options: CONTRACT_KIND_FILTER_OPTIONS,
      label: "계약유형",
    },
    {
      key: "srvcId",
      type: FilterElementType.Select,
      options: FILTER_OPTIONS_CONTRACT_CAR_TYPE,
      label: "차량구분",
    },
    {
      key: "ncarClsFlag",
      type: FilterElementType.Select,
      options: FILTER_OPTIONS_NC_PROD_TYPE,
      label: "판매유형",
    },
    {
      key: "consiStsCd",
      type: FilterElementType.Select,
      options: DELIVERY_STATE_FILTER_OPTIONS,
      label: "출고여부",
    },
    {
      key: "pldgAmtPaymentYn",
      type: FilterElementType.Select,
      label: "담보금액 납부여부",
      options: PLDG_YN_OPTIONS,
    },
    {
      key: "carmanNm",
      type: FilterElementType.Input,
      label: "담당 카매니저",
      placeholder: commonStrings.HINT_CAR_MANAGER_NAME,
    },
    {
      key: "carmanAsgnYn",
      type: FilterElementType.Select,
      label: "카매니저 배정여부",
      options: CARMANAGER_ASSIGN_YN_OPTIONS,
    },
    {
      key: "carNo",
      type: FilterElementType.Input,
      label: "차량번호",
      placeholder: commonStrings.HINT_CAR_NO,
    },
    {
      key: "cntrStsCd",
      type: FilterElementType.Select,
      options: CONTRACT_STATE_FILTER_OPTIONS,
      label: "계약진행상태",
    },
    {
      key: "addVrfYn",
      type: FilterElementType.Select,
      options: [{ label: "전체", value: "" }, ...ENUM_BIZ_AUTH_OPTIONS],
      label: "사업자 인증상태",
    },
    {
      key: "cnslUserNm",
      type: FilterElementType.Input,
      label: "계약상담원",
      placeholder: "계약상담원명을 입력해주세요",
    },
    {
      key: "skGrpYn",
      type: FilterElementType.Select,
      label: "외부채널",
      options: SK_GRP_DISCOUNT_SEARCH_OPTIONS,
    },
    {
      key: "cooperCd",
      type: FilterElementType.Select,
      label: "제휴채널",
      options: COOPER_SEARCH_OPTIONS,
    },
    {
      key: "tkvRtrnYnCd",
      type: FilterElementType.Select,
      label: "인수옵션",
      options: TKVRTRN_SEARCH_OPTIONS,
    },
    {
      key: "tkvReqYn",
      type: FilterElementType.Select,
      label: "인수요청여부",
      options: OPTIONS_YN,
    },
    {
      key: "cnclDt",
      type: FilterElementType.DateRangePicker,
      label: "계약취소기간",
      extraOptions: {
        allowClear: true,
      },
    },
  ];

  // 고객센터
  if(isCS) {
    filterForms.push(
      {
        key: "cnslEndYn",
        type: FilterElementType.Select,
        label: "계약상담",
        options: CNSL_YN_OPTIONS,
      },
    );
  }

  filterForms.push(
    {
      key: "erpCustCd",
      type: FilterElementType.Input,
      label: "거래처코드",
      placeholder: "거래처코드을 입력해주세요",
    },
  );

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);

    const {
      stDt,
      estDt,
      busiNo,
      custNm,
      custClsCd,
      srvcId,
      ncarClsFlag,
      consiStsCd,
      cntrStsCd,
      carmanNm,
      carNo,
      carmanAsgnYn,
      pldgAmtPaymentYn,
      hpNo,
      addVrfYn,
      cnslUserNm,
      skGrpYn,
      cooperCd,
      tkvRtrnYnCd,
      tkvReqYn,
      cnclDt,
      cnslEndYn,
      erpCustCd
    } = appliedFilterValues;
    const stDtParam = moment(stDt[0]).format("YYYYMMDD");
    const endDtParam = moment(stDt[1]).format("YYYYMMDD");    

    let estStParam;
    let estEndParam;
    if (estDt && estDt.length > 1) {
      estStParam = moment(estDt[0]).format("YYYYMMDD");
      estEndParam = moment(estDt[1]).format("YYYYMMDD");
    }

    let cnclStDt;
    let cnclEndDt;
    if (cnclDt && cnclDt.length > 1) {
      cnclStDt = moment(cnclDt[0]).format("YYYYMMDD");
      cnclEndDt = moment(cnclDt[1]).format("YYYYMMDD");
    }

    let addVrfYnParam = "";
    let kisAuthParam = "";

    if (addVrfYn === ENUM_KIS_OPT_VALUE_VM) {
      kisAuthParam = "Y";
      addVrfYnParam = "";
    } else {
      addVrfYnParam = addVrfYn;
      kisAuthParam = "";
    }

    try {
      const { items, count } = await fetchRentContracts(
        getPageIndex(startRow, paginationPageSize),
        paginationPageSize,
        stDtParam,
        endDtParam,
        busiNo,
        estStParam,
        estEndParam,
        custNm,
        custClsCd,
        ncarClsFlag,
        consiStsCd,
        cntrStsCd,
        carmanNm,
        carNo,
        pldgAmtPaymentYn,
        hpNo,
        carmanAsgnYn,
        addVrfYnParam,
        kisAuthParam,
        cnslUserNm,
        skGrpYn,
        srvcId,
        cooperCd,
        tkvRtrnYnCd,
        tkvReqYn,
        cnclStDt as string,
        cnclEndDt as string,
        cnslEndYn as string,
        erpCustCd
      );
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
      setConnectLogs({
        userId: user.userId,
        userIp: user.clientIp,
        methodNm: "/admin/rent-cntr-list",
        menuName: "견적/계약 - 계약관리 (렌탈형)",
        actionFlag: "SEARCH",
        reqCntn: appliedFilters
      });
    } catch (e) {
      setTotalCount(0);
      success({
        rowData: [],
        rowCount: 0,
      });
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { cntrId, cntrSeq } = data;
    setSelectedDataId(cntrId);
    setSelectedDataSeq(cntrSeq);
    setModalVisible(true);
  };

  const handleUserDetailCellClick = (
    custId?: string,
    cntrId?: string,
    cntrSeq?: number
  ) => {
    if (!custId) {
      alertError(`${custId} 고객 아이디가 없습니다.`);
    } else {
      setCustId(custId);
      setCntrId(cntrId);
      setCntrSeq(cntrSeq);
      setCustomerModalVisible(true);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <PaginationTableLayout
        defaultLimit={100}
        headerTitle={strings.TITLE_HEADER_RENT_CONTRACT_MANAGER}
        headerDescription={strings.DESC_RENT_CONTRACT_MANAGER}
        tableHeaderTitle={strings.TABLE_HEADER_RENT_CONTRACT_LIST}
        onDataLoad={onDataLoad}
        cols={createRentContractCols(isCS)}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        hideFilterAction
        totalCount={totalCount}
        frameworkComponents={{
          recCarDetail: (props: ICellRendererParams) => {
            const { data } = props;
            if (data.recClsDeg) {
              return (
                <Button
                  type="default"
                  size="small"
                  onClick={() => {
                    const model = data as RentContract;
                    setSelectedProdId(model.extgProdId);
                    setSelectedModeGrdId(model.extgModeGrdId);
                    setRecCarModalVisible(true);
                  }}
                >
                  {`${data.recClsDeg}차추천`}
                </Button>
              );
            }

            return "";
          },
          ...createUserDetailRenderer(handleUserDetailCellClick),
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
          userDetailButton: (props: ICellRendererParams) => {
            const { data } = props;
            return (
              <Button
                type="default"
                size="small"
                onClick={() => {
                  alertSuccess(JSON.stringify(data));
                }}
              >
                TEST
              </Button>
            );
          },
        }}
        apiRef={(api) => {
          setGridApi(api);
        }}
        methodName="/admin/rent-cntr-list"
        logInfo={logInfo}
      />
      <RentContractDetailModal
        cntrId={selectedDataId}
        cntrSeq={selectedDataSeq}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onDataChange={() => {
          refreshServerSideTable(gridApi);
        }}
      />
      <CustomerModal
        editable
        id={custId}
        cntrId={cntrId}
        cntrSeq={cntrSeq}
        visible={customerModalVisible}
        onCancel={() => {
          setCustomerModalVisible(false);
        }}
        onDataChange={() => {
          refreshServerSideTable(gridApi);
        }}
      />
      <RecCarInfoModal
        visible={recCarModalVisible}
        onCancel={() => {
          setRecCarModalVisible(false);
        }}
        prodId={selectedProdId}
        modeGrdId={selectedModeGrdId}
      />
    </>
  );
};

RentContractManager.defaultProps = {};
export default RentContractManager;
