export const CUST_CLS_FILTER_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "정회원",
    value: "정회원",
  },
  {
    label: "가회원",
    value: "가회원",
  },
];

export const MARKETING_FILTER_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "SMS 수신동의",
    value: "SMS",
  },
  {
    label: "TM 수신동의",
    value: "TM",
  },
];

export const MGM_FILTER_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "가입",
    value: "Y",
  },
  {
    label: "미가입",
    value: "N",
  },
];

export const CAR_MANAGER_TRANSFER_REASON_OPTIONS = [
  {
    label: "인도일정 미확보",
    value: "A29001",
  },
  {
    label: "조직변경",
    value: "A29002",
  },
  {
    label: "퇴사",
    value: "A29003",
  },
  {
    label: "주말 인수",
    value: "A29006",
  },
  {
    label: "고객요청(담당자변경)",
    value: "A29007",
  },
  {
    label: "직접인수 취소",
    value: "A29008",
  },
];
