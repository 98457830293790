import styled from "styled-components";

export const ReportTableWrapper = styled.div`
  background: white;
`;

export const ReportTableHeaderWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.common.colors.border};
  height: 64px;
`;

export const ReportTableHeader = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

export const ReportTableContentWrapper = styled.div`
  
  padding:16px;
`;
