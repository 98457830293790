import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { CustomerEditParam, CustomerInfoDO } from "./types";
import Customer from "../../models/Customer";
import BusinessInfoHistory from "../../models/BusinessInfoHistory";
import Estimation from "../../models/Estimation";
import CustomerInfoHistory from "../../models/CustomerInfoHistory";
import CustomerDetail from "../../models/CustomerDetail";
import RentContract from "../../models/RentContract";
import { BizEditInfo } from "../../pages/customer/CustomerManager/pages/CustomerModal/types";

const apiRootUrl = `${ROOT_URL}/admin/user-info`;

/**
 * 회원 리스트 조회
 */
export const fetchCustomers = async (
  pageIndex: number,
  pageSize: number,
  stDt: string,
  endDt: string,
  custNm?: string,
  custCls?: string,
  hpNo?: string,
  mktAgreYn?: string,
  carmanNm?: string,
  cntrCnt?: number,
  busrCustNm?: string,
  skFrnsYn?: string,
  authStDt?: string,
  authEndDt?: string
): Promise<ListItemResponse<Customer>> => {
  const res = await request(HttpMethod.Get, apiRootUrl, {
    stDt,
    endDt,
    pageIndex,
    pageSize,
    custNm: custNm || undefined,
    custCls: custCls || undefined,
    hpNo: hpNo || undefined,
    mktAgreYn: mktAgreYn || undefined,
    carmanNm: carmanNm || undefined,
    cntrCnt: typeof cntrCnt === "number" ? cntrCnt : undefined,
    busrCustNm,
    skFrnsYn,
    authStDt,
    authEndDt,
  });

  const { data } = res;
  const { response } = data;
  const { totalCnt, list } = response;

  return {
    items: plainToClass(Customer, list as Array<JSON>),
    count: Number(totalCnt),
  };
};

/**
 * 회원 상세 조회
 */

export const fetchCustomerDetail = async (
  custId: string
): Promise<CustomerDetail> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/${custId}`);
  const { data } = res;
  const { response } = data;
  return plainToClass(CustomerDetail, response);
};

export const editCustomerInfos = async (custId: string ,customerInfo: CustomerInfoDO) => {

  try {
    return await request(HttpMethod.Put, `${apiRootUrl}/${custId}`, undefined, customerInfo);
  } catch (e) {
    console.log("updateCustomerDetail error : " + e)
    return 0;
  }
};

/**
 * 사업자 정보 수정(PUT: ~​/admin​/user-info-business​/{busrCustId})
 * 
 * @param businessInfo 
 * @returns 
 */
export const updateBusiness = async (businessInfo: BizEditInfo) => {
  try {
    return await request(HttpMethod.Put, `${apiRootUrl}-business/${businessInfo.busrCustId}`, undefined, businessInfo);
  } catch (e) {
    console.log("updateBusiness error : " + e)
    return 0;
  }
};

/**
 * 사업자 정보 삭제(PUT: ~​/admin​/user-info-business-del​/{busrCustId})
 * 
 * @param busrCustId 
 * @returns 
 */
export const deleteBusiness = async (busrCustId: string) => {

  try {
    return await request(HttpMethod.Put, `${apiRootUrl}-business-del/${busrCustId}`);
  } catch (e) {
    console.log("deleteBusiness error : " + e)
    return 0;
  }
};


export const fetchCustomerDetailContract = async (
  custId: string,
  cntrId: string,
  cntrSeq: number
): Promise<CustomerDetail> => {
  const res = await request(
    HttpMethod.Get,
    `${apiRootUrl}/${custId}/${cntrId}/${cntrSeq}`
  );
  const { data } = res;
  const { response } = data;
  return plainToClass(CustomerDetail, response);
};

/**
 * 회원 수정
 */
export const editCustomerInfo = async (
  noticeSeq: string,
  params: CustomerEditParam
) => {
  await request(
    HttpMethod.Put,
    `${apiRootUrl}/${noticeSeq}`,
    undefined,
    params
  );
};

/**
 * 회원 정보 변경 이력 리스트
 */

export const fetchCustomerInfoHistories = async (custId: string) => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/user-info-history/${custId}`
  );

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(CustomerInfoHistory, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 사업자 변경 이력 리스트 조회
 */
export const fetchBusinessInfoHistories = async (
  busrCustId: string
): Promise<ListItemResponse<BusinessInfoHistory>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/user-info-business-history/${busrCustId}`
  );

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(BusinessInfoHistory, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 고객 견적 리스트
 */
export const fetchCustomerEstimations = async (
  custId: string
): Promise<ListItemResponse<Estimation>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/cust-estimate/${custId}`
  );

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(Estimation, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 고객 계약 리스트
 */
export const fetchCustomerContracts = async (
  custId: string
): Promise<ListItemResponse<RentContract>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/cust-contract/${custId}`
  );
  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(RentContract, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 사업자 승인 상태 변경
 */
export const editBizUserAuthState = async (
  busrCustId: string,
  addVrfYn: string
) => {
  const url = `${ROOT_URL}/admin/direct-business/${busrCustId}`;
  await request(HttpMethod.Put, url, undefined, {
    addVrfYn,
  });
};

export const editBizUserAuthStateContract = async (
  busrCustId: string,
  addVrfYn: string,
  cntrId: string,
  cntrSeq: number
) => {
  const url = `${ROOT_URL}/admin/direct-business-cntr//${busrCustId}/${cntrId}/${cntrSeq}`;
  await request(HttpMethod.Put, url, undefined, {
    addVrfYn,
  });
};
