class Customer {
  // 회원번호
  public custId = "";

  // 등록일시
  public regDtm = "";

  // 회원구분
  public custCls = "";

  // 본인인증여부
  public ciYn = "";

  // 회원명
  public custNm = "";

  // 휴대폰번호
  public hpNo = "";

  // 보유포인트
  public holdPoint = "";

  // 사업자구분
  public busrClsNm = "";

  // 계약건수
  public cntrCnt = "";

  // 담당카매니저ID
  public carmanId = "";

  // 담당카매니저명
  public carmanNm = "";

  // 담당카매니저휴대폰
  public carmanHpNo = "";

  // 담당카매니저담당지역
  public carmanSiteNm = "";

  // 소개프로그램가입여부
  public skFrnsYn = "";

  // 마케팅활용동의여부
  public mktAgreYn = "";

  // 회원상태
  public custSts = "";

  // DEL여부
  public delFlag = "";

  // 사용여부
  public useYn = "";

  // 개인사업자 인증상태
  public addVrfCnt = "";

  get isIdentified() {
    return this.ciYn === "Y";
  }

  get isSKFriends() {
    return this.skFrnsYn === "Y";
  }

  get isMarketingAgreementAccepted() {
    return this.mktAgreYn === "Y";
  }
}

export default Customer;
