import React from "react";
import { SizeType } from "antd/es/config-provider/SizeContext";

import { Radio as AntRadio, RadioProps } from "antd";

export interface RadioOption {
  label: string;
  value: any;
}

interface Props extends RadioProps {
  options?: Array<RadioOption>;
  onChange?: (value: any) => void;
  value?: any;
  size?: SizeType;
  buttonStyle?: boolean;
}

const Radio: React.FC<Props> = (props: Props) => {
  const { onChange, options = [], buttonStyle = true, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <AntRadio.Group
      {...rest}
      onChange={(e) => {
        if (onChange) onChange(e.target.value);
      }}
    >
      {options.map(({ label, value }) => {
        if (buttonStyle) {
          return (
            <AntRadio.Button key={value} value={value}>
              {label}
            </AntRadio.Button>
          );
        }

        return (
          <AntRadio key={value} value={value}>
            {label}
          </AntRadio>
        );
      })}
    </AntRadio.Group>
  );
};

Radio.defaultProps = {
  options: [],
};
export default Radio;
