import React, { useState } from "react";
import { FormItemProps } from "antd";
import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../types";
import CarManagerDeliverySchedule from "../../../../../models/CarManagerDeliverySchedule";
import {
  editCarManagerDeliveryScheduleDetail,
  fetchCarManagerDeliveryScheduleDetail,
} from "../../../../../apis/car-managers";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import CarManagerDeliveryScheduleDetail from "../../../../../models/CarManagerDeliveryScheduleDetail";
import FormBuilder, {
  defaultFormItemLayout,
} from "../../../../../components/shared/data-entry/FormBuilder";
import {
  createFormInfo,
  createInitialValues,
  createInitialValuesFromScheduleDetail,
  createValidationSchema,
} from "./utils/form-utils";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";

import { formatDateString } from "../../../../../utils/date-utils";
import commonStrings from "../../../../../constants/strings";

interface Props extends ModalProps {
  scheduleInfo?: CarManagerDeliverySchedule;
}

const ScheduleDetailModal: React.FC<Props> = (props: Props) => {
  const { scheduleInfo, visible, onCancel, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [scheduleDetail, setScheduleDetail] = useState<
    CarManagerDeliveryScheduleDetail
  >();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    if (scheduleInfo) {
      await requestFetchScheduleDetail(scheduleInfo);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchScheduleDetail = async (
    scheduleInfo: CarManagerDeliverySchedule
  ) => {
    setDataFetching(true);
    try {
      const { cntrId, consiId, consiClsCd, consiSeq } = scheduleInfo;
      const scheduleDetailInfo = await fetchCarManagerDeliveryScheduleDetail(
        cntrId,
        consiId,
        consiClsCd,
        consiSeq
      );
      setInitialValues(
        createInitialValuesFromScheduleDetail(scheduleDetailInfo)
      );
      setScheduleDetail(scheduleDetailInfo);
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  const requestEditSchedule = async (values: FormikValues) => {
    if (scheduleInfo) {
      const { consiId, consiClsCd, consiSeq } = scheduleInfo;
      const { arvDtm } = values;
      setConfirmLoading(true);
      try {
        await editCarManagerDeliveryScheduleDetail(
          consiId,
          consiClsCd,
          consiSeq,
          {
            arvDtm: arvDtm.format("YYYYMMDD"),
          }
        );
        alertSuccess(commonStrings.FEEDBACK_EDIT_CAR_MAN_SCHEDULE);
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    await requestEditSchedule(values);
  };

  /**
   * Render Helpers
   */
  const renderDetail = () => {
    if (scheduleDetail) {
      const formConfig = {
        labelAlign: "right",
        colon: true,
        ...defaultFormItemLayout,
      } as FormItemProps;

      const textStyle = {
        color: "#626DFF",
      };

      return (
        <>
          <div
            style={{
              borderRadius: "8px",
              backgroundColor: "#FAFAFC",
              padding: "16px",
              marginBottom: "24px",
            }}
          >
            <FormItem label="카매니저명" {...formConfig}>
              <span style={textStyle}>{scheduleDetail.userNm}</span>
            </FormItem>
            <FormItem label="고객명" {...formConfig}>
              <span style={textStyle}>{scheduleDetail.custNm}</span>
            </FormItem>
            <FormItem label="차량번호" {...formConfig}>
              <span style={textStyle}>{scheduleDetail.carNo}</span>
            </FormItem>
            <FormItem label="모델명" {...formConfig}>
              <span style={textStyle}>{scheduleDetail.modlNm}</span>
            </FormItem>
            <FormItem label="출발지" {...formConfig}>
              <span style={textStyle}>{scheduleDetail.podAddr}</span>
            </FormItem>
            <FormItem label="도착지" {...formConfig}>
              <span
                style={textStyle}
              >{`${scheduleDetail.poaAddr} ${scheduleDetail.poaDtladdr}`}</span>
            </FormItem>
            <FormItem label="탁송상태" {...formConfig}>
              <span style={textStyle}>{scheduleDetail.consiStsNm}</span>
            </FormItem>
            <FormItem label="출발일" {...formConfig}>
              <span style={textStyle}>
                {formatDateString(scheduleDetail.dptDtm)}
              </span>
            </FormItem>
          </div>
          <FormBuilder
            formRef={(ref) => {
              formik = ref;
            }}
            initialValues={initialValues}
            forms={createFormInfo()}
            validationSchema={createValidationSchema()}
            onSubmit={handleSubmit}
          />
        </>
      );
    }
    return "";
  };

  return (
    <Modal
      size="small"
      title="차량인도 스케줄"
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      {dataFetching ? renderLoading() : renderDetail()}
    </Modal>
  );
};

ScheduleDetailModal.defaultProps = {};
export default ScheduleDetailModal;
