export default class SkuReport {
  // 제조사
  public repMakerNm = "";

  // 차명
  public cartypeNm = "";

  // 모델명
  public modlNm = "";

  // 외장색상
  public xtnlCarColorNm = "";

  // 내장색상
  public innrCarColorNm = "";

  // 옵션명
  public optNm = "";

  // 지역제한
  public useAreaNm = "";

  // T/M
  public totFuelNm = "";

  // 차형
  public totShapeNm = "";

  // 기초SKU(전일)
  public prodStock = "";

  // 계약
  public totalCntrCnt = "";

  // 당일취소
  public todayCnclCnt = "";

  // 실계약
  public realCntrCnt = "";

  // 기타사유
  public minusCarCnt = "";

  // 재고등록
  public addCarCnt = "";

  // 계약취소
  public otherCntrCnt = "";

  // 잔여SKU(마감)
  public extraProdStock = "";

  // 판매유형
  public ncarClsFlagNm = "";

  // 차량구분
  public srvcNm = "차량구분(신차,중고차)";
}
