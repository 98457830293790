import React, { useState } from "react";
import Modal from "../../feedback/antd/Modal";
import { ModalProps } from "../types";
import DetailSection, { FieldType } from "../../layout/DetailSection";

interface Props extends ModalProps {
  
}

const ExcelUploadModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel } = props;
  const onModalOpen = () => {
    
  };

  const renderDetail = () => {

    const basicInfoSection = [
      { label: "제휴사",    span: 2, },
      { label: "차량번호",  span: 3, },
      { label: "제조사",    span: 2, },
      { label: "차량명",    span: 2, },
      { label: "연식",      span: 2, },
      { label: "등록일자",  span: 3, },
      { label: "주행거리",  span: 3, },
      { label: "연료",      span: 2, },      
      { label: "배기량",    span: 2, },
      { label: "인승",      span: 2, },
      { label: "변속기",    span: 2, },
      { label: "내부색상",  span: 3, },
      { label: "외부색상",  span: 3, },
      { label: "차량가격",  span: 3, },
      { label: "자산번호",  span: 3, },
    ];

    const priceInfoSection = [
      { label: "차량번호",  span: 3, },
      { label: "계약기간",  span: 3, },
      { label: "보증금율",  span: 3, },
      { label: "약정1만",   span: 3, },
      { label: "약정2만",   span: 3, },
      { label: "약정3만",   span: 3, },
      { label: "약정4만",   span: 3, },
    ];

    return (
      <div>
        <DetailSection label="차량" fieldInfos={basicInfoSection} />
        <DetailSection label="렌탈료" fieldInfos={priceInfoSection} />
      </div>
    );
  };

  return (
    <Modal
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="업로드템플릿"
    >
      {renderDetail()}
    </Modal>
  );
};

export default ExcelUploadModal;
