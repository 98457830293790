export default class CarManagerReplacement {
  // 등록일
  public regDtm = "";

  // 부재자카매니저 아이디
  public carmanId = "";

  // 부재자카매니저 사용자아이디
  public userId = "";

  // 부재자카매니저 사용자명
  public userNm = "";

  // 부재자카매니저 지역권명
  public cdNm = "";

  // 부재자카매니저 사용자 전화번호
  public hpNo = "";

  // 대무카매니저 아이디
  public daCarmanId = "";

  // 대무카매니저 사용자아이디
  public daUserId = "";

  // 대무카매니저 사용자명
  public daUserNm = "";

  // 대무카매니저 지역권명
  public daCdNm = "";

  // 대무카매니저 사용자 전화번호
  public daHpNo = "";

  // 부재자카매니저 부재 사유 코드
  public rsnCd = "";

  // 부재자카매니저 부재사유
  public rsn = "";

  // 부재자카매니저 부재기간 시작일
  public stDt = "";

  // 부재자카매니저 부재기간 종료일
  public stEndDt = "";

  // 부재자카매니저 설정여부
  public useYn = "";

  public seq = "";
}
