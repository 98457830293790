import styled from "styled-components";

export const TableHeaderTitle = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const Container = styled.div``;
