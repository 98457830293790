import React from "react";
import { Path } from "history";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MenuInfo } from "rc-menu/lib/interface";
import { Menu } from "antd";
import { AsideContainer, LogoBox, MenuContentWrapper } from "./style";
import "./aside.css";
import sideMenuInfos, {
  SideMenuInfo,
} from "../../../../routes/constants/side-menu-items";
import useRouter from "../../../../hooks/useRouter";

const { SubMenu } = Menu;
const MenuItem = Menu.Item;

export interface AsideProps {}

const Aside: React.FC<AsideProps> = (props: AsideProps) => {
  const { push, router } = useRouter();

  const selectedKeys = [router.location.pathname];

  const renderMenuItems = (menus: SideMenuInfo[], isSubMenu = false) => {
    // console.log(createMenuList());
    return menus.map(({ title, subMenus, disabled, key }) => {
      const isActive =
        selectedKeys.filter((selectedKey) => {
          return selectedKey === key;
        }).length > 0;

      if (subMenus && subMenus.length > 0) {
        return (
          <SubMenu
            key={key}
            title={title}
            style={{
              fontWeight: isActive ? "bold" : "normal",
            }}
          >
            {renderMenuItems(subMenus, true)}
          </SubMenu>
        );
      }

      return (
        <MenuItem key={key}>
          <MenuContentWrapper>
            <span
              style={{
                textDecoration: disabled ? "line-through" : "initial",
                fontWeight: isActive ? "bold" : "normal",
              }}
            >
              {title}
            </span>
          </MenuContentWrapper>
        </MenuItem>
      );
    });
  };

  const getSubMenuDefaultOpenKeys = () =>
    sideMenuInfos
      .filter(({ subMenus }) => subMenus && subMenus.length > 0)
      .map(({ key }) => key);

  const handleMenuItemClick = (menuInfo: MenuInfo) => {
    const { key } = menuInfo;
    push(key as Path);
  };

  return (
    <AsideContainer>
      <PerfectScrollbar
        options={{
          minScrollbarLength: 1,
        }}
      >
        <div
          style={{
            height: "72px",
            paddingLeft: "24px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <LogoBox />
        </div>

        <Menu
          style={{
            border: "none",
          }}
          selectedKeys={selectedKeys}
          mode="inline"
          triggerSubMenuAction="click"
          selectable={false}
          defaultOpenKeys={getSubMenuDefaultOpenKeys()}
          onClick={handleMenuItemClick}
        >
          {renderMenuItems(sideMenuInfos)}
        </Menu>
      </PerfectScrollbar>
    </AsideContainer>
  );
};

Aside.defaultProps = {};
export default Aside;
