import moment from "moment";
import * as Yup from "yup";
import DirectSurveyResult from "../../../../../../../../../models/DirectSurvey/DirectSurveyResult";
import { getInitialDateRangeFromRaw } from "../../../../../../../../../utils/form-utils";
import errorMessages from "../../../../../../../../../constants/errors";
import { FormElementType } from "../../../../../../../../../components/shared/data-entry/FormBuilder/types";

export const createInitialValues = () => {
  return {
    qstnOrdNm: "",
    qstnGrpCd: "",
    qstnStDt: "",
    qstnEndDt: "",
  };
};

export const createInitialValuesFromDirectSurvey = (
  master: DirectSurveyResult
) => {
  const { qstnOrdId, qstnOrdNm, qstnStDt, qstnEndDt, qstnGrpCd } = master;
  const format = "YYYYMMDDHHmmss";
  const initialDisplayTerm = [
    moment(qstnStDt, format),
    moment(qstnEndDt, format),
  ];
  getInitialDateRangeFromRaw(qstnStDt, qstnEndDt);

  return {
    qstnOrdId,
    qstnGrpCd,
    qstnOrdNm,
    displayTerm: initialDisplayTerm,
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    qstnOrdNm: Yup.string().required(errorMessages.REQUIRED_FIELD),
    displayTerm: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(2, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createForms = () => {
  return [
    {
      key: "qstnOrdNm",
      label: "설문명",
      type: FormElementType.Input,
      placeholder: "설문명을 입력해주세요",
      required: true,
    },
    {
      key: "displayTerm",
      label: "설문기간",
      type: FormElementType.DateRangePicker,
      placeholder: "설문기간을 입력해주세요",
      required: true,
      config: {
        showTime: false,
      },
    },
  ];
};
