export default class PromotionReward {
  // 특가정보ID
  public spclAmtInfoId = "";

  // 특가정보제목
  public spclAmtInfoTitl = "";

  // 계약ID
  public cntrId = "";

  // 고객ID(계약자ID)
  public custId = "";

  // 고객명(계약자)
  public custNm = "";

  // 추천고객ID
  public recCustId = "";

  // 추천고객명
  public recCustNm = "";

  // 추천고객계약여부
  public recCustCntrYn = "";

  // 리워드지급여부
  public rewardPayYn = "";

  // 리워드지급완료일시
  public rewardPayCompleteDt = "";

  // 리워드지급해지일시
  public rewardPayRvcnDt = "";

  // 계약상태명
  public cntrStsNm = "";

  // 가입일
  public joinDt = "";

  // 계약일
  public cntrDt = "";

  // 차량인도일
  public trasDt = "";

  // 계약상품
  public prodNm = "";
}
