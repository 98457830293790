import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { createSurveyTemplateCols } from "./utils/table-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import { alertError } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { fetchSurveyMasterTemplates } from "../../../apis/survey";
import SurveyMaster from "../../../models/Survey/SurveyMaster";
import SurveyMasterDetailModal from "./pages/SurveyMasterDetailModal";

const SatisfactionSurveyManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [surveyModalVisible, setSurveyModalVisible] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState<SurveyMaster>();
  const [rows, setRows] = useState<Array<SurveyMaster> | undefined>(undefined);
  const [totalCount, setTotalCount] = useState(0);
  const cols = createSurveyTemplateCols();

  useEffect(() => {
    (async function fetchData() {
      await requestSurveyMasterTemplates();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestSurveyMasterTemplates = async () => {
    try {
      const surveyMasters = await fetchSurveyMasterTemplates();
      const { items, count } = surveyMasters;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
      setRows([]);
    }
  };

  /**
   * Event Actions
   */

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    const survey = data as SurveyMaster;
    setSelectedSurvey(survey);
    setSurveyModalVisible(true);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout filterable={false} headerTitle="만족도 조사 템플릿 관리">
        <TableLayout
          gridApi={gridApi}
          title="템플릿 리스트"
          totalCount={totalCount}
          hideSelectionCountText
          methodName="/admin/survey-management"
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            cols={cols}
            frameworkComponents={{
              ...createActionCellRenderers(undefined, handleEditClick, {
                hideDeleteButton: true,
              }),
            }}
          />
        </TableLayout>
      </ContentLayout>
      <SurveyMasterDetailModal
        survey={selectedSurvey}
        visible={surveyModalVisible}
        onDataChange={() => {}}
        onCancel={() => {
          setSurveyModalVisible(false);
        }}
      />
    </>
  );
};

SatisfactionSurveyManager.defaultProps = {};
export default SatisfactionSurveyManager;
