import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {createActionCol, CW_LARGE, CW_MEDIUM, CW_SMALL} from "../../../../utils/table-utils";
import { numberFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

export const createStockReportCols = (): Array<TableColumn> => {
  return [
    // 제조사명
    createServerTableCol("carMakerNm", "제조사", {
      width: CW_MEDIUM,
    }),
    // COSS모델ID
    createServerTableCol("cossModlId", "COSS 모델ID", {
      width: CW_MEDIUM,
    }),
    // COSS 모델명
    createServerTableCol("cossModlNm", "COSS 모델", {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 세부모델
    createServerTableCol("carClassNm", "세부모델", {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // NICE 트림
    createServerTableCol("carGradeNm", "트림", {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 현재 수량
    createServerTableCol("prodStock", "현재수량", {
      width: CW_SMALL,
      // cellRenderer:numberFormatRenderer("prodStock")
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 입고수량
    createServerTableCol("inCnt", "입고수량", {
      width: CW_SMALL,
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 현재수량
    createServerTableCol("outCnt", "출고수량", {
      width: CW_SMALL,
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 견적건수
    createServerTableCol("estCnt", "견적건수", {
      width: CW_SMALL,
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 계약건수
    createServerTableCol("cntrCnt", "계약건수", {
      width: CW_SMALL,
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 평균보유일수
    createServerTableCol("avgHldDays", "평균보유일수", {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
    }),
    {
      ...createActionCol(80),
    },
  ];
};
