import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import ImageListItem, { CW_IMG, CW_NO } from "./components/ImageListItem";
import { ColumnHeaderLabel } from "./components/ImageListItem/styles";
import { ModalProps } from "../../../../../../types";
import { AucCatalogImage } from "../../../../../../../models/AucCatalogImage";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import AucCatalogDetail from "../../../../../../../models/AucCatalogDetail";
import { editImageSortOrders } from "../../../../../../../apis/auc-catalogs";

interface Props extends ModalProps {
  catalogDetail?: AucCatalogDetail;
}

const AucImageSortOrderModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, catalogDetail, onDataChange } = props;

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [prodId, setProdId] = useState<string>();
  const [images, setImages] = useState<AucCatalogImage[]>([]);

  const onModalOpen = async () => {
    setDataFetching(true);
    if (catalogDetail) {
      setProdId(catalogDetail.prodId);
      setImages(catalogDetail.carImgList);
    } else {
      setImages([]);
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */
  const handleSubmit = async () => {
    setConfirmLoading(true);
    const params = images.map(({ imgId }, index) => {
      return {
        imgId,
        viewSeq: `${index}`,
      };
    });
    try {
      await editImageSortOrders(prodId as string, params);
      alertSuccess("이미지 게시순서가 변경되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    const dragCard = images[dragIndex];
    setImages(
      update(images, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
    );
  };

  /**
   * Render Helpers
   */
  const renderLayout = () => {
    return (
      <DndProvider backend={HTML5Backend}>
        <div
          style={{
            padding: "16px 8px",
            width: "100%",
            display: "flex",
            height: "60px",
            alignItems: "center",
          }}
        >
          <ColumnHeaderLabel width={CW_NO}>No.</ColumnHeaderLabel>
          <ColumnHeaderLabel width={CW_IMG}>이미지</ColumnHeaderLabel>
        </div>

        <div
          style={{
            height: "60vh",
            overflow: "auto",
            position: "relative",
          }}
        >
          {images.map((image, index) => {
            return (
              <ImageListItem
                id={image.imgId}
                key={image.imgId}
                image={image}
                index={index}
                moveCard={handleMoveItem}
              />
            );
          })}
        </div>
      </DndProvider>
    );
  };

  return (
    <Modal
      size="small"
      title="이미지 게시순번 관리"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={handleSubmit}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? renderLoading() : renderLayout()}
    </Modal>
  );
};

AucImageSortOrderModal.defaultProps = {
  visible: true,
};
export default AucImageSortOrderModal;
