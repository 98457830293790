class SiteCodeInfo {
  public carmanId = "";

  public siteCd = "";

  public siteNm = "";
}

export class SiteListInfo {
  public siteCd = "";

  public siteNm = "";
}

class CarManagerDetail {
  // 카매니저ID
  public carmanId = "";

  // 사용자ID
  public userId = "";

  // 사용자명
  public userNm = "";

  // 담당권역
  public carManDtlList: SiteCodeInfo[] = [];

  // 담당지역 리스트
  public carManSiteList: SiteListInfo[] = [];
}

export default CarManagerDetail;
