import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import { QuestionInfo } from "../../../../../../../models/Survey/SurveyMasterDetail";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {
  createFormInfo,
  createInitialValues,
  createInitialValuesFromQuestion,
  createValidationSchema,
} from "./utils/form-utils";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import { editSurveyQuestion } from "../../../../../../../apis/survey";

interface Props extends ModalProps {
  question?: QuestionInfo;
}

const QuestionEditFormModal: React.FC<Props> = (props: Props) => {
  const { visible, onDataChange, onCancel, question } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  let formik: FormikProps<FormikValues>;

  const onModalOpen = () => {
    let initialValues = createInitialValues();
    if (question) {
      initialValues = createInitialValuesFromQuestion(question) as any;
    }
    setInitialValues(initialValues);
  };

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (question) {
      setConfirmLoading(true);
      const { srveQitmCont, headClsCd, replyAvailCnt } = values;
      try {
        await editSurveyQuestion(
          question.srveQstnId,
          question.point,
          srveQitmCont,
          headClsCd,
          replyAvailCnt || 0
        );
        alertSuccess("설문 템플릿 정보가 변경되었습니다.");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      title="설문 템플릿 수정"
      size="small"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
        forms={createFormInfo()}
        validationSchema={createValidationSchema()}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

QuestionEditFormModal.defaultProps = {};
export default QuestionEditFormModal;
