import React, { useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { renderLoading, alertError } from "../../../../../utils/render-utils";
import { ModalProps } from "../../../../types";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { fetchSurveyMasterTemplateDetail } from "../../../../../apis/survey";
import SurveyMasterDetail, {
  QuestionInfo,
  ReplyInfo,
} from "../../../../../models/Survey/SurveyMasterDetail";
import TableLayout from "../../../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createActionCellRenderers } from "../../../../../utils/table-utils";
import { createQuestionCols, createReplyCols } from "./utils/table-utils";
import QuestionEditFormModal from "./pages/QuestionEditFormModal";
import ReplyFormModal from "./pages/ReplyFormModal";
import SurveyMaster from "../../../../../models/Survey/SurveyMaster";

interface Props extends ModalProps {
  survey?: SurveyMaster;
}

const SurveyMasterDetailModal: React.FC<Props> = (props: Props) => {
  const questionCols = createQuestionCols();
  const replyCols = createReplyCols();

  const { visible, onCancel, onDataChange, survey } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [surveyDetail, setSurveyDetail] = useState<SurveyMasterDetail>();
  const [selectedReply, setSelectedReply] = useState<ReplyInfo>();
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionInfo>();
  const [questionModalVisible, setQuestionModalVisible] = useState(false);
  const [replyModalVisible, setReplyModalVisible] = useState(false);

  const onModalOpen = async () => {
    if (survey) {
      await requestFetchSurveyMasterDetail(survey);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchSurveyMasterDetail = async (
    survey: SurveyMaster,
    update = false
  ) => {
    if (!update) setDataFetching(true);
    try {
      if (survey) {
        const res = await fetchSurveyMasterTemplateDetail(
          survey.srveQstnId,
          survey.srveClfCd
        );
        const { items } = res;
        if (items.length > 0) {
          setSurveyDetail(items[0]);
        }
        if (update && onDataChange) onDataChange();
      }
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  /**
   * Event Actions
   */

  const handleQuestionDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const question = data as QuestionInfo;
    setSelectedQuestion(question);
    setQuestionModalVisible(true);
  };

  const handleReplyDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const reply = data as ReplyInfo;
    setSelectedReply(reply);
    setReplyModalVisible(true);
  };

  /**
   * Render Helpers
   */

  const renderQuestionList = () => {
    if (surveyDetail) {
      const { questionList } = surveyDetail;
      return (
        <div
          style={{
            height: "465px",
          }}
        >
          <TableLayout
            hideExcelAction
            hideFilterAction
            title="설문 템플릿 리스트"
            totalCount={questionList.length}
            methodName="/admin/survey"
          >
            <ClientRowTable
              cols={questionCols}
              rowData={questionList}
              frameworkComponents={{
                // [USE_YN_RENDERER]: renderSwitchCol("useYn", handleUseYnChange),
                ...createActionCellRenderers(
                  undefined,
                  handleQuestionDetailClick,
                  {
                    hideDeleteButton: true,
                  }
                ),
              }}
            />
          </TableLayout>
        </div>
      );
    }
    return "";
  };

  const renderReplyList = () => {
    if (surveyDetail) {
      const { replyList } = surveyDetail;
      return (
        <div
          style={{
            height: "500px",
          }}
        >
          <TableLayout
            hideExcelAction
            hideFilterAction
            title="설문 답변 템플릿 리스트"
            totalCount={replyList.length}
            rightActions={[
              {
                title: "답변 템플릿 등록",
                onClick: () => {
                  setSelectedReply(undefined);
                  setReplyModalVisible(true);
                },
              },
            ]}
            methodName="/admin/survey"
          >
            <ClientRowTable
              autoHeight
              cols={replyCols}
              rowData={replyList}
              frameworkComponents={{
                // [USE_YN_RENDERER]: renderSwitchCol("useYn", handleUseYnChange),
                ...createActionCellRenderers(
                  undefined,
                  handleReplyDetailClick,
                  {
                    hideDeleteButton: true,
                  }
                ),
              }}
            />
          </TableLayout>
        </div>
      );
    }
    return "";
  };

  return (
    <Modal
      size="large"
      footer={null}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="만족도조사 템플릿 수정"
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <div>
          {renderQuestionList()}
          {renderReplyList()}
        </div>
      )}
      <QuestionEditFormModal
        visible={questionModalVisible}
        onCancel={() => {
          setQuestionModalVisible(false);
        }}
        onDataChange={async () => {
          if (survey) await requestFetchSurveyMasterDetail(survey, true);
        }}
        question={selectedQuestion}
      />
      <ReplyFormModal
        visible={replyModalVisible}
        onCancel={() => {
          setReplyModalVisible(false);
        }}
        onDataChange={async () => {
          if (survey) await requestFetchSurveyMasterDetail(survey, true);
        }}
        reply={selectedReply}
        srveQstnId={survey?.srveQstnId}
      />
    </Modal>
  );
};

SurveyMasterDetailModal.defaultProps = {};
export default SurveyMasterDetailModal;
