import { plainToClass } from "class-transformer";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { UcRntFeeCarNo, UcRntFeeCarNoParam, UcRntFeeCond, UcRntFeeCondParam } from "./types";

const apiRootUrl = `${ROOT_URL}/admin`;

/**
 * 중고장기 상품 견적 조건 조회(GET: ~​/admin​/uc-rnt-fee-cond)
 */
export const fetchUcRntFeeConds = async (
  params: UcRntFeeCondParam
): Promise<UcRntFeeCond> => {
  const url = `${apiRootUrl}/uc-rnt-fee-cond`;
  const res = await request(HttpMethod.Get, url, params as Record<string, any>);
  const { data } = res;
  const { response } = data;

  return plainToClass(UcRntFeeCond, response);
};

/**
 * 중고장기 상품 견적 조건 조회(GET: ~​/admin​/uc-rnt-fee-cond)
 */
export const fetchUcRntFeeCarNos = async (
  params: UcRntFeeCarNoParam
): Promise<UcRntFeeCarNo> => {
  const url = `${apiRootUrl}/uc-rnt-fee-car-no`;
  // const deposit = Math.ceil(((75025449 * 10) / 100) / 1000) * 1000 // Math.ceil(((carAmt * rateToNum) / 100) / 1000) * 1000
  const res = await request(HttpMethod.Get, url, params as Record<string, any>); // TODO: 전달인자
  const { data } = res;
  const { response } = data;

  return plainToClass(UcRntFeeCarNo, response);
};