import { plainToClass } from "class-transformer";
import Event from "../../models/Event";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { EventAddParam, EventEditParam } from "./types";
import EventDetail from "../../models/EventDetail";
import EventJoinHistory from "../../models/EventJoinHistory";

const apiRootUrl = `${ROOT_URL}/admin/events`;

/**
 * 이벤트 응모내역 조회
 */

export const fetchEventJoinHistories = async (evntSeq: string) => {
  const url = `${ROOT_URL}/admin/events/cust/${evntSeq}`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(EventJoinHistory, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 이벤트 리스트 조회
 */
export const fetchEvents = async (): Promise<ListItemResponse<Event>> => {
  const res = await request(HttpMethod.Get, apiRootUrl);

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(
      Event,
      list.sort((a: any, b: any) => {
        if (a.topFixedYn === "Y" && (b.topFixedYn === "N" || !b.topFixedYn))
          return -1;
        return 0;
      }) as Array<JSON>
    ),
    count: Number(listCnt),
  };
};

/**
 * 이벤트 상세 조회
 */

export const fetchEventDetail = async (
  eventSeq: string
): Promise<EventDetail> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/${eventSeq}`);
  const { data } = res;
  const { response } = data;
  return plainToClass(EventDetail, response);
};

/**
 * 이벤트 등록
 */
export const addEvent = async (params: EventAddParam) => {
  await request(HttpMethod.Post, apiRootUrl, undefined, params);
};

/**
 * 이벤트 수정
 */
export const editEvent = async (eventSeq: string, params: EventEditParam) => {
  await request(HttpMethod.Put, `${apiRootUrl}/${eventSeq}`, undefined, params);
};

/**
 * 이벤트 삭제
 */
export const deleteEvent = async (eventSeq: string) => {
  await request(HttpMethod.Get, `${apiRootUrl}/${eventSeq}`);
};
