import strings from "../constants/strings";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import { formatDateString } from "../../../../utils/date-utils";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";
import commonStrings from "../../../../constants/strings";

export const USAGE_STATE_SWITCH_RENDERER = "usageStateSwitchRenderer";

export const createFAQCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", strings.COL_REG_DATE, FilterType.Date, {
      width: CW_DATE_RANGE,
      valueGetter: (params) => {
        const { data } = params;
        const { regDtm } = data;
        if (regDtm) return formatDateString(regDtm);
        return "";
      },
    }),
    // 대분류
    createTableCol("llfNm", strings.COL_CATEGORY1, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 중분류
    createTableCol("mlfNm", strings.COL_CATEGORY2, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 메인 노출 여부
    createTableCol("mainYn", commonStrings.COL_MAIN_DISPLAY, undefined, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "노출" : "미노출",
          },
        };
      },
    }),
    // 제목
    createTableCol("qstn", strings.COL_TITLE, FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 사융유무
    createTableCol("useYn", commonStrings.USE_YN, FilterType.Text, {
      width: CW_SMALL,
      cellRenderer: USAGE_STATE_SWITCH_RENDERER,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
