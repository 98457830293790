import {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_LONG_TEXT,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../../utils/table-utils";
import strings from "../../../../../car-sales/NewCarCatalogManager/constants/strings";
import commonStrings from "../../../../../../constants/strings";
import {getUcProdType} from "../../../../../../constants/enums";

export const createExhibitionCatalogCols = () => {
  return [
    // 노출순번
    createTableCol("exhViewSeq", "게시순번", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 차량번호
    createTableCol(
        "carNo",
        commonStrings.COL_CAR_NO,
        FilterType.Text,
        {
          width: CW_MEDIUM,
        }
    ),
    // 상품유형
    createTableCol(
      "ncarClsFlag",
      commonStrings.COL_PROD_TYPE,
      FilterType.Text,
      {
        width: CW_MEDIUM,
        valueGetter: ({ data }) => {
          const { ncarClsFlag } = data;

          return getUcProdType(ncarClsFlag) || "-";
        }
      }
    ),
    // 제조사
    createTableCol("repMakerNm", strings.COL_BRAND, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 모델
    createTableCol("repCarTypeNm", strings.COL_MODEL, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 상품명
    createTableCol("prodNm", strings.COL_PROD_NM, FilterType.Text, {
      width: CW_MEDIUM,
      flex: 1
    }),
  ];
};
