/**
 * Public Routes
 */

export const LOGIN = "/login";

/**
 * Private Routes
 */

/**
 * 회원
 */

// 회원관리
export const CUSTOMER_MANAGER = "/customers";

// 회원이관
export const CAR_MANAGER_TRANSFER_MANAGER = "/car-manager-transfers";

/**
 * 견적/계약
 */

// 견적관리
export const ESTIMATION_MANAGER = "/estimations";

// 계약관리(렌탈형)
export const RENT_CONTRACT_MANAGER = "/rent-contracts";

// 계약관리(인수형)
// TODO 2차
export const SALE_CONTRACT_MANAGER = "/sale-contracts";

// 계약변경요청 관리
export const CONTRACT_UPDATE_REQUEST_MANAGER = "/contract-update-requests";

// 할인 마스터 관리
export const DISCOUNT_MASTER_MANAGER = "/discount-info-master-manager";

// 인수요청관리(타고바이)
export const TAGO_TKV_REQ_CONTRACT_MANAGER = "/tago-tkv-req-contract";

// 계약관리(제휴중고차)
export const AUC_CONTRACT_MANAGER = "/auc-contracts";

/**
 * 차량판매
 */

// 차량 정보 관리
export const COSS_CAR_INFO_MANAGER = "/coss-car-infos";

// 온라인 상품관리 (신차장기)
export const NEW_CAR_RENT_CATALOGS_MANAGER = "/nc-catalogs";

// 온라인 상품관리 (중고장기)
export const USED_CAR_RENT_CATALOGS_MANAGER = "/uc-catalogs";

// 온라인 상품관리 (타고바이)
export const TAGO_CAR_RENT_CATALOGS_MANAGER = "/tb-catalogs";

// 온라인 상품관리 (월렌트)
export const MONTHLY_CAR_RENT_CATALOGS_MANAGER = "/mc-catalogs";

// 온라인 상품관리 (제휴중고)
export const AUC_CAR_RENT_CATALOGS_MANAGER = "/auc-catalogs";

// 온라인 상품관리 (제휴연동)
export const COOPER_CAR_RENT_CATALOGS_MANAGER = "/cooper-catalogs";

// 상품태그관리 (신차장기)
export const CATALOG_TAG_MANAGER = "/catalog-tags";

// 상품태그관리 (중고장기)
export const USED_CAR_CATALOG_TAG_MANAGER = "/uc-catalog-tags";

// 보증보험 수수료율 관리
export const INSURANCE_FEE_RATE_MANAGER = "/grade-rates";

// 신차발주 납기관리
export const NEW_CAR_DELIVERY_MANAGER = "/nc-expected-deliveries";

// 메인상품 가격기준 관리
export const MAIN_PRODUCT_PRICING_MANAGER = "/main-product-pricing";

// 미래가치계수 관리
export const YEAR_CEFT_MANAGER = "/year-ceft-manager";

/**
 * 마케팅
 */

// 기획전 관리(신차장기)
export const EXHIBITION_MANAGER = "/exhibitions";

// 기획전 관리(중고장기)
export const USED_CAR_EXHIBITION_MANAGER = "/uc-exhibitions";

// 기획전 관리(타고바이)
export const TAGO_CAR_EXHIBITION_MANAGER = "/tb-exhibitions";

// 제휴사 관리(제휴중고차)
export const AUC_MANAGER = "/Auc-manager";

// 이벤트 관리
export const EVENT_MANAGER = "/events";

// 배너 관리
export const BANNER_MANAGER = "/banners";

// 후기 관리
export const REVIEW_MANAGER = "/reviews";

// 지능형 상담
export const CONSULTING_DATA_MANAGER = "/consulting-data-manager";

// 상담신청 관리
export const CUSTOMER_CONSULTING_REQUEST = "/customer-consulting-requests";

// 사전예약 신청내역
export const CAR_BOOKING_HISTORIES = "/car-booking-histories";

// 특가정보 발송 이력
export const SPECIAL_PROMOTION_MSG_HISTORY_MANAGER =
  "/friends-promotion-msg-history";

// 친구추천 프로그램 가입자 관리
export const MGM_REPORT_MANAGER = "/mgm-report-manager";

// 특가정보 리워드 관리
export const SPECIAL_PROMOTION_REWARD_MANAGER = "/friends-promotion-rewards";

/**
 * 고객지원
 */

// 공지사항
export const NOTICE_MANAGER = "/notices";

// FAQ
export const FAQ_MANAGER = "/faqs";

// Q&A
export const QA_MANAGER = "/qna";

/**
 * 카매니저
 */

// 인도스케줄 관리
export const DELIVERY_SCHEDULE_MANAGER = "/car-manager-delivery-schedules";

// 대무 설정
export const CAR_MANAGER_REPLACEMENT_MANAGER = "/car-manager-replaces";

// 템플릿 문자 전송
// TODO 2차
export const CAR_MANAGER_SMS_SENDER = "/car-manager-sns-sender";

/**
 * 서비스운영 관리
 */

// 권한 관리
export const AUTHORITY_MANAGER = "/authorities";

// 서비스 만족도 조사 결과
export const CUSTOMER_SATISFACTION_REPORT_MANAGER = "/csat-reports";

// 고객 설문 조사 결과
export const SURVEY_REPORT_MANAGER = "/survey-reports";

// 견적정보
export const ESTIMATION_REPORT_MANAGER = "/estimation-reports";

// 재고정보
export const STOCK_REPORT_MANAGER = "/stock-reports";

// 카매니저 관리
export const CAR_MANAGERS = "/car-managers";

// 문자전송 템플릿 관리
// TODO 2차
export const SMS_TEMPLATE_MANAGER = "/sms-templates";

// 만족도 조사 관리
export const SATISFACTION_SURVEY_MANAGER = "/satisfaction-survey-templates";

/**
 * 보고서
 */
// SKU Report
export const SKU_REPORT_MANAGER = "/sku-report";
// TODO 2차
