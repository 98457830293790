import React from "react";
import { Helmet } from "react-helmet";
import SubHeader from "../SubHeader";
import { ContentLayoutWrapper } from "./style";
import TableFilterLayout from "../TableFilterLayout";
import { FilterFormInfo } from "../TableFilterLayout/types";

interface ContentLayoutProps {
  headerTitle?: string;
  description?: string;
  children?: React.ReactNode | React.FC;
  disableBreadcrumbs?: boolean;
  filterable?: boolean;
  filterForms?: Array<FilterFormInfo>;
  filterInitialValues?: any;
  filterValues?: Record<string, any>;
  onSearchSubmit?: (values: any) => void;
}

const ContentLayout: React.FC<ContentLayoutProps> = (
  props: ContentLayoutProps
) => {
  const {
    headerTitle,
    description,
    children,
    disableBreadcrumbs,
    filterable,
    filterForms,
    filterInitialValues,
    filterValues,
    onSearchSubmit,
  } = props;

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */
  return (
    <>
      <SubHeader
        title={headerTitle}
        description={description}
        disableBreadcrumbs={disableBreadcrumbs}
      />
      {headerTitle && (
        <Helmet>
          <title>{`ezyManager - ${headerTitle}`}</title>
        </Helmet>
      )}
      {filterable && (
        <TableFilterLayout
          forms={filterForms}
          formInitialValues={filterInitialValues}
          filterValues={filterValues}
          onSearchSubmit={onSearchSubmit}
        />
      )}

      <ContentLayoutWrapper>{children}</ContentLayoutWrapper>
    </>
  );
};

ContentLayout.defaultProps = {
  filterable: false,
};
export default ContentLayout;
