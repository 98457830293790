import styled from "styled-components";

export const BaseContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  background: yellow;
  // safari bug
  width: 0;
`;
