import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import { FormElementType } from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import errorMessages from "../../../../../../../constants/errors";
import { CarVideoInfo } from "../../../../../../../models/NewCarCatalogDetail";
import { alertSuccess } from "../../../../../../../utils/render-utils";
import {
  createUcVideo,
  updateUcVideo,
} from "../../../../../../../apis/uc-catalogs";

interface Props extends ModalProps {
  prodId: string;
  videoInfo?: CarVideoInfo;
}

const VideoRegistrationModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, prodId, videoInfo } = props;

  const [initialValues, setInitialValues] = useState<any>(videoInfo || {});

  let formik: FormikProps<FormikValues>;

  const forms = [
    {
      key: "useYn",
      type: FormElementType.Radio,
      label: "사용여부",
      required: true,
      options: [
        { label: "사용", value: "Y" },
        { label: "미사용", value: "N" },
      ],
    },
    {
      key: "videoRate",
      type: FormElementType.Select,
      label: "영상 비율",
      required: true,
      options: [
        { label: "가로형", value: "W" },
        { label: "세로형", value: "V" },
      ],
      placeholder: "영상비율을 선택해주세요.",
    },
    {
      key: "videoBtn",
      type: FormElementType.Input,
      label: "버튼명",
      required: true,
      placeholder: "버튼명을 압력해주세요.",
    },
    {
      key: "videoUrl",
      type: FormElementType.Input,
      label: "동영상 URL",
      required: true,
      placeholder: "동영상 URL을 입력해 주세요.",
    },
  ];

  const validationSchema = Yup.object().shape({
    videoRate: Yup.string().required(errorMessages.REQUIRED_FIELD),
    videoBtn: Yup.string().required(errorMessages.REQUIRED_FIELD),
    videoUrl: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });

  const onModalOpen = () => {
    if (videoInfo) {
      setInitialValues(videoInfo);
    } else {
      setInitialValues({});
    }
  };

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    try {
      if (videoInfo) {
        /* 수정 */
        await updateUcVideo(videoInfo.videoSeq, {
          prodId,
          videoRate: values.videoRate,
          videoBtn: values.videoBtn,
          videoUrl: values.videoUrl,
          useYn: values.useYn,
        });

        alertSuccess("비디오 수정이 완료되었습니다.");
      } else {
        /* 생성 */
        await createUcVideo({
          prodId,
          videoRate: values.videoRate,
          videoBtn: values.videoBtn,
          videoUrl: values.videoUrl,
          useYn: values.useYn,
        });

        alertSuccess("비디오 생성이 완료되었습니다.");
      }

      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      title="동영상 관리 설정"
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        formik?.handleSubmit();
      }}
      size="small"
      onOpen={onModalOpen}
      description="버튼명은 띄어쓰기 포함 10글자 이하로 입력하셔야 합니다."
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
        forms={forms}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

VideoRegistrationModal.defaultProps = {};
export default VideoRegistrationModal;
