import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { ReportTableWrapper, ReportTableContentWrapper } from "./styles";
import CarManagerSurveyReport from "../../../models/CarManagerSurveyReport";
import {
  fetchCarManagerSurveyReport,
  fetchCarManagerSurveyReportDetailForExcel,
  fetchServiceSurveyReportDetail,
} from "../../../apis/survey";
import { alertError } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { getWeekDateRange } from "../../../utils/date-utils";
import {
  createCarManagerSurveyReportDetailsExcelCols,
  createCarManagerSurveyReportCols,
} from "./utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createActionCellRenderers } from "../../../utils/table-utils";
import DateRangePicker from "../../../components/shared/data-entry/antd/DateRangePicker";
import FormItem from "../../../components/shared/data-entry/antd/FormItem";
import CarManagerSurveyReportDetailModal from "./pages/CarManagerSurveyReportDetailModal";
import ServiceSurveyReportDetail from "../../../models/ServiceSurveyReportDetail";
import TableLayout from "../../../components/shared/layout/TableLayout";
import CarManagerSurveyReportDetailForExcel from "../../../models/CarManagerSurveyReportDetailForExcel";

const CustomerSatisfactionReportManager: React.FC = () => {
  /**
   * 카매니저 만족도 조사 결과 State
   */
  const [carManagerGridApi, setCarManagerGridApi] = useState<GridApi>();
  const [carManagerExcelGridApi, setCarManagerExcelGridApi] = useState<
    GridApi
  >();
  const [
    carManagerSurveyReportDetails,
    setCarManagerSurveyReportDetails,
  ] = useState<CarManagerSurveyReportDetailForExcel[]>();
  const carManagerSurveyReportCols = createCarManagerSurveyReportCols();

  const carManagerSurveyReportExcelCols = createCarManagerSurveyReportDetailsExcelCols();
  const [carManagerSurveyReports, setCarManagerSurveyReports] = useState<
    CarManagerSurveyReport[]
  >();
  const initialCarManagerSurveyFilterValues = {
    stDate: getWeekDateRange(),
  };
  const [FilterValues, setFilterValues] = useState<any>({
    ...initialCarManagerSurveyFilterValues,
  });
  const [
    carManagerReportDetailModalVisible,
    setCarManagerReportDetailModalVisible,
  ] = useState(false);
  const [selectedCarManagerId, setSelectedCarManagerId] = useState<string>();
  const [selectedCarManagerName, setSelectedCarManagerName] = useState<
    string
  >();

  /**
   * 서비스 만족도 결과 State
   */
  const [serviceSurveyDetail, setServiceSurveyDetail] = useState<
    ServiceSurveyReportDetail
  >();

  useEffect(() => {
    (async function fetchData() {
      await requestFetchCarManagerSurveyReports(FilterValues);
      await requestFetchServiceSurveyReportDetail(FilterValues);
      await requestFetchCarManagerExcelReports(FilterValues);
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestFetchCarManagerSurveyReports = async (filterValues: any) => {
    try {
      const { stDate } = filterValues;
      let stStartParam;
      let stEndParam;
      if (stDate && stDate.length > 1) {
        const [start, end] = stDate;
        stStartParam = moment(start).format("YYYYMMDD");
        stEndParam = moment(end).format("YYYYMMDD");
      }

      const { items } = await fetchCarManagerSurveyReport(
        stStartParam,
        stEndParam
      );
      setCarManagerSurveyReports(items);
    } catch (e) {
      setCarManagerSurveyReports([]);
      if (e.status !== 404) alertError(getErrorMessage(e));
    }
  };

  const requestFetchCarManagerExcelReports = async (filterValues: any) => {
    try {
      let startDtParam;
      let endDtParam;
      if (
        filterValues &&
        filterValues.stDate &&
        filterValues.stDate.length > 1
      ) {
        const [start, end] = filterValues.stDate;
        startDtParam = moment(start).format("YYYYMMDD");
        endDtParam = moment(end).format("YYYYMMDD");
      }

      const { items } = await fetchCarManagerSurveyReportDetailForExcel(
        startDtParam,
        endDtParam
      );
      setCarManagerSurveyReportDetails(items);
    } catch (e) {
      alertError(getErrorMessage(e));
      setCarManagerSurveyReportDetails([]);
    }
  };

  const requestFetchServiceSurveyReportDetail = async (filterValues: any) => {
    try {
      const { stDate } = filterValues;
      let stStartParam;
      let stEndParam;
      if (stDate && stDate.length > 1) {
        const [start, end] = stDate;
        stStartParam = moment(start).format("YYYYMMDD");
        stEndParam = moment(end).format("YYYYMMDD");
      }
      const detail = await fetchServiceSurveyReportDetail(
        stStartParam,
        stEndParam
      );
      setServiceSurveyDetail(detail);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const handleCarManagerSurveyReportDetail = (props: ICellRendererParams) => {
    const { data } = props;
    const report = data as CarManagerSurveyReport;
    if (report.carmanNm === "합계") {
      setCarManagerReportDetailModalVisible(true);
    } else {
      setSelectedCarManagerId(report.carmanId);
      setSelectedCarManagerName(report.carmanNm);
      setCarManagerReportDetailModalVisible(true);
    }
  };

  /**
   * Render Helpers
   */
  const renderCountInfo = (
    cntrCnt: string | number,
    replyCnt: string | number
  ) => {
    return (
      <div
        style={{
          marginTop: "16px",
          display: "flex",
        }}
      >
        <div
          style={{
            marginLeft: "16px",
          }}
        >
          총 계약
          <span
            style={{
              marginLeft: "6px",
              fontWeight: "bold",
            }}
          >
            {cntrCnt}
          </span>
          건
        </div>
        <div
          style={{
            marginLeft: "16px",
          }}
        >
          설문 응답 수
          <span
            style={{
              marginLeft: "6px",
              fontWeight: "bold",
            }}
          >
            {replyCnt}
          </span>
          건
        </div>
      </div>
    );
  };

  const getPinnedBottomRowData = () => {
    if (carManagerSurveyReports) {
      return [
        {
          carmanNm: "합계",
          avgPoint03:
            _.sum(
              carManagerSurveyReports.map((item) => {
                return Number(item.avgPoint03);
              })
            ) / carManagerSurveyReports.length,
          avgPoint04:
            _.sum(
              carManagerSurveyReports.map((item) => {
                return Number(item.avgPoint04);
              })
            ) / carManagerSurveyReports.length,
          avgPoint05:
            _.sum(
              carManagerSurveyReports.map((item) => {
                return Number(item.avgPoint05);
              })
            ) / carManagerSurveyReports.length,
          ldCntrCnt: _.sum(
            carManagerSurveyReports.map((item) => {
              return Number(item.ldCntrCnt);
            })
          ),
          replyCntrCnt: _.sum(
            carManagerSurveyReports.map((item) => {
              return Number(item.replyCntrCnt);
            })
          ),
        },
      ];
    }
    return null;
  };

  return (
    <ContentLayout headerTitle="서비스 만족도 조사결과">
      <ReportTableWrapper>
        <ReportTableContentWrapper>
          <TableLayout
            hideFooter
            gridApi={carManagerExcelGridApi}
            title="카매니저 만족도결과"
            methodName="/admin/surv-aggr-carman-excel"
          >
            <FormItem
              required
              label="조회기간"
              style={{
                marginBottom: "16px",
              }}
            >
              <DateRangePicker
                value={FilterValues.stDate}
                onChange={async (values) => {
                  const updatedFilterValues = {
                    ...FilterValues,
                    stDate: values,
                  };
                  setFilterValues(updatedFilterValues);
                  carManagerGridApi?.showLoadingOverlay();
                  await requestFetchCarManagerSurveyReports(
                    updatedFilterValues
                  );
                  await requestFetchCarManagerExcelReports(updatedFilterValues);
                  await requestFetchServiceSurveyReportDetail(
                    updatedFilterValues
                  );
                  carManagerGridApi?.hideOverlay();
                }}
                allowClear={false}
              />
            </FormItem>
            <div
              style={{
                display: "none",
              }}
            >
              <ClientRowTable
                floatingFilter={false}
                height={0}
                cols={carManagerSurveyReportExcelCols}
                rowData={carManagerSurveyReportDetails}
                apiRef={(api) => {
                  setCarManagerExcelGridApi(api);
                }}
              />
            </div>
            {carManagerSurveyReports && (
              <ClientRowTable
                apiRef={(ref) => {
                  setCarManagerGridApi(ref);
                }}
                height={773}
                cols={carManagerSurveyReportCols}
                rowData={carManagerSurveyReports}
                frameworkComponents={{
                  ...createActionCellRenderers(
                    undefined,
                    handleCarManagerSurveyReportDetail,
                    {
                      hideDeleteButton: true,
                    }
                  ),
                }}
                pinnedBottomRowData={getPinnedBottomRowData()}
              />
            )}
          </TableLayout>
          {renderCountInfo(
            serviceSurveyDetail?.cntrCnt || 0,
            serviceSurveyDetail?.replyCnt || 0
          )}
        </ReportTableContentWrapper>
      </ReportTableWrapper>
      <CarManagerSurveyReportDetailModal
        filterValues={FilterValues}
        carmanId={selectedCarManagerId}
        carmanNm={selectedCarManagerName}
        visible={carManagerReportDetailModalVisible}
        onCancel={() => {
          setCarManagerReportDetailModalVisible(false);
          setSelectedCarManagerId(undefined);
          setSelectedCarManagerName(undefined);
        }}
      />
    </ContentLayout>
  );
};

CustomerSatisfactionReportManager.defaultProps = {};
export default CustomerSatisfactionReportManager;
