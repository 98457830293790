import { groupBy } from "lodash";
import DirectSurveyResultDetail from "../../../../../../models/DirectSurvey/DirectSurveyResultDetail";
import { DIRECT_SURVEY_QUESTION_TYPE_SUBJECTIVE } from "../../../../../../constants/enums";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

export const convertChartData = (
  rawList: DirectSurveyResultDetail[]
): ReportResultVM[] => {
  const groupedDetails = groupBy(rawList, "qstnCntn");
  const reportResults: ReportResultVM[] = [];

  Object.keys(groupedDetails).forEach((questionTitle) => {
    const details = groupedDetails[questionTitle];
    const [first] = details;

    // 객관식 질문의 텍스트가 없는 선택항목 데이터 변환 (차트)
    const groupedReplyForChart = groupBy(details, "replyItemSeq");
    const chartInfos: ChartInfo[] = [];
    let totatlCount = 0;

    Object.keys(groupedReplyForChart).forEach((key) => {
      totatlCount += groupedReplyForChart[key].length;
    });

    Object.keys(groupedReplyForChart).forEach((key) => {
      const replyList = groupedReplyForChart[key];
      const [first] = replyList;
      const { txtInsYn } = first;

      const label = first.itemNm || "미응답";

      chartInfos.push({
        label: `${label}(${replyList.length}명) - ${(
          (replyList.length / totatlCount) *
          100
        ).toFixed(1)}%`,
        data: replyList.length,
        isEtc: txtInsYn === "Y",
        replyList,
      });
    });

    const { qstnType } = first;

    const isChart = qstnType !== DIRECT_SURVEY_QUESTION_TYPE_SUBJECTIVE;
    reportResults.push({
      questionTitle: `${questionTitle}(${details.length}명)`,
      type: isChart ? "chart" : "table",
      chartInfos: chartInfos.sort((a, b) => {
        if (a.data >= b.data) return -1;

        return 1;
      }),
      // subjectList: replyListWithText,
      rawList: details,
    });
  });

  return reportResults;
};

export interface ReportResultVM {
  type: "chart" | "table";
  questionTitle: string;
  chartInfos: ChartInfo[];
  // 전체 리스트
  rawList: DirectSurveyResultDetail[];
}

export interface ChartInfo {
  label: string;
  data: number;
  isEtc: boolean;
  replyList: DirectSurveyResultDetail[];
}

export const createDirectSurveyResultCols = (): Array<TableColumn> => {
  return [
    // 고객명
    createTableCol("custNm", "고객명", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 설문 답변
    createTableCol("replyCntn", "설문답변", undefined, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        if (data.qstnType === DIRECT_SURVEY_QUESTION_TYPE_SUBJECTIVE)
          return data.replyCntn;

        if (data.txtInsYn === "N") return data.itemNm;

        return data.replyCntn;
      },
    }),
    // // 계약번호
    // createTableCol("cntrId", "계약번호", FilterType.Text, {
    //   width: CW_MEDIUM,
    // }),
    // 계약일
    createTableCol("cntrDt", "계약일", FilterType.Text, {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("cntrDt"),
    }),
    // 카매니저
    createTableCol("mgntPicNm", "카매니저", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 모델
    createTableCol("repCarClassNm", "모델", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 세부모델
    createTableCol("carClassNm", "세부모델", FilterType.Text, {
      width: CW_MEDIUM,
    }),
  ];
};

export const createExcelExportCols = (): Array<TableColumn> => {
  return [
    // 고객명
    createTableCol("custNm", "고객명", undefined, {
      width: CW_SMALL,
      hide: true,
    }),
    // 고객명
    createTableCol("custClsNm", "고객유형", undefined, {
      width: CW_SMALL,
      hide: true,
    }),
    // 질문
    createTableCol("qstnCntn", "질문", undefined, {
      minWidth: CW_LARGE,
      flex: 1,
      hide: true,
    }),
    // 설문 답변
    createTableCol("replyCntn", "설문답변", undefined, {
      minWidth: CW_LARGE,
      flex: 1,
      hide: true,
      valueGetter: ({ data }) => {
        if (data.qstnType === DIRECT_SURVEY_QUESTION_TYPE_SUBJECTIVE)
          return data.replyCntn;

        if (data.txtInsYn === "N") return data.itemNm;

        return data.replyCntn;
      },
    }),
    // // 계약번호
    createTableCol("cntrId", "계약번호", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
    // 계약일
    createTableCol("cntrDt", "계약일", FilterType.Text, {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("cntrDt"),
      hide: true,
    }),
    // 카매니저
    createTableCol("mgntPicNm", "카매니저", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
    // 모델
    createTableCol("repCarClassNm", "모델", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
    // 세부모델
    createTableCol("carClassNm", "세부모델", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
    // 성별
    createTableCol("gndr", "성별", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
    // 생년월일
    createTableCol("brthDt", "생년월일", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
    // 지역
    createTableCol("useAreaNm", "지역", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
    // 판매유형
    createTableCol("ncarClsFlagNm", "판매유형", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
    // 연료타입
    createTableCol("fuel", "연료타입", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
    // 차형
    createTableCol("extShape", "차형", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
    // 제출일자
    createTableCol("regDtm", "제출일자", FilterType.Text, {
      width: CW_MEDIUM,
      hide: true,
    }),
  ];
};
