import { ROOT_URL } from "../../network/api-urls";
import { HttpMethod, request } from "../../network/request";
import { plainToClass } from "class-transformer";
import SMSTemplate from "../../models/SMSTemplate";
import SMSSendHistory from "../../models/SMSSendHistory";
import SMSSendHistoryDetail from "../../models/SMSSendHistoryDetail";
import Customer from "../../models/Customer";

const apiRootUrl = `${ROOT_URL}/admin/sms-templates`;

/**
 * SMS 발송 카매니저 담당 고객 리스트 조회
 */
export const fetchCarManagerCustomers = async () => {
  const url = `${ROOT_URL}/admin/sms-user-info`;
  const res = await request(HttpMethod.Get, url);
  const { data } = res;
  const { response } = data;
  const { totalCnt, list } = response;
  return {
    items: plainToClass(Customer, list as Array<JSON>),
    count: Number(totalCnt),
  };
};

export interface SMSSendParam {
  smsMsgId: string;
  smsTitle: string;
  custId: string;
  regId: string;
  chkYn: string;
}

/**
 * 카매니저 문자 전송
 * @param params
 */
export const sendSMS = async (params: SMSSendParam[]) => {
  const url = `${ROOT_URL}/admin/carman/sms-send`;
  await request(HttpMethod.Post, url, undefined, params);
};

/**
 * 카매니저 문자 전송 이력 리스트 조회
 */
export const fetchSMSSendHistories = async (
  pageIndex: number,
  pageSize: number,
  startSendDt: string,
  endSendDt: string,
  templateTitle?: string
) => {
  const url = `${ROOT_URL}/admin/carman/sms-send-histories`;
  const res = await request(HttpMethod.Get, url, {
    pageIndex,
    pageSize,
    startSendDt,
    endSendDt,
    templateTitle,
  });
  const { data } = res;
  const { response } = data;
  const { totalCnt, list } = response;
  return {
    items: plainToClass(SMSSendHistory, list as Array<JSON>),
    count: Number(totalCnt),
  };
};

/**
 * 카매니저 문자 전송 이력 상세 조회
 */
export const fetchSMSSendHistoryDetail = async (
  historyId: string,
  smsMsgId: string,
  carmanId?: string
): Promise<SMSSendHistoryDetail> => {
  const url = `${ROOT_URL}/admin/carman/sms-send-histories/${historyId}`;
  const res = await request(HttpMethod.Get, url, {
    smsMsgId,
    carmanId,
  });
  const { data } = res;
  const { response } = data;
  return plainToClass(SMSSendHistoryDetail, response);
};

/**
 * SMS 템플릿 상세 조회
 * @param templateId
 */
export const fetchSMSTemplateDetail = async (templateId: string) => {
  const url = `${apiRootUrl}/${templateId}`;
  const res = await request(HttpMethod.Get, url);
  const { data } = res;
  const { response } = data;
  return plainToClass(SMSTemplate, response);
};

/**
 * SMS 템플릿 목록 조회
 * @param pageIndex
 * @param pageSize
 * @param searchText
 */
export const fetchSMSTemplates = async (
  pageIndex: number,
  pageSize: number,
  searchText?: string
) => {
  const res = await request(HttpMethod.Get, apiRootUrl, {
    pageIndex,
    pageSize,
    searchText,
  });
  const { data } = res;
  const { response } = data;
  const { totalCnt, list } = response;

  return {
    items: plainToClass(SMSTemplate, list as Array<JSON>),
    count: Number(totalCnt),
  };
};

/**
 * SMS 템플릿 등록
 * @param templateTitle
 * @param templateContent
 */
export const addSMSTemplate = async (
  templateTitle: string,
  templateContent: string
) => {
  await request(HttpMethod.Post, apiRootUrl, {
    templateTitle,
    templateContent,
  });
};

/**
 * SMS 템플릿 수정
 * @param id
 * @param smsTitle
 * @param smsMsg
 */
export const editSMSTemplate = async (
  id: string,
  smsTitle: string,
  smsMsg: string
) => {
  const url = `${apiRootUrl}/${id}`;
  await request(HttpMethod.Put, url, undefined, {
    smsTitle,
    smsMsg,
  });
};
