import React, { useState } from "react";
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import moment from "moment";
import { ICellRendererParams } from "ag-grid-community";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import { createStockReportCols } from "./utils/table-utils";
import { getPageIndex } from "../../../utils/ag-grid-utils";
import {
  FilterElementType,
  FilterFormInfo,
} from "../../../components/shared/layout/TableFilterLayout/types";
import { fetchStockInfoReport } from "../../../apis/report";
import commonStrings from "../../../constants/strings";
import { createActionCellRenderers } from "../../../utils/table-utils";
import StockInfoReport from "../../../models/StockInfoReport";
import StockReportDetailModal from "./pages/StockReportDetailModal";

const StockReportManager: React.FC = () => {
  const [totalCount, setTotalCount] = useState(0);
  const cols = createStockReportCols();
  const [selectedData, setSelectedData] = useState<StockInfoReport>();
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [logInfo, setLogInfo] = useState<string>("");

  const filterInitialValues = {
    stdDt: moment(),
    cossModlNm: "",
    brandNm: "",
    carNo: "",
  };

  const filterForms: FilterFormInfo[] = [
    {
      key: "stdDt",
      type: FilterElementType.DatePicker,
      label: "조회일",
    },
    {
      key: "cossModlNm",
      label: "COSS 모델",
      type: FilterElementType.Input,
      placeholder: commonStrings.HINT_COSS_MODEL_NAME,
    },
    {
      key: "brandNm",
      label: "제조사",
      type: FilterElementType.Input,
      placeholder: commonStrings.HINT_COSS_BRAND_NAME,
    },
    {
      key: "carNo",
      label: "차량번호",
      type: FilterElementType.Input,
      placeholder: "차량번호를 입력해주세요",
    },
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const { stdDt, brandNm, carNo, cossModlNm } = appliedFilterValues;
    const stdDtParam = moment(stdDt).format("YYYYMMDD");

    try {
      const { items, count } = await fetchStockInfoReport(
        getPageIndex(startRow, paginationPageSize),
        paginationPageSize,
        stdDtParam,
        cossModlNm,
        brandNm,
        undefined,
        undefined,
        carNo
        // endDtParam,
        // ncarClsFlag,
        // custCls
      );
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
    } catch (e) {
      if (e.status !== 404) {
        setTotalCount(0);
        success({
          rowData: [],
          rowCount: 0,
        });
      }
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const report = data as StockInfoReport;
    setSelectedData(report);
    setDetailModalVisible(true);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <PaginationTableLayout
        cols={cols}
        onDataLoad={onDataLoad}
        headerTitle="일일 재고 현황"
        tableHeaderTitle="재고 현황 리스트"
        totalCount={totalCount}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        hideFilterAction
        frameworkComponents={{
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
        }}
        methodName="/admin/stock-info"
        logInfo={logInfo}
      />
      <StockReportDetailModal
        report={selectedData}
        visible={detailModalVisible}
        onCancel={() => {
          setDetailModalVisible(false);
        }}
      />
    </>
  );
};

StockReportManager.defaultProps = {};
export default StockReportManager;
