import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import PromotionReward from "../../models/PromotionReward";
import PromotionMessageHistory from "../../models/PromotionMessageHistory";
import PromotionMessageHistoryDetail from "../../models/PromotionMessageHistoryDetail";
import MGMReport from "../../models/MGMReport";

/**
 * 친구추천 리포트 리스트 조회
 */
export const fetchMGMReport = async (
  stEntryDt: string,
  endEntryDt: string,
  stCntrEndDt?: string,
  endCntrEndDt?: string,
  custNm?: string,
  stRecCnt?: string,
  endRecCnt?: string,
  stRectRewardCnt?: string,
  endRecRewardCnt?: string,
  stRecRewardSumAmt?: string,
  endRecRewardSumAmt?: string
): Promise<ListItemResponse<MGMReport>> => {
  const url = `${ROOT_URL}/admin/special-amt-reward-report`;
  const res = await request(HttpMethod.Get, url, {
    stEntryDt,
    endEntryDt,
    stCntrEndDt,
    endCntrEndDt,
    custNm,
    stRecCnt,
    endRecCnt,
    stRectRewardCnt,
    endRecRewardCnt,
    stRecRewardSumAmt,
    endRecRewardSumAmt,
  });
  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(MGMReport, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 특가 상품 리워드 이력 리스트 조회
 */
export const fetchSpecialPromotionRewards = async (): Promise<
  ListItemResponse<PromotionReward>
> => {
  const url = `${ROOT_URL}/admin/special-amt-reward`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(PromotionReward, list as Array<JSON>),
    count: Number(listCnt),
  };
};

export interface RewardStateParam {
  spclAmtInfoId: string;
  cntrId: string;
  rewardPayYn: string;
  rewardAmt?: number;
  recRewardAmt?: number;
}

/**
 * 특가 리워드 지급 여부 변경
 */
export const editRewardState = async (rewardStateParam: RewardStateParam) => {
  const url = `${ROOT_URL}/admin/special-amt-reward`;
  await request(HttpMethod.Put, url, undefined, rewardStateParam);
};

/**
 * Bulk 특가정보 리워드 지급 여부 변경
 */
export const bulkEditRewardState = async (
  rewardStateParams: RewardStateParam[]
) => {
  const url = `${ROOT_URL}/admin/special-amt-reward-list`;
  await request(HttpMethod.Put, url, undefined, rewardStateParams);
};

/**
 * 특가 발송 정보 리스트 조회
 */
export const fetchSpecialPromotionMessages = async (): Promise<
  ListItemResponse<PromotionMessageHistory>
> => {
  const url = `${ROOT_URL}/admin/special-amt-info`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(PromotionMessageHistory, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 특가 발송 정보 상세 조회
 */
export const fetchSpecialPromotionMessageDetail = async (
  spclAmtInfoId: string
): Promise<PromotionMessageHistoryDetail> => {
  const url = `${ROOT_URL}/admin/special-amt-info/${spclAmtInfoId}`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;

  return plainToClass(PromotionMessageHistoryDetail, response);
};

/**
 * 특가 정보 발송
 */
export const sendSpecialPromotionMessage = async (spclAmtInfoId: string) => {
  const url = `${ROOT_URL}/admin/special-amt-info-send/${spclAmtInfoId}`;
  await request(HttpMethod.Post, url);
};
