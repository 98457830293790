import React from "react";
import {
  SubHeaderTitle,
  SubHeaderTitleWrapper,
  SubHeaderWrapper,
  DescriptionWrapper,
  UserNameTitle,
} from "./style";
import Description from "../../typo/Description";
import strings from "../Header/constants/strings";
import Button from "../../general/antd/Button";
import { showConfirm } from "../../feedback/Confirm";
import useAuth from "../../../../hooks/useAuth";
import { alertError } from "../../../../utils/render-utils";
import { getErrorMessage } from "../../../../utils/common-utils";
import { logout } from "../../../../apis/session";

export interface SubHeaderProps {
  title?: string;
  disableBreadcrumbs?: boolean;
  description?: string;
}

const SubHeader: React.FC<SubHeaderProps> = (props: SubHeaderProps) => {
  const { title, description } = props;
  const { user, setUser } = useAuth();
  /**
   * Private Functions
   */
  const requestLogout = () => {
    showConfirm(strings.TITLE_LOGOUT, strings.CONFIRM_LOGOUT, async () => {
      try {
        await logout();
        setUser(undefined);
      } catch (e) {
        alertError(getErrorMessage(e));
      }
    });
  };

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <header>
      <SubHeaderWrapper>
        <SubHeaderTitleWrapper>
          <SubHeaderTitle>{title}</SubHeaderTitle>
        </SubHeaderTitleWrapper>
        <div>
          {user && (
            <>
              <UserNameTitle>{`${user.userNm}님 `}</UserNameTitle>
              <span
                style={{
                  marginRight: "16px",
                }}
              >
                어서오세요.
              </span>
            </>
          )}

          <Button onClick={requestLogout} type="ghost">
            {strings.TITLE_LOGOUT}
          </Button>
        </div>
      </SubHeaderWrapper>
      {description && (
        <DescriptionWrapper>
          <Description>{description}</Description>
        </DescriptionWrapper>
      )}
    </header>
  );
};

SubHeader.defaultProps = {
  title: "",
  disableBreadcrumbs: false,
};
export default SubHeader;
