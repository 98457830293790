import { FormElementType } from "../../../../../../components/shared/data-entry/FormBuilder/types";
import CarBookingDetail from "../../../../../../models/CarBookingDetail";

export const createInitialValues = () => {
  return {
    // 상담완료여부
    cnslFinYn: false,

    // 상담사메모
    picMemo: "",
  };
};

export const createInitialValuesFromCarBookingDetail = (
  consultingDetail: CarBookingDetail
) => {
  const { callYn, picMemo } = consultingDetail;

  return {
    // 상담완료여부
    callYn: callYn === "Y",

    // 상담사메모
    picMemo: picMemo || "",
  };
};

export const createFormInfo = () => {
  return [
    // 상담센터 전화여부
    {
      key: "callYn",
      type: FormElementType.Checkbox,
      label: "전화여부",
    },
    // 상담사 메모
    {
      key: "picMemo",
      type: FormElementType.TextArea,
      label: "상담사 메모",
      placeholder: "메모를 입력해주세요",
    },
  ];
};
