import React, { useRef, useState } from "react";
import moment from "moment";
// ag-grid
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import { GridApi, ICellRendererParams } from "ag-grid-community";
// consts
import commonStrings from "@constants/strings";
import strings from "./constants/strings";
import {
  CUST_CLS_FILTER_OPTIONS,
  MARKETING_FILTER_OPTIONS,
  MGM_FILTER_OPTIONS,
} from "./constants/enums";
import { ENUM_DTY_ID_BIZ } from "@constants/enums";
// components
import { FilterElementType } from "@components/shared/layout/TableFilterLayout/types";
import { createCarManagerDetailRenderer } from "@components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import PaginationTableLayout from "@components/shared/composition/PaginationTableLayout";
import CustomerModal from "./pages/CustomerModal";
import EstimationSimulatorModal from "./pages/EstimationSimulatorModal";
// utils
import { getPageIndex, refreshServerSideTable } from "@utils/ag-grid-utils";
import { createActionCellRenderers } from "@utils/table-utils";
import { alertError } from "@utils/render-utils";
import {
  get1YearDateRange,
  getTodayDateRange,
} from "@utils/date-utils";
import { createCustomerCols } from "./utils/table-utils";
// apis
import { fetchCustomers } from "@apis/customers";
import { setConnectLogs } from "@apis/logs";
// hooks
import useAuth from "@hooks/useAuth";

const CustomerManager: React.FC = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>();
  const [logInfo, setLogInfo] = useState<string>("");
  const cols = createCustomerCols();
  const { user } = useAuth();
  const gridApiRef = useRef<GridApi>();
  const [gridApi, setGridApi] = useState<GridApi>();

  const getInitialDateRange = () => {
    if (user.dtyId === ENUM_DTY_ID_BIZ) return getTodayDateRange();
    return get1YearDateRange();
  };

  const filterInitialValues = {
    stDt: getInitialDateRange(),
    custCls: "",
    custNm: "",
    hpNo: "",
    carmanNm: "",
    mktAgreYn: "",
    cntrCnt: "",
    skFrnsYn: "",
    authStDt: "",
  };

  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: commonStrings.LABEL_SIGNED_AT,
    },
    {
      key: "custCls",
      type: FilterElementType.Select,
      label: commonStrings.LABEL_CUSTOMER_KIND,
      options: CUST_CLS_FILTER_OPTIONS,
    },
    {
      key: "custNm",
      type: FilterElementType.Input,
      label: commonStrings.LABEL_CUSTOMER_NAME,
      placeholder: commonStrings.HINT_CUSTOMER_NAME,
    },
    {
      key: "busrCustNm",
      type: FilterElementType.Input,
      label: commonStrings.LABEL_BIZ_NAME,
      placeholder: commonStrings.HINT_BIZ_NAME,
    },
    {
      key: "hpNo",
      type: FilterElementType.Input,
      label: commonStrings.LABEL_HP_NO,
      placeholder: commonStrings.HINT_HP_NO,
    },
    {
      key: "cntrCnt",
      type: FilterElementType.NumberInput,
      label: commonStrings.LABEL_CONTRACT_COUNT,
      placeholder: commonStrings.HINT_CONTRACT_COUNT,
    },
    {
      key: "carmanNm",
      type: FilterElementType.Input,
      label: commonStrings.LABEL_CAR_MANAGER_NAME,
      placeholder: commonStrings.LABEL_CAR_MANAGER_NAME,
    },
    {
      key: "mktAgreYn",
      type: FilterElementType.Select,
      label: commonStrings.LABEL_MARKETING_AGREEMENT,
      options: MARKETING_FILTER_OPTIONS,
    },
    {
      key: "skFrnsYn",
      type: FilterElementType.Select,
      label: commonStrings.LABEL_MGM_JOIN_YN,
      options: MGM_FILTER_OPTIONS,
    },
    {
      key: "authStDt",
      type: FilterElementType.DateRangePicker,
      label: commonStrings.LABEL_LAST_CERTIFIED,
    },
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 저장
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const {
      stDt,
      custNm,
      custCls,
      hpNo,
      mktAgreYn,
      carmanNm,
      cntrCnt,
      busrCustNm,
      skFrnsYn,
      authStDt,
    } = appliedFilterValues;
    // 가입일
    const stDtParam = moment(stDt[0]).format("YYYYMMDD");
    const endDtParam = moment(stDt[1]).format("YYYYMMDD");

    // 최신본인인증일
    let authStDtParam;
    let authEndDtParam;
    if (authStDt) {
      authStDtParam = moment(authStDt[0]).format("YYYYMMDD");
      authEndDtParam = moment(authStDt[1]).format("YYYYMMDD");
    }

    try {
      const { items, count } = await fetchCustomers(
        getPageIndex(startRow, paginationPageSize),
        paginationPageSize,
        stDtParam,
        endDtParam,
        custNm,
        custCls,
        hpNo,
        mktAgreYn,
        carmanNm,
        cntrCnt,
        busrCustNm,
        skFrnsYn,
        authStDtParam,
        authEndDtParam
      );
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
      setConnectLogs({
        userId: user.userId,
        userIp: user.clientIp,
        methodNm: "/admin/user-info",
        menuName: "회원 - 회원 관리",
        actionFlag: "SEARCH",
        reqCntn: appliedFilters
      });
    } catch (e) {
      if (e.status !== 404) {
        setTotalCount(0);
        success({
          rowData: [],
          rowCount: 0,
        });
      }
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { custId } = data;
    setSelectedDataId(custId);
    setModalVisible(true);
  };

  const handleCarManagerDetailCellClick = (carmanId?: string) => {
    alertError(`${carmanId}`);
  };

  /**
   * Render Helpers
   */
  const handleDataChange = async () => {
    await refreshServerSideTable(gridApi);
  };

  return (
    <>
      <PaginationTableLayout
              apiRef={(api) => {
                setGridApi(api);
                gridApiRef.current = api;
              }}
        headerTitle={strings.TITLE_HEADER_CUSTOMER_MANAGER}
        tableHeaderTitle={strings.TABLE_HEADER_CUSTOMER_LIST}
        onDataLoad={onDataLoad}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        totalCount={totalCount}
        hideFilterAction
        frameworkComponents={{
          ...createCarManagerDetailRenderer(handleCarManagerDetailCellClick),
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
        }}
        methodName="/admin/user-info"
        logInfo={logInfo}
      />
      <CustomerModal
        id={selectedDataId}
        visible={modalVisible}
        onDataChange={ () => handleDataChange() }
        onCancel={() => {
          setModalVisible(false);
        }}
      />
      <EstimationSimulatorModal />
    </>
  );
};

CustomerManager.defaultProps = {};
export default CustomerManager;
