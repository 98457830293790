import React from "react";
import { Select as AntSelect, Tag } from "antd";
import Select, { SelectProps } from "../../../data-entry/antd/Select";
import {
  getMobileTagStyles,
  getPcTagStyles,
  TAG_STYLE_INFO,
  TagStyleCode,
} from "../utils/utils";
import {
  // common tags
  TAG_STYLE_CD_1, TAG_STYLE_CD_2, TAG_STYLE_CD_11,
  // nc tags
  TAG_STYLE_CD_A54021, TAG_STYLE_CD_A54022, TAG_STYLE_CD_A54023, TAG_STYLE_CD_A54024, TAG_STYLE_CD_A54025, TAG_STYLE_CD_A54026, TAG_STYLE_CD_A54027, TAG_STYLE_CD_A54031, TAG_STYLE_CD_A54032, TAG_STYLE_CD_A54033, TAG_STYLE_CD_A54034, TAG_STYLE_CD_A54035, TAG_STYLE_CD_A54036, TAG_STYLE_CD_A54037, TAG_STYLE_CD_A54041, TAG_STYLE_CD_A54042, TAG_STYLE_CD_A54043, TAG_STYLE_CD_A54044, TAG_STYLE_CD_A54045, TAG_STYLE_CD_A54046, TAG_STYLE_CD_A54047, TAG_STYLE_CD_A54051, TAG_STYLE_CD_A54052, TAG_STYLE_CD_A54053, TAG_STYLE_CD_A54054, TAG_STYLE_CD_A54055, TAG_STYLE_CD_A54056, TAG_STYLE_CD_A54057,
  // mc tags
  MC_TAG_LIST
} from "@constants/enums";
import Image from "@components/shared/data-display/antd/Image";
import PromotionIcon from "@assets/images/promotion-icon.png";

const { Option } = AntSelect;

interface Props extends SelectProps {
  isNc?: boolean;
  platform?: "pc" | "mobile";
  tagPosition?: "top" | "bottom";
}

/**
 * 태그 선택박스
 * 
 * @param props 
 * @returns 
 */
const CatalogTagStyleSelect: React.FC<Props> = (props: Props) => {
  const { isNc, platform, tagPosition = "top", ...rest } = props;
  const topTags = [TAG_STYLE_CD_1, TAG_STYLE_CD_2, TAG_STYLE_CD_11];
  const ncTags = [TAG_STYLE_CD_A54021, TAG_STYLE_CD_A54022, TAG_STYLE_CD_A54023, TAG_STYLE_CD_A54024, TAG_STYLE_CD_A54025, TAG_STYLE_CD_A54026, TAG_STYLE_CD_A54027, TAG_STYLE_CD_A54031, TAG_STYLE_CD_A54032, TAG_STYLE_CD_A54033, TAG_STYLE_CD_A54034, TAG_STYLE_CD_A54035, TAG_STYLE_CD_A54036, TAG_STYLE_CD_A54037, TAG_STYLE_CD_A54041, TAG_STYLE_CD_A54042, TAG_STYLE_CD_A54043, TAG_STYLE_CD_A54044, TAG_STYLE_CD_A54045, TAG_STYLE_CD_A54046, TAG_STYLE_CD_A54047, TAG_STYLE_CD_A54051, TAG_STYLE_CD_A54052, TAG_STYLE_CD_A54053, TAG_STYLE_CD_A54054, TAG_STYLE_CD_A54055, TAG_STYLE_CD_A54056, TAG_STYLE_CD_A54057];
  // const ucTags = [TAG_STYLE_CD_661, TAG_STYLE_CD_662, TAG_STYLE_CD_663, TAG_STYLE_CD_664, TAG_STYLE_CD_665, TAG_STYLE_CD_666];
  const tags = isNc ? ncTags : [...ncTags, ...MC_TAG_LIST];

  return (
    <Select
      showSearch={false}
      placeholder="태그 스타일을 선택해주세요"
      {...rest}
    >
      {Object.keys(TAG_STYLE_INFO)
        .filter((key) => {
          if (tagPosition === "top") {
            return topTags.includes(key);
          }
          return tags.includes(key);
        })
        .map((key) => {
          let style = getPcTagStyles(key as TagStyleCode);

          if (platform === "mobile") {
            style = getMobileTagStyles(key as TagStyleCode);
          }

          return (
            <Option key={key} value={key} label={key}>
              <Tag style={style}>
                {
                  [TAG_STYLE_CD_11].includes(key) ? (
                  <>
                    <Image src={PromotionIcon} alt={"프로모션 로고"} width={12} height={12} style={{marginRight:"4px",position:"relative",top:"1px"}} preview={false} />
                    TEXT
                  </>) : "TEXT"
                }
              </Tag>
            </Option>
          );
        })}
    </Select>
  );
};

CatalogTagStyleSelect.defaultProps = {
  platform: "pc",
};

export default CatalogTagStyleSelect;
