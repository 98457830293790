class HttpError {
  public status = -1;

  private readonly response: any | null = null;

  private readonly message: any | null = null;

  constructor(error: any) {
    if (error) {
      const { data, status } = error;
      this.status = status;

      // 오류 응답이 있을 경우
      if (data) {
        const { message, response } = data;
        this.message = message;
        this.response = response;
      }
      // Timeout 등 요청 실패 오류 처리
      else {
        this.message = error.toString();
      }
    }
  }

  toString(): string {
    if (this.message) {
      return this.message;
    }
    return "";
  }
}

export default HttpError;
