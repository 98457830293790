export default class SMSSendHistory {
  // 발송이력ID
  public sndMainId = "";

  // SMS메시지ID
  public smsMsgId = "";

  // SMS제목
  public smsTitle = "";

  // 사용여부
  public useYn = "";

  // 입력일시
  public insDtm = "";

  // 발송 고객 수
  public custCnt = "";

  // 담당 고객 수
  public totCustCnt = "";

  public carmanId = "";

  public carmanNm = "";
}
