import React from "react";
import { Tag } from "antd";
import styled from "styled-components";
import { ModalProps } from "../../../../../../types";

// assets
import PromotionIcon from "@assets/images/promotion-icon.png";

// constants
import {
  TAG_POS_CD_BOTTOM,
  TAG_POS_CD_TOP,
} from "@constants/enums";

// models
import TagMaster from "@models/TagMaster";
import NewCarCatalogDetail from "@models/NewCarCatalogDetail";

// components
import Image from "@components/shared/data-display/antd/Image";
import Modal from "@components/shared/feedback/antd/Modal";
import {
  getMobileTagStyles,
  getPcTagStyles,
  TagStyleCode,
} from "@components/shared/composition/TagMasterSelect/utils/utils";
import { formatNumber } from "@utils/common-utils";

interface Props extends ModalProps {
  tagMasters?: TagMaster[];
  catalogDetail?: NewCarCatalogDetail;
  modeGrdId?: string;
}

const TagPreviewModal: React.FC<Props> = (props: Props) => {
  const { tagMasters = [], catalogDetail, visible, onCancel, modeGrdId } = props;

  // ! 프로모션 Tag
  const promotionTags = tagMasters.filter(({ pcTagStyl, mobTagStyl }) => {
    return [pcTagStyl, mobTagStyl].includes("A54011");
  });
  // ! PC top Tags
  const pcTopTags = tagMasters.filter(({ pcTagPos }) => {
    return pcTagPos === TAG_POS_CD_TOP;
  });
  // ! PC bottom Tags
  const pcBottomTags = tagMasters.filter(({ pcTagPos }) => {
    return pcTagPos === TAG_POS_CD_BOTTOM;
  });
  const pcNewStyleTags = [...promotionTags, ...pcBottomTags];
  // ! Mob top Tags
  const mobTopTags = tagMasters.filter(({ mobTagPos }) => {
    return mobTagPos === TAG_POS_CD_TOP;
  });
  // ! Mob bottom Tags
  const mobBottomTags = tagMasters.filter(({ mobTagPos }) => {
    return mobTagPos === TAG_POS_CD_BOTTOM;
  });
  const mobNewStyleTags = [...promotionTags, ...mobBottomTags];

  /**
   * 미리보기 render
   * 
   * @returns 
   */
  const renderPreview = () => {
    if (catalogDetail) {
      const { prodNm, repCarImg, list } = catalogDetail;
      const model = list?.find(v => v.modeGrdId === modeGrdId);
      if (model) {
        // ! 신차 모델 태그 미리보기
        return (
          <>
            <div>
              <PlatformLabel>PC</PlatformLabel>
              <GreyContainer>
                <CenterWrapper>
                  <PCCardContainer>
                    <NcTopTagBox>
                      <NcBottomTagBox>
                        {pcNewStyleTags.map(({ pcTagTxt, pcTagStyl }) => (
                          <Tag
                            style={
                              pcTagStyl === "A54011" ? 
                              {
                                ...getPcTagStyles(pcTagStyl as TagStyleCode),
                                fontSize: "12px",
                                lineHeight: "initial",
                                padding:"3px 8px",
                                marginRight:"4px",
                              } 
                              :
                              {
                                ...getPcTagStyles(pcTagStyl as TagStyleCode), 
                                marginRight: "4px", 
                                marginBottom: "2px",
                              }
                            }
                          >
                            {pcTagStyl === "A54011" && <Image src={PromotionIcon} alt={"프로모션 로고"} width={12} height={12} style={{marginRight:"4px",position:"relative",top:"1px"}} preview={false} />}
                            {pcTagTxt}
                          </Tag>
                        ))}
                      </NcBottomTagBox>
                      <article className="titleWrap">{model?.carGradeNm}</article>
                      <article className="colorWrap">
                        <span>외장색상</span><strong><img src={model?.modeXtnlCarColorUrl} alt={model?.modeXtnlCarColorNm} /> {model?.modeXtnlCarColorNm}</strong>
                      </article>
                      <article className="colorWrap">
                        <span>내장색상</span><strong><img src={model?.modeInnrCarColorUrl} alt={model?.modeInnrCarColorNm} /> {model?.modeInnrCarColorNm}</strong>
                      </article>

                      <div className="priceWrap"><p>차량 가격</p> <p>{formatNumber(model?.carAmt || 0)}</p></div>
                    </NcTopTagBox>
                  </PCCardContainer>
                  
                </CenterWrapper>
              </GreyContainer>
            </div>

            <div>
              <PlatformLabel top="20">MO</PlatformLabel>
              <GreyContainer>
                <CenterWrapper device="mo">
                  <NcMobileCardContainer>
                    <NcTopTagBox>
                      <NcBottomTagBox>
                        {mobNewStyleTags.map(({ mobTagTxt, mobTagStyl }) => (
                          <Tag
                            style={
                              mobTagStyl === "A54011" ? 
                              {
                                ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                                fontSize: "12px",
                                lineHeight: "initial",
                                padding:"3px 8px",
                                marginRight:"4px",
                              } 
                              :
                              {
                                ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                                marginRight: "4px",
                                marginBottom: "2px",
                                lineHeight:"13px",
                              }
                            }
                          >
                            {mobTagStyl === "A54011" && <Image src={PromotionIcon} alt={"프로모션 로고"} width={12} height={12} style={{marginRight:"4px",position:"relative",top:"1px"}} preview={false} />}
                            {mobTagTxt}
                          </Tag>
                        ))}
                      </NcBottomTagBox>
                      <article className="titleWrap">{model?.carGradeNm}</article>
                      <article className="colorWrap">
                        <span>외장색상</span><strong><img src={model?.modeXtnlCarColorUrl} alt={model?.modeXtnlCarColorNm} /> {model?.modeXtnlCarColorNm}</strong>
                      </article>
                      <article className="colorWrap">
                        <span>내장색상</span><strong><img src={model?.modeInnrCarColorUrl} alt={model?.modeInnrCarColorNm} /> {model?.modeInnrCarColorNm}</strong>
                      </article>

                      <div className="priceWrap"><p>차량 가격</p> <p>{formatNumber(model?.carAmt || 0)}</p></div>
                    </NcTopTagBox>
                  </NcMobileCardContainer>
                  
                </CenterWrapper>
              </GreyContainer>
            </div>
          </>
          
        )
      } else {
        // ! 신차 메인 태그 미리보기
        return (
          <div>
            <PlatformLabel>PC</PlatformLabel>
            <GreyContainer>
              <CenterWrapper>
                <PCCardContainer imageUrl={repCarImg}>
                  <TopTagBox platform="pc">
                    {pcTopTags.map(({ pcTagTxt, pcTagStyl }) => (
                      <Tag
                        style={{
                          ...getPcTagStyles(pcTagStyl as TagStyleCode),
                          marginRight: "4px",
                          marginBottom: "2px",
                        }}
                      >
                        {pcTagStyl === "A54011" && <Image src={PromotionIcon} alt={"프로모션 로고"} width={12} height={12} style={{marginRight:"4px",position:"relative",top:"1px"}} preview={false} />}
                        {pcTagTxt}
                      </Tag>
                    ))}
                  </TopTagBox>
                </PCCardContainer>
                <PCProductTitle>{prodNm}</PCProductTitle>
                <BottomTagBox platform="pc">
                  {pcBottomTags.map(({ pcTagTxt, pcTagStyl }) => (
                    <Tag
                      style={{
                        ...getPcTagStyles(pcTagStyl as TagStyleCode),
                        marginRight: "4px",
                        marginBottom: "2px",
                      }}
                    >
                      {pcTagTxt}
                    </Tag>
                  ))}
                </BottomTagBox>
              </CenterWrapper>
            </GreyContainer>
            {/* <PlatformLabel
              style={{
                marginTop: "24px",
              }}
            >
              모바일(카드)
            </PlatformLabel>
            <GreyContainer>
              <CenterWrapper>
                <MobileCardContainer imageUrl={repCarImg}>
                  <TopTagBox>
                    {mobTopTags.map(({ mobTagTxt, mobTagStyl }) => (
                      <Tag
                        style={{
                          ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                          marginRight: "4px",
                          marginBottom: "2px",
                        }}
                      >
                        {mobTagTxt}
                      </Tag>
                    ))}
                  </TopTagBox>
                </MobileCardContainer>
                <MobileProductTitle>{prodNm}</MobileProductTitle>
                <BottomTagBox>
                  {mobBottomTags.map(({ mobTagTxt, mobTagStyl }) => (
                    <Tag
                      style={{
                        ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                        marginRight: "4px",
                        marginBottom: "2px",
                      }}
                    >
                      {mobTagTxt}
                    </Tag>
                  ))}
                </BottomTagBox>
              </CenterWrapper>
            </GreyContainer> */}
            <PlatformLabel
              style={{
                marginTop: "24px",
              }}
            >
              모바일(리스트)
            </PlatformLabel>
            <GreyContainer>
              <CenterWrapper>
                <MobileListContainer>
                  <img
                    src={repCarImg}
                    alt="차량이미지"
                    height={60}
                    style={{
                      marginRight: "16px",
                    }}
                  />
                  <div>
                    <FlexBox>
                      <MobileListProductTitle>{prodNm}</MobileListProductTitle>
                      <MobileListTopTagBox>
                        {mobTopTags.map(({ mobTagTxt, mobTagStyl }) => (
                          <Tag
                            style={
                              mobTagStyl === "A54011" ? 
                              {
                                ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                                marginLeft: "17px",
                                marginBottom: "2px",
                                fontSize:"12px",
                                padding:"5px 8px"
                              } 
                              :
                              {
                                ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                                marginRight: "4px",
                                marginBottom: "2px",
                              }
                            }
                          >
                            {mobTagStyl === "A54011" && <Image src={PromotionIcon} alt={"프로모션 로고"} width={10} height={10} style={{marginRight:"4px",position:"relative",top:"1px"}} preview={false} />}
                            {mobTagTxt}
                          </Tag>
                        ))}
                      </MobileListTopTagBox>
                    </FlexBox>
                    <MobileListBottomTagBox>
                      {mobBottomTags.map(({ mobTagTxt, mobTagStyl }) => (
                        <Tag
                          style={{
                            ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                            marginRight: "4px",
                            marginBottom: "2px",
                          }}
                        >
                          {mobTagTxt}
                        </Tag>
                      ))}
                    </MobileListBottomTagBox>
                  </div>
                </MobileListContainer>
              </CenterWrapper>
            </GreyContainer>
          </div>
        );
      }
    }

    return "";
  };

  return (
    <Modal
      title="태그 미리보기"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {renderPreview()}
    </Modal>
  );
};

const PlatformLabel = styled.span<any>`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  display: inline-block;
  margin-top: ${(props) => (`${props.top}px`)};
`;

const PCProductTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  display: inline-block;
  margin-left: 8px;
`;

const MobileProductTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
  display: inline-block;
  margin-left: 4px;
`;

const MobileListProductTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
`;

const GreyContainer = styled.div`
  background-color: #f6f6fa;
  padding: 16px;
  text-align: center;
`;

const CenterWrapper = styled.div<any>`
  display: inline-block;
  text-align: left;
  width: ${(props) => (props.device === "mo" ? "100%" : "auto")};
`;

const PCCardContainer = styled.div<any>`
  position: relative;
  background-color: white;
  width: 280px;
  min-height: 161px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-image: url(${(props) => props.imageUrl});
  background-size: 172px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;
const NcMobileCardContainer = styled.div<any>`
  position: relative;
  background-color: white;
  width: 100%;
  min-height: 106px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-image: url(${(props) => props.imageUrl});
  background-size: 110px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`
const MobileCardContainer = styled.div<any>`
  position: relative;
  background-color: white;
  width: 160px;
  height: 106px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-image: url(${(props) => props.imageUrl});
  background-size: 110px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;
const NcTopTagBox = styled.div<any>`
  background-color: #fff;
  border-radius: 8px;
  padding: 14px 24px;
  article{
    display: flex;
    padding-top: 4px;
    color: #3A3A3C;
    >span{
      min-width: 56px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-right: 12px;
      white-space: nowrap;
      display: inline-block;
    }
    >strong{
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      word-break: keep-all;
      white-space: pre-wrap;
      line-height: 1.5;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      gap:4px;
    }
    &.titleWrap{
      white-space: pre-wrap;
      word-break: keep-all;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 10px;
    }
    &.colorWrap{
      >span{

      }
    }
  }
  .priceWrap{
    display: flex;
    justify-content: space-between;
    color: #262628;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    border-top: 1px solid #EDEDF0;
    padding-top: 10px;
    margin-top: 10px;
    >p{
      margin-bottom: 0;
    }
  }
`
const TopTagBox = styled.div<any>`
  position: absolute;
  width: ${(props) => (props.platform === "pc" ? "280px" : "160px")};
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
`;

const BottomTagBox = styled.div<any>`
  display: flex;
  width: ${(props) => (props.platform === "pc" ? "280px" : "160px")};
  overflow: hidden;
  flex-flow: row wrap;
`;
const NcBottomTagBox= styled.div<any>`
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-flow: row wrap;
`
const MobileListTopTagBox = styled.div`
  width: 100px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

const MobileListBottomTagBox = styled.div<any>`
  display: flex;
  width: 260px;
  overflow: hidden;
  flex-flow: row wrap;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 8px;
`;

export const MobileListContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0 16px 16px;
  background: white;
`;

export default TagPreviewModal;