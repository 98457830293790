import moment from "moment";
import * as Yup from "yup";
import { ExhibitionMaster } from "../../../../../../../../apis/ex/types";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../../components/shared/data-entry/FormBuilder/types";
import errorMessages from "../../../../../../../../constants/errors";
import { getInitialDateRangeFromRaw } from "../../../../../../../../utils/form-utils";

export const createInitialValues = () => {
  return {
    exhTitle: "",
    exhDesc: "",

    displayTerm: null,

    exhMstViewSeq: "",

    exhUseYn: false,
  };
};

export const createInitialValuesFromExhibitionMaster = (
  master: ExhibitionMaster
) => {
  const {
    exhStDt,
    exhEndDt,
    exhTitle,
    exhMstViewSeq,
    exhUseYn,
    exhDesc,
  } = master;
  const format = "YYYYMMDDHHmmss";
  const initialDisplayTerm = [
    moment(exhStDt, format),
    moment(exhEndDt, format),
  ];
  getInitialDateRangeFromRaw(exhStDt, exhEndDt);

  return {
    exhTitle,
    exhDesc: exhDesc || "",
    displayTerm: initialDisplayTerm,

    exhMstViewSeq,

    exhUseYn: exhUseYn === "Y",
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    exhTitle: Yup.string().required(errorMessages.REQUIRED_FIELD),
    exhDesc: Yup.string().required(errorMessages.REQUIRED_FIELD),
    exhMstViewSeq: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    displayTerm: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(2, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createForms = (): FormInfo[] => {
  return [
    {
      key: "exhTitle",
      label: "제목",
      type: FormElementType.Input,
      placeholder: "기획전 제목을 입력해주세요",
      required: true,
    },
    {
      key: "exhDesc",
      label: "설명",
      type: FormElementType.Input,
      placeholder: "제목 하단 설명을 입력해주세요",
      required: true,
    },
    {
      key: "displayTerm",
      label: "노출기간",
      type: FormElementType.DateRangePicker,
      placeholder: "적용기간을 입력해주세요",
      required: true,
      config: {
        showTime: true,
      },
    },
    {
      key: "exhMstViewSeq",
      label: "게시순번",
      type: FormElementType.InputNumber,
      placeholder: "게시순번을 입력해주세요",
      required: true,
      config: {
        min: 1,
      },
    },
    {
      key: "exhUseYn",
      label: "사용유무",
      type: FormElementType.Checkbox,
      required: true,
    },
  ];
};
