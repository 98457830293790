class RentContract {
  // 계약ID
  public cntrId = "";

  // 계약순번
  public cntrSeq = "";

  // 견적일자
  public estDt = "";

  // 계약일자
  public cntrDt = "";

  // 개인사업자 인증상태
  public addVrfYn = "";

  // 계약취소일
  public cnclDt = "";

  // 회원구분
  public modeCustClsNm = "";

  // 고객ID
  public custId = "";

  // 회원명
  public custNm = "";

  // 상품유형코드
  public prodPatternCd = "";

  // 상품유형
  public prodPatternNm = "";

  // 계약유형코드
  public custClsCd = "";

  // 계약유형
  public custClsNm = "";

  // 계약진행상태코드
  public cntrStsCd = "";

  // 계약진행상태
  public cntrStsNm = "";

  // 출고여부코드
  public consiStsCd = "";

  // 출고여부
  public consiStsNm = "";

  // 담당카매니저ID
  public carmanId = "";

  // 담당카매니저
  public carmanNm = "";

  // 차량ID
  public carId = "";

  // 차량번호
  public carNo = "";

  // 모델(대표차명)
  public repCartypeNm = "";

  // 총 차량가
  public totCarAmt = "";

  // 세부모델(차종명)
  public carClassNm = "";

  // 트림((NICE AB)등급명)
  public carGradeNm = "";

  // 대표차량이미지
  public repCarImg = "";

  // 계약기간시작일자
  public cntrTermStDt = "";

  // 계약기간종료일자
  public cntrTermEndDt = "";

  // 담보조건코드
  public pldgCondCd = "";

  // 담보조건
  public pldgCondNm = "";

  // 마이페이지 계약상태코드
  public mpCntrStsCd = "";

  // 마이페이지 계약상태명
  public mpCntrStsNm = "";

  // 마이페이지 계약상세상태코드
  public mpCntrDtlStsCd = "";

  // 마이페이지 계약상세상태명
  public mpCntrDtlStsNm = "";

  // 출고예정일자
  public outIntendDt = "";

  // 계약종료일자
  public cntrEndDt = "";

  // 결제수단코드
  public stmtMeansCd = "";

  // 결제수단명
  public stmtMeansNm = "";

  // 은행코드
  public custBankCd = "";

  // 은행명
  public bankNm = "";

  // 카드사코드
  public cardCoCd = "";

  // 카드사명
  public cardCoNm = "";

  // KIS 인증
  public kisAuthYn = "";

  // 추천차량 등급 아이디
  public extgModeGrdId = "";

  // 추천차량 상품 아아디
  public extgProdId = "";

  // 추천차량 단계 (1-> 1차, 2-> 2차)
  public recClsDeg = "";

  // 계약기간
  public cntrTermMm = "";

  // 연간약정거리코드
  public prmsDtcClsCd = "";

  // 연간약정거리
  public prmsDtcClsNm = "";

  // 등록지
  public useAreaNm = "";

  // 보증금 카드결제 여부
  public grtCardStmtYn = "";

  // 보증금 카드결제사
  public grtCardStmtCo = "";

  // 그룹사할인여부
  public skGrpYn = "";

  // 제휴사코드
  public cooperCd = "";

  // 제휴채널명
  public cooperCdNm = "";

  // 견적채널명
  public estCooperCdNm = "";

  // 직접인수 요청 유저
  public dpRequestUser = "";

  // 인수옵션코드
  public tkvRtrnYnCd = "";

  // 인수옵션코드네임
  public tkvRtrnYnNm = "";

  // ERP 고객 코드
  public erpCustCd = "";

  /** 추천 사번 */
  public rcmEmpi = "";
}

export default RentContract;
