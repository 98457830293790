import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import strings from "../constants/strings";
import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";

import Button from "../../../../components/shared/general/antd/Button";
import {
  currencyFormatRenderer,
  dateFormatRenderer,
} from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../../constants/strings";
import { formatDateString } from "../../../../utils/date-utils";
import CossMaster from "../../../../models/CossCarInfo/CossMaster";

export const COSS_CONNECT_ACTION = "cossConnectAction";

export const renderConnectCossButton = (
  onClick: (cossMaster: CossMaster) => void,
  onDeleteClick: (cossMaster: CossMaster, api: GridApi) => void
) => {
  return (props: ICellRendererParams) => {
    const { data, api } = props;

    const { niceMpnDt } = data as CossMaster;
    if (niceMpnDt) {
      return (
        <div>
          <Button
            danger
            ghost
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              onDeleteClick(data, api);
            }}
          >
            {formatDateString(niceMpnDt)}
          </Button>
        </div>
      );
    }

    return (
      <Button
        ghost
        size="small"
        onClick={() => {
          onClick(data);
        }}
      >
        연결하기
      </Button>
    );
  };
};

export const createCarInfoCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createServerTableCol("insDtm", commonStrings.REG_DATE, {
      width: CW_SMALL,
      cellRenderer: dateFormatRenderer("insDtm"),
    }),
    // NICE 연동일
    createServerTableCol("cossMpnDt", commonStrings.NICE_CONNECTED_AT, {
      width: CW_SMALL_M,
      cellRenderer: COSS_CONNECT_ACTION,
    }),
    // 제조사
    createServerTableCol("carMakerNm", commonStrings.MANUFACTURER, {
      width: CW_MEDIUM,
    }),
    // COSS ID
    createServerTableCol("modlId", commonStrings.COSS_ID, {
      width: CW_MEDIUM,
    }),
    // COSS 모델
    createServerTableCol("modlNm", commonStrings.COSS_MODEL, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 연료
    createServerTableCol("fuelNm", commonStrings.FUEL, {
      width: CW_SMALL,
    }),
    // COSS 차량가격
    createServerTableCol("carAmt", commonStrings.COSS_CAR_PRICE, {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: currencyFormatRenderer("carAmt"),
    }),
    // 상품유형
    createServerTableCol("aheadNm", commonStrings.AHEAD_YN, {
      width: CW_SMALL,
    }),
    // 연식
    createServerTableCol("yearType", strings.COL_MANUFACTURE_YEAR, {
      width: CW_SMALL,
    }),
    // NICE 모델
    createServerTableCol("carClassNm", commonStrings.NICE_MODEL, {
      width: CW_SMALL,
    }),
    // NICE 트림
    createServerTableCol("carGradeNm", commonStrings.NICE_TRIM, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
