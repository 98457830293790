import React, { useState } from "react";
import { FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import commonStrings from "../../../../../../../constants/strings";
import RentFeeResult from "../../../../../../../models/RentFeeResult";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import {
  FlexBox,
  PriceLabel,
  PriceTitle,
  Divider,
  PriceTitleStressed,
} from "./style";
import {
  formatNumber,
  getErrorMessage,
} from "../../../../../../../utils/common-utils";
import EstimationCatalogDetail from "../../../../../../../models/EstimationSimulator/EstimationCatalogDetail";
import { RentFeeQueryParam } from "../../../../../../../apis/est-sim/types";
import CustomerDetail from "../../../../../../../models/CustomerDetail";
import { createEstimationParams } from "../../utils/form-utils";
import { addEstimation } from "../../../../../../../apis/est-sim";
import BusinessInfo from "../../../../../../../models/BusinessInfo";

interface Props extends ModalProps {
  rentFeeResult?: RentFeeResult;
  catalogDetail?: EstimationCatalogDetail;
  rentFeeQuery?: RentFeeQueryParam;
  formValues?: FormikValues;
  customerDetail?: CustomerDetail;
  businessInfo?: BusinessInfo;
}

const EstimationResultModal: React.FC<Props> = (props: Props) => {
  const {
    visible,
    onCancel,
    onDataChange,
    rentFeeResult,
    catalogDetail,
    rentFeeQuery,
    formValues,
    customerDetail,
    businessInfo,
  } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onModalOpen = () => {};

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  const handleSaveEstimation = async () => {
    if (
      catalogDetail &&
      rentFeeQuery &&
      rentFeeResult &&
      formValues &&
      customerDetail
    ) {
      setConfirmLoading(true);
      try {
        const params = createEstimationParams(
          rentFeeQuery,
          catalogDetail,
          rentFeeResult,
          customerDetail,
          formValues,
          businessInfo
        );

        await addEstimation(params);
        alertSuccess(commonStrings.FEEDBACK_ADD_ESTIMATION);
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    } else {
      alertError("필수파라미터가 없습니다.");
    }
  };

  /**
   * Render Helpers
   */

  const renderContents = () => {
    if (rentFeeResult) {
      return (
        <div>
          <FlexBox>
            <PriceLabel>{commonStrings.LABEL_DEFAULT_RENT_FEE}</PriceLabel>
            <PriceTitle>{formatNumber(rentFeeResult.rglRentAmt)}원</PriceTitle>
          </FlexBox>
          {rentFeeResult.odsDcList.map(({ odsDcNm, odsDcAmt }) => {
            return (
              <FlexBox key={odsDcNm}>
                <PriceLabel>{odsDcNm}</PriceLabel>
                <PriceTitle>-{formatNumber(odsDcAmt)}원</PriceTitle>
              </FlexBox>
            );
          })}
          {rentFeeResult.mmInitamt ? (
            <>
              <FlexBox
                style={{
                  marginBottom: "8px",
                }}
              >
                <PriceLabel>선수금(매월차감)</PriceLabel>
                <PriceTitle>
                  -{formatNumber(rentFeeResult.mmInitamt)}원
                </PriceTitle>
              </FlexBox>
              <div
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  marginBottom: "16px",
                }}
              >{`(${formatNumber(rentFeeQuery?.initamt || 0)}원 / ${
                rentFeeQuery?.cntrTermMm
              }개월)`}</div>
            </>
          ) : (
            ""
          )}
          <Divider />
          <FlexBox>
            <PriceLabel>{commonStrings.LABEL_FINAL_RENT_FEE}</PriceLabel>
            <PriceTitleStressed>
              {formatNumber(rentFeeResult.mmRentAmtV - rentFeeResult.mmInitamt)}
              원
            </PriceTitleStressed>
          </FlexBox>
        </div>
      );
    }
    return "";
  };

  return (
    <Modal
      okText={commonStrings.SAVE_ESTIMATION}
      title={commonStrings.MODAL_TITLE_EST_RESULT}
      size="small"
      visible={visible}
      onOpen={onModalOpen}
      onOk={handleSaveEstimation}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
    >
      {renderContents()}
    </Modal>
  );
};

EstimationResultModal.defaultProps = {};
export default EstimationResultModal;
