import React, { useState } from "react";

import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import UsedCarCatalogDetail from "../../../../../../../models/UsedCarCatalogDetail";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import { renderLoading } from "../../../../../../../utils/render-utils";
import { updateUsdCarYearUpd } from "../../../../../../../apis/uc-catalogs";

interface Props extends ModalProps {
  catalogDetail?: UsedCarCatalogDetail;
}

const UsedCarYearTypeModifyModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, catalogDetail, onDataChange } = props;

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [defaultYearType, setDefaultYearType] = useState<string>();

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);
    if (catalogDetail) {
      setDefaultYearType(catalogDetail.yearType);
    } else {
      setDefaultYearType("");
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const forms: Array<FormInfo> = [
    {
      key: "yearType",
      type: FormElementType.Input,
      label: "연식",
      required: true,
    },
  ];

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    setConfirmLoading(true);

    const { yearType } = values;

    if (catalogDetail && yearType !== defaultYearType) {
      const body = {
        modeGrdId: catalogDetail.modeGrdId,
        cossModlId: catalogDetail.cossModlId,
        yearType: values.yearType,
      };

      try {
        await updateUsdCarYearUpd(body);
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
      } catch (e) {
        console.log(e);
      } finally {
        setConfirmLoading(false);
      }
    } else if (onCancel) onCancel();
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      title="연식 변경"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={{ yearType: defaultYearType }}
          forms={forms}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

UsedCarYearTypeModifyModal.defaultProps = {
  visible: true,
};
export default UsedCarYearTypeModifyModal;
