import dompurify from "dompurify";
import { unescape } from "lodash";

class EventDetail {
  // 이벤트순번
  public evntSeq = "";

  // 제목
  public titl = "";

  // 내용
  public contents = "";

  // 이벤트기간여부
  public evntTermYn = "";

  // 이벤트시작일시
  public evntStDt = "";

  // 이벤트종료일시
  public evntEndDt = "";

  // 조회횟수
  public readCnt = "";

  // 사용여부
  public useYn = "";

  // 삭제여부
  public delYn = "";

  // 등록자ID
  public regUser = "";

  // 등록일시
  public regDtm = "";

  // 수정자ID
  public updUser = "";

  // 수정일시
  public updDtm = "";

  // 이미지 경로
  public imgPath = "";

  // 이벤트분류명
  public evntClfNm = "";

  // 상단고정여부
  public topFixedYn = "";

  // 응모내역 파기일
  public winDtm = "";

  get htmlContent() {
    return dompurify.sanitize(unescape(this.contents));
  }
}

export default EventDetail;
