import { FormikValues } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { FormElementType } from "../../../../../../components/shared/data-entry/FormBuilder/types";
import errorMessages from "../../../../../../constants/errors";
import GradeRate from "../../../../../../models/GradeRate";
import { getParamFromDateRange } from "../../../../../../utils/form-utils";
import {CUST_CLS_OPTIONS} from "../../../../../../constants/enums";

export const createInitialValues = () => {
  return {
    custClsCd: null,
    grd1: "",
    grd2: "",
    grd3: "",
    grd4: "",
    grd5: "",
    grd6: "",
    grd7: "",
    apyDateRange: null,
  };
};

export const createInitialValuesFromGradeRate = (gradeRate: GradeRate) => {
  const {
    custClsCd,
    grd1,
    grd2,
    grd3,
    grd4,
    grd5,
    grd6,
    grd7,
    apyStDt,
    apyEndDt,
  } = gradeRate;

  const initialApyDate = [moment(apyStDt), moment(apyEndDt)];

  return {
    custClsCd,
    grd1,
    grd2,
    grd3,
    grd4,
    grd5,
    grd6,
    grd7,
    apyDateRange: initialApyDate,
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    custClsCd: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    grd1: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .max(100, "100이하 숫자를 입력해주세요")
      .min(0, "0이상의 숫자를 입력해주세요"),
    grd2: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .max(100, "100이하 숫자를 입력해주세요")
      .min(0, "0이상의 숫자를 입력해주세요"),
    grd3: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .max(100, "100이하 숫자를 입력해주세요")
      .min(0, "0이상의 숫자를 입력해주세요"),
    grd4: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .max(100, "100이하 숫자를 입력해주세요")
      .min(0, "0이상의 숫자를 입력해주세요"),
    grd5: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .max(100, "100이하 숫자를 입력해주세요")
      .min(0, "0이상의 숫자를 입력해주세요"),
    grd6: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .max(100, "100이하 숫자를 입력해주세요")
      .min(0, "0이상의 숫자를 입력해주세요"),
    grd7: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .max(100, "100이하 숫자를 입력해주세요")
      .min(0, "0이상의 숫자를 입력해주세요"),
    apyDateRange: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(2, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createAddGradeRateParam = (values: FormikValues) => {
  const {
    custClsCd,
    grd1,
    grd2,
    grd3,
    grd4,
    grd5,
    grd6,
    grd7,
    apyDateRange,
  } = values;

  let apyStDt = "";
  let apyEndDt = "";
  if (apyDateRange && apyDateRange.length > 0) {
    const { startDateParam, endDateParam } = getParamFromDateRange(
      apyDateRange
    );
    apyStDt = startDateParam as string;
    apyEndDt = endDateParam as string;
  }

  return {
    custClsCd,
    grd1: grd1 as number,
    grd2: grd2 as number,
    grd3: grd3 as number,
    grd4: grd4 as number,
    grd5: grd5 as number,
    grd6: grd6 as number,
    grd7: grd7 as number,
    apyStDt,
    apyEndDt,
  };
};

export const createFormInfo = () => {
  return [
    {
      // 사업자구분
      key: "custClsCd",
      type: FormElementType.Select,
      options: CUST_CLS_OPTIONS,
      placeholder: "사업자유형을 선택해주세요",
      label: "사업자구분",
      required: true,
    },
    // 기간설정
    {
      key: "apyDateRange",
      type: FormElementType.DateRangePicker,
      label: "기간설정",
      placeholder: "적용기간을 입력해주세요",
      required: true,
    },
    // 1등급
    {
      key: "grd1",
      type: FormElementType.InputNumber,
      label: "1등급",
      placeholder: "보증보험 수수료율 입력",
      required: true,
    },
    // 2등급
    {
      key: "grd2",
      type: FormElementType.InputNumber,
      label: "2등급",
      placeholder: "보증보험 수수료율 입력",
      required: true,
    },
    // 3등급
    {
      key: "grd3",
      type: FormElementType.InputNumber,
      label: "3등급",
      placeholder: "보증보험 수수료율 입력",
      required: true,
    },
    // 4등급
    {
      key: "grd4",
      type: FormElementType.InputNumber,
      label: "4등급",
      placeholder: "보증보험 수수료율 입력",
      required: true,
    },
    // 5등급
    {
      key: "grd5",
      type: FormElementType.InputNumber,
      label: "5등급",
      placeholder: "보증보험 수수료율 입력",
      required: true,
    },
    // 6등급
    {
      key: "grd6",
      type: FormElementType.InputNumber,
      label: "6등급",
      placeholder: "보증보험 수수료율 입력",
      required: true,
    },
    // 7등급
    {
      key: "grd7",
      type: FormElementType.InputNumber,
      label: "7등급",
      placeholder: "보증보험 수수료율 입력",
      required: true,
    },
  ];
};
