import styled from "styled-components";

export const StyledTable = styled.table`
  th,
  td {
    color: #19191e;
    padding: 12px 16px;
    width: 162px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  th {
    font-weight: 700;
    background: #fafafc;
  }
  tr {
    box-shadow: inset 0px -1px 0px #eeeff4;

    &.rate-control {
      input {
        width: 80px;
      }
    }
  }
  thead {
  }
  tbody {
  }
`;
