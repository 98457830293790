export default class NiceCarInfoDetail {
  // MODE 등급 ID
  public modeGrdId = "";

  // NICE 등급 ID
  public carGradeNbr = "";

  // MODE 등급 ID 등록일
  public modeMpnDt = "";

  // COSS Model ID
  public cossModlId = "";

  // COSS Model ID 등록일
  public cossMpnDt = "";

  // 제조사
  public brandNm = "";

  // 특판여부
  public aheadYn = "";

  // 차종
  public repCarClassNm = "";

  // 세부모델
  public carClassNm = "";

  // 세부모델(영문)
  public carClassNmEng = "";

  // 등급(트림)
  public carGradeNm = "";

  // 등급(트림) 영문
  public carGradeNmEng = "";

  // Custom 트림명
  public carGradeCustomNm = "";

  // 연식
  public yearType = "";

  // 차급
  public carSize = "";

  // 외형
  public extShape = "";

  // 장착변속기
  public istdTrans = "";

  // 연료
  public fuel = "";

  // 출시일자
  public releaseDt = "";

  // MODE 차량가격
  public carGradePrice = 0;

  // COSS 차량가격
  public carAmt = 0;

  // 전장
  public olen = "";

  // 전폭
  public owdth = "";

  // 전고
  public ohigh = "";

  // 배기량
  public engdisp = "";

  // 연비
  public fleffcncy = "";

  // 공차중량
  public tlrnWght = "";

  // 이미지
  public repImageUrl = "";
}
