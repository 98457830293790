import React, { useState } from "react";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import {FormElementType, FormInfo} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {renderLoading} from "../../../../../../../utils/render-utils";
import {FormikProps, FormikValues} from "formik";
import {updateUcCooperCompanyCond} from "../../../../../../../apis/auc-master";
import AucCooperDetail from "../../../../../../../models/AucCooperDetail";
import {  
  CooperDetailRequest,
  commCdList
} from "../../../../../../../apis/auc-master/types";

interface Props extends ModalProps {
  aucCooperDetail?: AucCooperDetail;
}

const CntrTermMmListModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, aucCooperDetail, onDataChange } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [defaultCompCd, setDefaultCompCd] = useState<string>();
  const [default1, setDefault1] = useState<boolean>();
  const [default2, setDefault2] = useState<boolean>();
  const [default3, setDefault3] = useState<boolean>();
  const [default4, setDefault4] = useState<boolean>();


  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);

    if (aucCooperDetail) {
      setDefaultCompCd(aucCooperDetail.compCd);
      setDefault1(aucCooperDetail.cntrTermMmList[0].useYn == "Y" ? true : false);
      setDefault2(aucCooperDetail.cntrTermMmList[1].useYn == "Y" ? true : false);
      setDefault3(aucCooperDetail.cntrTermMmList[2].useYn == "Y" ? true : false);
      setDefault4(aucCooperDetail.cntrTermMmList[3].useYn == "Y" ? true : false);
    } else {
      setDefaultCompCd("");
      setDefault1(false);
      setDefault2(false);
      setDefault3(false);
      setDefault4(false);
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const forms: Array<FormInfo> = [
    {      key: "A19007",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "12개월",        }, 
    {      key: "A19002",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "24개월",        },
    {      key: "A19004",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "36개월",        },
    {      key: "A19005",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "48개월",        },
  ]

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
      setConfirmLoading(true);
      const commCdList: commCdList[] = [];
      if(values.A19007) {  const commCd = { condCd: "A19007" as string, condVal: "0" as string  }; commCdList.push(commCd); }
      if(values.A19002) {  const commCd = { condCd: "A19002" as string, condVal: "0" as string  }; commCdList.push(commCd); }
      if(values.A19004) {  const commCd = { condCd: "A19004" as string, condVal: "0" as string  }; commCdList.push(commCd); }
      if(values.A19005) {  const commCd = { condCd: "A19005" as string, condVal: "0" as string  }; commCdList.push(commCd); }

      const params: CooperDetailRequest = {
        condClsCd: "A17001",
        condCdList: commCdList,
      };

      if (aucCooperDetail) {
        try {
          await updateUcCooperCompanyCond(defaultCompCd as string, params);
          if (onDataChange) onDataChange();
          if (onCancel) onCancel();
        } catch (e) {          
          console.log(e);
        } finally {
          setConfirmLoading(false);
        }
      } else {
        setConfirmLoading(false);
        if (onCancel) onCancel();
      }
  }; 

  /**
   * Render Helpers
   */


  return (
    <Modal
      size="small"
      title=" 계약기간 변경"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? (
          renderLoading()
      ) : (
          <FormBuilder
              formRef={(ref) => {
                formik = ref;
              }}
              initialValues={
                {
                  A19007: default1,
                  A19002: default2,
                  A19004: default3,
                  A19005: default4,
                }
              }
              forms={forms}
              onSubmit={handleSubmit}
          />
      )}
    </Modal>
  );
};

CntrTermMmListModal.defaultProps = {
  visible: true,
};
export default CntrTermMmListModal;
