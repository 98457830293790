import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useEffect, useState } from "react";

interface CustomNoteSectionProps {
    usernoteText?: string;
    onClickNoteButton: (usernote:string) => void;
  }

const CustomNoteSection: React.FC<CustomNoteSectionProps> = (props) => {

    const { usernoteText, onClickNoteButton  } = props;
    const [usernote, setUsernote] = useState(""); 

    useEffect(() => {
        if(!usernoteText) setUsernote("사용자 비고");
        else 
            setUsernote(usernoteText);
    }, []);

    return (
        <div>
          <TextArea rows={4} value={usernote} onChange={e => setUsernote(e.target.value)}
          onFocus={(e)=>e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
          />

          <Button
            type="primary"
            onClick={() => {
              onClickNoteButton(usernote);
            }}
            style={{
              float: "right",
              margin: "10px"
            }}
          >
            업데이트
          </Button>
        </div>
    );
  };

  export default CustomNoteSection;