import NewCarCatalogCarGrade from "./NewCarCatalogCarGrade";

class NewCarCatalog {
  // 상품ID
  public prodId = "";

  // 서비스ID
  public srvcId = "";

  // 상품명
  public prodNm = "";

  // 상품설명
  public prodDesc = "";

  // 대표 차량등급 ID
  public modeGrdId = "";

  // 대표 기준 렌탈료
  public repRentAmt = "";

  // 대표 제조사명
  public repMakerNm = "";

  // 대표 차명
  public repCartypeNm = "";

  // 대표 차급
  public repCarSizeNm = "";

  // 대표 연료
  public repFuelNm = "";

  // 대표 차량 외관 이미지
  public repCarImg = "";

  // 신차장기 판매구분
  public ncarClsFlag = "";

  // 신차장기 판매구분명
  public ncarClsFlagNm = "";

  // 사용여부
  public useYn = "";

  // 노출순번
  public viewSeq: string | null = "";

  // 등록자
  public regUser = "";

  // 등록일시
  public regDtm?: Date;

  // (NICE AB)등급명
  public carGradeNm = "";

  // 차종명
  public repCarClassNm = "";

  // 차종명
  public carClassNm = "";

  // 연형
  public yearType = "";

  // 제조사명
  public brandNm = "";

  // 트림수(판매적용수)
  public prodApyCnt = "";

  // 재고수
  public stockCnt = "";

  // 단산 트림 수
  public dnsnCnt = 0;
}
export default NewCarCatalog;
