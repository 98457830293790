import styled from "styled-components";

export const ContentLayoutWrapper = styled.div`
  position: relative;
  padding: 20px;
  overflow: auto;
  flex-grow: 1;

  // safari bug
  height: 0;
`;
