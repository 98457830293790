import React, { useState } from "react";
import moment from "moment";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../types";
import commonStrings from "../../../../../constants/strings";
import { fetchGradeRates } from "../../../../../apis/grade-rate-manager";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import {
  formatCurrency,
  getErrorMessage,
} from "../../../../../utils/common-utils";
import GradeRate from "../../../../../models/GradeRate";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import { defaultFormItemLayout } from "../../../../../components/shared/data-entry/FormBuilder";
import Select from "../../../../../components/shared/data-entry/antd/Select";
import InputNumber from "../../../../../components/shared/data-entry/antd/InputNumber";
import { Divider, FlexBox, PriceTitleStressed, PriceLabel } from "./style";
import { CUST_CLS_OPTIONS } from "../../../../../constants/enums";

interface Props extends ModalProps {}

const GradeRateSimulatorModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel } = props;
  const [rows, setRows] = useState<Array<GradeRate> | undefined>(undefined);
  const [dataFetching, setDataFetching] = useState(false);
  const [creditRate, setCreditRate] = useState<number | null>(null);
  const [custType, setCustType] = useState<string | null>(null);
  const [price, setPrice] = useState<string | number>("");
  /**
   * Private Functions
   */

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchGradeRates();
    setDataFetching(false);
  };

  const requestFetchGradeRates = async () => {
    try {
      const gradeRates = await fetchGradeRates();
      const { items } = gradeRates;
      setRows(items);
    } catch (e) {
      if (e.status !== 404) {
        alertError(getErrorMessage(e));
      }
      setRows([]);
    }
  };

  const createCreditOptions = () => {
    const options = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < 8; i++) {
      options.push({
        label: `${i}등급`,
        value: i,
      });
    }
    return options;
  };

  const getFeeText = () => {
    if (creditRate && custType && price) {
      if (rows) {
        const filtered = rows.filter((gradeRate) => {
          const sameCustCls = gradeRate.custClsCd === custType;
          const inDateRange = moment().isBetween(
            moment(gradeRate.apyStDt),
            moment(gradeRate.apyEndDt)
          );
          return sameCustCls && inDateRange;
        })[0];

        let rate;
        if (filtered) {
          if (creditRate === 1) {
            rate = Number(filtered.grd1);
          }
          if (creditRate === 2) {
            rate = Number(filtered.grd2);
          }
          if (creditRate === 3) {
            rate = Number(filtered.grd3);
          }
          if (creditRate === 4) {
            rate = Number(filtered.grd4);
          }
          if (creditRate === 5) {
            rate = Number(filtered.grd5);
          }
          if (creditRate === 6) {
            rate = Number(filtered.grd6);
          }
          if (creditRate === 7) {
            rate = Number(filtered.grd7);
          }

          if (rate) {
            return `${formatCurrency((Number(price) * rate) / 100)}`;
          }
        } else {
          return "등록된 정보가 없습니다";
        }
      }
    }

    return "-";
  };

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  const renderForms = () => {
    const style = {
      marginBottom: "16px",
    };

    return (
      <div>
        <FormItem
          {...defaultFormItemLayout}
          label={commonStrings.LABEL_CUSTOMER_TYPE}
          required
          style={style}
        >
          <Select
            placeholder={commonStrings.HINT_CUSTOMER_TYPE}
            value={custType}
            options={CUST_CLS_OPTIONS}
            onChange={(value) => {
              setCustType(value);
            }}
          />
        </FormItem>
        <FormItem
          {...defaultFormItemLayout}
          label={commonStrings.LABEL_CREDIT_RATE}
          required
          style={style}
        >
          <Select
            options={createCreditOptions()}
            placeholder={commonStrings.HINT_CREDIT_RATE}
            value={creditRate}
            onChange={(value) => {
              setCreditRate(value);
            }}
          />
        </FormItem>
        <FormItem
          {...defaultFormItemLayout}
          label={commonStrings.LABEL_INSURANCE_SIGN_IN_PRICE}
          required
          style={style}
        >
          <InputNumber
            placeholder={commonStrings.HINT_INSURANCE_SIGN_IN_PRICE}
            style={{
              width: "100%",
            }}
            value={price}
            onChange={(value) => {
              setPrice(value);
            }}
          />
        </FormItem>
        <Divider />
        <FlexBox>
          <PriceLabel>{commonStrings.LABEL_INSURANCE_FEE}</PriceLabel>
          <PriceTitleStressed>{getFeeText()}</PriceTitleStressed>
        </FlexBox>
      </div>
    );
  };

  return (
    <Modal
      size="small"
      title={commonStrings.MODAL_TITLE_GRADE_RATE_SIMULATOR}
      description={commonStrings.MODAL_DESC_GRADE_RATE_SIMULATOR}
      visible={visible}
      onOpen={onModalOpen}
      onCancel={onCancel}
      footer={null}
    >
      {dataFetching ? renderLoading() : renderForms()}
    </Modal>
  );
};

GradeRateSimulatorModal.defaultProps = {};
export default GradeRateSimulatorModal;
