import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../../constants/strings";
import { formatDateString } from "../../../../utils/date-utils";

export const USE_YN_STATE_RENDERER = "useYnStateRenderer";

export const createEventCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", commonStrings.REG_DATE, FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // 부재자
    createTableCol("userNm", commonStrings.OFF_CAR_MANAGER, FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
    }),
    // 대무자
    createTableCol(
      "daUserNm",
      commonStrings.REPLACE_CAR_MANAGER,
      FilterType.Text,
      {
        minWidth: CW_SMALL,
        flex: 1,
      }
    ),
    // 부재기간
    createTableCol("dateRange", commonStrings.OFF_DAY_RANGE, undefined, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: (params) => {
        const { data } = params;
        const { stDt, stEndDt } = data;
        return `${formatDateString(stDt)} ~ ${formatDateString(stEndDt)}`;
      },
    }),
    // 사용유무
    createTableCol("useYn", commonStrings.USE_YN, FilterType.Text, {
      cellRenderer: USE_YN_STATE_RENDERER,
      width: CW_MEDIUM,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
