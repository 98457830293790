import { plainToClass } from "class-transformer";
import { ROOT_URL } from "../../network/api-urls";
import { HttpMethod, request } from "../../network/request";
import ConsultingData from "../../models/ConsultingData/ConsultingData";
import ConsultingDetail from "../../models/ConsultingData/ConsultingDetail";
import {
  INT_CONSULTING_CATEGORY_OPTION,
  INT_CONSULTING_CATEGORY_TRIM,
} from "../../constants/enums";

const apiRootUrl = `${ROOT_URL}/admin/custcnte/intlcnsl`;

/**
 * 지능형 상담 순위 변경
 */

interface EditConsultingDataParam {
  cnslCls: string;
  btchNo: string;
  btchSeq: string;
  mngrRnk: number;
  autoYn: string;
}

export const editConsultingDataRank = async (
  params: EditConsultingDataParam[]
) => {
  await request(HttpMethod.Post, apiRootUrl, undefined, params);
};

/**
 * 지능형 상담 집계 리스트 조회
 */
export const fetchConsultingData = async (): Promise<ConsultingData> => {
  const res = await request(HttpMethod.Get, apiRootUrl);

  const { data } = res;
  const { response } = data;
  const { aggrList, mstList, dtlList } = response;

  const converted = {
    aggrList: aggrList.aggrList,
    aggrListCnt: aggrList.aggrListCnt,
    mstList: mstList.mstList,
    mstListCnt: mstList.mstListCnt,
    dtlList: dtlList.dtlList,
    dtlListCnt: dtlList.dtlListCnt,
  };

  return plainToClass(ConsultingData, converted);
};

/**
 * 지능형 상담 집계 상세 데이터 조회
 */
export const fetchConsultingDataDetail = async (
  targetCnslCls: string,
  targetCartypeNm: string
): Promise<ConsultingDetail[]> => {
  const res = await request(HttpMethod.Get, apiRootUrl);

  const { data } = res;
  const { response } = data;
  const { dtlList } = response;

  const details = plainToClass(ConsultingDetail, dtlList.dtlList as JSON[]);

  return details.filter(({ cnslCls, cond1 }) => {
    if (
      targetCnslCls === INT_CONSULTING_CATEGORY_TRIM ||
      targetCnslCls === INT_CONSULTING_CATEGORY_OPTION
    ) {
      return targetCnslCls === cnslCls && targetCartypeNm === cond1;
    }
    return targetCnslCls === cnslCls;
  });
};
