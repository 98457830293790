import { ROOT_URL } from "../../network/api-urls";
import { HttpMethod, request } from "../../network/request";

const apiRootUrl = `${ROOT_URL}/admin/log`;

/**
 * 엑셀 다운로드 로그 저장
 */
interface SetDownloadLogsBodyProps {
  userId: string;
  userIp: string;
  methodNm: string;
  menuName: string;
  downRsn: string;
  reqCntn?: string;
}

export const setDownloadLogs = async (body: SetDownloadLogsBodyProps) => {
  const res = await request(
    HttpMethod.Post,
    `${apiRootUrl}/file`,
    undefined,
    body
  );

  return res;
};

/**
 * 접속 로그 저장
 */
interface SetConnectLogsBodyProps {
  userId: string;
  userIp: string;
  methodNm: string;
  menuName: string;
  actionFlag: string;
  reqCntn?: string;
}

export const setConnectLogs = async (body: SetConnectLogsBodyProps) => {
  const res = await request(HttpMethod.Post, `${apiRootUrl}`, undefined, body);

  return res;
};
