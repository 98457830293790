import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_HEADER_EVENT_MANAGER: "이벤트",
    TABLE_HEADER_EVENT_LIST: "이벤트 리스트",

    // 테이블 컬럼
    COL_EVENT_NO: "게시번호",
    COL_EVENT_ORDER_NO: "게시순번",
    COL_REGISTERED_AT: "등록일",
    COL_EVENT_KIND: "이벤트유형",
    COL_EVENT_CATEGORY: "이벤트구분",
    COL_TITLE: "이벤트제목",
    COL_EVENT_START_DATE: "시작일시",
    COL_EVENT_END_DATE: "종료일시",
    COL_EVENT_WRITER: "작성자",

    // Form
    LABEL_IS_FIXED: "최상단 고정",
    LABEL_EVENT_KIND: "이벤트유형",
    HINT_EVENT_KIND: "이벤트유형을 선택해주세요",
    LABEL_EVENT_CATEGORY: "이벤트유형",
    LABEL_EVENT_TERM: "게시일",
    ERROR_EVENT_TERM: "게시일을 선택해주세요",
    HINT_EVENT_CATEGORY: "이벤트구분을 선택해주세요",
    LABEL_EVENT_TITLE: "제목",
    HINT_EVENT_TITLE: "제목을 입력해주세요",
    LABEL_EVENT_CONTENT: "내용",
    HINT_EVENT_CONTENT: "내용을 입력해주세요",
    LABEL_EVENT_FILE_UPLOAD: "파일첨부",

    FEEDBACK_ADD_EVENT: "이벤트가 등록되었습니다",
    FEEDBACK_EDIT_EVENT: "이벤트 정보가 변경되었습니다",

    TITLE_ACTION_ADD: "이벤트 등록",
    TITLE_ACTION_DELETE: "선택 삭제",

    CONFIRM_TITLE_DELETE_EVENT: "이벤트 삭제",
    CONFIRM_MESSAGE_DELETE_EVENT: "이벤트을 삭제하시겠습니까?",

    MODAL_TITLE_ADD_EVENT: "이벤트 등록",
    MODAL_DESCRIPTION_ADD_EVENT:
      "이벤트은 필수사항 입력 후 미리보기를 통해 확인하실 수 있습니다. 최상단 고정은 게시판 상단에 고정되는 게시물을 의미합니다.",
    MODAL_TITLE_EVENT_DETAIL: "이벤트 수정",
  },
});
