import React, { useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build-by-ryan/build/ckeditor";
import { EditorUploadAdapterPlugin } from "./ImageUploader";
import "./style.css";
import UploadCode from "../../../../models/UploadCode";
import { UPLOAD_CODES } from "../../../../constants/enums";

export interface EditorProps {
  data?: string;
  onChange?: (data: any) => void;
  uploadInfo?: UploadCode;
}

const HtmlEditor: React.FC<EditorProps> = (props: EditorProps) => {
  const { data: initialHtmlData, onChange, uploadInfo } = props;
  const editorRef = useRef(null);

  const editorConfig = {
    extraPlugins: [EditorUploadAdapterPlugin],
    alignment: {
      options: ["left", "right"],
    },
    toolbar: {
      items: [
        "heading",
        "alignment",
        "bold",
        "imageTextAlternative",
        "link",
        "selectAll",
        "fontBackgroundColor",
        "fontColor",
        "fontSize",
        "uploadImage",
        "resizeImage:original",
        "linkImage",
        "imageResize",
        "imageStyle:full",
        "imageStyle:side",
        "indent",
        "outdent",
        "italic",
        "numberedList",
        "bulletedList",
        "mediaEmbed",
        "insertTable",
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "undo",
        "redo",
      ],
      shouldNotGroupWhenFull: true,
    },
    // 업로드로더 API 요청 파라미터 전송
    uploadInfo,
  };

  return (
    <CKEditor
      ref={editorRef}
      editor={ClassicEditor}
      config={editorConfig}
      data={initialHtmlData}
      onChange={(event: any, editor: { getData: () => any }) => {
        const data = editor.getData();
        if (onChange) onChange(data);
      }}
    />
  );
};

HtmlEditor.defaultProps = {
  data: "",
  uploadInfo: UPLOAD_CODES.NOTICE,
};

export default HtmlEditor;
