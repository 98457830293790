import { FormikValues } from "formik";
import * as Yup from "yup";
import {FormElementType, FormOption} from "../../../../../../components/shared/data-entry/FormBuilder/types";

import errorMessages from "../../../../../../constants/errors";
import CarManagerDetail, {
  SiteListInfo,
} from "../../../../../../models/CarManagerDetail";

export const createInitialValues = () => {
  return {
    siteCd: [],
  };
};

export const createInitialValuesFromCarManager = (
  carManagerDetail: CarManagerDetail
) => {
  return {
    userName: carManagerDetail.userNm,
    siteCd: carManagerDetail.carManDtlList.map(({ siteCd }) => {
      return siteCd;
    }),
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    siteCd: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(1, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createCarManagerParam = (values: FormikValues) => {
  const { siteCd } = values;
  return {
    siteCd,
  };
};

export const createFormInfo = (cossSiteOptions: Array<FormOption>) => {
  return [
    {
      // 직원
      key: "userName",
      type: FormElementType.Input,
      label: "직원명",
      required: true,
      disabled: true,
      placeholder: "직원을 선택해주세요",
    },
    {
      // 담당지역
      key: "siteCd",
      type: FormElementType.Select,
      label: "담당지역",
      required: true,
      placeholder: "담당지역을 선택해주세요",
      options: cossSiteOptions,
      config: {
        mode: "multiple",
      },
    },
  ];
};
