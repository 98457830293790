import React, { useState } from "react";

import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import TagoCarCatalogDetail from "../../../../../../../models/TagoCarCatalogDetail";
import {FormElementType, FormInfo} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {alertError, renderLoading} from "../../../../../../../utils/render-utils";
import {FormikProps, FormikValues} from "formik";
import {editTbAmount} from "../../../../../../../apis/tb-catalogs";
import { showConfirm } from "../../../../../../../components/shared/feedback/Confirm";
import { alertSuccess } from './../../../../../../../utils/render-utils';

interface Props extends ModalProps {
  catalogDetail?: TagoCarCatalogDetail;
}

const TagoCarProdAmountModifyModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, catalogDetail, onDataChange } = props;

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [defaultCarId, setDefaultCarId] = useState<string>();
  const [defaultTkvAmt, setDefaultTkvAmt] = useState<number>();
  const [defaultAvgMinTkvAmt, setDefaultAvgMinTkvAmt] = useState<number>();
  const [defaultAvgMaxTkvAmt, setDefaultAvgMaxTkvAmt] = useState<number>();

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);
    if (catalogDetail) {
      setDefaultCarId(catalogDetail.carId);
      setDefaultTkvAmt(catalogDetail.tkvAmt);
      setDefaultAvgMinTkvAmt(catalogDetail.avgMinTkvAmt);
      setDefaultAvgMaxTkvAmt(catalogDetail.avgMaxTkvAmt);
    } else {
      setDefaultCarId("");
      setDefaultTkvAmt(0);
      setDefaultAvgMinTkvAmt(0);
      setDefaultAvgMaxTkvAmt(0);
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const forms: Array<FormInfo> = [
    {
      key: "tkvAmt",
      type: FormElementType.Input,
      label: "차량인수가",
      required: true,
    },
    {
      key: "avgMinTkvAmt",
      type: FormElementType.Input,
      label: "최소인수가",
      required: true,
    },
    {
      key: "avgMaxTkvAmt",
      type: FormElementType.Input,
      label: "최대인수가",
      required: true,
    },
  ]

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
      setConfirmLoading(true);

      const {tkvAmt, avgMinTkvAmt, avgMaxTkvAmt} = values;

      const intTkvAmt = Number(tkvAmt);
      const intAvgMinTkvAmt = Number(avgMinTkvAmt);
      const intAvgMaxTkvAmt = Number(avgMaxTkvAmt);

      let message = "";
      if(intTkvAmt > intAvgMinTkvAmt) {
        message = "인수가 금액이 최소인수가 보다 큽니다.";
        alertError(message);
        setConfirmLoading(false);
        return;
      }
      if(intAvgMaxTkvAmt < intAvgMinTkvAmt) {
        message = "최소인수가 금액이 최대인수가 보다 큽니다.";
        alertError(message);
        setConfirmLoading(false);
        return;
      }

      if (catalogDetail) {
        try {
          await editTbAmount(catalogDetail.prodId, catalogDetail.carId, tkvAmt, avgMinTkvAmt, avgMaxTkvAmt);

          if (onDataChange) onDataChange();
          if (onCancel) onCancel();

          alertSuccess("등록되었습니다.");
        } catch (e) {          
          console.log(e);
        } finally {
          setConfirmLoading(false);
        }
      } else {
        setConfirmLoading(false);
        if (onCancel) onCancel();
      }
  }; 

  /**
   * Render Helpers
   */


  return (
    <Modal
      size="small"
      title="차량인수가 변경"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? (
          renderLoading()
      ) : (
          <FormBuilder
              formRef={(ref) => {
                formik = ref;
              }}
              initialValues={{
                carId: defaultCarId,
                tkvAmt: defaultTkvAmt,
                avgMinTkvAmt: defaultAvgMinTkvAmt,
                avgMaxTkvAmt: defaultAvgMaxTkvAmt,
              }}
              forms={forms}
              onSubmit={handleSubmit}
          />
      )}
    </Modal>
  );
};

TagoCarProdAmountModifyModal.defaultProps = {
  visible: true,
};
export default TagoCarProdAmountModifyModal;
