class FAQDetail {
  // FAQ 번호
  public faqNo = "";

  // 대분류 CD
  public llfCd = "";

  // 중분류 CD
  public mlfCd = "";

  // 대분류 명
  public llfNm = "";

  // 중분류 명
  public mlfNm = "";

  // 질문
  public qstn = "";

  // 답변
  public answer = "";

  // 사용여부
  public useYn = "";

  // 삭제여부
  public delYn = "";

  // 메인여부
  public mainYn = "";

  // 변경자
  public updUser = "";

  // 변경일시
  public updDtm = "";

  // 등록자
  public regUser = "";

  // 등록일시
  public regDtm = "";
}
export default FAQDetail;
