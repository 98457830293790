import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import TableLayout from "../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import {
  createTransferRequestCols,
  STATE_PROCESS_RENDERER,
} from "./utils/table-utils";
import { alertError } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { fetchCarManagerTransferRequests } from "../../../apis/car-managers";
import CarManagerTransferRequest from "../../../models/CarManagerTransferRequest";
import commonStrings from "../../../constants/strings";
import CarManagerTransferRequestDetailModal from "./pages/CarManagerTransferRequestDetailModal";
import { ENUM_CAR_MANAGER_TRANSFER_STATE_REQUEST } from "../../../constants/enums";
import TableCellButton from "../../../components/shared/composition/TableCellButton";
import { createUserDetailRenderer } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import CustomerModal from "../CustomerManager/pages/CustomerModal";

/**
 * CarManagerTransferRequestManager Table
 */

const CarManagerTransferRequestManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rows, setRows] = useState<CarManagerTransferRequest[]>();
  const [totalCount, setTotalCount] = useState(0);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [custId, setCustId] = useState<string>();
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const [selectedTransferRequest, setSelectedTransferRequest] = useState<
    CarManagerTransferRequest
  >();
  const cols = createTransferRequestCols();

  useEffect(() => {
    (async function fetchData() {
      await requestFetchCarManagerTransferRequests();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestFetchCarManagerTransferRequests = async () => {
    try {
      const transferRequests = await fetchCarManagerTransferRequests();
      const { items, count } = transferRequests;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
      setRows([]);
      setTotalCount(0);
    }
  };

  /**
   * Event Actions
   */

  const handleUserDetailCellClick = (custId?: string) => {
    if (!custId) {
      alertError(`${custId} 고객 아이디가 없습니다.`);
    } else {
      setCustId(custId);
      setCustomerModalVisible(true);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <ContentLayout
      headerTitle={commonStrings.HEADER_CAR_MANAGER_TRANSFER_MANAGER}
      description={commonStrings.DESC_CAR_MANAGER_TRANSFER_MANAGER}
    >
      <TableLayout
        gridApi={gridApi}
        title={commonStrings.T_HEADER_CAR_MANAGER_TRANSFER_MANAGER}
        totalCount={totalCount}
        methodName="/admin/carman/tran"
      >
        <ClientRowTable
          apiRef={(api) => {
            setGridApi(api);
          }}
          rowData={rows}
          cols={cols}
          frameworkComponents={{
            ...createUserDetailRenderer(handleUserDetailCellClick),
            [STATE_PROCESS_RENDERER]: (props: ICellRendererParams) => {
              const { data } = props;
              const { tranSts } = data as CarManagerTransferRequest;
              if (tranSts === ENUM_CAR_MANAGER_TRANSFER_STATE_REQUEST) {
                return (
                  <div
                    style={{
                      display: "flex",
                      height: "40px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TableCellButton
                      size="small"
                      ghost
                      onClick={() => {
                        setSelectedTransferRequest(
                          data as CarManagerTransferRequest
                        );
                        setDetailModalVisible(true);
                      }}
                      label="요청처리"
                    />
                  </div>
                );
              }

              return "";
            },
          }}
        />
      </TableLayout>
      <CarManagerTransferRequestDetailModal
        carManagerTransferRequest={selectedTransferRequest}
        visible={detailModalVisible}
        onCancel={() => {
          setDetailModalVisible(false);
        }}
        onDataChange={async () => {
          await requestFetchCarManagerTransferRequests();
        }}
      />
      <CustomerModal
        id={custId}
        visible={customerModalVisible}
        onCancel={() => {
          setCustomerModalVisible(false);
        }}
      />
    </ContentLayout>
  );
};

CarManagerTransferRequestManager.defaultProps = {};
export default CarManagerTransferRequestManager;
