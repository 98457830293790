import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import ContractUpdateRequest from "../../../../models/ContractUpdateRequest";

export const createCols = (): Array<TableColumn> => {
  return [
    // 요청일
    createTableCol("changeReqDt", "요청일", undefined, {
      width: CW_SMALL_M,
      cellRenderer: dateFormatRenderer("changeReqDt"),
    }),
    // 회원명
    // createUserDetailButtonCol(),
    createTableCol("custNm", "회원명", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 차량번호
    createTableCol("carNo", "차량번호", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 계약유형
    createTableCol("custClsNm", "계약유형", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 담당 카매니저
    createTableCol("carmanNm", "담당 카매니저", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 변경요청 항목
    createTableCol("changeRecptNm", "변경요청 항목", FilterType.Text, {
      width: CW_LARGE,
    }),
    // 변경 전
    createTableCol("changeBfCntn", "변경 전", FilterType.Text, {
      flex: 1,
      minWidth: 300,
      cellRenderer: (params) => {
        const updateRequest = params.data as ContractUpdateRequest;
        return updateRequest.changeBfCntn.replace("\n", "<br/>");
      },
    }),
    // 변경 후
    createTableCol("changeAfCntn", "변경 후", FilterType.Text, {
      flex: 1,
      minWidth: 300,
      cellRenderer: (params) => {
        const updateRequest = params.data as ContractUpdateRequest;
        return updateRequest.changeAfCntn.replace("\n", "<br/>");
      },
    }),
    // 처리상태
    createTableCol("changePrgsStsNm", "처리상태", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 계약번호
    createTableCol("cntrNo", "계약번호", FilterType.Text, {
      width: CW_LARGE,
    }),
    // {
    //   ...createActionCol(80),
    // },
  ];
};
