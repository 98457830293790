import React from "react";
import { hot } from "react-hot-loader/root";
import { ConfigProvider } from "antd";
import koKr from "antd/lib/locale/ko_KR";
import ReactModal from "react-modal";
import { ThemeProvider } from "styled-components";
import AppRouter from "./routes/components/AppRouter/AppRouter";
import theme from "./components/shared/theme";
import InitialDataLoader from "./components/shared/composition/InitialDataLoader";
import "antd/dist/antd.less";

ReactModal.setAppElement("#root");

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <InitialDataLoader>
        <ConfigProvider locale={koKr}>
          <AppRouter />
        </ConfigProvider>
      </InitialDataLoader>
    </ThemeProvider>
  );
};

const hotApp = hot(App) as any;

export default hotApp;
