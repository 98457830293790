import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../types";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import {
  FormElementType,
  FormInfo,
} from "../../../../../components/shared/data-entry/FormBuilder/types";
import { alertError, alertSuccess } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import errorMessages from "../../../../../constants/errors";
import TagoCeftDetail from "../../../../../models/TagoCeftDetail";
import { updateUcTagobuyWrthCeftList } from "../../../../../apis/tago-ceft";
import { HeaderWrapper, HeaderTitle } from "./style";

interface Props extends ModalProps {
  ceftInfo?: TagoCeftDetail;
}

const YearCeftDetailModal: React.FC<Props> = (props: Props) => {
  const { ceftInfo, visible, onCancel } = props;
  
  let formik: FormikProps<FormikValues>;
  const formInitialValues = {
    yr : ceftInfo?.yr,
    ceft1 : ceftInfo?.ceft1,
    ceft2 : ceftInfo?.ceft2,
    ceft3 : ceftInfo?.ceft3,
    ceft4 : ceftInfo?.ceft4,
    ceft5 : ceftInfo?.ceft5,
    ceft6 : ceftInfo?.ceft6,
    ceft7 : ceftInfo?.ceft7,
    ceft8 : ceftInfo?.ceft8,
    ceft9 : ceftInfo?.ceft9,
    ceft10 : ceftInfo?.ceft10,
    ceft11 : ceftInfo?.ceft11,
    ceft12 : ceftInfo?.ceft12,
    ceft13 : ceftInfo?.ceft13,
  };
  const forms: FormInfo[] = [    
   {
      key: "ceft1",
      label: "0-30 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft2",
      label: "31-60 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft3",
      label: "61-90 Days",
      type: FormElementType.Input,
      placeholder: "0~30일에 해당되는 미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft4",
      label: "91-120 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft5",
      label: "121-150 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft6",
      label: "151-180 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft7",
      label: "181-210 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft8",
      label: "211-240 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft9",
      label: "241-270 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft10",
      label: "271-300 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft11",
      label: "301-330 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft12",
      label: "331-360 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
    {
      key: "ceft13",
      label: "361-999 Days",
      type: FormElementType.Input,
      placeholder: "미래가치계수를 입력하세요",
      required: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    ceft1: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft2: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft3: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft4: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft5: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft6: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft7: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft8: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft9: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft10: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft11: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft12: Yup.string().required(errorMessages.REQUIRED_FIELD),
    ceft13: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });

  const renderHeaderTitle = () => {
    return (
      <HeaderWrapper size="default">
        <HeaderTitle>{ceftInfo?.yr}년 미래가치계수 입력</HeaderTitle>
      </HeaderWrapper>
    );
  };

  const handleSubmit = async (values: FormikValues) => {
    const { 
      ceft1, 
      ceft2,
      ceft3,
      ceft4,
      ceft5,
      ceft6,
      ceft7,
      ceft8,
      ceft9,
      ceft10,
      ceft11,
      ceft12,
      ceft13,
    } = values;
    const yr = ceftInfo?.yr as string;

    try {
      await updateUcTagobuyWrthCeftList(
        yr,
        ceft1, 
        ceft2,
        ceft3,
        ceft4,
        ceft5,
        ceft6,
        ceft7,
        ceft8,
        ceft9,
        ceft10,
        ceft11,
        ceft12,
        ceft13,
      );
      alertSuccess("등록되었습니다.");      
      if (onCancel) onCancel(); 
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {

    }
  };

  return (
    <Modal
      visible={visible}
      title={renderHeaderTitle()}
      size="small"
      onOk={() => {
        formik?.handleSubmit();
      }}
      onCancel={onCancel}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={formInitialValues}
        forms={forms}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default YearCeftDetailModal;
