import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { createNoticeCols } from "./utils/table-utils";
import BannerGroup from "../../../models/BannerGroup";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import { alertError } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { fetchBanners } from "../../../apis/banners";
import BannerDetailModal from "./pages/BannerDetailModal";

const BannerManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [checkedRows, setCheckedRows] = useState<Array<BannerGroup>>([]);
  const [noticeModalVisible, setNoticeModalVisible] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState<
    BannerGroup | undefined
  >();
  const [rows, setRows] = useState<Array<BannerGroup> | undefined>(undefined);
  const [totalCount, setTotalCount] = useState(0);
  const cols = createNoticeCols();

  useEffect(() => {
    (async function fetchData() {
      await requestBannerList();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestBannerList = async () => {
    try {
      const noticeList = await fetchBanners();
      const { items, count } = noticeList;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
      setRows([]);
      setTotalCount(0);
    }
  };

  /**
   * Event Actions
   */

  const handleCheckRowChanged = (rows: Array<BannerGroup>) => {
    setCheckedRows(rows);
  };

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    setSelectedBanner(data as BannerGroup);
    setNoticeModalVisible(true);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout filterable={false} headerTitle="배너 관리">
        <TableLayout
          gridApi={gridApi}
          title="배너 목록"
          totalCount={totalCount}
          selectionCount={checkedRows.length}
          methodName="/admin/banners"
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            onCheckRowChanged={handleCheckRowChanged}
            cols={cols}
            frameworkComponents={{
              ...createActionCellRenderers(undefined, handleEditClick, {
                hideDeleteButton: true,
              }),
            }}
          />
        </TableLayout>
      </ContentLayout>
      <BannerDetailModal
        onDataChange={async () => {
          gridApi?.showLoadingOverlay();
          await requestBannerList();
          gridApi?.hideOverlay();
        }}
        banner={selectedBanner}
        visible={noticeModalVisible}
        onCancel={() => {
          setNoticeModalVisible(false);
        }}
      />
    </>
  );
};

BannerManager.defaultProps = {};
export default BannerManager;
