class Review {
  // 등록일자
  public regDt = "";

  // 고객 아이디
  public custId = "";

  // 고객명
  public custNm = "";

  // 카매니저 명
  public carmanNm = "";

  // 카매니저ID
  public carmanId = "";

  // 상품유형
  public prodPatternNm = "";

  // 계약유형
  public cntrPatternNm = "";

  // 제조사명
  public brandNm = "";

  // 모델명
  public repCartypeNm = "";

  // 세부모델
  public carGradeNm = "";

  // 후기내용
  public crmCntn = "";

  // 별점
  public crmScore = "";

  // 등급
  public crmGrd = "";

  // 이미지 첨부파일 경로
  public fileUrl = "";

  // 숨김 여부
  public hiddenYn = "";

  // 사용 여부
  public useYn = "";

  // 계약 아이디
  public cntrId = "";

  // 신차장기 메인 후기 노출
  public newCarYn = "";

  // 타보증 메인 후기 노출
  public carGrtYn = "";
}

export default Review;
