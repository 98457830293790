import {
  createTableCol,
  FilterType,
} from "../../../../../../../../components/shared/data-display/Tables/model/TableColumn";
import strings from "../../../../../../../car-sales/NewCarCatalogManager/constants/strings";
import {
  CW_LONG_TEXT,
  CW_MEDIUM,
} from "../../../../../../../../utils/table-utils";
import commonStrings from "../../../../../../../../constants/strings";
import {getUcProdType} from "../../../../../../../../constants/enums";

export const createCatalogCols = () => {
  return [
    {
      headerName: "",
      field: "checkItem",
      width: 30,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    // 차량번호
    createTableCol("carNo", strings.COL_CAR_NO, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 상품유형
    createTableCol(
      "ncarClsFlag",
      commonStrings.COL_PROD_TYPE,
      FilterType.Text,
      {
        width: CW_MEDIUM,
        valueGetter: ({ data }) => {
          const { ncarClsFlag } = data;
          
          return getUcProdType(ncarClsFlag) || "-";
        }
      }
    ),
    // 제조사
    createTableCol("repMakerNm", strings.COL_BRAND, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 모델
    createTableCol("repCarTypeNm", strings.COL_MODEL, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 세부 모델
    createTableCol("carClassNm", strings.COL_DETAIL_MODEL, FilterType.Text, {
      minWidth: CW_LONG_TEXT,
      flex: 1,
    }),
  ];
};
