import React from "react";
import { Form, FormItemProps } from "antd";
// import { css } from 'styled-components';
import "./label.css";

// interface CustomLabelProps {
//   whiteSpace?: string;
//   height?: string;
// }
// const CustomLabel = styled.label.attrs<CustomLabelProps>((props) => ({
//   whiteSpace: props.whiteSpace || 'nowrap',
//   }))<CustomLabelProps>`
  // & > label {
  //   height: ${(props) => props.height || '32px'};
  // }
// `;

// const customLabelStyles = css<CustomLabelProps>`
//   white-space: ${(props) => props.whiteSpace || 'nowrap'};
//   & > label {
//     height: ${(props) => props.height || '32px'};
//   }
// `;

interface Props extends FormItemProps {
  isModalLabel?: boolean
}

const FormItem: React.FC<Props> = (props: Props) => {
  const { children, labelCol, wrapperCol, style, labelAlign, isModalLabel, label, ...rest } = props;

  return (
    <Form.Item
      style={{
        marginBottom: 0,
        ...style,
      }}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      {...rest}
      label={label}
      className={isModalLabel ? "custom-label" : ""}
    >
      {children}
    </Form.Item>
  );
};

FormItem.defaultProps = {
  colon: false,
  labelAlign: "left",
};
export default FormItem;
