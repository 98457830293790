import moment from "moment";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_2X_LARGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";
import { formatDateString } from "../../../../utils/date-utils";

export const CONSULTING_STATE_RENDERER = "consultingStateRenderer";

export const createCols = (): Array<TableColumn> => {
  return [
    // 요청일
    createTableCol("regDtm", "상담인입 일시", undefined, {
      minWidth: CW_LARGE,
      flex: 1,
      cellRenderer: (data) => {
        const { value } = data;

        const date = moment(value, "YYYYMMDDHHmmss");
        return formatDateString(date, true);
      },
    }),
    // 상품유형
    createTableCol("ncarClsFlagNm", "상품유형", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
    }),
    // 상품명
    createTableCol("prodDesc", "상품명", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
    }),
    // 지능형상담 유형
    createTableCol("cnslClsNm", "상담유입 단계", FilterType.Text, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // 이름
    createTableCol("custNm", "이름", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
    }),
    // 회원 여부
    createTableCol("custId", "회원 여부", FilterType.Text, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: !!value,
            text: value ? "회원" : "비회원",
          },
        };
      },
    }),
    // 휴대폰 번호
    createTableCol("hpNo", "휴대폰 번호", FilterType.Text, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // 마케팅활용 동의
    createTableCol("mktAgreYn", "마케팅활용 동의", FilterType.Text, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "동의" : "미동의",
          },
        };
      },
    }),
    // 상담 완료 여부
    createTableCol("cnslFinYn", "상담 완료 여부", undefined, {
      minWidth: CW_MEDIUM,
      flex: 1,
      cellRenderer: CONSULTING_STATE_RENDERER,
    }),
    // 차리완료한 상담사
    createTableCol("finClslNm", "완료 상담사", FilterType.Text, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // 처리 완료 일시
    createTableCol("finClslDt", "완료일시", undefined, {
      minWidth: CW_LARGE,
      flex: 1,
      cellRenderer: (data) => {
        const { value } = data;

        if(value === "" || value === undefined || value===null) {
          return "";
        } else {
          const date = moment(value, "YYYYMMDDHHmmss");
          return formatDateString(date, true);
        }
      },
    }),
    // 문의 내용
    createTableCol("custAsk", "문의 내용", FilterType.Text, {
      minWidth: CW_2X_LARGE,
      flex: 1,
    }),
    // 상담사 메모
    createTableCol("cnslPicMemo", "상담사 메모", FilterType.Text, {
      minWidth: CW_2X_LARGE,
      flex: 1,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
