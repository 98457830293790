import React, { useCallback, useState } from "react";
import { FormikProps, FormikValues } from "formik";
import strings from "../../constants/strings";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../types";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  createAddGradeRateParam,
  createFormInfo,
  createInitialValues,
  createInitialValuesFromGradeRate,
  createValidationSchema,
} from "./utils/form-utils";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import GradeRate from "../../../../../models/GradeRate";
import {
  addGradeRate,
  updateGradeRate,
} from "../../../../../apis/grade-rate-manager";

interface Props extends ModalProps {
  gradeRate?: GradeRate;
}

const GradeRateModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, gradeRate, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  let formik: FormikProps<FormikValues>;
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  const onModalOpen = async () => {
    if (gradeRate) {
      setInitialValues(createInitialValuesFromGradeRate(gradeRate));
    } else {
      setInitialValues(createInitialValues());
    }
  };

  /**
   * Private gradeRate
   */

  const requestAddGradeRate = async (values: FormikValues) => {
    setConfirmLoading(true);
    try {
      const params = createAddGradeRateParam(values);
      await addGradeRate(params);
      alertSuccess("보증보험 수수료가 등록되었습니다");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditGradeRate = async (values: FormikValues) => {
    if (gradeRate) {
      setConfirmLoading(true);
      try {
        const params = createAddGradeRateParam(values);
        await updateGradeRate(gradeRate?.insrGrtRtId, params);
        alertSuccess("보증보험 수수료정보가 변경되었습니다");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const getModalTitle = useCallback(() => {
    if (gradeRate) {
      return strings.MODAL_TITLE_DETAIL;
    }
    return strings.MODAL_TITLE_ADD;
  }, [gradeRate]);

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (gradeRate) {
      await requestEditGradeRate(values);
    } else {
      await requestAddGradeRate(values);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      visible={visible}
      title={getModalTitle()}
      onOpen={onModalOpen}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={createFormInfo()}
          validationSchema={createValidationSchema()}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

GradeRateModal.defaultProps = {};
export default GradeRateModal;
