import NewCarCatalogCarGrade from "./NewCarCatalogCarGrade";
import TagMaster from "./TagMaster";
import { SrRentFee } from "./NcRentFeeSet";

interface NewCarTag {
  tagSeq: string;
  tagNm: string;
}

export interface CarVideoInfo {
  videoSeq: string;
  prodId: string;
  videoRate: string;
  videoBtn: string;
  videoUrl: string;
  useYn: string;
}

class NewCarCatalogDetail {
  // 상품ID
  public prodId = "";

  // 서비스ID
  public srvcId = "";

  // 상품명(차명)
  public prodNm = "";

  // 상품설명
  public prodDesc = "";

  // 대표 차량 외관 이미지
  public repCarImg = "";

  // 신차장기 판매구분
  public ncarClsFlag = "";

  // 사용여부
  public useYn = "";

  // 리스트 수
  public listCnt = 0;

  public list: Array<NewCarCatalogCarGrade> = [];

  get isActive() {
    return this.useYn === "Y";
  }

  public tagList: TagMaster[] = [];

  // 비디오 리스트
  public videoList: CarVideoInfo[] = [];

  // 렌탈료 목록
  public srRentFeelist: SrRentFee[] = [];

  // 경상이익율
  public oincRt: string = "";
}
export default NewCarCatalogDetail;
