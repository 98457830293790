import strings from "../constants/strings";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../../constants/strings";
import DiscountMaster from "../../../../models/DiscountMaster";
import {
  ENUM_DC_TYPE_PERCENT,
  ENUM_DC_TYPE_VALUE,
} from "../../../../constants/enums";
import { formatCurrency, formatNumber } from "../../../../utils/common-utils";

export const USE_YN_STATE_RENDERER = "useYnStateRenderer";

export const createEventCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", strings.COL_REGISTERED_AT, FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // 시작일
    createTableCol("apyStDt", commonStrings.START_DATE, undefined, {
      width: CW_SMALL,
      cellRenderer: dateFormatRenderer("apyStDt"),
    }),
    // 종료일
    createTableCol("apyEndDt", commonStrings.END_DATE, undefined, {
      width: CW_SMALL,
      cellRenderer: dateFormatRenderer("apyEndDt"),
    }),
    // 할인항목
    createTableCol("dcClsNm", commonStrings.DC_KIND, FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 할인타입
    createTableCol("dcTypeNm", commonStrings.DC_TYPE, FilterType.Text, {
      width: CW_SMALL,
    }),
    // 할인율(액)
    createTableCol("dcVal", commonStrings.DC_VALUE, FilterType.Text, {
      width: CW_SMALL,
      cellRenderer: (params) => {
        const dcMaster = params.data as DiscountMaster;
        if (dcMaster.dcTypeCd === ENUM_DC_TYPE_PERCENT) {
          return `${dcMaster.dcVal}%`;
        }
        if (dcMaster.dcTypeCd === ENUM_DC_TYPE_VALUE) {
          return formatCurrency(dcMaster.dcVal);
        }
        return "";
      },
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 사용유무
    createTableCol("useYn", commonStrings.USE_YN, FilterType.Text, {
      cellRenderer: USE_YN_STATE_RENDERER,
      width: CW_MEDIUM,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
