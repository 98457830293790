import React, { useState } from "react";
import moment from "moment";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../components/shared/feedback/antd/Modal";

import { ModalProps } from "../../../../types";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import {
  FormElementType,
  FormInfo,
} from "../../../../../components/shared/data-entry/FormBuilder/types";
import { alertError, alertSuccess } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import errorMessages from "../../../../../constants/errors";
import { insertUcCooperCompanyDetail } from "../../../../../apis/auc-master";

interface AddExhibitionModalProps extends ModalProps {}

const AddCooperModal: React.FC<AddExhibitionModalProps> = (
  props: AddExhibitionModalProps
) => {
  let formik: FormikProps<FormikValues>;
  const formInitialValues = {
    compNm: "",
  };

  const forms: Array<FormInfo> = [
    {
      key: "compNm",
      label: "제휴사명",
      type: FormElementType.Input,
      placeholder: "제휴사명을 입력해주세요",
      required: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    compNm: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });

  const { visible, onCancel, onDataChange } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    const { compNm } = values;

    try {
      setConfirmLoading(true);
      await insertUcCooperCompanyDetail({
        compNm,
        compMail : "",
      });
      alertSuccess("제휴사가 등록되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="제휴사 추가"
      size="small"
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={formInitialValues}
        forms={forms}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

AddCooperModal.defaultProps = {
  visible: true,
};
export default AddCooperModal;