import { FormikValues } from "formik";
import * as Yup from "yup";

import FAQDetail from "../../../../../../models/FAQDetail";
import errorMessages from "../../../../../../constants/errors";

export const createInitialValues = () => {
  return {
    // 대분류 CD
    llfCd: null,
    // 중분류 CD
    mlfCd: null,
    // 질문
    qstn: "",
    // 답변
    answer: "",
    mainYn: false,
  };
};

export const createInitialValuesFromFAQ = (faq: FAQDetail) => {
  const { answer, qstn, llfCd, mlfCd, mainYn } = faq;

  return {
    // 대분류 CD
    llfCd: llfCd || null,
    // 중분류 CD
    mlfCd: mlfCd || null,
    // 질문
    qstn: qstn || "",
    // 답변
    answer: answer || "",

    mainYn: mainYn === "Y",
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    // 대분류 CD
    llfCd: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    // 중분류 CD
    mlfCd: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    // 질문
    qstn: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    // 답변
    answer: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createAddFAQParam = (values: FormikValues) => {
  const { llfCd, mlfCd, qstn, answer, mainYn } = values;

  return {
    llfCd,
    mlfCd,
    qstn,
    answer,
    mainYn: mainYn ? "Y" : "N",
  };
};

export const createEditFAQParams = (values: FormikValues) => {
  const { llfCd, mlfCd, qstn, answer, mainYn } = values;

  return {
    llfCd,
    mlfCd,
    qstn,
    answer,
    mainYn: mainYn ? "Y" : "N",
  };
};
