import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
  CW_X_LARGE,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";

export const createCarManagerCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", "등록일", FilterType.Date, {
      width: CW_SMALL_M,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // 성명
    createTableCol("userId", "사번", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 성명
    createTableCol("userNm", "성명", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 전화번호
    createTableCol("carmanHpNo", "전화번호", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 담당지역
    createTableCol("siteNm", "담당지역", FilterType.Text, {
      flex: 1,
      width: CW_X_LARGE,
      minWidth: CW_X_LARGE,
    }),
    // 전체 계약 건수
    createTableCol("cntrCnt", "전체계약", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 인도 완료
    createTableCol("ldCntrCnt", "인도완료", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 인도 예정
    createTableCol("exLdCntrCnt", "인도예정", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 업무여부
    createTableCol("jobYn", "업무여부", FilterType.Text, {
      width: CW_SMALL_M,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "업무중" : "퇴직",
          },
        };
      },
    }),
    // 관리
    {
      ...createActionCol(80),
    },
  ];
};
