import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import strings from "./constants/strings";
import { createGradeRateCols } from "./utils/table-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import TableLayout from "../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import { alertError } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import GradeRate from "../../../models/GradeRate";
import { fetchGradeRates } from "../../../apis/grade-rate-manager";
import GradeRateModal from "./pages/GradeRateModal";
import GradeRateSimulatorModal from "./pages/GradeRateFeeSimulatorModal";

const GradeRateManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gradeRateModalVisible, setGradeRateModalVisible] = useState(false);
  const [
    gradeRateSimulatorModalVisible,
    setGradeRateSimulatorModalVisible,
  ] = useState(false);
  const [gradeRate, setGradeRate] = useState<GradeRate>();
  const cols = createGradeRateCols();
  const [rows, setRows] = useState<Array<GradeRate> | undefined>(undefined);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    (async function fetchData() {
      await requestFetchGradeRates();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestFetchGradeRates = async () => {
    try {
      const faqs = await fetchGradeRates();
      const { items, count } = faqs;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      if (e.status !== 404) {
        alertError(getErrorMessage(e));
      }
      setRows([]);
    }
  };

  const createActions = () => {
    return [
      {
        title: strings.TITLE_COST_CALCULATOR,
        onClick: handleGradeRateSimulatorModal,
      },
      {
        title: strings.TITLE_ACTION_ADD,
        onClick: handleAddGradeRateClick,
      },
    ];
  };

  /**
   * Event Actions
   */

  const handleAddGradeRateClick = () => {
    setGradeRate(undefined);
    setGradeRateModalVisible(true);
  };

  const handleGradeRateSimulatorModal = () => {
    setGradeRateSimulatorModalVisible(true);
  };

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    setGradeRate(data as GradeRate);
    setGradeRateModalVisible(true);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout headerTitle={strings.TITLE_HEADER_GRADE_RATE_MANAGER}>
        <TableLayout
          gridApi={gridApi}
          rightActions={createActions()}
          title={strings.TABLE_HEADER_GRADE_RATE_LIST}
          totalCount={totalCount}
          methodName="/admin/grade-rate-manager"
        >
          <ClientRowTable
            rowData={rows}
            cols={cols}
            frameworkComponents={createActionCellRenderers(
              undefined,
              handleEditClick,
              {
                hideDeleteButton: true,
              }
            )}
            apiRef={(api) => {
              setGridApi(api);
            }}
          />
        </TableLayout>
      </ContentLayout>
      <GradeRateModal
        gradeRate={gradeRate}
        visible={gradeRateModalVisible}
        onCancel={() => {
          setGradeRateModalVisible(false);
        }}
        onDataChange={async () => {
          await requestFetchGradeRates();
        }}
      />
      <GradeRateSimulatorModal
        visible={gradeRateSimulatorModalVisible}
        onCancel={() => {
          setGradeRateSimulatorModalVisible(false);
        }}
      />
    </>
  );
};

GradeRateManager.defaultProps = {};
export default GradeRateManager;
