import React from "react";
import { ICellRendererParams } from "ag-grid-community";

import TableCellButton from "../components/shared/composition/TableCellButton";

export type ActionCellClickListener = (
  renderParams: ICellRendererParams,
  data: any
) => void;

export type ActionRendererOptions = {
  hideDeleteButton?: boolean;
  deleteButtonText?: string;
  deleteButtonDisabled?: boolean;
};

const DEFAULT_ACTION_RENDERER_OPTIONS = {
  hideDeleteButton: false,
  deleteButtonText: "삭제",
  deleteButtonDisabled: false,
};

export const CELL_ACTION_RENDERER = "actionRenderer";

const createActionColRenderer = (
  props: ICellRendererParams,
  onDeleteClick: (e: React.MouseEvent) => void,
  onEditClick: (e: React.MouseEvent) => void,
  options: ActionRendererOptions = DEFAULT_ACTION_RENDERER_OPTIONS
) => {
  const { hideDeleteButton, deleteButtonText, deleteButtonDisabled } = options;

  return (
    <div
      style={{
        display: "flex",
        height: "40px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!hideDeleteButton && (
        <TableCellButton
          disabled={deleteButtonDisabled}
          size="small"
          danger
          ghost
          onClick={(e) => {
            onDeleteClick(e);
          }}
          label={deleteButtonText || "삭제"}
          style={{
            marginRight: "10px",
          }}
        />
      )}

      <TableCellButton
        size="small"
        ghost
        onClick={(e) => {
          onEditClick(e);
        }}
        label="상세보기"
      />
    </div>
  );
};

const getActionModel = (
  onDeleteClick?: ActionCellClickListener | null,
  onEditClick?: ActionCellClickListener | null,
  options?: ActionRendererOptions
) => {
  return (props: ICellRendererParams) =>
    createActionColRenderer(
      props,
      (e) => {
        e.stopPropagation();
        if (onDeleteClick) {
          onDeleteClick(props, props.data);
        }
      },
      () => {
        if (onEditClick) onEditClick(props, props.data);
      },
      options
    );
};

export const createActionCellRenderers = (
  onDeleteClick?: ActionCellClickListener | null,
  onEditClick?: ActionCellClickListener | null,
  options?: ActionRendererOptions
) => {
  return {
    [CELL_ACTION_RENDERER]: getActionModel(onDeleteClick, onEditClick, options),
  };
};

export const createActionCol = (width = 140) => {
  return {
    headerName: "관리",
    field: "content",
    width,
    cellRenderer: CELL_ACTION_RENDERER,
    pinned: "right",
  };
};

export const CW_SMALL = 100;
export const CW_SMALL_M = 120;
export const CW_MEDIUM = 150;
export const CW_LARGE = 200;
export const CW_DATE_RANGE = 270;
export const CW_LONG_TEXT = 250;
export const CW_X_LARGE = 400;
export const CW_540 = 540;
export const CW_2X_LARGE = 550;