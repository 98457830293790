import React, { FC } from "react";
import {
  BANNER_MANAGER,
  CAR_BOOKING_HISTORIES,
  CAR_MANAGER_REPLACEMENT_MANAGER,
  CAR_MANAGER_SMS_SENDER,
  CAR_MANAGER_TRANSFER_MANAGER,
  CAR_MANAGERS,
  CONSULTING_DATA_MANAGER,
  CONTRACT_UPDATE_REQUEST_MANAGER,
  COSS_CAR_INFO_MANAGER,
  CUSTOMER_CONSULTING_REQUEST,
  CUSTOMER_MANAGER,
  CUSTOMER_SATISFACTION_REPORT_MANAGER,
  DELIVERY_SCHEDULE_MANAGER,
  DISCOUNT_MASTER_MANAGER,
  ESTIMATION_MANAGER,
  ESTIMATION_REPORT_MANAGER,
  EVENT_MANAGER,
  EXHIBITION_MANAGER,
  FAQ_MANAGER,
  INSURANCE_FEE_RATE_MANAGER,
  LOGIN,
  MAIN_PRODUCT_PRICING_MANAGER,
  MGM_REPORT_MANAGER,
  NEW_CAR_DELIVERY_MANAGER,
  NEW_CAR_RENT_CATALOGS_MANAGER,
  CATALOG_TAG_MANAGER,
  NOTICE_MANAGER,
  RENT_CONTRACT_MANAGER,
  REVIEW_MANAGER,
  SATISFACTION_SURVEY_MANAGER,
  SMS_TEMPLATE_MANAGER,
  SPECIAL_PROMOTION_REWARD_MANAGER,
  STOCK_REPORT_MANAGER,
  SURVEY_REPORT_MANAGER,
  USED_CAR_RENT_CATALOGS_MANAGER,
  TAGO_CAR_RENT_CATALOGS_MANAGER,
  MONTHLY_CAR_RENT_CATALOGS_MANAGER,
  AUC_CAR_RENT_CATALOGS_MANAGER,
  USED_CAR_CATALOG_TAG_MANAGER,
  USED_CAR_EXHIBITION_MANAGER,
  TAGO_CAR_EXHIBITION_MANAGER,
  AUC_MANAGER,
  SKU_REPORT_MANAGER,
  COOPER_CAR_RENT_CATALOGS_MANAGER,
  TAGO_TKV_REQ_CONTRACT_MANAGER,
  AUC_CONTRACT_MANAGER,
  YEAR_CEFT_MANAGER,
} from "../urls";
import Login from "../../../pages/common/Login";
import NewCarCatalogManager from "../../../pages/car-sales/NewCarCatalogManager";
import NoticeManager from "../../../pages/customer-service/NoticeManager";
import FAQManager from "../../../pages/customer-service/FAQManager";
import CustomerTransferManager from "../../../pages/customer/CarManagerTransferRequestManager";
import EventManager from "../../../pages/marketing/EventManager";
import BannerManager from "../../../pages/marketing/BannerManager";
import NewCarDeliveryInfoManager from "../../../pages/car-sales/NewCarDeliveryInfoManager";
import EstimationManager from "../../../pages/contracts/EstimationManager";
import RentContractManager from "../../../pages/contracts/RentContractManager";
import ContractUpdateRequestManager from "../../../pages/contracts/ContractUpdateRequestManager";
import CarManagerManager from "../../../pages/service-management/CarManagerManager";
import GradeRateManager from "../../../pages/car-sales/GradeRateManager";
import DefaultRentOptionInfoManager from "../../../pages/car-sales/DefaultRentOptionInfoManager";
import CarManagerDeliveryScheduler from "../../../pages/car-manager/CarManagerDeliveryScheduler";
import ReviewManager from "../../../pages/marketing/ReviewManager";
import CustomerConsultingRequestManager from "../../../pages/customer/CustomerConsultingRequestManager";
import CustomerManager from "../../../pages/customer/CustomerManager";
import ConsultingDataManager from "../../../pages/marketing/ConsultingDataManager";
import DiscountMasterManager from "../../../pages/contracts/DiscountMasterManager";
import CarManagerReplacementManager from "../../../pages/car-manager/CarManagerReplacementManager";
import CossCarInfoManager from "../../../pages/car-sales/CossCarInfoManager";
import SatisfactionSurveyManager from "../../../pages/service-management/SatisfactionSurveyManager";
import PromotionRewardManager from "../../../pages/marketing/PromotionRewardManager";
import MGMReportManager from "../../../pages/marketing/MGMReportManager";
import CustomerSatisfactionReportManager from "../../../pages/service-management/CustomerSatisfactionReportManager";
import SMSTemplateManager from "../../../pages/service-management/SMSTemplateManager";
import CarManagerSMSSender from "../../../pages/car-manager/CarManagerSMSSender";
import EstimationReportManager from "../../../pages/reports/EstimationReportManager";
import StockReportManager from "../../../pages/reports/StockReportManager";
import SurveyReportManager from "../../../pages/service-management/SurveyReportManager";
import CarBookingHistoryManager from "../../../pages/customer/CarBookingHistoryManager";
import Exhibition from "../../../pages/marketing/ExhibitionManager";
import UcExhibition from "../../../pages/marketing/UcExhibitionManager";
import TbExhibition from "../../../pages/marketing/TagoExhibitionManager";
import AucManager from "../../../pages/marketing/AucManager";
import CatalogTagManager from "../../../pages/car-sales/CatalogTagManager";
import UsedCarCatalogManager from "../../../pages/car-sales/UsedCarCatalogManager";
import TagoCarCatalogManager from "../../../pages/car-sales/TagoCarCatalogManager";

import AucCarCatalogManager from "../../../pages/car-sales/AucCarCatalogManager";
import UsedCarCatalogTagManager from "../../../pages/car-sales/UsedCarCatalogTagManager";
import SkuReport from "../../../pages/reports/SkuReport";
import CooperCarCatalogManager from "../../../pages/car-sales/CooperCarCatalogManager";
import AucContractManager from "../../../pages/contracts/AucContractManager";
import YearCeftManager from "../../../pages/car-sales/YearCeftManager";
import TagoTkvReqContractManager from "../../../pages/contracts/TagoTkvReqContractManager";
import McCarCatalogManager from "../../../pages/car-sales/McCarCatalogManager";


export type RouteInfo = {
  path: string;
  component: typeof React.Component | FC<any>;
  exact: true;
  breadcrumb?: string;
};

export const PUBLIC_ROUTES: Array<RouteInfo> = [
  {
    path: LOGIN,
    component: Login,
    exact: true,
  },
];
export const PRIVATE_ROUTES: Array<RouteInfo> = [
  /**
   * 회원
   */
  // 회원 관리
  {
    path: CUSTOMER_MANAGER,
    component: CustomerManager,
    exact: true,
  },
  // 상담신청 관리
  {
    path: CUSTOMER_CONSULTING_REQUEST,
    component: CustomerConsultingRequestManager,
    exact: true,
  },
  // 사전예약 신청내역
  {
    path: CAR_BOOKING_HISTORIES,
    component: CarBookingHistoryManager,
    exact: true,
  },
  // 회원 이관 (카매니저)
  {
    path: CAR_MANAGER_TRANSFER_MANAGER,
    component: CustomerTransferManager,
    exact: true,
  },
  /**
   * 견적/계약
   */
  {
    path: ESTIMATION_MANAGER,
    component: EstimationManager,
    exact: true,
  },
  {
    path: RENT_CONTRACT_MANAGER,
    component: RentContractManager,
    exact: true,
  },
  {
    path: CONTRACT_UPDATE_REQUEST_MANAGER,
    component: ContractUpdateRequestManager,
    exact: true,
  },
  {
    path: DISCOUNT_MASTER_MANAGER,
    component: DiscountMasterManager,
    exact: true,
  },
  // 인수요청관리(타고바이)
  {
    path: TAGO_TKV_REQ_CONTRACT_MANAGER, 
    component: TagoTkvReqContractManager,
    exact: true,
  },
  // 계약관리(제휴중고차)
  {
    path: AUC_CONTRACT_MANAGER, 
    component: AucContractManager,
    exact: true,
  },
  /**
   * 차량판매
   */
  {
    path: COSS_CAR_INFO_MANAGER,
    component: CossCarInfoManager,
    exact: true,
  },
  // 온라인 상품관리(신차장기)
  {
    path: NEW_CAR_RENT_CATALOGS_MANAGER,
    component: NewCarCatalogManager,
    exact: true,
  },
  // 온라인 상품관리(중고장기)
  {
    path: USED_CAR_RENT_CATALOGS_MANAGER,
    component: UsedCarCatalogManager,
    exact: true,
  },
  // 온라인 상품관리(타고바이)
  {
    path: TAGO_CAR_RENT_CATALOGS_MANAGER,
    component: TagoCarCatalogManager,
    exact: true,
  },
  // 온라인 상품관리(월렌트)  TODO : define component
  {
    path: MONTHLY_CAR_RENT_CATALOGS_MANAGER,
    component: McCarCatalogManager,
    exact: true,
  },
 // 온라인 상품관리(제휴중고)
 {
    path: AUC_CAR_RENT_CATALOGS_MANAGER,
    component: AucCarCatalogManager,
    exact: true,
  },
  // 온라인 상품관리(제휴연동)
  {
    path: COOPER_CAR_RENT_CATALOGS_MANAGER,
    component: CooperCarCatalogManager,
    exact: true,
  },
  // 상품태그관리 (신차장기)
  {
    path: CATALOG_TAG_MANAGER,
    component: CatalogTagManager,
    exact: true,
  },
  // 상품태그관리 (중고장기)
  {
    path: USED_CAR_CATALOG_TAG_MANAGER,
    component: UsedCarCatalogTagManager,
    exact: true,
  },
  // 보증보험 수수료율 관리
  {
    path: INSURANCE_FEE_RATE_MANAGER,
    component: GradeRateManager,
    exact: true,
  },
  // 신차발주 납기관리
  {
    path: NEW_CAR_DELIVERY_MANAGER,
    component: NewCarDeliveryInfoManager,
    exact: true,
  },
  // 메인 가격기준 관리
  {
    path: MAIN_PRODUCT_PRICING_MANAGER,
    component: DefaultRentOptionInfoManager,
    exact: true,
  },
  // 미래가치계수 관리
  {
    path: YEAR_CEFT_MANAGER,
    component: YearCeftManager,
    exact: true,
  },
  /**
   * 마케팅
   */
  // 기획전 관리(신차장기)
  {
    path: EXHIBITION_MANAGER,
    component: Exhibition,
    exact: true,
  },
  // 기획전 관리(중고장기)
  {
    path: USED_CAR_EXHIBITION_MANAGER,
    component: UcExhibition,
    exact: true,
  },
  // 기획전 관리(타고바이)
  {
    path: TAGO_CAR_EXHIBITION_MANAGER,
    component: TbExhibition,
    exact: true,
  },
  // 제휴사 관리(제휴중고차)
  {
    path: AUC_MANAGER,
    component: AucManager,
    exact: true,
  },
  // 이벤트
  {
    path: EVENT_MANAGER,
    component: EventManager,
    exact: true,
  },
  // 배너 관리
  {
    path: BANNER_MANAGER,
    component: BannerManager,
    exact: true,
  },
  // 후기 관리
  {
    path: REVIEW_MANAGER,
    component: ReviewManager,
    exact: true,
  },
  // 지능형 상담
  {
    path: CONSULTING_DATA_MANAGER,
    component: ConsultingDataManager,
    exact: true,
  },
  // 리워드 지급 조회
  {
    path: MGM_REPORT_MANAGER,
    component: MGMReportManager,
    exact: true,
  },
  // 리워드 지급 조회
  {
    path: SPECIAL_PROMOTION_REWARD_MANAGER,
    component: PromotionRewardManager,
    exact: true,
  },
  /**
   * 고객지원
   */
  // 공지사항
  {
    path: NOTICE_MANAGER,
    component: NoticeManager,
    exact: true,
  },
  // FAQ
  {
    path: FAQ_MANAGER,
    component: FAQManager,
    exact: true,
  },
  /**
   * 카매니저
   */
  // 인도스케줄 관리
  {
    path: DELIVERY_SCHEDULE_MANAGER,
    component: CarManagerDeliveryScheduler,
    exact: true,
  },
  // 대무 설정
  {
    path: CAR_MANAGER_REPLACEMENT_MANAGER,
    component: CarManagerReplacementManager,
    exact: true,
  },
  // 템플릿 문자 전송
  {
    path: CAR_MANAGER_SMS_SENDER,
    component: CarManagerSMSSender,
    exact: true,
  },
  /**
   * 서비스운영 관리
   */
  // 서비스 만족도 조사결과 (고객 만족도조사)
  {
    path: CUSTOMER_SATISFACTION_REPORT_MANAGER,
    component: CustomerSatisfactionReportManager,
    exact: true,
  },
  // 고객 설문 조사결과
  {
    path: SURVEY_REPORT_MANAGER,
    component: SurveyReportManager,
    exact: true,
  },
  // 견적정보
  {
    path: ESTIMATION_REPORT_MANAGER,
    component: EstimationReportManager,
    exact: true,
  },
  // 재고정보
  {
    path: STOCK_REPORT_MANAGER,
    component: StockReportManager,
    exact: true,
  },
  // 카매니저 관리
  {
    path: CAR_MANAGERS,
    component: CarManagerManager,
    exact: true,
  },
  // 만족도 조사 템플릿 관리
  {
    path: SATISFACTION_SURVEY_MANAGER,
    component: SatisfactionSurveyManager,
    exact: true,
  },
  // 문자전송템플릿관리
  {
    path: SMS_TEMPLATE_MANAGER,
    component: SMSTemplateManager,
    exact: true,
  },
  /**
   * 보고서
   */
  {
    path: SKU_REPORT_MANAGER,
    component: SkuReport,
    exact: true,
  },
];
