import CossColor from "./CossColor";
import CossOption from "./CossOption";
import NiceInfoColor from "./NiceInfoColor";
import NiceInfoOption from "./NiceInfoOption";

class OptionDetail {
  public cossColorList: Array<CossColor> = [];

  public cossOptionList: Array<CossOption> = [];

  public niceInfoColorList: Array<NiceInfoColor> = [];

  public niceInfoOptionList: Array<NiceInfoOption> = [];
}

export default OptionDetail;
