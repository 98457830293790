import { get } from "lodash";
import CossCarInfoDetail from "../../../../../../models/CossCarInfo/CossCarInfoDetail";
import { formatCurrency } from "../../../../../../utils/common-utils";

export const getNiceTrim = (cossCarInfoDetail?: CossCarInfoDetail) => {
  return get(cossCarInfoDetail, "niceInfo.carGradeNm", "-");
};

export const getNiceBrand = (cossCarInfoDetail?: CossCarInfoDetail) => {
  return get(cossCarInfoDetail, "niceInfo.brandNm", "-");
};

export const getNiceYear = (cossCarInfoDetail?: CossCarInfoDetail) => {
  return get(cossCarInfoDetail, "niceInfo.yearType", "-");
};

export const getNiceCarPrice = (cossCarInfoDetail?: CossCarInfoDetail) => {
  const price = get(cossCarInfoDetail, "niceInfo.carGradePrice", "");
  if (price) return formatCurrency(price);
  return "-";
};
