import React, { useState } from "react";
import * as Yup from "yup";
import { ModalProps } from "../../../../types";
import {
  FormElementType,
  FormInfo,
} from "../../../../../components/shared/data-entry/FormBuilder/types";
import errorMessages from "../../../../../constants/errors";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import { FormikProps, FormikValues } from "formik";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  addSMSTemplate,
  editSMSTemplate,
  fetchSMSTemplateDetail,
  fetchSMSTemplates,
} from "../../../../../apis/sms-template";

interface Props extends ModalProps {
  templateId?: string;
}

const SMSTemplateFormModal: React.FC<Props> = (props: Props) => {
  const FORM_INIT_VALUES = {
    templateTitle: "",
    templateContent: "",
  };
  const forms: FormInfo[] = [
    {
      key: "templateTitle",
      type: FormElementType.Input,
      label: "템플릿명",
      required: true,
      placeholder: "템플릿명을 입력해주세요",
    },
    {
      key: "templateContent",
      type: FormElementType.TextArea,
      label: "내용",
      required: true,
      placeholder: "템플릿명을 입력해주세요",
    },
  ];

  const [initialValues, setInitialValues] = useState({ ...FORM_INIT_VALUES });
  const validationSchema = Yup.object().shape({
    templateTitle: Yup.string().required(errorMessages.REQUIRED_FIELD),
    templateContent: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });

  const { visible, onCancel, onDataChange, templateId } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  let formik: FormikProps<FormikValues>;
  const onModalOpen = async () => {
    if (templateId) {
      const template = await requestFetchTemplateDetail(templateId);
      if (template) {
        setInitialValues({
          templateTitle: template.smsTitle,
          templateContent: template.smsMsg,
        });
      }
    } else {
      setInitialValues({ ...FORM_INIT_VALUES });
    }
  };

  /**
   * Private Functions
   */

  const getModalTitle = () => {
    if (templateId) {
      return "템플릿 수정";
    }

    return "템플릿 등록";
  };

  const requestAddTemplate = async (values: FormikValues) => {
    const { templateTitle, templateContent } = values;
    try {
      setConfirmLoading(true);
      await addSMSTemplate(templateTitle, templateContent);
      alertSuccess("템플릿이 등록되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditTemplate = async (values: FormikValues) => {
    const { templateTitle, templateContent } = values;
    try {
      setConfirmLoading(true);
      if (templateId) {
        await editSMSTemplate(templateId, templateTitle, templateContent);
        alertSuccess("템플릿 정보가 변경되었습니다.");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      }
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestFetchTemplateDetail = async (templateId: string) => {
    try {
      setDataFetching(true);
      return await fetchSMSTemplateDetail(templateId);
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    // 수정
    if (templateId) {
      await requestEditTemplate(values);
    }
    // 등록
    else {
      await requestAddTemplate(values);
    }
  };

  /**
   * Render Helpers
   */

  const renderForms = () => {
    return (
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        forms={forms}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    );
  };

  return (
    <Modal
      title={getModalTitle()}
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      {dataFetching ? renderLoading() : renderForms()}
    </Modal>
  );
};

SMSTemplateFormModal.defaultProps = {};
export default SMSTemplateFormModal;
