import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { ModalProps } from "../../../../../../types";
import RentContractDetail from "../../../../../../../models/RentContractDetail";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";

import {
  FormElementType,
  FormOption,
} from "../../../../../../../components/shared/data-entry/FormBuilder/types";

import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import { setupCarManager } from "../../../../../../../apis/rent-contracts";
import errorMessages from "../../../../../../../constants/errors";
import { fetchCarManagers } from "../../../../../../../apis/car-managers";

interface Props extends ModalProps {
  rentContractDetail?: RentContractDetail;
}

const CarManagerSetupModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, rentContractDetail } = props;
  const formInitialValues = {
    carmanId: null,
  };
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const createForms = (options: FormOption[]) => {
    return [
      {
        key: "carmanId",
        type: FormElementType.Select,
        label: "카매니저",
        required: true,
        options,
        placeholder: "카매니저를 선택해주세요",
      },
    ];
  };
  const [forms, setForms] = useState<any>(createForms([]));
  const validationSchema = Yup.object().shape({
    carmanId: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    await requestFetchCarManagerOptions();
    setInitialValues(formInitialValues);
  };

  /**
   * Private Functions
   */

  const requestFetchCarManagerOptions = async () => {
    setDataFetching(true);
    try {
      const { items } = await fetchCarManagers();
      const options = items.map(({ carmanId, userNm }) => {
        return {
          label: userNm,
          value: carmanId,
        };
      });
      setForms(createForms(options));
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  const requestSetUpCarManager = async (
    cntrId: string,
    cntrSeq: number,
    carmanId: string
  ) => {
    setConfirmLoading(true);
    try {
      await setupCarManager(cntrId, cntrSeq.toString(), carmanId);
      alertSuccess("카매니저가 배정되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (rentContractDetail) {
      const { cntrId, cntrSeq } = rentContractDetail;
      const { carmanId } = values;
      await requestSetUpCarManager(cntrId, cntrSeq, carmanId);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      title="카매니저 배정"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          validationSchema={validationSchema}
          initialValues={initialValues}
          forms={forms}
          formRef={(ref) => {
            formik = ref;
          }}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

CarManagerSetupModal.defaultProps = {};
export default CarManagerSetupModal;
