import FileInfo from "./FileInfo";

class ReviewDetail {
  // 등록일자
  public regDt = "";

  // 관련식별자 아이디
  public rltIdtfId = "";

  // 고객 아이디
  public custId = "";

  // 고객명
  public custNm = "";

  // 상품유형
  public prodPattern = "";

  // 상품유형명
  public prodPatternNm = "";

  // 계약유형
  public cntrPattern = "";

  // 계약유형명
  public cntrPatternNm = "";

  // 제조사명
  public brandNm = "";

  // 모델
  public repCartypeNm = "";

  // 세부모델
  public carClassNm = "";

  // 트림
  public carGradeNm = "";

  // 후기내용
  public crmCntn = "";

  // 별점
  public crmScore = 0;

  // 등급
  public crmGrd = "";

  // 이미지 첨부파일 경로
  public fileUrl = "";

  // 전체 후기 숨김 여부
  public viewYn = "";

  // 사용 여부
  public useYn = "";

  // 계약 아이디
  public cntrId = "";

  // 신차장기 메인 후기 노출
  public newcarViewYn = "";

  // 타보증 메인 후기 노출
  public usdcarViewYn = "";

  // 이미지 파일 정보
  public fileInfoList: Array<FileInfo> = [];
}

export default ReviewDetail;
