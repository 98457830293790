import React, { useState } from "react";
import * as Yup from "yup";
import { Redirect } from "react-router-dom";
import { Formik, Form, FormikValues, FormikProps } from "formik";
import strings from "./constants/strings";
import {
  Jumbotron,
  LoginFormContainer,
  LoginFormWrapper,
  LoginRootFlexContainer,
} from "./style";
import useAuth from "../../../hooks/useAuth";
import Logo from "../../../components/shared/general/Logo";
import {
  CUSTOMER_MANAGER,
  RENT_CONTRACT_MANAGER,
} from "../../../routes/constants/urls";
import { fetchUserInfo, login } from "../../../apis/session";
import FormItem from "../../../components/shared/data-entry/antd/FormItem";
import Input from "../../../components/shared/data-entry/antd/Input";
import PasswordInput from "../../../components/shared/data-entry/antd/PasswordInput";
import Button from "../../../components/shared/general/antd/Button";
import { alertError } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import PhoneAuthModal from "./pages/PhoneAuthModal";
import User from "../../../models/User";
import { ENUM_DTY_ID_BIZ } from "../../../constants/enums";
import { setConnectLogs } from "../../../apis/logs";

const Login: React.FC = () => {
  const { setUser, user } = useAuth();
  const [passwd, setPasswd] = useState("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [phoneAuthModalVisible, setPhoneAuthModalVisible] = useState<boolean>(
    false
  );
  const [authNeededUser, setAuthNeededUser] = useState<User>();
  const CONTROL_WIDTH = 450;
  const formInitialValues = {
    employeeNo: "",
    password: "",
  };
  const formValidationSchema = Yup.object().shape({
    employeeNo: Yup.string().required(strings.ERR_EMPLOYEE_NO_REQUIRED),
    password: Yup.string().required(strings.ERR_PASSWORD_REQUIRED),
  });

  /**
   * Private Functions
   */

  const requestLogin = async (employeeNo: string, password: string) => {
    setButtonLoading(true);
    try {
      const { user: userResponse } = await login(employeeNo, password);
      if (userResponse.addAuthChk === "N") {
        // 로그인 응답에는 메뉴데이터가 없어 세션 재조회
        const { user: userRes } = await fetchUserInfo();
        setConnectLogs({
          userId: userRes.userId,
          userIp: userRes.clientIp,
          methodNm: "/admin/session",
          menuName: "로그인(접속)",
          actionFlag: "SEARCH",
        });
        setUser(userRes);
      } else {
        // 2차 인증 필요
        setAuthNeededUser(userResponse);
        setPhoneAuthModalVisible(true);
      }
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setButtonLoading(false);
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    const { employeeNo, password } = values;
    await requestLogin(employeeNo, password);
  };

  /**
   * Render Helpers
   */

  const renderLoginForms = (renderProps: FormikProps<FormikValues>) => {
    const { values, setFieldValue, errors, touched } = renderProps;
    const { employeeNo, password } = values;
    const loginButtonDisabled = !(employeeNo && password);

    let idError;
    let pwdError;

    if (errors.employeeNo && touched.employeeNo) {
      idError = errors.employeeNo;
    }

    if (errors.password && touched.password) {
      pwdError = errors.password;
    }

    return (
      <Form>
        <FormItem
          labelCol={{
            span: 24,
          }}
          name="employeeNo"
          label={strings.LABEL_ID}
          validateStatus={idError && "error"}
          help={idError}
        >
          <Input
            size="large"
            width={CONTROL_WIDTH}
            value={values.employeeNo}
            onChange={(e) => {
              setFieldValue("employeeNo", e.target.value);
            }}
            placeholder={strings.HINT_ID}
          />
        </FormItem>
        <FormItem
          style={{
            marginTop: "16px",
          }}
          labelCol={{
            span: 24,
          }}
          name="password"
          label={strings.LABEL_PASSWORD}
          validateStatus={pwdError && "error"}
          help={pwdError}
        >
          <PasswordInput
            size="large"
            value={values.password}
            onChange={(e) => {
              setFieldValue("password", e.target.value);
              setPasswd(e.target.value);
            }}
            placeholder={strings.HINT_PASSWORD}
          />
        </FormItem>
        <Button
          htmlType="submit"
          disabled={loginButtonDisabled}
          size="large"
          style={{
            marginTop: "32px",
            width: "100%",
            height: "54px",
          }}
          loading={buttonLoading}
        >
          {strings.TITLE_LOGIN}
        </Button>
      </Form>
    );
  };

  if (user) {
    if (user.dtyId === ENUM_DTY_ID_BIZ) {
      return <Redirect to={RENT_CONTRACT_MANAGER} />;
    }
    return <Redirect to={CUSTOMER_MANAGER} />;
  }

  return (
    <LoginRootFlexContainer>
      <LoginFormContainer>
        <LoginFormWrapper>
          <Logo
            style={{
              marginBottom: "50px",
            }}
          />
          <Formik
            validateOnBlur
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={handleSubmit}
          >
            {renderLoginForms}
          </Formik>
        </LoginFormWrapper>
      </LoginFormContainer>
      <Jumbotron />

      <PhoneAuthModal
        passwd={passwd}
        user={authNeededUser}
        visible={phoneAuthModalVisible}
        onCancel={() => {
          setPhoneAuthModalVisible(false);
        }}
      />
    </LoginRootFlexContainer>
  );
};

Login.defaultProps = {};
export default Login;
