import React from "react";
import { ColProps } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import { FormElementType, FormInfo } from "./types";
import FormItem from "../antd/FormItem";
import Input from "../antd/Input";
import CheckboxGroup from "../antd/CheckboxGroup";
import Checkbox from "../antd/Checkbox";
import Select from "../antd/Select";
import DateRangePicker from "../antd/DateRangePicker";
import TextArea from "../antd/TextArea";
import InputNumber from "../antd/InputNumber";
import ImageUpload, { ImageInfo } from "../ImageUpload";
import DatePicker from "../antd/DatePicker";
import Radio from "../antd/Radio";

export const defaultFormItemLayout = {
  labelCol: {
    xl: { span: 5 },
    lg: { span: 5 },
  },
  wrapperCol: {
    xl: { span: 19 },
    lg: { span: 19 },
  },
};

interface FormBuilderProps {
  forms?: Array<FormInfo>;
  formRef?: (formik: FormikProps<FormikValues>) => void;
  onSubmit?: (values: FormikValues) => void;
  onReset?: (values: any) => void;
  initialValues?: any;
  validationSchema?: any;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
}

const FormBuilder: React.FC<FormBuilderProps> = (props: FormBuilderProps) => {
  const {
    forms = [],
    formRef,
    initialValues = {},
    onSubmit,
    validationSchema,
    wrapperCol,
    labelCol,
  } = props;

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  const handleSubmit = (values: FormikValues) => {
    if (onSubmit) onSubmit(values);
  };

  /**
   * Render Helpers
   */
  const renderRadioGroup = (
    formInfo: FormInfo,
    renderProps: FormikProps<FormikValues>
  ) => {
    const {
      placeholder,
      key,
      options,
      disabled = false,
      config = {},
      style,
    } = formInfo;
    const { setFieldValue, values } = renderProps;

    return (
      <Radio
        disabled={disabled}
        value={values[key]}
        placeholder={placeholder}
        options={options}
        onChange={(value) => {
          setFieldValue(key, value);
        }}
        style={style}
        {...config}
      />
    );
  };

  const renderSelect = (
    formInfo: FormInfo,
    renderProps: FormikProps<FormikValues>
  ) => {
    const {
      placeholder,
      key,
      options,
      disabled = false,
      config = {},
      style,
    } = formInfo;
    const { setFieldValue, values } = renderProps;

    return (
      <Select
        disabled={disabled}
        value={values[key]}
        placeholder={placeholder}
        options={options}
        onChange={(value) => {
          setFieldValue(key, value);
        }}
        style={style}
        {...config}
      />
    );
  };

  const renderInput = (
    formInfo: FormInfo,
    renderProps: FormikProps<FormikValues>
  ) => {
    const { placeholder, key, disabled = false, style, config } = formInfo;
    const { setFieldValue, values } = renderProps;

    return (
      <Input
        disabled={disabled}
        value={values[key]}
        placeholder={placeholder}
        onChange={(e) => {
          const { target } = e;
          const { value } = target;
          setFieldValue(key, value);
        }}
        style={style}
        {...config}
      />
    );
  };

  const renderInputNumber = (
    formInfo: FormInfo,
    renderProps: FormikProps<FormikValues>
  ) => {
    const { placeholder, key, disabled = false, config = {}, style } = formInfo;
    const { setFieldValue, values } = renderProps;

    return (
      <InputNumber
        disabled={disabled}
        style={{
          width: "100%",
          ...style,
        }}
        value={values[key]}
        placeholder={placeholder}
        onChange={(value) => {
          setFieldValue(key, value);
        }}
        {...config}
      />
    );
  };

  const renderTextArea = (
    formInfo: FormInfo,
    renderProps: FormikProps<FormikValues>
  ) => {
    const { placeholder, key, disabled = false, style } = formInfo;
    const { setFieldValue, values } = renderProps;

    return (
      <TextArea
        disabled={disabled}
        value={values[key]}
        placeholder={placeholder}
        onChange={(e) => {
          const { target } = e;
          const { value } = target;
          setFieldValue(key, value);

          if (formInfo.onChange) {
            formInfo.onChange(value);
          }
        }}
        style={style}
      />
    );
  };

  const renderCheckboxGroup = (
    formInfo: FormInfo,
    renderProps: FormikProps<FormikValues>
  ) => {
    const { key, options, disabled = false, style } = formInfo;
    const { setFieldValue, values } = renderProps;

    return (
      <CheckboxGroup
        disabled={disabled}
        value={values[key]}
        options={options}
        onChange={(checkedValues) => {
          setFieldValue(key, checkedValues);
        }}
        style={style}
      />
    );
  };

  const renderCheckbox = (
    formInfo: FormInfo,
    renderProps: FormikProps<FormikValues>
  ) => {
    const { key, disabled = false, style } = formInfo;
    const { setFieldValue, values } = renderProps;

    return (
      <Checkbox
        disabled={disabled}
        checked={values[key]}
        onChange={(e) => {
          const { target } = e;
          const { checked } = target;
          setFieldValue(key, checked);
        }}
        style={style}
      />
    );
  };

  const renderDateRangerPicker = (
    formInfo: FormInfo,
    renderProps: FormikProps<FormikValues>
  ) => {
    const { key, disabled = false, config = {}, style } = formInfo;
    const { setFieldValue, values } = renderProps;
    const showTime = !!(config && config.showTime);

    return (
      <DateRangePicker
        showTime={showTime && { format: "HH:mm" }}
        format={showTime ? "YYYY-MM-DD HH:mm" : undefined}
        disabled={disabled}
        value={values[key]}
        onChange={(values) => {
          setFieldValue(key, values);
        }}
        style={style}
      />
    );
  };

  const renderDatePicker = (
    formInfo: FormInfo,
    renderProps: FormikProps<FormikValues>
  ) => {
    const { key, disabled = false, config = {}, style } = formInfo;
    const { setFieldValue, values } = renderProps;

    const showTime = !!(config && config.showTime);
    const { disablePast } = config;
    return (
      <DatePicker
        style={{
          width: "100%",
          ...style,
        }}
        disablePast={disablePast}
        showTime={showTime}
        disabled={disabled}
        value={values[key]}
        onChange={(values) => {
          setFieldValue(key, values);
        }}
      />
    );
  };

  const renderImageUpload = (
    formInfo: FormInfo,
    renderProps: FormikProps<FormikValues>
  ) => {
    const { uploadCodeInfo, key, disabled, config = {}, style } = formInfo;
    const { setFieldValue, values } = renderProps;

    let images: Array<ImageInfo> = [];

    if (values[key] && Array.isArray(values[key])) {
      images = values[key];
    }

    if (uploadCodeInfo) {
      return (
        <ImageUpload
          disabled={disabled}
          imageInfos={images}
          uploadCodeInfo={uploadCodeInfo}
          onImageChange={(imageUrl) => {
            setFieldValue(key, imageUrl || "");
          }}
          style={style}
          {...config}
        />
      );
    }
    return "required uploadCodeInfo";
  };

  const renderForms = (renderProps: FormikProps<FormikValues>) => {
    return forms.map((formInfo, index) => {
      const { type, render, key, label, required } = formInfo;
      if (type === FormElementType.Custom) {
        if (render) return render(renderProps);
      }

      let formElement: any;
      switch (type) {
        case FormElementType.Input:
          formElement = renderInput(formInfo, renderProps);
          break;
        case FormElementType.InputNumber:
          formElement = renderInputNumber(formInfo, renderProps);
          break;
        case FormElementType.TextArea:
          formElement = renderTextArea(formInfo, renderProps);
          break;
        case FormElementType.Select:
          formElement = renderSelect(formInfo, renderProps);
          break;
        case FormElementType.CheckboxGroup:
          formElement = renderCheckboxGroup(formInfo, renderProps);
          break;
        case FormElementType.Checkbox:
          formElement = renderCheckbox(formInfo, renderProps);
          break;
        case FormElementType.DateRangePicker:
          formElement = renderDateRangerPicker(formInfo, renderProps);
          break;
        case FormElementType.DatePicker:
          formElement = renderDatePicker(formInfo, renderProps);
          break;
        case FormElementType.ImageUpload:
          formElement = renderImageUpload(formInfo, renderProps);
          break;
        case FormElementType.Radio:
          formElement = renderRadioGroup(formInfo, renderProps);
          break;
        default:
          break;
      }

      const { touched, errors } = renderProps;
      let errorMessage;
      if (touched[key] && errors[key]) {
        errorMessage = errors[key] as string;
      }

      return (
        <FormItem
          help={errorMessage}
          validateStatus={errorMessage && "error"}
          required={required}
          label={label}
          key={key}
          style={{
            marginBottom: index !== forms.length - 1 ? "16px" : "0",
          }}
          wrapperCol={wrapperCol}
          labelCol={labelCol}
        >
          {formElement}
        </FormItem>
      );
    });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      innerRef={(ref: FormikProps<FormikValues>) => {
        if (formRef) formRef(ref);
      }}
    >
      {(props) => {
        return <form onSubmit={props.handleSubmit}>{renderForms(props)}</form>;
      }}
    </Formik>
  );
};

FormBuilder.defaultProps = {
  ...defaultFormItemLayout,
};
export default FormBuilder;
