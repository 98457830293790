import React, { CSSProperties } from "react";
import {
  DescriptionArrow,
  IconWrapper,
  SubHeaderDescription,
  SubHeaderDescriptionWrapper,
} from "./style";

interface Props {
  children?: React.ReactNode;
  style?: CSSProperties;
}

const Description: React.FC<Props> = (props: Props) => {
  const { children, style = {} } = props;

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <SubHeaderDescriptionWrapper style={{ ...style }}>
      <IconWrapper>
        <DescriptionArrow />
      </IconWrapper>
      <SubHeaderDescription>{children}</SubHeaderDescription>
    </SubHeaderDescriptionWrapper>
  );
};

Description.defaultProps = {};
export default Description;
