export default class UploadResult {
  // 이미지 ID
  public imgId = "";

  // 파일ID
  public fileId = "";

  // 파일명
  public fileNm = "";

  // 파일유형코드
  public filePatternCd = "";

  // 파일업무구분코드
  public fileJobClsCd = "";

  // 사용여부
  public useYn = "";

  // 식별자구분코드
  public idtfClsCd = "";

  // 관련식별자ID
  public rltIdtfId = "";

  // 관련순번
  public rltSeq = "";

  // 차량ID
  public carId = "";

  // 사고순번
  public accdSeq = "";

  // 기타 금액
  public etcAmt = "";

  // 기타 항목
  public etcItem = "";

  // 파일 URL
  public fileUrl = "";

  // 원 파일 명
  public origFileNm = "";

  // 파일타입
  public fileType = "";
}
