import React, { useState } from "react";
import { Input, Spin } from "antd";
import { StyledTable } from "../../../../../../../components/shared/data-display/Tables/components/StyledTable";

import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import { ModalProps } from "../../../../../../types";
import AucCatalogDetail from "../../../../../../../models/AucCatalogDetail";
import AucCatalogRentAmtParam from "../../../../../../../models/AucCatalogRentAmtParam";
import { updateUcCooperCatalogsDetailPricing } from "../../../../../../../apis/auc-catalogs";


interface Props extends ModalProps {
  catalogDetail?: AucCatalogDetail;
}

const MmRentAmountFormModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, catalogDetail } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [rglRentAmt12001, setRglRentAmt12001] = useState(""); const [rglRentAmt12101, setRglRentAmt12101] = useState(""); const [rglRentAmt12201, setRglRentAmt12201] = useState(""); const [rglRentAmt12301, setRglRentAmt12301] = useState("");
  const [rglRentAmt12002, setRglRentAmt12002] = useState(""); const [rglRentAmt12102, setRglRentAmt12102] = useState(""); const [rglRentAmt12202, setRglRentAmt12202] = useState(""); const [rglRentAmt12302, setRglRentAmt12302] = useState("");
  const [rglRentAmt12003, setRglRentAmt12003] = useState(""); const [rglRentAmt12103, setRglRentAmt12103] = useState(""); const [rglRentAmt12203, setRglRentAmt12203] = useState(""); const [rglRentAmt12303, setRglRentAmt12303] = useState("");
  const [rglRentAmt12004, setRglRentAmt12004] = useState(""); const [rglRentAmt12104, setRglRentAmt12104] = useState(""); const [rglRentAmt12204, setRglRentAmt12204] = useState(""); const [rglRentAmt12304, setRglRentAmt12304] = useState("");
  
  const [rglRentAmt24001, setRglRentAmt24001] = useState(""); const [rglRentAmt24101, setRglRentAmt24101] = useState(""); const [rglRentAmt24201, setRglRentAmt24201] = useState(""); const [rglRentAmt24301, setRglRentAmt24301] = useState("");
  const [rglRentAmt24002, setRglRentAmt24002] = useState(""); const [rglRentAmt24102, setRglRentAmt24102] = useState(""); const [rglRentAmt24202, setRglRentAmt24202] = useState(""); const [rglRentAmt24302, setRglRentAmt24302] = useState("");
  const [rglRentAmt24003, setRglRentAmt24003] = useState(""); const [rglRentAmt24103, setRglRentAmt24103] = useState(""); const [rglRentAmt24203, setRglRentAmt24203] = useState(""); const [rglRentAmt24303, setRglRentAmt24303] = useState("");
  const [rglRentAmt24004, setRglRentAmt24004] = useState(""); const [rglRentAmt24104, setRglRentAmt24104] = useState(""); const [rglRentAmt24204, setRglRentAmt24204] = useState(""); const [rglRentAmt24304, setRglRentAmt24304] = useState("");
  
  const [rglRentAmt36001, setRglRentAmt36001] = useState(""); const [rglRentAmt36101, setRglRentAmt36101] = useState(""); const [rglRentAmt36201, setRglRentAmt36201] = useState(""); const [rglRentAmt36301, setRglRentAmt36301] = useState("");
  const [rglRentAmt36002, setRglRentAmt36002] = useState(""); const [rglRentAmt36102, setRglRentAmt36102] = useState(""); const [rglRentAmt36202, setRglRentAmt36202] = useState(""); const [rglRentAmt36302, setRglRentAmt36302] = useState("");
  const [rglRentAmt36003, setRglRentAmt36003] = useState(""); const [rglRentAmt36103, setRglRentAmt36103] = useState(""); const [rglRentAmt36203, setRglRentAmt36203] = useState(""); const [rglRentAmt36303, setRglRentAmt36303] = useState("");
  const [rglRentAmt36004, setRglRentAmt36004] = useState(""); const [rglRentAmt36104, setRglRentAmt36104] = useState(""); const [rglRentAmt36204, setRglRentAmt36204] = useState(""); const [rglRentAmt36304, setRglRentAmt36304] = useState("");
  
  const [rglRentAmt48001, setRglRentAmt48001] = useState(""); const [rglRentAmt48101, setRglRentAmt48101] = useState(""); const [rglRentAmt48201, setRglRentAmt48201] = useState(""); const [rglRentAmt48301, setRglRentAmt48301] = useState("");
  const [rglRentAmt48002, setRglRentAmt48002] = useState(""); const [rglRentAmt48102, setRglRentAmt48102] = useState(""); const [rglRentAmt48202, setRglRentAmt48202] = useState(""); const [rglRentAmt48302, setRglRentAmt48302] = useState("");
  const [rglRentAmt48003, setRglRentAmt48003] = useState(""); const [rglRentAmt48103, setRglRentAmt48103] = useState(""); const [rglRentAmt48203, setRglRentAmt48203] = useState(""); const [rglRentAmt48303, setRglRentAmt48303] = useState("");
  const [rglRentAmt48004, setRglRentAmt48004] = useState(""); const [rglRentAmt48104, setRglRentAmt48104] = useState(""); const [rglRentAmt48204, setRglRentAmt48204] = useState(""); const [rglRentAmt48304, setRglRentAmt48304] = useState("");

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);

    if (catalogDetail) {

      let inputMonth = "";
      for(let i=0 ; catalogDetail.rentAmtList.length>i ; i++) {
        inputMonth = inputMonth + "," + catalogDetail.rentAmtList[i].cntrTermNm;
      }

      if(inputMonth.includes("12")) {
        let firstPosition = 0;
        for(let i=0 ; catalogDetail.rentAmtList.length>i ; i++) {
          if(catalogDetail.rentAmtList[i].cntrTermNm === "12") {
            firstPosition = i;
            break;
          }
        }
        setRglRentAmt12001(catalogDetail.rentAmtList[firstPosition].rglRentAmt1); setRglRentAmt12101(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt1); setRglRentAmt12201(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt1); setRglRentAmt12301(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt1);
        setRglRentAmt12002(catalogDetail.rentAmtList[firstPosition].rglRentAmt2); setRglRentAmt12102(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt2); setRglRentAmt12202(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt2); setRglRentAmt12302(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt2);
        setRglRentAmt12003(catalogDetail.rentAmtList[firstPosition].rglRentAmt3); setRglRentAmt12103(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt3); setRglRentAmt12203(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt3); setRglRentAmt12303(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt3);
        setRglRentAmt12004(catalogDetail.rentAmtList[firstPosition].rglRentAmt4); setRglRentAmt12104(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt4); setRglRentAmt12204(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt4); setRglRentAmt12304(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt4);
      } else {
        setRglRentAmt12001("0"); setRglRentAmt12101("0"); setRglRentAmt12201("0"); setRglRentAmt12301("0");
        setRglRentAmt12002("0"); setRglRentAmt12102("0"); setRglRentAmt12202("0"); setRglRentAmt12302("0");
        setRglRentAmt12003("0"); setRglRentAmt12103("0"); setRglRentAmt12203("0"); setRglRentAmt12303("0");
        setRglRentAmt12004("0"); setRglRentAmt12104("0"); setRglRentAmt12204("0"); setRglRentAmt12304("0");
      }

      if(inputMonth.includes("24")) {
        let firstPosition = 0;
        for(let i=0 ; catalogDetail.rentAmtList.length>i ; i++) {
          if(catalogDetail.rentAmtList[i].cntrTermNm === "24") {
            firstPosition = i;
            break;
          }
        }
        setRglRentAmt24001(catalogDetail.rentAmtList[firstPosition].rglRentAmt1); setRglRentAmt24101(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt1); setRglRentAmt24201(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt1); setRglRentAmt24301(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt1);
        setRglRentAmt24002(catalogDetail.rentAmtList[firstPosition].rglRentAmt2); setRglRentAmt24102(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt2); setRglRentAmt24202(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt2); setRglRentAmt24302(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt2);
        setRglRentAmt24003(catalogDetail.rentAmtList[firstPosition].rglRentAmt3); setRglRentAmt24103(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt3); setRglRentAmt24203(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt3); setRglRentAmt24303(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt3);
        setRglRentAmt24004(catalogDetail.rentAmtList[firstPosition].rglRentAmt4); setRglRentAmt24104(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt4); setRglRentAmt24204(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt4); setRglRentAmt24304(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt4);
      } else {
        setRglRentAmt24001("0"); setRglRentAmt24101("0"); setRglRentAmt24201("0"); setRglRentAmt24301("0");
        setRglRentAmt24002("0"); setRglRentAmt24102("0"); setRglRentAmt24202("0"); setRglRentAmt24302("0");
        setRglRentAmt24003("0"); setRglRentAmt24103("0"); setRglRentAmt24203("0"); setRglRentAmt24303("0");
        setRglRentAmt24004("0"); setRglRentAmt24104("0"); setRglRentAmt24204("0"); setRglRentAmt24304("0");
      }

      if(inputMonth.includes("36")) {
        let firstPosition = 0;
        for(let i=0 ; catalogDetail.rentAmtList.length>i ; i++) {
          if(catalogDetail.rentAmtList[i].cntrTermNm === "36") {
            firstPosition = i;
            break;
          }
        }
        setRglRentAmt36001(catalogDetail.rentAmtList[firstPosition].rglRentAmt1); setRglRentAmt36101(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt1); setRglRentAmt36201(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt1); setRglRentAmt36301(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt1);
        setRglRentAmt36002(catalogDetail.rentAmtList[firstPosition].rglRentAmt2); setRglRentAmt36102(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt2); setRglRentAmt36202(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt2); setRglRentAmt36302(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt2);
        setRglRentAmt36003(catalogDetail.rentAmtList[firstPosition].rglRentAmt3); setRglRentAmt36103(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt3); setRglRentAmt36203(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt3); setRglRentAmt36303(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt3);
        setRglRentAmt36004(catalogDetail.rentAmtList[firstPosition].rglRentAmt4); setRglRentAmt36104(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt4); setRglRentAmt36204(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt4); setRglRentAmt36304(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt4);
      } else {
        setRglRentAmt36001("0"); setRglRentAmt36101("0"); setRglRentAmt36201("0"); setRglRentAmt36301("0");
        setRglRentAmt36002("0"); setRglRentAmt36102("0"); setRglRentAmt36202("0"); setRglRentAmt36302("0");
        setRglRentAmt36003("0"); setRglRentAmt36103("0"); setRglRentAmt36203("0"); setRglRentAmt36303("0");
        setRglRentAmt36004("0"); setRglRentAmt36104("0"); setRglRentAmt36204("0"); setRglRentAmt36304("0");
      }

      if(inputMonth.includes("48")) {
        let firstPosition = 0;
        for(let i=0 ; catalogDetail.rentAmtList.length>i ; i++) {
          if(catalogDetail.rentAmtList[i].cntrTermNm === "48") {
            firstPosition = i;
            break;
          }
        }
        setRglRentAmt48001(catalogDetail.rentAmtList[firstPosition].rglRentAmt1); setRglRentAmt48101(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt1); setRglRentAmt48201(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt1); setRglRentAmt48301(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt1);
        setRglRentAmt48002(catalogDetail.rentAmtList[firstPosition].rglRentAmt2); setRglRentAmt48102(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt2); setRglRentAmt48202(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt2); setRglRentAmt48302(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt2);
        setRglRentAmt48003(catalogDetail.rentAmtList[firstPosition].rglRentAmt3); setRglRentAmt48103(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt3); setRglRentAmt48203(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt3); setRglRentAmt48303(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt3);
        setRglRentAmt48004(catalogDetail.rentAmtList[firstPosition].rglRentAmt4); setRglRentAmt48104(catalogDetail.rentAmtList[firstPosition+1].rglRentAmt4); setRglRentAmt48204(catalogDetail.rentAmtList[firstPosition+2].rglRentAmt4); setRglRentAmt48304(catalogDetail.rentAmtList[firstPosition+3].rglRentAmt4);
      } else {
        setRglRentAmt48001("0"); setRglRentAmt48101("0"); setRglRentAmt48201("0"); setRglRentAmt48301("0");
        setRglRentAmt48002("0"); setRglRentAmt48102("0"); setRglRentAmt48202("0"); setRglRentAmt48302("0");
        setRglRentAmt48003("0"); setRglRentAmt48103("0"); setRglRentAmt48203("0"); setRglRentAmt48303("0");
        setRglRentAmt48004("0"); setRglRentAmt48104("0"); setRglRentAmt48204("0"); setRglRentAmt48304("0");
      }
    }
    setDataFetching(false); 
  };

  /**
   * Private Functions
   */

  const handleSubmit = async () => {
    if (catalogDetail) {
      const prodId = catalogDetail.prodId;
      const carNo = catalogDetail.carNo;
      const AucCatalogRentAmtParams: AucCatalogRentAmtParam[] = [
        { carNo: carNo, cntrTermNm: "12", pldgRt: "0",  rglRentAmt1: rglRentAmt12001, rglRentAmt2: rglRentAmt12002, rglRentAmt3: rglRentAmt12003, rglRentAmt4: rglRentAmt12004, },
        { carNo: carNo, cntrTermNm: "12", pldgRt: "10", rglRentAmt1: rglRentAmt12101, rglRentAmt2: rglRentAmt12102, rglRentAmt3: rglRentAmt12103, rglRentAmt4: rglRentAmt12104, },
        { carNo: carNo, cntrTermNm: "12", pldgRt: "20", rglRentAmt1: rglRentAmt12201, rglRentAmt2: rglRentAmt12202, rglRentAmt3: rglRentAmt12203, rglRentAmt4: rglRentAmt12204, },
        { carNo: carNo, cntrTermNm: "12", pldgRt: "30", rglRentAmt1: rglRentAmt12301, rglRentAmt2: rglRentAmt12302, rglRentAmt3: rglRentAmt12303, rglRentAmt4: rglRentAmt12304, },

        { carNo: carNo, cntrTermNm: "24", pldgRt: "0",  rglRentAmt1: rglRentAmt24001, rglRentAmt2: rglRentAmt24002, rglRentAmt3: rglRentAmt24003, rglRentAmt4: rglRentAmt24004, },
        { carNo: carNo, cntrTermNm: "24", pldgRt: "10", rglRentAmt1: rglRentAmt24101, rglRentAmt2: rglRentAmt24102, rglRentAmt3: rglRentAmt24103, rglRentAmt4: rglRentAmt24104, },
        { carNo: carNo, cntrTermNm: "24", pldgRt: "20", rglRentAmt1: rglRentAmt24201, rglRentAmt2: rglRentAmt24202, rglRentAmt3: rglRentAmt24203, rglRentAmt4: rglRentAmt24204, },
        { carNo: carNo, cntrTermNm: "24", pldgRt: "30", rglRentAmt1: rglRentAmt24301, rglRentAmt2: rglRentAmt24302, rglRentAmt3: rglRentAmt24303, rglRentAmt4: rglRentAmt24304, },

        { carNo: carNo, cntrTermNm: "36", pldgRt: "0",  rglRentAmt1: rglRentAmt36001, rglRentAmt2: rglRentAmt36002, rglRentAmt3: rglRentAmt36003, rglRentAmt4: rglRentAmt36004, },
        { carNo: carNo, cntrTermNm: "36", pldgRt: "10", rglRentAmt1: rglRentAmt36101, rglRentAmt2: rglRentAmt36102, rglRentAmt3: rglRentAmt36103, rglRentAmt4: rglRentAmt36104, },
        { carNo: carNo, cntrTermNm: "36", pldgRt: "20", rglRentAmt1: rglRentAmt36201, rglRentAmt2: rglRentAmt36202, rglRentAmt3: rglRentAmt36203, rglRentAmt4: rglRentAmt36204, },
        { carNo: carNo, cntrTermNm: "36", pldgRt: "30", rglRentAmt1: rglRentAmt36301, rglRentAmt2: rglRentAmt36302, rglRentAmt3: rglRentAmt36303, rglRentAmt4: rglRentAmt36304, },

        { carNo: carNo, cntrTermNm: "48", pldgRt: "0",  rglRentAmt1: rglRentAmt48001, rglRentAmt2: rglRentAmt48002, rglRentAmt3: rglRentAmt48003, rglRentAmt4: rglRentAmt48004, },
        { carNo: carNo, cntrTermNm: "48", pldgRt: "10", rglRentAmt1: rglRentAmt48101, rglRentAmt2: rglRentAmt48102, rglRentAmt3: rglRentAmt48103, rglRentAmt4: rglRentAmt48104, },
        { carNo: carNo, cntrTermNm: "48", pldgRt: "20", rglRentAmt1: rglRentAmt48201, rglRentAmt2: rglRentAmt48202, rglRentAmt3: rglRentAmt48203, rglRentAmt4: rglRentAmt48204, },
        { carNo: carNo, cntrTermNm: "48", pldgRt: "30", rglRentAmt1: rglRentAmt48301, rglRentAmt2: rglRentAmt48302, rglRentAmt3: rglRentAmt48303, rglRentAmt4: rglRentAmt48304, },
      ];

      setConfirmLoading(true);
      try {
        await updateUcCooperCatalogsDetailPricing(prodId, AucCatalogRentAmtParams);
        alertSuccess("차량의 월 렌탈료 정보가 변경되었습니다.");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="렌탈료 수정"
      onOk={handleSubmit}
    >
      {dataFetching ? (
        <Spin size="large" />
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <th></th>
              <th>1만km</th>
              <th>2만km</th>
              <th>3만km</th>
              <th>4만km</th>
            </tr>
          </thead>
          <tbody>
            <tr className="rate-control"><th>12개월 / 보증금 0%</th>              
              <td key={"rglRentAmt12001"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12001(value) } defaultValue={rglRentAmt12001} /></td>
              <td key={"rglRentAmt12002"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12002(value) } defaultValue={rglRentAmt12002} /></td>
              <td key={"rglRentAmt12003"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12003(value) } defaultValue={rglRentAmt12003} /></td>
              <td key={"rglRentAmt12004"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12004(value) } defaultValue={rglRentAmt12004} /></td>
            </tr>
            <tr className="rate-control"><th>12개월/보증금 10%</th>              
              <td key={"rglRentAmt12101"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12101(value) } defaultValue={rglRentAmt12101} /></td>
              <td key={"rglRentAmt12102"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12102(value) } defaultValue={rglRentAmt12102} /></td>
              <td key={"rglRentAmt12103"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12103(value) } defaultValue={rglRentAmt12103} /></td>
              <td key={"rglRentAmt12104"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12104(value) } defaultValue={rglRentAmt12104} /></td>
            </tr>
            <tr className="rate-control"><th>12개월/보증금 20%</th>              
              <td key={"rglRentAmt12201"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12201(value) } defaultValue={rglRentAmt12201} /></td>
              <td key={"rglRentAmt12202"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12202(value) } defaultValue={rglRentAmt12202} /></td>
              <td key={"rglRentAmt12203"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12203(value) } defaultValue={rglRentAmt12203} /></td>
              <td key={"rglRentAmt12204"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12204(value) } defaultValue={rglRentAmt12204} /></td>
            </tr>
            <tr className="rate-control"><th>12개월/보증금 30%</th>              
              <td key={"rglRentAmt12301"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12301(value) } defaultValue={rglRentAmt12301} /></td>
              <td key={"rglRentAmt12302"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12302(value) } defaultValue={rglRentAmt12302} /></td>
              <td key={"rglRentAmt12303"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12303(value) } defaultValue={rglRentAmt12303} /></td>
              <td key={"rglRentAmt12304"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt12304(value) } defaultValue={rglRentAmt12304} /></td>
            </tr>
            <tr className="rate-control"><th>24개월/보증금 0%</th>              
              <td key={"rglRentAmt24001"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24001(value) } defaultValue={rglRentAmt24001} /></td>
              <td key={"rglRentAmt24002"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24002(value) } defaultValue={rglRentAmt24002} /></td>
              <td key={"rglRentAmt24003"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24003(value) } defaultValue={rglRentAmt24003} /></td>
              <td key={"rglRentAmt24004"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24004(value) } defaultValue={rglRentAmt24004} /></td>
            </tr>
            <tr className="rate-control"><th>24개월/보증금 10%</th>              
              <td key={"rglRentAmt24101"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24101(value) } defaultValue={rglRentAmt24101} /></td>
              <td key={"rglRentAmt24102"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24102(value) } defaultValue={rglRentAmt24102} /></td>
              <td key={"rglRentAmt24103"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24103(value) } defaultValue={rglRentAmt24103} /></td>
              <td key={"rglRentAmt24104"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24104(value) } defaultValue={rglRentAmt24104} /></td>
            </tr>
            <tr className="rate-control"><th>24개월/보증금 20%</th>              
              <td key={"rglRentAmt24201"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24201(value) } defaultValue={rglRentAmt24201} /></td>
              <td key={"rglRentAmt24202"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24202(value) } defaultValue={rglRentAmt24202} /></td>
              <td key={"rglRentAmt24203"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24203(value) } defaultValue={rglRentAmt24203} /></td>
              <td key={"rglRentAmt24204"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24204(value) } defaultValue={rglRentAmt24204} /></td>
            </tr>
            <tr className="rate-control"><th>24개월/보증금 30%</th>              
              <td key={"rglRentAmt24301"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24301(value) } defaultValue={rglRentAmt24301} /></td>
              <td key={"rglRentAmt24302"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24302(value) } defaultValue={rglRentAmt24302} /></td>
              <td key={"rglRentAmt24303"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24303(value) } defaultValue={rglRentAmt24303} /></td>
              <td key={"rglRentAmt24304"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt24304(value) } defaultValue={rglRentAmt24304} /></td>
            </tr>
            <tr className="rate-control"><th>36개월/보증금 0%</th>              
              <td key={"rglRentAmt36001"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36001(value) } defaultValue={rglRentAmt36001} /></td>
              <td key={"rglRentAmt36002"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36002(value) } defaultValue={rglRentAmt36002} /></td>
              <td key={"rglRentAmt36003"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36003(value) } defaultValue={rglRentAmt36003} /></td>
              <td key={"rglRentAmt36004"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36004(value) } defaultValue={rglRentAmt36004} /></td>
            </tr>
            <tr className="rate-control"><th>36개월/보증금 10%</th>              
              <td key={"rglRentAmt36101"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36101(value) } defaultValue={rglRentAmt36101} /></td>
              <td key={"rglRentAmt36102"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36102(value) } defaultValue={rglRentAmt36102} /></td>
              <td key={"rglRentAmt36103"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36103(value) } defaultValue={rglRentAmt36103} /></td>
              <td key={"rglRentAmt36104"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36104(value) } defaultValue={rglRentAmt36104} /></td>
            </tr>
            <tr className="rate-control"><th>36개월/보증금 20%</th>              
              <td key={"rglRentAmt36201"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36201(value) } defaultValue={rglRentAmt36201} /></td>
              <td key={"rglRentAmt36202"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36202(value) } defaultValue={rglRentAmt36202} /></td>
              <td key={"rglRentAmt36203"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36203(value) } defaultValue={rglRentAmt36203} /></td>
              <td key={"rglRentAmt36204"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36204(value) } defaultValue={rglRentAmt36204} /></td>
            </tr>
            <tr className="rate-control"><th>36개월/보증금 30%</th>              
              <td key={"rglRentAmt36301"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36301(value) } defaultValue={rglRentAmt36301} /></td>
              <td key={"rglRentAmt36302"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36302(value) } defaultValue={rglRentAmt36302} /></td>
              <td key={"rglRentAmt36303"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36303(value) } defaultValue={rglRentAmt36303} /></td>
              <td key={"rglRentAmt36304"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt36304(value) } defaultValue={rglRentAmt36304} /></td>
            </tr> 
            <tr className="rate-control"><th>48개월/보증금 0%</th>              
              <td key={"rglRentAmt48001"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48001(value) } defaultValue={rglRentAmt48001} /></td>
              <td key={"rglRentAmt48002"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48002(value) } defaultValue={rglRentAmt48002} /></td>
              <td key={"rglRentAmt48003"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48003(value) } defaultValue={rglRentAmt48003} /></td>
              <td key={"rglRentAmt48004"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48004(value) } defaultValue={rglRentAmt48004} /></td>
            </tr>
            <tr className="rate-control"><th>48개월/보증금 10%</th>              
              <td key={"rglRentAmt48101"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48101(value) } defaultValue={rglRentAmt48101} /></td>
              <td key={"rglRentAmt48102"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48102(value) } defaultValue={rglRentAmt48102} /></td>
              <td key={"rglRentAmt48103"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48103(value) } defaultValue={rglRentAmt48103} /></td>
              <td key={"rglRentAmt48104"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48104(value) } defaultValue={rglRentAmt48104} /></td>
            </tr>
            <tr className="rate-control"><th>48개월/보증금 20%</th>              
              <td key={"rglRentAmt48201"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48201(value) } defaultValue={rglRentAmt48201} /></td>
              <td key={"rglRentAmt48202"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48202(value) } defaultValue={rglRentAmt48202} /></td>
              <td key={"rglRentAmt48203"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48203(value) } defaultValue={rglRentAmt48203} /></td>
              <td key={"rglRentAmt48204"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48204(value) } defaultValue={rglRentAmt48204} /></td>
            </tr>
            <tr className="rate-control"><th>48개월/보증금 30%</th>              
              <td key={"rglRentAmt48301"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48301(value) } defaultValue={rglRentAmt48301} /></td>
              <td key={"rglRentAmt48302"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48302(value) } defaultValue={rglRentAmt48302} /></td>
              <td key={"rglRentAmt48303"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48303(value) } defaultValue={rglRentAmt48303} /></td>
              <td key={"rglRentAmt48304"}><Input type="string" style={{ width: "150px", }} onChange={({ target: { value } }) => setRglRentAmt48304(value) } defaultValue={rglRentAmt48304} /></td>
            </tr>            
          </tbody>
        </StyledTable>
      )}
    </Modal>
  );
};

MmRentAmountFormModal.defaultProps = {};
export default MmRentAmountFormModal;
