export default class FAQCategory {
  // 대분류 코드
  public llfCd = "";

  // 대분류 코드명
  public llfNm = "";

  // 2차 카테고리
  public mlfCdList: Array<SubCategory> = [];
}

class SubCategory {
  // 중분류 코드
  public mlfCd = "";

  // 중분류 코드명
  public mlfNm = "";
}
