export class DiscountInfo {
  public odsDcCd = "";

  public odsDcNm = "";

  public odsDcRemark = "";

  public odsDcAmt = 0;
}

export default class RentFeeResult {
  // 월 렌탈료(부가세미포함)
  public mmRentAmt = 0;

  // 월 렌탈료(부가세포함)
  public mmRentAmtV = 0;

  // 표준렌탈료(정상렌탈료 : 할인금액을 빼지않은 금액)
  public rglRentAmt = 0;

  // Ezy DIrect 무사고 고객 할인금액
  public odsAccdDcAmt = 0;

  // Ezy DIrect 재계약(기존고객) 할인금액
  public odsReCntrDcAmt = 0;

  // Ezy DIrect 신용등급별 할인금액
  public odsRentDcAmt = 0;

  // EZY Direct 기본 할인금액
  public odsBasicDcAmt = 0;

  // EZY Direct 프로모션 할인금액
  public odsPrmtDcAmt = 0;

  // EZY Direct 임직원 할인금액
  public odsEmpDcAmt = 0;

  // 인수가
  public tkvAmt = 0;

  // 선수금 차감
  public mmInitamt = 0;

  // 할인 항목 리스트
  public odsDcList: DiscountInfo[] = [];

  // 렌탈료 조회 아이디
  public rntFeeId = "";
}
