import TableColumn, {
    createTableCol,
    FilterType
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {CW_LARGE, CW_MEDIUM} from "../../../../../../utils/table-utils";
import {
    getTagPositionText,
    MOBILE_TAG_RENDERER,
    PC_TAG_RENDERER
} from "../../../../CatalogTagManager/utils/table-utils";

export const createCatalogTagCols = (): Array<TableColumn> => {
    return [
        // PC 태그
        createTableCol("pcTagTxt", "PC 태그", FilterType.Text, {
            minWidth: CW_LARGE,
            flex: 1,
            valueGetter: ({ data }) => {
                return data.pcTagTxt;
            },
            cellRenderer: PC_TAG_RENDERER,
        }),
        // PC 태그위치
        createTableCol("pcTagPos", "PC 태그위치", FilterType.Text, {
            width: CW_MEDIUM,
            flex: 1,
            valueGetter: ({ data }) => {
                return getTagPositionText(data.pcTagPos);
            },
        }),
        // 모바일 태그
        createTableCol("mobTagTxt", "모바일 태그", FilterType.Text, {
            minWidth: CW_LARGE,
            flex: 1,
            valueGetter: ({ data }) => {
                return data.mobTagTxt;
            },
            cellRenderer: MOBILE_TAG_RENDERER,
        }),
        // 모바일 태그위치
        createTableCol("mobTagPos", "모바일 태그위치", FilterType.Text, {
            width: CW_MEDIUM,
            flex: 1,
            valueGetter: ({ data }) => {
                return getTagPositionText(data.mobTagPos);
            },
        }),
    ];
};
