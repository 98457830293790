import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, FormikProps, FormikValues } from "formik";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../../../types";
import commonStrings from "../../../../../../../constants/strings";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import {
  copyNiceColor,
  fetchNiceCarInfoOptions,
} from "../../../../../../../apis/coss-car-infos";
import FormItem from "../../../../../../../components/shared/data-entry/antd/FormItem";
import Select from "../../../../../../../components/shared/data-entry/antd/Select";
import Input from "../../../../../../../components/shared/data-entry/antd/Input";
import errorMessages from "../../../../../../../constants/errors";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import OptionDetail from "../../../../../../../models/CossCarInfo/OptionDetail";
import { MODE_COLOR_CLS_I } from "../../../../../../../models/CossCarInfo/NiceInfoColor";

interface Props extends ModalProps {
  optionDetail?: OptionDetail;
  onColorDataChange?: (optionDetail: OptionDetail) => void;
  modlId?: string;
  modeGrdId?: string;
}

const AddNiceColorModal: React.FC<Props> = (props: Props) => {
  const {
    visible,
    onCancel,
    onColorDataChange,
    optionDetail,
    modlId,
    modeGrdId,
  } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const formInitialValues = {
    modeColorId: null,
    colorNm: "",
  };
  const validationSchema = Yup.object().shape({
    modeColorId: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    colorNm: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);
  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setInitialValues(formInitialValues);
  };

  /**
   * Private Functions
   */

  // NICE 차량 선택에 따른 옵션 정보 조회
  const requestFetchOptionDetail = async (
    modlId: string,
    modeGrdId: string
  ) => {
    try {
      return await fetchNiceCarInfoOptions(modlId, modeGrdId);
    } catch (e) {
      alertError(getErrorMessage(e));
      return null;
    }
  };

  const requestCopyNiceColor = async (values: FormikValues) => {
    setConfirmLoading(true);
    const { modeColorId, colorNm } = values;
    try {
      await copyNiceColor(modeColorId, colorNm);
      if (modlId && modeGrdId) {
        const optionDetail = await requestFetchOptionDetail(modlId, modeGrdId);
        if (optionDetail) {
          if (onColorDataChange) onColorDataChange(optionDetail);
        }
      }
      alertSuccess(commonStrings.FEEDBACK_ADD_NICE_COLOR);
      if (onCancel) onCancel();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const getNiceInnerColorOptions = () => {
    if (optionDetail && optionDetail.niceInfoColorList) {
      return optionDetail.niceInfoColorList
        .filter((colorInfo) => {
          return colorInfo.modeColorCls === MODE_COLOR_CLS_I;
        })
        .map((colorInfo) => {
          return {
            label: colorInfo.modeColorNm,
            value: colorInfo.modeColorId,
          };
        });
    }

    return [];
  };

  const findNiceColorName = (modeColorId: string) => {
    if (optionDetail) {
      if (optionDetail.niceInfoColorList) {
        const filtered = optionDetail.niceInfoColorList.filter((colorInfo) => {
          return colorInfo.modeColorId === modeColorId;
        });

        if (filtered.length > 0) {
          return filtered[0].modeColorNm;
        }
      }
    }

    return "";
  };

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    await requestCopyNiceColor(values);
  };

  /**
   * Render Helpers
   */

  const renderForms = () => {
    return (
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        innerRef={(ref: FormikProps<FormikValues>) => {
          formik = ref;
        }}
      >
        {(renderProps) => {
          const {
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          } = renderProps;
          const { modeColorId, colorNm } = values;
          let niceColorErr;
          if (errors.modeColorId && touched.modeColorId) {
            niceColorErr = errors.modeColorId;
          }

          let niceColorNmErr;
          if (errors.colorNm && touched.colorNm) {
            niceColorNmErr = errors.colorNm;
          }

          const formItemLayout = {
            wrapperCol: {
              span: 24,
            },
            labelCol: {
              span: 24,
            },
            style: {
              marginBottom: "16px",
            },
          };

          return (
            <form onSubmit={handleSubmit}>
              {/* 복사 대상 NICE 색상 */}
              <FormItem
                required
                help={niceColorErr}
                validateStatus={niceColorErr && "error"}
                label={commonStrings.LABEL_NICE_INNER_COLOR}
                {...formItemLayout}
              >
                <Select
                  value={modeColorId}
                  placeholder={commonStrings.HINT_NICE_COLOR_COPY_TARGET}
                  options={getNiceInnerColorOptions()}
                  onChange={(value) => {
                    setFieldValue("modeColorId", value);
                    setFieldValue("colorNm", findNiceColorName(value));
                  }}
                />
              </FormItem>
              {modeColorId && (
                <FormItem
                  required
                  label={commonStrings.LABEL_NICE_CUSTOM_COLOR_NAME}
                  {...formItemLayout}
                  help={niceColorNmErr}
                  validateStatus={niceColorNmErr && "error"}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                    value={colorNm}
                    placeholder={commonStrings.HINT_NICE_CUSTOM_COLOR_NAME}
                    onChange={async (e) => {
                      setFieldValue("colorNm", e.target.value);
                    }}
                  />
                </FormItem>
              )}
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      title={commonStrings.MODAL_TITLE_ADD_NICE_INNER_COLOR}
      size="small"
      onOpen={onModalOpen}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      {renderForms()}
    </Modal>
  );
};

AddNiceColorModal.defaultProps = {};
export default AddNiceColorModal;
