import React, { useEffect, useState } from "react";
import { renderLoading } from "../../../../utils/render-utils";
import { fetchUserInfo } from "../../../../apis/session";
import useAuth from "../../../../hooks/useAuth";

interface Props {
  children?: React.ReactNode;
}

const InitialDataLoader: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const [isLoading, setIsLoading] = useState(true);
  const { setUser } = useAuth();

  useEffect(() => {
    (async function fetchData() {
      await requestInitialData();
    })();
  }, []);

  /**
   * Private Functions
   */

  /**
   * 메뉴 및 로그인 상태 확인
   */
  const requestInitialData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchUserInfo();
      const { user } = response;
      setUser(user);
    } catch (e) {
      setUser(undefined);
      // if (e.toString() === "Error: Network Error") {
      //   window.location.href = "https://www.skcarrentalezydirect.com/";
      // }
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Render Helpers
   */
  return (
    <div>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
          }}
        >
          {renderLoading()}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

InitialDataLoader.defaultProps = {};
export default InitialDataLoader;
