import strings from "../constants/strings";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";
import {
  dateFormatRenderer,
  numberFormatRenderer,
} from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";
import commonStrings from "../../../../constants/strings";

export const EVENT_DISPLAY_STATE_RENDERER = "displayStateSwitchRenderer";

export const createEventCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", commonStrings.REG_DATE, FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // 노출상태
    createTableCol("useYn", "노출상태", FilterType.Text, {
      cellRenderer: EVENT_DISPLAY_STATE_RENDERER,
      width: CW_MEDIUM,
    }),
    // 최상단 고정
    createTableCol("topFixedYn", "최상단 고정", undefined, {
      width: CW_SMALL,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "고정" : "미고정",
          },
        };
      },
    }),
    // /(이벤트)제목
    createTableCol("titl", "제목", FilterType.Text, {
      flex: 1,
      minWidth: CW_LARGE,
    }),
    // 시작일
    createTableCol("evntStDt", commonStrings.START_DATE, undefined, {
      width: CW_SMALL,
      cellRenderer: dateFormatRenderer("evntStDt"),
    }),
    // 종료일
    createTableCol("evntEndDt", commonStrings.END_DATE, undefined, {
      width: CW_SMALL,
      cellRenderer: dateFormatRenderer("evntEndDt"),
    }),
    // 파기일
    createTableCol("winDtm", "파기일", undefined, {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("winDtm"),
    }),

    // 응모수
    createTableCol("evntPtcpCnt", "응모 수", undefined, {
      width: CW_SMALL,
      cellRenderer: numberFormatRenderer("evntPtcpCnt"),
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 응모내역
    createTableCol("joinHistory", "응모내역", undefined, {
      width: CW_SMALL,
      cellRenderer: "eventJoinHistoryButton",
    }),

    // 만료 여부
    createTableCol("isActive", "만료여부", FilterType.Text, {
      width: CW_MEDIUM,
      valueGetter: (params) => {
        const { data } = params;
        const { isActive } = data;
        return isActive === "Y" ? "유효" : "만료";
      },
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "유효",
            text: value,
          },
        };
      },
    }),
    {
      ...createActionCol(80),
    },
  ];
};
