import React from "react";
import { Checkbox as AntCheckbox, CheckboxProps as AntCheckboxProps } from "antd";

interface CheckboxProps extends AntCheckboxProps {}

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const { style, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <AntCheckbox
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

Checkbox.defaultProps = {};
export default Checkbox;
