import React, { useState } from "react";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  ExhibitionCatalog,
  ExhibitionMaster,
} from "../../../../../apis/ex/types";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import { formatDateString } from "../../../../../utils/date-utils";
import Button from "../../../../../components/shared/general/antd/Button";
import ExhibitionMasterInfoEditModal from "./pages/ExihibitionMasterInfoEditModal";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  fetchExhibitionCatalogs,
  fetchExhibitionDetail,
} from "../../../../../apis/ex";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createActionCellRenderers } from "../../../../../utils/table-utils";
import { createExhibitionCatalogCols } from "./utils/table-utils";
import TableLayout from "../../../../../components/shared/layout/TableLayout";
import ExhibitionCatalogAddModal from "./pages/ExhibitionCatalogAddModal";
import ExhibitionCatalogSortOrderEditModal from "./pages/ExhibitionCatalogSortOrderEditModal";

interface Props extends ModalProps {
  exhibitionMaster?: ExhibitionMaster;
}

const ExhibitionDetailModal: React.FC<Props> = (props: Props) => {
  const cols = createExhibitionCatalogCols();
  const [catalogs, setCatalogs] = useState<ExhibitionCatalog[]>();
  const [catalogTotalCount, setCatalogTotalCount] = useState(0);
  const { visible, onDataChange, onCancel, exhibitionMaster } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [masterInfoEditModalVisible, setMasterInfoEditModalVisible] = useState(
    false
  );
  const [exhibitionMasterDetail, setExhibitionMasterDetail] = useState<
    ExhibitionMaster
  >();
  const [addExCatalogModalVisible, setAddExCatalogModalVisible] = useState(
    false
  );
  const [
    editCatalogSortOrderModalVisible,
    setEditCatalogSortOrderModalVisible,
  ] = useState(false);

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchExhibitionDetail();
    await requestFetchExhibitionCatalogs();
    setDataFetching(false);
  };

  const requestFetchExhibitionDetail = async () => {
    if (exhibitionMaster) {
      try {
        const exhibition = await fetchExhibitionDetail(
          exhibitionMaster.exhMstSeq
        );
        setExhibitionMasterDetail(exhibition);
      } catch (e) {
        alertError(getErrorMessage(e));
      }
    }
  };

  const requestFetchExhibitionCatalogs = async () => {
    if (exhibitionMaster) {
      const { list, listCnt } = await fetchExhibitionCatalogs(
        exhibitionMaster.exhMstSeq,
        exhibitionMaster.exhGbnCd
      );
      setCatalogTotalCount(listCnt);
      setCatalogs(list);
    }
  };

  const renderDetail = () => {
    if (exhibitionMasterDetail) {
      const exhibitionInfoSection: SectionFieldInfo[] = [
        {
          label: "제목",
          content: exhibitionMasterDetail.exhTitle,
          span: 12,
        },
        {
          label: "유형",
          content: exhibitionMasterDetail.exhGbnNm,
          span: 12,
        },
        {
          label: "설명",
          content: exhibitionMasterDetail.exhDesc,
          span: 24,
        },
        {
          label: "게시순번",
          content: exhibitionMasterDetail.exhMstViewSeq,
        },
        {
          label: "노출기간",
          content: `${formatDateString(
            exhibitionMasterDetail.exhStDt,
            true
          )} ~ ${formatDateString(exhibitionMasterDetail.exhEndDt, true)}`,
          span: 12,
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <Button
                onClick={() => {
                  setMasterInfoEditModalVisible(true);
                }}
              >
                정보수정
              </Button>
            );
          },
        },
      ];

      return (
        <div>
          <DetailSection label="기본 정보" fieldInfos={exhibitionInfoSection} />
          <TableLayout
            hideExcelAction
            hideFilterAction
            title="기획전 차량 리스트"
            totalCount={catalogTotalCount}
            rightActions={[
              {
                title: "게시순번 관리",
                onClick: () => {
                  if (!catalogs || catalogs.length === 0) {
                    alertError(
                      "기획전에 등록된 차량이 없습니다. 차량을 먼저 등록해주세요."
                    );
                  } else {
                    setEditCatalogSortOrderModalVisible(true);
                  }
                },
              },
              {
                title: "차량 관리",
                onClick: () => {
                  setAddExCatalogModalVisible(true);
                },
              },
            ]}
            methodName={`/admin/ex-catalogs/${exhibitionMaster?.exhMstSeq}/Y`}
          >
            <div
              style={{
                height: "400px",
              }}
            >
              <ClientRowTable
                cols={cols}
                rowData={catalogs}
                frameworkComponents={{
                  ...createActionCellRenderers(undefined, undefined),
                }}
              />
            </div>
          </TableLayout>
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        visible={visible}
        onCancel={onCancel}
        title="기획전 상세 (신차장기)"
        onOpen={onModalOpen}
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      {/* 기획전 기본정보 수정 */}
      <ExhibitionMasterInfoEditModal
        visible={masterInfoEditModalVisible}
        onCancel={() => {
          setMasterInfoEditModalVisible(false);
        }}
        exhibitionMaster={exhibitionMasterDetail}
        onDataChange={async () => {
          await requestFetchExhibitionDetail();
          if (onDataChange) onDataChange();
        }}
      />
      {/* 기획전 차량 게시순번관리 */}
      <ExhibitionCatalogSortOrderEditModal
        exhibitionMaster={exhibitionMaster}
        visible={editCatalogSortOrderModalVisible}
        onCancel={() => {
          setEditCatalogSortOrderModalVisible(false);
        }}
        onDataChange={async () => {
          await requestFetchExhibitionCatalogs();
        }}
      />
      {/* 기획전 차량연결 */}
      <ExhibitionCatalogAddModal
        initialCheckList={catalogs}
        exhibitionMaster={exhibitionMasterDetail}
        visible={addExCatalogModalVisible}
        onCancel={() => {
          setAddExCatalogModalVisible(false);
        }}
        onDataChange={async () => {
          await requestFetchExhibitionCatalogs();
        }}
      />
    </>
  );
};

export default ExhibitionDetailModal;
