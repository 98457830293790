import {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_LONG_TEXT,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../../utils/table-utils";
import strings from "../../../../../car-sales/NewCarCatalogManager/constants/strings";
import commonStrings from "../../../../../../constants/strings";

export const createExhibitionCatalogCols = () => {
  return [
    // 게시순번
    createTableCol("exhViewSeq", "게시순번", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 제조사
    createTableCol("repMakerNm", strings.COL_BRAND, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 상품명
    createTableCol("prodNm", strings.COL_CATALOG_NAME, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 모델
    createTableCol("repCarTypeNm", strings.COL_MODEL, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 연식
    createTableCol("yearType", "연식", FilterType.Text, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // 필수값 입력완료
    createTableCol("certainValue", "필수값 입력완료", FilterType.Text, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
