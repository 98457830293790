import React, { CSSProperties } from "react";
import { TableHeaderTitle } from "./style";

interface Props {
  children?: React.ReactNode;
  headerTitle?: string;
  style?: CSSProperties;
}

const ModalTableWrapper: React.FC<Props> = (props: Props) => {
  const { children, headerTitle, style } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <div style={style}>
      {headerTitle && <TableHeaderTitle>{headerTitle}</TableHeaderTitle>}
      <div
        style={{
          width: "100%",
          height: "500px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

ModalTableWrapper.defaultProps = {};
export default ModalTableWrapper;
