import React, { useState } from "react";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import {FormElementType, FormInfo} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {alertSuccess, renderLoading} from "../../../../../../../utils/render-utils";
import {FormikProps, FormikValues} from "formik";
import {updateUcCooperCompanyCond} from "../../../../../../../apis/auc-master";
import AucCooperDetail from "../../../../../../../models/AucCooperDetail";
import {  
  CooperDetailRequest,
  commCdList
} from "../../../../../../../apis/auc-master/types";

interface Props extends ModalProps {
  aucCooperDetail?: AucCooperDetail;
}

const PrmsDtcListModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, aucCooperDetail, onDataChange } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [defaultCompCd, setDefaultCompCd] = useState<string>();
  const [default1, setDefault1] = useState<boolean>();
  const [default2, setDefault2] = useState<boolean>();
  const [default3, setDefault3] = useState<boolean>();
  const [default4, setDefault4] = useState<boolean>();


  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);

    if (aucCooperDetail) {
      setDefaultCompCd(aucCooperDetail.compCd);
      setDefault1(aucCooperDetail.prmsDtcList[0].useYn == "Y" ? true : false);
      setDefault2(aucCooperDetail.prmsDtcList[1].useYn == "Y" ? true : false);
      setDefault3(aucCooperDetail.prmsDtcList[2].useYn == "Y" ? true : false);
      setDefault4(aucCooperDetail.prmsDtcList[3].useYn == "Y" ? true : false);
    } else {
      setDefaultCompCd("");
      setDefault1(false);
      setDefault2(false);
      setDefault3(false);
      setDefault4(false);
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const forms: Array<FormInfo> = [
    {      key: "A06001",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "1만Km 이하",        }, 
    {      key: "A06003",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "2만Km 이하",        },
    {      key: "A06005",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "3만Km 이하",        },
    {      key: "A06008",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "4만Km 이하",        },
  ]

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
      setConfirmLoading(true);
      const commCdList: commCdList[] = [];
      if(values.A06001) {  const commCd = { condCd: "A06001" as string, condVal: "0" as string  }; commCdList.push(commCd); }
      if(values.A06003) {  const commCd = { condCd: "A06003" as string, condVal: "0" as string  }; commCdList.push(commCd); }
      if(values.A06005) {  const commCd = { condCd: "A06005" as string, condVal: "0" as string  }; commCdList.push(commCd); }
      if(values.A06008) {  const commCd = { condCd: "A06008" as string, condVal: "0" as string  }; commCdList.push(commCd); }

      const params: CooperDetailRequest = {
        condClsCd: "A17002",
        condCdList: commCdList,
      };

      if (aucCooperDetail) {
        try {
          await updateUcCooperCompanyCond(defaultCompCd as string, params);
          alertSuccess("변경되었습니다.");
          if (onDataChange) onDataChange();
          if (onCancel) onCancel();
        } catch (e) {          
          console.log(e);
        } finally {
          setConfirmLoading(false);
        }
      } else {
        setConfirmLoading(false);
        if (onCancel) onCancel();
      }
  }; 

  /**
   * Render Helpers
   */


  return (
    <Modal
      size="small"
      title=" 약정주행거리 변경"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? (
          renderLoading()
      ) : (
          <FormBuilder
              formRef={(ref) => {
                formik = ref;
              }}
              initialValues={
                {
                  A06001: default1,
                  A06003: default2,
                  A06005: default3,
                  A06008: default4,
                }
              }
              forms={forms}
              onSubmit={handleSubmit}
          />
      )}
    </Modal>
  );
};

PrmsDtcListModal.defaultProps = {
  visible: true,
};
export default PrmsDtcListModal;
