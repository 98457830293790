import React, { useState } from "react";
import { GridApi } from "ag-grid-community";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../types";
import StockInfoReport from "../../../../../models/StockInfoReport";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { fetchStockInfoReportDetail } from "../../../../../apis/report";
import StockInfoReportDetail from "../../../../../models/StockInfoReportDetail";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createStockReportDetailCols } from "./utils/table-utils";
import TableLayout from "../../../../../components/shared/layout/TableLayout";

interface Props extends ModalProps {
  report?: StockInfoReport;
}

const StockReportDetailModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, report } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [details, setDetails] = useState<StockInfoReportDetail[]>();
  const [totalCount, setTotalCount] = useState(0);
  const cols = createStockReportDetailCols();
  const [gridApi, setGridApi] = useState<GridApi>();
  /**
   * Private Functions
   */

  const onModalOpen = async () => {
    if (report) {
      await requestFetchStockReportDetail(report.cossModlId);
    }
  };

  const requestFetchStockReportDetail = async (cossModlId: string) => {
    try {
      setDataFetching(true);
      const { items, count } = await fetchStockInfoReportDetail(cossModlId);
      setDetails(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  const renderDetail = () => {
    return (
      <div
        style={{
          height: "700px",
        }}
      >
        <TableLayout
          title="차량 리스트"
          totalCount={totalCount}
          hideFilterAction
          gridApi={gridApi}
          methodName={`/admin/stock-info/${report?.cossModlId}`}
        >
          <ClientRowTable
            cols={cols}
            rowData={details}
            apiRef={(gridApi) => {
              setGridApi(gridApi);
            }}
          />
        </TableLayout>
      </div>
    );
  };

  return (
    <Modal
      title="재고 현황 상세"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
    >
      {dataFetching ? renderLoading() : renderDetail()}
    </Modal>
  );
};

StockReportDetailModal.defaultProps = {};
export default StockReportDetailModal;
