import React from "react";
import { Switch as AntSwitch, SwitchProps as AntSwitchProps } from "antd";

interface SwitchProps extends AntSwitchProps {}

const Switch: React.FC<SwitchProps> = (props: SwitchProps) => {
  const { style, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <AntSwitch
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

Switch.defaultProps = {};
export default Switch;
