import { FormikValues } from "formik";
import * as Yup from "yup";
import {
  getInitialDateRangeFromRaw,
  getParamFromDateRange,
} from "../../../../../../../../utils/form-utils";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../../components/shared/data-entry/FormBuilder/types";
import BannerInfo from "../../../../../../../../models/BannerDetail/BannerInfo";
import errorMessages from "../../../../../../../../constants/errors";
import { UPLOAD_CODES } from "../../../../../../../../constants/enums";

export const createInitialValues = () => {
  return {
    bnnrTitleSeq: "",
    bnnrTitle: "",
    bnnrTerm: null,
    bnnrCooperLinkUrl: "",
    images: [],
  };
};

export const createInitialValuesFromBannerInfo = (bannerInfo: BannerInfo) => {
  const {
    bnnrCooperLinkUrl,
    bnnrStDt,
    bnnrEndDt,
    fileUrl,
    bnnrTitle,
    bnnrTitleSeq,
  } = bannerInfo;

  return {
    bnnrTitleSeq,
    bnnrTitle,
    bnnrTerm: getInitialDateRangeFromRaw(bnnrStDt, bnnrEndDt),
    bnnrCooperLinkUrl,
    images: [
      {
        imageUrl: fileUrl,
      },
    ],
  };
};

export const createValidationSchema = (mainBanner = false) => {
  const schemaShape = {
    bnnrCooperLinkUrl: Yup.string().url(errorMessages.INVALID_URL).nullable(),
    bnnrTerm: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(2, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    images: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(1, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  };

  if (mainBanner) {
    // @ts-ignore
    schemaShape.bnnrTitle = Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .nullable();
    // @ts-ignore
    schemaShape.bnnrTitleSeq = Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD);
  }

  return Yup.object().shape(schemaShape);
};

export const createAddBannerParam = (values: FormikValues) => {
  const {
    bnnrCooperLinkUrl,
    bnnrTerm,
    images,
    bnnrTitle,
    bnnrTitleSeq,
  } = values;

  let bnnrStDt;
  let bnnrEndDt;
  if (bnnrTerm && bnnrTerm.length > 0) {
    const { startDateParam, endDateParam } = getParamFromDateRange(bnnrTerm);
    bnnrStDt = startDateParam;
    bnnrEndDt = endDateParam;
  }

  return {
    fileUrl: images[0].imageUrl,
    bnnrCooperLinkUrl,
    bnnrStDt: bnnrStDt as string,
    bnnrEndDt: bnnrEndDt as string,
    bnnrTitle,
    bnnrTitleSeq,
  };
};

export const createEditBannerParam = (values: FormikValues) => {
  const {
    bnnrCooperLinkUrl,
    bnnrTerm,
    images,
    bnnrTitle,
    bnnrTitleSeq,
  } = values;

  let bnnrStDt;
  let bnnrEndDt;
  if (bnnrTerm && bnnrTerm.length > 0) {
    const { startDateParam, endDateParam } = getParamFromDateRange(bnnrTerm);
    bnnrStDt = startDateParam;
    bnnrEndDt = endDateParam;
  }

  return {
    fileUrl: images[0].imageUrl,
    bnnrCooperLinkUrl,
    bnnrStDt,
    bnnrEndDt,
    bnnrTitle,
    bnnrTitleSeq,
  };
};

export const createFormInfo = (mainBanner = false): FormInfo[] => {
  const forms: FormInfo[] = [
    {
      // 노출 기간
      key: "bnnrTerm",
      type: FormElementType.DateRangePicker,
      label: "노출 기간",
      required: true,
    },
  ];

  if (mainBanner) {
    forms.push({
      // 배너명
      key: "bnnrTitle",
      type: FormElementType.Input,
      label: "배너명",
      required: true,
      placeholder: "메인배너등록시 필수로 입력",
    });

    forms.push({
      // 배너순서
      key: "bnnrTitleSeq",
      type: FormElementType.InputNumber,
      label: "게시순번",
      required: true,
      placeholder: "게시순번를 입력해주세요",
      config: {
        min: 0,
      },
    });
  }

  return [
    ...forms,
    // 링크
    {
      key: "bnnrCooperLinkUrl",
      type: FormElementType.Input,
      label: "링크",
      placeholder: "제휴링크 입력",
    },
    // 이미지
    {
      key: "images",
      type: FormElementType.ImageUpload,
      label: "이미지",
      uploadCodeInfo: UPLOAD_CODES.BANNER,
      required: true,
    },
  ];
};
