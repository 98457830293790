import React, { useRef, useState } from "react";
import {
  GridApi,
  ICellRendererParams,
  LoadSuccessParams,
} from "ag-grid-community";
import moment from "moment";
import {
  MC_RSV_STATE_SWITCH,
  IMG_RENDERER,
  createNewCarCatalogCols, createSwitchRenderer,
} from "./utils/table-utils";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import { getErrorMessage } from "../../../utils/common-utils";

import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import {
  getPageIndex,
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import commonStrings from "../../../constants/strings";
import { OPTIONS_MC_PROD_TYPE, OPTIONS_MC_RSV_YN, OPTIONS_UC_PROD_TYPE, OPTIONS_YN, OPTION_MC_YN, OPTION_TB_YN } from "../../../constants/enums";
import { get1MonthDateRange } from "../../../utils/date-utils";
import McCarCatalogModal from "./pages/McCarCatalogModal";
import { fetchMcCatalogs, updateMcCatalogDisplayState, updateMcRsvibilityState } from "../../../apis/mc-catalogs";
import { renderSwitchCol, imgRenderer } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

import McCarCatalog from "../../../models/McCarCatalog";
import McCartypeTagModal from "./pages/McCartypeTagModal";

const McCarCatalogManager: React.FC = () => {
  const gridApiRef = useRef<GridApi>();
  const [gridApi, setGridApi] = useState<GridApi>();
  const cols = createNewCarCatalogCols();
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [tagModalVisible, setTagModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string>();
  const [logInfo, setLogInfo] = useState<string>("");

  const getInitialDateRange = () => {
    return get1MonthDateRange();
  };

  const filterInitialValues = {
    stDt: getInitialDateRange(),
    cossId: "",
    carNo: "",
    brandNm: "",
    repCarClassNm: "",
    carClassNm: "",
    carGradeNm: "",
    yearType: "",
    lastDtc: "",
    fuel: "",
    istdTrans: "",
    carNmInfo: "",
    fixYn: "",
    ncarClsFlag: "",
    niceConYn: "",
    useYn: "",
    viewYn: "",
    rsvPsblYn: "",
  }; 

  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "등록일",
    },
    {
      key: "cossId",
      type: FilterElementType.Input,
      label: "COSS ID",
      placeholder: commonStrings.HINT_COSS_MODEL_ID,
    },
    {
      key: "carNo",
      type: FilterElementType.Input,
      label: "차량번호",
      placeholder: commonStrings.HINT_CAR_NO,
    },
    {
      key: "brandNm",
      type: FilterElementType.Input,
      label: "제조사",
      placeholder: commonStrings.HINT_COSS_BRAND_NAME,
    },
    {
      key: "repCarClassNm",
      type: FilterElementType.Input,
      label: "모델",
      placeholder: commonStrings.HINT_REP_CAR_CLASS_NM,
    },
    {
      key: "carClassNm",
      type: FilterElementType.Input,
      label: "세부모델",
      placeholder: commonStrings.HINT_CAR_CLASS_NM,
    },
    {
      key: "carGradeNm",
      type: FilterElementType.Input,
      label: "트림",
      placeholder: commonStrings.HINT_CAR_GRADE_NM,
    },
    {
      key: "yearType",
      type: FilterElementType.Input,
      label: "연식",
      placeholder: commonStrings.HINT_YEAR_TYPE,
    },
    {
      key: "startLastDtc",
      type: FilterElementType.Input,
      label: "주행거리(시작)",
      placeholder: commonStrings.HINT_LAST_DTC,
    },
    {
      key: "endLastDtc",
      type: FilterElementType.Input,
      label: "주행거리(종료)",
      placeholder: commonStrings.HINT_LAST_DTC,
    },
    {
      key: "fuel",
      type: FilterElementType.Input,
      label: "연료",
      placeholder: "연료를 입력해주세요",
    },
    {
      key: "istdTrans",
      type: FilterElementType.Input,
      label: "변속",
      placeholder: "변속을 입력해주세요",
    },
    {
      key: "carNmInfo",
      type: FilterElementType.Select,
      options: OPTIONS_YN,
      label: "차량정보",
    },
    {
      key: "niceConYn",
      type: FilterElementType.Select,
      options: OPTIONS_YN,
      label: "NICE 연결여부",
    },
    {
      key: "fixYn",
      type: FilterElementType.Select,
      options: OPTIONS_YN,
      label: "정비이력",
    },
    {
      key: "ncarClsFlag",
      type: FilterElementType.Select,
      options: OPTIONS_MC_PROD_TYPE,
      label: "상품유형",
    },
    {
      key: "useYn",
      type: FilterElementType.Select,
      options: OPTION_MC_YN,
      label: "월렌트 등록여부",
    },
    {
      key: "viewYn", 
      type: FilterElementType.Select,
      options: OPTIONS_YN,
      label: "월렌트 노출여부",
    },
    {
      key: "rsvPsblYn", 
      type: FilterElementType.Select,
      options: OPTIONS_MC_RSV_YN,
      label: "예약 가능여부",
    },

  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const {
      stDt,
      cossId,
      carNo,
      brandNm,
      repCarClassNm,
      carClassNm,
      carGradeNm,
      yearType,
      startLastDtc,
      endLastDtc,
      fuel,
      istdTrans,
      carNmInfo,
      fixYn,
      niceConYn,
      ncarClsFlag,
      useYn,
      viewYn,
      rsvPsblYn
    } = appliedFilterValues;
    const regStDt = moment(stDt[0]).format("YYYYMMDD");
    const regEndDt = moment(stDt[1]).format("YYYYMMDD");

    try {
      const { items, count } = await fetchMcCatalogs({
        pageSize: paginationPageSize,
        pageIndex: getPageIndex(startRow, paginationPageSize),
        regStDt,
        regEndDt,
        cossId,
        carNo,
        brandNm,
        repCarClassNm,
        carClassNm,
        carGradeNm,
        yearType,
        startLastDtc,
        endLastDtc,
        fuel,
        istdTrans,
        carNmInfo,
        fixYn,
        niceConYn,
        ncarClsFlag,
        useYn,
        viewYn,
        rsvPsblYn
      });
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
    } catch (e) {
      setTotalCount(0);
      success({
        rowData: [],
        rowCount: 0,
      });
    }
  }; 

  const requestUpdateDisplayState = async (
    prodId: string,
    carId: string,
    checked: boolean,
    useYn: string,
  ) => {
    try {

      await updateMcCatalogDisplayState(prodId, carId, useYn, checked ? "Y" : "N");

      if(useYn === "-") {
        alertSuccess("월렌트 등록 되었습니다");
      } else {
        alertSuccess("월렌트 노출여부가 변경 되었습니다");
      }      
      await refreshServerSideTable(gridApiRef.current);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };


  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { prodId } = data;
    setSelectedDataId(prodId);
    setModalVisible(true);
  };


  const handleRsvStateSwitchChange = (    
    checked: boolean,   
    mcCarCatalog: McCarCatalog,
    gridApi?: GridApi
  ) => {
    const rsvPsblYn = checked? "Y" : "N";

    const rsvInfo = {
      "prodId": mcCarCatalog.prodId,
      "rsvPsblYn": rsvPsblYn,
    }

    showConfirm(

      checked?
        commonStrings.CONFIRM_RSVIBILITY_ON
        : commonStrings.CONFIRM_RSVIBILITY_OFF,

      checked?
        commonStrings.CONFIRM_STATE_RSVIBILITY_ON
        : commonStrings.CONFIRM_STATE_RSVIBILITY_OFF,

        async () => {
    
          try {
            await updateMcRsvibilityState(
              mcCarCatalog.prodId, 
              rsvInfo
            );
            alertSuccess("예약가능 정보가 변경되었습니다.");
            refreshServerSideTable(gridApi);

          } catch (e) {
            alertError(getErrorMessage(e));
            refreshServerSideTable(gridApi);
          }
        }
    );
    
  };


  const handleViewStateSwitchChange = (    
    checked: boolean,   // 노출의도여부
    prodId: string,
    carId: string,
    useYn: string,
  ) => {
    // console.log("useYn : "+useYn);
    // console.log("checked : "+checked);
    // console.log("prodId : "+prodId);
    // console.log("carId : "+carId);

    if(useYn === "-") {
      showConfirm(
        "월렌트 상품등록",
        "월렌트 상품을 등록하시겠습니까?",
        async () => {
          await requestUpdateDisplayState(prodId, carId, checked, "Y");
        }
      );
    } else {
      // 월렌트 등록사용 상품
        showConfirm(
          useYn === '-' ? ("월렌트 상품등록") : (checked ? "노출상태로 변경" : "숨김상태로 변경"),
          useYn === '-' ? ("월렌트 상품을 등록하시겠습니까?") : (checked ? "해당상품을 노출상태로 변경하시겠습니까?" : "해당상품을 숨김상태로 변경하시겠습니까?"),
          async () => {
            await requestUpdateDisplayState(prodId, carId, checked, "Y");
          }
        );
        
    }
  };
  

  /**
   * Render Helpers
   */

  const createTableActions = () => {
    return [
      {
        title: "차종 태그관리",
        onClick: async () => {
          setTagModalVisible(true);
        },
      },
    ];
  };

  return (
    <>
      <PaginationTableLayout
        tableHeaderTitle=""
        apiRef={(api) => {
          setGridApi(api);
          gridApiRef.current = api;
        }}
        headerTitle="온라인 상품관리(월렌트)"
        headerDescription="월렌트 렌터카 상품 정보를 관리 합니다."
        totalCount={totalCount}
        hideFilterAction
        hideSelectionCountText
        leftActions={createTableActions()}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        onDataLoad={onDataLoad}
        frameworkComponents={{
          [IMG_RENDERER]: (props: ICellRendererParams) => {
            return props.value ? imgRenderer(props.value, 80) : ""; // vh => ["mainCarImg","xtnlClrImgUrl"].includes(String(props.colDef.field)) ? 5 : 2
          },
          [MC_RSV_STATE_SWITCH]: renderSwitchCol(
            "rsvPsblYn",
            handleRsvStateSwitchChange
          ),
            ...createActionCellRenderers(undefined, handleDetailClick, {
              hideDeleteButton: true,
            }),
            ...createSwitchRenderer(handleViewStateSwitchChange, "ucMmViewYn"),
          } 
        }
        methodName="/admin/mm-catalogs"
        logInfo={logInfo}
      />
      <McCarCatalogModal
        onDataChange={async () => {
          await refreshServerSideTable(gridApi);
        }}
        onCancel={async() => {
          setModalVisible(false);
          await refreshServerSideTable(gridApi);
        }}
        visible={modalVisible}
        dataId={selectedDataId}
      />
      <McCartypeTagModal
        // onDataChange={async () => {
        //   await requestFetchNCCatalogs();
        // }}
        visible={tagModalVisible}
        onCancel={() => {
          setTagModalVisible(false);
        }}
      />
    </>
  );
};

McCarCatalogManager.defaultProps = {};
export default McCarCatalogManager;
