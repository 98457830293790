import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import { editReview } from "../../../../../../../apis/reviews";
import ReviewDetail from "../../../../../../../models/ReviewDetail";
import { ImageInfo } from "../../../../../../../components/shared/data-entry/ImageUpload";
import { FormElementType } from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import { UPLOAD_CODES } from "../../../../../../../constants/enums";
import errorMessages from "../../../../../../../constants/errors";

interface Props extends ModalProps {
  reviewDetail?: ReviewDetail;
}

const ReviewImageEditModal: React.FC<Props> = (props: Props) => {
  const REVIEW_MAX_UPLOAD_COUNT = 10;
  const formInitialValues = {
    images: [],
  };
  const validationSchema = Yup.object().shape({
    images: Yup.array().min(1, errorMessages.REQUIRED_FIELD),
  });
  let formik: FormikProps<FormikValues>;
  const { visible, onCancel, onDataChange, reviewDetail } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [forms, setForms] = useState<any>();
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);

  const onModalOpen = () => {
    if (reviewDetail) {
      setInitialValues({
        images: reviewDetail.fileInfoList.map(({ fileUrl, fileId }) => ({
          imageUrl: fileUrl,
          fileId,
        })),
      });
      setForms(createForms());
    }
  };

  /**
   * Private Functions
   */

  const createForms = () => {
    return [
      {
        key: "images",
        type: FormElementType.ImageUpload,
        label: "",
        uploadCodeInfo: UPLOAD_CODES.EVENT,
        config: {
          maxUploadCount: REVIEW_MAX_UPLOAD_COUNT,
        },
      },
    ];
  };

  const requestEditReviewImages = async (values: FormikValues) => {
    if (reviewDetail) {
      setConfirmLoading(true);
      try {
        const { images } = values;
        await editReview(reviewDetail.cntrId, {
          fileIdList: images.map((image: ImageInfo) => image.fileId),
        });
        alertSuccess("후기정보가 변경되었습니다");
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    await requestEditReviewImages(values);
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      visible={visible}
      onCancel={onCancel}
      title="후기 이미지 수정"
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
        forms={forms}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

ReviewImageEditModal.defaultProps = {};
export default ReviewImageEditModal;
