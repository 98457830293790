import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";
import { USE_YN_RENDERER } from "../../BannerManager/pages/BannerDetailModal/utils/table-utils";
import { formatDateString } from "../../../../utils/date-utils";

export const createExhibitionMasterCols = (): Array<TableColumn> => {
  return [
    createTableCol("exhStDt", "노출기간", undefined, {
      width: CW_DATE_RANGE,
      valueGetter: (params) => {
        const { data } = params;
        const { exhStDt, exhEndDt } = data;
        return `${formatDateString(exhStDt, true)} ~ ${formatDateString(
          exhEndDt,
          true
        )}`;
      },
    }),
    createTableCol("exhMstViewSeq", "게시순번", undefined, {
      width: CW_SMALL,
    }),
    createTableCol("exhTitle", "제목", FilterType.Text, {
      width: CW_LARGE,
      flex: 1,
    }),
    createTableCol("exhDesc", "설명", FilterType.Text, {
      width: CW_LARGE,
      flex: 1,
    }),
    createTableCol("exhUseYn", "사용유무", undefined, {
      width: CW_MEDIUM,
      cellRenderer: USE_YN_RENDERER,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
