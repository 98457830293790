import { plainToClass } from "class-transformer";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import UploadResult from "../../models/UploadResult";

const apiRootUrl = `${ROOT_URL}/com/uploadfiles`;

export const uploadFile = async (
  jobClsCd: string,
  idtfClsCd: string,
  formData: FormData
): Promise<UploadResult> => {
  const res = await request(
    HttpMethod.Post,
    apiRootUrl,
    { jobClsCd, idtfClsCd },
    formData,
    undefined,
    true
  );

  const { data } = res;
  const { response } = data;

  return plainToClass(UploadResult, response);
};
