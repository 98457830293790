export const ENUM_OPT_CLR_CD_OPT = "OPT";
export const ENUM_OPT_CLR_CD_CLR = "CLR";

export default class OptionLogicInfo {
  public seq = "";
  public cartypeDtlId = "";
  // 옵션 / 색상 구분 코드
  public optClrCd = "";
  // 옵션 및 색상 코드
  public optClrId = "";
  // 필수옵션
  public essenId = "";
  // 중복 불가 옵션
  public nodupId = "";
  // 패키지 리스트
  public pkgList = "";
  // 선택가능 색상
  public possibleClr = "";
}
