import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { CR_STATUS_TEXT } from "../../../../../../components/shared/data-display/Tables/components/ServerRowTable";

export const createCols = (): Array<TableColumn> => {
  return [
    // 요청일
    createTableCol("changeReqDt", "요청일", undefined, {
      width: CW_SMALL_M,
      cellRenderer: dateFormatRenderer("changeReqDt"),
    }),
    // 변경요청 항목
    createTableCol("changeRecptNm", "변경요청 항목", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 변경 전
    createTableCol("changeBfCntn", "변경 전", FilterType.Text, {
      flex: 1,
      minWidth: CW_LARGE,
    }),
    // 변경 후
    createTableCol("changeAfCntn", "변경 후", FilterType.Text, {
      flex: 1,
      minWidth: CW_LARGE,
    }),
    // 처리상태
    createTableCol("changePrgsStsNm", "처리상태", FilterType.Text, {
      width: CW_SMALL,
    }),
  ];
};

export const createMemoCols = (): Array<TableColumn> => {
  return [
    // 등록일시
    createTableCol("regDtm", "등록일시", undefined, {
      width: CW_LARGE,
      cellRenderer: dateFormatRenderer("regDtm", true),
    }),
    // 상담원명
    createTableCol("cnslUserNm", "상담원명", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 완료여부
    createTableCol("cnslEndYn", "완료여부", FilterType.Text, {
      width: CW_SMALL,
      cellRendererSelector: (data) => {
        const { value } = data;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "완료" : "미완료",
          },
        };
      },
    }),
    // 메모 내용
    createTableCol("memoCntn", "메모내용", FilterType.Text, {
      flex: 1,
      minWidth: CW_LARGE,
    }),
    createActionCol(80),
  ];
};
