import { FormikProps, FormikValues } from "formik";
import moment, { Moment } from "moment";

export const getFormError = (
  renderProps: FormikProps<FormikValues>,
  key: string
): {
  help: any;
  validateStatus: any;
} => {
  const { errors, touched } = renderProps;
  if (errors[key] && touched[key]) {
    return {
      help: errors[key] as string,
      validateStatus: "error",
    };
  }
  return {
    help: undefined,
    validateStatus: "",
  };
};

export const getInitialDateRangeFromRaw = (stDt?: string, endDt?: string) => {
  if (typeof stDt === "string" && typeof endDt === "string") {
    return [moment(stDt), moment(endDt)];
  }
  return null;
};

export const getParamFromDateRange = (dateRange?: Array<Moment>) => {
  let startDateParam;
  let endDateParam;
  if (dateRange && dateRange.length > 1) {
    const [start, end] = dateRange;
    startDateParam = start.format("YYYYMMDD");
    endDateParam = end.format("YYYYMMDD");
  }

  return {
    startDateParam,
    endDateParam,
  };
};
