import React, { useEffect, useState } from "react";
import moment from "moment";
import { GridApi } from "ag-grid-community";
import { createCols } from "./utils/table-utils";
import { get3MonthDateRange } from "../../../utils/date-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import { fetchMGMReport } from "../../../apis/special-amt";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import TableLayout from "../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import MGMReport from "../../../models/MGMReport";
import { alertError } from "../../../utils/render-utils";

const MGMReportManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rows, setRows] = useState<Array<MGMReport> | undefined>(undefined);
  const [totalCount, setTotalCount] = useState(0);
  const [logInfo, setLogInfo] = useState<string>("");

  const cols = createCols();

  const filterInitialValues = {
    // 가입일
    stEntryDt: get3MonthDateRange(),
    // 계약일
    stCntrDt: null,
    // 고객명
    custNm: "",
    // 추천횟수(범위)
    stRecCnt: null,
    // 리워드횟수(범위)
    stRecRewardCnt: null,
    // 리워드금액(범위)
    stRecRewardSumAmt: null,
  };

  const filterForms = [
    {
      key: "stEntryDt",
      type: FilterElementType.DateRangePicker,
      label: "가입일",
    },
    {
      key: "stCntrDt",
      type: FilterElementType.DateRangePicker,
      label: "계약 종료일",
    },
    {
      key: "custNm",
      type: FilterElementType.Input,
      placeholder: "고객명을 입력해주세요",
      label: "고객명",
    },
    {
      key: "stRecCnt",
      type: FilterElementType.NumberRange,
      label: "추천횟수",
    },
    {
      key: "stRecRewardCnt",
      type: FilterElementType.NumberRange,
      label: "리워드횟수",
    },
    {
      key: "stRecRewardSumAmt",
      type: FilterElementType.NumberRange,
      label: "리워드금액",
    },
  ];

  const [filterValues, setFilterValues] = useState<any>({
    ...filterInitialValues,
  });

  useEffect(() => {
    (async function fetchData() {
      await requestDataList(filterValues);
    })();
    return () => {};
  }, []);

  /**
   * Private Functions
   */

  const requestDataList = async (filterValues: any) => {
    try {
      // 검색조건 설정
      const appliedFilters = JSON.stringify(filterValues);
      setLogInfo(appliedFilters);
      const {
        stEntryDt,
        stCntrDt,
        custNm,
        stRecCnt,
        stRecRewardCnt,
        stRecRewardSumAmt,
      } = filterValues;

      // 가입일
      const stEntryDtParam = moment(stEntryDt[0]).format("YYYYMMDD");
      const endEntryDtParam = moment(stEntryDt[1]).format("YYYYMMDD");

      // 계약종료일
      let stCntrEndParam;
      let endCntrEndParam;
      if (stCntrDt && stCntrDt.length > 1) {
        stCntrEndParam = moment(stCntrDt[0]).format("YYYYMMDD");
        endCntrEndParam = moment(stCntrDt[1]).format("YYYYMMDD");
      }

      // 추천횟수
      let stRecCntParam;
      let endRecCntParam;
      if (stRecCnt && stRecCnt.length > 1) {
        const [start, end] = stRecCnt;
        if (typeof start === "number") stRecCntParam = start.toString();
        if (typeof end === "number") endRecCntParam = end.toString();
      }

      // 리워드횟수
      let stRecRewardCntParam;
      let endRecRewardCntParam;
      if (stRecRewardCnt && stRecRewardCnt.length > 1) {
        const [start, end] = stRecRewardCnt;
        if (typeof start === "number") stRecRewardCntParam = start.toString();
        if (typeof end === "number") endRecRewardCntParam = end.toString();
      }

      // 리워드금액
      let stRecRewardSumAmtParam;
      let endRecRewardSumAmtParam;
      if (stRecRewardSumAmt && stRecRewardSumAmt.length > 1) {
        const [start, end] = stRecRewardSumAmt;
        if (typeof start === "number")
          stRecRewardSumAmtParam = start.toString();
        if (typeof end === "number") endRecRewardSumAmtParam = end.toString();
      }

      const mgmReports = await fetchMGMReport(
        stEntryDtParam,
        endEntryDtParam,
        stCntrEndParam,
        endCntrEndParam,
        custNm,
        stRecCntParam,
        endRecCntParam,
        stRecRewardCntParam,
        endRecRewardCntParam,
        stRecRewardSumAmtParam,
        endRecRewardSumAmtParam
      );
      const { items, count } = mgmReports;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      if (e.status !== 404) {
        if (e.getMessage) alertError(e.getMessage());
        else alertError(e.toString());
      }
      setRows([]);
    }
  };

  /**
   * Event Actions
   */

  const handleSearchSubmit = async (values: any) => {
    setFilterValues(values);
    gridApi?.showLoadingOverlay();
    await requestDataList(values);
    gridApi?.hideOverlay();
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout
        filterable
        headerTitle="친구추천 가입자 관리"
        filterForms={filterForms}
        filterValues={filterValues}
        filterInitialValues={filterInitialValues}
        onSearchSubmit={handleSearchSubmit}
      >
        <TableLayout
          gridApi={gridApi}
          title="가입자 리스트"
          totalCount={totalCount}
          hideSelectionCountText
          methodName="/admin/special-amt-reward-report"
          logInfo={logInfo}
        >
          <ClientRowTable
            floatingFilter={false}
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            cols={cols}
          />
        </TableLayout>
      </ContentLayout>
    </>
  );
};

MGMReportManager.defaultProps = {};
export default MGMReportManager;
