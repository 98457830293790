/**
 * 경상이익율 정보
 */
export class OincRtSet {
  // 상품ID
  prodId: string = "";
  // 상품 조정 구분 코드
  prodAdjClsCd: string = "A67001";
  // 상품 조정 유형 코드
  prodAdjTypCd: string = "A40001";
  // 상품 조정 값
  prodAdjVal: string = "";
}