import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_HEADER_RENT_CONTRACT_MANAGER: "계약관리 (렌탈형)",
    DESC_RENT_CONTRACT_MANAGER:
      "COSS 계약 내용을 조회 합니다. 계약 정보 수정은 COSS에서 진행해 주시기 바랍니다.",
    TABLE_HEADER_RENT_CONTRACT_LIST: "계약 리스트",
  },
});
