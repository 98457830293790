import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  common: {
    colors: {
      point: "#626DFF",
      subHeaderTitle: "#0A0C1C",
      contentDetailBg: "#eeeff5",
      border: "#eeeff5",
      black: "#0A0C1C",
      gray: "#9698A8",
      red: "#FF0000",
    },
    zIndex: {
      topMost: 1000,
      top: 1,
    },
  },
  subHeader: {
    height: "56px",
  },
  header: {
    height: "72px",
    colors: {
      background: "white",
      border: "#efefef",
    },
  },
  formItem: {
    fontSizes: {
      labelMedium: "14px",
      labelSmall: "12px",
      descMedium: "14px",
      descSmall: "12px",
    },
    colors: {
      labelTextBlack: "#000000",
      labelTextGray: "#828282",

      descTextBlack: "#000000",
      descTextGray: "#828282",
      descTextError: "#EB5757",
    },
  },
  dropdown: {
    iconSize: {
      normal: "width: 16px; height: 16px;",
    },
    borderRadius: {
      normal: "4px",
    },
    fontSizes: {
      small: "15px",
      medium: "16px",
    },
    colors: {
      placeholder: "#bdbdbd",
      option: "#333333",
      select: "#626dff",
      unit: "#0a0c1c",
      hoverBackground: "#eeeff5",
      activeBackground: "#ffffff",
      disabledBackground: "#f2f2f2",
      border: "#eeeff5",
      borderActive: "#626dff",
    },
  },
  input: {
    colors: {
      border: "#f2f2f2",
      borderActive: "#626DFF",
      borderError: "#EB5757",
      disabled: "#f2f2f2",
      text: "#333333",
      placeholder: "#bdbdbd",
    },
  },
  button: {
    colors: {
      // outline
      outlineBorder: "#626DFF",
      outlineBg: "#ffffff",
      outlineText: "#626DFF",
      // outline + disabled
      outlineBorderDisabled: "#efefef",
      outlineBgDisabled: "#ffffff",
      outlineTextDisabled: "#c7c7c7",
      // primary
      borderPrimary: "#626DFF",
      bgPrimary: "#626DFF",
      textPrimary: "#ffffff",
      // primary + disabled
      borderDisabled: "#efefef",
      bgDisabled: "#efefef",
      textDisabled: "#c7c7c7",
    },
    fontSizes: {
      medium: "16px",
      small: "15px",
      large: "18px",
      extraLarge: "20px",
    },
    heights: {
      medium: "48px",
      small: "40px",
      large: "56px",
      extraLarge: "62px",
    },
  },
  aside: {
    colors: {
      background: "#141626",
      backgroundDark: "#0a0c1c",
    },
    sizes: {
      medium: "264px",
    },
  },
  fontSizes: {
    heading: {
      largeExtra: "46px",
      large: "38px",
      medium: "34px",
      small: "28px",
      smallExtra: "24px",
    },

    body: {
      large: "20px",
      medium: "18px",
      small: "15px",
      smallExtra: "14px",
    },

    tiny: {
      medium: "12x",
      small: "11px",
    },
  },
  fontWeights: {
    light: "300",
    normal: "400",
    medium: "500",
    bold: "700",
  },
};

export default theme;
