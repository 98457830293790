import {
  createTableCol,
  FilterType,
} from "../../../../../../../../components/shared/data-display/Tables/model/TableColumn";
import strings from "../../../../../../../car-sales/NewCarCatalogManager/constants/strings";
import {
  CW_LONG_TEXT,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../../../../utils/table-utils";
import commonStrings from "../../../../../../../../constants/strings";

export const createCatalogCols = () => {
  return [
    {
      headerName: "",
      field: "checkItem",
      width: 30,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    // 상품명
    createTableCol("prodNm", strings.COL_CATALOG_NAME, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 판매유형
    createTableCol(
      "ncarClsFlagNm",
      commonStrings.COL_SELLING_TYPE,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    // 제조사
    createTableCol("repMakerNm", strings.COL_BRAND, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 모델
    createTableCol("repCarTypeNm", strings.COL_MODEL, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 세부 모델
    createTableCol("carGradeNm", strings.COL_DETAIL_MODEL, FilterType.Text, {
      minWidth: CW_LONG_TEXT,
      flex: 1,
    }),
    // 연식
    createTableCol("yearType", strings.COL_CAR_YEAR, FilterType.Text, {
      width: CW_SMALL,
    }),
  ];
};
