import React, { useState } from "react";
import { GridApi } from "ag-grid-community";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { fetchEventJoinHistories } from "../../../../../apis/events";
import EventJoinHistory from "../../../../../models/EventJoinHistory";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import {
  createTableCol,
  FilterType,
} from "../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_DATE_RANGE,
  CW_LARGE,
  CW_SMALL,
} from "../../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import TableLayout from "../../../../../components/shared/layout/TableLayout";

interface Props extends ModalProps {
  evntSeq?: string;
}

const evntTypeCdRenderer = (typeCd: "A58001" | "A58002") => {
  if (typeCd === "A58001") return "E형";
  if (typeCd === "A58002") return "I형";
  return "";
};

const EventJoinHistoryListModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, evntSeq } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [rows, setRows] = useState<EventJoinHistory[]>();
  const [totalCount, setTotalCount] = useState(0);
  const [gridApi, setGridApi] = useState<GridApi>();

  const cols = [
    createTableCol("custNm", "고객명", FilterType.Text, {
      flex: 1,
      minWidth: CW_LARGE,
    }),
    createTableCol("hpNo", "휴대폰번호", FilterType.Text, {
      flex: 1,
      minWidth: CW_LARGE,
    }),
    createTableCol("evntTypeCdNm", "응모유형", FilterType.Text, {
      flex: 1,
      minWidth: CW_DATE_RANGE,
    }),
    createTableCol("evntAgreDtm", "응모일", FilterType.Date, {
      flex: 1,
      minWidth: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("evntAgreDtm"),
    }),
  ];

  if (evntSeq === "45") {
    cols.push(
      createTableCol("evntTypeCd", "선택경품", FilterType.Text, {
        flex: 1,
        minWidth: CW_SMALL,
        cellRenderer: ({ value }) => evntTypeCdRenderer(value),
      })
    );
  }

  /**
   * Private Functions
   */
  const onModalOpen = async () => {
    if (evntSeq) {
      await requestFetchEventJoinHistories(evntSeq);
    }
  };

  /**
   * Event Actions
   */

  const requestFetchEventJoinHistories = async (evntSeq: string) => {
    setDataFetching(true);
    try {
      const { items, count } = await fetchEventJoinHistories(evntSeq);
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  /**
   * Render Helpers
   */

  const renderList = () => {
    return (
      <TableLayout
        title="고객 리스트"
        gridApi={gridApi}
        totalCount={totalCount}
        methodName={`/admin/events${evntSeq}`}
      >
        <ClientRowTable
          cols={cols}
          rowData={rows}
          apiRef={(ref) => {
            setGridApi(ref);
          }}
        />
      </TableLayout>
    );
  };

  return (
    <Modal
      title="이벤트 응모내역"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      onOpen={onModalOpen}
    >
      <div
        style={{
          height: "700px",
        }}
      >
        {dataFetching ? renderLoading() : renderList()}
      </div>
    </Modal>
  );
};

EventJoinHistoryListModal.defaultProps = {};
export default EventJoinHistoryListModal;
