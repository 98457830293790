export default class DirectSurveyResult {
  // 설문회차명
  public qstnOrdNm = "";

  // 설문시작일
  public qstnStDt = "";

  // 설문종료일
  public qstnEndDt = "";

  // 설문그룹코드(공통코드:A26000)
  public qstnGrpCd = "";

  // 설문그룹코드명
  public qstnGrpNm = "";

  // 질문문항수
  public qstnCnt = 0;

  // 응답고객수
  public replyCustCnt = 0;

  // 설문회차아이디
  public qstnOrdId = "";
}
