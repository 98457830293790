import React, { useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Image } from "antd";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  EditExhibitionShorts,
  ExhibitionCatalog,
  ExhibitionEtcVideo,
  ExhibitionMaster,
  ExhibitionShorts,
} from "../../../../../apis/ex/types";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import { formatDateString } from "../../../../../utils/date-utils";
import Button from "../../../../../components/shared/general/antd/Button";
import ExhibitionMasterInfoEditModal from "./pages/ExihibitionMasterInfoEditModal";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  deleteExhibitionEtcVideo,
  deleteExhibitionShorts,
  editExhibitionEtcVideo,
  editExhibitionShorts,
  fetchExhibitionCatalogs,
  fetchExhibitionDetail,
  fetchExhibitionVideos,
} from "../../../../../apis/ex";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createActionCellRenderers } from "../../../../../utils/table-utils";
import {
  createEtcVideoListCols,
  createShortsVideoListCols,
  USE_YN_RENDERER,
} from "./utils/table-utils";
import TableLayout from "../../../../../components/shared/layout/TableLayout";
import ShortsAddModal from "./pages/ShortsAddModal";
import EtcVideoAddModal from "./pages/EtcVideoAddModal";
import { renderSwitchCol } from "../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

interface Props extends ModalProps {
  exhibitionMaster?: ExhibitionMaster;
}

const renderShortsImageCol = () => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    const item = data as ExhibitionShorts;

    // eslint-disable-next-line react/jsx-no-undef
    return <Image src={item.thumbnail} height={50} />;
  };
};

const renderEtcVideoImageCol = () => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    const item = data as ExhibitionEtcVideo;

    // eslint-disable-next-line react/jsx-no-undef
    return <Image src={item.pcThumbnail} height={50} />;
  };
};

const ShortsExhibitionDetailModal: React.FC<Props> = (props: Props) => {
  // props
  const { visible, onDataChange, onCancel, exhibitionMaster } = props;

  // 테이블 데이터 관리
  const shortsCols = createShortsVideoListCols();
  const etcCols = createEtcVideoListCols();

  // 데이터 패칭 관리
  const [shorts, setShorts] = useState<ExhibitionShorts[]>();
  const [etcVideos, setEtcVideos] = useState<ExhibitionEtcVideo[]>();
  const [shortsTotalCount, setShortsTotalCount] = useState(0);
  const [etcVideoTotalCount, setEtcVideoTotalCount] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);

  // 모달 상태 관리
  const [shortsAddModalVisible, setShortsAddModalVisible] = useState(false);
  /**
   * TODO : API 연결시 타입 수정
   */
  const [
    selectShortsData,
    setSelectShortsData,
  ] = useState<EditExhibitionShorts | null>(null);
  const [etcVideoAddModalVisible, setEtcVideoAddModalVisible] = useState(false);
  const [
    selectEtcVideoData,
    setSelectEtcVideoData,
  ] = useState<ExhibitionEtcVideo | null>(null);
  const [masterInfoEditModalVisible, setMasterInfoEditModalVisible] = useState(
    false
  );
  const [exhibitionMasterDetail, setExhibitionMasterDetail] = useState<
    ExhibitionMaster
  >();

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchExhibitionDetail();
    await requestFetchExhibitionItems();
    setDataFetching(false);
  };

  const requestFetchExhibitionDetail = async () => {
    if (exhibitionMaster) {
      try {
        const exhibition = await fetchExhibitionDetail(
          exhibitionMaster.exhMstSeq
        );
        setExhibitionMasterDetail(exhibition);
      } catch (e) {
        alertError(getErrorMessage(e));
      }
    }
  };

  const requestFetchExhibitionItems = async () => {
    if (exhibitionMaster) {
      const { shorts, etcVideos } = await fetchExhibitionVideos(
        exhibitionMaster.exhMstSeq,
        exhibitionMaster.exhGbnCd
      );

      setShorts(shorts.list);
      setShortsTotalCount(shorts.listCnt);
      setEtcVideos(etcVideos.list);
      setEtcVideoTotalCount(etcVideos.listCnt);
    }
  };

  const handleDleleteShorts = async (
    renderParams: ICellRendererParams,
    data: any
  ) => {
    try {
      await deleteExhibitionShorts(data.shortsSeq);
      alertSuccess("쇼츠가 삭제되었습니다");

      setDataFetching(true);
      await requestFetchExhibitionItems();
      setDataFetching(false);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };
  const handleDleleteShortsVideoClick = (
    renderParams: ICellRendererParams,
    data: any
  ) => {
    handleDleleteShorts(renderParams, data);
  };
  const handleDetailShortsVideoClick = (
    _: ICellRendererParams,
    data: EditExhibitionShorts
  ) => {
    setSelectShortsData(data);
    setShortsAddModalVisible(true);
  };

  const handleDleleteEtcVideo = async (
    renderParams: ICellRendererParams,
    data: any
  ) => {
    try {
      await deleteExhibitionEtcVideo(data.videoSeq);
      alertSuccess("비디오가 삭제되었습니다");

      setDataFetching(true);
      await requestFetchExhibitionItems();
      setDataFetching(false);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };
  const handleDleleteEtcVideoClick = (
    renderParams: ICellRendererParams,
    data: any
  ) => {
    handleDleleteEtcVideo(renderParams, data);
  };
  const handleDetaiEtcVideoClick = (
    _: ICellRendererParams,
    data: ExhibitionEtcVideo
  ) => {
    setSelectEtcVideoData(data);
    setEtcVideoAddModalVisible(true);
  };

  const requestShortsEdit = async (checked: boolean, data: any) => {
    try {
      await editExhibitionShorts(
        data,
        data.shortsSeq,
        data.useYn === "Y" ? "N" : "Y"
      );
      alertSuccess("쇼츠 정보가 변경되었습니다");

      setDataFetching(true);
      await requestFetchExhibitionItems();
      setDataFetching(false);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const hadnleShortsEditClick = (checked: boolean, data: any) => {
    requestShortsEdit(checked, data);
  };

  const requestEtcVideoEdit = async (checked: boolean, data: any) => {
    try {
      await editExhibitionEtcVideo(
        data,
        data.videoSeq,
        data.useYn === "Y" ? "N" : "Y"
      );
      alertSuccess("비디오 정보가 변경되었습니다");

      setDataFetching(true);
      await requestFetchExhibitionItems();
      setDataFetching(false);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const hadnleEtcVideoEditClick = (checked: boolean, data: any) => {
    requestEtcVideoEdit(checked, data);
  };

  const renderDetail = () => {
    if (exhibitionMasterDetail) {
      const exhibitionInfoSection: SectionFieldInfo[] = [
        {
          label: "제목",
          content: exhibitionMasterDetail.exhTitle,
          span: 12,
        },
        {
          label: "유형",
          content: exhibitionMasterDetail.exhGbnNm,
          span: 12,
        },
        {
          label: "게시순번",
          content: exhibitionMasterDetail.exhMstViewSeq,
          span: 12,
        },
        {
          label: "노출기간",
          content: `${formatDateString(
            exhibitionMasterDetail.exhStDt,
            true
          )} ~ ${formatDateString(exhibitionMasterDetail.exhEndDt, true)}`,
          span: 12,
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <Button
                onClick={() => {
                  setMasterInfoEditModalVisible(true);
                }}
              >
                수정하기
              </Button>
            );
          },
        },
      ];

      const exhibitionShorsListSection: SectionFieldInfo[] = [
        {
          label: "",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <div
                  style={{
                    marginTop: "8px",
                    height: "576px",
                  }}
                >
                  <TableLayout
                    hideExcelAction
                    hideFilterAction
                    totalCount={shortsTotalCount}
                    rightActions={[
                      {
                        title: "쇼츠 추가",
                        onClick: () => {
                          setShortsAddModalVisible(true);
                        },
                      },
                    ]}
                    // eslint-disable-next-line react/prop-types
                    methodName={`/admin/ex-catalogs/${exhibitionMaster?.exhMstSeq}/Y`}
                  >
                    <div
                      style={{
                        height: "400px",
                      }}
                    >
                      <ClientRowTable
                        cols={shortsCols}
                        rowData={shorts}
                        frameworkComponents={{
                          [USE_YN_RENDERER]: renderSwitchCol(
                            "useYn",
                            hadnleShortsEditClick
                          ),
                          ...createActionCellRenderers(
                            handleDleleteShortsVideoClick,
                            handleDetailShortsVideoClick
                          ),
                          imageRenderer: renderShortsImageCol(),
                        }}
                      />
                    </div>
                  </TableLayout>
                </div>
              </div>
            );
          },
        },
      ];

      const exhibitionEtcVideoListSection: SectionFieldInfo[] = [
        {
          label: "",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <div
                  style={{
                    marginTop: "8px",
                    height: "576px",
                  }}
                >
                  <TableLayout
                    hideExcelAction
                    hideFilterAction
                    totalCount={etcVideoTotalCount}
                    rightActions={[
                      {
                        title: "동영상 추가",
                        onClick: () => {
                          setEtcVideoAddModalVisible(true);
                        },
                      },
                    ]}
                    // eslint-disable-next-line react/prop-types
                    methodName={`/admin/ex-catalogs/${exhibitionMaster?.exhMstSeq}/Y`}
                  >
                    <div
                      style={{
                        height: "400px",
                      }}
                    >
                      <ClientRowTable
                        cols={etcCols}
                        rowData={etcVideos}
                        frameworkComponents={{
                          [USE_YN_RENDERER]: renderSwitchCol(
                            "useYn",
                            hadnleEtcVideoEditClick
                          ),
                          ...createActionCellRenderers(
                            handleDleleteEtcVideoClick,
                            handleDetaiEtcVideoClick
                          ),
                          imageRenderer: renderEtcVideoImageCol(),
                        }}
                      />
                    </div>
                  </TableLayout>
                </div>
              </div>
            );
          },
        },
      ];

      return (
        <div>
          <DetailSection label="기본 정보" fieldInfos={exhibitionInfoSection} />
          <DetailSection
            label="쇼츠 리스트"
            fieldInfos={exhibitionShorsListSection}
          />
          <DetailSection
            label="기타 동영상"
            fieldInfos={exhibitionEtcVideoListSection}
          />
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        visible={visible}
        onCancel={onCancel}
        title="기획전 상세 (신차장기)"
        onOpen={onModalOpen}
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      {/* 쇼츠 추가 모달 */}
      <ShortsAddModal
        visible={shortsAddModalVisible}
        shortsInfo={selectShortsData}
        exhMstSeq={exhibitionMaster?.exhMstSeq || ""}
        onDataChange={async () => {
          setDataFetching(true);
          await requestFetchExhibitionItems();
          setDataFetching(false);
        }}
        onCancel={() => {
          setShortsAddModalVisible(false);
          setSelectShortsData(null);
        }}
      />
      {/* 기타 동영상 추가 모달 */}
      <EtcVideoAddModal
        visible={etcVideoAddModalVisible}
        etcVideoInfo={selectEtcVideoData}
        exhMstSeq={exhibitionMaster?.exhMstSeq || ""}
        onDataChange={async () => {
          setDataFetching(true);
          await requestFetchExhibitionItems();
          setDataFetching(false);
        }}
        onCancel={() => {
          setEtcVideoAddModalVisible(false);
          setSelectEtcVideoData(null);
        }}
      />
      {/* 기획전 기본정보 수정 */}
      <ExhibitionMasterInfoEditModal
        visible={masterInfoEditModalVisible}
        onCancel={() => {
          setMasterInfoEditModalVisible(false);
        }}
        exhibitionMaster={exhibitionMasterDetail}
        onDataChange={async () => {
          await requestFetchExhibitionDetail();
          if (onDataChange) onDataChange();
        }}
      />
    </>
  );
};

export default ShortsExhibitionDetailModal;
