import React, { useState } from "react";
import moment from "moment";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../components/shared/feedback/antd/Modal";

import { ModalProps } from "../../../../types";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import {
  FormElementType,
  FormInfo,
} from "../../../../../components/shared/data-entry/FormBuilder/types";
import { alertError, alertSuccess } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import errorMessages from "../../../../../constants/errors";
import { addExhibition } from "../../../../../apis/uc-ex";

interface AddExhibitionModalProps extends ModalProps {}

const AddExhibitionModal: React.FC<AddExhibitionModalProps> = (
  props: AddExhibitionModalProps
) => {
  let formik: FormikProps<FormikValues>;
  const formInitialValues = {
    exhTitle: "",
    displayTerm: null,
    exhDesc: "",
  };
  const forms: FormInfo[] = [
    {
      key: "exhTitle",
      label: "제목",
      type: FormElementType.Input,
      placeholder: "기획전 제목을 입력해주세요",
      required: true,
    },
    {
      key: "exhDesc",
      label: "설명",
      type: FormElementType.Input,
      placeholder: "제목 하단 설명을 입력해주세요",
      required: true,
    },
    {
      key: "displayTerm",
      label: "노출기간",
      type: FormElementType.DateRangePicker,
      placeholder: "노출기간을 입력해주세요",
      required: true,
      config: {
        showTime: true,
      },
    },
  ];
  const validationSchema = Yup.object().shape({
    exhTitle: Yup.string().required(errorMessages.REQUIRED_FIELD),
    exhDesc: Yup.string().required(errorMessages.REQUIRED_FIELD),
    displayTerm: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(2, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });

  const { visible, onCancel, onDataChange } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSubmit = async (values: FormikValues) => {
    const { exhTitle, displayTerm, exhDesc } = values;
    const exhStDt = moment(displayTerm[0]).format("YYYYMMDDHHmmss");
    const exhEndDt = moment(displayTerm[1]).format("YYYYMMDDHHmmss");

    try {
      setConfirmLoading(true);
      await addExhibition({
        exhTitle,
        exhDesc,
        exhStDt,
        exhEndDt,
      });
      alertSuccess("기획전이 등록되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="기획전 추가 (중고장기)"
      size="small"
      onOk={() => {
        formik?.handleSubmit();
      }}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={formInitialValues}
        forms={forms}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default AddExhibitionModal;
