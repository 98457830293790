import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import strings from "./constants/strings";
import {
  createEventCols,
  EVENT_DISPLAY_STATE_RENDERER,
} from "./utils/table-utils";
import Event from "../../../models/Event";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import EventModal from "./pages/EventModal";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import DateRangeFilter from "../../../components/shared/data-display/Tables/components/AgGridFilters/DateRangeFilter";
import { editEvent, fetchEvents } from "../../../apis/events";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { renderSwitchCol } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import Button from "../../../components/shared/general/antd/Button";
import EventJoinHistoryListModal from "./pages/EventJoinHistoryListModal";

const EventManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [checkedRows, setCheckedRows] = useState<Array<Event>>([]);
  const [eventModalVisible, setEventModalVisible] = useState(false);
  const [
    eventJoinHistoryModalVisible,
    setEventJoinHistoryModalVisible,
  ] = useState(false);
  const [selectedSeq, setSelectedSeq] = useState<string | undefined>();
  const [rows, setRows] = useState<Array<Event> | undefined>(undefined);
  const [totalCount, setTotalCount] = useState(0);
  const cols = createEventCols();

  useEffect(() => {
    (async function fetchData() {
      await requestEventList();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestEventList = async () => {
    try {
      const eventList = await fetchEvents();
      const { items, count } = eventList;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      setRows([]);
    }
  };

  const requestUpdateDisplayState = async (
    eventSeq: string,
    checked: boolean,
    event: Event
  ) => {
    try {
      await editEvent(eventSeq, {
        useYn: checked ? "Y" : "N",
        evntTermYn: event.evntTermYn,
        evntStDt: event.evntStDt,
        evntEndDt: event.evntEndDt,
      });
      alertSuccess("이벤트 노출 여부가 변경되었습니다");
      await requestEventList();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  const handleAddNoticeClick = () => {
    setSelectedSeq(undefined);
    setEventModalVisible(true);
  };

  const handleCheckRowChanged = (rows: Array<Event>) => {
    setCheckedRows(rows);
  };

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { evntSeq } = data;
    setSelectedSeq(evntSeq);
    setEventModalVisible(true);
  };

  const createActions = () => {
    return [
      {
        title: strings.TITLE_ACTION_ADD,
        onClick: handleAddNoticeClick,
      },
    ];
  };

  const handleEventDisplayCheckChange = (checked: boolean, event: Event) => {
    showConfirm(
      checked ? "노출상태로 변경" : "숨김상태로 변경",
      checked
        ? "해당 이벤트를 노출상태로 변경하시겠습니까?"
        : "해당 이벤트를 숨김상태로 변경하시겠습니까?",
      async () => {
        await requestUpdateDisplayState(event.evntSeq, checked, event);
      }
    );
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout
        filterable={false}
        headerTitle={strings.TITLE_HEADER_EVENT_MANAGER}
      >
        <TableLayout
          gridApi={gridApi}
          title={strings.TABLE_HEADER_EVENT_LIST}
          totalCount={totalCount}
          selectionCount={checkedRows.length}
          rightActions={createActions()}
          methodName="/admin/events"
        >
          <ClientRowTable
            apiRef={(ref) => {
              setGridApi(ref);
            }}
            rowData={rows}
            onCheckRowChanged={handleCheckRowChanged}
            cols={cols}
            frameworkComponents={{
              eventJoinHistoryButton: (props: ICellRendererParams) => {
                const { data } = props;
                const event = data as Event;

                return (
                  <Button
                    disabled={event.evntPtcpCnt === 0}
                    size="small"
                    type="default"
                    onClick={() => {
                      setSelectedSeq(event.evntSeq);
                      setEventJoinHistoryModalVisible(true);
                    }}
                  >
                    내역확인
                  </Button>
                );
              },
              [EVENT_DISPLAY_STATE_RENDERER]: renderSwitchCol(
                "useYn",
                handleEventDisplayCheckChange
              ),
              ...createActionCellRenderers(undefined, handleEditClick, {
                hideDeleteButton: true,
              }),
              dateRangeFilter: DateRangeFilter,
            }}
          />
        </TableLayout>
      </ContentLayout>
      <EventModal
        seq={selectedSeq}
        isOpen={eventModalVisible}
        onCancel={() => {
          setEventModalVisible(false);
        }}
        onDataChange={async () => {
          gridApi?.showLoadingOverlay();
          await requestEventList();
          gridApi?.hideOverlay();
        }}
      />
      <EventJoinHistoryListModal
        evntSeq={selectedSeq}
        visible={eventJoinHistoryModalVisible}
        onCancel={() => {
          setEventJoinHistoryModalVisible(false);
        }}
      />
    </>
  );
};

EventManager.defaultProps = {};
export default EventManager;
