import { formatCurrency } from "../utils/common-utils";
import { DiscountInfo } from "./RentFeeResult";

class EstimationDetail {
  /**
   * 고객 정보
   */

  // 고객명
  public custNm = "";

  // 휴대폰번호
  public hpNo = "";

  // 성별
  public gndr = "";

  // 생년월일
  public birthDt = "";

  // 주소
  public addr = "";

  // 상세주소
  public dtlAddr = "";

  /**
   * 견적 정보
   */

  // 견적번호
  public estReqNo = "";

  // 견적버전
  public estVer = "";

  // 견적상세순번
  public estDtlSeq = "";

  // 견적제목
  public estTitle = "";

  // MODE 고객 ID
  public custId = "";

  // COSS 고객 ID
  public cossCustId = "";

  // 고객구분코드
  public custClsCd = "";

  // 고객구분코드명
  public custClsNm = "";

  // 상품ID
  public prodId = "";

  // 견적구분코드
  public shpeClsCd = "";

  // 견적구분명
  public shpeClsNm = "";

  // 견적요청구분코드
  public newAddClsCd = "";

  // 견적요청구분명
  public newAddClsNm = "";

  // 견적상태코드
  public estStsCd = "";

  // 견적상태명
  public estStsNm = "";

  // 견적일자
  public estDt = "";

  // 계약기간
  public cntrTermMm = "";

  // 견적유효기간
  public cntrAvailTermMm = "";

  // 담보조건코드
  public pldgCondCd = "";

  // 담보조건명
  public pldgCondNm = "";

  // 내장 색상 ID
  public innrCarColorId = "";

  // 내장 색상 명
  public innrCarColorNm = "";

  // 외장 색상 ID
  public xtnlCarColorId = "";

  // 외장 색상 명
  public xtnlCarColorNm = "";

  // 전면 선팅메이커명
  public suntMakerNm1 = "";

  // 전면 선팅 농도
  public suntConc1 = "";

  // 측면 선팅메이커명
  public suntMakerNm2 = "";

  // 측면 선팅 농도
  public suntConc2 = "";

  // 후면 선팅메이커명
  public suntMakerNm3 = "";

  // 후면 선팅 농도
  public suntConc3 = "";

  // 약정주행거리 코드
  public prmsDtcClsCd = "";

  // 약정주행거리 코드 명
  public prmsDtcClsNm = "";

  // 운전자범위 코드
  public drvSoe = "";

  // 운전자범위 코드 명
  public drvSoeNm = "";

  // 대표 제조사명
  public repMakerNm = "";

  // 대표 차명
  public repCartypeNm = "";

  // 대표 차량 외관 이미지
  public repCarImg = "";

  // 등급(트림)
  public carGradeNm = "";

  // 차종명
  public carClassNm = "";

  // 할인 내역
  public odsDcList: DiscountInfo[] = [];

  // 월렌트료(부가세미포함)
  public mmRentAmt = 0;

  // 월렌트료(부가세포함)
  public mmRentAmtV = 0;

  // COSS 재고
  public prodStock = 0;

  // 계약진행상태
  public cntrStsCd = "";

  // 계약진행상태명
  public cntrStsNm = "";

  // 대물포함정보 코드
  public impsnlCd = "";

  // 대물포함정보 코드명
  public impsnlNm = "";

  // 담보금
  public pldgAmt = 0;

  // 담보율
  public pldgRt = 0;

  // 납입자ID
  public paymentUserId = "";

  // 결제수단 코드
  public stmtMeansCd = "";

  // 결제수단명
  public stmtMeansNm = "";

  // 결제일
  public stmtDd = "";

  // 담보입보상태
  public pldgRcptSts = "";

  // 추가 옵션
  public carGoodsNm1 = "";

  // 차량 인수가
  public tkvAmt = "";

  // 선수금 차감액
  public mmInitamt = 0;

  // 실 납입금
  public realPaymentAmt = 0;

  // 인수옵션코드
  public tkvRtrnYnCd = "";

  // 인수옵션코드네임
  public tkvRtrnYnNm = "";

  /**
   * 중고장기
   */

  // 요금제 코드
  public tkvAmtRtFlag = "";

  // 차량번호
  public carNo = "";

  // 변속
  public grbxNm = "";

  // 최초 등록일
  public regDt = "";

  // 인승
  public seaterClsNm = "";

  // 연식(연형)
  public yearType = "";

  // 연료명
  public fuelNm = "";

  // 주행거리
  public curTravelDtc = "";

  // 배기량
  public engdisp = "";

  // 차량가
  public totalCarAmt = "";

  /**
   * UBR 추가 필드
   */

  // 요금제 코드
  public amtCondCd = "";

  // 요금제명
  public amtCondNm = "";

  // 고정 렌탈료
  public stdRentAmt = "";

  // 주행거리 렌탈료
  public amtPerMile = "";

  // 보증금 정보
  get rentInsuranceInfoText() {
    if (this.pldgRt === 0) return "면제";
    return `${this.pldgCondNm}(${this.pldgRt}%) ${
      this.pldgAmt != null ? ` / ${formatCurrency(this.pldgAmt)}` : ""
    }`;
  }
}

export default EstimationDetail;
