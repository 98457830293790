import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
} from "../../../../utils/table-utils";
import { TagPositionCode, TagLevelCode } from "../../../../components/shared/composition/TagMasterSelect/utils/utils";

export const PC_TAG_RENDERER = "pcTagRenderer";
export const MOBILE_TAG_RENDERER = "mobileTagRenderer";

export const getTagPositionText = (positionCode: TagPositionCode) => {
  if (positionCode === "A55001") return "상단";
  return "하단";
};

export const getTagLevelText = (levelCode: TagLevelCode) => {
  if (levelCode === "A69001") return "상품(MP)";
  else if (levelCode === "A69002") return "트림(MG)";
  return "공통";
};

export const createTagMasterCols = (): Array<TableColumn> => {
  return [
    // PC 태그
    createTableCol("pcTagTxt", "PC 태그", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        return data.pcTagTxt;
      },
      cellRenderer: PC_TAG_RENDERER,
    }),
    // PC 태그위치
    createTableCol("pcTagPos", "PC 태그위치", FilterType.Text, {
      width: CW_MEDIUM,
      flex: 1,
      valueGetter: ({ data }) => {
        return getTagPositionText(data.pcTagPos);
      },
    }),
    // 모바일 태그
    createTableCol("mobTagTxt", "모바일 태그", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        return data.mobTagTxt;
      },
      cellRenderer: MOBILE_TAG_RENDERER,
    }),
    // 모바일 태그위치
    createTableCol("mobTagPos", "모바일 태그위치", FilterType.Text, {
      width: CW_MEDIUM,
      flex: 1,
      valueGetter: ({ data }) => {
        return getTagPositionText(data.mobTagPos);
      },
    }),
    // 태그 사용 구분
    createTableCol("tagLvlCd", "태그 사용 구분", FilterType.Text, {
      width: CW_MEDIUM,
      flex: 1,
      valueGetter: ({ data }) => {
        return getTagLevelText(data.tagLvlCd);
      },
    }),
    {
      ...createActionCol(80),
    },
  ];
};
