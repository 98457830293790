import React, { useState } from "react";
import { ICellRendererParams } from "ag-grid-community";

// components
import Modal from "@components/shared/feedback/antd/Modal";
import ModalTableWrapper from "@components/shared/layout/ModalTableWrapper";
import {
  createTableCol,
  FilterType,
} from "@components/shared/data-display/Tables/model/TableColumn";
import ClientRowTable from "@components/shared/data-display/Tables/components/ClientRowTable";
import Button from "@components/shared/general/antd/Button";

// modal
import { ModalProps } from "../../../../types";
import CatalogTagSetupModal from "./pages/CatalogTagSetupModal";

// apis
import { fetchMcProdNmList } from "@apis/mc-catalogs";

// models
import { McProdNmTagDetail } from "@models/McProdNmTag";

/**
 * 차종 태그관리
 * 
 * @param props 
 * @returns 
 */
const McCartypeTagModal: React.FC<ModalProps> = (props: ModalProps) => {
  const { visible, onCancel, onDataChange } = props;
  const [tagSetupModalVisible, setTagSetupModalVisible] = useState(false);
  const [mcProdNmList, setMcProdNmList] = useState<McProdNmTagDetail[]>();
  const [mcProdNmDetail, setMcProdNmDetail] = useState<McProdNmTagDetail>();
  const TAG_SETTING_BUTTON = "tagSettingButton";

  let cols = [
    // 노출 여부
    createTableCol("prodNm", "차종명", FilterType.Text, {
      width: 277,
    }),
    createTableCol("tagList", "", undefined, {
      hide: true,
    }),
    // 태그설정
    createTableCol("contents", "태그설정", undefined, {
      width: 200,
      pinned: "right",
      cellRenderer: TAG_SETTING_BUTTON,
    })
  ];

  const onModalOpen = async () => {
    initData();
  };

  const initData = async () => {
    const res = await fetchMcProdNmList();
    setMcProdNmList(res?.prodTagList);
  };

  /**
   * 태그설정 모달 on
   */
  const handleTagSetupButtonClick = async (data: McProdNmTagDetail) => {
    await setMcProdNmDetail(data);
    setTagSetupModalVisible(true);
  };

  return (
    <>
      <Modal
        size="small"
        onOpen={onModalOpen}
        visible={visible}
        onCancel={onCancel}
        title="차종 태그관리"
        footer={null}
      >
        <ModalTableWrapper
          // headerTitle={"차종 태그관리"}
        >
          <ClientRowTable
            height={500}
            cols={cols}
            rowData={mcProdNmList}
            frameworkComponents={{
              [TAG_SETTING_BUTTON]: (props: ICellRendererParams) => {
                const { data } = props;

                return (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="primary"
                      ghost
                      size="small"
                      onClick={() => handleTagSetupButtonClick(data)}
                    >
                      태그설정
                    </Button>
                  </div>
                );
              },
            }}
          />
        </ModalTableWrapper>
      </Modal>

      <CatalogTagSetupModal
        visible={tagSetupModalVisible}
        onCancel={() => {
          setTagSetupModalVisible(false);
        }}
        prodDetail={mcProdNmDetail}
        onDataChange={async () => {
          await initData();
          if (onDataChange) onDataChange();
        }}
      />
    </>
  );
};

McCartypeTagModal.defaultProps = {
  onCancel: () => {},
  visible: false,
};
export default McCartypeTagModal;
