import React, { ReactNode } from "react";
import { StatusTextWrapper, StatusDot, StatusText } from "./style";

interface StatusCellTextProps {
  children?: ReactNode;
  active?: boolean;
  isRed?: boolean,
  text?: string;
}

const StatusCellText: React.FC<StatusCellTextProps> = (
  props: StatusCellTextProps
) => {
  const { text = "", active = true, isRed = false } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <StatusTextWrapper>
      <StatusDot active={active} isRed={isRed} />
      <StatusText active={active} isRed={isRed} >{text}</StatusText>
    </StatusTextWrapper>
  );
};

StatusCellText.defaultProps = {
  active: true,
  isRed: false,
};
export default StatusCellText;
