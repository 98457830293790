class DefaultRentOptionInfo {
  // 기본상품 관리 ID
  public prdbaseId = "";

  // 신용등급
  public kcbGrd = 0;

  // 계약기간 코드
  public cntrTermMm = "";

  // 계약기간 명
  public cntrTermMmNm = "";

  // 약정주행거리 코드
  public prmsDtc = "";

  // 약정주행거리 명
  public prmsDtcNm = "";

  // 운전자범위 코드
  public drvSoe = "";

  // 운전자범위 코드
  public drvSoeNm = "";

  // 적용시작일
  public apyStDt = "";

  // 적용종료일
  public apyEndDt = "";
}

export default DefaultRentOptionInfo;
