import React, { useState } from "react";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import { alertError } from "../../../../../../../utils/render-utils";
import ClientRowTable from "../../../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { fetchCustomerInfoHistories } from "../../../../../../../apis/customers";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import CustomerInfoHistory from "../../../../../../../models/CustomerInfoHistory";
import { createUserInfoHistoryCols } from "./utils/table-utils";
import ModalTableWrapper from "../../../../../../../components/shared/layout/ModalTableWrapper";

interface Props extends ModalProps {
  custId?: string;
}

const CustomerInfoHistoryListModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, custId } = props;
  const [rows, setRows] = useState<Array<CustomerInfoHistory>>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const cols = createUserInfoHistoryCols();

  const onModalOpen = async () => {
    if (custId) {
      await requestFetchUserInfoHistories(custId);
    } else {
      setRows([]);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchUserInfoHistories = async (custId: string) => {
    try {
      const { items, count } = await fetchCustomerInfoHistories(custId);
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
      setRows([]);
    }
  };

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <Modal
      title="개인정보 수정내역"
      visible={visible}
      onOpen={onModalOpen}
      onCancel={onCancel}
      footer={null}
    >
      <ModalTableWrapper>
        <ClientRowTable rowData={rows} cols={cols} floatingFilter={false} />
      </ModalTableWrapper>
    </Modal>
  );
};

CustomerInfoHistoryListModal.defaultProps = {};
export default CustomerInfoHistoryListModal;
