import moment from "moment";
import BusinessInfo from "./BusinessInfo";
import {Type} from "class-transformer";

class CustomerDetail {
  // 고객번호
  public custId = "";

  // 온라인 로그인 ID
  public custOid = "";

  // 성명
  public custNm = "";

  // 휴대폰번호
  public hpNo = "";

  // 성별
  public gndr = "";

  // 생년
  public birthYr = "";

  // (생년)월일
  public birthDt = "";

  // CI값
  public ci = "";

  // DI값
  public di = "";

  // 이메일
  public email = "";

  // 주소
  public addr = "";

  // 상세주소
  public dtladdr = "";

  // 최근 로그인 일시
  public lastLoginDtm = "";

  // 패스워드 수정 일시
  public pwUpdDt = "";

  // 운전면허 번호
  public drvLcnsNo = "";

  // 운전면허 종류
  public drvLcnsKind = "";

  // 면허 유효기간
  public drvLcnsTerm = "";

  // 사용유무
  public useYn = "";

  // 최초 소셜가입시 채널코드
  public snsChl = "";

  // COSS 고객 ID
  public cossCustId = "";

  // 비고
  public remark = "";

  // 탈퇴요청일
  public delDtm = "";

  // 탈퇴처리여부
  public delFlag = "";

  // 기존가입내역 확인FLAG
  public dupFlag = "";

  // 회원구분
  public custCls = "";

  // 담당카매니저ID
  public carmanId = "";

  // 담당카매니저명
  public carmanNm = "";

  // 담당카매니저휴대폰
  public carmanHpNo = "";

  // 담당카매니저담당지역
  public carmanSiteNm = "";

  // 수신동의 리스트
  public agreeList: Array<MarketingAgreementInfo> = [];

  // 사업자 정보 리스트
  @Type(() => BusinessInfo)
  public businessList: Array<BusinessInfo> = [];

  public businessListCnt = 0;

  // 신용등급
  public crdGrd = "";

  // 담보율
  public pldgRt = "";

  // 신용일자
  public crdDt = "";

  // 임직원 여부
  public empYn = "";

  // 신용조회여부 ( Y일경우 재조회 필요 )
  public crdQryYn = "";

  // 소개프로그램 가입여부
  public frnRecEntryYn = "";

  // 소개프로그램 가입여부
  public frnRecEntryDtm = "";

  get pldgRtText() {
    if (this.pldgRt !== null) {
      const rate = Number(this.pldgRt);
      if (rate === 0) return "(담보율 : 면제)";

      return `(담보율 : ${this.pldgRt}%)`;
    }
    return "견적진행불가";
  }

  // 개인고객 계약 가능 여부 확인
  get canEstimate() {
    // 본인인증을 하지 않은 고객일시 계약 불가
    if (!this.crdGrd) return false;

    // 신용등급 8등급 이상 계약 불가
    if (this.crdGrd) {
      const grade = Number(this.crdGrd);
      if (grade > 7) return false;
    }

    // 담보율 없을 경우 계약 불가
    return this.pldgRt !== null;
  }

  get birthDayText() {
    if (this.birthYr && this.birthDt) {
      const birth = `${this.birthYr}${this.birthDt}`;

      return moment(birth).format("YYYY/MM/DD");
    }
    return "-";
  }

  get genderText() {
    if (this.gndr) {
      if (this.gndr === "1") return "남";
      if (this.gndr === "2") return "여";
    }
    return "-";
  }

  get addressText() {
    let addr = "";

    if (this.addr) {
      addr += this.addr;
    }

    if (this.dtladdr) {
      addr += ` ${this.dtladdr}`;
    }
    return addr;
  }

  get isSMSAgreed() {
    return (
      this.agreeList.filter(({ agreClsCd, agreYn }) => {
        return agreClsCd === "A03041" && agreYn === "Y";
      }).length > 0
    );
  }

  get isTMAgreed() {
    return (
      this.agreeList.filter(({ agreClsCd, agreYn }) => {
        return agreClsCd === "A03025" && agreYn === "Y";
      }).length > 0
    );
  }

  get smsAgreementText() {
    if (this.isSMSAgreed) return "동의";

    return "미동의";
  }

  get tmAgreementText() {
    if (this.isTMAgreed) return "동의";

    return "미동의";
  }
}

class MarketingAgreementInfo {
  // 동의 구분 코드 (A03009:SMS 수신동의, A03010:이메일 수신동의)
  public agreClsCd = "";

  // 동의 구분 명
  public agreClsNm = "";

  // 동의여부
  public agreYn = "";

  // 동의일시
  public agreDtm = "";
}

export default CustomerDetail;
