import React, { useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import strings from "../../constants/strings";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import {
  fetchNCCatalogDetail,
  updateNCCatalogDisplayState,
  updateNCModlDisplayState,
} from "../../../../../apis/nc-catalogs";
import NewCarCatalogDetail from "../../../../../models/NewCarCatalogDetail";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import {
  createNewCarCatalogTrimCols,
  createCatalogTagCols,
  TAG_SETTING_BUTTON,
  DANSAN_SETTING_BUTTON,
  createSwitchRenderer,
  createRentFeeCols,
  DISPLAY_STATE_INPUT,
} from "./utils/table-utils";
import Switch from "../../../../../components/shared/data-entry/antd/Switch";
import { formatNumber, getErrorMessage } from "../../../../../utils/common-utils";
import { ModalProps } from "../../../../types";
import { showConfirm } from "../../../../../components/shared/feedback/Confirm";
import ModalTableWrapper from "../../../../../components/shared/layout/ModalTableWrapper";
import {
  ENUM_PROD_TYPE_FAST_DELIVERY,
  ENUM_PROD_TYPE_TODAY_DELIVERY,
  ENUM_PROD_TYPE_TAGO_PAY,
  ENUM_PROD_TYPE_TODAY_SLIM,
  ENUM_PROD_TYPE_FAST_SLIM,
} from "../../../../../constants/enums";
import NewCarCatalogCarGrade from "../../../../../models/NewCarCatalogCarGrade";
import DansanReasonInfoModal from "./pages/DansanReasonInfoModal";
import Button from "../../../../../components/shared/general/antd/Button";
import {
  MOBILE_TAG_RENDERER,
  PC_TAG_RENDERER,
} from "../../../CatalogTagManager/utils/table-utils";
import {
  mobileTagRenderer,
  pcTagCellRenderer,
} from "../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import CatalogTagSetupModal from "./pages/CatalogTagSetupModal";
import TagPreviewModal from "./pages/TagPreviewModal";
import VideoRegistrationModal from "./pages/VideoRegistrationModal";
import RentFeeSetupModal from "./pages/RentFeeSetupModal";
import OincRtSetupModal from "./pages/OincRtSetupModal";

interface Props extends ModalProps {
  dataId?: string;
}

const NewCarCatalogModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, dataId, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [dansanModalVisible, setDansanModalVisible] = useState(false);
  const [carGrade, setCarGrade] = useState<NewCarCatalogCarGrade>();
  const [catalogDetail, setCatalogDetail] = useState<NewCarCatalogDetail>();
  const [modeGrdId, setModeGrdId] = useState<string | undefined>();
  const tagCols = createCatalogTagCols();

  // 태그 관리 - "설정"
  const [tagSetupModalVisible, setTagSetupModalVisible] = useState(false);
  // 태그 관리 - "미리보기"
  const [tagPreviewModalVisible, setTagPreviewModalVisible] = useState(false);
  // 동영상 관리 - "설정"
  const [videoRegistrationModalVisible, setVideoRegistrationModalVisible] = useState(false);
  // 1년 렌트 기본 렌탈료 - "설정"
  const [rentFeeSetupModalVisible, setRentFeeSetupModalVisible] = useState(false);
  // 1년 렌트 경상이익율 - "설정"
  const [oincRtSetupModalVisible, setOincRtSetupModalVisible] = useState(false);

  /**
   * 모달 오픈 시, 처리되는 부분
   */
  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchCatalogDetail();
    setDataFetching(false);
  };

  /**
   * 상품 상세 조회
   */
  const requestFetchCatalogDetail = async () => {
    if (dataId) {
      try {
        const catalogDetail = await fetchNCCatalogDetail(dataId);
        setCatalogDetail(catalogDetail);
      } catch (e) {
        alertError(getErrorMessage(e));
      }
    }
  };

  /**
   * 노출 제어
   * 
   * @param checked 
   */
  const requestUpdateDisplayState = async (checked: boolean) => {
    setSwitchLoading(true);
    if (catalogDetail) {
      if (catalogDetail) {
        catalogDetail.useYn = checked ? "Y" : "N";
      }
      setCatalogDetail(catalogDetail);
      try {
        await updateNCCatalogDisplayState(catalogDetail.prodId, checked);
        alertSuccess(strings.FEEDBACK_DISPLAY_STATE_CHANGE);
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setSwitchLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */
  const handleDisplayStateSwitchChange = async (checked: boolean) => {
    showConfirm(
      checked ? "노출상태로 변경" : "숨김상태로 변경",
      checked
        ? "해당상품을 노출상태로 변경하시겠습니까?"
        : "해당상품을 숨김상태로 변경하시겠습니까?",
      async () => {
        await requestUpdateDisplayState(checked);
      }
    );
  };

  /**
   * 1년렌트 노출여부 갱신
   * 
   * @param cossModlId
   * @param isCheck
   */
  const requestModlUpdateDisplayState = async (
    isCheck: boolean,
    srSeq: string,
    modeGrdId: string,
  ) => {
    setSwitchLoading(true);
    try {
      if (catalogDetail) {
        await updateNCModlDisplayState(isCheck ? "Y" : "N", catalogDetail.prodId, srSeq, modeGrdId);
        alertSuccess(strings.MODEL_DISPLAY_STATE_CHANGE);
        await requestFetchCatalogDetail();
        // if (onDataChange) onDataChange();
      }
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setSwitchLoading(false);
    }
  };

  /**
   * 1년렌트/타고페이 노출여부
   * 
   * @param isCheck 
   * @param srSeq 
   * @param modeGrdId 
   */
  const handleModelDisplayStateSwitchChange = (
    isCheck: boolean,
    srSeq: string,
    modeGrdId: string,
  ) => {
    showConfirm(
      isCheck ? "노출상태로 변경" : "숨김상태로 변경",
      "동일 모델이 존재할 경우, 해당 모든 모델이 변경될 수 있습니다. 그래도 진행하시겠습니까?",
      async () => {
        await requestModlUpdateDisplayState(isCheck, srSeq, modeGrdId);
      }
    );
  };

  /**
   * 태그 설정 모달 띄우기
   */
  const setModl = async (modeGrdId: string) => {
    setModeGrdId(modeGrdId);
  };

  /**
   * 태그 설정 모달 띄우기
   */
  const handleTagSetupButtonClick = () => {
    setTagSetupModalVisible(true);
  };

  /**
   * 태그 미리보기 모달 띄우기
   */
  const handleTagPreviewButtonClick = () => {
    setTagPreviewModalVisible(true);
  };

  /**
   * 동영상 관리 설정 모달 띄우기
   */
  const handleVideoRegistrationButtonClick = () => {
    setVideoRegistrationModalVisible(true);
  };

  /**
   * 1년 렌트 기본 렌탈료 - "설정"
   */
  const handleRentFeeButtonClick = () => {
    setRentFeeSetupModalVisible(true);
  };

  /**
   * 1년 렌트 경상이익율 - "설정"
   */
  const handleOincRtButtonClick = () => {
    setOincRtSetupModalVisible(true);
  };

  /**
   * Render Helpers
   */

  const renderDetail = () => {
    if (catalogDetail) {
      const displayInfoSection: Array<SectionFieldInfo> = [
        {
          label: "상품 노출 여부",
          type: FieldType.Custom,
          render: () => {
            return (
              <Switch
                checked={catalogDetail.isActive}
                loading={switchLoading}
                defaultChecked={catalogDetail.isActive}
                onChange={handleDisplayStateSwitchChange}
              />
            );
          },
        },
      ];
      const prodInfoSection = [
        {
          label: "상품 대표 이미지",
          type: FieldType.Custom,
          render: () => {
            return (
              <img
                width={160}
                src={catalogDetail.repCarImg}
                alt="상품대표이미지"
              />
            );
          },
        },
        {
          label: "상품명",
          content: catalogDetail.prodNm,
        },
      ];

      const tagInfoSection: SectionFieldInfo[] = [
        {
          label: "",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <Button
                  style={{
                    marginRight: "8px",
                  }}
                  onClick={handleTagSetupButtonClick}
                >
                  설정
                </Button>
                <Button ghost onClick={handleTagPreviewButtonClick}>
                  미리보기
                </Button>
                <div
                  style={{
                    marginTop: "8px",
                    height: "300px",
                  }}
                >
                  <ClientRowTable
                    cols={tagCols}
                    rowData={catalogDetail.tagList}
                    frameworkComponents={{
                      [PC_TAG_RENDERER]: pcTagCellRenderer,
                      [MOBILE_TAG_RENDERER]: mobileTagRenderer,
                    }}
                  />
                </div>
              </div>
            );
          },
        },
      ];

      const renderVideoRate = (value: string) => {
        return value === "W" ? "가로형" : "세로형";
      };

      /** 동영상 관리 */
      const videoInfoSection: SectionFieldInfo[] = [
        {
          label: "",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <Button
                  style={{
                    marginRight: "8px",
                  }}
                  onClick={handleVideoRegistrationButtonClick}
                >
                  설정
                </Button>
              </div>
            );
          },
        },
        {
          label: "영상 비율",
          content: catalogDetail?.videoList[0]
            ? renderVideoRate(catalogDetail.videoList[0].videoRate)
            : "-",
        },
        {
          label: "버튼명",
          content: catalogDetail?.videoList[0]
            ? catalogDetail.videoList[0].videoBtn
            : "-",
        },
        {
          label: "동영상 URL",
          content: catalogDetail?.videoList[0]
            ? catalogDetail.videoList[0].videoUrl
            : "-",
        },
        {
          label: "사용 여부",
          content: catalogDetail?.videoList[0]
            ? catalogDetail.videoList[0].useYn
            : "-",
        },
      ];

      // 재고 상품
      const isStockProduct = [ENUM_PROD_TYPE_TODAY_DELIVERY, ENUM_PROD_TYPE_FAST_DELIVERY, ENUM_PROD_TYPE_TODAY_SLIM, ENUM_PROD_TYPE_FAST_SLIM, ENUM_PROD_TYPE_TAGO_PAY].includes(catalogDetail.ncarClsFlag);
      // 신차 12개월 상품
      const isSlimProduct = [ENUM_PROD_TYPE_TODAY_SLIM, ENUM_PROD_TYPE_FAST_SLIM].includes(catalogDetail.ncarClsFlag);
      // 타고페이 상품
      const isTpProduct = ENUM_PROD_TYPE_TAGO_PAY === catalogDetail.ncarClsFlag;

      return (
        <div>
          <DetailSection label="노출상태" fieldInfos={displayInfoSection} />
          <DetailSection label="신차 상품 정보" fieldInfos={prodInfoSection} />
          <DetailSection label="태그 관리" fieldInfos={tagInfoSection} />
          <DetailSection label="동영상 관리" fieldInfos={videoInfoSection} />
          {isSlimProduct && <>
            <ModalTableWrapper
              style={{
                position: "relative",
                paddingBottom: "30px"
              }}
              headerTitle={"1년 렌트 기본 렌탈료"}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 180,
                }}
              >
                <Button onClick={handleRentFeeButtonClick}>
                  설정
                </Button>
              </div>
              <ClientRowTable
                cols={createRentFeeCols()}
                rowData={catalogDetail.srRentFeelist}
                frameworkComponents={{
                  [DISPLAY_STATE_INPUT]: (props: ICellRendererParams) => `${props?.data.rglRentAmt ? formatNumber(props?.data.rglRentAmt) + '원' : '-'}`
                }}
              />
            </ModalTableWrapper>
            <DetailSection label="1년 렌트 경상이익율" fieldInfos={[
              {
                label: "",
                span: 24,
                type: FieldType.Custom,
                render: () => {
                  return (
                    <div>
                      <Button
                        style={{
                          marginRight: "8px",
                        }}
                        onClick={handleOincRtButtonClick}
                      >
                        설정
                      </Button>
                    </div>
                  );
                },
              },
              {
                label: '경상이익율',
                content: catalogDetail.oincRt,
              }
            ]} />
          </>
          }
          <ModalTableWrapper
            headerTitle={isStockProduct ? "재고리스트" : "트림리스트"}
          >
            <ClientRowTable
              height={420}
              cols={createNewCarCatalogTrimCols(isStockProduct, isSlimProduct, isTpProduct)}
              rowData={catalogDetail.list}
              frameworkComponents={{
                [TAG_SETTING_BUTTON]: (props: ICellRendererParams) => {
                  const { data } = props;

                  return (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        ghost
                        size="small"
                        onClick={async () => {
                          await setModl(data.modeGrdId);
                          handleTagSetupButtonClick();
                        }}
                      >
                        태그설정
                      </Button>
                    </div>
                  );
                },
                [DANSAN_SETTING_BUTTON]: (props: ICellRendererParams) => {
                  const { data } = props;

                  return (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        ghost
                        size="small"
                        onClick={() => {
                          setCarGrade(data);
                          setDansanModalVisible(true);
                        }}
                      >
                        단산설정
                      </Button>
                    </div>
                  );
                },
                ...createSwitchRenderer(handleModelDisplayStateSwitchChange),
              }}
            />
          </ModalTableWrapper>
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        onOpen={onModalOpen}
        visible={visible}
        onCancel={onCancel}
        title={strings.TITLE_MODAL_NC_CATALOG_DETAIL}
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      <DansanReasonInfoModal
        visible={dansanModalVisible}
        onCancel={() => {
          setDansanModalVisible(false);
        }}
        carGrade={carGrade}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      {/* ▼ 태그 관리 모달 영역 */}
      <CatalogTagSetupModal
        visible={tagSetupModalVisible}
        onCancel={() => {
          setModeGrdId(undefined);
          setTagSetupModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        modeGrdId={modeGrdId}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <TagPreviewModal
        visible={tagPreviewModalVisible}
        modeGrdId={modeGrdId}
        onCancel={() => {
          setTagPreviewModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        tagMasters={catalogDetail && catalogDetail.tagList}
      />
      {/* ▼ 동영상 관리 모달 영역 */}
      {catalogDetail && (
        <VideoRegistrationModal
          prodId={catalogDetail.prodId}
          visible={videoRegistrationModalVisible}
          onCancel={() => setVideoRegistrationModalVisible(false)}
          videoInfo={
            catalogDetail?.videoList.length
              ? catalogDetail.videoList[0]
              : undefined
          }
          onDataChange={async () => {
            await requestFetchCatalogDetail();
            if (onDataChange) onDataChange();
          }}
        />
      )}
      {/* ▼ 1년 렌트 모달 영역 */}
      {catalogDetail && (
        <>
          {/* 기본 렌탈료 설정 */}
          <RentFeeSetupModal
            prodId={catalogDetail.prodId}
            visible={rentFeeSetupModalVisible}
            onCancel={() => setRentFeeSetupModalVisible(false)}
            onDataChange={async () => {
              await requestFetchCatalogDetail();
              if (onDataChange) onDataChange();
            }}
          />
          <OincRtSetupModal
            prodId={catalogDetail.prodId}
            visible={oincRtSetupModalVisible}
            onCancel={() => setOincRtSetupModalVisible(false)}
            onDataChange={async () => {
              await requestFetchCatalogDetail();
            }}
          />
        </>
      )}
    </>
  );
};

NewCarCatalogModal.defaultProps = {
  onCancel: () => {},
  visible: false,
  dataId: undefined,
};
export default NewCarCatalogModal;
