import React, { useRef, useState } from "react";
import {
  GridApi,
  ICellRendererParams,
  LoadSuccessParams,
} from "ag-grid-community";
import {
  createCooperCarCatalogCols,
  createSwitchRenderer,
} from "./utils/table-utils";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import { getErrorMessage } from "../../../utils/common-utils";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import {
  getPageIndex,
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import commonStrings from "../../../constants/strings";
import { CooperType, OPTIONS_COOPER_TYPE } from "../../../constants/enums";
import CooperCarCatalogModal from "./pages/CooperCarCatalogModal";
import {
  fetchCooperCarCatalogs,
  turnOffCooperCarDisplayState,
  turnOnCooperCarDisplayState,
} from "../../../apis/cooper-catalogs";

const CooperCarCatalogManager: React.FC = () => {
  const gridApiRef = useRef<GridApi | null>(null);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string>("");
  const [selectedPartner, setSelectedPartner] = useState<CooperType>(
    CooperType.ELEVEN
  );
  const [logInfo, setLogInfo] = useState<string>("");

  const cols = createCooperCarCatalogCols(selectedPartner);

  const filterInitialValues = {
    cooperCd: CooperType.ELEVEN,
    prodNm: "",
  };

  const filterForms = [
    {
      key: "cooperCd",
      type: FilterElementType.Select,
      options: OPTIONS_COOPER_TYPE,
      label: "제휴사",
    },
    {
      key: "prodNm",
      type: FilterElementType.Input,
      label: "모델",
      placeholder: commonStrings.HINT_REP_CAR_CLASS_NM,
    },
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const { cooperCd, prodNm } = appliedFilterValues;

    setSelectedPartner(cooperCd);

    try {
      const { items, count } = await fetchCooperCarCatalogs({
        pageSize: paginationPageSize,
        pageIndex: getPageIndex(startRow, paginationPageSize),
        cooperCd,
        prodNm,
      });

      setTotalCount(count ?? 0);
      success({
        rowData: items ?? [],
        rowCount: count ?? 0,
      });
    } catch (e) {
      if (e.status !== 404) {
        setTotalCount(0);
        success({
          rowData: [],
          rowCount: 0,
        });
      }
    }
  };

  const requestUpdateDisplayState = async (
    checked: boolean,
    prodId: string,
    cooperCd: string,
    cooperProdNo = ""
  ) => {
    try {
      if (checked) {
        await turnOnCooperCarDisplayState(prodId, cooperCd, cooperProdNo);
      } else {
        await turnOffCooperCarDisplayState(prodId, cooperCd, cooperProdNo);
      }
      alertSuccess("상품 노출 여부가 변경되었습니다");
      if (gridApiRef?.current) {
        refreshServerSideTable(gridApiRef.current);
      }
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { prodId } = data;
    setSelectedDataId(prodId);
    setModalVisible(true);
  };

  const handleDisplayStateSwitchChange = (
    checked: boolean,
    prodId: string,
    cooperCd: string,
    cooperProdNo = ""
  ) => {
    showConfirm(
      checked ? "노출상태로 변경" : "숨김상태로 변경",
      checked
        ? "해당상품을 노출상태로 변경하시겠습니까?"
        : "해당상품을 숨김상태로 변경하시겠습니까?",
      async () => {
        await requestUpdateDisplayState(
          checked,
          prodId,
          cooperCd,
          cooperProdNo
        );
      }
    );
  };

  /**
   * Render Helpers
   */

  const createTableActions = () => {
    return [];
  };

  return (
    <>
      <PaginationTableLayout
        tableHeaderTitle="노출 차량 리스트"
        apiRef={(api) => {
          setGridApi(api);
          gridApiRef.current = api;
        }}
        headerTitle="온라인 상품관리(제휴연동)"
        headerDescription="제휴연동 렌터카 상품 정보를 관리 합니다."
        totalCount={totalCount}
        hideFilterAction
        hideSelectionCountText
        rightActions={createTableActions()}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        onDataLoad={onDataLoad}
        hidePagination
        defaultLimit={100}
        frameworkComponents={{
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
          ...createSwitchRenderer(handleDisplayStateSwitchChange),
        }}
        methodName="/admin/cooper/nc-catalogs"
        logInfo={logInfo}
      />
      <CooperCarCatalogModal
        onDataChange={async () => {
          await refreshServerSideTable(gridApi);
        }}
        visible={modalVisible}
        prodId={selectedDataId}
        onCancel={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};

CooperCarCatalogManager.defaultProps = {};
export default CooperCarCatalogManager;
