import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { createRewardCols } from "./utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout, {
  TableHeaderAction,
} from "../../../components/shared/layout/TableLayout";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { renderUserDetailButton } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import {
  bulkEditRewardState,
  editRewardState,
  fetchSpecialPromotionRewards,
  RewardStateParam,
} from "../../../apis/special-amt";
import PromotionReward from "../../../models/PromotionReward";
import CustomerModal from "../../customer/CustomerManager/pages/CustomerModal";
import TableCellButton from "../../../components/shared/composition/TableCellButton";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import PaymentCompleteModal from "./pages/PaymentCompleteModal";

const PromotionRewardManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [checkedRows, setCheckedRows] = useState<Array<PromotionReward>>([]);
  const [rows, setRows] = useState<Array<PromotionReward> | undefined>(
    undefined
  );
  const [customerModalVisible, setCustomerModalVisible] = useState<boolean>(
    false
  );
  const [
    paymentCompleteModalVisible,
    setPaymentCompleteModalVisible,
  ] = useState(false);
  const [custId, setCustId] = useState<string>();
  const [selectedPromotionReward, setSelectedPromotionReward] = useState<
    PromotionReward
  >();
  const [totalCount, setTotalCount] = useState(0);
  // 대량 지급처리 상태
  const [bulkMode, setBulkMode] = useState(false);
  const cols = createRewardCols();

  useEffect(() => {
    (async function fetchData() {
      await requestFetchPromotionRewards();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestFetchPromotionRewards = async () => {
    try {
      const noticeList = await fetchSpecialPromotionRewards();
      const { items, count } = noticeList;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
      setRows([]);
      setTotalCount(0);
    }
  };

  const requestEditRewardPaymentState = async (
    rewardInfo: PromotionReward,
    isPayment: boolean
  ) => {
    try {
      await editRewardState({
        spclAmtInfoId: rewardInfo.spclAmtInfoId,
        cntrId: rewardInfo.cntrId,
        rewardPayYn: isPayment ? "Y" : "N",
      });
      await requestFetchPromotionRewards();
      alertSuccess("리워드 지급상태가 변경되었습니다.");
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const requestBulkEditRewardPaymentStates = async (
    rewardInfos: RewardStateParam[]
  ) => {
    try {
      await bulkEditRewardState(rewardInfos);
      await requestFetchPromotionRewards();
      alertSuccess("리워드 지급상태가 변경되었습니다.");
      gridApi?.deselectAll();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */
  const handlePayRewardList = () => {
    setBulkMode(true);
    setPaymentCompleteModalVisible(true);
  };

  const handleCancelRewardList = async () => {
    showConfirm(
      "일괄지급취소",
      "선택한 지급내역을 일괄취소 처리하시겠습니까?",
      async () => {
        const params: RewardStateParam[] = checkedRows.map(
          ({ spclAmtInfoId, cntrId }) => ({
            spclAmtInfoId,
            cntrId,
            rewardPayYn: "N",
          })
        );
        await requestBulkEditRewardPaymentStates(params);
      }
    );
  };

  const handleCheckRowChanged = (rows: Array<PromotionReward>) => {
    setCheckedRows(rows);
  };

  const handleUserDetailClick = (custId?: string) => {
    setCustId(custId);
    setCustomerModalVisible(true);
  };

  /**
   * Render Helpers
   */

  const createActions = (): TableHeaderAction[] => [
    {
      title: "일괄지급처리",
      onClick: () => {
        handlePayRewardList();
      },
      disabled: checkedRows.length === 0,
    },
    {
      title: "일괄지급취소",
      onClick: async () => {
        await handleCancelRewardList();
      },
      type: "ghost",
      danger: true,
      disabled: checkedRows.length === 0,
    },
  ];

  return (
    <>
      <ContentLayout
        filterable={false}
        headerTitle="친구추천 프로그램 리워드 관리"
      >
        <TableLayout
          leftActions={createActions()}
          gridApi={gridApi}
          title="리워드 지급 이력"
          totalCount={totalCount}
          selectionCount={checkedRows.length}
          methodName="/admin/special-amt-reward"
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            onCheckRowChanged={handleCheckRowChanged}
            cols={cols}
            frameworkComponents={{
              userDetail: renderUserDetailButton((custId) => {
                handleUserDetailClick(custId);
              }, "custId"),
              recUserDetail: renderUserDetailButton(
                (custId) => {
                  handleUserDetailClick(custId);
                },
                "recCustId",
                "recCustNm"
              ),
              actionButtons: (props: ICellRendererParams) => {
                const { data } = props;
                const promotionReward = data as PromotionReward;
                const completeActionVisible = !promotionReward.rewardPayCompleteDt;
                const cancelActionVisible = promotionReward.rewardPayCompleteDt;
                return (
                  <div
                    style={{
                      display: "flex",
                      height: "40px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {completeActionVisible && (
                      <TableCellButton
                        size="small"
                        ghost
                        onClick={() => {
                          setSelectedPromotionReward(data);
                          setBulkMode(false);
                          setPaymentCompleteModalVisible(true);
                        }}
                        label="지급처리"
                      />
                    )}
                    {cancelActionVisible && (
                      <TableCellButton
                        size="small"
                        danger
                        ghost
                        onClick={() => {
                          showConfirm(
                            "지급취소",
                            "해당 이력을 지급취소 하시겠습니까?",
                            async () => {
                              await requestEditRewardPaymentState(
                                promotionReward,
                                false
                              );
                            }
                          );
                        }}
                        label="지급취소"
                      />
                    )}
                  </div>
                );
              },
            }}
          />
        </TableLayout>
      </ContentLayout>
      <CustomerModal
        id={custId}
        visible={customerModalVisible}
        onCancel={() => {
          setCustomerModalVisible(false);
        }}
      />
      <PaymentCompleteModal
        bulkMode={bulkMode}
        selectedRewards={checkedRows}
        promotionReward={selectedPromotionReward}
        visible={paymentCompleteModalVisible}
        onCancel={() => {
          setPaymentCompleteModalVisible(false);
        }}
        onDataChange={async () => {
          gridApi?.deselectAll();
          await requestFetchPromotionRewards();
        }}
      />
    </>
  );
};

PromotionRewardManager.defaultProps = {};
export default PromotionRewardManager;
