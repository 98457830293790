import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_SMALL,
} from "../../../../utils/table-utils";
import {
  SURVEY_REPLY_GROUP_CD_5,
  SURVEY_REPLY_GROUP_CD_4,
  SURVEY_REPLY_GROUP_CD_3,
  SURVEY_REPLY_GROUP_CD_2,
  SURVEY_REPLY_GROUP_CD_1,
} from "../../../../constants/enums";

export const STAR_RENDERER = "starRenderer";

export const createCarManagerSurveyReportCols = (): TableColumn[] => {
  return [
    // 이름
    createTableCol("carmanNm", "이름", FilterType.Text, {
      width: CW_SMALL,
      flex: 1,
    }),
    // 친절도 평점
    createTableCol("avgPoint03", "친절도 평점", FilterType.Text, {
      width: CW_SMALL,
      flex: 1,
      valueGetter: ({ data }) => {
        const { avgPoint03 } = data;

        if (avgPoint03) {
          const point = Number(avgPoint03);
          return point.toFixed(1);
        }

        return 0;
      },
    }),
    // 전문성 평점
    createTableCol("avgPoint04", "전문성 평점", FilterType.Text, {
      width: CW_SMALL,
      flex: 1,
      valueGetter: ({ data }) => {
        const { avgPoint04 } = data;

        if (avgPoint04) {
          const point = Number(avgPoint04);
          return point.toFixed(1);
        }

        return 0;
      },
    }),
    // 종합 평점
    createTableCol("avgPoint05", "종합 평점", FilterType.Text, {
      width: CW_SMALL,
      flex: 1,
      valueGetter: ({ data }) => {
        const { avgPoint05 } = data;

        if (avgPoint05) {
          const point = Number(avgPoint05);
          return point.toFixed(1);
        }

        return 0;
      },
    }),
    // 담당고객 수
    createTableCol("ldCntrCnt", "인도 완료 수", FilterType.Text, {
      width: CW_SMALL,
      flex: 1,
    }),
    // 응답고객 수
    createTableCol("replyCntrCnt", "설문 응답 수", FilterType.Text, {
      width: CW_SMALL,
      flex: 1,
    }),
    // 상세내용,
    createActionCol(80),
  ];
};

export const createCarManagerSurveyReportDetailsExcelCols = (): TableColumn[] => {
  return [
    createTableCol("valnDt", "응답완료날짜", FilterType.Text),
    createTableCol("custNm", "계약자명", FilterType.Text),
    createTableCol("avgPoint03", "친절도 별점", FilterType.Text),
    createTableCol("avgPoint04", "전문성 별점", FilterType.Text),
    createTableCol("avgPoint05", "종합 별점", FilterType.Text),
    createTableCol("srveReplyItem", "종합 질문 답변", FilterType.Text),
    createTableCol("cntrId", "계약번호", FilterType.Text),
    createTableCol("cntrDt", "계약일", FilterType.Text),
    createTableCol("carmanNm", "카매니저", FilterType.Text),
    createTableCol("repCarTypeNm", "모델", FilterType.Text),
    createTableCol("ncarClsFlagNm", "계약유형", FilterType.Text),
    createTableCol("gndr", "성별", FilterType.Text),
    createTableCol("brthDt", "생년월일", FilterType.Text),
    createTableCol("poaSiteNm", "사용지역명", FilterType.Text),
    createTableCol("fuelNm", "연료타입", FilterType.Text),
    createTableCol("extShape", "차형", FilterType.Text),
  ];
};

export const createServiceSurveyReportCols = (): TableColumn[] => {
  return [
    // SEQ
    createTableCol("seq", "순위", undefined, {
      width: CW_SMALL,
      valueGetter: ({ data }) => {
        if (data[0]) {
          return data[0].replySort;
        }
        return "";
      },
    }),
    // 1점
    createTableCol("1", "불만이에요", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        const filtered = data.filter((replyInfo: any) => {
          return replyInfo.replyClsCd === SURVEY_REPLY_GROUP_CD_1;
        });
        if (filtered.length > 0) {
          return filtered[0].srveReplyItemNm;
        }
        return "";
      },
    }),
    // 2점
    createTableCol("2", "아쉬워요", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        const filtered = data.filter((replyInfo: any) => {
          return replyInfo.replyClsCd === SURVEY_REPLY_GROUP_CD_2;
        });
        if (filtered.length > 0) {
          return filtered[0].srveReplyItemNm;
        }
        return "";
      },
    }),
    // 3점
    createTableCol("3", "괜찮아요", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        const filtered = data.filter((replyInfo: any) => {
          return replyInfo.replyClsCd === SURVEY_REPLY_GROUP_CD_3;
        });
        if (filtered.length > 0) {
          return filtered[0].srveReplyItemNm;
        }
        return "";
      },
    }),
    // 4점
    createTableCol("4", "좋아요", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        const filtered = data.filter((replyInfo: any) => {
          return replyInfo.replyClsCd === SURVEY_REPLY_GROUP_CD_4;
        });
        if (filtered.length > 0) {
          return filtered[0].srveReplyItemNm;
        }
        return "";
      },
    }),
    // 5점
    createTableCol("5", "최고에요", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        const filtered = data.filter((replyInfo: any) => {
          return replyInfo.replyClsCd === SURVEY_REPLY_GROUP_CD_5;
        });
        if (filtered.length > 0) {
          return filtered[0].srveReplyItemNm;
        }
        return "";
      },
    }),
  ];
};

export const colNameLabel = {
  count03: "친절도 별점",
  count04: "전문성 별점",
  count05: "종합 별점",
};

export const pointLabels: Array<keyof typeof colNameLabel> = [
  "count03",
  "count04",
  "count05",
];

export const createServicePointCols = (
  colName: keyof typeof colNameLabel
): TableColumn[] => {
  return [
    // 별점
    createTableCol("point", colNameLabel[colName], undefined, {
      minWidth: CW_SMALL,
      flex: 1,
      cellRenderer: STAR_RENDERER,
    }),
    // 고객 응답 수
    createTableCol(colName, "설문 응답 수", undefined, {
      width: CW_SMALL,
      cellStyle: {
        textAlign: "right",
      },
    }),
  ];
};

export const createServiceCount03Cols = (): TableColumn[] => {
  return [
    // 친절도 별점
    createTableCol("count03", "친절도 별점", undefined, {
      minWidth: CW_SMALL,
      flex: 1,
      cellRenderer: STAR_RENDERER,
    }),
    // 고객 응답 수
    createTableCol("pointCnt", "설문 응답 수", undefined, {
      width: CW_SMALL,
      cellStyle: {
        textAlign: "right",
      },
    }),
  ];
};

export const createServiceCount04Cols = (): TableColumn[] => {
  return [
    // 전문성 별점
    createTableCol("count04", "전문성 별점", undefined, {
      minWidth: CW_SMALL,
      flex: 1,
      cellRenderer: STAR_RENDERER,
    }),
    // 고객 응답 수
    createTableCol("pointCnt", "설문 응답 수", undefined, {
      width: CW_SMALL,
      cellStyle: {
        textAlign: "right",
      },
    }),
  ];
};

export const createServiceCount05Cols = (): TableColumn[] => {
  return [
    // 종합 별점
    createTableCol("count05", "종합 별점", undefined, {
      minWidth: CW_SMALL,
      flex: 1,
      cellRenderer: STAR_RENDERER,
    }),
    // 고객 응답 수
    createTableCol("pointCnt", "설문 응답 수", undefined, {
      width: CW_SMALL,
      cellStyle: {
        textAlign: "right",
      },
    }),
  ];
};
