import React, { useState } from "react";

import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import TagoCarCatalogDetail from "../../../../../../../models/TagoCarCatalogDetail";
import {FormElementType, FormInfo} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {renderLoading} from "../../../../../../../utils/render-utils";
import {FormikProps, FormikValues} from "formik";
import {updateUcTagobuyCatalogsCarOption} from "../../../../../../../apis/tb-catalogs";

interface Props extends ModalProps {
  catalogDetail?: TagoCarCatalogDetail;
}

const TagoCarMainOptionModifyModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, catalogDetail, onDataChange } = props;

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [defaultProdId, setDefaultProdId] = useState<string>();
  const [defaultCarId, setDefaultCarId] = useState<string>();
  const [defaultA60001, setDefaultA60001] = useState<boolean>();
  const [defaultA60002, setDefaultA60002] = useState<boolean>();
  const [defaultA60003, setDefaultA60003] = useState<boolean>();
  const [defaultA60004, setDefaultA60004] = useState<boolean>();
  const [defaultA60005, setDefaultA60005] = useState<boolean>();
  const [defaultA60006, setDefaultA60006] = useState<boolean>();
  const [defaultA60007, setDefaultA60007] = useState<boolean>();
  const [defaultA60008, setDefaultA60008] = useState<boolean>();
  const [defaultA60009, setDefaultA60009] = useState<boolean>();
  const [defaultA60010, setDefaultA60010] = useState<boolean>();


  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);

    if (catalogDetail) {
      setDefaultProdId(catalogDetail.prodId);
      setDefaultCarId(catalogDetail.carId);
      setDefaultA60001(catalogDetail.mainOptionsList[0].useYn == "Y" ? true : false);
      setDefaultA60002(catalogDetail.mainOptionsList[1].useYn == "Y" ? true : false);
      setDefaultA60003(catalogDetail.mainOptionsList[2].useYn == "Y" ? true : false);
      setDefaultA60004(catalogDetail.mainOptionsList[3].useYn == "Y" ? true : false);
      setDefaultA60005(catalogDetail.mainOptionsList[4].useYn == "Y" ? true : false);
      setDefaultA60006(catalogDetail.mainOptionsList[5].useYn == "Y" ? true : false);
      setDefaultA60007(catalogDetail.mainOptionsList[6].useYn == "Y" ? true : false);
      setDefaultA60008(catalogDetail.mainOptionsList[7].useYn == "Y" ? true : false);
      setDefaultA60009(catalogDetail.mainOptionsList[8].useYn == "Y" ? true : false);
      setDefaultA60010(catalogDetail.mainOptionsList[9].useYn == "Y" ? true : false);
    } else {
      setDefaultProdId("");
      setDefaultCarId("");
      setDefaultA60001(false);
      setDefaultA60002(false);
      setDefaultA60003(false);
      setDefaultA60004(false);
      setDefaultA60005(false);
      setDefaultA60006(false);
      setDefaultA60007(false);
      setDefaultA60008(false);
      setDefaultA60009(false);
      setDefaultA60010(false);

    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const forms: Array<FormInfo> = [
    {      key: "A60001",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "헤드램프",        }, 
    {      key: "A60002",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "선루프",        },
    {      key: "A60003",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "하이패스",        },
    {      key: "A60004",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "열선시트",        },
    {      key: "A60005",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "통풍시트",        },
    {      key: "A60006",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "내비게이션",        },
    {      key: "A60007",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "AVM",        },
    {      key: "A60008",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "LDWS",        },
    {      key: "A60009",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "크루즈",        },
    {      key: "A60010",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "HUD",        },
  ]

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
      setConfirmLoading(true);

      const commCdList: string[] = [];

      if(values.A60001) {  commCdList.push("A60001");   }
      if(values.A60002) {  commCdList.push("A60002");   }
      if(values.A60003) {  commCdList.push("A60003");   }
      if(values.A60004) {  commCdList.push("A60004");   }
      if(values.A60005) {  commCdList.push("A60005");   }
      if(values.A60006) {  commCdList.push("A60006");   }
      if(values.A60007) {  commCdList.push("A60007");   }
      if(values.A60008) {  commCdList.push("A60008");   }
      if(values.A60009) {  commCdList.push("A60009");   }
      if(values.A60010) {  commCdList.push("A60010");   }

      if (catalogDetail) {
        try {
          await updateUcTagobuyCatalogsCarOption(catalogDetail.prodId, catalogDetail.carId, commCdList);
          if (onDataChange) onDataChange();
          if (onCancel) onCancel();
        } catch (e) {          
          console.log(e);
        } finally {
          setConfirmLoading(false);
        }
      } else {
        setConfirmLoading(false);
        if (onCancel) onCancel();
      }
  }; 

  /**
   * Render Helpers
   */


  return (
    <Modal
      size="small"
      title="주요옵션 변경"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? (
          renderLoading()
      ) : (
          <FormBuilder
              formRef={(ref) => {
                formik = ref;
              }}
              initialValues={
                {
                  A60001: defaultA60001,
                  A60002: defaultA60002,
                  A60003: defaultA60003,
                  A60004: defaultA60004,
                  A60005: defaultA60005,
                  A60006: defaultA60006,
                  A60007: defaultA60007,
                  A60008: defaultA60008,
                  A60009: defaultA60009,
                  A60010: defaultA60010,
                }
              }
              forms={forms}
              onSubmit={handleSubmit}
          />
      )}
    </Modal>
  );
};

TagoCarMainOptionModifyModal.defaultProps = {
  visible: true,
};
export default TagoCarMainOptionModifyModal;
