import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import FormBuilder, {
  defaultFormItemLayout,
} from "../../../../../components/shared/data-entry/FormBuilder";

import {
  createFormInfo,
  createInitialValues,
  createInitialValuesFromCarBookingDetail,
} from "./utils/form-utils";
import {
  renderLoading,
  alertError,
  alertSuccess,
} from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { ModalProps } from "../../../../types";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import {
  editCarBookingHistory,
  fetchCarBookingDetail,
} from "../../../../../apis/car-booking";
import CarBookingDetail from "../../../../../models/CarBookingDetail";

interface Props extends ModalProps {
  preRsvId?: string;
}

const CarBookingHistoryDetailModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, preRsvId, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );
  const [
    carBookingDetail,
    setCarBookingDetail,
  ] = useState<CarBookingDetail | null>(null);
  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setCarBookingDetail(null);
    if (preRsvId) {
      // Fetch data
      setDataFetching(true);
      try {
        const carBookingDetail = await fetchCarBookingDetail(preRsvId);
        setCarBookingDetail(carBookingDetail);
        setInitialValues(
          createInitialValuesFromCarBookingDetail(carBookingDetail)
        );
      } catch (e) {
        if (e.getMessage) {
          alertError(e.getMessage());
        } else {
          alertError(e.toString());
        }
      } finally {
        setDataFetching(false);
      }
    } else {
      setInitialValues(createInitialValues());
    }
  };

  /**
   * Private Functions
   */

  const requestEditCarBookingHistoryDetail = async (values: FormikValues) => {
    if (carBookingDetail) {
      const { callYn, picMemo } = values;
      setConfirmLoading(true);
      try {
        await editCarBookingHistory(
          carBookingDetail.preRsvId,
          callYn ? "Y" : "N",
          picMemo
        );
        alertSuccess("사전예약 상담정보가 변경되었습니다.");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (preRsvId) {
      await requestEditCarBookingHistoryDetail(values);
    }
  };

  /**
   * Render Helpers
   */
  const renderDetail = () => {
    const marginBottom = {
      marginBottom: "15px",
    };

    if (carBookingDetail) {
      return (
        <div>
          <>
            <FormItem
              label="예약순번"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {carBookingDetail.preRsvId}
            </FormItem>
            <FormItem
              label="신청고객명"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {carBookingDetail.custNm}
            </FormItem>
            <FormItem
              label="휴대폰 번호"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {carBookingDetail.hpNo}
            </FormItem>
            <FormItem
              label="예약차량"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {carBookingDetail.prodNm}
            </FormItem>
            <FormItem
              label="거주지"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {carBookingDetail.useAreaNm}
            </FormItem>
          </>

          <FormBuilder
            formRef={(ref) => {
              formik = ref;
            }}
            initialValues={initialValues}
            forms={createFormInfo()}
            onSubmit={handleSubmit}
          />
        </div>
      );
    }

    return "예약정보가 없습니다";
  };

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      size="small"
      visible={visible}
      onCancel={onCancel}
      title="예약 상세"
      onOk={() => {
        if (!carBookingDetail && onCancel) onCancel();
        else if (formik) formik.handleSubmit();
      }}
    >
      {dataFetching ? renderLoading() : renderDetail()}
    </Modal>
  );
};

CarBookingHistoryDetailModal.defaultProps = {};

export default CarBookingHistoryDetailModal;
