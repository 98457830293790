import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { ICellRendererParams } from "ag-grid-community";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  ExhibitionCatalog,
  ExhibitionMaster,
} from "../../../../../apis/ex/types";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import { formatDateString } from "../../../../../utils/date-utils";
import Button from "../../../../../components/shared/general/antd/Button";
import ExhibitionMasterInfoEditModal from "./pages/ExihibitionMasterInfoEditModal";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  editTimeDealTime,
  fetchExhibitionCatalogs,
  fetchExhibitionDetail,
} from "../../../../../apis/ex";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createActionCellRenderers } from "../../../../../utils/table-utils";
import { createExhibitionCatalogCols } from "./utils/table-utils";
import TableLayout from "../../../../../components/shared/layout/TableLayout";
import ExhibitionCatalogAddModal from "./pages/ExhibitionCatalogAddModal";
import ExhibitionCatalogSortOrderEditModal from "./pages/ExhibitionCatalogSortOrderEditModal";
import DateRangePicker from "../../../../../components/shared/data-entry/antd/DateRangePicker";
import TimeDealInfoEditModal from "./pages/TimeDealInfoEditModal";

interface Props extends ModalProps {
  exhibitionMaster?: ExhibitionMaster;
}

const TimeDealExhibitionDetailModal: React.FC<Props> = (props: Props) => {
  const cols = createExhibitionCatalogCols();
  const [catalogs, setCatalogs] = useState<ExhibitionCatalog[]>();
  const [catalogTotalCount, setCatalogTotalCount] = useState(0);
  const { visible, onDataChange, onCancel, exhibitionMaster } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [masterInfoEditModalVisible, setMasterInfoEditModalVisible] = useState(
    false
  );

  const [
    selectedTimeDealItem,
    setSelectedTimeDealItem,
  ] = useState<ExhibitionCatalog | null>(null);
  const [exhibitionMasterDetail, setExhibitionMasterDetail] = useState<
    ExhibitionMaster
  >();

  const [timeDealStartDate, setTimeDealStartDate] = useState<string>();
  const [timeDealEndDate, setTimeDealEndDate] = useState<string>();
  const [addExCatalogModalVisible, setAddExCatalogModalVisible] = useState(
    false
  );
  const [
    editCatalogSortOrderModalVisible,
    setEditCatalogSortOrderModalVisible,
  ] = useState(false);
  const [
    timeDealInfoEditModalVisible,
    setTimeDealInfoEditModalVisible,
  ] = useState<boolean>(false);

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchExhibitionDetail();
    await requestFetchExhibitionCatalogs();
    setDataFetching(false);
  };

  const requestFetchExhibitionDetail = async () => {
    if (exhibitionMaster) {
      try {
        const exhibition = await fetchExhibitionDetail(
          exhibitionMaster.exhMstSeq
        );
        setExhibitionMasterDetail(exhibition);
        setTimeDealStartDate(exhibition?.timedealStDt ?? exhibition.exhStDt);
        setTimeDealEndDate(exhibition?.timedealEndDt ?? exhibition.exhEndDt);
      } catch (e) {
        alertError(getErrorMessage(e));
      }
    }
  };

  const requestFetchExhibitionCatalogs = async () => {
    if (exhibitionMaster) {
      const { list, listCnt } = await fetchExhibitionCatalogs(
        exhibitionMaster.exhMstSeq,
        exhibitionMaster.exhGbnCd
      );

      setCatalogTotalCount(listCnt);
      setCatalogs(list);
    }
  };

  const requestEditTimeDealTime = async () => {
    try {
      await editTimeDealTime({
        exhMstSeq: exhibitionMaster?.exhMstSeq ?? "",
        timedealStDt: timeDealStartDate as string,
        timedealEndDt: timeDealEndDate as string,
      });

      await requestFetchExhibitionDetail();
    } catch (error) {
      alertError(getErrorMessage(error));
    } finally {
      alertSuccess("타임딜 기간 변경되었습니다.");
    }
  };

  const handleListItemClick = (itemValue: ICellRendererParams) => {
    const { data } = itemValue;
    setSelectedTimeDealItem(data);
    setTimeDealInfoEditModalVisible(true);
  };

  const handleTimeDealDateChange = () => {
    requestEditTimeDealTime();
  };

  const renderDetail = () => {
    if (exhibitionMasterDetail) {
      const exhibitionInfoSection: SectionFieldInfo[] = [
        {
          label: "제목",
          content: exhibitionMasterDetail.exhTitle,
          span: 12,
        },
        { label: "유형", content: "타임딜", span: 12 },
        {
          label: "설명",
          content: exhibitionMasterDetail.exhDesc,
          span: 24,
        },
        {
          label: "게시순번",
          content: exhibitionMasterDetail.exhMstViewSeq,
          span: 12,
        },
        {
          label: "노출기간",
          content: `${formatDateString(
            exhibitionMasterDetail.exhStDt,
            true
          )} ~ ${formatDateString(exhibitionMasterDetail.exhEndDt, true)}`,
          span: 12,
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <Button
                onClick={() => {
                  setMasterInfoEditModalVisible(true);
                }}
              >
                수정하기
              </Button>
            );
          },
        },
      ];

      const exhibitionListSection: SectionFieldInfo[] = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => (
            <TableLayout
              hideExcelAction
              hideFilterAction
              title=""
              totalCount={catalogTotalCount}
              rightActions={[
                {
                  title: "게시순번 관리",
                  onClick: () => {
                    if (!catalogs || catalogs.length === 0) {
                      alertError(
                        "기획전에 등록된 차량이 없습니다. 차량을 먼저 등록해주세요."
                      );
                    } else {
                      setEditCatalogSortOrderModalVisible(true);
                    }
                  },
                },
                {
                  title: "차량 관리",
                  onClick: () => {
                    setAddExCatalogModalVisible(true);
                  },
                },
              ]}
              methodName={`/admin/ex-catalogs/${exhibitionMaster?.exhMstSeq}/Y`}
            >
              <div
                style={{
                  height: "400px",
                }}
              >
                <ClientRowTable
                  cols={cols}
                  rowData={catalogs}
                  frameworkComponents={{
                    ...createActionCellRenderers(
                      undefined,
                      handleListItemClick,
                      {
                        hideDeleteButton: true,
                      }
                    ),
                  }}
                />
              </div>
            </TableLayout>
          ),
        },
      ];

      return (
        <div>
          <DetailSection
            label="문자 전송 템플릿 명"
            fieldInfos={exhibitionInfoSection}
          />
          <DetailSection label="타임딜 기간">
            <Div>
              <p className="title">타임딜 기간</p>
              <DateRangePicker
                style={{ width: "100%", flex: 1 }}
                placeholder={["시작 일시", "종료 일시"]}
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                defaultValue={[
                  moment(timeDealStartDate, "YYYYMMDDHHmm"),
                  moment(timeDealEndDate, "YYYYMMDDHHmm"),
                ]}
                onChange={(_, datesString) => {
                  const newStartDate = moment(datesString[0]).format(
                    "YYYYMMDDHHmm"
                  );
                  const newEndDate = moment(datesString[1]).format(
                    "YYYYMMDDHHmm"
                  );

                  setTimeDealStartDate(newStartDate);
                  setTimeDealEndDate(newEndDate);
                }}
              />
            </Div>
            <Button onClick={handleTimeDealDateChange}>적용하기</Button>
          </DetailSection>
          <DetailSection
            label="타임딜 리스트"
            fieldInfos={exhibitionListSection}
          />
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        visible={visible}
        onCancel={onCancel}
        title="기획전 상세 (신차장기)"
        onOpen={onModalOpen}
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      {/* 기획전 기본정보 수정 */}
      <ExhibitionMasterInfoEditModal
        visible={masterInfoEditModalVisible}
        onCancel={() => {
          setMasterInfoEditModalVisible(false);
        }}
        exhibitionMaster={exhibitionMasterDetail}
        onDataChange={async () => {
          await requestFetchExhibitionDetail();
          if (onDataChange) onDataChange();
        }}
      />
      {/* 기획전 차량 게시순번관리 */}
      <ExhibitionCatalogSortOrderEditModal
        exhibitionMaster={exhibitionMaster}
        visible={editCatalogSortOrderModalVisible}
        onCancel={() => {
          setEditCatalogSortOrderModalVisible(false);
        }}
        onDataChange={async () => {
          await requestFetchExhibitionCatalogs();
        }}
      />
      {/* 기획전 차량연결 */}
      <ExhibitionCatalogAddModal
        initialCheckList={catalogs}
        exhibitionMaster={exhibitionMasterDetail}
        visible={addExCatalogModalVisible}
        onCancel={() => {
          setAddExCatalogModalVisible(false);
        }}
        onDataChange={async () => {
          await requestFetchExhibitionCatalogs();
        }}
      />
      {selectedTimeDealItem && (
        <TimeDealInfoEditModal
          visible={timeDealInfoEditModalVisible}
          exhibitionMaster={selectedTimeDealItem}
          onCancel={() => {
            setTimeDealInfoEditModalVisible(false);
          }}
          onDataChange={async () => {
            setDataFetching(true);
            await requestFetchExhibitionCatalogs();
            setDataFetching(false);
          }}
        />
      )}
    </>
  );
};

export default TimeDealExhibitionDetailModal;

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;

  p.title {
    margin: 0;
    width: 100px;

    &::after {
      content: "*";
      color: #ed1d34;
      margin-left: 4px;
    }
  }
`;
