import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import TagoCeftDetail from "../../models/TagoCeftDetail";

/**
 * 미래가치계수 리스트 조회
 */
export const selectUcTagobuyWrthCeftList = async (): Promise<ListItemResponse<TagoCeftDetail>> => {
  
  const url = `${ROOT_URL}/admin/tb-wrth-ceft`;
  const res = await request(HttpMethod.Get, url, );

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(TagoCeftDetail, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 미래가치계수 수정
 */
export const updateUcTagobuyWrthCeftList = async (
  yr: string,
  ceft1: string, 
  ceft2: string,
  ceft3: string,
  ceft4: string,
  ceft5: string,
  ceft6: string,
  ceft7: string,
  ceft8: string,
  ceft9: string,
  ceft10: string,
  ceft11: string,
  ceft12: string,
  ceft13: string,
) => {
  const url = `${ROOT_URL}/admin/tb-wrth-ceft/${yr}`;
  await request(HttpMethod.Post, url, undefined, {
    yr,
    ceft1, 
    ceft2,
    ceft3,
    ceft4,
    ceft5,
    ceft6,
    ceft7,
    ceft8,
    ceft9,
    ceft10,
    ceft11,
    ceft12,
    ceft13,
  });
};
