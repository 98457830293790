import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import NewCarDeliveryTermInfo from "../../models/NewCarDeliveryTermInfo";
import {NewCarDeliveryTermInfoAddParam, NewCarDeliveryTermInfoEditParam} from "./types";
import DeliveryTermProduct from "../../models/DeliveryTermProduct";

const apiRootUrl = `${ROOT_URL}/admin/dlv-term`;

/**
 * 납기일 관리 리스트 조회
 */
export const fetchDeliveryTermInfos = async (): Promise<
  ListItemResponse<NewCarDeliveryTermInfo>
> => {
  const res = await request(HttpMethod.Get, apiRootUrl);

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(NewCarDeliveryTermInfo, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 납기일 관리 등록
 */
export const addDeliveryTermInfo = async (
  params: NewCarDeliveryTermInfoAddParam
) => {
  await request(HttpMethod.Post, apiRootUrl, undefined, params);
};

/**
 * 납기일 관리 수정
 */
export const editDeliveryTermInfo = async (
  dlvTermId: string,
  params: NewCarDeliveryTermInfoEditParam
) => {
  await request(
    HttpMethod.Put,
    `${apiRootUrl}/${dlvTermId}`,
    undefined,
    params
  );
};

/**
 * 납기일 관리 삭제
 */
export const deleteDeliveryTermInfo = async (dlvTermId: string) => {
  await request(HttpMethod.Delete, `${apiRootUrl}/${dlvTermId}`, undefined);
};

/**
 * 납기정보 입력 상품 리스트 조회
 */
export const fetchDeliveryTermTargetTrims = async (): Promise<
  ListItemResponse<DeliveryTermProduct>
> => {
  const res = await request(HttpMethod.Get, `${ROOT_URL}/admin/dlv-term-input`);

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(DeliveryTermProduct, list as Array<JSON>),
    count: Number(listCnt),
  };
};
