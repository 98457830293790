import React, { useState } from "react";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { fetchEstimationCatalogDetail } from "../../../../../apis/est-sim";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import EstimationCatalogDetail from "../../../../../models/EstimationSimulator/EstimationCatalogDetail";
import { defaultFormItemLayout } from "../../../../../components/shared/data-entry/FormBuilder";
import { FormItemProps } from "antd";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";

interface Props extends ModalProps {
  prodId?: string;
  modeGrdId?: string;
}

const RecCarInfoModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, prodId, modeGrdId } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [detail, setDetail] = useState<EstimationCatalogDetail>();

  const onModalOpen = async () => {
    if (prodId) {
      await requestFetchCarInfo(prodId);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchCarInfo = async (prodId: string) => {
    try {
      setDataFetching(true);
      const catalogDetail = await fetchEstimationCatalogDetail(prodId);
      setDetail(catalogDetail);
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  const renderDetail = () => {
    let trim;

    if (detail && prodId && modeGrdId) {
      const filtered = detail.trimList.filter((trim) => {
        return trim.modeGrdId === modeGrdId;
      });
      trim = filtered[0];
    }

    if (trim) {
      const formConfig = {
        labelAlign: "right",
        colon: true,
        ...defaultFormItemLayout,
      } as FormItemProps;

      const textStyle = {
        color: "#626DFF",
      };

      return (
        <div
          style={{
            borderRadius: "8px",
            backgroundColor: "#FAFAFC",
            padding: "16px",
            marginBottom: "24px",
          }}
        >
          <FormItem label="상품명" {...formConfig}>
            <span style={textStyle}>{trim.cartypeNm}</span>
          </FormItem>
          <FormItem label="등급명" {...formConfig}>
            <span style={textStyle}>{trim.carGradeNm}</span>
          </FormItem>
        </div>
      );
    }
  };

  return (
    <Modal
      title="추천차량정보"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      size="small"
      footer={null}
    >
      {dataFetching ? renderLoading() : renderDetail()}
    </Modal>
  );
};

RecCarInfoModal.defaultProps = {};
export default RecCarInfoModal;
