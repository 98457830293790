import moment from "moment";

class Event {
  // 이벤트순번
  public evntSeq = "";

  // 제목
  public titl = "";

  // 이벤트기간여부
  public evntTermYn = "";

  // 이벤트시작일시
  public evntStDt = "";

  // 이벤트종료일시
  public evntEndDt = "";

  // 조회횟수
  public readCnt = "";

  // 사용여부
  public useYn = "";

  // 삭제여부
  public delYn = "";

  // 등록자ID
  public regUser = "";

  // 등록일시
  public regDtm?: Date;

  // 수정자ID
  public updUser = "";

  // 수정일시
  public updDtm = "";

  // 이미지 경로
  public imgPath = "";

  // 이벤트분류명
  public evntClfNm = "";

  // 상단고정여부
  public topFixedYn = "";

  // 응모카운트
  public evntPtcpCnt = 0;

  get isActive() {
    const start = moment(this.evntStDt);
    const end = moment(this.evntEndDt).add("d", 1);

    const current = moment();

    if (start && !this.evntEndDt) return "Y";

    if (current.isBetween(start, end)) return "Y";

    return "N";
  }
}

export default Event;
