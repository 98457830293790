import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import FormBuilder, {
  defaultFormItemLayout,
} from "../../../../../components/shared/data-entry/FormBuilder";

import {
  createConsultingRequestParam,
  createFormInfo,
  createInitialValues,
  createInitialValuesFromConsultingDetail,
} from "./utils/form-utils";
import {
  renderLoading,
  alertError,
  alertSuccess,
} from "../../../../../utils/render-utils";
import CustomerConsultingRequestDetail from "../../../../../models/CustomerConsultingRequestDetail";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { ModalProps } from "../../../../types";
import {
  editCustomerConsultingRequest,
  fetchCustomerConsultingRequestDetail,
} from "../../../../../apis/counseling-recpt";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import useAuth from "../../../../../hooks/useAuth";

interface Props extends ModalProps {
  cnslRecptId?: string;
}

const CustomerConsultingRequestDetailModal: React.FC<Props> = (
  props: Props
) => {
  const { visible, onCancel, cnslRecptId, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );
  const [
    consultingDetail,
    setConsultingDetail,
  ] = useState<CustomerConsultingRequestDetail | null>(null);
  let formik: FormikProps<FormikValues>;

  const { user } = useAuth();

  const onModalOpen = async () => {
    if (cnslRecptId) {
      // Fetch data
      setDataFetching(true);
      try {
        const consultingDetail = await fetchCustomerConsultingRequestDetail(
          cnslRecptId
        );
        setConsultingDetail(consultingDetail);
        setInitialValues(
          createInitialValuesFromConsultingDetail(consultingDetail)
        );
      } catch (e) {
        alertError("상세 정보를 불러올 수 없습니다.");
      } finally {
        setDataFetching(false);
      }
    } else {
      setInitialValues(createInitialValues());
    }
  };

  /**
   * Private Functions
   */

  const requestEditCustomerConsultingRequest = async (values: FormikValues) => {
    if (consultingDetail) {
      setConfirmLoading(true);
      try {
        await editCustomerConsultingRequest(
          consultingDetail.cnslRecptId,
          createConsultingRequestParam(values)
        );
        alertSuccess("상담신청정보가 변경되었습니다.");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (cnslRecptId) {
      await requestEditCustomerConsultingRequest(values);
    }
  };

  /**
   * Render Helpers
   */
  const renderDetail = () => {
    const marginBottom = {
      marginBottom: "15px",
    };
    return (
      <div>
        {consultingDetail && (
          <>
            <FormItem
              label="상품유형"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {consultingDetail.ncarClsFlagNm}
            </FormItem>
            <FormItem
              label="상담유형"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {consultingDetail.cnslClsNm}
            </FormItem>
            <FormItem
              label="상품명"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {consultingDetail.prodDesc}
            </FormItem>
            <FormItem
              label="상담인입 일시"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {consultingDetail.formattedReqDate}
            </FormItem>
            <FormItem
              label="상담고객명"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {consultingDetail.custNm}
            </FormItem>
            <FormItem
              label="휴대폰 번호"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {consultingDetail.hpNo}
            </FormItem>
            <FormItem
              label="문의 내용"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {consultingDetail.custAsk}
            </FormItem>
            <FormItem
              label="완료상담사"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {consultingDetail.finClslNm}-{consultingDetail.formattedFinClsDate}
            </FormItem>
            <FormItem
              label="최종수정"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {consultingDetail.updUserNm}-{consultingDetail.formattedUpdDate}
            </FormItem>
            <FormItem
              label="현재상담사"
              style={marginBottom}
              {...defaultFormItemLayout}
            >
              {user.userNm}
            </FormItem>
          </>
        )}

        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={createFormInfo()}
          onSubmit={handleSubmit}
        />
      </div>
    );
  };

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      size="small"
      visible={visible}
      onCancel={onCancel}
      title="상담신청 상세"
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
    >
      {dataFetching ? renderLoading() : renderDetail()}
    </Modal>
  );
};

CustomerConsultingRequestDetailModal.defaultProps = {};

export default CustomerConsultingRequestDetailModal;
