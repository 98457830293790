import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

export const ALL_DISPLAY_STATE_SWITCH = "allDisplaySwtich";

export const NC_DISPLAY_STATE_SWITCH = "ncDisplaySwtich";

export const UC_DISPLAY_STATE_SWITCH = "ucDisplaySwtich";

export const createEstCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createServerTableCol("regDt", "등록일", {
      width: CW_SMALL_M,
      cellRenderer: dateFormatRenderer("regDt"),
    }),
    createServerTableCol("viewYn", "전체후기노출", {
      width: CW_SMALL_M,
      cellRenderer: ALL_DISPLAY_STATE_SWITCH,
    }),
    createServerTableCol("newcarViewYn", "신차메인노출", {
      width: CW_SMALL_M,
      cellRenderer: NC_DISPLAY_STATE_SWITCH,
    }),
    createServerTableCol("usdcarViewYn", "중고차메인노출", {
      width: CW_SMALL_M,
      cellRenderer: UC_DISPLAY_STATE_SWITCH,
    }),
    // 회원명
    createServerTableCol("custNm", "회원명", {
      width: CW_SMALL_M,
    }),
    // 상품유형
    createServerTableCol("prodPatternNm", "상품유형", {
      width: CW_MEDIUM,
    }),
    // 계약유형
    createServerTableCol("cntrPatternNm", "계약유형", {
      width: CW_SMALL,
    }),
    // 제조사
    createServerTableCol("brandNm", "제조사", {
      width: CW_SMALL,
    }),
    // 모델
    createServerTableCol("repCartypeNm", "모델", {
      width: CW_SMALL,
    }),
    // 세부모델
    createServerTableCol("carClassNm", "세부모델", {
      width: CW_SMALL,
    }),
    // 트림
    createServerTableCol("carGradeNm", "트림", {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // // 트림
    // createServerTableCol("crmGrd", "트림", {
    //   minWidth: CW_MEDIUM,
    //   flex: 1,
    // }),
    // 별점
    createServerTableCol("crmScore", "별점", {
      width: CW_MEDIUM,
    }),
    // 내용
    createServerTableCol("crmCntn", "내용", {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // 카매니저
    createServerTableCol("carmanNm", "카매니저", {
      width: CW_SMALL,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
