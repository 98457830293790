import React from "react";
import Button, { ButtonProps } from "../../general/antd/Button";

interface Props extends ButtonProps {
  label: string;
}

const TableCellButton: React.FC<Props> = (props: Props) => {
  const { label, ...rest } = props;
  return (
      <Button {...rest} >{label}</Button>
  );
};

TableCellButton.defaultProps = {
};
export default TableCellButton;
