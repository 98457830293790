import { FormikValues } from "formik";
import * as Yup from "yup";
import {
  FormElementType,
  FormOption,
} from "../../../../../../components/shared/data-entry/FormBuilder/types";

import errorMessages from "../../../../../../constants/errors";

export const createInitialValues = () => {
  return {
    userId: null,
    siteCd: [],
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    userId: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    siteCd: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(1, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createAddCarManagerParam = (values: FormikValues) => {
  const { userId, siteCd } = values;

  return {
    userId,
    siteCd,
  };
};

export const createFormInfo = (
  cossSiteOptions: Array<FormOption>,
  cossUserOptions?: Array<FormOption>
) => {
  return [
    {
      // 직원
      key: "userId",
      type: FormElementType.Select,
      label: "직원 선택",
      required: true,
      placeholder: "직원을 선택해주세요",
      options: cossUserOptions,
    },
    {
      // 담당지역
      key: "siteCd",
      type: FormElementType.Select,
      label: "담당지역",
      required: true,
      placeholder: "담당지역을 선택해주세요",
      options: cossSiteOptions,
      config: {
        mode: "multiple",
      },
    },
  ];
};
