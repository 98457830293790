import React, { FC, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import { LOGIN } from "../../constants/urls";
import PublicRoute from "../PublicRoute";
import useAuth from "../../../hooks/useAuth";

interface PrivateRouteProps {
  path: string;
  component: typeof React.Component | FC;
  exact: true;
  breadcrumb?: string;
  layout?: typeof React.Component | FC;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (
  props: PrivateRouteProps
) => {
  const { user } = useAuth();
  const { path, component, exact, layout, ...rest } = props;

  if (user) {
    return (
      <PublicRoute
        path={path}
        component={component}
        exact={exact}
        layout={layout}
        {...rest}
      />
    );
  }
  return <Redirect to={LOGIN} />;
};

PrivateRoute.defaultProps = {};
export default PrivateRoute;
