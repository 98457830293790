class CustomerInfoHistory {
  // 고객번호
  public custId = "";

  // 이력일시
  public histDtm = "";

  // 온라인 로그인 ID
  public custOid = "";

  // 비밀번호
  public passwd = "";

  // 성명
  public custNm = "";

  // 휴대폰번호
  public hpNo = "";

  // 성별
  public gndr = "";

  // 생년
  public birthYr = "";

  // (생년)월일
  public birthDt = "";

  // CI값
  public ci = "";

  // DI값
  public di = "";

  // 이메일
  public email = "";

  // 주소
  public addr = "";

  // 상세주소
  public dtladdr = "";

  // 최근 로그인 일시
  public lastLoginDtm = "";

  // 패스워드 수정 일시
  public pwUpdDt = "";

  // 운전면허 번호
  public drvLcnsNo = "";

  // 운전면허 종류
  public drvLcnsKind = "";

  // 면허 유효기간
  public drvLcnsTerm = "";

  // 카매니저ID
  public carmanId = "";

  // 사용유무
  public useYn = "";

  // 최초 소셜가입시 채널코드
  public snsChl = "";

  // COSS 고객 ID
  public cossCustId = "";

  // 비고
  public remark = "";

  // 탈퇴요청일
  public delDtm = "";

  // 탈퇴처리여부
  public delFlag = "";

  // 변경자
  public updUser = "";

  // 변경일시
  public updDtm = "";

  // 등록자
  public regUser = "";

  // 등록일시
  public regDtm = "";
}

export default CustomerInfoHistory;
