import React, { useCallback, useState } from "react";
import { FormikProps, FormikValues } from "formik";
import strings from "../../constants/strings";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import {
  createAddNoticeParam,
  createEditNoticeParam,
  createFormInfo,
  createInitialValues,
  createInitialValuesFromNotice,
  createValidationSchema,
} from "./utils/form-utils";
import {
  renderLoading,
  alertError,
  alertSuccess,
} from "../../../../../utils/render-utils";
import {
  addNotice,
  editNotice,
  fetchNoticeDetail,
} from "../../../../../apis/notices";
import NoticeDetail from "../../../../../models/NoticeDetail";
import { getErrorMessage } from "../../../../../utils/common-utils";

interface Props {
  isOpen: boolean;
  onCancel?: () => void;
  seq?: string;
  onDataChange?: () => void;
}

const NoticeModal: React.FC<Props> = (props: Props) => {
  const { isOpen, onCancel, seq, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [notice, setNotice] = useState<NoticeDetail | null>(null);

  let formik: FormikProps<FormikValues>;
  const [initialValues, setInitialValues] = useState(createInitialValues());

  const onModalOpen = async () => {
    if (seq) {
      // Fetch data
      setDataFetching(true);
      try {
        const notice = await fetchNoticeDetail(seq);
        setInitialValues(createInitialValuesFromNotice(notice) as any);
        setNotice(notice);
      } catch (e) {
        if (e.getMessage) {
          alertError(e.getMessage());
        } else {
          alertError(e.toString());
        }
      } finally {
        setDataFetching(false);
      }
    } else {
      setInitialValues(createInitialValues());
    }
  };

  /**
   * Private Functions
   */

  const requestAddNotice = async (values: FormikValues) => {
    setConfirmLoading(true);

    try {
      await addNotice(createAddNoticeParam(values));
      alertSuccess(strings.FEEDBACK_ADD_NOTICE);
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditNotice = async (values: FormikValues) => {
    if (notice) {
      setConfirmLoading(true);
      try {
        await editNotice(notice.noticeSeq, createEditNoticeParam(values));
        alertSuccess(strings.FEEDBACK_EDIT_NOTICE);
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const getModalTitle = useCallback(() => {
    if (seq) {
      return strings.MODAL_TITLE_NOTICE_DETAIL;
    }
    return strings.MODAL_TITLE_ADD_NOTICE;
  }, [seq]);

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (seq) {
      await requestEditNotice(values);
    } else {
      await requestAddNotice(values);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={isOpen}
      onCancel={onCancel}
      title={getModalTitle()}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={createFormInfo()}
          validationSchema={createValidationSchema()}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

NoticeModal.defaultProps = {
  onCancel: () => {},
  isOpen: false,
  seq: undefined,
};
export default NoticeModal;
