import { createCatalogTagCols } from "../../../utils/table-utils";

export const createTagSetupCols = () => {
  return [
    {
      headerName: "",
      field: "checkItem",
      width: 30,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    ...createCatalogTagCols(),
  ];
};