import React from "react";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const renderLoading = () => {
  const loading = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        minHeight: "300px",
        height: "100%",
      }}
    >
      <Spin indicator={loading} />
    </div>
  );
};

export const alertError = (content: string) => {
  message.error(content);
};

export const alertSuccess = (content: string) => {
  message.success(content);
};
