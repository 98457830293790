import React from "react";
import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";

export interface ButtonProps extends AntButtonProps {}

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { children, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return <AntButton {...rest}>{children}</AntButton>;
};

Button.defaultProps = {
  type: "primary",
};
export default Button;
