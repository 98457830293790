import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, UploadProps as AntUploadProps } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { uploadFile } from "../../../../apis/upload";
import { alertError } from "../../../../utils/render-utils";
import { getErrorMessage } from "../../../../utils/common-utils";
import UploadCode from "../../../../models/UploadCode";
import Modal from "../../feedback/antd/Modal";

export interface ImageInfo {
  fileId?: string;
  imageUrl: string;
}

interface ImageUploadProps extends AntUploadProps {
  onImageChange?: (imageInfos: Array<ImageInfo>) => void;
  uploadCodeInfo: UploadCode;
  imageInfos?: Array<ImageInfo>;
  maxUploadCount?: 1;
}

const ImageUpload: React.FC<ImageUploadProps> = (props: ImageUploadProps) => {
  const {
    onImageChange,
    uploadCodeInfo,
    imageInfos,
    maxUploadCount = 1,
  } = props;
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>();

  const getInitialImageFile = (): Array<any> => {
    if (imageInfos) {
      return imageInfos.map(({ fileId, imageUrl }) => ({
        uid: fileId || imageUrl,
        url: imageUrl,
        status: "done",
        name: imageUrl,
        // size: 0,
        type: "image/*",
        response: {
          uploadResult: {
            fileId,
            imageUrl,
          },
        },
      }));
    }

    return [];
  };

  const [fileList, setFileList] = useState<Array<UploadFile>>(
    getInitialImageFile()
  );

  /**
   * Private Functions
   */

  const uploadButtonVisible = () => {
    // 등록된 파일이 없을 경우 업로드 버튼 활성화
    if (!fileList || fileList.length === 0) return true;

    // 최대 업로드 개수 제한
    return fileList.length < maxUploadCount;
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = (await getBase64(file.originFileObj)) as string;
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  /**
   * Event Actions
   */

  const handleChange = async (info: UploadChangeParam) => {
    const updated = info.fileList.filter(({ status, response }) => {
      return status !== "error";
    });
    setFileList(updated);
    if (onImageChange) {
      onImageChange(
        updated
          .filter((file) => {
            return !!file.response;
          })
          .map(({ response }) => {
            const { uploadResult } = response;
            const { fileUrl, fileId } = uploadResult;
            return {
              imageUrl: fileUrl,
              fileId,
            };
          })
      );
    }
  };

  const handleUpload = async (options: any) => {
    // TODO Progress
    // const { onProgress } = options;
    const { onSuccess, onError, file } = options;

    const formData = new FormData();
    formData.append("uploadfile", file);
    try {
      const uploadResult = await uploadFile(
        uploadCodeInfo.jobClsCd,
        uploadCodeInfo.idtfClsCd,
        formData
      );
      onSuccess({
        uploadResult,
      });
    } catch (e) {
      alertError(getErrorMessage(e));
      onError("Error");
    }
  };

  /**
   * Render Helpers
   */

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>이미지 업로드</div>
    </div>
  );

  return (
    <>
      <Upload
        fileList={fileList}
        listType="picture-card"
        onChange={handleChange}
        customRequest={handleUpload}
        onPreview={handlePreview}
      >
        {uploadButtonVisible() && uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

ImageUpload.defaultProps = {};
export default ImageUpload;
