import { FormikProps, FormikValues } from "formik";
import { CSSObject } from "styled-components";
import UploadCode from "../../../../../models/UploadCode";

export enum FormElementType {
  Input,
  InputNumber,
  TextArea,
  Radio,
  Select,
  Checkbox,
  CheckboxGroup,
  DateRangePicker,
  DatePicker,
  Custom,
  ImageUpload,
}

export interface FormOption {
  label: string;
  value: any;
}

export interface FormInfo {
  key: string;
  type: FormElementType;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  render?: (renderProps: FormikProps<FormikValues>) => any;
  onChange?: (value: any) => void;
  options?: Array<FormOption>;
  span?: number;
  uploadCodeInfo?: UploadCode;
  config?: any;
  style?: CSSObject;
}
