import React, { useRef, useState } from "react";
import {
  GridApi,
  LoadSuccessParams,
} from "ag-grid-community";
import moment from "moment";
import {
  createNewCarCatalogCols,
} from "./utils/table-utils";
import {
  fetchAUCContract,
  updateUcCooperCntrList,
} from "../../../apis/auc-catalogs";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import {
  getPageIndex,
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import commonStrings from "../../../constants/strings";
import { get1MonthDateRange } from "../../../utils/date-utils";
import {
  COMP_CNTR_STS_OPTIONS,
  AUC_COM_CD,
} from "../../../constants/enums";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import {
  createSwitchRenderer,
} from "./utils/table-utils";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import { ICellRendererParams } from "ag-grid-community";
import CustomerModal from "../../../pages/customer/CustomerManager/pages/CustomerModal";
import useAuth from "@hooks/useAuth";
import { setConnectLogs } from "@apis/logs";

const AucContractManager: React.FC = () => {
  const gridApiRef = useRef<GridApi>();
  const cols = createNewCarCatalogCols();
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>();
  const { user } = useAuth();
  const [logInfo, setLogInfo] = useState<string>("");

  const getInitialDateRange = () => {
    return get1MonthDateRange();
  };

  const filterInitialValues = {
    stDt: getInitialDateRange(),
    compCd: "",
    carNo: "",
    compNm: "",
    cntrReqSts: "",
  };

  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "등록일",
    },
    {
      key: "carNo",
      type: FilterElementType.Input,
      label: "차량번호",
      placeholder: commonStrings.HINT_CAR_NO,
    },
    {
      key: "compCd",
      type: FilterElementType.Select,
      options: AUC_COM_CD,
      label: "제휴사",
    },
    {
      key: "cntrReqSts",
      type: FilterElementType.Select,
      label: "계약상태",
      options: COMP_CNTR_STS_OPTIONS,
    },     
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);

    const {
      stDt,
      carNo,
      compCd,
      cntrReqSts,
    } = appliedFilterValues;
    const regStDt = moment(stDt[0]).format("YYYYMMDD");
    const regEndDt = moment(stDt[1]).format("YYYYMMDD");

    try {
      const { items, count } = await fetchAUCContract({
        pageSize: paginationPageSize,
        pageIndex: getPageIndex(startRow, paginationPageSize),
        regStDt,
        regEndDt,
        carNo,
        compCd,
        cntrReqSts,
      });
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
      setConnectLogs({
        userId: user.userId,
        userIp: user.clientIp,
        methodNm: "/admin/auc-cntr-list",
        menuName: "견적/계약 - 계약관리(제휴중고차)",
        actionFlag: "SEARCH",
        reqCntn: appliedFilters
      });
    } catch (e) {      
      setTotalCount(0);
      success({
        rowData: [],
        rowCount: 0,
      });
    }
  };

  /**
   * Event Actions
   */
  const handleStateSwitchChange = (    
    cntrId: string,
    cntrReqSts: string,
  ) => {
    try {
      if(cntrReqSts === "A61001") {
        showConfirm(
          "제휴중고차 계약반려",
          "제휴중고차 계약요청을 반려하시겠습니까?\n반려하실 경우, 요청 전달된 제휴사와 공유 부탁 드립니다.",
          async () => {
            await updateUcCooperCntrList(cntrId, "A61002");
            alertSuccess("반려 되었습니다");
            await refreshServerSideTable(gridApiRef.current);
          }        
        );
      } else {
        alertSuccess("계약상태가 요청상태가 아닙니다.");
      }
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { custId } = data;
    setSelectedDataId(custId);
    setModalVisible(true);
  };

  /**
   * Render Helpers
   */

  const createTableActions = () => {
    return [];
  };

  return (
    <>
      <PaginationTableLayout
        tableHeaderTitle=""
        apiRef={(api) => {
          gridApiRef.current = api;
        }}
        headerTitle="계약관리(제휴중고차)" 
        headerDescription="제휴중고 렌터카 계약 정보를 관리 합니다."
        totalCount={totalCount}
        hideFilterAction
        hideSelectionCountText 
        rightActions={createTableActions()}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        onDataLoad={onDataLoad}
        frameworkComponents={{          
          ...createSwitchRenderer(handleStateSwitchChange, "cntrReqSts"),
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
        }}
        methodName="/admin/auc-cntr-list"
        logInfo={logInfo}
      />
      <CustomerModal
        id={selectedDataId}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};

AucContractManager.defaultProps = {};
export default AucContractManager;
