import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_2X_LARGE,
  CW_DATE_RANGE,
  CW_LARGE,
  CW_LONG_TEXT,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
  CW_X_LARGE,
} from "../../../../utils/table-utils";
import Switch from "../../../../components/shared/data-entry/antd/Switch";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { formatNumber } from "../../../../utils/common-utils";
import { getMcProdType } from "../../../../constants/enums";

export const MC_DISPLAY_STATE_SWITCH = "displayStateSwitchRenderer";
export const MC_RSV_STATE_SWITCH = "mcRsvStateSwitchRenderer";
export const IMG_RENDERER = "imgRenderer";

const renderSwitch = (
  onChange: (checked: boolean, prodId: string, carId: string, useYn: string) => void,
  key = "ucMmViewYn"
) => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    const { prodId, carId, ucMmUseYn } = data;
  
    return (
      <Switch
        checked={data[key] === "Y"}
        onClick={() => {
          data[key] === "Y"?
          onChange(false, prodId, carId, ucMmUseYn)
          : onChange(true, prodId, carId, ucMmUseYn)
        }}
      />
    );
  };
};

export const createSwitchRenderer = (
  onChange: (checked: boolean, prodId: string, carId: string, useYn: string) => void,
  key = "ucMmViewYn"
) => ({
  [MC_DISPLAY_STATE_SWITCH]: renderSwitch(onChange, key),
});

export const createSwitchRendererRsvc = (
  onChange: (checked: boolean, prodId: string, carId: string, useYn: string) => void,
  key = "rsvPsblYn"
) => ({
  [MC_RSV_STATE_SWITCH]: renderSwitch(onChange, key),
});


export const createNewCarCatalogCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", "등록일", FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // NICE 연동일
    createTableCol("niceConYn", "NICE 연동여부", FilterType.Text, {
      width: CW_LARGE,
    }),
    // 상품유형
    createTableCol("ncarClsFlag", "상품유형", FilterType.Text, {
      width: CW_SMALL_M,
      valueGetter: ({ data }) => {
        const { ncarClsFlag } = data;

        return getMcProdType(ncarClsFlag) || "-";
      },
    }),     
    // 월렌트 등록여부 
    createTableCol("ucMmUseYn", "월렌트 등록여부", FilterType.Text, {
      width: CW_LARGE,
    }),    
    // 월렌트 노출상태 
    createTableCol("ucMmViewYn", "월렌트 노출여부", FilterType.Text, {
      cellRenderer: MC_DISPLAY_STATE_SWITCH,
      width: CW_LARGE,
    }),
    // 월렌트 예약 가능 상태 
    createTableCol("rsvPsblYn", "예약 가능여부", FilterType.Text, {
      cellRenderer: MC_RSV_STATE_SWITCH,
      width: CW_LARGE,
    }),
    // cossId
    createTableCol("cossId", "COSS_ID", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 차량번호
    createTableCol("carNo", "차량번호", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 제조사
    createTableCol("brandNm", "제조사", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 모델
    createTableCol("repCarClassNm", "모델", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 세부 모델
    createTableCol("carClassNm", "세부모델", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 트림
    createTableCol("carGradeNm", "트림", FilterType.Text, {
      minWidth: CW_LONG_TEXT,
      flex: 1,
    }),
    // 연식
    createTableCol("yearType", "연식", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 주행거리
    createTableCol("lastDtc", "주행거리", FilterType.Text, {
      width: CW_SMALL_M,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.lastDtc)}km`;
      },
    }),
    // 연료
    createTableCol("repFuelNm", "연료", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 변속
    createTableCol("istdTrans", "변속", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 차량정보
    // createTableCol("carNmInfo", "차량정보", FilterType.Text, {
    //   width: CW_SMALL,
    // }),
    // 정비이력
    createTableCol("fixCont", "정비이력", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 바로인수가
    // createTableCol("tkvAmt", "바로인수가", FilterType.Text, {
    //   width: CW_SMALL_M,
    //   cellStyle: {
    //     textAlign: "right",
    //   },
    //   cellRenderer: ({ data }) => {
    //     return `${formatNumber(data.tkvAmt)}`;
    //   },
    // }),
    // 월 렌탈료
    createTableCol("cntr1mmRentAmtV", "1개월 월렌탈료", FilterType.Text, {
      width: CW_SMALL_M,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.cntr1mmRentAmtV)}`;
      },
    }),
    createTableCol("cntr3mmRentAmtV", "3개월 월렌탈료", FilterType.Text, {
      width: CW_SMALL_M,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.cntr3mmRentAmtV)}`;
      },
    }),
    createTableCol("cntr6mmRentAmtV", "6개월 월렌탈료", FilterType.Text, {
      width: CW_SMALL_M,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.cntr6mmRentAmtV)}`;
      },
    }),
    createTableCol("cntr9mmRentAmtV", "9개월 월렌탈료", FilterType.Text, {
      width: CW_SMALL_M,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.cntr9mmRentAmtV)}`;
      },
    }),
    createTableCol("cntr12mmRentAmtV", "12개월 월렌탈료", FilterType.Text, {
      width: CW_SMALL_M,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.cntr12mmRentAmtV)}`;
      },
    }),
    //  // 월렌트 상품리스트 추가 231017
    // createTableCol("mainCarImg", "메인이미지", FilterType.Text, {
    //   minWidth: CW_SMALL_M,
    //   cellRenderer: IMG_RENDERER
    // }),
    createTableCol("mainCarImg", "메인이미지URL", FilterType.Text, {
      minWidth: CW_X_LARGE ,
      wrapText:true,
      autoHeight:true,
      flex: 5,
    }),
    // createTableCol("xtnlClrImgUrl", "상세이미지", FilterType.Text, {
    //   minWidth: CW_SMALL_M,
    //   cellRenderer: IMG_RENDERER
    // }),
    createTableCol("xtnlClrImgUrl", "상세이미지URL", FilterType.Text, {
      minWidth: CW_X_LARGE ,
      wrapText:true,
      autoHeight:true,
      flex: 5,
    }),
    createTableCol("xtnlCarColorId", "COSS 외장색상ID", FilterType.Text, {
      width: CW_LARGE,
    }),
    createTableCol("cossXtnlCarColorNm", "COSS 외장색상명", FilterType.Text, {
      width: CW_LARGE,
    }),
    createTableCol("cossXtnlColorUseYn", "COSS 외장색상사용여부", FilterType.Text, {
      width: CW_LARGE,
    }),
    createTableCol("xtnlCarColorNm", "NICE 외장색상명", FilterType.Text, {
      width: CW_LARGE,
    }),
    // createTableCol("xtnlColorImgUrl", "NICE 외장색상칩", FilterType.Text, {
    //   minWidth: CW_SMALL_M,
    //   cellRenderer: IMG_RENDERER
    // }),
    createTableCol("xtnlColorImgUrl", "NICE 외장색상칩URL", FilterType.Text, {
      minWidth: CW_X_LARGE ,
      wrapText:true,
      autoHeight:true,
      flex: 5,
    }),
    createTableCol("innrCarColorId", "COSS 내장색상ID", FilterType.Text, {
      width: CW_LARGE,
    }),
    createTableCol("cossInnrCarColorNm", "COSS 내장색상명", FilterType.Text, {
      width: CW_LARGE,
    }),
    createTableCol("cossInnrColorUseYn", "COSS 내장색상사용여부", FilterType.Text, {
      width: CW_LARGE,
    }),
    createTableCol("innrCarColorNm", "NICE 내장색상명", FilterType.Text, {
      width: CW_LARGE,
    }),
    // createTableCol("innrColorImgUrl", "NICE 내장색상칩", FilterType.Text, {
    //   minWidth: CW_SMALL_M,
    //   cellRenderer: IMG_RENDERER
    // }),
    createTableCol("innrColorImgUrl", "NICE 내장색상칩URL", FilterType.Text, {
      minWidth: CW_X_LARGE ,
      wrapText:true,
      autoHeight:true,
      flex: 5,
    }),

    // 최소인수가
    // createTableCol("avgMinTkvAmt", "최소인수가", FilterType.Text, {
    //   width: CW_SMALL_M,
    //   cellStyle: {
    //     textAlign: "right",
    //   },
    //   cellRenderer: ({ data }) => {
    //     return `${formatNumber(data.avgMinTkvAmt)}`;
    //   },
    // }),
    // 최대인수가
    // createTableCol("avgMaxTkvAmt", "최대인수가", FilterType.Text, {
    //   width: CW_SMALL_M,
    //   cellStyle: {
    //     textAlign: "right",
    //   },
    //   cellRenderer: ({ data }) => {
    //     return `${formatNumber(data.avgMaxTkvAmt)}`;
    //   },
    // }),
    // 성능평가
    // createTableCol("carCheck", "성능평가", FilterType.Text, {
    //   width: CW_SMALL,
    // }),
    // 사고이력
    // createTableCol("accdHist", "사고이력", FilterType.Text, {
    //   width: CW_SMALL,
    // }),
    // 블록오디세이
    // createTableCol("blckOdsy", "블록체인", FilterType.Text, {
    //   width: CW_SMALL,
    // }),
    // 상세보기
    {
      ...createActionCol(80),
    },
  ];
};
