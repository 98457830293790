/* eslint-disable */
import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  ConnectDropTarget,
  ConnectDragSource,
  DropTargetMonitor,
  DragSourceMonitor,
  DragSource,
  DropTarget,
  DropTargetConnector,
  DragSourceConnector,
  XYCoord,
} from "react-dnd";

import { ListItemWrapper, ColumnWrapper } from "./styles";
import { ExhibitionCatalog } from "../../../../../../../../../apis/ex/types";

export interface CatalogListItemObject {
  id: string;
  index: number;
}

interface CatalogListItemInstance {
  getNode(): HTMLDivElement | null;
}

interface CatalogListItemProps {
  id: string;
  catalog: ExhibitionCatalog;
  index: number;
  style?: CSSProperties;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
}

const ListItemType = "card";
export const CW_NO = 50;
export const CW_REG_DATE = 80;
export const CW_IMG = 140;
export const CW_PROD_TYPE = 80;
export const CW_BRAND = 80;
export const CW_PROD_NM = 120;
export const CW_MODEL = 120;

const CatalogListItem = forwardRef<HTMLDivElement, CatalogListItemProps>(
  function Card(
    {
      catalog,
      index,
      moveCard,
      isDragging,
      connectDragSource,
      connectDropTarget,
      ...rest
    },
    ref
  ) {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);

    const opacity = isDragging ? 0 : 1;
    useImperativeHandle<any, CatalogListItemInstance>(ref, () => ({
      getNode: () => elementRef.current,
    }));

    return (
      <ListItemWrapper
        style={{
          // backgroundColor: isDragging ? "grey" : "white",
          opacity,
        }}
        ref={elementRef}
        {...rest}
      >
        {/* No */}
        <ColumnWrapper width={CW_NO}>{index + 1}</ColumnWrapper>
        {/* 등록일 */}
        {/*<ColumnWrapper width={CW_REG_DATE}>*/}
        {/*  {formatDateString(catalog.regDtm)}*/}
        {/*</ColumnWrapper>*/}
        {/* 이미지 */}
        <ColumnWrapper width={CW_IMG}>
          <img src={catalog.repCarImg} alt="차량이미지" height={70} />
        </ColumnWrapper>
        {/* 상품타입 */}
        <ColumnWrapper width={CW_PROD_TYPE}>
          {catalog.ncarClsFlagNm}
        </ColumnWrapper>
        {/* 제조사 */}
        <ColumnWrapper width={CW_BRAND}>{catalog.repMakerNm}</ColumnWrapper>
        {/* 상품명 */}
        <ColumnWrapper width={CW_PROD_NM}>{catalog.prodNm}</ColumnWrapper>
        {/* 모델 */}
        <ColumnWrapper width={CW_MODEL}>{catalog.repCarTypeNm}</ColumnWrapper>
        {/* 세부모델 */}
        <ColumnWrapper>{catalog.carGradeNm}</ColumnWrapper>
      </ListItemWrapper>
    );
  }
);

export default DropTarget(
  ListItemType,
  {
    hover(
      props: CatalogListItemProps,
      monitor: DropTargetMonitor,
      component: CatalogListItemInstance
    ) {
      if (!component) {
        return null;
      }

      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem<CatalogListItemObject>().index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = node.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      props.moveCard(dragIndex, hoverIndex);
      monitor.getItem<CatalogListItemObject>().index = hoverIndex;
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    ListItemType,
    {
      beginDrag: (props: CatalogListItemProps) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(CatalogListItem)
);

CatalogListItem.defaultProps = {};
