import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";

import FormBuilder, {
  defaultFormItemLayout,
} from "../../../../../components/shared/data-entry/FormBuilder";
import { alertError, alertSuccess } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  FormElementType,
  FormInfo,
} from "../../../../../components/shared/data-entry/FormBuilder/types";
import { updateOptionInfo } from "../../../../../apis/coss-car-infos";
import errorMessages from "../../../../../constants/errors";
import { CossOptionDetail } from "../../../../../models/CossCarInfo/CossCarInfoDetail";
import FileUpload, {
  FileInfo,
} from "../../../../../components/shared/data-entry/FileUpload";
import { UPLOAD_CODES } from "../../../../../constants/enums";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";

interface Props extends ModalProps {
  optionInfo?: CossOptionDetail;
}

const OptionInfoFormModal: React.FC<Props> = (props: Props) => {
  const formInitialValues = {
    optDescUrl: "",
    optCustomNm: "",
    files: [],
  };

  const validationSchema = Yup.object().shape({
    optDescUrl: Yup.string().url(errorMessages.INVALID_URL),
  });

  const formInfo: Array<FormInfo> = [
    {
      key: "optCustomNm",
      label: "커스텀옵션명",
      placeholder: "미입력시 기본 옵션명이 노출됩니다",
      type: FormElementType.Input,
    },
    {
      key: "optDescUrl",
      label: "동영상링크",
      placeholder: "동영상 링크를 입력해주세요",
      type: FormElementType.Input,
    },
    {
      key: "files",
      type: FormElementType.Custom,
      render: (renderProps) => {
        const { values, setFieldValue } = renderProps;
        const { files } = values;
        return (
          <FormItem label="PDF첨부" {...defaultFormItemLayout}>
            <FileUpload
              fileList={files}
              uploadCodeInfo={UPLOAD_CODES.CAR_OPTION_PDF}
              onFileChange={(fileList) => {
                setFieldValue("files", fileList);
              }}
              onUploadStateChange={(loading) => {
                setConfirmLoading(loading);
              }}
            />
          </FormItem>
        );
      },
    },
  ];

  const { visible, onCancel, onDataChange, optionInfo } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);

  let formik: FormikProps<FormikValues>;

  const onModalOpen = () => {
    if (optionInfo) {
      setInitialValues({
        ...formInitialValues,

        optDescUrl: optionInfo.cossOptDescUrl || "",
        optCustomNm: optionInfo.cossOptCustomNm || "",
      });
    } else {
      setInitialValues(formInitialValues);
    }
  };

  /**
   * Private Functions
   */

  const requestUpdateOptionInfo = async (values: FormikValues) => {
    if (optionInfo) {
      setConfirmLoading(true);

      try {
        const { optDescUrl, optCustomNm, files } = values;
        let optionPdfParam;
        console.log(values)
        if (files && files.length > 0) {
          const [file] = files as FileInfo[];
          optionPdfParam = file.fileId;
        }
        await updateOptionInfo(
          optionInfo.cossOptId,
          optDescUrl,
          undefined,
          optCustomNm,
          optionPdfParam
        );
        alertSuccess("옵션정보가 변경되었습니다");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    await requestUpdateOptionInfo(values);
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      title={optionInfo && optionInfo.cossOptNm}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      <FormBuilder
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        forms={formInfo}
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
      />
    </Modal>
  );
};

OptionInfoFormModal.defaultProps = {};
export default OptionInfoFormModal;
