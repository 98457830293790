import React, { useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";
import { fetchExhibitions, editExhibition } from "../../../apis/ex";
import {
  ExhibitionEditParams,
  ExhibitionMaster,
  ExhibitionResponse,
} from "../../../apis/ex/types";
import TableLayout from "../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { createExhibitionMasterCols } from "./utils/table-utils";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import commonStrings from "../../../constants/strings";
import { USE_YN_RENDERER } from "../BannerManager/pages/BannerDetailModal/utils/table-utils";
import { renderSwitchCol } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import AddExhibitionModal from "./pages/AddExhibitionModal";
import ExhibitionDetailModal from "./pages/ExhibitionDetailModal";
import ShortsExhibitionDetailModal from "./pages/ShortsExhibitionDetailModal";
import TimeDealExhibitionDetailModal from "./pages/TimeDealExhibitionDetailModal";
import {
  NC_EXHIBITION_ELEC_CAR,
  NC_EXHIBITION_SHORTS,
  NC_EXHIBITION_TIMEDEAL,
} from "../../../constants/enums";
import ElecCarExhibitionDetailModal from "./pages/ElecCarExhibitionDetailModal";

enum ModalState {
  NONE,
  ADD,
  NORMAL,
  SHORTS,
  TIMEDEAL,
  ELECT_CAR,
}

const ExhibitionManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();

  // 기획전 리스트 관련 State
  const [exhibitions, setExhibitions] = useState<
    ExhibitionResponse<ExhibitionMaster>
  >();
  const [exFetchProgress, setExFetchProgress] = useState<boolean>(false);

  // 기획전 상세 관련 State
  const [selectedExhibition, setSelectedExhibition] = useState<
    ExhibitionMaster
  >();
  const [modalState, setModalState] = useState<ModalState>(ModalState.NONE);

  useEffect(() => {
    (async function fetchData() {
      await requestFetchExhibitionMasters();
    })();
  }, []);

  const requestFetchExhibitionMasters = async () => {
    setExFetchProgress(true);
    try {
      const { list, listCnt } = await fetchExhibitions();
      setExhibitions({
        listCnt,
        list,
      });
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setExFetchProgress(false);
    }
  };

  const requestEditExhibitionUseYn = async (params: ExhibitionEditParams) => {
    try {
      await editExhibition(params);
      alertSuccess("기획전 사용상태가 변경되었습니다.");
      await requestFetchExhibitionMasters();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const handleDetailClick = ({ data }: { data: ExhibitionMaster }) => {
    setSelectedExhibition(data);

    switch (data.exhGbnCd) {
      case NC_EXHIBITION_TIMEDEAL:
        setModalState(ModalState.TIMEDEAL);
        break;
      case NC_EXHIBITION_SHORTS:
        setModalState(ModalState.SHORTS);
        break;
      case NC_EXHIBITION_ELEC_CAR:
        setModalState(ModalState.ELECT_CAR);
        break;
      default:
        setModalState(ModalState.NORMAL);
        break;
    }
  };

  const handleModalClose = () => {
    setModalState(ModalState.NONE);
  };

  const handleUseYnChange = (checked: boolean, data: ExhibitionMaster) => {
    showConfirm(
      checked ? commonStrings.CONFIRM_USE_ON : commonStrings.CONFIRM_USE_OFF,
      checked
        ? commonStrings.CONFIRM_CONTENT_USE_ON
        : commonStrings.CONFIRM_CONTENT_USE_OFF,
      async () => {
        const params = {
          exhMstSeq: data.exhMstSeq,
          useYn: checked ? "Y" : "N",
        };
        await requestEditExhibitionUseYn(params);
      }
    );
  };

  const renderTableActions = () => {
    return [
      {
        title: "기획전 등록",
        onClick: () => {
          setModalState(ModalState.ADD);
        },
      },
    ];
  };

  const renderDetailModal = () => {
    switch (modalState) {
      case ModalState.ADD:
        return (
          <AddExhibitionModal
            visible={modalState === ModalState.ADD}
            onCancel={handleModalClose}
            onDataChange={async () => {
              await requestFetchExhibitionMasters();
            }}
          />
        );
      case ModalState.NORMAL:
        return (
          <ExhibitionDetailModal
            visible={modalState === ModalState.NORMAL}
            onCancel={handleModalClose}
            exhibitionMaster={selectedExhibition}
            onDataChange={async () => {
              await requestFetchExhibitionMasters();
            }}
          />
        );
      case ModalState.SHORTS:
        return (
          <ShortsExhibitionDetailModal
            visible={modalState === ModalState.SHORTS}
            onCancel={handleModalClose}
            exhibitionMaster={selectedExhibition}
            onDataChange={async () => {
              await requestFetchExhibitionMasters();
            }}
          />
        );
      case ModalState.TIMEDEAL:
        return (
          <TimeDealExhibitionDetailModal
            visible={modalState === ModalState.TIMEDEAL}
            exhibitionMaster={selectedExhibition}
            onCancel={handleModalClose}
            onDataChange={async () => {
              await requestFetchExhibitionMasters();
            }}
          />
        );
      case ModalState.ELECT_CAR:
        return (
          <ElecCarExhibitionDetailModal
            visible={modalState === ModalState.ELECT_CAR}
            onCancel={handleModalClose}
            exhibitionMaster={selectedExhibition}
            onDataChange={async () => {
              await requestFetchExhibitionMasters();
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ContentLayout filterable={false} headerTitle="기획전 관리(신차장기)">
        {exFetchProgress && renderLoading()}
        {exhibitions && (
          <TableLayout
            gridApi={gridApi}
            title="기획전 리스트"
            totalCount={exhibitions.listCnt}
            rightActions={renderTableActions()}
            methodName="/admin/ex"
          >
            <ClientRowTable
              apiRef={(api) => {
                setGridApi(api);
              }}
              rowData={exhibitions.list}
              cols={createExhibitionMasterCols()}
              frameworkComponents={{
                [USE_YN_RENDERER]: renderSwitchCol(
                  "exhUseYn",
                  handleUseYnChange
                ),
                ...createActionCellRenderers(undefined, handleDetailClick, {
                  hideDeleteButton: true,
                }),
              }}
            />
          </TableLayout>
        )}
      </ContentLayout>
      {renderDetailModal()}
    </>
  );
};

ExhibitionManager.defaultProps = {};
export default ExhibitionManager;
