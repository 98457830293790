import LocalizedStrings from "react-localization";

const errorMessages = new LocalizedStrings({
  ko: {
    REQUIRED_FIELD: "필수 입력 필드입니다",
    INVALID_URL: "올바른 URL을 입력해주세요",
    MAX_LENGTH_10: "최대 10자리 입력 가능한 필드입니다.",

    MSS_FIELD: "#없음",
  },
});

export default errorMessages;
