import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_HEADER_CUSTOMER_MANAGER: "회원 관리",
    TABLE_HEADER_CUSTOMER_LIST: "회원 리스트",

    COL_REG_DATE: "등록일",
    COL_CUSTOMER_KIND: "회원구분",
    COL_IS_PASS_AUTHED: "본인인증여부",
    COL_NAME: "회원명",
    COL_HP_NO: "휴대폰번호",
    COL_POINT: "보유포인트",
    COL_BIZ_KIND: "고객유형",
    COL_CONTRACT_COUNT: "유지계약건수",
    COL_CAR_MANAGER: "담당 카매니저",
    COL_IS_SK_INVITATION_SIGNED: "추천프로그램 가입",
    COL_MARKETING_AGREEMENT : "마케팅활용동의",
    COL_OVERALL_MARKETING_AGREEMENT : "통합마케팅활용동의",
    COL_SMS_MARKETING_AGREEMENT: "SMS 수신동의",
    COL_TM_MARKETING_AGREEMENT: "TM 수신동의",
    COL_USERNOTE: "비고",
    COL_BIZ_NAME: "사업자명",
    COL_BIZ_AUTH_STATE: "개인사업자 인증상태",
    COL_AUTH_DATE: "본인인증일",

    MODAL_TITLE_CUSTOMER_DETAIL: "회원 상세",

    FILTER_LABEL_REGISTERED_AT: "가입일",
    FILTER_LABEL_HP_NO: "휴대폰번호",
    FILTER_HINT_HP_NO: "휴대폰번호 입력",
    FILTER_LABEL_CAR_MANAGER: "담당 카매니저",
    FILTER_HINT_CAR_MANAGER: "카매니저명 입력",
    FILTER_LABEL_CUST_NM: "회원명",
    FILTER_HINT_CUST_NM: "회원명 입력",
  },
});
