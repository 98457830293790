import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_HEADER_NOTICE_MANAGER: "공지사항",
    TITLE_HEADER_DESCRIPTION: "공지사항 Description",
    TABLE_HEADER_NOTICE_LIST: "공지사항 리스트",

    // 테이블 컬럼
    COL_NOTICE_NO: "게시번호",
    COL_NOTICE_ORDER_NO: "게시순번",
    COL_REGISTERED_AT: "등록일",
    COL_NOTICE_KIND: "공지유형",
    COL_NOTICE_CATEGORY: "공지구분",
    COL_TITLE: "공지제목",
    COL_NOTICE_START_DATE: "팝업 시작일시",
    COL_NOTICE_END_DATE: "팝업 종료일시",
    COL_NOTICE_WRITER: "작성자",
    COL_NOTICE_USE_YN: "사용유무",

    // Form
    LABEL_IS_FIXED: "최상단 고정",
    LABEL_IS_MAIN: "메인 노출여부",
    LABEL_NOTICE_KIND: "공지유형",
    HINT_NOTICE_KIND: "공지유형을 선택해주세요",
    LABEL_NOTICE_CATEGORY: "공지구분",
    HINT_NOTICE_CATEGORY: "공지구분을 선택해주세요",
    LABEL_NOTICE_TITLE: "제목",
    HINT_NOTICE_TITLE: "제목을 입력해주세요",
    LABEL_NOTICE_CONTENT: "내용",
    HINT_NOTICE_CONTENT: "내용을 입력해주세요",
    LABEL_NOTICE_FILE_UPLOAD: "파일첨부",

    FEEDBACK_ADD_NOTICE: "공지사항이 등록되었습니다",

    FEEDBACK_EDIT_NOTICE: "공지사항 정보가 변경되었습니다",

    TITLE_ACTION_ADD: "공지사항 등록",
    TITLE_ACTION_DELETE: "선택 삭제",

    CONFIRM_TITLE_DELETE_NOTICE: "공지사항 삭제",
    CONFIRM_MESSAGE_DELETE_NOTICE: "공지사항을 삭제하시겠습니까?",

    MODAL_TITLE_ADD_NOTICE: "공지사항 등록",
    MODAL_DESCRIPTION_ADD_NOTICE:
      "공지사항은 필수사항 입력 후 미리보기를 통해 확인하실 수 있습니다. 최상단 고정은 게시판 상단에 고정되는 게시물을 의미합니다.",
    MODAL_TITLE_NOTICE_DETAIL: "공지사항 수정",
  },
});
