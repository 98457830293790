import styled from "styled-components";
import LogoImage from "../../../../assets/svgs/logo.svg";

export const LogoBox = styled.img.attrs({
  src: LogoImage,
  width: 184,
  height: 60,
})`
  background: white;
`;
