import React from "react";
import { RouteComponentProps, useLocation } from "react-router";
import styled from "styled-components";
import errorPNG from "./assets/error.png";

interface ErrorPageProps extends RouteComponentProps {
  code?: "400" | "401" | "403" | "404" | "405" | "500" | "503";
  detail?: string;
}
const ErrorPage: React.FC<ErrorPageProps> = ({
  match,
  code = "404",
  detail = "페이지를 찾을 수 없습니다. 다시 시도해주세요.",
}: ErrorPageProps) => {
  // const { url } = match;
  return (
    <Container>
      <figure>
        <img src={errorPNG} alt="에러 이미지" />
      </figure>
      <h1>{code}</h1>
      <p>{detail}</p>
    </Container>
  );
};

ErrorPage.defaultProps = {};
export default ErrorPage;

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > figure {
    margin-bottom: 32px;
    width: 180px;
    object-fit: contain;

    img {
      width: 100%;
      height: 100%;
    }
  }

  > h1 {
    font-size: 48px;
    margin-bottom: 16px;
  }

  > p {
    font-size: 14px;
    color: #9698a8;
  }
`;
