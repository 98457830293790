class Estimation {
  // 견적번호
  public estReqNo = "";

  // 견적버전
  public estVer = "";

  // 견적상세순번
  public estDtlSeq = "";

  // 견적제목
  public estTitle = "";

  // MODE 고객 ID
  public custId = "";

  // COSS 고객 ID
  public cossCustId = "";

  // COSS 고객 명
  public cossCustNm = "";

  // 고객구분코드
  public custClsCd = "";

  // 고객구분코드명
  public custClsNm = "";

  // 상품ID
  public prodId = "";

  // 견적구분코드
  public shpeClsCd = "";

  // 견적구분명
  public shpeClsNm = "";

  // 견적요청구분코드
  public newAddClsCd = "";

  // 견적요청구분명
  public newAddClsNm = "";

  // 견적상태코드
  public estStsCd = "";

  // 견적상태명
  public estStsNm = "";

  // 견적일자
  public estDt = "";

  // 계약기간
  public cntrTermMm = "";

  // 견적유효기간
  public cntrAvailTermMm = "";

  // 담보조건코드
  public pldgCondCd = "";

  // 담보조건명
  public pldgCondNm = "";

  // 운전자범위 코드
  public drvSoe = "";

  // 운전자범위 코드 명
  public drvSoeNm = "";

  // 약정주행거리 코드
  public prmsDtcClsCd = "";

  // 약정주행거리 코드 명
  public prmsDtcClsNm = "";

  // 대표 제조사명
  public repMakerNm = "";

  // 대표 차명
  public repCartypeNm = "";

  // 대표 차량 외관 이미지
  public repCarImg = "";

  // 신차장기 판매구분
  public ncarClsFlag = "";

  public ncarClsFlagNm = "";

  // 등급(트림)
  public carGradeNm = "";

  // 차종명
  public carClassNm = "";

  // 월렌트료(부가세미포함)
  public mmRentAmt = "";

  // 월렌트료(부가세포함)
  public mmRentAmtV = "";

  // COSS 재고
  public prodStock = "";

  // 추천차량 등급 아이디
  public extgModeGrdId = "";

  // 추천차량 상품 아아디
  public extgProdId = "";

  // 추천차량 단계 (1-> 1차, 2-> 2차)
  public recClsDeg = "";

  // 외장색상
  public xtnlColorNm = "";

  // 내장색상
  public innrColorNm = "";

  // 그룹사할인여부
  public skGrpYn = "";

  // 제휴사코드
  public cooperCd = "";

  // 제휴사명
  public cooperCdNm = "";

  /**
   * 타고페이
   */

  // 요금제명
  public amtCondNm = "";

  /**
   * 중고장기
   */
  // 요금제명
  public tkvAmtRtFlag = "";

  // 인수옵션코드
  public tkvRtrnYnCd = "";

  // 인수옵션코드네임
  public tkvRtrnYnNm = "";

  // 타고바이 여부
  public tgbyYn = "";
}

export default Estimation;
