import { Switch } from "antd";
import React from "react";
import { ContentText } from "../../shared/layout/DetailSection/style";


interface CustomTMAgreeSectionProps {
  tmAgreeTxtValue: string;
  onChange:(checked: boolean)=> void
}

const CustomTMAgreeSection: React.FC<CustomTMAgreeSectionProps> = (props) => {

  const {tmAgreeTxtValue , onChange  } = props;

  return (
      <div  style={{ marginRight: "10px",}}>

        <Switch
          checked={tmAgreeTxtValue === "동의"}
        
          onChange={value => onChange(value)}
        />
        <span
          style={{
            marginLeft: "12px",
            color: "#626DFF",
          }}
        >
          {tmAgreeTxtValue}
        </span>
      </div>
  );
  };

  export default CustomTMAgreeSection;