import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import RentContractDetail from "../../../../../../../models/RentContractDetail";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import RentContractMemo from "../../../../../../../models/RentContractMemo";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../components/shared/data-entry/FormBuilder/types";

import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import {
  addContractMemo,
  editContractMemo,
} from "../../../../../../../apis/rent-contracts";
import {  
  CNSL_YN_NO_ALL_OPTIONS,
} from "../../../../../../../../src/constants/enums";

import errorMessages from "../../../../../../../constants/errors";

interface Props extends ModalProps {
  rentContractDetail?: RentContractDetail;
  memo?: RentContractMemo;
}

const MemoFormModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, rentContractDetail, memo } = props;
  const formInitialValues = {
    cnslYn: rentContractDetail?.cnslEndYn,
    custId: rentContractDetail?.custId,
    memoCntn: "",
  };
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    memoCntn: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
  const forms: FormInfo[] = [
    {
      key: "cnslYn",
      type: FormElementType.Select,
      label: "완료여부",
      required: true,
      options: CNSL_YN_NO_ALL_OPTIONS,
    },
    {
      key: "memoCntn",
      type: FormElementType.TextArea,
      label: "내용",
      required: true,
      placeholder: "상담내용을 입력해주세요",
    },
  ];

  let formik: FormikProps<FormikValues>;

  const onModalOpen = () => {
    if (memo) {
      setInitialValues({
        cnslYn: memo.cnslEndYn,
        memoCntn: memo.memoCntn,
      });
    } else {
      setInitialValues(formInitialValues);
    }
  };

  /**
   * Private Functions
   */
  const requestAddMemo = async (
    cntrId: string,
    cntrSeq: number,
    memoCntn: string,
    cnsEndlYn: string,
    custId: string,
  ) => {
    setConfirmLoading(true);
    try {
      await addContractMemo(cntrId, cntrSeq, memoCntn, cnsEndlYn, custId);
      alertSuccess("메모가 등록되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditMemo = async (
    cntrId: string,
    cntrSeq: number,    
    memoSeq: number,
    memoCntn: string,    
    cnsEndlYn: string,
    custId: string,
  ) => {
    setConfirmLoading(true);
    try {
      await editContractMemo(cntrId, cntrSeq, memoSeq, memoCntn, cnsEndlYn, custId);
      alertSuccess("메모가 수정되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const getModalTitle = () => {
    if (memo) {
      return "메모수정";
    }
    return "메모등록";
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (rentContractDetail) {
      const { cntrId, cntrSeq, custId } = rentContractDetail;
      const { cnslYn, memoCntn } = values;
      // 수정
      if (memo) {
        await requestEditMemo(cntrId, cntrSeq, memo.memoSeq, memoCntn, cnslYn, custId);
      }
      // 등록
      else {
        await requestAddMemo(cntrId, cntrSeq, memoCntn, cnslYn, custId);
      }
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      title={getModalTitle()}
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      <FormBuilder
        validationSchema={validationSchema}
        initialValues={initialValues}
        forms={forms}
        formRef={(ref) => {
          formik = ref;
        }}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

MemoFormModal.defaultProps = {};
export default MemoFormModal;
