import React, { ReactNode } from "react";
import { Col, Row } from "antd";
import {
  SectionContainer,
  SectionTitle,
  SectionDivider,
  ContentText,
  InnerSectionTitle,
} from "./style";
import FormItem from "../../data-entry/antd/FormItem";

export enum FieldType {
  Text,
  LabelOnly,
  Custom,
  Divider,
}

export interface SectionFieldInfo {
  label?: string;
  type?: FieldType;
  render?: () => void;
  span?: number;
  content?: any;
  visible?: boolean;
}

export interface DetailSectionProps {
  children?: React.ReactNode | React.FC;
  label?: string;
  fieldInfos?: Array<SectionFieldInfo | undefined>;
  hideDivider?: boolean;
}

const DetailSection: React.FC<DetailSectionProps> = (
  props: DetailSectionProps
) => {
  const { children, label, fieldInfos, hideDivider } = props;

  const renderFieldInfos = () => {
    if (fieldInfos) {
      return fieldInfos.map((info, index) => {
        if (!info) return "";

        const {
          label,
          type = FieldType.Text,
          render,
          span = 6,
          content,
          visible = true,
        } = info;

        if (!visible) return "";

        if (type === FieldType.Divider) {
          return (
            <Col
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#eeeff5",
                padding: "0",
              }}
              span={24}
              key={index}
            />
          );
        }

        if (label) {
          if (type === FieldType.LabelOnly) {
            return (
              <Col span={24} key={index}>
                <InnerSectionTitle>{label}</InnerSectionTitle>
              </Col>
            );
          }

          return (
            <Col key={index} span={span}>
              <FormItem label={label} labelCol={{ span: 24, offset: 0 }}>
                {renderContent(type, content, render) as any}
              </FormItem>
            </Col>
          );
        }

        if (!label && type === FieldType.Custom && render)
          return (
            <Col key={index} span={span}>
              {render() as ReactNode}
            </Col>
          );

        // eslint-disable-next-line react/no-array-index-key
        return <Col key={index} span={span} />;
      });
    }

    return "";
  };

  const renderContent = (
    type?: FieldType,
    content?: any,
    render?: () => void
  ) => {
    if (type === FieldType.Text)
      return <ContentText>{content || "-"}</ContentText>;
    if (type === FieldType.Custom) {
      if (render) return render();
    }
    return "";
  };

  return (
    <div>
      <SectionContainer>
        <SectionTitle>{label}</SectionTitle>
        <Row
          gutter={[16, 24]}
          style={{
            flex: 1,
          }}
        >
          {renderFieldInfos()}
          {children}
        </Row>
      </SectionContainer>
      {!hideDivider && <SectionDivider />}
    </div>
  );
};

DetailSection.defaultProps = {
  label: "Section title",
  hideDivider: false,
};
export default DetailSection;
