import styled from "styled-components";

// padding-top: ${(props) => props.theme.header.height};
export const Wrapper = styled.div`
  height: 100vh;
  overflow-y: hidden;
  background-color: ${(props) => props.theme.common.colors.contentDetailBg};
  display: flex;
  flex-direction: column;
`;
