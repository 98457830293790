import React, { FC, ReactNode } from "react";
import { Route } from "react-router-dom";

interface PublicRouteProps {
  path: string;
  component: typeof React.Component | FC;
  exact: true;
  layout?: typeof React.Component | FC;
}

const PublicRoute: React.FC<PublicRouteProps> = (props: PublicRouteProps) => {
  const { path, exact, component: Component, layout: Layout, ...rest } = props;

  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={(routerProps) =>
        Layout ? (
          <Layout>
            <Component {...routerProps} />
          </Layout>
        ) : (
          <Component {...routerProps} />
        )
      }
    />
  );
};

PublicRoute.defaultProps = {};
export default PublicRoute;
