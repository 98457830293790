import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { createSurveyReportCols } from "./utils/table-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import { alertError } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { fetchDirectSurveyReportResults } from "../../../apis/survey";
import DirectSurveyResult from "../../../models/DirectSurvey/DirectSurveyResult";
import SurveyReportResultDetailModal from "./pages/SurveyReportResultDetailModal";

const SurveyReportManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [reportDetailVisible, setReportDetailVisible] = useState(false);

  const [selectedReport, setSelectedReport] = useState<DirectSurveyResult>();
  const [rows, setRows] = useState<Array<DirectSurveyResult> | undefined>(
    undefined
  );
  const [totalCount, setTotalCount] = useState(0);
  const cols = createSurveyReportCols();

  useEffect(() => {
    (async function fetchData() {
      await requestFetchDirectSurveyReportResults();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestFetchDirectSurveyReportResults = async () => {
    try {
      const carManagers = await fetchDirectSurveyReportResults();
      const { items, count } = carManagers;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
      setRows([]);
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    setSelectedReport(data);
    setReportDetailVisible(true);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout filterable={false} headerTitle="고객 설문조사 결과">
        <TableLayout
          gridApi={gridApi}
          title="설문조사 결과 리스트"
          totalCount={totalCount}
          hideSelectionCountText
          methodName="/admin/qstn-result"
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            cols={cols}
            frameworkComponents={{
              ...createActionCellRenderers(undefined, handleDetailClick, {
                hideDeleteButton: true,
              }),
            }}
          />
        </TableLayout>
      </ContentLayout>
      <SurveyReportResultDetailModal
        visible={reportDetailVisible}
        onCancel={() => {
          setReportDetailVisible(false);
        }}
        onDataChange={() => {
          requestFetchDirectSurveyReportResults();
          setReportDetailVisible(false);
        }}
        directSurveyResult={selectedReport}
      />
    </>
  );
};

SurveyReportManager.defaultProps = {};
export default SurveyReportManager;
