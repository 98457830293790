import * as Parallel from "async-parallel";
import { HttpMethod, request } from "../../network/request";
import {
  ExhibitionCatalog,
  ExhibitionMaster,
  ExhibitionResponse,
  ExhibitionEditParams,
  AddExhibitionParams,
  RequestUpdateExCatalogSeqParams,
  LinkExhibitionCatalogParams,
} from "./types";
import { ROOT_URL } from "../../network/api-urls";

/**
 * 기획전 등록
 * @param params
 */
export const addExhibition = async (params: AddExhibitionParams) => {
  await request(HttpMethod.Put, `${ROOT_URL}/admin/uc-ex`, undefined, params);
};

/**
 * 기획전 리스트 조회
 */
export const fetchExhibitions = async (): Promise<
  ExhibitionResponse<ExhibitionMaster>
> => {
  const res = await request(HttpMethod.Get, `${ROOT_URL}/admin/uc-ex`);
  return res.data.response;
};

/**
 * 기획전 상세 조회 (실제 API는 없지만 데이터 갱신용으로 정의)
 */
export const fetchExhibitionDetail = async (
  mstSeq: string
): Promise<ExhibitionMaster> => {
  const res = await fetchExhibitions();
  const { list } = res;
  const filtered = list.filter(({ exhMstSeq }) => {
    return exhMstSeq === mstSeq;
  });
  return filtered[0];
};

/**
 * 기획전 마스터 수정
 * @param params
 */
export const editExhibition = async (params: ExhibitionEditParams) => {
  await request(
    HttpMethod.Put,
    `${ROOT_URL}/admin/uc-ex-mst-update`,
    undefined,
    params
  );
};

/**
 * 기획전 상세 차량 조회
 */
export const fetchExhibitionCatalogs = async (
  exhMstSeq: string,
  exhUseYn = "Y"
): Promise<ExhibitionResponse<ExhibitionCatalog>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/uc-ex-catalogs/${exhMstSeq}/${exhUseYn}`
  );
  return res.data.response;
};

/**
 * 기획전 차량 연결
 */
export const linkExhibitionCatalog = async (
  linkInfo: LinkExhibitionCatalogParams
) => {
  await request(
    HttpMethod.Put,
    `${ROOT_URL}/admin/uc-ex-prod-useyn`,
    undefined,
    linkInfo
  );
};

/**
 * 기획전 차량 벌크 연결
 */
export const linkExhibitionCatalogs = async (
  linkInfos: LinkExhibitionCatalogParams[]
) => {
  await Parallel.each(linkInfos, async (linkInfo) => {
    await linkExhibitionCatalog(linkInfo);
  });
};

/**
 * 기획전 상품 게시순번 저장
 * @param list
 */
export const editExhibitionCatalogSeq = async (
  list: Array<RequestUpdateExCatalogSeqParams>
) => {
  await request(
    HttpMethod.Put,
    `${ROOT_URL}/admin/uc-ex-catalogs-seq`,
    undefined,
    list
  );
};
