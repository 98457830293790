import React, { useEffect, useState } from "react";
import moment from "moment";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { CONSULTING_STATE_RENDERER, createCols } from "./utils/table-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { get1MonthDateRange } from "../../../utils/date-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import { TABLE_DATE_FILTER_LIGHT_OPTIONS } from "../../../constants/enums";
import CarBooking from "../../../models/CarBooking";
import {
  createUserDetailRenderer,
  renderSwitchCol,
} from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import { getErrorMessage } from "../../../utils/common-utils";
import CustomerConsultingRequestDetailModal from "./pages/CarBookingHistoryDetailModal";
import {
  editCarBookingHistory,
  fetchCarBookingHistories,
} from "../../../apis/car-booking";

const CarBookingHistoryManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rows, setRows] = useState<Array<CarBooking> | undefined>(undefined);
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>();
  const [logInfo, setLogInfo] = useState<string>("");

  const cols = createCols();

  const filterInitialValues = {
    stDt: get1MonthDateRange(),
  };

  const [filterValues, setFilterValues] = useState<any>(filterInitialValues);

  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "예약신청일",
      options: TABLE_DATE_FILTER_LIGHT_OPTIONS,
    },
  ];

  useEffect(() => {
    (async function fetchData() {
      await requestDataList(filterValues);
    })();
    return () => {};
  }, []);

  /**
   * Private Functions
   */

  const requestDataList = async (filterValues: any) => {
    try {
      // 검색조건 설정
      const appliedFilters = JSON.stringify(filterValues);
      setLogInfo(appliedFilters);
      const { stDt } = filterValues;
      const stDtParam = moment(stDt[0]).format("YYYYMMDD");
      const endDtParam = moment(stDt[1]).format("YYYYMMDD");

      const bookingHistories = await fetchCarBookingHistories(
        stDtParam,
        endDtParam
      );
      const { items, count } = bookingHistories;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      if (e.status !== 404) {
        if (e.getMessage) alertError(e.getMessage());
        else alertError(e.toString());
      }
      setRows([]);
    }
  };

  const requestUpdateConsultingState = async (
    preRsvId: string,
    callYn: string
  ) => {
    try {
      await editCarBookingHistory(preRsvId, callYn);
      await requestDataList(filterValues);
      alertSuccess("상담전화여부 상태가 변경되었습니다");
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const booking = data as CarBooking;
    setSelectedDataId(booking.preRsvId);
    setModalVisible(true);
  };

  const handleSearchSubmit = async (values: any) => {
    setFilterValues(values);
    gridApi?.showLoadingOverlay();
    await requestDataList(values);
    gridApi?.hideOverlay();
  };

  const handleUserDetailCellClick = (custId?: string) => {
    alertError(`custId 필요 ${custId}`);
  };

  const handleCallStateChange = (checked: boolean, data: CarBooking) => {
    showConfirm(
      "상담센터 전화여부 변경",
      checked
        ? "통화완료 처리하시겠습니까?"
        : "미통화 상태로 변경하시겠습니까?",
      async () => {
        await requestUpdateConsultingState(data.preRsvId, checked ? "Y" : "N");
      }
    );
  };

  /**
   * Render Helpers
   */
  return (
    <>
      <ContentLayout
        filterable
        headerTitle="사전예약 신청내역"
        filterForms={filterForms}
        filterValues={filterValues}
        filterInitialValues={filterInitialValues}
        onSearchSubmit={handleSearchSubmit}
      >
        <TableLayout
          gridApi={gridApi}
          title="예약 신청리스트"
          totalCount={totalCount}
          hideSelectionCountText
          methodName="/admin/prereserv"
          logInfo={logInfo}
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            cols={cols}
            frameworkComponents={{
              [CONSULTING_STATE_RENDERER]: renderSwitchCol(
                "callYn",
                handleCallStateChange
              ),
              ...createUserDetailRenderer(handleUserDetailCellClick),
              ...createActionCellRenderers(undefined, handleDetailClick, {
                hideDeleteButton: true,
              }),
            }}
          />
        </TableLayout>
        <CustomerConsultingRequestDetailModal
          preRsvId={selectedDataId}
          visible={modalVisible}
          onDataChange={async () => {
            gridApi?.showLoadingOverlay();
            await requestDataList(filterValues);
            gridApi?.hideOverlay();
          }}
          onCancel={() => {
            setModalVisible(false);
          }}
        />
      </ContentLayout>
    </>
  );
};

CarBookingHistoryManager.defaultProps = {};
export default CarBookingHistoryManager;
