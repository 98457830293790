import React, { CSSProperties } from "react";
import Button, { ButtonProps } from "../../general/antd/Button";

interface Props extends ButtonProps {
  disabled?: boolean;
  style?: CSSProperties;
}

const TableDataActionButton: React.FC<Props> = (props: Props) => {
  const { disabled, style, children, ...rest } = props;

  return (
    <Button
      style={{
        fontWeight: 400,
        ...style,
      }}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};

TableDataActionButton.defaultProps = {
  disabled: false,
};
export default TableDataActionButton;
