import React, { useEffect, useState } from "react";
import { Modal as AntModal } from "antd";
import { uniqBy } from "lodash";
import { Formik, FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import {
  formatCurrency,
  getErrorMessage,
} from "../../../../../utils/common-utils";
import { fetchCustomerDetail } from "../../../../../apis/customers";
import CustomerDetail from "../../../../../models/CustomerDetail";
import EstimationNewCarCatalog from "../../../../../models/EstimationSimulator/EstimationNewCarCatalog";
import {
  ExtraOptionParam,
  fetchEstimationCatalogDetail,
  fetchEstimationCatalogs,
  fetchEstimationStockCatalogDetail,
  fetchRentFee,
  saveCarOptions,
} from "../../../../../apis/est-sim";
import commonStrings from "../../../../../constants/strings";
import {
  createInitialValues,
  createRentFeeQuery,
  createValidationSchema,
} from "./utils/form-utils";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import Select from "../../../../../components/shared/data-entry/antd/Select";
import {
  ENUM_PROD_TYPE_ORDER_PLACE,
  ENUM_PROD_TYPE_TAGO_PAY,
  ENUM_RENT_OPTION_CONTRACT_TERM,
  ENUM_RENT_OPTION_DRIVER_RANGE,
  ENUM_RENT_OPTION_FIX_SERVICE,
  ENUM_RENT_OPTION_LIABILITY_COVERAGE,
  ENUM_RENT_OPTION_MILEAGE,
  ENUM_RENT_OPTION_RENT_SERVICE,
  ENUM_VM_DEPOSIT,
  ENUM_VM_F_DEPOSIT,
  OPTIONS_PROD_TYPE,
} from "../../../../../constants/enums";
import EstimationCatalogDetail from "../../../../../models/EstimationSimulator/EstimationCatalogDetail";
import {
  createContractTermOptions,
  createInsrAgeOptions,
  createDepositOptions,
  createDepositWayOptions,
  createFDepositOptions,
  createRentOptions,
  getDefaultRentValue,
  validateOptions,
} from "./utils/utils";
import { RentFeeQueryParam } from "../../../../../apis/est-sim/types";
import RentFeeResult from "../../../../../models/RentFeeResult";
import EstimationResultModal from "./pages/EstimationResultModal";
import TrimInfo from "../../../../../models/EstimationSimulator/TrimInfo";
import OptionLogicInfo from "../../../../../models/EstimationSimulator/OptionLogicInfo";
import BusinessInfo from "../../../../../models/BusinessInfo";

interface Props extends ModalProps {
  custId?: string;
  businessInfo?: BusinessInfo;
}

const EstimationSimulatorModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, custId, businessInfo } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [catalogDetailLoading, setCatalogDetailLoading] = useState(false);
  const [catalogDetail, setCatalogDetail] = useState<EstimationCatalogDetail>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [
    estimationResultModalVisible,
    setEstimationResultModalVisible,
  ] = useState(false);
  const [customerDetail, setCustomerDetail] = useState<CustomerDetail>();
  const [catalogs, setCatalogs] = useState<Array<EstimationNewCarCatalog>>([]);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );
  const [rentFeeResult, setRentFeeResult] = useState<RentFeeResult>();
  const [rentFeeQuery, setRentFeeQuery] = useState<RentFeeQueryParam>();
  const [inputValues, setInputValues] = useState<FormikValues>();
  const [optionLogicInfos, setOptionLogicInfos] = useState<OptionLogicInfo[]>(
    []
  );
  // 반납형 여부
  const [isTkvRtn, setIsTkvRtn] = useState(false);

  useEffect(() => {
    setIsTkvRtn(catalogDetail?.tkvRtnYn === "Y");
  }, [catalogDetail?.tkvRtnYn]);

  let formik: FormikProps<FormikValues>;
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
    style: {
      marginBottom: "16px",
    },
  };
  const validationSchema = createValidationSchema();

  const onModalOpen = async () => {
    if (custId) {
      setInitialValues(createInitialValues());
      setCatalogDetail(undefined);
      setDataFetching(true);
      const customer = await requestFetchCustomer(custId);
      const catalogs = await requestCatalogs();
      setCustomerDetail(customer);
      setCatalogs(catalogs);
      setDataFetching(false);
      setOptionLogicInfos([]);
    } else {
      // 상태 Reset
      setInitialValues(createInitialValues());
      setCatalogs([]);
      setCatalogDetail(undefined);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchCustomer = async (custId: string) => {
    try {
      return await fetchCustomerDetail(custId);
    } catch (e) {
      alertError(getErrorMessage(e));
      return undefined;
    }
  };

  const requestCatalogs = async () => {
    try {
      const { items } = await fetchEstimationCatalogs();
      return items;
    } catch (e) {
      alertError(getErrorMessage(e));
      return [];
    }
  };

  const requestCatalogDetail = async (prodType: string, prodId: string) => {
    setCatalogDetailLoading(true);
    try {
      let catalogDetail;
      if (prodType === ENUM_PROD_TYPE_ORDER_PLACE) {
        catalogDetail = await fetchEstimationCatalogDetail(prodId);
      } else {
        catalogDetail = await fetchEstimationStockCatalogDetail(prodId);
      }
      setCatalogDetail(catalogDetail);
      return catalogDetail;
    } catch (e) {
      alertError(getErrorMessage(e));
      setCatalogDetail(undefined);
      return undefined;
    } finally {
      setCatalogDetailLoading(false);
    }
  };

  // 제조사 콤보박스 옵션 리스트 리턴
  const createBrandOptions = (renderProps: FormikProps<FormikValues>) => {
    const { values } = renderProps;
    const { prodType } = values;

    const filtered = catalogs.filter(
      ({ ncarClsFlag }) => ncarClsFlag === prodType
    );

    return uniqBy(filtered, "repMakerNm").map(({ repMakerNm }) => {
      return {
        label: repMakerNm,
        value: repMakerNm,
      };
    });
  };

  // 모델 콤보박스 옵션 리스트 리턴
  const createCatalogOptions = (renderProps: FormikProps<FormikValues>) => {
    const { values } = renderProps;
    const { prodType, brand } = values;

    return catalogs
      .filter((catalog) => {
        return catalog.ncarClsFlag === prodType && catalog.repMakerNm === brand;
      })
      .map((catalog) => {
        return {
          label: catalog.prodNm,
          value: catalog.prodId,
        };
      });
  };

  // 트림 옵션 리턴
  const createTrimOptions = (renderProps: FormikProps<FormikValues>) => {
    const { values } = renderProps;
    const { prodType } = values;

    if (catalogDetail) {
      // 발주 차량
      if (prodType === ENUM_PROD_TYPE_ORDER_PLACE) {
        return catalogDetail.trimList.map((trim, index) => {
          return {
            label: `${trim.carGradeNm} - ${
              trim.cartypeDtlNm
            } - ${formatCurrency(trim.carAmt)}`,
            value: index,
          };
        });
      }
      // 특판 차량
      return catalogDetail.trimList.map((trim: TrimInfo, index) => {
        const exColorName = trim.trimOption.exteriorColorOptionsList
          .filter((option) => {
            return !!option;
          })
          .map((option) => {
            return option.modeColorNm;
          })
          .join(",");
        const innerColorName = trim.trimOption.interiorColorOptionsList
          .filter((option) => {
            return !!option;
          })
          .map((option) => {
            return option.modeColorNm;
          })
          .join(",");
        const optionNames = trim.trimOption.carOptionsList
          .filter((option) => {
            return !!option;
          })
          .map((option) => {
            return option.optNm;
          })
          .join(",");
        return {
          label: `${trim.carGradeNm} ${exColorName}/${innerColorName}${
            optionNames ? `/${optionNames}` : ""
          } - ${formatCurrency(trim.carAmt)}${
            trim.useAreaNm ? ` (${trim.useAreaNm.replace("\\n", "")})` : ""
          }`,
          value: index,
        };
      });
    }

    return [];
  };

  // 외장색상 옵션 리턴
  const createColorOptions = (
    renderProps: FormikProps<FormikValues>,
    isEx: boolean
  ) => {
    const { values } = renderProps;
    const { trimIndex } = values;

    try {
      if (typeof trimIndex === "number") {
        let trim;
        if (catalogDetail) {
          trim = catalogDetail.trimList[trimIndex];
        }

        if (trim) {
          let optionsList;
          if (isEx) optionsList = trim.trimOption.exteriorColorOptionsList;
          else optionsList = trim.trimOption.interiorColorOptionsList;

          return optionsList.map((option) => {
            return {
              label: `${option.cossColorNm} - ${formatCurrency(option.amt)}`,
              value: option.cossColorId,
            };
          });
        }
      }
      return [];
    } catch (e) {
      alertError((e||"").toString());
      return [];
    }
  };

  // 추가 옵션 리턴
  const createExtraOptions = (renderProps: FormikProps<FormikValues>) => {
    const { values } = renderProps;
    const { trimIndex } = values;
    try {
      if (typeof trimIndex === "number") {
        let trim;
        if (catalogDetail) {
          trim = catalogDetail.trimList[trimIndex];
        }

        if (trim) {
          return trim.trimOption.carOptionsList.map((option) => {
            return {
              label: option.optNm,
              value: option.optId,
            };
          });
        }
      }
      return [];
    } catch (e) {
      alertError((e||"").toString());
      return [];
    }
  };

  // 차량 옵션 리턴
  const resetCarOptions = (renderProps: FormikProps<FormikValues>) => {
    const { setFieldValue } = renderProps;
    // 색상 초기화
    setFieldValue("carXtnlColor", null);
    setFieldValue("carInnrColor", null);
    setFieldValue("extraOptions", []);
    // 옵션로직 정보 갱신
    setOptionLogicInfos([]);
  };

  /**
   * 상품 선택 시, 옵션 기본 설정
   * 
   * @param renderProps 
   * @param catalogDetail 
   */
  const setDefaultRentOptions = (
    renderProps: FormikProps<FormikValues>,
    catalogDetail?: EstimationCatalogDetail
  ) => {
    // 렌트 옵션 디폴트 처리
    const { setFieldValue } = renderProps;
    const mIsTkvRtn = catalogDetail?.tkvRtnYn === "Y"

    const initCntrTermMm = getDefaultRentValue(ENUM_RENT_OPTION_CONTRACT_TERM, catalogDetail, businessInfo);
    if (mIsTkvRtn) {
      // 계약기간 설정
      setFieldValue("cntrTermMm", initCntrTermMm);
      // 약정주행거리 설정
      setPrmsDtcForBuyback(renderProps, initCntrTermMm)
      // 대물 설정
      setFieldValue("impsnl", "703007");
    } else {
      // 계약 기간
      setFieldValue("cntrTermMm", initCntrTermMm);

      // 약정 주행거리
      setFieldValue(
        "prmsDtc",
        getDefaultRentValue(ENUM_RENT_OPTION_MILEAGE, catalogDetail, businessInfo)
      );

      // 대물
      setFieldValue(
        "impsnl",
        getDefaultRentValue(ENUM_RENT_OPTION_LIABILITY_COVERAGE, catalogDetail, businessInfo)
      );
    }

    // 운전자 범위
    setFieldValue(
      "drvSoe",
      getDefaultRentValue(ENUM_RENT_OPTION_DRIVER_RANGE, catalogDetail, businessInfo)
    );

    // 정비서비스
    setFieldValue(
      "fixSrvcId",
      getDefaultRentValue(ENUM_RENT_OPTION_FIX_SERVICE, catalogDetail, businessInfo)
    );

    // 대차서비스
    setFieldValue(
      "lndcarSrvcId",
      /*getDefaultRentValue(
        ENUM_RENT_OPTION_RENT_SERVICE,
        catalogDetail,
        businessInfo
      ) || */
      "N"
    );

    // 초기납부조건
    // depositWay,
    // 담보율
    // depositRate,
  };

  const resetRentOptions = (renderProps: FormikProps<FormikValues>) => {
    // 렌트 옵션 리
    const { setFieldValue } = renderProps;
    // 계약 기간
    setFieldValue("cntrTermMm", null);
    // 보험연령
    setFieldValue("insrAge", null);
    // 약정 주행거리
    setFieldValue("prmsDtc", null);
    // 운전자 범위
    setFieldValue("drvSoe", null);
    // 정비서비스
    setFieldValue("fixSrvcId", null);
    // 대차서비스
    setFieldValue("lndcarSrvcId", null);
    // 초기납부조건
    setFieldValue("depositWay", null);
    // 담보율
    setFieldValue("depositRate", null);
    // 대물
    setFieldValue("impsnl", null);
  };

  /**
   * 신차장기상품 렌탈료 조회 + 옵션 내역 저장
   * 
   * @param params 
   * @param values 
   */
  const requestFetchRentFee = async (
    params: RentFeeQueryParam,
    values: FormikValues
  ) => {
    setConfirmLoading(true);
    try {
      const result = await fetchRentFee(params);
      const { extraOptions, trimIndex } = values;
      const trim = catalogDetail?.trimList[trimIndex];
      let carOptionsParam: ExtraOptionParam[] = [];
      const { rntFeeId } = result;
      if (trim && extraOptions) {
        if (extraOptions.length > 0) {
          const filtered = trim.trimOption.carOptionsList.filter(
            ({ optId }) => {
              return extraOptions.indexOf(optId) !== -1;
            }
          );
          carOptionsParam = filtered.map(
            ({ optId, optAmt, optNm, optClsCd }) => {
              return {
                optId,
                optAmt,
                optNm,
                optClsCd,
              };
            }
          );
        }
      }

      await saveCarOptions(rntFeeId, carOptionsParam);
      setRentFeeQuery(params);
      setRentFeeResult(result);
      setInputValues(values);
      setEstimationResultModalVisible(true);
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    if (customerDetail) {
      const { carXtnlColor, carInnrColor, extraOptions, trimIndex, insrAge } = values;

      let trim = catalogDetail?.trimList[trimIndex];
      // 요금제구분 설정
      values.amtCondCd = trim?.trimOption?.ubrOptionsList?.find(v => v.drvingAgeCd === insrAge)?.cd ?? null;

      const errs = validateOptions(
        optionLogicInfos,
        extraOptions,
        carInnrColor,
        carXtnlColor,
        trim
      );
      if (errs && errs.length > 0) {
        AntModal.error({
          title: "옵션 및 색상정보를 확인해주세요",
          content: (
            <div>
              {errs.map(({ reasonCode, selectedOption, errorOption }) => {
                if (reasonCode === "dup") {
                  return (
                    <div>
                      선택하신
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {` [${selectedOption}] `}
                      </span>
                      은
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {` [${errorOption}] `}
                      </span>
                      과 중복하여 선택불가합니다.
                    </div>
                  );
                }

                if (reasonCode === "color") {
                  return (
                    <div>
                      선택하신
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {` [${selectedOption}] `}
                      </span>
                      은
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {` [${errorOption}] `}
                      </span>
                      색상을 선택시 선택가능합니다.
                    </div>
                  );
                }

                if (reasonCode === "essential") {
                  return (
                    <div>
                      선택하신
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {` [${selectedOption}] `}
                      </span>
                      은
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {` [${errorOption}] `}
                      </span>
                      옵션 선택시 선택가능합니다.
                    </div>
                  );
                }
              })}
            </div>
          ),
        });
      } else {
        const params = createRentFeeQuery(
          values,
          customerDetail,
          catalogDetail,
          businessInfo
        );
        await requestFetchRentFee(params, values);
      }
    }
  };

  // 상품 타입 변경시
  const handleProdTypeChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("prodType", value);

    // 제조사 초기화
    setFieldValue("brand", null);
    // 상품 초기화
    setFieldValue("prodId", null);
    // 트림 초기화
    setFieldValue("trimIndex", null);
    // 보험연령 초기화
    setFieldValue("insrAge", null);

    // 상품 상태 초기화
    setCatalogDetail(undefined);

    // 옵션 초기화
    resetCarOptions(renderProps);

    // 렌트옵션 초기화
    resetRentOptions(renderProps);
  };

  // 제조사 변경시
  const handleBrandChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("brand", value);

    // 상품 초기화
    setFieldValue("prodId", null);
    // 트림 초기화
    setFieldValue("trimIndex", null);
    // 보험연령 초기화
    setFieldValue("insrAge", null);
    // 상품 상태 초기화
    setCatalogDetail(undefined);
    // 옵션 초기화
    resetCarOptions(renderProps);
    // 렌트옵션 초기화
    resetRentOptions(renderProps);
  };

  // 상품 변경시
  const handleCatalogChange = async (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue, values } = renderProps;
    const { prodType } = values;
    // 상품 상세 조회
    const catalogDetail = await requestCatalogDetail(prodType, value);

    setFieldValue("prodId", value);
    // 트림 초기화
    setFieldValue("trimIndex", null);
    // 보험연령 초기화
    setFieldValue("insrAge", null);

    // 옵션 초기화
    resetCarOptions(renderProps);

    // 렌트 옵션 기본값 적용
    setDefaultRentOptions(renderProps, catalogDetail);
  };

  // 트림 변경 시
  const handleTrimChange = (
    renderProps: FormikProps<FormikValues>,
    value: number
  ) => {
    const { setFieldValue, values } = renderProps;
    const { prodType } = values;
    setFieldValue("trimIndex", value);
    // 보험연령 초기화
    setFieldValue("insrAge", null);

    try {
      if (catalogDetail) {
        // 옵션 초기화
        resetCarOptions(renderProps);
        if (prodType !== ENUM_PROD_TYPE_ORDER_PLACE) {
          // 특판 하위 옵션 초기화 및 등록된 옵션 자동 매핑
          const trim = catalogDetail.trimList[value];
          if (trim) {
            // 외장
            const exColorId =
              trim.trimOption.exteriorColorOptionsList[0].cossColorId;
            handleExColorChange(renderProps, exColorId);

            // 내장
            const inColorId =
              trim.trimOption.interiorColorOptionsList[0].cossColorId;
            handleInColorChange(renderProps, inColorId);

            // 부가 옵션
            const extraOptions = trim.trimOption.carOptionsList.map(
              (carOption) => {
                return carOption.optId;
              }
            );
            handleExtraOptionChange(renderProps, extraOptions);

            // "타고페이"인 경우,
            if (prodType === ENUM_PROD_TYPE_TAGO_PAY) {

              // 보험연령
              const insrAge = trim.trimOption?.ubrOptionsList[0]?.drvingAgeCd;
              if (insrAge) {
                handleInsrAgeChange(renderProps, insrAge);
              }
            }
          }
        }
        // 신차일 경우 옵션 로직 정보 저장
        else {
          const trim = catalogDetail.trimList[value];
          if (trim) {
            setOptionLogicInfos(trim.trimOption.optionHrchList);
          } else {
            setOptionLogicInfos([]);
          }
        }
      }
    } catch (e) {
      alertError(e.toString());
    }
  };

  const handleExColorChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("carXtnlColor", value);
  };

  const handleInColorChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("carInnrColor", value);
  };

  const handleExtraOptionChange = (
    renderProps: FormikProps<FormikValues>,
    values: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("extraOptions", values);
  };

  /**
   * (바이백 전용) 약정 주행거리 값 가져오기
   * 
   * @param value 
   * @returns 
   */
  const setPrmsDtcForBuyback = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    handleMileageChange(renderProps, value === "18" ? "402009" : /* if value === "12" */ "402001");
  }

  /**
   * 계약기간 선택 이벤트
   * 
   * @param renderProps 
   * @param value 
   */
  const handleContractTermChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    // const isTkvRtn = catalogDetail?.tkvRtnYn === "Y";
    const { setFieldValue } = renderProps;
    setFieldValue("cntrTermMm", value);
    if (isTkvRtn) {
      setPrmsDtcForBuyback(renderProps, value);
    }
  };

  /**
   * 보험연령 선택 이벤트
   * 
   * @param renderProps 
   * @param value 
   */
  const handleInsrAgeChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("insrAge", value);
  };

  const handleMileageChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("prmsDtc", value);
  };

  const handleDriverRangeChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("drvSoe", value);
  };

  const handleFixServiceChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("fixSrvcId", value);
  };

  const handleRentServiceChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("lndcarSrvcId", value);
  };

  const handleLiAbilityCoverageServiceChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("impsnl", value);
  };

  const handleDepositWayChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("depositWay", value);
    if (value === ENUM_VM_DEPOSIT || value === ENUM_VM_F_DEPOSIT)
      setFieldValue("depositRate", null);
  };

  const handleDepositRateChange = (
    renderProps: FormikProps<FormikValues>,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue("depositRate", value);
  };

  // 트림 선택 가능
  const canSelectTrimOptions = () => {
    return !!catalogDetail;
  };

  // 색상, 부가옵션 선택가능
  const canSelectCarOption = (renderProps: FormikProps<FormikValues>) => {
    const { values } = renderProps;
    const { trimIndex, prodType } = values;
    return prodType === ENUM_PROD_TYPE_ORDER_PLACE && trimIndex !== null;
  };

  const getFormError = (
    renderProps: FormikProps<FormikValues>,
    key: string
  ): {
    help: any;
    validateStatus: any;
  } => {
    const { errors, touched } = renderProps;
    if (errors[key] && touched[key]) {
      return {
        help: errors[key] as string,
        validateStatus: "error",
      };
    }
    return {
      help: undefined,
      validateStatus: "",
    };
  };

  /**
   * 약정주행거리 옵션 목록 생성
   * 
   * @param renderProps 
   * @returns 
   */
  const prmsDtcRentOptions = (renderProps: FormikProps<FormikValues>) => {
    const { values } = renderProps;
    const {
      // 계약 기간
      cntrTermMm,
    } = values;
    // const isTkvRtn = catalogDetail?.tkvRtnYn === "Y";
    return createRentOptions(
      ENUM_RENT_OPTION_MILEAGE,
      catalogDetail,
      businessInfo
    ).filter(v => isTkvRtn ? (cntrTermMm === "12" ? v.value === "402001" : (cntrTermMm === "18" ? v.value === "402009" : true)) : true);
  };

  /**
   * 대차서비스 옵션 목록 생성
   * 
   * @returns 
   */
  const fixSrvcIdRentOptions = () => {
    return createRentOptions(
      ENUM_RENT_OPTION_RENT_SERVICE,
      catalogDetail,
      businessInfo
    ).filter(v => isTkvRtn ? v.value === "N" : true);
  };

  /**
   * 대물 옵션 목록 생성
   * 
   * @returns 
   */
  const impsnlRentOptions = () => {
    // const isTkvRtn = catalogDetail?.tkvRtnYn === "Y";
    return createRentOptions(
      ENUM_RENT_OPTION_LIABILITY_COVERAGE,
      catalogDetail,
      businessInfo
    ).filter(v => isTkvRtn ? v.value === "703007" : true);
  };

  /**
   * 보증급 납부 옵션 목록 생성
   * 
   * @returns 
   */
  const depositWayRentOptions = () => {
    // const isTkvRtn = catalogDetail?.tkvRtnYn === "Y";
    return createDepositWayOptions(
      customerDetail,
      businessInfo,
      isTkvRtn
    )
  };

  /**
   * Render Helpers
   */
  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        innerRef={(ref: FormikProps<FormikValues>) => {
          formik = ref;
        }}
      >
        {(renderProps: FormikProps<FormikValues>) => {
          const { values } = renderProps;
          const {
            prodId,
            prodType,
            // 제조사
            brand,
            trimIndex,
            carXtnlColor,
            carInnrColor,
            extraOptions,
            // 계약 기간
            cntrTermMm,
            // 보험연령
            insrAge,
            // 약정 주행거리
            prmsDtc,
            // 운전자 범위
            drvSoe,
            // 초기납부조건
            depositWay,

            // 정비서비스
            fixSrvcId,
            // 대차서비스
            lndcarSrvcId,
            // 담보율
            depositRate,
            // 대물
            impsnl,
          } = values;

          return (
            <form>
              {/* 상품유형 */}
              <FormItem
                label={commonStrings.LABEL_PROD_TYPE}
                required
                {...formItemLayout}
              >
                <Select
                  value={prodType}
                  options={OPTIONS_PROD_TYPE}
                  onChange={(value) => {
                    handleProdTypeChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 제조사 */}
              <FormItem
                label={commonStrings.LABEL_BRAND}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "brand")}
              >
                <Select
                  placeholder={commonStrings.HINT_BRAND}
                  value={brand}
                  options={createBrandOptions(renderProps)}
                  onChange={(value) => {
                    handleBrandChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 모델(상품명) */}
              <FormItem
                label={commonStrings.LABEL_MODEL}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "prodId")}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={catalogDetailLoading || !brand}
                  value={prodId}
                  placeholder={commonStrings.HINT_REP_CAR_CLASS_NM}
                  options={createCatalogOptions(renderProps)}
                  onChange={async (value) => {
                    await handleCatalogChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 트림 */}
              <FormItem
                label={commonStrings.LABEL_TRIM}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "trimIndex")}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={!canSelectTrimOptions()}
                  value={trimIndex}
                  placeholder={commonStrings.HINT_TRIM}
                  options={createTrimOptions(renderProps)}
                  onChange={(value) => {
                    handleTrimChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 외장색상 */}
              <FormItem
                label={commonStrings.LABEL_EX_COLOR}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "carXtnlColor")}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={!canSelectCarOption(renderProps)}
                  value={carXtnlColor}
                  placeholder={commonStrings.HINT_EX_COLOR}
                  options={createColorOptions(renderProps, true)}
                  onChange={(value) => {
                    handleExColorChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 내장색상 */}
              <FormItem
                label={commonStrings.LABEL_IN_COLOR}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "carInnrColor")}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={!canSelectCarOption(renderProps)}
                  value={carInnrColor}
                  placeholder={commonStrings.HINT_IN_COLOR}
                  options={createColorOptions(renderProps, false)}
                  onChange={(value) => {
                    handleInColorChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 추가옵션 */}
              <FormItem
                label={commonStrings.LABEL_EXTRA_OPTIONS}
                {...formItemLayout}
              >
                <Select
                  mode="multiple"
                  loading={catalogDetailLoading}
                  disabled={!canSelectCarOption(renderProps)}
                  value={extraOptions}
                  placeholder={commonStrings.HINT_EXTRA_OPTION}
                  options={createExtraOptions(renderProps)}
                  onChange={(values) => {
                    handleExtraOptionChange(renderProps, values);
                  }}
                />
              </FormItem>

              {/* 계약기간 */}
              <FormItem
                label={commonStrings.LABEL_CONTRACT_TERM}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "cntrTermMm")}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={!canSelectTrimOptions()}
                  value={cntrTermMm}
                  placeholder={commonStrings.HINT_CONTRACT_TERM}
                  options={createContractTermOptions(catalogDetail)}
                  onChange={(value) => {
                    handleContractTermChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 보험연령 - if 상품유형이 "타고페이"일 때, 활성화 */}
              {prodType === ENUM_PROD_TYPE_TAGO_PAY &&
              <FormItem
                label={commonStrings.LABEL_INSR_AGE}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "insrAge")}
              >
                {/* if 트림 선택 시, 활성화 */}
                <Select
                  loading={catalogDetailLoading}
                  disabled={trimIndex === null}
                  value={insrAge}
                  placeholder={commonStrings.HINT_INSR_AGE}
                  options={createInsrAgeOptions(catalogDetail, trimIndex)}
                  onChange={(value) => {
                    handleInsrAgeChange(renderProps, value);
                  }}
                />
              </FormItem>}

              {/* 약정주행거리 */}
              <FormItem
                label={commonStrings.LABEL_MILEAGE}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "prmsDtc")}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={!canSelectTrimOptions()}
                  value={prmsDtc}
                  placeholder={commonStrings.HINT_MILEAGE}
                  options={prmsDtcRentOptions(renderProps)}
                  onChange={(value) => {
                    handleMileageChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 운전자범위 */}
              <FormItem
                label={commonStrings.LABEL_DRIVER_RANGE}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "drvSoe")}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={!canSelectTrimOptions()}
                  value={drvSoe}
                  placeholder={commonStrings.HINT_DRIVER_RANGE}
                  options={createRentOptions(
                    ENUM_RENT_OPTION_DRIVER_RANGE,
                    catalogDetail,
                    businessInfo
                  )}
                  onChange={(value) => {
                    handleDriverRangeChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 정비서비스 */}
              <FormItem
                label={commonStrings.LABEL_FIX_SERVICE}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "fixSrvcId")}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={!canSelectTrimOptions()}
                  value={fixSrvcId}
                  placeholder={commonStrings.HINT_FIX_SERVICE}
                  options={createRentOptions(
                    ENUM_RENT_OPTION_FIX_SERVICE,
                    catalogDetail,
                    businessInfo
                  )}
                  onChange={(value) => {
                    handleFixServiceChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 대차서비스 */}
              <FormItem
                label={commonStrings.LABEL_RENT_SERVICE}
                {...formItemLayout}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={!canSelectTrimOptions()}
                  value={lndcarSrvcId}
                  placeholder={commonStrings.HINT_RENT_SERVICE}
                  options={fixSrvcIdRentOptions()}
                  onChange={(value) => {
                    handleRentServiceChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 대물 */}
              <FormItem
                required
                label={commonStrings.LABEL_RENT_LAB_COV}
                {...formItemLayout}
                {...getFormError(renderProps, "impsnl")}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={!canSelectTrimOptions()}
                  value={impsnl}
                  placeholder={commonStrings.HINT_RENT_LAV_COV}
                  options={impsnlRentOptions()}
                  onChange={(value) => {
                    handleLiAbilityCoverageServiceChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 초기납부조건 */}
              <FormItem
                label={commonStrings.LABEL_DEPOSIT_WAY}
                required
                {...formItemLayout}
                {...getFormError(renderProps, "depositWay")}
              >
                <Select
                  loading={catalogDetailLoading}
                  disabled={!canSelectTrimOptions()}
                  value={depositWay}
                  placeholder={commonStrings.HINT_DEPOSIT_WAY}
                  options={depositWayRentOptions()}
                  onChange={(value) => {
                    handleDepositWayChange(renderProps, value);
                  }}
                />
              </FormItem>

              {/* 담보율 */}
              {depositWay === ENUM_VM_DEPOSIT && (
                <FormItem
                  label={commonStrings.LABEL_DEPOSIT}
                  required
                  {...formItemLayout}
                  {...getFormError(renderProps, "depositRate")}
                >
                  <Select
                    loading={catalogDetailLoading}
                    disabled={!canSelectTrimOptions()}
                    value={depositRate}
                    placeholder={commonStrings.HINT_DEPOSIT}
                    options={createDepositOptions(customerDetail, businessInfo)}
                    onChange={(value) => {
                      handleDepositRateChange(renderProps, value);
                    }}
                  />
                </FormItem>
              )}

              {/* 선수금율 */}
              {depositWay === ENUM_VM_F_DEPOSIT && (
                <FormItem
                  label={commonStrings.LABEL_F_DEPOSIT}
                  required
                  {...formItemLayout}
                  {...getFormError(renderProps, "depositRate")}
                >
                  <Select
                    loading={catalogDetailLoading}
                    disabled={!canSelectTrimOptions()}
                    value={depositRate}
                    placeholder={commonStrings.HINT_F_DEPOSIT}
                    options={createFDepositOptions(customerDetail)}
                    onChange={(value) => {
                      handleDepositRateChange(renderProps, value);
                    }}
                  />
                </FormItem>
              )}
            </form>
          );
        }}
      </Formik>
    );
  };

  const getModalTitle = () => {
    if (businessInfo) {
      return `견적내기 - ${businessInfo.busrCustNm}`;
    }

    return "견적내기 - 개인";
  };

  return (
    <>
      <Modal
        title={getModalTitle()}
        visible={visible}
        onCancel={onCancel}
        okText={commonStrings.FETCH_RENT_FEE}
        onOk={() => {
          formik?.handleSubmit();
        }}
        confirmLoading={confirmLoading}
        onOpen={onModalOpen}
      >
        <div>{dataFetching ? renderLoading() : renderForm()}</div>
      </Modal>
      <EstimationResultModal
        businessInfo={businessInfo}
        visible={estimationResultModalVisible}
        onCancel={() => {
          setEstimationResultModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        rentFeeQuery={rentFeeQuery}
        rentFeeResult={rentFeeResult}
        formValues={inputValues}
        customerDetail={customerDetail}
        onDataChange={() => {
          if (onDataChange) onDataChange();
        }}
      />
    </>
  );
};

EstimationSimulatorModal.defaultProps = {
  custId: "MC00000272",
};
export default EstimationSimulatorModal;
