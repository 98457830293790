import {
  ContractTerm,
  DriverRange,
  DrivingMileage,
} from "../../../../constants/enums";

export const CREDIT_RATING_OPTIONS = [
  {
    label: "1등급",
    value: 1,
  },
  {
    label: "2등급",
    value: 2,
  },
  {
    label: "3등급",
    value: 3,
  },
  {
    label: "4등급",
    value: 4,
  },
  {
    label: "5등급",
    value: 5,
  },
  {
    label: "6등급",
    value: 6,
  },
  {
    label: "7등급",
    value: 7,
  },
];

export const CONTRACT_TERM_OPTIONS = [
  {
    label: "18개월",
    value: ContractTerm.M18,
  },
  {
    label: "24개월",
    value: ContractTerm.M24,
  },
  {
    label: "30개월",
    value: ContractTerm.M30,
  },
  {
    label: "36개월",
    value: ContractTerm.M36,
  },
  {
    label: "48개월",
    value: ContractTerm.M48,
  },
];

export const DRIVER_RANGE_OPTIONS = [
  {
    label: "가족한정",
    value: DriverRange.AllFamily,
  },
  {
    label: "1인지정",
    value: DriverRange.WithOne,
  },
  {
    label: "누구나",
    value: DriverRange.AnyOne,
  },
  {
    label: "임직원",
    value: DriverRange.Employees,
  },
  {
    label: "가족(직계, 형제자매)",
    value: DriverRange.Family,
  },
  {
    label: "가족(직계, 형제자매) + 1인추가",
    value: DriverRange.FamilyWithOne,
  },
  {
    label: "가족(직계, 형제자매) + 2인추가",
    value: DriverRange.FamilyWithTwo,
  },
  {
    label: "가족(직계, 형제자매)/직원",
    value: DriverRange.FamilyEmployees,
  },
  {
    label: "가족(직계, 형제자매)/직원 + 1인추가",
    value: DriverRange.FamilyEmployeesWithOne,
  },
  {
    label: "가족(직계, 형제자매)/직원 + 2인추가",
    value: DriverRange.FamilyEmployeesWithTwo,
  },
  {
    label: "임직원한정(특약)",
    value: DriverRange.EmployeesSpecial,
  },
  {
    label: "임직원한정(특약미가입)",
    value: DriverRange.EmployeesNonSpecial,
  },
  {
    label: "임직원/임원가족 + 1인(특약미가입)",
    value: DriverRange.EmployeesFamilyWithOne,
  },
  {
    label: "임직원/임원가족 + 2인(특약미가입)",
    value: DriverRange.EmployeesFamilyWithTwo,
  },
];

export const MILEAGE_OPTIONS = [
  {
    label: "1만km이하",
    value: DrivingMileage.L10000KM,
  },
  {
    label: "1만5천km이하",
    value: DrivingMileage.L15000KM,
  },
  {
    label: "2만km이하",
    value: DrivingMileage.L20000KM,
  },
  {
    label: "2만5천km이하",
    value: DrivingMileage.L25000KM,
  },
  {
    label: "3만km이하",
    value: DrivingMileage.L30000KM,
  },
  {
    label: "3만5천km이하",
    value: DrivingMileage.L35000KM,
  },
  {
    label: "무제한",
    value: DrivingMileage.NO_LIMIT,
  },
  {
    label: "4만km이하",
    value: DrivingMileage.L40000KM,
  },
  {
    label: "5만km이하",
    value: DrivingMileage.L50000KM,
  },
  {
    label: "6만km",
    value: DrivingMileage.L60000KM,
  },
];
