import React, { useState } from "react";
import { isEqual } from "lodash";
import { ModalProps } from "../../../../../../types";
import ConsultingMaster from "../../../../../../../models/ConsultingData/ConsultingMaster";
import ConsultingDetail from "../../../../../../../models/ConsultingData/ConsultingDetail";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ConsultingMovableItem from "./components/ConsultingMovableItem";
import update from "immutability-helper";
import { ColumnHeaderLabel } from "../../../../../../car-sales/NewCarCatalogManager/pages/CatalogSortOrderModal/components/CatalogListItem/styles";
import { CW_NO } from "../../../../../../car-sales/NewCarCatalogManager/pages/CatalogSortOrderModal/components/CatalogListItem";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import { editConsultingDataRank } from "../../../../../../../apis/intlcnsl";

interface Props extends ModalProps {
  consultingMaster?: ConsultingMaster;
  details?: ConsultingDetail[];
}

const ConsultingDataEditModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, consultingMaster, details } = props;
  const [updatedDetails, setUpdatedDetails] = useState<ConsultingDetail[]>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onModalOpen = () => {
    if (details) {
      setUpdatedDetails(details);
    }
  };

  /**
   * Private Functions
   */

  const requestEditRank = async () => {
    setConfirmLoading(true);
    try {
      const params = updatedDetails.map((data, index) => {
        return {
          cnslCls: data.cnslCls,
          btchNo: data.btchNo,
          btchSeq: data.btchSeq,
          mngrRnk: index + 1,
          autoYn: "N",
        };
      });
      await editConsultingDataRank(params);
      alertSuccess("노출순서가 변경되었습니다");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Event Actions
   */
  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    const dragCard = updatedDetails[dragIndex];
    setUpdatedDetails(
      update(updatedDetails, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
    );
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      title="집계 정보 변경"
      visible={visible}
      onCancel={onCancel}
      size="small"
      onOpen={onModalOpen}
      confirmLoading={confirmLoading}
      onOk={async () => {
        await requestEditRank();
      }}
    >
      <DndProvider backend={HTML5Backend}>
        <div
          style={{
            padding: "0 8px",
            width: "100%",
            display: "flex",
            height: "20px",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <ColumnHeaderLabel width={CW_NO}>순위</ColumnHeaderLabel>
          <ColumnHeaderLabel width={300}>집계 항목</ColumnHeaderLabel>
        </div>
        <div>
          {updatedDetails?.map((detail, index) => {
            return (
              <ConsultingMovableItem
                detail={detail}
                id={detail.btchSeq}
                key={detail.btchSeq}
                index={index}
                moveCard={handleMoveItem}
              />
            );
          })}
        </div>
      </DndProvider>
    </Modal>
  );
};

ConsultingDataEditModal.defaultProps = {};
export default ConsultingDataEditModal;
