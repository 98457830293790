/* eslint-disable */
import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  ConnectDropTarget,
  ConnectDragSource,
  DropTargetMonitor,
  DragSourceMonitor,
  DragSource,
  DropTarget,
  DropTargetConnector,
  DragSourceConnector,
  XYCoord,
} from "react-dnd";

import ConsultingDetail from "../../../../../../../../../models/ConsultingData/ConsultingDetail";
import { ListItemWrapper, ColumnWrapper } from "./styles";
import {INT_CONSULTING_CATEGORY_OPTION, INT_CONSULTING_CATEGORY_TRIM} from "../../../../../../../../../constants/enums";

export interface ConsultingListItemObject {
  id: string;
  index: number;
}

interface CatalogListItemInstance {
  getNode(): HTMLDivElement | null;
}

interface CatalogListItemProps {
  id: string;
  detail: ConsultingDetail;
  index: number;
  style?: CSSProperties;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
}

const ListItemType = "card";
export const CW_NO = 50;
export const CW_REG_DATE = 80;
export const CW_IMG = 140;
export const CW_PROD_TYPE = 80;
export const CW_BRAND = 80;
export const CW_PROD_NM = 120;
export const CW_MODEL = 120;

const ConsultingMovableItem = forwardRef<HTMLDivElement, CatalogListItemProps>(
  function Card(
    {
      detail,
      index,
      moveCard,
      isDragging,
      connectDragSource,
      connectDropTarget,
      ...rest
    },
    ref
  ) {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);

    const opacity = isDragging ? 0 : 1;
    useImperativeHandle<any, CatalogListItemInstance>(ref, () => ({
      getNode: () => elementRef.current,
    }));

    const { cnslCls, cond1, cond2, cond3 } = detail;
    let condText = "";
    if (cnslCls === INT_CONSULTING_CATEGORY_TRIM) {
        condText = cond3
    }
    else if (cnslCls === INT_CONSULTING_CATEGORY_OPTION) {
        condText = cond2;
    }
    else {
        condText = cond2;
    }

    return (
      <ListItemWrapper
        style={{
          // backgroundColor: isDragging ? "grey" : "white",
          opacity,
        }}
        ref={elementRef}
        {...rest}
      >
        {/* 순위 */}
        <ColumnWrapper width={CW_NO}>{index + 1}</ColumnWrapper>
        {/* 집계 */}
        <ColumnWrapper width={300}>{condText}</ColumnWrapper>

      </ListItemWrapper>
    );
  }
);

export default DropTarget(
  ListItemType,
  {
    hover(
      props: CatalogListItemProps,
      monitor: DropTargetMonitor,
      component: CatalogListItemInstance
    ) {
      if (!component) {
        return null;
      }

      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem<ConsultingListItemObject>().index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = node.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      props.moveCard(dragIndex, hoverIndex);
      monitor.getItem<ConsultingListItemObject>().index = hoverIndex;
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    ListItemType,
    {
      beginDrag: (props: CatalogListItemProps) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(ConsultingMovableItem)
);

ConsultingMovableItem.defaultProps = {};
