export default class DirectSurveyResultDetail {
  // 질문ID
  public qstnId = "";

  // 응답내역
  public replyCntn = "";

  // 계약ID
  public cntrId = "";

  // 계약체결일
  public cntrDt = "";

  // 모델
  public repCarClassNm = "";

  // 세부모델
  public carClassNm = "";

  // 카매니저ID
  public mgntPicId = "";

  // 카매니저명
  public mgntPicNm = "";

  // COSS거래처ID
  public cossCustId = "";

  // COSS거래처명
  public cossCustNm = "";

  // 고객구분코드
  public custClscd = "";

  // 고객구분명
  public custClsNm = "";

  // 고객ID
  public custId = "";

  // 고객명
  public custNm = "";

  // 설문회차 아이디
  public qstnOrdId = "";

  // 답변항목 아이디
  public replyItemSeq = 0;

  // 답변내용(객관식)
  public itemNm = "";

  // 질문명
  public qstnCntn = "";

  // 질문 유형 코드
  public qstnType = "";

  // 질문 유형명
  public qstnTypeNm = "";

  // 답변 텍스트입력 가능 여부
  public txtInsYn = "";

  // 제출일자
  public regDtm = "";
}
