import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_DATE_RANGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import {
  dateFormatRenderer,
  numberFormatRenderer,
} from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";

export const USAGE_STATE_SWITCH_RENDERER = "usageStateSwitchRenderer";

export const createRewardCols = (): Array<TableColumn> => {
  return [
    {
      headerName: "",
      field: "athlete",
      width: 30,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    // 추천자
    createTableCol("recCustNm", "추천고객명", FilterType.Text, {
      width: CW_SMALL_M,
      cellRenderer: "recUserDetail",
    }),
    // 계약자
    createTableCol("custNm", "고객명(계약자)", FilterType.Text, {
      width: CW_SMALL_M,
      cellRenderer: "userDetail",
    }),
    // 가입일
    createTableCol("joinDt", "가입일", FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("joinDt"),
    }),
    // 계약일
    createTableCol("cntrDt", "계약일", FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("cntrDt"),
    }),
    // 계약번호
    createTableCol("cntrId", "계약번호", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 계약상태명
    createTableCol("cntrStsNm", "계약상태명", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 리워드 지급여부
    createTableCol("rewardPayYn", "리워드 지급여부", undefined, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "완료" : "미완료",
          },
        };
      },
    }),
    // 리워드금액
    createTableCol("rewardAmt", "고객 리워드금액", undefined, {
      width: CW_MEDIUM,
      cellRenderer: numberFormatRenderer("rewardAmt"),
    }),
    // 추천자 리워드 금액
    createTableCol("recRewardAmt", "추천자 리워드금액", undefined, {
      width: CW_MEDIUM,
      cellRenderer: numberFormatRenderer("recRewardAmt"),
    }),
    // 리워드지급일
    createTableCol("rewardPayCompleteDt", "리워드 지급완료일시", undefined, {
      width: CW_LARGE,
      cellRenderer: dateFormatRenderer("rewardPayCompleteDt", true),
    }),
    // 리워드지급해지
    createTableCol("rewardPayRvcnDt", "리워드 지급해지일시", undefined, {
      width: CW_LARGE,
      cellRenderer: dateFormatRenderer("rewardPayRvcnDt", true),
    }),
    {
      headerName: "관리",
      field: "content",
      width: 80,
      cellRenderer: "actionButtons",
      pinned: "right",
    },
  ];
};
