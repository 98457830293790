import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { ModalProps } from "../../../../types";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import { createValidationSchema } from "./utils/form-utils";
import {
  FormElementType,
  FormInfo,
} from "../../../../../components/shared/data-entry/FormBuilder/types";
import {
  editElevenCatalogDetail,
  fetchElevenCatalogDetail,
} from "../../../../../apis/cooper-catalogs";
import { ElevenCarCatalogDetail } from "../../../../../models/ElevenCarCatalogDetail";
import { CooperType } from "../../../../../constants/enums";

interface Props extends ModalProps {
  prodId: string;
}

const CooperCarCatalogModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, prodId, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [
    catalogDetail,
    setCatalogDetail,
  ] = useState<ElevenCarCatalogDetail | null>(null);

  let formik: FormikProps<FormikValues>;
  const [initialValues, setInitialValues] = useState<any>();

  const onModalOpen = async () => {
    setDataFetching(true);
    try {
      const detail = await fetchElevenCatalogDetail(prodId);
      if (detail) {
        setCatalogDetail(detail);
        setInitialValues({
          prodId,
          elevenProdId: detail.cooperProdNo ?? "",
        });
      }
    } catch (e) {
      alertError("차량의 노출상태를 먼저 변경해주세요");
      if (onCancel) onCancel();
    } finally {
      setDataFetching(false);
    }
  };

  const createFormInfo = (): FormInfo[] => {
    return [
      {
        key: "prodId",
        type: FormElementType.Input,
        disabled: true,
        label: "상품코드",
        required: false,
        style: { background: "transparent", color: "#000000d9" },
      },
      // 질문
      {
        key: "elevenProdId",
        type: FormElementType.Input,
        label: "11번가 상품코드",
        placeholder: "상품코드를 입력해주세요.",
        required: true,
      },
    ];
  };

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    try {
      await editElevenCatalogDetail(
        values.prodId,
        CooperType.ELEVEN,
        values.elevenProdId
      );
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Render Helpers
   */
  const renderContent = () => {
    if (catalogDetail) {
      return (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={createFormInfo()}
          validationSchema={createValidationSchema()}
          onSubmit={handleSubmit}
        />
      );
    }
    return null;
  };

  return (
    <Modal
      size="small"
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="11번가 상품 관리"
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
      okText="저장"
      style={{ width: 600 }}
    >
      {dataFetching ? renderLoading() : renderContent()}
    </Modal>
  );
};

CooperCarCatalogModal.defaultProps = {
  onCancel: () => {},
  visible: false,
  prodId: "",
};
export default CooperCarCatalogModal;
