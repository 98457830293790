import React, { useState } from "react";
import Input from "../../../../../../../../../components/shared/data-entry/antd/Input";

export interface GridInputProps {
    // grid index
    rowIndex: Number;
    // grid row data
    data: string;
    // grid row changed function
    onChange: (data: string) => void;
    // grid index list
    gridUptIdxList: Number[];
    // set grid index list
    setGridUptIdxList: (gridUptIdxList: Number[]) => void;
};

const GridInput: React.FC<GridInputProps> = (props: GridInputProps) => {

    const { rowIndex, data, onChange, gridUptIdxList, setGridUptIdxList } = props;
    const [value, setValue] = useState(data);

    return (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Input
            value={value}
            size={"small"}
            onChange={(e) => {
              const aftValue = e?.target.value;
              setValue(aftValue); // input value
              onChange(aftValue); // grid value
              if (!gridUptIdxList.some(v => v === rowIndex)) {
                const list: Number[] = gridUptIdxList;
                list.push(rowIndex)
                setGridUptIdxList(list);
              }
            }}
          />
        </div>
    );
}

export default GridInput;