export const NCAR_CLS_FLAG_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "당일배송",
    value: "A45002",
  },
  {
    label: "빠른배송",
    value: "A45003",
  },
  {
    label: "타고페이",
    value: "A45005",
  },
  {
    label: "중고장기-선택형",
    value: "A45006",
  },
  {
    label: "중고장기-반납형",
    value: "A45007",
  },
  {
    label: "중고장기-제주전용",
    value: "A45008",
  },
];

export const SRVC_ID_OPTIONS = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "신차",
    value: "S0001",
  },
  {
    label: "중고차",
    value: "S0002",
  },
];
