import React, { useRef, useState } from "react";
import {
  GridApi,
  ICellRendererParams,
  LoadSuccessParams,
} from "ag-grid-community";
import moment from "moment";
import {
  createNewCarCatalogCols,
  createSwitchRenderer,
} from "./utils/table-utils";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import AucCarCatalogModal from "./pages/AucCarCatalogModal";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import { getErrorMessage } from "../../../utils/common-utils";
import {
  editAucCatalogDisplayState,
  fetchAUCCatalogs,
} from "../../../apis/auc-catalogs";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import {
  getPageIndex,
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import commonStrings from "../../../constants/strings";
import { AUC_COM_CD } from "../../../constants/enums";
import { get1MonthDateRange } from "../../../utils/date-utils";

const AucCarCatalogManager: React.FC = () => {
  const gridApiRef = useRef<GridApi>();
  const [gridApi, setGridApi] = useState<GridApi>();
  const cols = createNewCarCatalogCols();
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string>();
  const [logInfo, setLogInfo] = useState<string>("");

  const getInitialDateRange = () => {
    return get1MonthDateRange();
  };

  const filterInitialValues = {
    stDt: getInitialDateRange(),
    compCd: "",
    carNo: "",
  };

  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "등록일",
    },
    {
      key: "compCd",
      type: FilterElementType.Select,
      options: AUC_COM_CD,
      label: "제휴사",
    },
    {
      key: "carNo",
      type: FilterElementType.Input,
      label: "차량번호",
      placeholder: commonStrings.HINT_CAR_NO,
    },  
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const {
      stDt,
      compCd,
      carNo,
    } = appliedFilterValues;
    const regStDt = moment(stDt[0]).format("YYYYMMDD");
    const regEndDt = moment(stDt[1]).format("YYYYMMDD");

    try {
      const { items, count } = await fetchAUCCatalogs({
        pageSize: paginationPageSize,
        pageIndex: getPageIndex(startRow, paginationPageSize),
        regStDt,
        regEndDt,
        compCd,
        carNo,
      });
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
    } catch (e) {      
      setTotalCount(0);
      success({
        rowData: [],
        rowCount: 0,
      });
    }
  };

  const requestUpdateDisplayState = async (
    prodId: string,
    checked: boolean
  ) => {
    try {
      await editAucCatalogDisplayState(prodId, checked ? "Y" : "N");
      alertSuccess("상품 노출 여부가 변경되었습니다");
      await refreshServerSideTable(gridApiRef.current);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { prodId } = data;
    setSelectedDataId(prodId);
    setModalVisible(true);
  };

  const handleDisplayStateSwitchChange = (
    checked: boolean,
    prodId: string
  ) => {
    showConfirm(
      checked ? "노출상태로 변경" : "숨김상태로 변경",
      checked
        ? "해당상품을 노출상태로 변경하시겠습니까?"
        : "해당상품을 숨김상태로 변경하시겠습니까?",
      async () => {
        await requestUpdateDisplayState(prodId, checked);
      }
    );
  };

  /**
   * Render Helpers
   */

  const createTableActions = () => {
    return [];
  };

  return (
    <>
      <PaginationTableLayout
        tableHeaderTitle=""
        apiRef={(api) => {
          setGridApi(api);
          gridApiRef.current = api;
        }}
        headerTitle="온라인 상품관리(제휴중고차)" 
        headerDescription="제휴중고 렌터카 상품 정보를 관리 합니다."
        totalCount={totalCount}
        hideFilterAction
        hideSelectionCountText
        rightActions={createTableActions()}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        onDataLoad={onDataLoad}
        frameworkComponents={{
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
          ...createSwitchRenderer(handleDisplayStateSwitchChange, "viewYn"),
        }}
        methodName="/admin/auc-catalogs"
        showImportExcel1
        showImportExcelName1="차량업로드"
        saveThenApi1="/admin/auc-catalogs"
        showImportExcel2
        showImportExcelName2="렌탈료업로드"
        saveThenApi2="/admin/auc-catalogs-pricing"
        showExcelTemplate
        showExcelTemplateName="업로드템플릿"
        logInfo={logInfo}
      />
      <AucCarCatalogModal
        onDataChange={async () => {
          await refreshServerSideTable(gridApi);
        }}
        visible={modalVisible}
        dataId={selectedDataId}
        onCancel={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};

AucCarCatalogManager.defaultProps = {};
export default AucCarCatalogManager;
