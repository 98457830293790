import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_SMALL,
  CW_MEDIUM,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { formatNumber } from "../../../../utils/common-utils";

export const createNewCarCatalogCols = (): Array<TableColumn> => {
  return [
    // 계약ID
    createTableCol("cntrId", "계약ID", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 인수요청일자
    createTableCol("tkvReqDt", "인수요청일자", FilterType.Date, {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("tkvReqDt"),
    }),
    // 인수요청
    createTableCol("tkvReqYn", "인수요청여부", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 계약일자
    createTableCol("cntrDt", "계약일자", FilterType.Date, {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("cntrDt"),
    }),
    // 고객명
    createTableCol("custNm", "계약자명", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 차량번호
    createTableCol("carNo", "차량번호", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 모델
    createTableCol("repCartypeNm", "모델", FilterType.Text, {
      width: 250,
    }),
    // 트림
    createTableCol("carGradeNm", "트림", FilterType.Text, {
      width: 250,
    }),
    // 연료명
    createTableCol("fuelNm", "연료명", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 차량가격    
    createTableCol("totCarAmt", "차량가격", FilterType.Text, {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.totCarAmt)}`;
      },
    }),
    // 판매유형
    createTableCol("ncarClsFlagNm", "판매유형", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 바로인수가    
    createTableCol("carTkvAmt", "바로인수가", FilterType.Text, {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.carTkvAmt)}`;
      },
    }),
    // 월 렌탈료(부가세포함)    
    createTableCol("mmRentAmtV", "월 렌탈료(부가세포함)", FilterType.Text, {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.mmRentAmtV)}`;
      },
    }),
    // 약정주행거리
    createTableCol("prmsDtcClsNm", "약정주행거리", FilterType.Text, {
      width: CW_MEDIUM,
    }),
  ];
};
