import { createReducer } from "typesafe-actions";
import { setUser } from "../../actions/auth";
import User from "../../../models/User";

export type AuthState = {
  token?: string;
  user?: User;
};

const initialState: AuthState = {
  token: "",
  user: undefined,
};

const authReducer = createReducer(initialState).handleAction(
  setUser,
  (state, action) => ({
    ...state,
    user: action.payload,
  })
);

export default authReducer;
