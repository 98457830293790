import React, { useState } from "react";
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import { ColumnApi, ExcelCell, GridApi } from "ag-grid-community";
import { ReactSVG } from "react-svg";
import { Pagination } from "antd";
import { FormikProps, FormikValues } from "formik";
import {
  TableLayoutWrapper,
  TableFooter,
  HighLightText,
  TableHeaderWrapper,
  TableHeaderTitleWrapper,
  RightPanelWrapper,
  TableContentWrapper,
  AddStatisticsWrapper,
} from "./style";
import TableDataActionButton from "../TableDataActionButton";
import Button, { ButtonProps } from "../../general/antd/Button";
import excelIcon from "../../../../assets/svgs/excel-btn.svg";
import filterResetIcon from "../../../../assets/svgs/filter-reset.svg";
import { DEFAULT_GRID_LIMIT } from "../../../../constants/values";
import ServerRowTable from "../../data-display/Tables/components/ServerRowTable";
import ContentLayout from "../../layout/ContentLayout";
import TableColumn from "../../data-display/Tables/model/TableColumn";
import {
  applyFilterValues,
  refreshServerSideTable,
} from "../../../../utils/ag-grid-utils";
import { FilterFormInfo } from "../../layout/TableFilterLayout/types";
import { alertError, alertSuccess } from "../../../../utils/render-utils";
import Modal from "../../feedback/antd/Modal";
import FormBuilder from "../../data-entry/FormBuilder";
import { FormElementType, FormInfo } from "../../data-entry/FormBuilder/types";
import { setDownloadLogs } from "../../../../apis/logs";
import useAuth from "../../../../hooks/useAuth";
import { getErrorMessage } from "../../../../utils/common-utils";
import ExcelUploadModal from "../ExcelUploadModal";
import ShowTemplateModal from "../ShowTemplateModal";

interface TableHeaderAction extends ButtonProps {
  title: string;
  onClick?: () => void;
  disabled?: boolean;
}

interface PaginationTableLayoutProps {
  excelAppendRows?: ExcelCell[][];
  // Table 컬럼 정보
  cols: Array<TableColumn>;

  // Table 헤더 좌측 액션
  leftActions?: Array<TableHeaderAction>;

  // Table 헤더 우측 액션
  rightActions?: Array<TableHeaderAction>;

  // Table Column 커스텀뷰 정
  frameworkComponents?:
    | {
        [p: string]: {
          new (): any;
        };
      }
    | any;

  totalCount: number;
  tableFooterContents?: React.ReactNode;
  selectionCount?: number;
  hideSelectionCountText?: boolean;
  hideFilterAction?: boolean;

  // 필터 초기값
  filterInitialValues?: any;

  // 필터 폼 정보
  filterForms?: Array<FilterFormInfo>;

  // 테이블 데이터 요청 API 함수 연결
  onDataLoad: (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    filterValues: any,
    paginationPageSize: number
  ) => void;
  onCheckRowChanged?: (data: Array<any>) => void;

  // 관리 헤더 타이틀
  headerTitle: string;

  // 관리 헤더 디스크립션
  headerDescription?: string;

  // 테이블 목록 타이블
  tableHeaderTitle: string;

  // GridApi 레퍼런스
  apiRef?: (api: GridApi) => void;

  filterable?: boolean;

  defaultLimit?: number;

  hidePagination?: boolean;

  addStatistics?: { title: string; component: React.ReactNode };

  methodName?: string;

  showImportExcel1?: boolean;
  showImportExcelName1?: string;
  saveThenApi1?: string;

  showImportExcel2?: boolean;
  showImportExcelName2?: string;
  saveThenApi2?: string;

  showExcelTemplate?: boolean;
  showExcelTemplateName?: string;

  rowSelection?: string;

  // 로그 정보
  logInfo?: string;
}

const PaginationTableLayout: React.FC<PaginationTableLayoutProps> = (
  props: PaginationTableLayoutProps
) => {
  const {
    totalCount = 0,
    tableFooterContents,
    selectionCount,
    leftActions,
    rightActions = [],
    hideSelectionCountText,
    hideFilterAction,
    filterInitialValues,
    onDataLoad,
    onCheckRowChanged,
    cols,
    frameworkComponents,
    filterForms,
    tableHeaderTitle,
    headerTitle,
    headerDescription,
    apiRef,
    filterable = true,
    defaultLimit = DEFAULT_GRID_LIMIT,
    hidePagination = false,
    addStatistics,
    methodName,
    showImportExcel1,
    showImportExcelName1,
    saveThenApi1,
    showImportExcel2,
    showImportExcelName2,
    saveThenApi2,
    showExcelTemplate,
    showExcelTemplateName,
    rowSelection,
    logInfo
  } = props;

  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColApi, setGridColApi] = useState<ColumnApi>();
  const [currentPage, setCurrentPage] = useState<number>();
  const [limit, setLimit] = useState(defaultLimit);
  const [filterValues, setFilterValues] = useState(filterInitialValues);
  const [downloadReason, setDownloadReason] = useState("");
  const [isVisbledownloadReason, setIsVisbledownloadReason] = useState(false);

  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [saveThenApi, setSaveThenApi] = useState("");

  const { user } = useAuth();

  let formik: FormikProps<FormikValues>;

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  const handlePaginationChange = (page: number) => {
    if (gridApi) {
      gridApi.paginationGoToPage(page - 1);
    }
  };

  const handleGridPaginationCallback = () => {
    if (gridApi) setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
  };

  const handlePaginationSizeChange = (current: number, pageSize: number) => {
    setLimit(pageSize);
    if (gridApi) setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
    refreshServerSideTable(gridApi);
  };

  const handleSearchSubmit = (values: any) => {
    setFilterValues(values);
    if (gridApi) applyFilterValues(values, gridApi);
  };

  const resetFilter = () => {
    if (gridApi) {
      gridApi.setFilterModel(null);
    }
  };

  /*
  const generateColumnsForExcel = (): string[] | undefined => {
    if (gridColApi) {
      const keys = gridColApi
        .getAllDisplayedColumns()
        .map((column) => column.getColId());

      const amountIndex: number = keys.findIndex(
        (column) => column === "amount"
      );
      keys.splice(amountIndex + 1, 0, "currency");

      return keys;
    }
  };
  */

  const importExcel1 = () => {
    setSaveThenApi(saveThenApi1 as string);
    setFileUploadModal(true);
  };

  const importExcel2 = () => {
    setSaveThenApi(saveThenApi2 as string);
    setFileUploadModal(true);
  };

  const showTemplate = () => {
    setShowTemplateModal(true);
  };

  const exportExcel = () => {
    setDownloadReason("");
    setIsVisbledownloadReason(true);
  };

  /**
   * Render Helpers
   */
  const renderHeader = () => {
    return (
      <TableHeaderWrapper>
        <TableHeaderTitleWrapper>{tableHeaderTitle}</TableHeaderTitleWrapper>
        <RightPanelWrapper>
          <>
            {leftActions &&
              leftActions.map(({ title, type = "primary", ...rest }) => {
                return (
                  <TableDataActionButton
                    type={type}
                    key={title}
                    style={{
                      marginRight: "8px",
                    }}
                    {...rest}
                  >
                    {title}
                  </TableDataActionButton>
                );
              })}
            {showImportExcel1 && (
              <Button
                type="default"
                style={{
                  marginRight: "8px",
                }}
                onClick={importExcel1}
              >
                {showImportExcelName1}
              </Button>
            )}
            {showImportExcel2 && (
              <Button
                type="default"
                style={{
                  marginRight: "8px",
                }}
                onClick={importExcel2}
              >
                {showImportExcelName2}
              </Button>
            )}
            {showExcelTemplate && (
              <Button
                type="default"
                style={{
                  marginRight: "8px",
                }}
                onClick={showTemplate}
              >
                {showExcelTemplateName}
              </Button>
            )}
            <Button
              type="default"
              icon={<ReactSVG src={excelIcon} />}
              onClick={exportExcel}
            />
            {!hideFilterAction && (
              <Button
                type="default"
                icon={<ReactSVG src={filterResetIcon} />}
                style={{
                  marginLeft: "8px",
                }}
                onClick={resetFilter}
              />
            )}
            {rightActions &&
              rightActions.map(({ title, type = "primary", ...rest }) => {
                return (
                  <TableDataActionButton
                    type={type}
                    key={title}
                    style={{
                      marginLeft: "8px",
                    }}
                    {...rest}
                  >
                    {title}
                  </TableDataActionButton>
                );
              })}
          </>
        </RightPanelWrapper>
      </TableHeaderWrapper>
    );
  };

  const createFormInfo: () => FormInfo[] = () => [
    {
      key: "reason",
      type: FormElementType.TextArea,
      label: "사유",
      placeholder: "사유를 입력해주세요",
      required: true,
      onChange: (value) => {
        setDownloadReason(value?.trim());
      },
    },
  ];

  const handleSubmit = async (values: FormikValues) => {
    if (!downloadReason) {
      alertError("사유를 입력해주세요.");

      return;
    }

    try {
      if (gridApi) {
        const cacheState = gridApi.getCacheBlockState();
        if (cacheState) {
          let pageLoaded = true;
          Object.keys(cacheState).forEach((key) => {
            const cacheInfo = cacheState[key];
            if (cacheInfo && cacheInfo.pageStatus !== "loaded") {
              pageLoaded = false;
            }
          });
          if (!pageLoaded) {
            alertError(
              "페이지를 불러오는중 입니다. 잠시 후 다시 시도해주세요."
            );
          } else {
            await setDownloadLogs({
              userId: user.userId,
              userIp: user.clientIp,
              methodNm: methodName || "",
              menuName: headerTitle,
              downRsn: downloadReason,
              reqCntn: logInfo
            });
            gridApi.exportDataAsExcel();
          }
        }
      }
    } catch (e) {
      alertError(getErrorMessage(e));
    }

    setIsVisbledownloadReason(false);
  };

  return (
    <>
      <ContentLayout
        headerTitle={headerTitle}
        description={headerDescription}
        filterable={filterable}
        filterInitialValues={filterInitialValues}
        filterValues={filterValues}
        filterForms={filterForms}
        onSearchSubmit={handleSearchSubmit}
      >
        {addStatistics && (
          <AddStatisticsWrapper>
            <header>
              <h2>{addStatistics.title}</h2>
            </header>
            <main>{addStatistics.component}</main>
          </AddStatisticsWrapper>
        )}
        <TableLayoutWrapper>
          {renderHeader()}
          <TableContentWrapper>
            <ServerRowTable
              paginationPageSize={limit}
              apiRef={(api) => {
                setGridApi(api);
                if (apiRef) apiRef(api);
              }}
              colApiRef={(ref) => {
                setGridColApi(ref);
              }}
              filterInitialValues={filterInitialValues}
              onDataLoad={onDataLoad}
              onCheckRowChanged={onCheckRowChanged}
              floatingFilter={false}
              cols={cols}
              frameworkComponents={frameworkComponents}
              onPaginationChanged={handleGridPaginationCallback}
              rowSelection={rowSelection ?? "single"}
            />

            <TableFooter>
              <div>
                총<HighLightText>{totalCount}</HighLightText>건
              </div>
              {!hideSelectionCountText && (
                <div
                  style={{
                    marginLeft: "16px",
                  }}
                >
                  선택<HighLightText>{selectionCount}</HighLightText>
                </div>
              )}
              {tableFooterContents}
              {!hidePagination && (
                <div
                  style={{
                    flex: 1,
                    textAlign: "right",
                  }}
                >
                  <Pagination
                    pageSizeOptions={["10", "20", "50", "100", "500", "1000"]}
                    pageSize={limit}
                    onShowSizeChange={handlePaginationSizeChange}
                    current={currentPage}
                    total={totalCount}
                    onChange={handlePaginationChange}
                    showSizeChanger
                  />
                </div>
              )}
            </TableFooter>
          </TableContentWrapper>
        </TableLayoutWrapper>
      </ContentLayout>
      <Modal
        size="small"
        title="엑셀 다운로드 사유 입력"
        visible={isVisbledownloadReason}
        onCancel={() => {
          setIsVisbledownloadReason(false);
        }}
        onOk={() => {
          formik.handleSubmit();
        }}
        okButtonProps={{ disabled: downloadReason.length < 10 }}
        description="최소 10글자 이상 입력하셔야 합니다."
      >
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={{ reason: downloadReason }}
          forms={createFormInfo()}
          onSubmit={handleSubmit}
        />
      </Modal>
      <ExcelUploadModal
        visible={fileUploadModal}
        saveThenApi={saveThenApi}
        onCancel={() => {
          setFileUploadModal(false);
        }}
      />
      <ShowTemplateModal
        visible={showTemplateModal}
        onCancel={() => {
          setShowTemplateModal(false);
        }}
      />
    </>
  );
};

PaginationTableLayout.defaultProps = {
  totalCount: 0,
  selectionCount: 0,
  leftActions: [],
  rightActions: [],
  hideSelectionCountText: true,
};
export default PaginationTableLayout;
