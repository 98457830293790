import React from "react";
import { IFilterComp } from "ag-grid-community/dist/lib/interfaces/iFilter";
import Input from "../../../../../data-entry/antd/Input";

interface TextFilterProps {
  onFloatingFilterChanged(type: string, value: any): void;
  parentFilterInstance: (
    callback: (filterInstance: IFilterComp) => void
  ) => void;
}

class TextFilter extends React.Component<
  TextFilterProps,
  { filterText: string }
> {
  state = { filterText: "" };

  /**
   * Event Actions
   */

  // ag-grid callback
  onParentModelChanged = (parentModel: any) => {
    if (!parentModel) {
      this.setState({
        filterText: "",
      });
    }
  };

  /**
   * Render Helpers
   */

  render() {
    const { parentFilterInstance } = this.props;
    const { filterText } = this.state;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Input
          value={filterText}
          size={"small"}
          onChange={(e) => {
            const { target } = e;
            const { value } = target;
            this.setState({
              filterText: value,
            });
            parentFilterInstance((filterInstance) => {
              // @ts-ignore
              filterInstance.onFloatingFilterChanged("contains", value);
            });
          }}
        />
      </div>
    );
  }
}
export default TextFilter;
