import React from "react";
import { Route, Switch, RouteProps } from "react-router-dom";
import ErrorPage from "../../../pages/common/Error";

interface ErrorRouteProps extends RouteProps {}

const ErrorRoute: React.FC<ErrorRouteProps> = (props: ErrorRouteProps) => {
  const { path, ...rest } = props;

  return (
    <Switch>
      <Route
        {...rest}
        path="/400"
        exact
        render={(routerProps) => (
          <ErrorPage code="400" detail="Bad Request" {...routerProps} />
        )}
      />
      <Route
        {...rest}
        path="/401"
        render={(routerProps) => (
          <ErrorPage code="401" detail="Unauthorized" {...routerProps} />
        )}
      />
      <Route
        {...rest}
        path="/403"
        render={(routerProps) => (
          <ErrorPage code="403" detail="Forbidden" {...routerProps} />
        )}
      />
      <Route
        {...rest}
        path="/405"
        render={(routerProps) => (
          <ErrorPage code="405" detail="Method Not Allowed" {...routerProps} />
        )}
      />
      <Route
        {...rest}
        path="/500"
        render={(routerProps) => (
          <ErrorPage
            code="500"
            detail="Internal Server Error"
            {...routerProps}
          />
        )}
      />
      <Route
        {...rest}
        path="/503"
        render={(routerProps) => (
          <ErrorPage code="503" detail="Service Unavailable" {...routerProps} />
        )}
      />
      <Route
        {...rest}
        path="*"
        render={(routerProps) => <ErrorPage code="404" {...routerProps} />}
      />
    </Switch>
  );
};

ErrorRoute.defaultProps = {};
export default ErrorRoute;
