import React, { CSSProperties } from "react";
import { LogoBox } from "./style";

export interface LogoProps {
  style?: CSSProperties;
}

const Logo: React.FC<LogoProps> = (props: LogoProps) => {
  const { style = {} } = props;
  return <LogoBox style={{ ...style }} />;
};

Logo.defaultProps = {};
export default Logo;
