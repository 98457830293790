import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import { createCols } from "./utils/table-utils";
import { get1MonthDateRange } from "../../../utils/date-utils";
import { alertError } from "../../../utils/render-utils";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import TableLayout from "../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createUserDetailRenderer } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import { fetchConsultingData } from "../../../apis/intlcnsl";
import ConsultingMaster from "../../../models/ConsultingData/ConsultingMaster";
import ConsultingDataDetailModal from "./pages/ConsultingDataDetailModal";

const ConsultingDataManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rows, setRows] = useState<Array<ConsultingMaster> | undefined>(
    undefined
  );
  const [totalCount, setTotalCount] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<ConsultingMaster>();

  const cols = createCols();

  const filterInitialValues = {
    stDt: get1MonthDateRange(),
  };

  useEffect(() => {
    (async function fetchData() {
      await requestDataList();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestDataList = async () => {
    try {
      const consultingInfo = await fetchConsultingData();
      const { mstList, mstListCnt } = consultingInfo;
      setRows(mstList);
      setTotalCount(mstListCnt);
    } catch (e) {
      if (e.status !== 404) {
        if (e.getMessage) alertError(e.getMessage());
        else alertError(e.toString());
      }
      setRows([]);
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const master = data as ConsultingMaster;
    setSelectedData(master);
    setModalVisible(true);
  };

  const handleUserDetailCellClick = (custId?: string) => {
    alertError(`custId 필요 ${custId}`);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout
        headerTitle="지능형 상담"
        filterInitialValues={filterInitialValues}
      >
        <TableLayout
          gridApi={gridApi}
          title="지능형 상담 집계 리스트"
          totalCount={totalCount}
          hideSelectionCountText
          methodName="/admin/custcnte/intlcnsl"
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            cols={cols}
            frameworkComponents={{
              ...createUserDetailRenderer(handleUserDetailCellClick),
              ...createActionCellRenderers(undefined, handleDetailClick, {
                hideDeleteButton: true,
              }),
            }}
          />
        </TableLayout>
      </ContentLayout>
      <ConsultingDataDetailModal
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        consultingMaster={selectedData}
        onDataChange={async () => {
          await requestDataList();
        }}
      />
    </>
  );
};

ConsultingDataManager.defaultProps = {};
export default ConsultingDataManager;
