import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import {
  createCarManagerParam,
  createFormInfo,
  createInitialValues,
  createInitialValuesFromCarManager,
  createValidationSchema,
} from "./utils/form-utils";
import {
  renderLoading,
  alertError,
  alertSuccess,
} from "../../../../../utils/render-utils";
import {
  editCarManager,
  fetchCarManagerDetail,
} from "../../../../../apis/car-managers";
import { ModalProps } from "../../../../types";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { FormOption } from "../../../../../components/shared/data-entry/FormBuilder/types";

interface Props extends ModalProps {
  carmanId?: string;
}

const CarManagerEditModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, carmanId } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );
  let formik: FormikProps<FormikValues>;
  const [carManSiteList, setCarManSiteList] = useState<Array<FormOption>>([]);

  const onModalOpen = async () => {
    if (carmanId) {
      await requestFetchCarManagerDetail(carmanId);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchCarManagerDetail = async (carmanId: string) => {
    setDataFetching(true);
    try {
      const carManagerDetail = await fetchCarManagerDetail(carmanId);
      setCarManSiteList(
        carManagerDetail.carManSiteList.map(({ siteCd, siteNm }) => {
          return {
            label: siteNm,
            value: siteCd,
          };
        })
      );
      setInitialValues(createInitialValuesFromCarManager(carManagerDetail));
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  const requestEditCarManager = async (values: FormikValues) => {
    if (carmanId) {
      setConfirmLoading(true);
      try {
        await editCarManager(carmanId, createCarManagerParam(values));
        alertSuccess("카매니저 정보가 변경되었습니다");
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
      } catch (e) {
        if (e.getMessage) alertError(e.getMessage());
        else {
          alertError(e.toString());
        }
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    await requestEditCarManager(values);
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="카매니저 수정"
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={createFormInfo(carManSiteList)}
          validationSchema={createValidationSchema()}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

CarManagerEditModal.defaultProps = {};
export default CarManagerEditModal;
