import React, { useState } from "react";
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import { createActionCellRenderers } from "../../../utils/table-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import {
  getPageIndex,
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import { createSMSTemplateCols } from "./utils/table-utils";
import { fetchSMSTemplates } from "../../../apis/sms-template";
import SMSTemplate from "../../../models/SMSTemplate";
import SMSTemplateFormModal from "./pages/SMSTemplateFormModal";

const SMSTemplateManager: React.FC = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>();
  const cols = createSMSTemplateCols();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [logInfo, setLogInfo] = useState<string>("");

  const filterInitialValues = {
    searchText: "",
  };

  const filterForms = [
    {
      key: "searchText",
      type: FilterElementType.Input,
      label: "템플릿명",
      placeholder: "템플릿명을 입력해주세요",
    },
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const { searchText } = appliedFilterValues;

    try {
      const { items, count } = await fetchSMSTemplates(
        getPageIndex(startRow, paginationPageSize),
        paginationPageSize,
        searchText
      );
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
    } catch (e) {
      if (e.status !== 404) {
        setTotalCount(0);
        success({
          rowData: [],
          rowCount: 0,
        });
      }
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const template = data as SMSTemplate;
    setSelectedDataId(template.smsMsgId);
    setModalVisible(true);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <PaginationTableLayout
        rightActions={[
          {
            title: "신규등록",
            onClick: () => {
              setSelectedDataId(undefined);
              setModalVisible(true);
            },
          },
        ]}
        apiRef={(ref) => {
          setGridApi(ref);
        }}
        headerTitle="문자전송 템플릿 관리"
        tableHeaderTitle="문자전송 템플릿 리스트"
        onDataLoad={onDataLoad}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        totalCount={totalCount}
        hideFilterAction
        frameworkComponents={{
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
        }}
        methodName="/admin/sms-templates"
        logInfo={logInfo}
      />
      <SMSTemplateFormModal
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        templateId={selectedDataId}
        onDataChange={() => {
          refreshServerSideTable(gridApi);
        }}
      />
    </>
  );
};

SMSTemplateManager.defaultProps = {};
export default SMSTemplateManager;
