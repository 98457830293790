import moment from "moment";

class NewCarDeliveryTermInfo {
  // 납기기간ID
  public dlvTermId = "";

  // 온라인 차량 등급ID
  public modeGrdId = "";

  // 적용시작일
  public apyStDt = "";

  // 적용종료일
  public apyEndDt = "";

  // 납기 기간
  public dlvTerm = 0;

  // 사용여부
  public useYn = "";

  // NICE 등급 ID
  public carGradeNbr = "";

  // 브렌드명
  public brandNm = "";

  // 대표차종명
  public repCarClassNm = "";

  // 차종명
  public carClassNm = "";

  // NICE 등급명
  public carGradeNm = "";

  // 연료
  public fuel = "";

  // COSS_연료명 <- 관리자표시
  public cossFuelNm = "";

  // 연식
  public yearType = "";

  // 상품ID
  public prodId = "";

  // 상품명
  public prodNm = "";

  // 만료여부
  public expireYn = "";

  // 수정사용자
  public updUser = "";

  // 수정일시
  public updDtm = "";

  // 입력사용자
  public regUser = "";

  // 입력일시
  public regDtm = "";

  get isActive() {
    const start = moment(this.apyStDt);
    const end = moment(this.apyEndDt);

    const current = moment();

    if (start && !this.apyEndDt) return "Y";

    if (current.isBetween(start, end)) return "Y";

    return "N";
  }
}
export default NewCarDeliveryTermInfo;
