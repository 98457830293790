import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";

export const createCols = (): Array<TableColumn> => {
  return [
    // 상담구분명
    createTableCol("cnslClsNm", "상담구분", FilterType.Text, {
      minWidth: CW_SMALL_M,
      flex: 1,
      // cellRenderer: dateFormatRenderer("changeReqDt"),
    }),
    // 차종
    // createUserDetailButtonCol(),
    createTableCol("cartypeNm", "차종", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
    }),
    // 노출 딜레이(초)
    createTableCol("delay", "노출 딜레이(초)", FilterType.Text, {
      width: CW_MEDIUM,
      valueGetter: ({ data }) => {
        return Number(data.delay) / 1000;
      },
      cellStyle: {
        textAlign: "right",
      },
    }),
    // 설명
    createTableCol("descr", "설명", FilterType.Text, {
      width: CW_SMALL,
      flex: 1,
    }),
    // 노출방식
    createTableCol("viewWay", "노출방식", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
    }),
    // 자동 업데이트여부
    createTableCol("autoYn", "자동 업데이트", FilterType.Text, {
      width: CW_MEDIUM,
      valueGetter: ({ data }) => {
        return data.autoYn === "Y" ? "자동" : "수동";
      },
    }),
    {
      ...createActionCol(80),
    },
  ];
};
