import styled from "styled-components";

export const CalendarWrapper = styled.div`
  margin: 24px;
  background-color: white;
  padding: 24px;

  .fc-daygrid-dot-event {
    display: flex;
    align-items: baseline;
    padding: 2px 0;
  }

  .fc-event-title {
    white-space: pre-wrap;
    text-align: left;
  }

  .fc-daygrid-day-number {
    color: #0a0c1c;
  }
  .fc-scrollgrid-sync-inner {
    text-align: right;
  }
  .fc-col-header-cell-cushion {
    color: #0a0c1c;
  }
  .fc-daygrid-day {
    border: 1px solid ${(props) => props.theme.common.colors.border};
  }
  .fc-scrollgrid {
    border: none;
  }
  .fc-day-sun {
    background-color: #fafafc;
  }
  .fc-day-sat {
    background-color: #fafafc;
  }
  .fc-day-today {
    background-color: white !important;
  }

  .fc-day-today .fc-daygrid-day-number {
    color: white;
    font-weight: bold;
    background-color: ${(props) => props.theme.common.colors.point};
  }

  .fc-scrollgrid-section {
    border: none !important;
  }

  .fc-scrollgrid-section > td {
    border: none;
  }

  .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
    top: -22px;
  }
`;
