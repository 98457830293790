import React from "react";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_SMALL,
  CW_MEDIUM,
  createActionCol,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { formatNumber } from "../../../../utils/common-utils";
import { ICellRendererParams } from "ag-grid-community";
import Button from "../../../../components/shared/general/antd/Button";

export const AR_DISPLAY_STATE_SWITCH = "displayStateSwitchRenderer";

const renderSwitch = (
  onChange: (cntrId: string, cntrReqSts: string) => void
) => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    const { cntrId, cntrReqSts } = data;
    if(cntrReqSts === "A61001") {
      return (
        <Button
          onClick={() => {
            onChange(cntrId, cntrReqSts);
          }}
        >반려처리</Button>
      );
    } else {
      return "반려불가";
    }    
  };
};

export const createSwitchRenderer = (
  onChange: (cntrId: string, cntrReqSts: string) => void,
  key = "cntrReqSts"
) => ({
  [AR_DISPLAY_STATE_SWITCH]: renderSwitch(onChange),
});

export const createNewCarCatalogCols = (): Array<TableColumn> => {
  return [
    // 계약ID
    createTableCol("cntrId", "계약ID", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 상품ID
    createTableCol("prodId", "상품ID", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 제휴사명
    createTableCol("compNm", "제휴사명", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 차량번호
    createTableCol("carNo", "차량번호", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 제조사
    createTableCol("brandNm", "제조사", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 모델
    createTableCol("carClassNm", "모델", FilterType.Text, {
      width: 400,
    }),
    // 연식
    createTableCol("yearType", "연식", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 차량등록일
    createTableCol("carRegDtm", "차량등록일", FilterType.Date, {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("carRegDtm"),
    }),
    // 표준 렌탈료    
    createTableCol("rglRentAmt", "표준 렌탈료", FilterType.Text, {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: ({ data }) => {
        return `${formatNumber(data.rglRentAmt)}`;
      },
    }),
    // 계약요청 상태
    createTableCol("cntrReqStsNm", "계약상태", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 계약반려처리
    createTableCol("cntrReqSts", "계약반려처리", FilterType.Text, {
      width: CW_MEDIUM,
      cellRenderer: AR_DISPLAY_STATE_SWITCH,
    }),
    {
      ...createActionCol(80),
    },
  ];
};

