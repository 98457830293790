import React from "react";
import { Checkbox as AntCheckbox } from "antd";

import { CheckboxGroupProps as AntCheckboxGroupProps } from "antd/lib/checkbox/Group";

interface CheckboxGroupProps extends AntCheckboxGroupProps {}

const CheckboxGroup: React.FC<CheckboxGroupProps> = (
  props: CheckboxGroupProps
) => {
  const { style, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <AntCheckbox.Group
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

CheckboxGroup.defaultProps = {};
export default CheckboxGroup;
