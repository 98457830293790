import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { BannerAddParam, BannerEditParam } from "./types";
import BannerGroup from "../../models/BannerGroup";
import BannerDetail from "../../models/BannerDetail";
import BannerInfo from "../../models/BannerDetail/BannerInfo";

const apiRootUrl = `${ROOT_URL}/admin/banners`;

/**
 * 배너 리스트 조회
 */
export const fetchBanners = async (): Promise<
  ListItemResponse<BannerGroup>
> => {
  const res = await request(HttpMethod.Get, apiRootUrl);

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(BannerGroup, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 배너 상세 조회
 */

export const fetchBannerDetail = async (
  bnnrId: string
): Promise<BannerDetail> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/${bnnrId}`);
  const { data } = res;
  const { response } = data;

  const converted = {
    webListCnt: 0,
    webList: [],
    tabletListCnt: 0,
    tabletList: [],
    mobileListCnt: 0,
    mobileList: [],
  };

  response.forEach((bannerInfo: any) => {
    if (bannerInfo.webList) {
      converted.webListCnt = bannerInfo.webListCnt || 0;
      converted.webList = bannerInfo.webList.sort(
        (a: BannerInfo, b: BannerInfo) => {
          if (a.bnnrTitleSeq > b.bnnrTitleSeq) return 1;

          return 0;
        }
      );
    }

    if (bannerInfo.mobileList) {
      converted.mobileListCnt = bannerInfo.mobileListCnt || 0;
      converted.mobileList = bannerInfo.mobileList.sort(
        (a: BannerInfo, b: BannerInfo) => {
          if (a.bnnrTitleSeq > b.bnnrTitleSeq) return 1;

          return 0;
        }
      );
    }

    if (bannerInfo.tabletList) {
      converted.tabletList = bannerInfo.tabletList.sort(
        (a: BannerInfo, b: BannerInfo) => {
          if (a.bnnrTitleSeq > b.bnnrTitleSeq) return 1;

          return 0;
        }
      );
      converted.tabletListCnt = bannerInfo.tabletListCnt || 0;
    }
  });

  return plainToClass(BannerDetail, converted);
};

/**
 * 배너 등록
 */
export const addBanner = async (
  bnnrId: string,
  bnnrDmnId: string,
  params: BannerAddParam
) => {
  await request(HttpMethod.Post, apiRootUrl, undefined, {
    ...params,
    bnnrId,
    bnnrDmnId,
  });
};

/**
 * 배너 수정
 */

export const editBanner = async (bnnrSeq: string, params: BannerEditParam) => {
  const url = `${apiRootUrl}/${bnnrSeq}`;
  await request(HttpMethod.Post, url, undefined, {
    ...params,
  });
};

/**
 * 배너 사용 유무 수정
 */

export const editBannerUseYn = async (bnnrSeq: string, useYn: string) => {
  const url = `${apiRootUrl}/${bnnrSeq}/${useYn}`;
  await request(HttpMethod.Post, url);
};
