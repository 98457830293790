import React from "react";
import { Wrapper } from "./style";

export interface ContentProps {
  children?: React.ReactNode | React.FC;
}

const Content: React.FC<ContentProps> = (props: ContentProps) => {
  const { children } = props;
  return <Wrapper>{children}</Wrapper>;
};

Content.defaultProps = {};
export default Content;
