import strings from "../constants/strings";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import { formatDateString } from "../../../../utils/date-utils";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";
import { rateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

export const createGradeRateCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", strings.COL_REG_DATE, undefined, {
      width: CW_SMALL,
      valueGetter: (params) => {
        const { data } = params;
        const { regDtm } = data;
        if (regDtm) return formatDateString(regDtm);
        return "";
      },
    }),
    // 적용 시작년도
    createTableCol("apyYr", "적용시작년도", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 유효기간
    createTableCol("dateRange", "유효기간", undefined, {
      width: CW_LARGE,
      valueGetter: (params) => {
        const { data } = params;
        const { apyStDt, apyEndDt } = data;
        return `${formatDateString(apyStDt)} ~ ${formatDateString(apyEndDt)}`;
      },
    }),
    // 만료유무
    createTableCol("isActive", "만료유무", undefined, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "유효" : "만료",
          },
        };
      },
    }),
    // 계약유형
    createTableCol("custClsNm", "계약유형", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 1등급
    createTableCol("grd1", "1등급", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: rateFormatRenderer("grd1"),
    }),
    // 2등급
    createTableCol("grd2", "2등급", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: rateFormatRenderer("grd2"),
    }),
    // 3등급
    createTableCol("grd3", "3등급", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: rateFormatRenderer("grd3"),
    }),
    // 4등급
    createTableCol("grd4", "4등급", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: rateFormatRenderer("grd4"),
    }),
    // 5등급
    createTableCol("grd5", "5등급", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: rateFormatRenderer("grd5"),
    }),
    // 6등급
    createTableCol("grd6", "6등급", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: rateFormatRenderer("grd6"),
    }),
    // 7등급
    createTableCol("grd7", "7등급", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: rateFormatRenderer("grd7"),
    }),
    {
      ...createActionCol(80),
    },
  ];
};
