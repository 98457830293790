import React, { useState } from "react";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import {
  formatCurrency,
  getErrorMessage,
} from "../../../../../utils/common-utils";
import {
  fetchCossCarInfoDetail,
  unlinkNiceCarInfo,
} from "../../../../../apis/coss-car-infos";
import TabLayout from "../../../../../components/shared/layout/TabLayout";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import Button from "../../../../../components/shared/general/antd/Button";
import StatusCellText from "../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/StatusCellText";
import OptionInfoFormModal from "../OptionInfoFormModal";
import NiceCarInfoConnectModal from "../NiceCarInfoConnectModal";
import CossMaster from "../../../../../models/CossCarInfo/CossMaster";
import CossCarInfoDetail, {
  CossOptionDetail,
} from "../../../../../models/CossCarInfo/CossCarInfoDetail";
import {
  getNiceBrand,
  getNiceCarPrice,
  getNiceTrim,
  getNiceYear,
} from "./utils";
import { showConfirm } from "../../../../../components/shared/feedback/Confirm";
import ColorConnectionEditModal from "../ColorConnectionEditModal";
import Image from "../../../../../components/shared/data-display/antd/Image";

interface Props extends ModalProps {
  cossMaster?: CossMaster;
}

const CarInfoDetailModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, cossMaster, onDataChange } = props;
  const [carInfoDetail, setCarInfoDetail] = useState<CossCarInfoDetail>();
  const [dataFetching, setDataFetching] = useState<boolean>(false);
  const [colorEditModalVisible, setColorEditModalVisible] = useState(false);

  const [cossConnectionModalVisible, setCossConnectionModalVisible] = useState(
    false
  );
  const [optionUpdateModalVisible, setOptionUpdateModalVisible] = useState(
    false
  );
  const [selectedOptionInfo, setSelectedOptionInfo] = useState<
    CossOptionDetail
  >();

  /**
   * Private Functions
   */
  const onModalOpen = async () => {
    if (cossMaster) {
      await requestFetchCarInfoDetail(cossMaster.modlId);
    } else {
      setCarInfoDetail(undefined);
    }
  };

  const requestRemoveNiceCarInfo = async (cossModlId: string) => {
    try {
      await unlinkNiceCarInfo(cossModlId);
      alertSuccess("연결정보가 초기화되었습니다.");
      await updateCarInfoDetail();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const requestFetchCarInfoDetail = async (
    cossModlId: string,
    dataFetching = true
  ) => {
    setDataFetching(dataFetching);
    try {
      const carInfoDetail = await fetchCossCarInfoDetail(cossModlId);
      setCarInfoDetail(carInfoDetail);
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setDataFetching(false);
    }
  };

  const updateCarInfoDetail = async () => {
    if (cossMaster) await requestFetchCarInfoDetail(cossMaster.modlId, false);
  };

  /**
   * Event Actions
   */

  const handleCossConnectButtonClick = () => {
    setCossConnectionModalVisible(true);
  };

  const handleCossUnlinkButtonClick = () => {
    showConfirm("연결취소", "NICE 연결정보를 초기화하시겠습니까?", async () => {
      if (cossMaster) {
        await requestRemoveNiceCarInfo(cossMaster.modlId);
        if (onDataChange) onDataChange();
      }
    });
  };

  const handleOptionUpdateButtonClick = (optionData: CossOptionDetail) => {
    setSelectedOptionInfo(optionData);
    setOptionUpdateModalVisible(true);
  };

  /**
   * Render Helpers
   */

  const renderCustomContent = (cossValue = "-", niceValue = "-") => {
    return (
      <div>
        <span
          style={{
            display: "block",
          }}
        >
          {cossValue}
        </span>
        <span
          style={{
            display: "block",
            color: "#626BFF",
          }}
        >
          {niceValue}
        </span>
      </div>
    );
  };

  const renderNiceContent = (niceValue = "-") => {
    return (
      <div>
        <span
          style={{
            display: "block",
            color: "#626BFF",
          }}
        >
          {niceValue}
        </span>
      </div>
    );
  };

  const renderCarInfoTabContent = () => {
    if (cossMaster) {
      // COSS 연결
      const isCossConnected = !!carInfoDetail?.niceInfo;
      const carImageUrl = carInfoDetail?.niceInfo?.repImageUrl;

      const connectionInfoSection: Array<SectionFieldInfo> = [
        {
          label: "연결유무",
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <StatusCellText
                  active={isCossConnected}
                  text={isCossConnected ? "연결" : "미연결"}
                />
                {!isCossConnected ? (
                  <Button
                    style={{ marginTop: "16px" }}
                    type="primary"
                    onClick={handleCossConnectButtonClick}
                  >
                    연결하기
                  </Button>
                ) : (
                  <Button
                    style={{ marginTop: "16px" }}
                    ghost
                    danger
                    onClick={handleCossUnlinkButtonClick}
                  >
                    연결해제
                  </Button>
                )}
              </div>
            );
          },
        },
      ];

      // NICE 정보
      const carInfoSection: SectionFieldInfo[] = [
        {
          type: FieldType.Custom,
          span: 24,
          visible: !!carImageUrl,
          render: () => {
            return (
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                대표차량 이미지
                <Image
                  src={carImageUrl}
                  alt={carImageUrl}
                  width={140}
                  style={{
                    display: "block",
                  }}
                />
              </div>
            );
          },
        },
        {
          label: "트림정보",
          type: FieldType.LabelOnly,
        },

        {
          label: "트림",
          type: FieldType.Custom,
          content: cossMaster.modlNm,
          render: () => {
            return renderCustomContent(
              cossMaster.modlNm,
              getNiceTrim(carInfoDetail)
            );
          },
        },
        {
          label: "차랑 가격",
          type: FieldType.Custom,
          render: () => {
            return renderCustomContent(
              formatCurrency(cossMaster.carAmt),
              getNiceCarPrice(carInfoDetail)
            );
          },
        },
        {
          label: "제조사",
          type: FieldType.Custom,
          render: () => {
            return renderCustomContent(
              cossMaster.carMakerNm,
              getNiceBrand(carInfoDetail)
            );
          },
        },
        {
          label: "연식",
          type: FieldType.Custom,
          render: () => {
            return renderNiceContent(getNiceYear(carInfoDetail));
          },
        },
      ];

      if (
        carInfoDetail &&
        carInfoDetail.niceInfoColorList &&
        carInfoDetail.niceInfoColorList.length > 0
      ) {
        carInfoSection.push({
          label: "",
          type: FieldType.Divider,
        });

        carInfoSection.push({
          label: "",
          type: FieldType.Custom,
          render: () => {
            return (
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                색상정보
                <Button
                  ghost
                  size="small"
                  style={{
                    marginLeft: "8px",
                  }}
                  onClick={() => {
                    setColorEditModalVisible(true);
                  }}
                >
                  색상수정
                </Button>
              </span>
            );
          },
          span: 24,
        });

        carInfoDetail.cossColorList.forEach((colorInfo, index) => {
          const filters = carInfoDetail.niceInfoColorList?.filter(
            ({ cossColorId }) => {
              return cossColorId === colorInfo.cossColorId;
            }
          );

          let modeColorNm = "-";
          let modeImageUrl = "";
          if (filters && filters.length > 0) {
            modeColorNm = filters[0].modeColorNm;
            modeImageUrl = filters[0].modeColorImgUrl;
          }

          carInfoSection.push({
            label: `${colorInfo.cossColorClsNm} - ${index + 1}`,
            type: FieldType.Custom,
            render: () => {
              return (
                <div>
                  <span
                    style={{
                      display: "block",
                    }}
                  >
                    {`${colorInfo.cossColorNm}` || "-"}
                  </span>
                  <div>{formatCurrency(colorInfo.cossColorAmt)}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {modeImageUrl && (
                      <img
                        style={{
                          borderRadius: "9px",
                          marginRight: "8px",
                        }}
                        width={18}
                        height={18}
                        src={modeImageUrl}
                        alt="색상이미지"
                      />
                    )}

                    <span
                      style={{
                        color: "#626BFF",
                      }}
                    >
                      {modeColorNm}
                    </span>
                  </div>
                </div>
              );
            },
          });
        });
      }

      return (
        <div>
          <DetailSection label="NICE 연결" fieldInfos={connectionInfoSection} />
          <DetailSection label="차량정보" fieldInfos={carInfoSection} />
        </div>
      );
    }
    return "";
  };

  const renderOptionListTabContent = () => {
    if (
      carInfoDetail &&
      carInfoDetail.niceInfoOptionList &&
      carInfoDetail.niceInfoOptionList.length > 0
    ) {
      return (
        <div>
          {carInfoDetail?.niceInfoOptionList.map((optionInfo, index) => {
            const label = `COSS 옵션정보 ${index + 1}`;
            const { optDescFileUrl } = optionInfo;

            const fieldInfos = [
              {
                label: "옵션명",
                type: FieldType.Text,
                content: optionInfo.cossOptNm,
              },
              {
                label: "금액",
                type: FieldType.Text,
                content: formatCurrency(optionInfo.cossTaxnAmt),
              },
              {
                label: "커스텀 옵션명",
                type: FieldType.Text,
                content: optionInfo.cossOptCustomNm,
              },
              {
                label: "옵션 PDF",
                type: FieldType.Custom,
                render: () => {
                  if (optDescFileUrl) {
                    return (
                      <Button
                        type="default"
                        onClick={() => {
                          window.open(optDescFileUrl);
                          alertSuccess(optDescFileUrl);
                        }}
                      >
                        다운로드
                      </Button>
                    );
                  }
                  return "-";
                },
              },

              {
                label: "동영상 링크",
                type: FieldType.Text,
                content: optionInfo.cossOptDescUrl,
              },
              {
                type: FieldType.Custom,
                span: 24,
                render: () => {
                  return (
                    <Button
                      onClick={() => {
                        handleOptionUpdateButtonClick(optionInfo);
                      }}
                    >
                      옵션정보 입력
                    </Button>
                  );
                },
              },
            ];

            return (
              <div>
                <DetailSection
                  key={index}
                  label={label}
                  fieldInfos={fieldInfos}
                />
              </div>
            );
          })}
        </div>
      );
    }

    return "옵션정보가 없습니다.";
  };

  const renderTabs = () => {
    const tabs = [
      {
        title: "NICE 연결정보",
        key: "1",
        content: renderCarInfoTabContent(),
      },
      {
        title: "고객용 옵션정보 등록",
        key: "2",
        content: renderOptionListTabContent(),
      },
    ];

    return (
      <div>
        <TabLayout tabInfos={tabs} />
      </div>
    );
  };

  return (
    <>
      <Modal
        footer={null}
        size="large"
        title="차량정보 상세"
        visible={visible}
        onCancel={onCancel}
        onOpen={onModalOpen}
      >
        {dataFetching ? renderLoading() : renderTabs()}
      </Modal>
      <NiceCarInfoConnectModal
        cossMaster={cossMaster}
        visible={cossConnectionModalVisible}
        onDataChange={async () => {
          await updateCarInfoDetail();
          if (onDataChange) onDataChange();
        }}
        onCancel={() => {
          setCossConnectionModalVisible(false);
        }}
      />
      <OptionInfoFormModal
        optionInfo={selectedOptionInfo}
        onDataChange={async () => {
          await updateCarInfoDetail();
          if (onDataChange) onDataChange();
        }}
        onCancel={() => {
          setOptionUpdateModalVisible(false);
        }}
        visible={optionUpdateModalVisible}
      />
      <ColorConnectionEditModal
        visible={colorEditModalVisible}
        cossMaster={cossMaster}
        onCancel={() => {
          setColorEditModalVisible(false);
        }}
        onDataChange={async () => {
          await updateCarInfoDetail();
          if (onDataChange) onDataChange();
        }}
      />
    </>
  );
};

CarInfoDetailModal.defaultProps = {};
export default CarInfoDetailModal;
