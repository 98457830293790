class PrivilegeInfo {
  public privId = "";

  public privNm = "";
}

class MenuItem {
  public dtyId = "";

  public menuId = "";

  public upperMenuId = "";

  public menuNm = "";

  public upperMenuNm = "";

  public privList: PrivilegeInfo[] = [];
}

class User {
  public clientIp = "";

  public loginId = "";

  public userId = "";

  public userNo = "";

  public userNm = "";

  public hpNo = "";

  public dtyId = "";

  public addAuthChk = "";

  public sessionDTO: any;

  public menuList: MenuItem[] = [];

  get canReadUserList() {
    if (this.addAuthChk === "Y") {
      return "TEST";
    }

    return "TTTT";
  }
}

export default User;
