import Qs from "qs";
import { get } from "lodash";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import HttpError from "../models/HttpError";
import { ERR_CD_SESSION_EXPIRED } from "../constants/enums";
import { setUser } from "../redux/actions/auth";
import { setConnectLogs } from "../apis/logs";
import User from "../models/User";

const TIMEOUT_MILLS = 60000;
let axiosInterceptor: number | null;
let axiosRequestInterceptor: number | null;
axios.defaults.withCredentials = true;

export enum HttpMethod {
  Get = "get",
  Put = "put",
  Post = "post",
  Delete = "delete",
}

export const request = async (
  method: HttpMethod,
  url: string,
  query?: Record<string, unknown>,
  body?: any,
  headers?: Record<string, unknown>,
  isMultipart?: boolean
): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    url,
    method,
    headers,
    params: query,
    data: body,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: "repeat" }),
    timeout: TIMEOUT_MILLS,
  };

  const { reduxStore } = await import("../index");
  const token = get(reduxStore.getState(), "auth.token", "");
  if (token) {
    config.headers = {
      "x-auth-token": token,
    };
  }
  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    };
  }
  if (isMultipart) {
    config.headers = {
      ...config.headers,
      "content-type": "multipart/form-data",
    };
  }

  if (typeof axiosInterceptor === "number") {
    axios.interceptors.response.eject(axiosInterceptor);
    axiosInterceptor = null;
  }

  if (typeof axiosRequestInterceptor === "number") {
    axios.interceptors.response.eject(axiosRequestInterceptor);
    axiosInterceptor = null;
  }

  axiosInterceptor = axios.interceptors.response.use(
    (response) => {
      // 전체 요청에 대한 로그 작성 코드
      // const { origin } = document.location;
      // const urlPath = response.config.url?.split(origin)[1];
      // const { user }: { user: User } = reduxStore.getState().auth;

      // if (urlPath && !excludeUrlPath.includes(urlPath)) {
      //   setConnectLogs({
      //     userId: user.userId,
      //     userIp: user.clientIp,
      //     methodNm: urlPath,
      //     menuName: urlPath,
      //     actionFlag: response.config.method || "",
      //   });
      // }

      if (response) {
        const { data } = response;
        if (data) {
          const { message } = data;
          // TODO 임시 오류 처리 -> 추후 백엔드 업데이트시 Status 코드로 변경필요
          if (message && message !== "요청에 대한 작업을 정상적으로 완료함.") {
            return Promise.reject(new HttpError(response));
          }
        }
      }
      return response;
    },
    (error) => {
      if (error.response) {
        const { data } = error.response;
        if (data) {
          const { status } = data;
          if (status && status === ERR_CD_SESSION_EXPIRED) {
            reduxStore.dispatch(setUser(undefined));
            // eslint-disable-next-line no-console
            console.error("SESSION EXPIRED");
          }
        }
      }

      return Promise.reject(new HttpError(error.response || error));
    }
  );

  return axios.request(config);
};

const excludeUrlPath = ["/admin/log", "/admin/log/file"];
