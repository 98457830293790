import styled from "styled-components";
import LogoImage from "../../../../assets/svgs/logo.svg";

export const AsideContainer = styled.div<any>`
  flex-basis: ${(props) => props.theme.aside.sizes.medium};
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  background-color: white;
  z-index: 2;
  filter: drop-shadow(4px -4px 4px rgba(215, 215, 215, 0.25));
  border: 1px solid #f0f0f0;
`;

export const LogoBox = styled.img.attrs({
  src: LogoImage,
  width: 139,
  height: 28,
})`
  background: white;
`;

export const SubMenuDot = styled.span<any>`
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: ${(props) =>
    props.isActive ? props.theme.common.colors.point : "#464858"};
  border-radius: 2px;
  margin-right: 10px;

  .rc-menu-item-selected {
    background-color: red;
  }
`;

export const MenuContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
