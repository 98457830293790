import React from "react";
import { DatePicker as AntDatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import { RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import { Moment } from "moment";
import {
  get1MonthDateRange,
  get1YearDateRange,
  get3MonthDateRange,
  getTodayDateRange,
  getWeekDateRange,
} from "../../../../../utils/date-utils";

interface DateRangePickerProps extends RangePickerDateProps<Moment> {}

const { RangePicker } = AntDatePicker;

const DateRangePicker: React.FC<DateRangePickerProps> = (
  props: DateRangePickerProps
) => {
  const { style, ranges, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <RangePicker
      format={["YY/MM/DD", "YYMMDD", "YYYYMMDD"]}
      ranges={ranges}
      locale={locale}
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

DateRangePicker.defaultProps = {
  ranges: {
    // @ts-ignore
    오늘: getTodayDateRange(),
    // @ts-ignore
    "1주일": getWeekDateRange(),
    // @ts-ignore
    "1개월": get1MonthDateRange(),
    // @ts-ignore
    "3개월": get3MonthDateRange(),
    // @ts-ignore
    "1년": get1YearDateRange(),
  },
};
export default DateRangePicker;
