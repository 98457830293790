import React, { useState } from "react";
import moment from "moment";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import strings from "./constants/strings";
import { createActionCellRenderers } from "../../../utils/table-utils";
import {
  COSS_CONNECT_ACTION,
  createCarInfoCols,
  renderConnectCossButton,
} from "./utils/table-utils";
import {
  getPageIndex,
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import { fetchCarInfos, unlinkNiceCarInfo } from "../../../apis/coss-car-infos";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import {
  COSS_FUEL_TYPE_FILTER_OPTIONS,
  NICE_CONNECT_FILTER_OPTIONS,
} from "./constants/enums";
import { get1MonthDateRange } from "../../../utils/date-utils";
import CarInfoDetailModal from "./pages/CarInfoDetailModal";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import NiceCarInfoConnectModal from "./pages/NiceCarInfoConnectModal";
import commonStrings from "../../../constants/strings";
import CossMaster from "../../../models/CossCarInfo/CossMaster";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";

const CossCarInfoManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<CossMaster>();
  const [cossConnectionModalVisible, setCossConnectionModalVisible] = useState<
    boolean
  >();
  const [logInfo, setLogInfo] = useState<string>("");

  const cols = createCarInfoCols();

  const filterInitialValues = {
    cossDt: get1MonthDateRange(),
    cossModlId: "",
    cossModlNm: "",
    brandNm: "",
    cartypeNm: "",
    yearType: "",
    fuel: "",
    niceMappingYn: "",
  };
  const filterForms = [
    {
      key: "cossDt",
      label: "등록기간",
      type: FilterElementType.DateRangePicker,
      extraOptions: {
        allowClear: false,
      },
    },
    {
      key: "cossModlId",
      label: "모델 ID",
      type: FilterElementType.Input,
      placeholder: commonStrings.HINT_COSS_MODEL_ID,
    },
    {
      key: "cossModlNm",
      label: "모델명",
      type: FilterElementType.Input,
      placeholder: commonStrings.HINT_COSS_MODEL_NAME,
    },
    {
      key: "brandNm",
      label: "제조사",
      type: FilterElementType.Input,
      placeholder: commonStrings.HINT_COSS_BRAND_NAME,
    },
    {
      key: "cartypeNm",
      label: "차명",
      type: FilterElementType.Input,
      placeholder: commonStrings.HINT_COSS_CAR_NAME,
    },
    {
      key: "yearType",
      label: "연식",
      type: FilterElementType.Input,
      placeholder: commonStrings.HINT_CAR_YEAR,
    },
    {
      key: "fuel",
      label: "연료",
      type: FilterElementType.Select,
      options: COSS_FUEL_TYPE_FILTER_OPTIONS,
    },
    {
      key: "niceMappingYn",
      label: "NICE 연결여부",
      type: FilterElementType.Select,
      options: NICE_CONNECT_FILTER_OPTIONS,
    },
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const {
      cossDt,
      cossModlId,
      cossModlNm,
      brandNm,
      cartypeNm,
      yearType,
      fuel,
      niceMappingYn,
    } = appliedFilterValues;

    // COSS 등록기간
    const cossInsSDt = moment(cossDt[0]).format("YYYYMMDD");
    const cossInsEDt = moment(cossDt[1]).format("YYYYMMDD");

    try {
      const { items, count } = await fetchCarInfos(
        getPageIndex(startRow, paginationPageSize),
        paginationPageSize,
        cossInsSDt,
        cossInsEDt,
        cossModlId,
        cossModlNm,
        brandNm,
        cartypeNm,
        yearType,
        fuel,
        niceMappingYn
      );
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
    } catch (e) {
      setTotalCount(0);
      success({
        rowData: [],
        rowCount: 0,
      });
    }
  };

  const requestRemoveNiceCarInfo = async (cossModlId: string) => {
    try {
      await unlinkNiceCarInfo(cossModlId);
      alertSuccess("연결정보가 초기화되었습니다.");
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    setSelectedData(data);
    setModalVisible(true);
  };

  const handleCossConnectButtonClick = (cossMaster: CossMaster) => {
    setSelectedData(cossMaster);
    setCossConnectionModalVisible(true);
  };

  const handleCossRemoveButtonClick = (
    cossMaster: CossMaster,
    api: GridApi
  ) => {
    showConfirm("연결취소", "NICE 연결정보를 초기화하시겠습니까?", async () => {
      await requestRemoveNiceCarInfo(cossMaster.modlId);
      refreshServerSideTable(api);
    });
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <PaginationTableLayout
        headerTitle={strings.TITLE_HEADER_CAR_INFO_MANAGER}
        headerDescription={strings.TITLE_DESCRIPTION_HEADER}
        tableHeaderTitle={strings.TITLE_TABLE_HEADER}
        onDataLoad={onDataLoad}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        totalCount={totalCount}
        hideFilterAction
        frameworkComponents={{
          [COSS_CONNECT_ACTION]: renderConnectCossButton(
            handleCossConnectButtonClick,
            handleCossRemoveButtonClick
          ),
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
        }}
        apiRef={(api) => {
          setGridApi(api);
        }}
        methodName="/admin/car-infos"
        logInfo={logInfo}
      />
      <NiceCarInfoConnectModal
        cossMaster={selectedData}
        visible={cossConnectionModalVisible}
        onDataChange={() => {
          refreshServerSideTable(gridApi);
        }}
        onCancel={() => {
          setCossConnectionModalVisible(false);
        }}
      />
      <CarInfoDetailModal
        visible={modalVisible}
        cossMaster={selectedData}
        onCancel={() => {
          setModalVisible(false);
        }}
        onDataChange={() => {
          refreshServerSideTable(gridApi);
        }}
      />
    </>
  );
};

CossCarInfoManager.defaultProps = {};

export default CossCarInfoManager;
