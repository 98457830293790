import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";

import {
  createFormInfo,
  createInitialValues,
  createValidationSchema,
} from "./utils/form-utils";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import { ModalProps } from "../../../../../../types";
import UsedCarCatalogDetail from "../../../../../../../models/UsedCarCatalogDetail";
import { editMdComment } from "../../../../../../../apis/uc-catalogs";

interface Props extends ModalProps {
  catalogDetail?: UsedCarCatalogDetail;
}

const MdCommentFormModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, catalogDetail } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues("")
  );
  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);
    if (catalogDetail) {
      setInitialValues(createInitialValues(catalogDetail.mdComment));
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */

  const requestEditMdComment = async (values: FormikValues) => {
    if (catalogDetail) {
      const { prodId, carId } = catalogDetail;
      const { mdComment } = values;
      setConfirmLoading(true);
      try {
        await editMdComment(prodId, carId, mdComment);
        alertSuccess("차량소개 정보가 변경되었습니다.");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    await requestEditMdComment(values);
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="차량소개 수정"
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={createFormInfo()}
          validationSchema={createValidationSchema()}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

MdCommentFormModal.defaultProps = {};
export default MdCommentFormModal;
