import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { DefaultRentOptionInfoParam } from "./types";
import DefaultRentOptionInfo from "../../models/DefaultRentOptionInfo";

const apiRootUrl = `${ROOT_URL}/admin/nc-atmsts`;

/**
 * 메인가격 기준 리스트 조회
 */
export const fetchDefaultRentOptionInfos = async (): Promise<
  ListItemResponse<DefaultRentOptionInfo>
> => {
  const res = await request(HttpMethod.Get, apiRootUrl, {
    pageIndex: 1,
    pageSize: 1000,
  });

  const { data } = res;
  const { response } = data;
  const { totalCnt, list } = response;

  return {
    items: plainToClass(DefaultRentOptionInfo, list as Array<JSON>),
    count: totalCnt,
  };
};

/**
 * 메인 가격 기준  등록
 */
export const addDefaultRentOptionInfo = async (
  params: DefaultRentOptionInfoParam
) => {
  await request(HttpMethod.Post, apiRootUrl, undefined, params);
};

/**
 * 공지사항 수정
 */
export const editDefaultRentOptionInfo = async (
  prdbaseId: string,
  params: DefaultRentOptionInfoParam
) => {
  await request(
    HttpMethod.Put,
    `${apiRootUrl}/${prdbaseId}`,
    undefined,
    params
  );
};
