import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useEffect, useState } from "react";

interface CustomSimulSectionProps {
  pldgRtText: string;
  onClickEstButton: () => void;
  }

const CustomSimulSection: React.FC<CustomSimulSectionProps> = (props) => {

  const { pldgRtText, onClickEstButton  } = props;

  const onClickNoteButton = ()=>{};

  return (
      <div  style={{ marginRight: "60px",}}>
        <Button
          type="primary"
          onClick={() => {
            onClickEstButton();
          }}
        >
          견적내기
        </Button>
        <span
          style={{
            marginLeft: "12px",
            color: "#626DFF",
          }}
        >
          {pldgRtText}
        </span>
      </div>
  );
  };

  export default CustomSimulSection;