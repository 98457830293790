import React, { useState } from "react";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import {
  ColumnApi,
  GridApi,
  GridOptions,
  IServerSideDatasource,
  ServerSideStoreType,
} from "ag-grid-community";
import {
  GridReadyEvent,
  SelectionChangedEvent,
} from "ag-grid-community/dist/lib/events";
import TableColumn, { FilterType } from "../../model/TableColumn";
import {
  TableContainer,
  TableHeaderContainer,
  TableHeaderTitle,
} from "../../styles/style";
import "../../styles/table-theme.scss";
import AG_GRID_LOCALE_KO from "../../constants/localization";
import StatusCellText from "../AgGridCellRenderers/StatusCellText";
import { DEFAULT_GRID_LIMIT } from "../../../../../../constants/values";

export const CR_STATUS_TEXT = "statusCellRenderer";

export interface ServerRowTableProps extends GridOptions {
  width?: string | number;
  height?: string | number;
  onDataLoad: (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    filterValues: any,
    paginationPageSize: number
  ) => void;
  cols: Array<TableColumn>;
  paginationPageSize?: number;
  floatingFilter?: boolean;
  frameworkComponents?:
    | {
        [p: string]: {
          new (): any;
        };
      }
    | any;
  rowSelection?: string;
  headerTitle?: string;
  apiRef?: (api: GridApi) => void;
  colApiRef?: (api: ColumnApi) => void;
  onCheckRowChanged?: (data: Array<any>) => void;
  filterInitialValues?: any;
}

const ServerRowTable: React.FC<ServerRowTableProps> = (
  props: ServerRowTableProps
) => {
  const {
    width,
    height,
    onDataLoad,
    cols,
    paginationPageSize = DEFAULT_GRID_LIMIT,
    frameworkComponents,
    headerTitle,
    apiRef,
    colApiRef,
    onCheckRowChanged,
    filterInitialValues,
    ...rest
  } = props;

  const tableWidth = typeof width === "number" ? `${width}px` : width;
  const tableHeight = typeof height === "number" ? `${height}px` : height;
  const [gridApi, setGridApi] = useState<GridApi>();

  function createServerSideDataSource(): IServerSideDatasource {
    return {
      getRows: (params) => {
        if (onDataLoad) {
          const { request } = params;
          const { startRow, filterModel } = request;

          let filterValues = {};
          if (filterModel.filterDataStore) {
            const { filter } = filterModel.filterDataStore;
            try {
              filterValues = JSON.parse(filter);
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(e);
            }
          }
          onDataLoad(
            ({ rowCount, rowData }) => {
              if (rowData.length === 0) gridApi?.showNoRowsOverlay();

              params.success({
                rowCount,
                rowData: rowData.map((data) => ({
                  ...data,
                  // Filter 데이터 저장을 위한 임시 필드 추가
                  filterDataStore: "",
                })),
              });
            },
            () => {
              params.fail();
            },
            startRow,
            filterValues,
            paginationPageSize
          );
        }
      },
    };
  }

  const onGridReady = (params: GridReadyEvent) => {
    const { api, columnApi } = params;
    setGridApi(api);
    if (apiRef) apiRef(api);
    if (colApiRef) colApiRef(columnApi);
    const dataSource = createServerSideDataSource();

    // Filter 초기값 설정
    if (filterInitialValues) {
      api.setFilterModel({
        filterDataStore: {
          filter: JSON.stringify(filterInitialValues),
          type: "equals",
        },
      });
    }

    api.setServerSideDatasource(dataSource);
  };

  const handleSelectionChanged = (event: SelectionChangedEvent) => {
    const { api } = event;
    if (onCheckRowChanged) {
      onCheckRowChanged(api.getSelectedRows());
    }
  };

  const colKeys: string[] = cols
    .filter(({ field }) => {
      return field && field !== "filterDataStore";
    })
    .map(({ field }) => {
      return field || "";
    });

  return (
    <TableContainer
      className="ag-theme-custom-alpine"
      width={tableWidth}
      height={tableHeight}
    >
      {headerTitle && (
        <TableHeaderContainer>
          <TableHeaderTitle>{headerTitle}</TableHeaderTitle>
        </TableHeaderContainer>
      )}
      <AgGridReact
        // @ts-ignore
        modules={[ServerSideRowModelModule]}
        localeText={AG_GRID_LOCALE_KO}
        suppressPaginationPanel
        pagination
        paginationPageSize={paginationPageSize}
        cacheBlockSize={paginationPageSize}
        rowBuffer={paginationPageSize}
        maxBlocksInCache={1}
        onSelectionChanged={handleSelectionChanged}
        rowClass="mode-row-style"
        rowModelType="serverSide"
        serverSideStoreType={ServerSideStoreType.Partial}
        onGridReady={onGridReady}
        suppressCellSelection
        suppressRowClickSelection
        frameworkComponents={{
          ...frameworkComponents,
          [CR_STATUS_TEXT]: StatusCellText,
        }}
        defaultExportParams={{
          columnKeys: colKeys,
        }}
        defaultColDef={{
          resizable: true,
          floatingFilter: false,
          suppressMenu: true,
          sortable: false,
        }}
        {...rest}
      >
        {cols.map(({ headerName, field, valueGetter, minWidth, ...rest }) => (
          <AgGridColumn
            valueGetter={valueGetter}
            key={headerName}
            field={field}
            headerName={headerName}
            minWidth={minWidth}
            {...rest}
          />
        ))}
        {/* 필터 데이터 저장용 Hidden 컬럼 */}
        <AgGridColumn field="filterDataStore" filter={FilterType.Text} hide />
      </AgGridReact>
    </TableContainer>
  );
};

ServerRowTable.defaultProps = {
  rowSelection: "single",
  width: "100%",
  height: "100%",
  floatingFilter: true,
  headerTitle: "",
  cols: [],
  filterInitialValues: undefined,
};
export default ServerRowTable;
