import {
  BANNER_MANAGER,
  CAR_BOOKING_HISTORIES,
  CAR_MANAGER_REPLACEMENT_MANAGER,
  CAR_MANAGER_SMS_SENDER,
  CAR_MANAGER_TRANSFER_MANAGER,
  CAR_MANAGERS,
  CONSULTING_DATA_MANAGER,
  CONTRACT_UPDATE_REQUEST_MANAGER,
  COSS_CAR_INFO_MANAGER,
  CUSTOMER_CONSULTING_REQUEST,
  CUSTOMER_MANAGER,
  CUSTOMER_SATISFACTION_REPORT_MANAGER,
  DELIVERY_SCHEDULE_MANAGER,
  DISCOUNT_MASTER_MANAGER,
  ESTIMATION_MANAGER,
  ESTIMATION_REPORT_MANAGER,
  EVENT_MANAGER,
  EXHIBITION_MANAGER,
  FAQ_MANAGER,
  INSURANCE_FEE_RATE_MANAGER,
  MAIN_PRODUCT_PRICING_MANAGER,
  MGM_REPORT_MANAGER,
  NEW_CAR_DELIVERY_MANAGER,
  NEW_CAR_RENT_CATALOGS_MANAGER,
  CATALOG_TAG_MANAGER,
  NOTICE_MANAGER,
  RENT_CONTRACT_MANAGER,
  REVIEW_MANAGER,
  SATISFACTION_SURVEY_MANAGER,
  SMS_TEMPLATE_MANAGER,
  SPECIAL_PROMOTION_REWARD_MANAGER,
  STOCK_REPORT_MANAGER,
  SURVEY_REPORT_MANAGER,
  USED_CAR_RENT_CATALOGS_MANAGER,
  TAGO_CAR_RENT_CATALOGS_MANAGER,
  MONTHLY_CAR_RENT_CATALOGS_MANAGER,
  AUC_CAR_RENT_CATALOGS_MANAGER,
  USED_CAR_CATALOG_TAG_MANAGER,
  USED_CAR_EXHIBITION_MANAGER,
  TAGO_CAR_EXHIBITION_MANAGER,
  AUC_MANAGER,
  SKU_REPORT_MANAGER,
  COOPER_CAR_RENT_CATALOGS_MANAGER,
  TAGO_TKV_REQ_CONTRACT_MANAGER,
  AUC_CONTRACT_MANAGER,
  YEAR_CEFT_MANAGER,
} from "../urls";

export type SideMenuInfo = {
  key: string;
  title: string;
  icon?: string;
  disabled?: boolean;
  subMenus?: Array<SideMenuInfo>;
};

const sideMenuInfos: Array<SideMenuInfo> = [
  {
    key: "user-section",
    title: "회원",
    subMenus: [
      {
        key: CUSTOMER_MANAGER,
        title: "회원 관리",
      },
      {
        key: CUSTOMER_CONSULTING_REQUEST,
        title: "상담신청 관리",
      },
      {
        key: CAR_BOOKING_HISTORIES,
        title: "사전예약 신청관리",
      },
      {
        key: CAR_MANAGER_TRANSFER_MANAGER,
        title: "회원 카매니저 이관",
      },
    ],
  },
  {
    key: "contract-section",
    title: "견적/계약",
    subMenus: [
      {
        key: ESTIMATION_MANAGER,
        title: "견적관리",
      },
      {
        key: RENT_CONTRACT_MANAGER,
        title: "계약관리(렌탈형)",
      },
      // {
      //   key: SALE_CONTRACT_MANAGER,
      //   title: "계약관리(인수형)",
      //   disabled: true,
      // },
      {
        key: CONTRACT_UPDATE_REQUEST_MANAGER,
        title: "계약변경요청 관리",
      },
      {
        key: DISCOUNT_MASTER_MANAGER,
        title: "할인마스터 관리",
      },
      {
        key: TAGO_TKV_REQ_CONTRACT_MANAGER,
        title: "인수요청관리(타고바이)",
      },
      {
        key: AUC_CONTRACT_MANAGER,
        title: "계약관리(제휴중고차)",
      },
    ],
  },
  {
    key: "car-selling-section",
    title: "차량판매",
    subMenus: [
      {
        key: COSS_CAR_INFO_MANAGER,
        title: "차량정보관리",
      },
      {
        key: NEW_CAR_RENT_CATALOGS_MANAGER,
        title: "온라인 상품관리(신차장기)",
      },
      {
        key: USED_CAR_RENT_CATALOGS_MANAGER,
        title: "온라인 상품관리(중고장기)",
      },
      {
        key: TAGO_CAR_RENT_CATALOGS_MANAGER,
        title: "온라인 상품관리(타고바이)",
      },
      {
        key: MONTHLY_CAR_RENT_CATALOGS_MANAGER,
        title: "온라인 상품관리(월렌트)",
      },
      {
        key: AUC_CAR_RENT_CATALOGS_MANAGER,
        title: "온라인 상품관리(제휴중고)",
      },
      {
        key: COOPER_CAR_RENT_CATALOGS_MANAGER,
        title: "온라인 상품관리(제휴연동)",
      },
      {
        key: CATALOG_TAG_MANAGER,
        title: "상품태그관리(신차장기)",
      },
      {
        key: USED_CAR_CATALOG_TAG_MANAGER,
        title: "상품태그관리(중고장기)",
      },
      {
        key: INSURANCE_FEE_RATE_MANAGER,
        title: "보증보험 수수료율 관리",
      },
      {
        key: NEW_CAR_DELIVERY_MANAGER,
        title: "신차발주 납기관리",
      },
      {
        key: MAIN_PRODUCT_PRICING_MANAGER,
        title: "메인상품 가격기준 관리",
      },
      {
        key: YEAR_CEFT_MANAGER,
        title: "미래가치계수 관리",
      },
    ],
  },
  {
    key: "marketing-section",
    title: "마케팅",
    subMenus: [
      {
        key: EXHIBITION_MANAGER,
        title: "기획전 관리(신차장기)",
      },
      {
        key: USED_CAR_EXHIBITION_MANAGER,
        title: "기획전 관리(중고장기)",
      },
      {
        key: TAGO_CAR_EXHIBITION_MANAGER,
        title: "기획전 관리(타고바이)",
      },
      {
        key: AUC_MANAGER,
        title: "제휴사 관리(제휴중고차)",
      },
      {
        key: EVENT_MANAGER,
        title: "프로모션 관리",
      },
      {
        key: BANNER_MANAGER,
        title: "배너 관리",
      },
      {
        key: REVIEW_MANAGER,
        title: "후기 관리",
      },
      {
        key: CONSULTING_DATA_MANAGER,
        title: "지능형 상담",
      },
      {
        key: MGM_REPORT_MANAGER,
        title: "친구추천 프로그램 (가입자 관리) ",
      },
      {
        key: SPECIAL_PROMOTION_REWARD_MANAGER,
        title: "친구추천 프로그램 (리워드 관리)",
      },
    ],
  },
  {
    key: "customer-service-section",
    title: "고객지원",
    subMenus: [
      {
        key: NOTICE_MANAGER,
        title: "공지사항",
      },
      {
        key: FAQ_MANAGER,
        title: "FAQ",
      },
    ],
  },
  {
    key: "car-manager-section",
    title: "카매니저",
    subMenus: [
      {
        key: DELIVERY_SCHEDULE_MANAGER,
        title: "인도스케줄 관리",
      },
      {
        key: CAR_MANAGER_REPLACEMENT_MANAGER,
        title: "대무 설정",
      },
      {
        key: CAR_MANAGER_SMS_SENDER,
        title: "템플릿 문자 전송",
      },
    ],
  },
  // {
  //   key: "report-section",
  //   title: "리포트",
  //   subMenus: [
  //     {
  //       key: ESTIMATION_REPORT_MANAGER,
  //       title: "견적 집계 순위",
  //     },
  //     {
  //       key: STOCK_REPORT_MANAGER,
  //       title: "일일 재고 현황",
  //     },
  //   ],
  // },
  {
    key: "service-manager-section",
    title: "서비스운영 관리",
    subMenus: [
      {
        key: CUSTOMER_SATISFACTION_REPORT_MANAGER,
        title: "서비스 만족도 조사결과",
      },
      {
        key: SURVEY_REPORT_MANAGER,
        title: "고객 설문 조사결과",
      },
      {
        key: CAR_MANAGERS,
        title: "카매니저 관리(개선 예정)",
      },
      {
        key: SMS_TEMPLATE_MANAGER,
        title: "문자전송 템플릿 관리",
      },
      {
        key: SATISFACTION_SURVEY_MANAGER,
        title: "만족도 조사 템플릿 관리",
      },
    ],
  },
  // {
  //   key: "report-manager-section",
  //   title: "리포트",
  //   subMenus: [
  //     {
  //       key: SKU_REPORT_MANAGER,
  //       title: "SKU Report(테스트)",
  //     },
  //   ],
  // },
];

export default sideMenuInfos;
