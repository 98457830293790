import { formatCurrency } from "../utils/common-utils";
import RentContractMemo from "./RentContractMemo";
import { DiscountInfo } from "./RentFeeResult";

class RentContractDetail {
  // 계약ID
  public cntrId = "";

  // 계약순번
  public cntrSeq = 0;

  // 계약번호
  public cntrNo = "";

  // 견적요청번호
  public estReqNo = "";

  // 견적버전
  public estVer = 0;

  // 계약유형코드
  public custClsCd = "";

  // 계약유형
  public custClsNm = "";

  // 차량ID
  public carId = "";

  // 차량번호
  public carNo = "";

  // 고객ID
  public custId = "";

  // COSS고객ID
  public cossCustId = "";

  // 고객명
  public custNm = "";

  // 성별
  public gndr = "";

  // 생년월일
  public brthdt = "";

  // 사업자번호
  public busiNo = "";

  // 주소
  public addr = "";

  // 전화번호
  public telNo = "";

  // 휴대폰번호
  public hpNo = "";

  // 카매니저명
  public carmanNm = "";

  // 카매니저휴대폰번호
  public carmanHpNo = "";

  // 면허종별코드
  public drvingLcnsKnd = "";

  // 면허종별
  public drvingLcnsKndNm = "";

  // 면허번호
  public drvingLcnsNo = "";

  // 면허취득일
  public drvingLcnsGetDt = "";

  // 대표자성명
  public repstNm = "";

  // 대표자생년월일
  public repstBrthdt = "";

  // 대표자성별
  public repstGndr = "";

  // 대표자주소
  public repstAddr = "";

  // 대표자휴대폰번호
  public repstHpNo = "";

  // 대표자전화번호
  public repstTelNo = "";

  // 운전자성명
  public drvNm = "";

  // 운전자생년월일
  public drvBrthdt = "";

  // 운전자성별
  public drvGndr = "";

  // 운전자주소
  public drvAddr = "";

  // 운전자휴대폰번호
  public drvHpNo = "";

  // 운전자전화번호
  public drvTelNo = "";

  // 임직원특약 가입여부
  public empSpcnEntryYn = "";

  // 운전자범위코드
  public drvSoe = "";

  // 운전자범위명
  public drvSoeNm = "";

  // 추가운전자명1
  public addDrvNm1 = "";

  // 추가운전자생년월일1
  public addDrvBrthdt1 = "";

  // 추가운전자면허종별코드1
  public addDrvDrvingLcnsKnd1 = "";

  // 추가운전자면허종별1
  public addDrvDrvingLcnsKndNm1 = "";

  // 추가운전자면허번호1
  public addDrvDrvingLcnsNo1 = "";

  // 추가운전자연락처1
  public addDrvHpNo1 = "";

  // 추가운전자관계코드1
  public addDrvRelClsCd1 = "";

  // 추가운전자관계1
  public addDrvRelClsNm1 = "";

  // 추가운전자명2
  public addDrvNm2 = "";

  // 추가운전자생년월일2
  public addDrvBrthdt2 = "";

  // 추가운전자면허종별코드2
  public addDrvDrvingLcnsKnd2 = "";

  // 추가운전자면허종별2
  public addDrvDrvingLcnsKndNm2 = "";

  // 추가운전자면허번호2
  public addDrvDrvingLcnsNo2 = "";

  // 추가운전자연락처2
  public addDrvHpNo2 = "";

  // 추가운전자관계코드2
  public addDrvRelClsCd2 = "";

  // 추가운전자관계2
  public addDrvRelClsNm2 = "";

  // 모델(대표차명)
  public repCartypeNm = "";

  // 세부모델(차종명)
  public carClassNm = "";

  // 트림((NICE AB)등급명)
  public carGradeNm = "";

  // 내장색상
  public innrCarColorNm = "";

  // 외장색상
  public xtnlCarColorNm = "";

  // 제조사 옵션(옵션I)
  public carOpt1 = "";

  // 차량용품(옵션II)
  public carOpt2 = "";

  // 선팅메이커명(측후면)
  public suntMakerNm1 = "";

  // 선팅농도(측후면)
  public suntConc1 = "";

  // 선팅메이커명(전면)
  public suntMakerNm2 = "";

  // 선팅농도(전면)
  public suntConc2 = "";

  // 선팅메이커명(선루프)
  public suntMakerNm3 = "";

  // 선팅농도(선루프)
  public suntConc3 = "";

  // 차량가격
  public totCarAmt = 0;

  // 계약기간
  public cntrTermMm = "";

  // 연간약정거리코드
  public prmsDtcClsCd = "";

  // 연간약정거리
  public prmsDtcClsNm = "";

  // 인수옵션코드
  public tkvRtrnYnCd = "";

  // 인수옵션
  public tkvRtrnYnNm = "";

  // 보증금설정금액
  public grtAmt = 0;

  // 보증보험설정금액
  public grtInsrAmt = 0;

  // 담보율
  public pldgRt = 0;

  // 인수금액
  public tkvAmt = 0;

  // 월렌탈료
  public rglRentAmt = 0;

  // 실제월납입료
  public mmRentAmt2 = 0;

  // 정비서비스ID
  public fixSrvcId = "";

  // 정비서비스명
  public fixSrvcNm = "";

  // TCMS제공여부
  public tcmsEntryYn = "";

  // 운전자연령코드
  public drvingAgeCd = "";

  // 운전자연령
  public drvingAgeNm = "";

  // 대인배상1코드
  public psnl1Cd = "";

  // 대인배상1
  public psnl1Nm = "";

  // 대인배상2코드
  public psnl2Cd = "";

  // 대인배상2
  public psnl2Nm = "";

  // 대물배상코드
  public impsnlCd = "";

  // 대물배상
  public impsnlNm = "";

  // 자기신체코드
  public myBodyCd = "";

  // 자기신체
  public myBodyNm = "";

  // 무보험차상해코드
  public noinsrCd = "";

  // 무보험차상해
  public noinsrNm = "";

  // 사고당면책금
  public exempAmt = "";

  // 위약금률코드
  public pnltyAmtTrf = "";

  // 위약금률
  public pnltyAmtTrfNm = "";

  // 반납지연금
  public ovrdAmt = "";

  // 이자율
  public ovrdIntRt = "";

  // 결제수단코드
  public stmtMeansCd = "";

  // 결제수단명
  public stmtMeansNm = "";

  // 결제일
  public stmtDd = "";

  // 은행코드
  public custBankCd = "";

  // 은행명
  public bankNm = "";

  // 계좌번호
  public custAcntNo = "";

  // 예금주명
  public apprvPsnNm = "";

  // 실명번호
  public rlnmNo = "";

  // 카드사코드
  public cardCoCd = "";

  // 카드사
  public cardCoNm = "";

  // 카드번호끝4자리
  public cardNoChk = "";

  // 세금계산서담당자명
  public taxCntpNm = "";

  // 세금계산서담당자전화번호
  public taxTelNo = "";

  // 세금계산서담당자휴대폰번호
  public taxHpNo = "";

  // 세금계산서담당자이메일
  public taxEmail = "";

  // 차량등록증파일ID
  public carRegLicFileId = "";

  // 차량등록증파일URL
  public carRegLicFileUrl = "";

  // 마이페이지 계약상태코드
  public mpCntrStsCd = "";

  // 마이페이지 계약상태명
  public mpCntrStsNm = "";

  // 마이페이지 계약상세상태코드
  public mpCntrDtlStsCd = "";

  // 마이페이지 계약상세상태명
  public mpCntrDtlStsNm = "";

  // 출고예정일자
  public outIntendDt = "";

  // 계약시작일자
  public cntrStDt = "";

  // 계약종료일자
  public cntrEndDt = "";

  // 보증보험수수료
  public grtInsrCmis = 0;

  // 서명구분코드(A28001: PASS, A28002: TOSS, A28003: KAKAO, A28004: 자필서명)
  public signCoCls = "";

  // 서명구분명
  public signCoClsNm = "";

  // 보증금잔액
  public grtBalnAmt = 0;

  // 보증보험잔액
  public grtInsrBalnAmt = 0;

  // 선수금잔액
  public initamtBalnAmt = 0;

  // 선수금
  public initamtAmt = 0;

  // 담보금액 납부여부
  public pldgAmtPaymentYn = "";

  // 계약일시
  public cntrDtm = "";

  // 계약취소일자
  public cnclDt = "";

  // 차량이용확인서 링크
  public cfmLinkUrl = "";

  // 월실납입금액
  public realPaymentAmt = 0;

  // 계약서 링크
  public cntrLinkUrl = "";

  // 계약 메모 리스트
  public cntrMemoList: RentContractMemo[] = [];

  // 상담원 아이디
  public cnslUserId = "";

  // 상담원명
  public cnslUserNm = "";

  // 상담완료여부
  public cnslEndYn = "";

  // 거래처코드
  public erpCustCd = "";

  // 제조사계약번호
  public carCntrNo = "";

  // 생산예정일
  public mnftIntendDt = "";

  // 출고확정일
  public outDcsnDt = "";

  // 출문일
  public googDt = "";

  // 1차 탁송도착일
  public consi1ArvDt = "";

  // 할인 내역
  public odsDcList: DiscountInfo[] = [];

  /**
   * 중고장기
   */

  // 요금제 코드
  public tkvAmtRtFlag = "";

  // 변속
  public grbxNm = "";

  // 최초 등록일
  public regDt = "";

  // 인승
  public seaterClsNm = "";

  // 연식(연형)
  public yearType = "";

  // 연료명
  public fuelNm = "";

  // 주행거리
  public curTravelDtc = "";

  // 배기량
  public engdisp = "";

  // 차량가
  public totalCarAmt = "";

  /**
   * UBR 추가 필드
   */

  // 요금제 코드
  public amtCondCd = "";

  // 요금제명
  public amtCondNm = "";

  // 고정 렌탈료
  public stdRentAmt = "";

  // 주행거리 렌탈료
  public amtPerMile = "";

  // 직접인수 선택
  public dpStsCdNm = "";

  // 직접인수 요청 유저
  public dpRequestUser = "";

  // 직접인수 일시
  public arvReqDtm = "";

  // 직접인수 설정인
  public reqUserNm = "";

  // 직접인수 설정일시
  public reqDtm = "";

  // 배송지 주소
  public poaAddr = "";

  // 보증금 정보
  get rentInsuranceInfoText() {
    if (this.pldgRt === 0) return "면제";

    let insrText = "-";

    if (this.initamtAmt) {
      insrText = `선수금(${this.pldgRt}%) / ${formatCurrency(this.initamtAmt)}`;
    }

    if (this.grtAmt) {
      insrText = `보증금(${this.pldgRt}%) / ${formatCurrency(this.grtAmt)}`;
    }

    // 보증보험
    if (this.grtInsrAmt) {
      insrText = `보증보험(${this.pldgRt}%) / ${formatCurrency(
        this.grtInsrAmt
      )}`;
    }
    return insrText;
  }

  // 납부한 보증금 정보
  get rentInsurancePaymentInfoText() {
    // 면제
    if (this.pldgRt === 0) return "면제";

    let insrText = "-";

    if (this.initamtBalnAmt) {
      insrText = `${formatCurrency(this.initamtBalnAmt)}`;
    }

    if (this.grtAmt) {
      insrText = `${formatCurrency(this.grtBalnAmt)}`;
    }

    // 보증보험
    if (this.grtInsrAmt) {
      insrText = `${formatCurrency(this.grtInsrBalnAmt)}`;
    }
    return insrText;
  }

  // 결제일 정보
  get paymentDayText() {
    if (this.stmtDd) {
      if (this.stmtDd === "99") {
        return "매월 말일";
      }

      return `매월 ${this.stmtDd}일`;
    }
    return "";
  }

  // 카매니저 배정 가능 여부
  get canAssignCarManager() {
    return this.mpCntrStsCd === "A41400" && !this.carmanNm;
  }

  //타고바이 계약 여부
  public tgbyYn = "";

  //타고바이 인수요청 여부
  public tkvReqYn = "";

  //타고바이 인수요청 일자
  public tkvReqDtm = "";

  //타고바이 차량 인수가
  public carTkvAmt = "";

  //타고바이 누적일수
  public accumDays = "";

  //타고바이 미래가치계수
  public ceft = "";

  //타고바이 중도 인수가
  public mdlTkvAmt = "";

  //타고바이 미확정 금액
  public undcAmt = "";

  //타고바이 매출 완료 렌탈료
  public sellCompRentAmt = "";

  //타고바이 월 렌탈료
  public mmRentAmt = "";

  //타고바이 COSS 인수 금액
  public cossTkvAmt = "";

  //계약시점 차령
  public carAge = "";

  //계약시점 주행거리
  public cntrStTravelDtc = "";

  //타고바이 상품유형
  public tbProductGbn = "";
}

export default RentContractDetail;
