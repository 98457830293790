import { UsedCarCatalogImage } from "./UsedCarCatalogImage";
import { UsedCarOptionInfo } from "./UsedCarOptionInfo";
import { UsedCarFixHistory } from "./UsedCarFixHistory";
import { TkvAmtSetResListVO } from "./TkvAmtSetResListVO";
import { RentFeeSetResVO } from "./RentFeeSet";
import TagMaster from "./TagMaster";

export interface CarVideoInfo {
  videoSeq: string;
  prodId: string;
  videoRate: string;
  videoBtn: string;
  videoUrl: string;
  useYn: string;
}
export default class UsedCarCatalogDetail {
  // MODE 상품ID
  public prodId = "";

  // MODE 상품명
  public prodNm = "";

  // COSS 상품ID
  public cossProdId = "";

  // 판매구분
  public ncarClsFlag = "";

  // 판매구분명
  public ncarClsFlagNm = "";

  // 대표 차량 외관 이미지
  public repCarImg = "";

  // 대표 차량등급 ID
  public modeGrdId = "";

  // COSS 모델ID
  public cossModlId = "";

  // (NICE AB)등급명
  public carGradeNm = "";

  // 차량가격(신차가격)
  public carAmt = 0;

  // 차종ID
  public cartypeId = "";

  // 차종명
  public cartypeNm = "";

  // 차정ID
  public cartypeDtlId = "";

  // 차정명
  public cartypeDtlNm = "";

  // MODE 연료명
  public modeFuelNm = "";

  // 연료코드
  public fuelCd = "";

  // 연료명
  public fuelNm = "";

  // 제조사ID
  public carMakerId = "";

  // 제조사명
  public carMakerNm = "";

  // 수입차여부
  public importYn = "";

  // 배기량
  public engdisp = "";

  // 차량재고
  public prodStock = 0;

  // 주요옵션 리스트
  public mainOptNms = "";

  // 대표차명
  public repCarTypeNm = "";

  // 단산여부
  public dnsnYn = "";

  // 단산사유
  public dnsnRsn = "";

  // 차량ID
  public carId = "";

  // 연식(연형)
  public yearType = "";

  // 주행거리
  public curTravelDtc = "";

  // 인승
  public seaterClsNm = "";

  // 변속기
  public grbxNm = "";

  // 최초 등록일
  public regDt = "";

  // 차량번호
  public carNo = "";

  // 내부 색상 ID
  public innrCarColorId = "";

  // 내부 색상명
  public innrCarColorNm = "";

  // coss 내부 색상명
  public cossInnrCarColorNm = "";

  // 내부 차량색상 이미지 URL
  public innrColorImgUrl = "";

  // 외부 색상 ID
  public xtnlCarColorId = "";

  // 외부 색상명
  public xtnlCarColorNm = "";

  // coss 내부 색상명
  public cossXtnlCarColorNm = "";

  // 외부 차량색상 이미지 URL
  public xtnlColorImgUrl = "";

  // 외장색상 이미지 URL
  public xtnlClrImgUrl = "";

  // MD 차량소개
  public mdComment = "";

  // 차량 세부모델
  public carClassNm = "";

  // 차량 이미지 정보
  public carImgList: UsedCarCatalogImage[] = [];

  // 옵션 리스트
  public carOptionsList: UsedCarOptionInfo[] = [];

  // 정비 이력
  public fixContList: UsedCarFixHistory[] = [];

  // 렌탈료 세팅 리스트
  public rentFeeSetList: RentFeeSetResVO[] = [];

  // 인수가 세팅 리스트
  public tkvAmtSetList: TkvAmtSetResListVO[] = [];

  // 총 옵션가
  public totOptAmt = 0;

  // 태그 리스트
  public tagList: TagMaster[] = [];

  // 비디오 리스트
  public videoList: CarVideoInfo[] = [];

  // 3D image 사용여부
  public view3dYn: string = '';
}