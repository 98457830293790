import React, { useCallback, useState } from "react";
import { FormikProps, FormikValues } from "formik";
import strings from "../../constants/strings";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../types";
import { alertError, alertSuccess } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  createDefaultRentOptionInfoParam,
  createFormInfo,
  createInitialValues,
  createInitialValuesFromDefaultOptionInfo,
  createValidationSchema,
} from "./utils/form-utils";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import DefaultRentOptionInfo from "../../../../../models/DefaultRentOptionInfo";
import {
  addDefaultRentOptionInfo,
  editDefaultRentOptionInfo,
} from "../../../../../apis/nc-atmsts";

interface Props extends ModalProps {
  defaultOptionInfo?: DefaultRentOptionInfo;
}

const DefaultRentOptionInfoModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, defaultOptionInfo, onDataChange } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  let formik: FormikProps<FormikValues>;
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  const onModalOpen = async () => {
    if (defaultOptionInfo) {
      setInitialValues(
        createInitialValuesFromDefaultOptionInfo(defaultOptionInfo)
      );
    } else {
      setInitialValues(createInitialValues());
    }
  };

  /**
   * Private defaultOptionInfo
   */

  const requestAddDefaultRentOption = async (values: FormikValues) => {
    setConfirmLoading(true);
    try {
      const params = createDefaultRentOptionInfoParam(values);
      await addDefaultRentOptionInfo(params);
      alertSuccess("가격기준 정보가 등록되었습니다");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditDefaultRentOption = async (values: FormikValues) => {
    if (defaultOptionInfo) {
      setConfirmLoading(true);
      try {
        const params = createDefaultRentOptionInfoParam(values);
        await editDefaultRentOptionInfo(defaultOptionInfo.prdbaseId, params);
        alertSuccess("가격기준 정보가 변경되었습니다");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const getModalTitle = useCallback(() => {
    if (defaultOptionInfo) {
      return strings.MODAL_TITLE_DETAIL;
    }
    return strings.MODAL_TITLE_ADD;
  }, [defaultOptionInfo]);

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (defaultOptionInfo) {
      await requestEditDefaultRentOption(values);
    } else {
      await requestAddDefaultRentOption(values);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      visible={visible}
      title={getModalTitle()}
      onOpen={onModalOpen}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
        forms={createFormInfo()}
        validationSchema={createValidationSchema()}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

DefaultRentOptionInfoModal.defaultProps = {};
export default DefaultRentOptionInfoModal;
