import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import CarBooking from "../../models/CarBooking";
import CarBookingDetail from "../../models/CarBookingDetail";

const apiRootUrl = `${ROOT_URL}/admin/prereserv`;

/**
 * 사전예약 신청 리스트 조회
 */
export const fetchCarBookingHistories = async (
  stDt: string,
  endDt: string
): Promise<ListItemResponse<CarBooking>> => {
  const res = await request(HttpMethod.Get, apiRootUrl, { stDt, endDt });

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(CarBooking, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 사전예약 상담여부 상세 조회
 */

export const fetchCarBookingDetail = async (
  preRsvId: string
): Promise<CarBookingDetail> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/${preRsvId}`);
  const { data } = res;
  const { response } = data;
  return plainToClass(CarBookingDetail, response);
};

/**
 * 사전예약 상담여부 및 메모 수정
 */
export const editCarBookingHistory = async (
  preRsvId: string,
  callYn?: string,
  picMemo?: string
) => {
  await request(HttpMethod.Put, `${apiRootUrl}/${preRsvId}`, undefined, {
    callYn,
    picMemo,
  });
};
