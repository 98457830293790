import React from "react";
import { ModalProps } from "../../../../../types";
import Modal from "../../../../../../components/shared/feedback/antd/Modal";
import DirectSurveyResultDetail from "../../../../../../models/DirectSurvey/DirectSurveyResultDetail";
import { createDirectSurveyResultCols } from "../utils/table-utils";
import ClientRowTable from "../../../../../../components/shared/data-display/Tables/components/ClientRowTable";

interface Props extends ModalProps {
  details?: DirectSurveyResultDetail[];
  chartLabel?: string;
}

const ChartDetailModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, details, chartLabel } = props;
  const cols = createDirectSurveyResultCols();
  return (
    <Modal
      title="답변 상세"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {chartLabel}
        </span>
      </div>
      <ClientRowTable
        autoHeight
        floatingFilter={false}
        height={400}
        cols={cols}
        rowData={details}
      />
    </Modal>
  );
};

export default ChartDetailModal;
