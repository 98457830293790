/* eslint-disable no-console */
import { plainToClass } from "class-transformer";
import { ListItemResponse, ListRequestParams } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import McCarCatalog from "../../models/McCarCatalog";
import McCarCatalogDetail from "../../models/McCarCatalogDetail";
import { UcMmProdRsvInfo } from "./types";
import { McProdNmList } from "@models/McProdNmTag";

export interface McCatalogListRequestParams extends ListRequestParams {
  // 등록일자 조회시작일자
  regStDt?: string;
  // 등록일자 조회종료일자
  regEndDt?: string;
  // COSS ID
  cossId?: string;
  // 차량번호
  carNo?: string;
  // 제조사
  brandNm?: string;
  // 모델
  repCarClassNm?: string;
  // 세부모델
  carClassNm?: string;
  // 트림
  carGradeNm?: string;
  // 연식
  yearType?: string;
  // 주행거리시작
  startLastDtc?: string;
  // 주행거리종료
  endLastDtc?: string;
  // 연료
  fuel?: string;
  // 변속
  istdTrans?: string;
  // 차량정보
  carNmInfo?: string;
  // 정비이력
  fixYn?: string;
  // niceDnr연결여부
  niceConYn?: string;
  // 상품유형
  ncarClsFlag?: string;
  // 월렌트 등록여부
  useYn?: string;
  // 월렌트 노출여부
  viewYn?: string;
  // 월렌트 예약 가능 여부
  rsvPsblYn?: string;
}

/**
 * 상품 리스트 조회
 */
export const fetchMcCatalogs = async (
  params?: McCatalogListRequestParams
): Promise<ListItemResponse<McCarCatalog>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/mm-catalogs`,
    params as Record<string, any>
  );

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(McCarCatalog, list as Array<JSON>),
    count: totalCnt,
  };
};

/**
 * 월렌트 메인 태그 리스트 조회(GET: ~​/admin​/mm-prod-tag-list)
 */
export const fetchMcProdNmList = async (): Promise<McProdNmList> => {
  const url = `${ROOT_URL}/admin/mm-prod-tag-list`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  return plainToClass(McProdNmList, response);
};

/**
 * 상품 상세 조회
 */
export const fetchMcCatalogDetail = async (
  prodId: string
): Promise<McCarCatalogDetail> => {
  const url = `${ROOT_URL}/admin/mm-catalogs/${prodId}`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  return plainToClass(McCarCatalogDetail, response);
};

/**
 * 상품 노출상태 변경 (Y/N or Y/Y)
 */
export const updateMcCatalogDisplayState = async (
  prodId: string,
  carId: string,
  useYn: string,
  viewYn: string
) => {
  const url = `${ROOT_URL}/admin/mm-prod-viewyn`;
  const response = await request(HttpMethod.Post, url, undefined, {
    prodId,
    carId,
    useYn,
    viewYn,
  });
};


export const updateMcRsvibilityState = async (
  prodId: string,
  ucMmProdRsvInfo : UcMmProdRsvInfo
) => {
  const url = `${ROOT_URL}/admin/rsv-psbl?modeProdId=${prodId}`;
  
  await request(HttpMethod.Put, url, undefined, ucMmProdRsvInfo);
};


/**
 * 주요 옵션 수정
 */
 export const updateMcCatalogsOptions = async (prodId: string, carId: string, commCdList: string[]) => {
  const url = `${ROOT_URL}/admin/mm-catalogs/${prodId}/options`;
  await request(HttpMethod.Post, url, undefined, {carId, commCdList});
};


/**
 * 기본렌탈료 가져오기
 */
 export const updateMcRentAmt = async (modeProdId: string) => {
  const url = `${ROOT_URL}/admin/mm-rent-fee?modeProdId=${modeProdId}`;
  await request(HttpMethod.Put, url, undefined, undefined);
};