import { FormikProps, FormikValues } from "formik";
import { FormOption } from "../../../data-entry/FormBuilder/types";

export enum FilterElementType {
  Input,
  NumberInput,
  Select,
  DateRangePicker,
  DatePicker,
  Custom,
  RadioDateRangePicker,
  NumberRange
}

export interface FilterFormInfo {
  key: string;
  type: FilterElementType;
  label?: string;
  placeholder?: string;
  required?: boolean;
  render?: (renderProps: FormikProps<FormikValues>) => any;
  onChange?: (value: any) => void;
  options?: Array<FormOption>;
  span?: number;
  extraOptions?: Record<string, any>;
}
