import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { createCarManagerCols } from "./utils/table-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import { alertError } from "../../../utils/render-utils";
import CarManagerAddModal from "./pages/CarManagerAddModal";
import CarManager from "../../../models/CarManager";
import { fetchCarManagers } from "../../../apis/car-managers";
import { getErrorMessage } from "../../../utils/common-utils";
import CarManagerEditModal from "./pages/CarManagerEditModal";

const CarManagerManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [carManagerAddModalVisible, setCarManagerAddModalVisible] = useState(
    false
  );
  const [
    carManagerDetailModalVisible,
    setCarManagerDetailModalVisible,
  ] = useState(false);
  const [selectedCarManagerId, setSelectedCarManagerId] = useState<
    string | undefined
  >();
  const [rows, setRows] = useState<Array<CarManager> | undefined>(undefined);
  const [totalCount, setTotalCount] = useState(0);
  const cols = createCarManagerCols();

  useEffect(() => {
    (async function fetchData() {
      await requestCarManagers();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestCarManagers = async () => {
    try {
      const carManagers = await fetchCarManagers();
      const { items, count } = carManagers;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
      setRows([]);
    }
  };

  /**
   * Event Actions
   */

  const handleAddCarManagerClick = () => {
    setSelectedCarManagerId(undefined);
    setCarManagerAddModalVisible(true);
  };

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { carmanId } = data;
    setSelectedCarManagerId(carmanId);
    setCarManagerDetailModalVisible(true);
  };

  const createActions = () => {
    return [
      {
        title: "카매니저 등록",
        onClick: handleAddCarManagerClick,
      },
    ];
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout filterable={false} headerTitle="카매니저 관리(개선 예정)">
        <TableLayout
          gridApi={gridApi}
          title="카매니저 리스트"
          totalCount={totalCount}
          rightActions={createActions()}
          hideSelectionCountText
          methodName="/admin/carman"
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            cols={cols}
            frameworkComponents={{
              ...createActionCellRenderers(undefined, handleEditClick, {
                hideDeleteButton: true,
              }),
            }}
          />
        </TableLayout>
      </ContentLayout>
      <CarManagerAddModal
        visible={carManagerAddModalVisible}
        onCancel={() => {
          setCarManagerAddModalVisible(false);
        }}
        onDataChange={async () => {
          gridApi?.showLoadingOverlay();
          await requestCarManagers();
          gridApi?.hideOverlay();
        }}
      />
      <CarManagerEditModal
        carmanId={selectedCarManagerId}
        visible={carManagerDetailModalVisible}
        onCancel={() => {
          setCarManagerDetailModalVisible(false);
        }}
        onDataChange={async () => {
          gridApi?.showLoadingOverlay();
          await requestCarManagers();
          gridApi?.hideOverlay();
        }}
      />
    </>
  );
};

CarManagerManager.defaultProps = {};
export default CarManagerManager;
