import { HttpMethod, request } from "../../network/request";
import { plainToClass } from "class-transformer";
import {
  CooperResponse,
  CooperMaster,
  AddCooperNmParam,
  UcCooperNmModParm,
  CooperDetailRequest,
} from "./types";
import AucCooperDetail from "../../models/AucCooperDetail";
import { ROOT_URL } from "../../network/api-urls";

/**
 * 제휴사 등록
 * @param params
 */
export const insertUcCooperCompanyDetail = async (params: AddCooperNmParam) => {
  await request(HttpMethod.Post, `${ROOT_URL}/admin/auc-company`, undefined, params);
};

/**
 * 제휴사 수정
 * @param params
 */
 export const updateUcCooperCompanyDetail = async (compCd: string, compNm: string) => {  
  const params: UcCooperNmModParm = {
    compNm,
    compMail : "",
  };
  await request(HttpMethod.Post, `${ROOT_URL}/admin/auc-company/${compCd}`, undefined, params);
};

/**
 * 제휴사 리스트 조회
 */
export const selectUcCooperCompanyList = async (): Promise<
CooperResponse<CooperMaster>
> => {
  const res = await request(HttpMethod.Get, `${ROOT_URL}/admin/auc-company`);
  return res.data.response;
};


/**
 * 제휴사 상세 조회
 */
export const selectUcCooperCompanyDetail = async (
  compCd: string
): Promise<AucCooperDetail> => {
  const res = await request(HttpMethod.Get, `${ROOT_URL}/admin/auc-company/${compCd}`);

  const { data } = res;
  const { response } = data;
  return plainToClass(AucCooperDetail, response);
};

/**
 * 제휴사 코드 수정
 */
 export const updateUcCooperCompanyCond = async (compCd: string, commCdList: CooperDetailRequest) => {
  const url = `${ROOT_URL}/admin/auc-company/${compCd}/cntr-cond`;
  await request(HttpMethod.Post, url, undefined, commCdList);
};
