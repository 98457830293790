/**
 * 만족도 조사 설문 상세
 */
export default class SurveyMasterDetail {
  // 설문질문ID
  public srveQstnId = "";

  // 설문그룹코드
  public srveGrpCd = "";

  // 설문분류코드
  public srveClfCd = "";

  // 설문내용
  public srveCntn = "";

  // 말머리구분코드(U:유저명/M:카매니저명/C:법인명/N:미사용)
  public headClsCd = "";

  // 사용여부
  public useYn = "";

  // 우선순위
  public priority = "";

  // 설문문항개수
  public srveQitmCnt = "";

  public questionList: Array<QuestionInfo> = [];

  public replyList: Array<ReplyInfo> = [];
}

export class QuestionInfo {
  // 설문질문ID
  public srveQstnId = "";

  // 점수
  public point = "";

  // 설문문항내용
  public srveQitmCont = "";

  // 말머리구분코드
  public headClsCd = "";

  // 답변가능수
  public replyAvailCnt = 0;
}

export class ReplyInfo {
  // 설문질문ID
  public srveQstnId = "";

  // 설문응답항목코드
  public srveReplyItemCd = "";

  // 설문응답항목코드명
  public srveReplyItemNm = "";

  // 텍스트입력여부
  public txtInsYn = "";

  // 설문 그룹 코드
  public replyClsCd = "";
}
