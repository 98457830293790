import React, { useEffect, useState } from "react";
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import moment from "moment";
import { Table } from "antd";
import styled from "styled-components";
import { createActionCellRenderers } from "../../../utils/table-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import {
  getPageIndex,
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import { createSMSTemplateCols } from "./utils/table-utils";
import { fetchSMSTemplates } from "../../../apis/sms-template";
import SMSTemplate from "../../../models/SMSTemplate";
import SMSTemplateFormModal from "./pages/SMSTemplateFormModal";
import {
  fetchSkuReport,
  fetchSkuReportThumbnail,
} from "../../../apis/sku-report";
import {
  ReportTotalSection,
  ReportTotalTableListWrapper,
  ReportTotalWrapper,
  TableWrapper,
  TotalCount,
} from "./style";
import SkuReportType from "../../../models/SkuReport";
import { NCAR_CLS_FLAG_OPTIONS, SRVC_ID_OPTIONS } from "./constants/enums";
import {
  FuelType,
  ReportTableDataType,
  SkuReportThumbnailResponse,
} from "./@types";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import TableLayout from "../../../components/shared/layout/TableLayout";
import ClientRowTableDefs from "../../../components/shared/data-display/Tables/components/CluentRowTableDefs";

const getPercent = (x: number, y: number) => {
  if (x === 0) return 0;

  return ((x / y) * 100).toFixed(1);
};

const getReportTableData = (
  res: SkuReportThumbnailResponse
): ReportTableDataType => {
  return {
    total: {
      nc: res.todayTrnsN + res.todayTrnsY,
      uc: res.usdCarSelect + res.usdCarReturn + res.usdCarJeju,
      tp: res.tagoPay,
      today: res.todayTrnsY,
      fast: res.todayTrnsN,
      total: res.totalCnt,
      ucSuv: res.usdSelectSuv + res.usdReturnSuv + res.usdJejuSuv,
      ucSedan: res.usdSelectSedan + res.usdReturnSedan + res.usdJejuSedan,
      ncSuv: res.todaySuv,
      ncSedan: res.todaySedan,
      tpSuv: res.tagoSuv,
      tpSedan: res.tagoSedan,
    },
    data: {
      nc: {
        EV: {
          SUV: res.todaySuvEv,
          Sedan: res.todaySedanEv,
          total: res.todayEv,
        },
        HEV: {
          SUV: res.todaySuvHev,
          Sedan: res.todaySedanHev,
          total: res.todayHev,
        },
        ICV: {
          SUV: res.todaySuvIcv,
          Sedan: res.todaySedanIcv,
          total: res.todayIcv,
        },
        total: {
          SUV: res.todaySuv,
          Sedan: res.todaySedan,
          total: res.todayTrnsY + res.todayTrnsN,
        },
      },
      tp: {
        EV: {
          SUV: res.tagoSuvEv,
          Sedan: res.tagoSedanEv,
          total: res.tagoEv,
        },
        HEV: {
          SUV: res.tagoSuvHev,
          Sedan: res.tagoSedanHev,
          total: res.tagoHev,
        },
        ICV: {
          SUV: res.tagoSuvIcv,
          Sedan: res.tagoSedanIcv,
          total: res.tagoIcv,
        },
        total: {
          SUV: res.tagoSuv,
          Sedan: res.tagoSedan,
          total: res.tagoPay,
        },
      },
      uc: {
        EV: {
          SUV: res.usdSelectSuvEv + res.usdReturnSuvEv + res.usdJejuSuvEv,
          Sedan:
            res.usdSelectSedanEv + res.usdReturnSedanEv + res.usdJejuSedanEv,
          total: res.usdSelectEv + res.usdReturnEv + res.usdJejuEv,
        },
        HEV: {
          SUV: res.usdSelectSuvHev + res.usdReturnSuvHev + res.usdJejuSuvHev,
          Sedan:
            res.usdSelectSedanHev + res.usdReturnSedanHev + res.usdJejuSedanHev,
          total: res.usdSelectHev + res.usdReturnHev + res.usdJejuHev,
        },
        ICV: {
          SUV: res.usdSelectSuvIcv + res.usdReturnSuvIcv + res.usdJejuSuvIcv,
          Sedan:
            res.usdSelectSedanIcv + res.usdReturnSedanIcv + res.usdJejuSedanIcv,
          total: res.usdSelectIcv + res.usdReturnIcv + res.usdJejuIcv,
        },
        total: {
          SUV: res.usdSelectSuv + res.usdReturnSuv + res.usdJejuSuv,
          Sedan: res.usdSelectSedan + res.usdReturnSedan + res.usdJejuSedan,
          total: res.usdCarSelect + res.usdCarReturn + res.usdCarJeju,
        },
      },
    },
  };
};

type ReportTotalDataTableType = {
  carShapeNames: { name: string; count: number }[];
  tableData: {
    key: string;
    title: string;
    SUV: number;
    Sedan: number;
    total: number;
  }[];
};

type ReportTotalDataType = Record<"nc" | "uc" | "tp", ReportTotalDataTableType>;

const filterInitialValues = {
  selDt: moment(),
  srvcId: SRVC_ID_OPTIONS[0].value,
  ncarClsFlag: NCAR_CLS_FLAG_OPTIONS[0].value,
  carMakerNm: "",
  carClssNm: "",
  carModlNm: "",
};

function sum(values: any[], col: any) {
  let sum = 0;
  values.forEach((value) => {
    sum += Number(value[col]);
  });
  return sum;
}

function createFooterData(rows: any[]) {
  const result = [];

  result.push({
    srvcNm: "Total",
    prodStock: sum(rows, "prodStock"),
    totalCntrCnt: sum(rows, "totalCntrCnt"),
    todayCnclCnt: sum(rows, "todayCnclCnt"),
    realCntrCnt: sum(rows, "realCntrCnt"),
    minusCarCnt: sum(rows, "minusCarCnt"),
    addCarCnt: sum(rows, "addCarCnt"),
    otherCntrCnt: sum(rows, "otherCntrCnt"),
    extraProdStock: sum(rows, "extraProdStock"),
  });

  return result;
}

const SkuReport: React.FC = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>();
  const cols = createSMSTemplateCols();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [filterValues, setFilterValues] = useState(filterInitialValues);

  const [reportThumbnailData, setReportThumbnailData] = useState<
    ReportTableDataType
  >();
  const [rows, setRows] = useState<SkuReportType[]>([]);
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState<any>([]);
  const [logInfo, setLogInfo] = useState<string>("");

  useEffect(() => {
    (async function fetchData() {
      await requestDataList();
    })();
  }, []);

  const filterForms = [
    {
      key: "selDt",
      type: FilterElementType.DatePicker,
      label: "일자",
      placeholder: "일자를 선택해주세요",
    },
    {
      key: "srvcId",
      type: FilterElementType.Select,
      label: "차량구분",
      options: SRVC_ID_OPTIONS,
    },
    {
      key: "ncarClsFlag",
      type: FilterElementType.Select,
      label: "판매유형",
      options: NCAR_CLS_FLAG_OPTIONS,
    },
    {
      key: "carMakerNm",
      type: FilterElementType.Input,
      label: "제조사",
      placeholder: "제조사를 입력해주세요",
    },
    {
      key: "carClssNm",
      type: FilterElementType.Input,
      label: "차명",
      placeholder: "차명을 입력해주세요",
    },
    {
      key: "carModlNm",
      type: FilterElementType.Input,
      label: "모델명",
      placeholder: "모델명을 입력해주세요",
    },
  ];

  /**
   * Private Functions
   */
  const requestDataList = async (values = filterInitialValues) => {
    try {
      // 검색조건 설정
      const appliedFilters = JSON.stringify(values);
      setLogInfo(appliedFilters);
      const {
        selDt,
        srvcId,
        ncarClsFlag,
        carMakerNm,
        carClssNm,
        carModlNm,
      } = values;
      setFilterValues(values);
      const selDtParam = moment(selDt).format("YYYYMMDD");

      const reportList = await fetchSkuReport(
        selDtParam,
        srvcId,
        ncarClsFlag,
        carMakerNm,
        carClssNm,
        carModlNm
      );

      const reportsThumbnail = await fetchSkuReportThumbnail(selDtParam);

      setReportThumbnailData(getReportTableData(reportsThumbnail));

      const { items, count } = reportList;

      setRows(items);
      setTotalCount(count);
      setPinnedBottomRowData(createFooterData(items));
    } catch (e) {
      setRows([]);
      setTotalCount(0);
    }
  };

  /**
   * Event Actions
   */
  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const template = data as SMSTemplate;
    setSelectedDataId(template.smsMsgId);
    setModalVisible(true);
  };

  const handleSearchSubmit = (values: any) => {
    requestDataList(values);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout
        filterable
        filterInitialValues={filterInitialValues}
        filterValues={filterValues}
        filterForms={filterForms}
        onSearchSubmit={handleSearchSubmit}
        headerTitle="SKU Report(테스트)"
      >
        <AddStatisticsWrapper>
          <header>
            <h2>SKU Report</h2>
          </header>
          <main>
            {reportThumbnailData && (
              <ReportTotalSection>
                <ReportTotalWrapper>
                  <h3>Report Total</h3>
                  <ul>
                    <li>
                      Direct(중고장기){" "}
                      <span>{reportThumbnailData.total.uc}대</span>
                    </li>
                    <li className="bar" />
                    <li>
                      타고페이 소계{" "}
                      <span>{reportThumbnailData.total.tp}대</span>
                    </li>
                    <li className="bar" />
                    <li>
                      당일배송 <span>{reportThumbnailData.total.today}대</span>
                    </li>
                    <li className="bar" />
                    <li>
                      빠른배송 <span>{reportThumbnailData.total.fast}대</span>
                    </li>
                  </ul>
                  <TotalCount>
                    <p>
                      합계 <span>{reportThumbnailData.total.total}</span>
                      <em>대</em>
                    </p>
                  </TotalCount>
                </ReportTotalWrapper>
                <ReportTotalTableListWrapper>
                  <li>
                    <header>
                      <h3>당일 ∙ 빠른배송</h3>
                      <ul>
                        <li>
                          SUV :{" "}
                          {getPercent(
                            reportThumbnailData.total.ncSuv,
                            reportThumbnailData.total.nc
                          )}
                          %
                        </li>
                        <li className="bar" />
                        <li>
                          Sedan :{" "}
                          {getPercent(
                            reportThumbnailData.total.ncSedan,
                            reportThumbnailData.total.nc
                          )}
                          %
                        </li>
                        <li>{!reportThumbnailData.total.nc ? "0%" : "100%"}</li>
                      </ul>
                    </header>
                    <TableWrapper>
                      <table>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>SUV</th>
                            <th>Sedan</th>
                            <th>계</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(Object.keys(
                            reportThumbnailData.data.nc
                          ) as FuelType[]).map((data) => (
                            <tr>
                              <td>{data !== "total" ? data : "합계"}</td>
                              <td>{reportThumbnailData.data.nc[data].SUV}</td>
                              <td>{reportThumbnailData.data.nc[data].Sedan}</td>
                              <td>{reportThumbnailData.data.nc[data].total}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableWrapper>
                  </li>
                  <li>
                    <header>
                      <h3>타고페이</h3>
                      <ul>
                        <li>
                          SUV :{" "}
                          {getPercent(
                            reportThumbnailData.total.tpSuv,
                            reportThumbnailData.total.tp
                          )}
                          %
                        </li>
                        <li className="bar" />
                        <li>
                          Sedan :{" "}
                          {getPercent(
                            reportThumbnailData.total.tpSedan,
                            reportThumbnailData.total.tp
                          )}
                          %
                        </li>
                        <li>{!reportThumbnailData.total.tp ? "0%" : "100%"}</li>
                      </ul>
                    </header>
                    <TableWrapper>
                      <table>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>SUV</th>
                            <th>Sedan</th>
                            <th>계</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(Object.keys(
                            reportThumbnailData.data.tp
                          ) as FuelType[]).map((data) => (
                            <tr>
                              <td>{data !== "total" ? data : "합계"}</td>
                              <td>{reportThumbnailData.data.tp[data].SUV}</td>
                              <td>{reportThumbnailData.data.tp[data].Sedan}</td>
                              <td>{reportThumbnailData.data.tp[data].total}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableWrapper>
                  </li>
                  <li>
                    <header>
                      <h3>Direct(중고장기)</h3>
                      <ul>
                        <li>
                          SUV :{" "}
                          {getPercent(
                            reportThumbnailData.total.ucSuv,
                            reportThumbnailData.total.uc
                          )}
                          %
                        </li>
                        <li className="bar" />
                        <li>
                          Sedan :{" "}
                          {getPercent(
                            reportThumbnailData.total.ucSedan,
                            reportThumbnailData.total.uc
                          )}
                          %
                        </li>
                        <li>{!reportThumbnailData.total.uc ? "0%" : "100%"}</li>
                      </ul>
                    </header>
                    <TableWrapper>
                      <table>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>SUV</th>
                            <th>Sedan</th>
                            <th>계</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(Object.keys(
                            reportThumbnailData.data.uc
                          ) as FuelType[]).map((data) => (
                            <tr>
                              <td>{data !== "total" ? data : "합계"}</td>
                              <td>{reportThumbnailData.data.uc[data].SUV}</td>
                              <td>{reportThumbnailData.data.uc[data].Sedan}</td>
                              <td>{reportThumbnailData.data.uc[data].total}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableWrapper>
                  </li>
                </ReportTotalTableListWrapper>
              </ReportTotalSection>
            )}
          </main>
        </AddStatisticsWrapper>
        <TableLayout
          gridApi={gridApi}
          title="SKU Report 상세내역"
          totalCount={totalCount}
          methodName="/admin/carman/dtyagcy"
          logInfo={logInfo}
        >
          <ClientRowTableDefs
            apiRef={(ref) => {
              setGridApi(ref);
            }}
            rowData={rows}
            cols={cols}
            pinnedBottomRowData={pinnedBottomRowData}
            frameworkComponents={{
              ...createActionCellRenderers(undefined, handleDetailClick, {
                hideDeleteButton: true,
              }),
            }}
          />
        </TableLayout>
      </ContentLayout>
      {/* <PaginationTableLayout
        methodName="/admin/sku-report"
        apiRef={(ref) => {
          setGridApi(ref);
        }}
        headerTitle="SKU Report"
        tableHeaderTitle="SKU Report 상세내역"
        onDataLoad={onDataLoad}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        totalCount={totalCount}
        hideFilterAction
        frameworkComponents={{
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
        }}
        addStatistics={{
          title: "SKU Report",
          component: reportThumbnailData && (
            <ReportTotalSection>
              <ReportTotalWrapper>
                <h3>Report Total</h3>
                <ul>
                  <li>
                    Direct(중고장기){" "}
                    <span>{reportThumbnailData.total.uc}대</span>
                  </li>
                  <li className="bar" />
                  <li>
                    타고페이 소계 <span>{reportThumbnailData.total.tp}대</span>
                  </li>
                  <li className="bar" />
                  <li>
                    당일배송 <span>{reportThumbnailData.total.today}대</span>
                  </li>
                  <li className="bar" />
                  <li>
                    빠른배송 <span>{reportThumbnailData.total.fast}대</span>
                  </li>
                </ul>
                <TotalCount>
                  <p>
                    합계 <span>{reportThumbnailData.total.total}</span>
                    <em>대</em>
                  </p>
                </TotalCount>
              </ReportTotalWrapper>
              <ReportTotalTableListWrapper>
                <li>
                  <header>
                    <h3>당일 ∙ 빠른배송</h3>
                    <ul>
                      <li>
                        SUV :{" "}
                        {getPercent(
                          reportThumbnailData.total.ncSuv,
                          reportThumbnailData.total.nc
                        )}
                        %
                      </li>
                      <li className="bar" />
                      <li>
                        Sedan :{" "}
                        {getPercent(
                          reportThumbnailData.total.ncSedan,
                          reportThumbnailData.total.nc
                        )}
                        %
                      </li>
                      <li>{!reportThumbnailData.total.nc ? "0%" : "100%"}</li>
                    </ul>
                  </header>
                  <TableWrapper>
                    <table>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>SUV</th>
                          <th>Sedan</th>
                          <th>계</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(Object.keys(
                          reportThumbnailData.data.nc
                        ) as FuelType[]).map((data) => (
                          <tr>
                            <td>{data !== "total" ? data : "합계"}</td>
                            <td>{reportThumbnailData.data.nc[data].SUV}</td>
                            <td>{reportThumbnailData.data.nc[data].Sedan}</td>
                            <td>{reportThumbnailData.data.nc[data].total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TableWrapper>
                </li>
                <li>
                  <header>
                    <h3>타고페이</h3>
                    <ul>
                      <li>
                        SUV :{" "}
                        {getPercent(
                          reportThumbnailData.total.tpSuv,
                          reportThumbnailData.total.tp
                        )}
                        %
                      </li>
                      <li className="bar" />
                      <li>
                        Sedan :{" "}
                        {getPercent(
                          reportThumbnailData.total.tpSedan,
                          reportThumbnailData.total.tp
                        )}
                        %
                      </li>
                      <li>{!reportThumbnailData.total.tp ? "0%" : "100%"}</li>
                    </ul>
                  </header>
                  <TableWrapper>
                    <table>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>SUV</th>
                          <th>Sedan</th>
                          <th>계</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(Object.keys(
                          reportThumbnailData.data.tp
                        ) as FuelType[]).map((data) => (
                          <tr>
                            <td>{data !== "total" ? data : "합계"}</td>
                            <td>{reportThumbnailData.data.tp[data].SUV}</td>
                            <td>{reportThumbnailData.data.tp[data].Sedan}</td>
                            <td>{reportThumbnailData.data.tp[data].total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TableWrapper>
                </li>
                <li>
                  <header>
                    <h3>Direct(중고장기)</h3>
                    <ul>
                      <li>
                        SUV :{" "}
                        {getPercent(
                          reportThumbnailData.total.ucSuv,
                          reportThumbnailData.total.uc
                        )}
                        %
                      </li>
                      <li className="bar" />
                      <li>
                        Sedan :{" "}
                        {getPercent(
                          reportThumbnailData.total.ucSedan,
                          reportThumbnailData.total.uc
                        )}
                        %
                      </li>
                      <li>{!reportThumbnailData.total.uc ? "0%" : "100%"}</li>
                    </ul>
                  </header>
                  <TableWrapper>
                    <table>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>SUV</th>
                          <th>Sedan</th>
                          <th>계</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(Object.keys(
                          reportThumbnailData.data.uc
                        ) as FuelType[]).map((data) => (
                          <tr>
                            <td>{data !== "total" ? data : "합계"}</td>
                            <td>{reportThumbnailData.data.uc[data].SUV}</td>
                            <td>{reportThumbnailData.data.uc[data].Sedan}</td>
                            <td>{reportThumbnailData.data.uc[data].total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TableWrapper>
                </li>
              </ReportTotalTableListWrapper>
            </ReportTotalSection>
          ),
        }}
      /> */}
      <SMSTemplateFormModal
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        templateId={selectedDataId}
        onDataChange={() => {
          refreshServerSideTable(gridApi);
        }}
      />
    </>
  );
};

SkuReport.defaultProps = {};
export default SkuReport;

export const AddStatisticsWrapper = styled.div`
  background: white;
  overflow: hidden;
  margin-bottom: 24px;

  & > header {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 24px;
    border-bottom: 1px solid #eeeff5;
    box-sizing: border-box;

    & > h2 {
      font-weight: bold;
      font-size: 18px;
    }
  }

  & > main {
    padding: 28px 24px 16px;
    height: calc(100% - 64px);
    box-sizing: border-box;
  }
`;
