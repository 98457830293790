export const COSS_COLOR_CLS_IN = "626001";
export const COSS_COLOR_CLS_EX = "626002";

class CossColor {
  // COSS 모델 ID
  public cossModlId = "";

  // COSS 차정ID
  public cartypeDtlId = "";

  // COSS 차량색상 ID
  public cossColorId = "";

  // COSS 내외장 구분
  public cossColorCls = "";

  // COSS 내외장 구분명
  public cossColorClsNm = "";

  // COSS Color명
  public cossColorNm = "";

  // 컬러 가격
  public cossColorAmt = "";
}

export default CossColor;
