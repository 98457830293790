/* eslint-disable no-console */
import { plainToClass } from "class-transformer";
import { TagoCarCatalog } from "../../models/TagoCarCatalog";
import { ListItemResponse, ListRequestParams } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import TagoCarCatalogDetail from "../../models/TagoCarCatalogDetail";

export interface GetTagoCarCatalogRequestParams extends ListRequestParams {
  // 등록일자 조회시작일자
  regStDt?: string;
  // 등록일자 조회종료일자
  regEndDt?: string;
  // COSS ID
  cossId?: string;
  // 차량번호
  carNo?: string;
  // 제조사
  brandNm?: string;
  // 모델
  repCarClassNm?: string;
  // 세부모델
  carClassNm?: string;
  // 트림
  carGradeNm?: string;
  // 연식
  yearType?: string;
  // 주행거리시작
  startLastDtc?: string;
  // 주행거리종료
  endLastDtc?: string;
  // 연료
  fuel?: string;
  // 변속
  istdTrans?: string;
  // 차량정보
  carNmInfo?: string;
  // 정비이력
  fixYn?: string;
  // niceDnr연결여부
  niceConYn?: string;
  // 상품유형
  ncarClsFlag?: string;
  // 타고바이 등록여부
  useYn?: string;
  // 타고바이 노출여부
  viewYn?: string;
}

/**
 * 중고장기 타고바이 상품 리스트 조회
 */
export const fetchTBCatalogs = async (
  params?: GetTagoCarCatalogRequestParams
): Promise<ListItemResponse<TagoCarCatalog>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/tb-catalogs`,
    params as Record<string, any>
  );

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(TagoCarCatalog, list as Array<JSON>),
    count: totalCnt,
  };
};

/**
 * 중고장기 타고바이 상품 상세 조회
 */
export const fetchTBCatalogDetail = async (
  prodId: string
): Promise<TagoCarCatalogDetail> => {
  const url = `${ROOT_URL}/admin/tb-catalogs/${prodId}`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  return plainToClass(TagoCarCatalogDetail, response);
};

/**
 * 타고바이 상품 노출상태 변경
 */
export const editTbCatalogDisplayState = async (
  prodId: string,
  carId: string,
  useYn: string,
  viewYn: string
) => {
  const url = `${ROOT_URL}/admin/tb-catalogs`;
  if(useYn === "-") {
    useYn = "Y";
    viewYn = "N";
  } else {
    useYn = "Y";
  }
  await request(HttpMethod.Post, url, undefined, {
    prodId,
    carId,
    useYn,
    viewYn,
  });
};

/**
 * 중고장기 타고바이 차량인수가 수정
 */
export const editTbAmount = async (
  prodId: string, 
  carId: string, 
  tkvAmt: string,
  avgMinTkvAmt: string,
  avgMaxTkvAmt: string,
) => {
  const url = `${ROOT_URL}/admin/tb-catalogs/${prodId}`;
  await request(HttpMethod.Post, url, undefined, {carId, tkvAmt, avgMinTkvAmt, avgMaxTkvAmt});
};


/**
 * 중고장기 타고바이 주요 옵션 수정
 */
 export const updateUcTagobuyCatalogsCarOption = async (prodId: string, carId: string, commCdList: string[]) => {
  const url = `${ROOT_URL}/admin/tb-catalogs/${prodId}/options`;
  await request(HttpMethod.Post, url, undefined, {carId, commCdList});
};

/**
 * 중고장기 타고바이(TAGOBUY) 상품 상세 - 성능진단 가져오기
 */
 export const updateUcTagobuyCatalogsPfmc = async ( prodId: string, carId: string, carNo: string, 
                                                    checkId: string, exchange: string, sheetMetal: string, 
                                                    corrosion: string, scratch: string, crack: string, dent: string,
                                                    frInPann: string, frReWhHous: string, 
                                                    fiDaFlPann: string, siQuPann: string, rePannTrFlor: string, 
                                                    roPannPaTray: string, hood: string, frFend: string, 
                                                    frDoor: string, reDoor: string, trLid: string) => {  
  const url = `${ROOT_URL}/admin/tb-catalogs/${prodId}/car-check`;
  await request(HttpMethod.Post, url, undefined, {carId, carNo, checkId, exchange, sheetMetal, 
                                                  corrosion, scratch, crack, dent,
                                                  frInPann, frReWhHous, fiDaFlPann, siQuPann, rePannTrFlor, 
                                                  roPannPaTray, hood, frFend, frDoor, reDoor, trLid});
};

/**
 * 중고장기 타고바이(TAGOBUY) 상품 상세 - 사고이력 가져오기
 */
 export const updateUcTagobuyCatalogsAccdHist = async (prodId: string, carId: string, carNo: string) => {
  const url = `${ROOT_URL}/admin/tb-catalogs/${prodId}/accd-hist`;
  await request(HttpMethod.Post, url, undefined, {carId, carNo});
};

/**
 * 중고장기 타고바이(TAGOBUY) 상품 상세 - 블록오디세이 가져오기
 */
 export const updateUcTagobuyCatalogsBlck = async (prodId: string, carId: string, carNo: string) => {
  const url = `${ROOT_URL}/admin/tb-catalogs/${prodId}/blck-odsy`;
  await request(HttpMethod.Post, url, undefined, {carId, carNo});
};

/**
 * 중고장기 타고바이(TAGOBUY) 상품 상세 - 기본렌탈료 가져오기
 */
 export const updateUcTagobuyRentAmt = async (modeProdId: string) => {
  const url = `${ROOT_URL}/admin/tb-rent-fee?modeProdId=${modeProdId}`;
  await request(HttpMethod.Put, url, undefined, undefined);
};