import moment from "moment";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

export const CONSULTING_STATE_RENDERER = "consultingStateRenderer";

export const createCols = (): Array<TableColumn> => {
  return [
    // 신청일시
    createTableCol("regDtm", "신청일시", undefined, {
      width: CW_LARGE,
      cellRenderer: dateFormatRenderer("regDtm", true),
    }),
    // 순번
    createTableCol("preRsvId", "순번", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 이름
    createTableCol("custNm", "이름", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 휴대폰 번호
    createTableCol("hpNo", "휴대폰 번호", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 거주지
    createTableCol("useAreaNm", "거주지", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 예약신청 차량
    createTableCol("prodNm", "예약신청 차량", FilterType.Text, {
      minWidth: CW_SMALL,
      flex: 1,
    }),
    // 상담 완료 여부
    createTableCol("callYn", "상담센터 전화여부", undefined, {
      width: CW_MEDIUM,
      cellRenderer: CONSULTING_STATE_RENDERER,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
