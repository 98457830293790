import React, { useCallback, useState } from "react";
import { FormikProps, FormikValues } from "formik";
import strings from "../../constants/strings";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";

import {
  createEventParam,
  createFormInfo,
  createInitialValues,
  createInitialValuesFromEvent,
  createValidationSchema,
} from "./utils/form-utils";
import {
  renderLoading,
  alertError,
  alertSuccess,
} from "../../../../../utils/render-utils";
import {
  addEvent,
  editEvent,
  fetchEventDetail,
} from "../../../../../apis/events";
import EventDetail from "../../../../../models/EventDetail";
import { getErrorMessage } from "../../../../../utils/common-utils";

interface Props {
  isOpen: boolean;
  onCancel?: () => void;
  seq?: string;
  onDataChange?: () => void;
}

const EventModal: React.FC<Props> = (props: Props) => {
  const { isOpen, onCancel, seq, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );
  const [event, setEvent] = useState<EventDetail | null>(null);
  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    if (seq) {
      // Fetch data
      setDataFetching(true);
      try {
        const event = await fetchEventDetail(seq);
        setEvent(event);
        setInitialValues(createInitialValuesFromEvent(event));
      } catch (e) {
        if (e.getMessage) {
          alertError(e.getMessage());
        } else {
          alertError(e.toString());
        }
      } finally {
        setDataFetching(false);
      }
    } else {
      setInitialValues(createInitialValues());
    }
  };

  /**
   * Private Functions
   */

  const requestAddNotice = async (values: FormikValues) => {
    setConfirmLoading(true);

    try {
      await addEvent(createEventParam(values));
      alertSuccess(strings.FEEDBACK_ADD_EVENT);
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditNotice = async (values: FormikValues) => {
    if (event) {
      setConfirmLoading(true);
      try {
        await editEvent(event.evntSeq, createEventParam(values));
        alertSuccess(strings.FEEDBACK_EDIT_EVENT);
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const getModalTitle = useCallback(() => {
    if (seq) {
      return strings.MODAL_TITLE_EVENT_DETAIL;
    }
    return strings.MODAL_TITLE_ADD_EVENT;
  }, [seq]);

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (seq) {
      await requestEditNotice(values);
    } else {
      await requestAddNotice(values);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={isOpen}
      onCancel={onCancel}
      title={getModalTitle()}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={createFormInfo()}
          validationSchema={createValidationSchema()}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

EventModal.defaultProps = {
  onCancel: () => {},
  isOpen: false,
  seq: undefined,
};
export default EventModal;
