import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import strings from "./constants/strings";
import { createCols, USAGE_STATE_SWITCH_RENDERER } from "./utils/table-utils";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import NewCarDeliveryInfoModal from "./pages/NewCarDeliveryInfoModal";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import NewCarDeliveryTermInfo from "../../../models/NewCarDeliveryTermInfo";
import {
  editDeliveryTermInfo,
  fetchDeliveryTermInfos,
} from "../../../apis/dlv-term";
import { getErrorMessage } from "../../../utils/common-utils";
import { renderSwitchCol } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../constants/strings";

const NewCarDeliveryInfoManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rows, setRows] = useState<Array<NewCarDeliveryTermInfo> | undefined>(
    undefined
  );
  const [checkedRows, setCheckedRows] = useState<Array<NewCarDeliveryTermInfo>>(
    []
  );
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<NewCarDeliveryTermInfo>();

  const cols = createCols();

  useEffect(() => {
    (async function fetchData() {
      await requestDataList();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestDataList = async () => {
    try {
      const deliveryTermInfos = await fetchDeliveryTermInfos();
      const { items, count } = deliveryTermInfos;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      if (e.status !== 404) {
        if (e.getMessage) alertError(e.getMessage());
        else alertError(e.toString());
      }
      setRows([]);
    }
  };

  const requestEditDeliveryInfoUseYn = async (
    dlvTermId: string,
    checked: boolean
  ) => {
    try {
      await editDeliveryTermInfo(dlvTermId, {
        useYn: checked ? "Y" : "N",
      });
      alertSuccess(commonStrings.FEEDBACK_USE_YN_CHANGE);
      await requestDataList();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  const handleAddAction = () => {
    setSelectedData(undefined);
    setModalVisible(true);
  };

  const handleCheckRowChanged = (rows: Array<NewCarDeliveryTermInfo>) => {
    setCheckedRows(rows);
  };

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    setSelectedData(data as NewCarDeliveryTermInfo);
    setModalVisible(true);
  };

  const handleUseYnChange = (
    checked: boolean,
    data: NewCarDeliveryTermInfo
  ) => {
    showConfirm(
      checked ? commonStrings.CONFIRM_USE_ON : commonStrings.CONFIRM_USE_OFF,
      checked
        ? commonStrings.CONFIRM_CONTENT_USE_ON
        : commonStrings.CONFIRM_CONTENT_USE_OFF,
      async () => {
        await requestEditDeliveryInfoUseYn(data.dlvTermId, checked);
      }
    );
  };

  /**
   * Render Helpers
   */

  const createActions = () => {
    return [
      {
        title: strings.TITLE_ACTION_ADD,
        onClick: handleAddAction,
      },
    ];
  };

  return (
    <>
      <ContentLayout
        filterable={false}
        headerTitle={strings.TITLE_HEADER}
        description={strings.TITLE_HEADER_DESC}
      >
        <TableLayout
          gridApi={gridApi}
          title={strings.TITLE_TABLE_HEADER}
          totalCount={totalCount}
          selectionCount={checkedRows.length}
          rightActions={createActions()}
          methodName="/admin/dlv-term"
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            onCheckRowChanged={handleCheckRowChanged}
            cols={cols}
            frameworkComponents={{
              [USAGE_STATE_SWITCH_RENDERER]: renderSwitchCol(
                "useYn",
                handleUseYnChange
              ),
              ...createActionCellRenderers(undefined, handleDetailClick, {
                hideDeleteButton: true,
              }),
            }}
          />
        </TableLayout>
      </ContentLayout>
      <NewCarDeliveryInfoModal
        deliveryTermInfo={selectedData}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onDataChange={async () => {
          gridApi?.showLoadingOverlay();
          await requestDataList();
          gridApi?.hideOverlay();
        }}
      />
    </>
  );
};

NewCarDeliveryInfoManager.defaultProps = {};
export default NewCarDeliveryInfoManager;
