import strings from "../constants/strings";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LONG_TEXT,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";
import commonStrings from "../../../../constants/strings";

export const USAGE_STATE_SWITCH_RENDERER = "usageStateSwitchRenderer";

export const createNoticeCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", strings.COL_REGISTERED_AT, FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // 공지구분
    createTableCol(
      "noticeClfNm",
      strings.COL_NOTICE_CATEGORY,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    // 최상단 고정
    createTableCol("noticeFixed", "최상단 고정", undefined, {
      width: CW_SMALL,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "고정" : "미고정",
          },
        };
      },
    }),
    // 메인 노출 여부
    createTableCol("mainYn", commonStrings.COL_MAIN_DISPLAY, undefined, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "노출" : "미노출",
          },
        };
      },
    }),
    // 공지제목
    createTableCol("titl", strings.COL_TITLE, FilterType.Text, {
      flex: 1,
      minWidth: CW_LONG_TEXT,
    }),
    // 시작일시
    createTableCol("noticeStDtm", strings.COL_NOTICE_START_DATE, undefined, {
      width: CW_SMALL_M,
      cellRenderer: dateFormatRenderer("noticeStDtm"),
    }),
    // 종료일시
    createTableCol("noticeEndDtm", strings.COL_NOTICE_END_DATE, undefined, {
      width: CW_SMALL_M,
      cellRenderer: dateFormatRenderer("noticeEndDtm"),
    }),
    createTableCol("useYn", strings.COL_NOTICE_USE_YN, undefined, {
      width: CW_SMALL,
      cellRenderer: USAGE_STATE_SWITCH_RENDERER,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
