import React, { useEffect } from "react";
import { Tag } from "antd";
import { Switch, useLocation } from "react-router";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "../../constants/route-infos";
import PublicRoute from "../PublicRoute";
import PrivateRoute from "../PrivateRoute";
import Base from "../../../components/shared/layout/Base";
import {
  CUSTOMER_MANAGER,
  LOGIN,
  RENT_CONTRACT_MANAGER,
} from "../../constants/urls";
import packageJson from "../../../../package.json";
import { ENUM_DTY_ID_BIZ } from "../../../constants/enums";
import useAuth from "../../../hooks/useAuth";
import { setConnectLogs } from "../../../apis/logs";
import ErrorRoute from "../ErrorRoute";

interface Props {}

const logExcludePath = [LOGIN];

const AppRouter: React.FC<Props> = (props: Props) => {
  const { user } = useAuth();

  const { pathname } = useLocation();

  const getRedirectPath = () => {
    if (user && user.dtyId === ENUM_DTY_ID_BIZ) {
      return RENT_CONTRACT_MANAGER;
    }
    return CUSTOMER_MANAGER;
  };

  useEffect(() => {
    if (!logExcludePath.includes(pathname) && user) {
      setConnectLogs({
        userId: user.userId,
        userIp: user.clientIp,
        methodNm: pathname,
        menuName: "페이지 이동",
        actionFlag: "SEARCH",
      });
    }
  }, [pathname]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Switch>
        {PUBLIC_ROUTES.map(({ path, exact, component }) => {
          return (
            <PublicRoute
              key={path}
              path={path}
              component={component}
              exact={exact}
            />
          );
        })}

        {PRIVATE_ROUTES.map(({ path, exact, component }) => {
          return (
            <PrivateRoute
              key={path}
              path={path}
              component={component}
              layout={Base}
              exact={exact}
            />
          );
        })}

        <ErrorRoute path="*" />
      </Switch>
      <Tag
        color="#626DFF"
        style={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 100,
        }}
      >
        {packageJson.version}
      </Tag>
    </div>
  );
};

AppRouter.defaultProps = {};
export default AppRouter;
