import React, { useState } from "react";
import { ModalProps } from "../../../../types";
import ConsultingMaster from "../../../../../models/ConsultingData/ConsultingMaster";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { fetchConsultingDataDetail } from "../../../../../apis/intlcnsl";
import ConsultingDetail from "../../../../../models/ConsultingData/ConsultingDetail";
import {
  INT_CONSULTING_CATEGORY_OPTION,
  INT_CONSULTING_CATEGORY_TRIM,
  INT_RENT_OPT_COND1_CD_CORP,
  INT_RENT_OPT_COND1_CD_PERSONAL,
  INT_RENT_OPT_COND1_CD_PRIVATE_BIZ,
  INT_TRIM_FUEL_DIESEL,
  INT_TRIM_FUEL_ELEC,
  INT_TRIM_FUEL_GASOLINE,
  INT_TRIM_FUEL_LPG,
} from "../../../../../constants/enums";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../../../components/shared/layout/TableLayout";
import { createCols } from "./utils/table-utils";
import ConsultingDataEditModal from "./pages/ConsultingDataEditModal";

interface Props extends ModalProps {
  consultingMaster?: ConsultingMaster;
}

const ConsultingDataDetailModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, consultingMaster, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<ConsultingDetail[]>(
    []
  );
  const [editModalVisible, setEditModalVisible] = useState(false);

  // 휘발유 트림
  const [gasolineTrimDetails, setGasolineTrimDetails] = useState<
    ConsultingDetail[]
  >([]);

  // 디젤 트림
  const [dieselTrimDetails, setDieselTrimDetails] = useState<
    ConsultingDetail[]
  >([]);

  // LPG
  const [lpgTrimDetails, setLpgTrimDetails] = useState<ConsultingDetail[]>([]);

  // 전기 트림
  const [elecTrimDetails, setElecTrimDetails] = useState<ConsultingDetail[]>(
    []
  );

  // 개인
  const [personalRentOptionDetails, setPersonalRentOptionDetails] = useState<
    ConsultingDetail[]
  >([]);

  // 옵션
  const [carOptionDetails, setCarOptionDetails] = useState<ConsultingDetail[]>(
    []
  );

  // 개인사업자
  const [
    privateBizRentOptionDetails,
    setPrivateBizRentOptionDetails,
  ] = useState<ConsultingDetail[]>([]);

  // 법인
  const [corpRentOptionDetails, setCorpRentOptionDetails] = useState<
    ConsultingDetail[]
  >([]);

  const onModalOpen = async () => {
    setDataFetching(true);
    if (consultingMaster) {
      const { cnslCls, cartypeNm } = consultingMaster;
      await requestFetchConsultingDataDetails(cnslCls, cartypeNm);
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */

  const filterConsultingDataDetails = (details: ConsultingDetail[]) => {
    const filterData = (targetKey: string, clsCd: string) => {
      return details
        .filter((data) => {
          // @ts-ignore
          return data[targetKey] === clsCd;
        })
        .sort((a, b) => {
          if (a.mngrRnk < b.mngrRnk) return -1;
          return 0;
        });
    };

    if (consultingMaster) {
      const { cnslCls: masterCls } = consultingMaster;

      // 트림
      if (masterCls === INT_CONSULTING_CATEGORY_TRIM) {
        const gasoline = filterData("cond2", INT_TRIM_FUEL_GASOLINE);
        setGasolineTrimDetails(gasoline);

        const diesel = filterData("cond2", INT_TRIM_FUEL_DIESEL);
        setDieselTrimDetails(diesel);

        const lpg = filterData("cond2", INT_TRIM_FUEL_LPG);
        setLpgTrimDetails(lpg);

        const elec = filterData("cond2", INT_TRIM_FUEL_ELEC);
        setElecTrimDetails(elec);
      }
      // 옵션
      else if (masterCls === INT_CONSULTING_CATEGORY_OPTION) {
        setCarOptionDetails(details);
      }
      // 렌트 옵션
      else {
        const personal = filterData("cond1", INT_RENT_OPT_COND1_CD_PERSONAL);
        setPersonalRentOptionDetails(personal);

        const privateBiz = filterData(
          "cond1",
          INT_RENT_OPT_COND1_CD_PRIVATE_BIZ
        );
        setPrivateBizRentOptionDetails(privateBiz);

        const corp = filterData("cond1", INT_RENT_OPT_COND1_CD_CORP);
        setCorpRentOptionDetails(corp);
      }
    }
  };

  const requestFetchConsultingDataDetails = async (
    cnslCls: string,
    cartypeNm: string
  ) => {
    try {
      const details = await fetchConsultingDataDetail(cnslCls, cartypeNm);
      filterConsultingDataDetails(details);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  const renderTable = (
    tableTitle: string,
    rows: Array<any>,
    onEditClick: () => void
  ) => {
    return (
      <TableLayout
        hideFooter
        hideExcelAction
        hideFilterAction
        title={tableTitle}
        totalCount={0}
        rightActions={[
          {
            title: "정보변경",
            onClick: onEditClick,
          },
        ]}
        methodName="/admin/custcnte/intlcnsl"
      >
        <ClientRowTable
          floatingFilter={false}
          cols={createCols()}
          rowData={rows}
          height={176}
        />
      </TableLayout>
    );
  };

  const renderDetails = () => {
    if (consultingMaster) {
      const defaultTableTitle = `${consultingMaster?.cartypeNm} - ${consultingMaster?.cnslClsNm} 순위`;
      const { cnslCls: masterCls } = consultingMaster;
      // 트림
      if (masterCls === INT_CONSULTING_CATEGORY_TRIM) {
        return (
          <div>
            {/* 휘발유 */}
            {gasolineTrimDetails.length > 0 &&
              renderTable(
                `${consultingMaster?.cartypeNm}(가솔린) - ${consultingMaster?.cnslClsNm} 순위`,
                gasolineTrimDetails,
                () => {
                  setSelectedDetails(gasolineTrimDetails);
                  setEditModalVisible(true);
                }
              )}
            {/* 경유 */}
            {dieselTrimDetails.length > 0 &&
              renderTable(
                `${consultingMaster?.cartypeNm}(디젤) - ${consultingMaster?.cnslClsNm} 순위`,
                dieselTrimDetails,
                () => {
                  setSelectedDetails(dieselTrimDetails);
                  setEditModalVisible(true);
                }
              )}
            {/* LPG */}
            {lpgTrimDetails.length > 0 &&
              renderTable(
                `${consultingMaster?.cartypeNm}(LPG) - ${consultingMaster?.cnslClsNm} 순위`,
                lpgTrimDetails,
                () => {
                  setSelectedDetails(lpgTrimDetails);
                  setEditModalVisible(true);
                }
              )}
            {/* 전기 */}
            {elecTrimDetails.length > 0 &&
              renderTable(
                `${consultingMaster?.cartypeNm}(전기) - ${consultingMaster?.cnslClsNm} 순위`,
                elecTrimDetails,
                () => {
                  setSelectedDetails(elecTrimDetails);
                  setEditModalVisible(true);
                }
              )}
          </div>
        );
      }
      // 옵션
      if (masterCls === INT_CONSULTING_CATEGORY_OPTION) {
        return renderTable(defaultTableTitle, carOptionDetails, () => {
          setSelectedDetails(carOptionDetails);
          setEditModalVisible(true);
        });
      }
      // 기타 (렌트 옵션)

      return (
        <div>
          {/* 개인 */}
          {personalRentOptionDetails.length > 0 &&
            renderTable(
              `${consultingMaster?.cnslClsNm}(개인) 순위`,
              personalRentOptionDetails,
              () => {
                setSelectedDetails(personalRentOptionDetails);
                setEditModalVisible(true);
              }
            )}
          {/* 개인사업자 */}
          {privateBizRentOptionDetails.length > 0 &&
            renderTable(
              `${consultingMaster?.cnslClsNm}(개인사업자) 순위`,
              privateBizRentOptionDetails,
              () => {
                setSelectedDetails(privateBizRentOptionDetails);
                setEditModalVisible(true);
              }
            )}
          {/* 법인 */}
          {corpRentOptionDetails.length > 0 &&
            renderTable(
              `${consultingMaster?.cnslClsNm}(법인) 순위`,
              corpRentOptionDetails,
              () => {
                setSelectedDetails(corpRentOptionDetails);
                setEditModalVisible(true);
              }
            )}
        </div>
      );
    }

    return "";
  };

  return (
    <>
      <Modal
        footer={null}
        title="지능형 상담 집계 상세"
        visible={visible}
        onCancel={onCancel}
        onOpen={onModalOpen}
      >
        {dataFetching ? renderLoading() : renderDetails()}
      </Modal>
      <ConsultingDataEditModal
        visible={editModalVisible}
        onCancel={() => {
          setEditModalVisible(false);
        }}
        details={selectedDetails}
        consultingMaster={consultingMaster}
        onDataChange={async () => {
          if (consultingMaster) {
            await requestFetchConsultingDataDetails(
              consultingMaster.cnslCls,
              consultingMaster.cartypeNm
            );
            if (onDataChange) onDataChange();
          }
        }}
      />
    </>
  );
};

ConsultingDataDetailModal.defaultProps = {};
export default ConsultingDataDetailModal;
