export const MODE_COLOR_CLS_EX = "E";
export const MODE_COLOR_CLS_I = "I";

class NiceInfoColor {
  // MODE 등급 ID
  public modeGrdId = "";

  // MODE 차량색상ID
  public modeColorId = "";

  // MODE 내외장 구분
  public modeColorCls = "";

  // MODE 내외장 구분명
  public modeColorClsNm = "";

  // MODE Color명
  public modeColorNm = "";

  // MODE Color URL
  public modeColorImgUrl = "";

  // COSS 차량색상 ID
  public cossColorId = "";

  // COSS 차량색상명
  public cossColorNm = "";

  // NICE 가격
  public modeColorPrice = "";


  // 추가 SEQ (컬러 자동 복사시)
  public modeColorAddSeq = "";

}

export default NiceInfoColor;
