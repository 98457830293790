import React, { useState } from "react";
import DetailSection, { FieldType } from "../../../../../../../components/shared/layout/DetailSection";
// defines
import { ModalProps } from "../../../../../../types";
import { OincRtSet } from "../../../../../../../models/OincRtSet";
// apis
import { fetchOincRtInfo, updateOincRtState } from "../../../../../../../apis/nc-catalogs";
// components
import ModalTableWrapper from "../../../../../../../components/shared/layout/ModalTableWrapper";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import Button from "../../../../../../../components/shared/general/antd/Button";
import Input from "../../../../../../../components/shared/data-entry/antd/Input";
// utils
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";

interface Props extends ModalProps {
  prodId?: string;
}
/**
 * 1년 렌트 경상이익율 "설정" 모달
 * 
 * @param props 
 * @returns 
 */
const OincRtSetupModal: React.FC<Props> = (props: Props) => {

  const { visible, onCancel, prodId, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [oincRtSet, setOincRtSet] = useState<OincRtSet>();
  const [oincRt, setOincRt] = useState<string>();

  const onModalOpen = async () => {
    await requestFetchOincRt();
  };

  /**
   * 모달 닫기
   */
  const closeModal = () => {
    // 초기화
    setOincRt("");
    // 모달 닫기
    if (onCancel) onCancel();
  }

  /**
   * 경상이익율 갱신
   */
  const requestUpdateOincRt = async () => {
    if (prodId) {
      try {
        if (oincRtSet && oincRt) {
          await updateOincRtState({ ...oincRtSet, prodAdjVal: oincRt });
          alertSuccess("경상이익율이 변경되었습니다.");
        }
        if (onDataChange) onDataChange(); // 부모 데이터 갱신
        if (closeModal) closeModal();     // 모달 닫기
      } catch (e) {
        alertError(getErrorMessage(e));
      }
    }
  };

  /**
   * 경상이익율 가져오기
   * 
   * @returns 
   */
  const requestFetchOincRt = async () => {
    setDataFetching(true);
    try {
      if (prodId) {
        const oincRtRes = await fetchOincRtInfo(prodId);
        setOincRtSet(oincRtRes ? oincRtRes : {...new OincRtSet, prodId});
      }
    } catch (e) {
      alertError(getErrorMessage(e));
      return undefined;
    } finally {
      setDataFetching(false);
    }
  };

  /**
   * Render Helpers
   */
  const renderDetail = () => {
    if (oincRtSet) {

      const oincRtSection = [
        {
          label: "경상이익율",
          span: 24,
          type: FieldType.Text,
          content: oincRtSet.prodAdjVal,
        },
        {
          label: "적용할 경상이익율",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <Input
                value={oincRt}
                style={{
                  width: 90,
                  borderLeft: 0,
                  borderRight: 0,
                  borderRadius: 0,
                  backgroundColor: "white",
                }}
                onChange={(e) => {
                  // setOincRtSet({ ...oincRtSet, prodAdjVal: e.target.value });
                  setOincRt(e.target.value);
                }}
              />
            );
          },
        },
      ];

      return (
        <ModalTableWrapper
          // headerTitle="경상이익율"
          style={{
            position: "relative",
          }}
        >
          {/** <div
            style={{
              position: "absolute",
              top: 0,
              left: 180,
            }}
          >
            <Button onClick={requestFetchOincRt}>
              가져오기
            </Button>
          </div> */}
          <DetailSection
            label="경상이익율"
            fieldInfos={oincRtSection}
            hideDivider
          />
        </ModalTableWrapper>
      );
    }

    return "";
  };

  return (
    <>
      <Modal
        size="small"
        title="1년 렌트 경상이익율 설정"
        visible={visible}
        onCancel={closeModal}
        onOpen={onModalOpen}
        footer={[
          <Button key="cancel" type="default" onClick={closeModal}>
            취소
          </Button>,
          <Button key="submit" type="primary" onClick={requestUpdateOincRt}>
            확인
          </Button>,
        ]}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
    </>
  );
};

OincRtSetupModal.defaultProps = {
  prodId: undefined,
};

export default OincRtSetupModal;