import React from "react";
import moment from "moment";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { ENUM_EVENT_CD_SAMPLE } from "../../../constants/enums";
import { FormElementType } from "../../../../../../components/shared/data-entry/FormBuilder/types";
import strings from "../../../constants/strings";

import FormItem from "../../../../../../components/shared/data-entry/antd/FormItem";
import HtmlEditor from "../../../../../../components/shared/input/HtmlEditor";
import EventDetail from "../../../../../../models/EventDetail";
import { UPLOAD_CODES } from "../../../../../../constants/enums";
import { getParamFromDateRange } from "../../../../../../utils/form-utils";
import dompurify from "dompurify";

export const createInitialValues = () => {
  return {
    topFixedYn: false,
    ENUM_EVENT_CD_SAMPLE,
    // 제목
    titl: "",
    // 내용
    contents: "",
    // 공지 기간
    eventTerm: null,
    // 이벤트 응모내역 파기일
    winDtm: null,
    // 썸네일 이미지
    images: [],
    useYn: false,
  };
};

export const createInitialValuesFromEvent = (event: EventDetail) => {
  const {
    titl,
    topFixedYn,
    evntStDt,
    evntEndDt,
    imgPath,
    useYn,
    winDtm,
  } = event;

  let initialEventTerm = null;
  if (evntStDt && evntEndDt) {
    initialEventTerm = [moment(evntStDt), moment(evntEndDt)];
  }

  let initialWinDate = null;
  if (winDtm) {
    initialWinDate = moment(winDtm);
  }

  return {
    topFixedYn: topFixedYn === "Y",
    // 제목
    titl: titl || "",
    // 내용
    contents: dompurify.sanitize(event.htmlContent),
    // 공지 기간
    eventTerm: initialEventTerm,
    images: [
      {
        imageUrl: imgPath,
      },
    ],
    useYn: useYn === "Y",
    winDtm: initialWinDate,
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    titl: Yup.string().required(strings.HINT_EVENT_TITLE),
    contents: Yup.string().required(strings.HINT_EVENT_CONTENT),
  });
};

export const createEventParam = (values: FormikValues) => {
  const {
    titl,
    contents,
    topFixedYn,
    eventTerm,
    images,
    useYn,
    winDtm,
  } = values;

  let evntTermParam = "N";
  let evntStDt;
  let evntEndDt;
  if (eventTerm && eventTerm.length > 0) {
    const { startDateParam, endDateParam } = getParamFromDateRange(eventTerm);
    evntStDt = startDateParam;
    evntEndDt = endDateParam;
    evntTermParam = "Y";
  }

  let thumbParam = "";
  if (images && images.length > 0) {
    thumbParam = images[0].imageUrl;
  }

  let winDtmParam = null;
  if (winDtm) {
    winDtmParam = moment(winDtm).format("YYYYMMDD");
  }

  return {
    titl,
    contents,
    topFixedYn: topFixedYn ? "Y" : "N",
    evntTermYn: evntTermParam,
    evntStDt,
    evntEndDt,
    imgPath: thumbParam,
    useYn: useYn ? "Y" : "N",
    winDtm: winDtmParam,
  };
};

export const createFormInfo = () => {
  return [
    // 최상단 고정여부
    {
      key: "topFixedYn",
      type: FormElementType.Checkbox,
      label: strings.LABEL_IS_FIXED,
    },
    // 노출 여부
    {
      key: "useYn",
      type: FormElementType.Checkbox,
      label: "노출 여부",
    },
    // 이벤트 제목
    {
      key: "titl",
      type: FormElementType.Input,
      label: strings.LABEL_EVENT_TITLE,
      placeholder: strings.HINT_EVENT_TITLE,
      required: true,
    },

    // 게시일
    {
      key: "eventTerm",
      type: FormElementType.DateRangePicker,
      label: strings.LABEL_EVENT_TERM,
    },
    // 이벤트 파기일
    {
      key: "winDtm",
      type: FormElementType.DatePicker,
      label: strings.LABEL_EVENT_DELETE_DATE,
    },

    // 이미지 첨부
    {
      key: "images",
      type: FormElementType.ImageUpload,
      label: "썸네일 이미지",
      uploadCodeInfo: UPLOAD_CODES.EVENT,
    },
    // 내용
    {
      key: "contents",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue, touched, errors } = renderProps;
        const value = values.contents || "";

        let errorMessage = "";
        if (touched.contents && errors.contents) {
          errorMessage = errors.contents as string;
        }

        return (
          <FormItem
            help={errorMessage}
            validateStatus={errorMessage && "error"}
            key="contents"
            label={strings.LABEL_EVENT_CONTENT}
            required
            style={{
              marginBottom: "15px",
            }}
          >
            <HtmlEditor
              uploadInfo={UPLOAD_CODES.EVENT}
              data={value}
              onChange={(data) => {
                setFieldValue("contents", data);
              }}
            />
          </FormItem>
        );
      },
    },
  ];
};
