import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import { ReplyInfo } from "../../../../../../../models/Survey/SurveyMasterDetail";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {
  createFormInfo,
  createInitialValues,
  createInitialValuesFromReply,
  createValidationSchema,
} from "./utils/form-utils";
import {
  alertError,
  alertSuccess,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import {
  addSurveyReply,
  editSurveyReply,
} from "../../../../../../../apis/survey";

interface Props extends ModalProps {
  // 답변 템플릿 수정시
  reply?: ReplyInfo;

  // 답변 템플릿 등록시
  srveQstnId?: string;
}

const ReplyFormModal: React.FC<Props> = (props: Props) => {
  const { visible, onDataChange, onCancel, reply, srveQstnId } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  let formik: FormikProps<FormikValues>;

  const onModalOpen = () => {
    let initialValues = createInitialValues();
    if (reply) {
      initialValues = createInitialValuesFromReply(reply) as any;
    }
    setInitialValues(initialValues);
  };

  /**
   * Private Functions
   */
  const getModalTitle = () => {
    if (reply) return "설문 답변 템플릿 수정";

    return "설문 답변 템플릿 등록";
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    setConfirmLoading(true);
    const { txtInsYn, srveReplyItemNm, replyClsCd } = values;
    try {
      // 답변 템플릿 수정
      if (reply) {
        await editSurveyReply(
          reply.srveQstnId,
          reply.srveReplyItemCd,
          srveReplyItemNm,
          txtInsYn ? "Y" : "N",
          replyClsCd
        );
        alertSuccess("답변 템플릿 정보가 변경되었습니다.");
      }
      // 답변 템플릿 등록
      else if (srveQstnId) {
        await addSurveyReply(
          srveQstnId,
          srveReplyItemNm,
          txtInsYn ? "Y" : "N",
          replyClsCd
        );
        alertSuccess("답변 템플릿이 등록되었습니다.");
      }

      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      title={getModalTitle()}
      size="small"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
        forms={createFormInfo()}
        validationSchema={createValidationSchema()}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

ReplyFormModal.defaultProps = {};
export default ReplyFormModal;
