export default class TagMaster {
  /** 태그ID */
  public tagId = "";

  /** PC 태그위치 */
  public pcTagPos = "";

  /** PC태그 스타일 */
  public pcTagStyl = "";

  /** PC태그 텍스트 */
  public pcTagTxt = "";

  /** 모바일태그 위치 */
  public mobTagPos = "";

  /** 모바일태그 스타일 */
  public mobTagStyl = "";

  /** 모바일태그 텍스트 */
  public mobTagTxt = "";

  /** 사용여부 */
  public useYn = "";

  /** 등록자ID; */
  public regUser = "";

  /** 등록일시 */
  public regDtm = "";

  /** 변경자ID; */
  public updUser = "";

  /** 변경일시 */
  public updDtm = "";

  /** 태그 순번 */
  public tagSeq = "";

  /** 태그 레벨 코드 */
  public tagLvlCd = "";
}
