import styled from "styled-components";
import descriptionArrow from "../../../../assets/svgs/description-arrow.svg";

export const SubHeaderWrapper = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
`;

export const SubHeaderTitle = styled.h1`
  font-size: 24px;
  color${(props) => props.theme.common.colors.subHeaderTitle};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-right: 20px;
  line-height: 32px;
`;

export const SubHeaderDescriptionWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  background-color: ${(props) => props.theme.common.colors.point};
  width: 14px;
  height: 14px;
  line-height: 8px;
  vertical-align: middle;
  text-align: center;
  margin-right: 8px;
  border-radius: 7px;
`;

export const DescriptionArrow = styled.img.attrs({
  src: descriptionArrow,
})``;

export const SubHeaderDescription = styled.h3`
  font-size: 12px;
  color${(props) => props.theme.common.colors.subHeaderTitle};
  display: block;
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

export const SubHeaderTitleWrapper = styled.div`
  padding: 20px 0;
  flex: 1;
`;

export const SubHeaderActionWrapper = styled.div`
  flex-grow: 1;
  text-align: right;
`;

export const DescriptionWrapper = styled.div`
  background: white;
  padding: 12px 12px;
  border-top: 1px solid ${(props) => props.theme.common.colors.border};
`;

export const UserNameTitle = styled.span`
  color: ${(props) => props.theme.common.colors.point};
`;
