import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../types";
import PromotionReward from "../../../../../models/PromotionReward";
import {
  bulkEditRewardState,
  editRewardState,
  RewardStateParam,
} from "../../../../../apis/special-amt";
import { alertError, alertSuccess } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";
import { FormElementType } from "../../../../../components/shared/data-entry/FormBuilder/types";

import errorMessages from "../../../../../constants/errors";

interface Props extends ModalProps {
  promotionReward?: PromotionReward;
  bulkMode?: boolean;
  selectedRewards?: PromotionReward[];
}

const PaymentCompleteModal: React.FC<Props> = (props: Props) => {
  const {
    onCancel,
    visible,
    onDataChange,
    promotionReward,
    bulkMode,
    selectedRewards,
  } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const formInitialValues = {
    rewardAmt: "",
  };
  const [initialValues, setInitialValues] = useState(formInitialValues);

  const validationSchema = Yup.object().shape({
    rewardAmt: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });

  const forms = [
    {
      // 리워드 금액
      key: "rewardAmt",
      type: FormElementType.InputNumber,
      label: "리워드 금액",
      placeholder: "리워드 금액을 입력해주세요",
      required: true,
    },
  ];

  let formik: FormikProps<FormikValues>;

  const onModalOpen = () => {
    setInitialValues(formInitialValues);
  };

  /**
   * Private Functions
   */

  const requestEditRewardPaymentState = async (values: FormikValues) => {
    const { rewardAmt } = values;
    if (promotionReward) {
      const { spclAmtInfoId, cntrId } = promotionReward;
      setConfirmLoading(true);
      const param = {
        spclAmtInfoId,
        cntrId,
        rewardPayYn: "Y",
        rewardAmt,
        recRewardAmt: rewardAmt,
      };
      try {
        await editRewardState(param);
        alertSuccess("리워드 지급상태가 변경되었습니다.");
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const requestBulkEditRewardPaymentState = async (values: FormikValues) => {
    const { rewardAmt } = values;
    if (selectedRewards && selectedRewards.length > 0) {
      setConfirmLoading(true);
      const params: RewardStateParam[] = selectedRewards.map(
        ({ spclAmtInfoId, cntrId }) => ({
          spclAmtInfoId,
          cntrId,
          rewardPayYn: "Y",
          rewardAmt,
          recRewardAmt: rewardAmt,
        })
      );
      try {
        await bulkEditRewardState(params);
        alertSuccess("리워드 지급상태가 변경되었습니다.");
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    if (bulkMode) {
      await requestBulkEditRewardPaymentState(values);
    } else {
      await requestEditRewardPaymentState(values);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      title={bulkMode ? "리워드 일괄 지급 처리" : "리워드 지급 처리"}
      visible={visible}
      size="small"
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
      onOpen={onModalOpen}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
        forms={forms}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

PaymentCompleteModal.defaultProps = {};
export default PaymentCompleteModal;
