import strings from "../constants/strings";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { formatDateString } from "../../../../utils/date-utils";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";
import commonStrings from "../../../../constants/strings";

export const USAGE_STATE_SWITCH_RENDERER = "usageStateSwitchRenderer";

export const createCols = (): Array<TableColumn> => {
  return [
    // 등록일
    createTableCol("regDtm", strings.COL_REGISTERED_AT, undefined, {
      width: CW_SMALL,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // 제조사
    createTableCol("brandNm", "제조사", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 상품명
    createTableCol("prodNm", "상품명", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 세부모델
    createTableCol("carClassNm", strings.COL_DETAIL_MODEL, FilterType.Text, {
      width: CW_LARGE,
    }),
    // 연료
    createTableCol("cossFuelNm", "연료", FilterType.Text, {
      width: CW_SMALL_M,
    }),
    // 트림
    createTableCol("carGradeNm", strings.COL_TRIM, FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 납기일
    createTableCol(
      "dlvTerm",
      strings.COL_ORDER_DELIVERY_DATE,
      FilterType.Text,
      {
        width: CW_SMALL,
        valueGetter: (params) => {
          const { data } = params;
          const { dlvTerm } = data;
          return `${dlvTerm}주`;
        },
      }
    ),
    // 유효기간
    createTableCol(
      "effectiveDateRange",
      strings.COL_EFFECTIVE_DATE,
      undefined,
      {
        width: CW_DATE_RANGE,
        valueGetter: (params) => {
          const { data } = params;
          const { apyStDt, apyEndDt } = data;
          return `${formatDateString(apyStDt)} ~ ${formatDateString(apyEndDt)}`;
        },
      }
    ),
    // 만료 여부
    createTableCol("expiredYn", strings.COL_IS_EXPIRED, FilterType.Text, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "유효" : "만료",
          },
        };
      },
    }),
    // 사용유무
    createTableCol("useYn", commonStrings.USE_YN, undefined, {
      width: CW_SMALL,
      cellRenderer: USAGE_STATE_SWITCH_RENDERER,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
