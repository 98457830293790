import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Table } from "antd";
import { ModalProps } from "../../../../../../types";
import RentContractDetail from "../../../../../../../models/RentContractDetail";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";

import { FormElementType } from "../../../../../../../components/shared/data-entry/FormBuilder/types";

import { alertError } from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import Switch from "../../../../../../../components/shared/data-entry/antd/Switch";
import FormItem from "../../../../../../../components/shared/data-entry/antd/FormItem";
import { showConfirm } from "../../../../../../../components/shared/feedback/Confirm";
import { editDirectPick } from "../../../../../../../apis/direct-pick";

interface Props extends ModalProps {
  rentContractDetail?: RentContractDetail;
}

const DirectPickupModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, rentContractDetail } = props;
  let formik: FormikProps<FormikValues>;
  const formInitialValues = {
    dpStsCdNm: rentContractDetail?.dpStsCdNm,
    arvReqDtm: rentContractDetail?.arvReqDtm
      ? moment(rentContractDetail?.arvReqDtm, "YYYYMMDDHHmmss")
      : null,
  };
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const createForms = () => {
    return [
      {
        key: "dpStsCdNm",
        type: FormElementType.Custom,
        render: ({ values, setFieldValue }: any) => {
          return (
            <FormItem
              label="직접인수 선택"
              style={{ marginBottom: "16px" }}
              labelCol={{ span: 5 }}
            >
              <Switch
                defaultChecked={values.dpStsCdNm === "Y"}
                onChange={(checked) => {
                  setFieldValue("dpStsCdNm", checked ? "Y" : "N");
                }}
              />
            </FormItem>
          );
        },
      },
      {
        key: "arvReqDtm",
        label: "직접인수 일시",
        type: FormElementType.DatePicker,
        config: {
          showTime: true,
        },
      },
      {
        label: "직접인수 최종 변경 정보",
        type: FormElementType.Custom,
      },
    ];
  };
  const [forms] = useState<any>(createForms());
  const validationSchema = Yup.object().shape({});

  const onModalOpen = async () => {
    setInitialValues(formInitialValues);
  };

  const requestDirectPickupStatus = async (values: FormikValues) => {
    setConfirmLoading(true);
    try {
      await editDirectPick(
        rentContractDetail?.cntrId,
        rentContractDetail?.cntrSeq,
        {
          arvReqDtm: moment(values.arvReqDtm).format("YYYYMMDD"),
          arvReqDtmHhmm: moment(values.arvReqDtm).format("HHmm"),
          dpStsCd: values.dpStsCdNm === "Y" ? "A31001" : "A31004",
        }
      );
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    showConfirm(
      "직접인수 선택 변경",
      values.dpStsCdNm === "Y"
        ? "직접인수로 설정됩니다. 변경하시겠습니까?"
        : "직접인수 설정이 해제됩니다. 변경하시겠습니까?",
      async () => {
        await requestDirectPickupStatus(values);
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
      }
    );
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      title="직접인수 선택 설정"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      <FormBuilder
        validationSchema={validationSchema}
        initialValues={initialValues}
        forms={forms}
        formRef={(ref) => {
          formik = ref;
        }}
        onSubmit={handleSubmit}
      />
      <Table
        pagination={false}
        style={{
          margin: "10px 0 80px",
        }}
        dataSource={[
          {
            dpRequestUser: rentContractDetail?.dpRequestUser,
            dpStsCd: rentContractDetail?.dpStsCdNm,
            arvReqDtm: rentContractDetail?.arvReqDtm,
            reqUserNm: rentContractDetail?.reqUserNm,
            reqDtm: rentContractDetail?.reqDtm,
          },
        ]}
        columns={[
          {
            key: "dpRequestUser",
            dataIndex: "dpRequestUser",
            title: "직접인수 선택",
          },
          {
            key: "arvReqDtm",
            dataIndex: "arvReqDtm",
            title: "직접인수 일시",
            render: (value) => {
              return value
                ? moment(value, "YYYYMMDDHHmmss").format("YYYY/MM/DD HH:mm")
                : null;
            },
          },
          {
            key: "reqUserNm",
            dataIndex: "reqUserNm",
            title: "설정인",
          },
          {
            key: "reqDtm",
            dataIndex: "reqDtm",
            title: "설정일시",
            render: (value) =>
              value ? moment(value).format("YYYY/MM/DD HH:mm") : null,
          },
        ]}
      />
    </Modal>
  );
};

DirectPickupModal.defaultProps = {};
export default DirectPickupModal;
