import {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_LONG_TEXT,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../../utils/table-utils";
import strings from "../../../../../car-sales/NewCarCatalogManager/constants/strings";
import commonStrings from "../../../../../../constants/strings";

export const createExhibitionCatalogCols = () => {
  return [
    // 노출순번
    createTableCol("exhViewSeq", "게시순번", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 상품명
    createTableCol("prodNm", strings.COL_CATALOG_NAME, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 판매유형
    createTableCol(
      "ncarClsFlagNm",
      commonStrings.COL_SELLING_TYPE,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    // 제조사
    createTableCol("repMakerNm", strings.COL_BRAND, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 모델
    createTableCol("repCarTypeNm", strings.COL_MODEL, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 세부 모델
    createTableCol("carGradeNm", strings.COL_DETAIL_MODEL, FilterType.Text, {
      minWidth: CW_LONG_TEXT,
      flex: 1,
    }),
  ];
};
