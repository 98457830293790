import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import SurveyMaster from "../../models/Survey/SurveyMaster";
import SurveyMasterDetail from "../../models/Survey/SurveyMasterDetail";
import CarManagerSurveyReport from "../../models/CarManagerSurveyReport";
import CarManagerSurveyReportDetail from "../../models/CarManagerSurveyReportDetail";
import ServiceSurveyReportDetail from "../../models/ServiceSurveyReportDetail";
import DirectSurveyResult from "../../models/DirectSurvey/DirectSurveyResult";
import DirectSurveyResultDetail from "../../models/DirectSurvey/DirectSurveyResultDetail";
import CarManagerSurveyReportDetailForExcel from "../../models/CarManagerSurveyReportDetailForExcel";
import { SurveyEditParams } from "../../pages/service-management/SurveyReportManager/pages/SurveyReportResultDetailModal/ChartDetailModal/pages/DirectSurveyInfoEditModal/types";

const apiRootUrl = `${ROOT_URL}/admin/survey`;

/**
 * 만족도 조사 템플릿 조회
 */
export const fetchSurveyMasterTemplates = async (): Promise<
  ListItemResponse<SurveyMaster>
> => {
  const url = `${ROOT_URL}/admin/survey-management`;
  const res = await request(HttpMethod.Get, url, {
    srveGrpCd: "A26001",
  });

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(SurveyMaster, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 만족도 조사 템플릿 상세 조회
 */
export const fetchSurveyMasterTemplateDetail = async (
  srveQstnId: string,
  srveClfCd: string
): Promise<ListItemResponse<SurveyMasterDetail>> => {
  const res = await request(HttpMethod.Get, apiRootUrl, {
    srveGrpCd: "A26001",
    srveQstnId,
    srveClfCd,
  });

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(SurveyMasterDetail, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 설문 회차 수정
 */
export const editSurvey = async (qstnOrdId: string, data: SurveyEditParams) => {
  const url = `${ROOT_URL}/admin/qstn-ord/${qstnOrdId}`;
  await request(HttpMethod.Put, url, undefined, data);
};

/**
 * 만족도 조사 설문 항목 수정
 */
export const editSurveyQuestion = async (
  srveQstnId: string,
  point: string,
  srveQitmCont: string,
  headClsCd: string,
  replyAvailCnt: number
) => {
  const url = `${ROOT_URL}/admin/survey-management/${srveQstnId}/${point}`;
  await request(HttpMethod.Put, url, undefined, {
    srveQitmCont,
    headClsCd,
    replyAvailCnt,
  });
};

/**
 * 만족도 조사 설문 응답 항목 수정
 */
export const editSurveyReply = async (
  srveQstnId: string,
  srveReplyItemCd: string,
  srveReplyItemNm: string,
  txtInsYn: string,
  replyClsCd: string
) => {
  const url = `${ROOT_URL}/admin/survey-management-reply/${srveQstnId}/${srveReplyItemCd}`;
  await request(HttpMethod.Put, url, undefined, {
    srveReplyItemNm,
    txtInsYn,
    replyClsCd,
  });
};

/**
 * 만족도 조사 설문 응답 항목 등록
 */
export const addSurveyReply = async (
  srveQstnId: string,
  srveReplyItemNm: string,
  txtInsYn: string,
  replyClsCd: string
) => {
  const url = `${ROOT_URL}/admin/survey-management-reply/${srveQstnId}`;
  await request(HttpMethod.Post, url, undefined, [
    {
      srveReplyItemNm,
      txtInsYn,
      replyClsCd,
    },
  ]);
};

/**
 * 카매니저 평가 리포트
 */
export const fetchCarManagerSurveyReport = async (
  stDt?: string,
  endDt?: string,
  srveGrpCd?: string
): Promise<ListItemResponse<CarManagerSurveyReport>> => {
  const url = `${ROOT_URL}/admin/surv-aggr-carman`;
  const res = await request(HttpMethod.Get, url, {
    stDt,
    endDt,
    srveGrpCd: srveGrpCd || "A26001",
  });
  const { data } = res;
  const { response } = data;
  const { listCnt, list, cntrCnt, replyCnt } = response;
  return {
    items: plainToClass(CarManagerSurveyReport, list as Array<JSON>),
    count: Number(listCnt),
    extraData: {
      cntrCnt,
      replyCnt,
    },
  };
};

/**
 * 카매니저 평가 리포트
 */
export const fetchCarManagerSurveyReportDetailList = async (
  carmanId?: string,
  stDt?: string,
  endDt?: string,
  srveGrpCd?: string
): Promise<ListItemResponse<CarManagerSurveyReportDetail>> => {
  const url = `${ROOT_URL}/admin/surv-aggr-carman/${carmanId || "all"}`;
  const res = await request(HttpMethod.Get, url, {
    stDt,
    endDt,
    srveGrpCd: srveGrpCd || "A26001",
  });
  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(CarManagerSurveyReportDetail, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 카매니저 평가 리포트(엑셀용)
 * */

export const fetchCarManagerSurveyReportDetailForExcel = async (
  stDt?: string,
  endDt?: string
): Promise<ListItemResponse<CarManagerSurveyReportDetailForExcel>> => {
  const url = `${ROOT_URL}/admin/surv-aggr-carman-excel`;
  const res = await request(HttpMethod.Get, url, {
    stDt,
    endDt,
  });
  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(
      CarManagerSurveyReportDetailForExcel,
      list as Array<JSON>
    ),
    count: Number(listCnt),
  };
};

/**
 * 계약 설문 리포트
 */
export const fetchServiceSurveyReportDetail = async (
  stDt?: string,
  endDt?: string,
  srveGrpCd?: string
): Promise<ServiceSurveyReportDetail> => {
  const url = `${ROOT_URL}/admin/surv-aggr-point`;
  const res = await request(HttpMethod.Get, url, {
    stDt,
    endDt,
    srveGrpCd: srveGrpCd || "A26001",
  });
  const { data } = res;
  const { response } = data;
  return plainToClass(ServiceSurveyReportDetail, response);
};

/**
 * 고객 설문결과 리포트
 */
export const fetchDirectSurveyReportResults = async (): Promise<
  ListItemResponse<DirectSurveyResult>
> => {
  const url = `${ROOT_URL}/admin/qstn-result`;
  const res = await request(HttpMethod.Get, url);
  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(DirectSurveyResult, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 고객 설문결과 리포트 상세
 */
export const fetchDirectSurveyReportResultDetail = async (
  qstnOrdId: string
): Promise<ListItemResponse<DirectSurveyResultDetail>> => {
  const url = `${ROOT_URL}/admin/qstn-resultDet`;
  const res = await request(HttpMethod.Get, url, {
    qstnOrdId,
  });
  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(DirectSurveyResultDetail, list as Array<JSON>),
    count: Number(listCnt),
  };
};
