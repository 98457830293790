import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
// defines
import { ModalProps } from "../../../../../../types";
import { NcRentFeeSetResVO, RentFeeSaveVO } from "../../../../../../../models/NcRentFeeSet";
// apis
import { fetchSrRentFeeDetail, updateSrRentFeeState } from "../../../../../../../apis/nc-catalogs";
// components
import ModalTableWrapper from "../../../../../../../components/shared/layout/ModalTableWrapper";
import ClientRowTable from "../../../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import Input from "../../../../../../../components/shared/data-entry/antd/Input";
import Button from "../../../../../../../components/shared/general/antd/Button";
import GridInput from "./components/GridInput";
// utils
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
// import { formatNumber } from "../../../../../../../utils/common-utils";
import { DISPLAY_STATE_INPUT, createRentFeeCols } from "../../utils/table-utils";

interface Props extends ModalProps {
  prodId?: string;
}
/**
 * 1년 렌트 기본 렌탈료 "설정" 모달
 * 
 * @param props 
 * @returns 
 */
const RentFeeSetupModal: React.FC<Props> = (props: Props) => {
  
  const { visible, onCancel, prodId, onDataChange } = props;
  const [gridApi, setGridApi] = useState<GridApi>();
  const [dataFetching, setDataFetching] = useState(false);
  // const [rentFee, setRentFee] = useState<string>("");
  const [rentFeeSetRes, setRentFeeSetRes] = useState<NcRentFeeSetResVO>();
  const [gridUptIdxList, setGridUptIdxList] = useState<Number[]>(new Array());
  // const [changedRows, setChangedRows] = useState<Number[]>();

  useEffect(() => {
    // console.log(gridUptIdxList)
    setGridUptIdxList(new Array());
  }, []);

  const onModalOpen = async () => {
    if (prodId) {
      await requestFetchRentFee();
    } else {
      setRentFeeSetRes(undefined);
    }
  };

  /**
   * 모달 닫기
   */
  const closeModal = () => {
    // setRentFee(befTestData.mmRentAmt12); // TODO: 렌탈료 초기화
    if (onCancel) onCancel();
  }

  /**
   * 렌탈료 갱신
   */
  const requestUpdateRentFee = async () => {
    if (prodId) {
      try {
        const list: RentFeeSaveVO[] = [];
        gridUptIdxList.forEach(v => {
          const rowNode: any = gridApi?.getRowNode(String(v));
          const { data } = rowNode;
          list.push(data);
        });

        if (list.length > 0) {
          await updateSrRentFeeState(list);
          alertSuccess("렌탈료가 변경되었습니다.");
          if (onDataChange) onDataChange(); // 부모 데이터 갱신
        } else {
          alertSuccess("변경할 데이터가 없습니다.");
        }
        if (closeModal) closeModal();     // 모달 닫기
      } catch (e) {
        alertError(getErrorMessage(e));
      }
    }
  };

  /**
   * 렌탈료 가져오기
   * 
   * @returns 
   */
  const requestFetchRentFee = async () => {
    setDataFetching(true);
    try {
      if (prodId) setRentFeeSetRes(await fetchSrRentFeeDetail(prodId));
    } catch (e) {
      alertError(getErrorMessage(e));
      return undefined;
    } finally {
      setDataFetching(false);
    }
  };

  /**
   * grid 내 input renderer
   * 
   * @returns 
   */
  const renderInputCol = () => {
    return (props: ICellRendererParams) => {
      const { data, rowIndex } = props;
      const onChange = (v: string) => {
        data.rglRentAmt = v;
      }
  
      return (
        <GridInput
          rowIndex={rowIndex}  
          data={data.rglRentAmt}
          onChange={onChange}
          gridUptIdxList={gridUptIdxList}
          setGridUptIdxList={setGridUptIdxList}
        />
      );
    };
  };

  /**
   * Render Helpers
   */
  const renderDetail = () => {
    if (rentFeeSetRes) {

      // 기준 기본 렌탈료
      // const baseRentFeeSection = rentFeeSetRes.srRentFeelist.map(v => {
      //   return (
      //     {
      //       label: `${v.custClsCd + '/' + v.cntrTerm + '개월/' + v.drvSoe} 월렌탈료`,
      //       content: `${formatNumber(v.rglRentAmt)}원`,
      //     }
      //   );
      // });

      return (
        <ModalTableWrapper
          headerTitle="기본 렌탈료"
          style={{
            position: "relative",
          }}
        >
          {/** <div
            style={{
              position: "absolute",
              top: 0,
              left: 180,
            }}
          >
            <Button onClick={requestFetchRentFee}>
              가져오기
            </Button>
          </div>
          <DetailSection
            label="기준 기본 렌탈료"
            fieldInfos={baseRentFeeSection}
            hideDivider
          /> */}
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            cols={createRentFeeCols()}
            rowData={rentFeeSetRes.srRentFeelist}
            frameworkComponents={{
              [DISPLAY_STATE_INPUT]: renderInputCol()
            }}
          />
        </ModalTableWrapper>
      );
    }

    return "";
  };

  return (
    <>
      <Modal
        size="large"
        title="1년 렌트 기본 렌탈료 설정"
        visible={visible}
        onCancel={closeModal}
        onOpen={onModalOpen}
        footer={[
          <Button key="cancel" type="default" onClick={closeModal}>
            취소
          </Button>,
          <Button key="submit" type="primary" onClick={requestUpdateRentFee}>
            확인
          </Button>,
        ]}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
    </>
  );
};

RentFeeSetupModal.defaultProps = {
  prodId: undefined,
};

export default RentFeeSetupModal;