import strings from "../constants/strings";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import { formatDateString } from "../../../../utils/date-utils";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
} from "../../../../utils/table-utils";

export const createDefaultRentOptionInfoCols = (): Array<TableColumn> => {
  return [
    // 등록일
    // createTableCol("regDtm", "등록일", undefined, {
    //   width: CW_SMALL,
    //   valueGetter: (params) => {
    //     const { data } = params;
    //     const { regDtm } = data;
    //     if (regDtm) return formatDateString(regDtm);
    //     return "";
    //   },
    // }),
    // 신용등급
    createTableCol("kcbGrd", "신용등급", FilterType.Text, {
      width: CW_MEDIUM,
      valueGetter: (params) => {
        const { data } = params;
        return `${data.kcbGrd}등급`;
      },
    }),
    // 계약기간
    createTableCol("cntrTermMmNm", "계약기간", FilterType.Text, {
      width: CW_MEDIUM,
      valueGetter: (params) => {
        const { data } = params;
        return `${data.cntrTermMmNm}개월`;
      },
    }),
    // 약정주행거리
    createTableCol("prmsDtcNm", "약정주행거리", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 운전자범위
    createTableCol("drvSoeNm", "운전자범위", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 적용기간
    createTableCol("dateRange", "적용기간", undefined, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: (params) => {
        const { data } = params;
        const { apyStDt, apyEndDt } = data;
        return `${formatDateString(apyStDt)} ~ ${formatDateString(apyEndDt)}`;
      },
    }),

    {
      ...createActionCol(80),
    },
  ];
};
