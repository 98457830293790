import React, { useState } from "react";
import moment from "moment";
import { FormikProps, FormikValues } from "formik";
// ag-grid
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import { ICellRendererParams } from "ag-grid-community";
// consts
import {
  FILTER_OPTIONS_EST_STATES,
  FILTER_OPTIONS_NC_PROD_TYPE,
  FILTER_OPTIONS_SRVC_ID,
  COOPER_SEARCH_OPTIONS,
  SK_GRP_DISCOUNT_SEARCH_OPTIONS,
  TKVRTRN_SEARCH_OPTIONS,
} from "@constants/enums";
import commonStrings from "@constants/strings";
import strings from "./constants/strings";
import { CONTRACT_KIND_FILTER_OPTIONS } from "../RentContractManager/constants/enums";
// utils
import { createActionCellRenderers } from "@utils/table-utils";
import { getTodayDateRange } from "@utils/date-utils";
import { getPageIndex } from "@utils/ag-grid-utils";
import { alertError } from "@utils/render-utils";
import { getErrorMessage } from "@utils/common-utils";
import { createEstCols } from "./utils/table-utils";
// components
import { FilterElementType } from "@components/shared/layout/TableFilterLayout/types";
import { createUserDetailRenderer } from "@components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import PaginationTableLayout from "@components/shared/composition/PaginationTableLayout";
import Button from "@components/shared/general/antd/Button";
import { showConfirm } from "@components/shared/feedback/Confirm";
import Modal from "@components/shared/feedback/antd/Modal";
import FormBuilder from "@components/shared/data-entry/FormBuilder";
import { FormElementType, FormInfo } from "@components/shared/data-entry/FormBuilder/types";
import EstimationDetailModal from "./pages/EstimationDetailModal";
import Estimation from "../../../models/Estimation";
import RecCarInfoModal from "../RentContractManager/pages/RecCarInfoModal";
// apis
import { fetchEstimations, deleteEstimation } from "@apis/estimations";
import { setConnectLogs } from "@apis/logs";
// hooks
import useAuth from "@hooks/useAuth";

const EstimationManager: React.FC = () => {
  const { user } = useAuth();
  const [totalCount, setTotalCount] = useState(0);
  const [custCount, setCustCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>();
  const [recCarModalVisible, setRecCarModalVisible] = useState(false);
  const [selectedProdId, setSelectedProdId] = useState<string>();
  const [selectedModeGrdId, setSelectedModeGrdId] = useState<string>();
  const [isVisbleEstDel, setIsVisbleEstDel] = useState(false);
  const [carNo, setCarNo] = useState<string>("");
  const [logInfo, setLogInfo] = useState<string>("");

  let formik: FormikProps<FormikValues>;

  const cols = createEstCols();

  const filterInitialValues = {
    stDt: getTodayDateRange(),
    custClsCd: "",
    custNm: "",
    srvcId: "",
    ncarClsFlag: "",
    hpNo: "",
    estStsCd: "",
    repCartypeNm: "",
    skGrpYn: "",
    cooperCd: "",
    tkvRtrnYnCd: "",
    carNo: "",
  };

  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "견적기간",
    },
    {
      key: "custNm",
      type: FilterElementType.Input,
      label: commonStrings.LABEL_CUSTOMER_NAME,
      placeholder: commonStrings.HINT_CUSTOMER_NAME,
    },
    {
      key: "hpNo",
      type: FilterElementType.NumberInput,
      label: commonStrings.LABEL_HP_NO,
      placeholder: commonStrings.HINT_HP_NO,
    },
    {
      key: "custClsCd",
      type: FilterElementType.Select,
      options: CONTRACT_KIND_FILTER_OPTIONS,
      label: "견적유형",
    },
    {
      key: "srvcId",
      type: FilterElementType.Select,
      options: FILTER_OPTIONS_SRVC_ID,
      label: "차량구분",
    },
    {
      key: "ncarClsFlag",
      type: FilterElementType.Select,
      options: FILTER_OPTIONS_NC_PROD_TYPE,
      label: "판매유형",
    },
    {
      key: "estStsCd",
      type: FilterElementType.Select,
      options: FILTER_OPTIONS_EST_STATES,
      label: "견적상태",
    },
    {
      key: "repCartypeNm",
      type: FilterElementType.Input,
      label: "모델명",
      placeholder: "모델명을 입력해주세요",
    },
    {
      key: "skGrpYn",
      type: FilterElementType.Select,
      label: "외부채널",
      options: SK_GRP_DISCOUNT_SEARCH_OPTIONS,
    },
    {
      key: "cooperCd",
      type: FilterElementType.Select,
      label: "제휴채널",
      options: COOPER_SEARCH_OPTIONS,
    },
    {
      key: "tkvRtrnYnCd",
      type: FilterElementType.Select,
      label: "인수옵션",
      options: TKVRTRN_SEARCH_OPTIONS,
    },
    {
      key: "carNo",
      type: FilterElementType.Input,
      label: "차량번호",
      placeholder: commonStrings.HINT_CAR_NO,
    },
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const {
      stDt,
      custClsCd,
      srvcId,
      ncarClsFlag,
      custNm,
      hpNo,
      estStsCd,
      repCartypeNm,
      skGrpYn,
      cooperCd,
      tkvRtrnYnCd,
      carNo,
    } = appliedFilterValues;
    const stDtParam = moment(stDt[0]).format("YYYYMMDD");
    const endDtParam = moment(stDt[1]).format("YYYYMMDD");

    try {
      const { items, count, extraData } = await fetchEstimations(
        getPageIndex(startRow, paginationPageSize),
        paginationPageSize,
        stDtParam,
        endDtParam,
        custNm,
        custClsCd,
        srvcId,
        ncarClsFlag,
        hpNo,
        estStsCd,
        repCartypeNm,
        skGrpYn,
        cooperCd,
        tkvRtrnYnCd,
        carNo
      );

      setTotalCount(count ?? 0);
      setCustCount(extraData ?? 0);
      success({
        rowData: items,
        rowCount: count,
      });
      setConnectLogs({
        userId: user.userId,
        userIp: user.clientIp,
        methodNm: "/admin/est-list",
        menuName: "견적/계약 - 견적관리",
        actionFlag: "SEARCH",
        reqCntn: appliedFilters
      });
    } catch (e) {
      setTotalCount(0);
      setCustCount(0);
      success({
        rowData: [],
        rowCount: 0,
      });
    }
  };

  /**
   * 체크박스 선택
   * 
   * @param rows 
   */
  // const handleCheckRowChanged = (rows: Array<Estimation>) => {
  //   setCheckedRows(rows);
  // };

  /**
   * 견적폐기 처리
   */
  const handleDisuseEstimation = async (allDelYn: string) => {
    // console.log('handleDisuseEstimation - ', checkedRows)
    showConfirm(
      "견적폐기",
      "선택한 견적을 견적폐기 처리하시겠습니까?",
      async () => {
        const carNo = ""; // TODO: 항목 가져오기
        // checkedRows?[0].
        // const estDelList: Array<EstDel> = checkedRows.map(
        //   ({ estReqNo }) => ({
        //     // TODO: 구현 필요
        //     est_req_no: estReqNo
        //   })
        // );
        // const params: EstimationDisuseParam = {
        //   allDelYn,
        //   carNo,
        //   listCnt: String(estDelList?.length),
        //   estDelList
        // };
        // console.log(params)
        // await requestDisuseEstimation(params);
      }
    );
  };

  /**
   * Render Helpers
   */
  const createActions = () => {
    return [
      {
        title: "견적폐기",
        onClick: async () => {
          // await handleDisuseEstimation("Y");
          setIsVisbleEstDel(true);
        },
        // disabled: checkedRows.length === 0,
      },
      // {
      //   title: "견적폐기",
      //   onClick: async () => {
      //     await handleDisuseEstimation("N");
      //   },
      //   disabled: checkedRows.length === 0,
      // },
    ]
  };

  /**
   * [견적폐기] 모달 폼 생성
   * 
   * @returns 
   */
  const createFormInfo: () => FormInfo[] = () => [
    {
      key: "carNo",
      type: FormElementType.TextArea,
      label: "차량번호",
      placeholder: "차량번호를 입력해주세요",
      required: true,
      onChange: (value) => {
        setCarNo(value?.trim());
      },
    },
  ];

  /**
   * [견적폐기] submit
   * 
   * @param values 
   * @returns 
   */
  const handleSubmit = async (values: FormikValues) => {
    if (!carNo) {
      alertError("차량번호를 입력해주세요.");

      return;
    }

    try {
      // 1. 견적 폐기
      await deleteEstimation(carNo);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
    // 2. 모달 닫기
    setIsVisbleEstDel(false);
    // 3. 차량번호 초기화
    setCarNo("");
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { estReqNo } = data;
    setSelectedDataId(estReqNo);
    setModalVisible(true);
  };

  const handleUserDetailCellClick = (custId?: string) => {
    // TODO
    alertError("custId 필요");
  };

  /**
   * Render Helpers
   */

  const renderCustomerCount = () => {
    return (
      <div
        style={{
          marginLeft: "16px",
        }}
      >
        고객
        <span
          style={{
            marginLeft: "8px",
            fontWeight: "bold",
          }}
        >
          {custCount}
        </span>
        명
      </div>
    );
  };

  return (
    <>
      <PaginationTableLayout
        headerTitle={strings.TITLE_HEADER_ESTIMATION_MANAGER}
        tableHeaderTitle={strings.TABLE_HEADER_ESTIMATION_LIST}
        onDataLoad={onDataLoad}
        // onCheckRowChanged={handleCheckRowChanged}
        leftActions={createActions()}
        // rowSelection="multiple"
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        totalCount={totalCount}
        hideFilterAction
        tableFooterContents={renderCustomerCount()}
        frameworkComponents={{
          recCarDetail: (props: ICellRendererParams) => {
            const { data } = props;
            if (data.recClsDeg) {
              return (
                <Button
                  type="default"
                  size="small"
                  onClick={() => {
                    const model = data as Estimation;
                    setSelectedProdId(model.extgProdId);
                    setSelectedModeGrdId(model.extgModeGrdId);
                    setRecCarModalVisible(true);
                    setRecCarModalVisible(true);
                  }}
                >
                  {`${data.recClsDeg}차추천`}
                </Button>
              );
            }

            return "";
          },
          ...createUserDetailRenderer(handleUserDetailCellClick),
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
        }}
        methodName="/admin/est-list"
        logInfo={logInfo}
      />
      <Modal
        size="small"
        title="견적폐기"
        visible={isVisbleEstDel}
        onCancel={() => {
          // 1. 모달 닫기
          setIsVisbleEstDel(false);
          // 2. 차량번호 초기화
          setCarNo("");
        }}
        onOk={() => {
          formik.handleSubmit();
        }}
        okButtonProps={{ disabled: !carNo }}
      >
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={{ carNo: carNo }}
          forms={createFormInfo()}
          onSubmit={handleSubmit}
        />
      </Modal>
      <EstimationDetailModal
        id={selectedDataId}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
      />
      <RecCarInfoModal
        visible={recCarModalVisible}
        onCancel={() => {
          setRecCarModalVisible(false);
        }}
        prodId={selectedProdId}
        modeGrdId={selectedModeGrdId}
      />
    </>
  );
};

EstimationManager.defaultProps = {};
export default EstimationManager;
