export default class MGMReport {
  // 고객ID
  public custId = "";

  // 고객명
  public custNm = "";

  // 가입일
  public frnRecEntryDt = "";

  // 계약종료일(최종)
  public lastCntrEndDt = "";

  // 리워드 지급 횟수
  public recRewardCnt = 0;

  // 리워드 지금 금액
  public recRewardSumAmt = 0;

  // 추천횟수
  public recCnt = 0;
}
