import * as Parallel from "async-parallel";
import UploadResult from "../../models/UploadResult";
import imageCompression from "browser-image-compression";
import { ListItemResponse, ListRequestParams } from "../types";
import { HttpMethod, request } from "../../network/request";
import { plainToClass } from "class-transformer";
import { ROOT_URL } from "../../network/api-urls";
import { AucCatalog, AucCatalogDetail, AucCatalogRentAmtParam, AucComtractList } from "../../models/AucCatalog";

export interface GetAucCatalogRequestParams extends ListRequestParams {
  // 등록일자 조회시작일자
  regStDt?: string;
  // 등록일자 조회종료일자
  regEndDt?: string;
  // 제휴사코드
  compCd?: string;
  // 차량번호
  carNo?: string;
}

/**
 * 제휴중고 상품 리스트 조회
 */
 export const fetchAUCCatalogs = async (
  params?: GetAucCatalogRequestParams
): Promise<ListItemResponse<AucCatalog>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/auc-catalogs`,
    params as Record<string, any>
  );

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(AucCatalog, list as Array<JSON>),
    count: totalCnt,
  };
};

/**
 * 제휴중고 상품 노출상태 변경
 */
 export const editAucCatalogDisplayState = async (
  prodId: string,
  viewYn: string
) => {
  const url = `${ROOT_URL}/admin/auc-catalogs-viewyn`;
  await request(HttpMethod.Post, url, undefined, {
    prodId,
    viewYn,
  });
};


/**
 * 제휴중고 상품 상세 조회
 */
 export const fetchAUCCatalogDetail = async (
  prodId: string
): Promise<AucCatalogDetail> => {
  const url = `${ROOT_URL}/admin/auc-catalogs/${prodId}`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  return plainToClass(AucCatalogDetail, response);
}; 

/**
 * 제휴중고 상품 태그 정보 수정
 */
export const fetchAUcCooperCatalogsTagUpdate = async (
  prodId: string,
  tagCd: string,
) => {
  const url = `${ROOT_URL}/admin/auc-catalogs/${prodId}/tag-update`;
  await request(HttpMethod.Post, url, undefined, {tagCd});
}; 

/**
 * 제휴중고 차량소개 변경
 */
 export const editMdComment = async (
  prodId: string,
  compCd: string,
  carNo: string,
  brandNm: string,
  carClassNm: string,
  carGradeNm: string,
  yearType: string,
  lastDtc: string,
  fuelNm: string,
  istdTrans: string,
  engdisp: string,
  seaterCapa: string,
  innrCarColorNm: string,
  xtnlCarColorNm: string,
  carGradePrice: string,
  carRegDtm: string,
  mainOptNms: string,
  mdComment: string
) => {
  const url = `${ROOT_URL}/admin/auc-catalogs/${prodId}`;
  await request(HttpMethod.Post, url, undefined, {
    compCd,
    carNo,
    brandNm,
    carClassNm,
    carGradeNm,
    yearType,
    lastDtc,
    fuelNm,
    istdTrans,
    engdisp,
    seaterCapa,
    innrCarColorNm,
    xtnlCarColorNm,
    carGradePrice,
    carRegDtm,
    mainOptNms,
    mdComment,
  });
};


/**
 * 제휴 중고차 - 상품 상세 프라이싱(계약 기간별/약정거리별 렌탈료) 수정
 */
 export const updateUcCooperCatalogsDetailPricing = async (
  prodId: string,
  params: AucCatalogRentAmtParam[],
) => {
  const url = `${ROOT_URL}/admin/auc-catalogs/${prodId}/pricing`;
  await request(HttpMethod.Post, url, undefined, params);
};


/**
 * 제휴 중고차 이미지 벌크 등록
 */
 export const uploadAUCImages = async (carId: string, files: any[]) => {
  /**
   * 1. 원본이미지 등록
   */
  const url = `${ROOT_URL}/com/multiuploadfiles`;
  const originFormData = new FormData();

  for (let i = 0; i < files.length; i += 1) {
    originFormData.append("uploadfiles", files[i]);
    console.log(`originFile`, files[i]);
    console.log(`originFile size ${files[i].size / 1024 / 1024} MB`);
  }

  console.log("원본이미지 배열[업로드전]", files);

  const res = await request(
    HttpMethod.Post,
    url,
    { idtfClsCd: "687111", carId },
    originFormData,
    undefined,
    true
  );

  const { data } = res;
  const { response } = data;
  const list = plainToClass(UploadResult, response as JSON[]);
  console.log("원본이미지 응답[업로드후]", list);

  /**
   * 2. 리사이즈 이미지 등록
   */
  const resizeUrl = `${ROOT_URL}/admin/auc-catalogs/${carId}/multiimage-reg`;

  const imgIds = list.map(({ imgId }) => imgId);
  const resizedFormData = new FormData();
  const options = {
    maxSizeMB: 0.1,
    useWebWorker: true,
  };
  const compressedImages: any[] = [];
  try {
    await Parallel.each(
      files,
      async (file) => {
        const compressed = await imageCompression(file, options);
        // Blob to File
        let compressedFile = compressed;
        if (compressed instanceof Blob) {
          compressedFile = new File([compressed], `${file.name}`);
        }
        compressedImages.push(compressedFile);
        console.log(`compressedFile`, compressedFile);
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        );
        resizedFormData.append("uploadfiles", compressedFile);
      },
      1
    );
  } catch (e) {
    console.error(e);
  }
  console.log("리사이즈이미지[업로드전]", compressedImages);

  await request(
    HttpMethod.Post,
    resizeUrl,
    { UploadImgId: imgIds },
    resizedFormData,
    undefined,
    true
  );
};


export interface UCImageUnlinkParam {
  imgId: string;
  carId: string;
  rltIdtfId: string;
}

/**
 * 제휴 중고차 이미지 삭제
 */

export const unlinkAUCImage = async (
  carId: string,
  param: UCImageUnlinkParam
) => {
  const url = `${ROOT_URL}/admin/auc-catalogs/${carId}/image-delete`;
  const imgId = param.imgId;
  const rltIdtfId = param.rltIdtfId;
  await request(HttpMethod.Post, url, undefined, {imgId, rltIdtfId});
};

/**
 * 제휴 중고차 이미지 벌크 삭제
 */
 export const unlinkAUCImages = async (
  carId: string,
  params: UCImageUnlinkParam[]
) => {
  await Parallel.each(params, async (param) => {
    await unlinkAUCImage(carId, param);
  });
};

interface ImageSortOrderParam {
  imgId: string;
  viewSeq: string;
}

/**
 * 제휴 중고차 이미지 게시순번 변경
 */

 export const editImageSortOrders = async (prodId: string, params: ImageSortOrderParam[]) => {
  const url = `${ROOT_URL}/admin/auc-catalogs/${prodId}/image-seq`;
  await request(HttpMethod.Post, url, undefined, params);
};


export interface GetAucContractRequestParams extends ListRequestParams {
  // 등록일자 조회시작일자
  regStDt?: string;
  // 등록일자 조회종료일자
  regEndDt?: string;
  // 차량번호
  carNo?: string;
  // 제휴사명
  compCd?: string;
  // 계약상태
  cntrReqSts?: string;
}

/**
 * 제휴중고 계약 리스트 조회
 */
 export const fetchAUCContract = async (
  params?: GetAucContractRequestParams
): Promise<ListItemResponse<AucComtractList>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/auc-cntr-list`,
    params as Record<string, any>
  );

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(AucComtractList, list as Array<JSON>),
    count: totalCnt,
  };
};

/**
 * 제휴 중고차 - 계약 상태 변경
 */
export const updateUcCooperCntrList = async (
  cntrId: string,
  cntrReqSts: string,
) => {
  const url = `${ROOT_URL}/admin/auc-cntr-list`;
  await request(HttpMethod.Post, url, undefined, {
    cntrId,
    cntrReqSts,
  });
};