import React from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {
  GridReadyEvent,
  SelectionChangedEvent,
} from "ag-grid-community/dist/lib/events";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { ColumnApi, GridApi } from "ag-grid-community";
import styled from "styled-components";
import TableColumn from "../../model/TableColumn";
import "../../styles/table-theme.scss";
import AG_GRID_LOCALE_KO from "../../constants/localization";
import DateRangeFilter from "../AgGridFilters/DateRangeFilter";
import TextFilter from "../AgGridFilters/TextFilter";
import StatusCellText from "../AgGridCellRenderers/StatusCellText";
import { CR_STATUS_TEXT } from "../ServerRowTable";

export interface ClientRowTableProps {
  width?: string | number;
  height?: string | number;
  cols: Array<TableColumn>;
  paginationPageSize?: number;
  floatingFilter?: boolean;
  frameworkComponents?:
    | {
        [p: string]: {
          new (): any;
        };
      }
    | any;
  rowSelection?: string;
  onRowClicked?: (selectedRow: any, colId: string, index: number) => void;
  apiRef?: (api: GridApi, colApi: ColumnApi) => void;
  onCheckRowChanged?: (data: Array<any>) => void;
  rowData?: Array<any>;
  sortable?: boolean;
  rowHeight?: number;
  autoHeight?: boolean;
  getRowHeight?: any;
  pinnedBottomRowData?: any;
}

const ClientRowTableDefs: React.FC<ClientRowTableProps> = (
  props: ClientRowTableProps
) => {
  const {
    width,
    height,
    cols,
    floatingFilter,
    frameworkComponents,
    apiRef,
    onCheckRowChanged,
    rowData,
    sortable,
    rowHeight,
    autoHeight = false,
    getRowHeight,
    pinnedBottomRowData,
  } = props;

  const tableWidth = typeof width === "number" ? `${width}px` : width;
  const tableHeight = typeof height === "number" ? `${height}px` : height;

  const onGridReady = (params: GridReadyEvent) => {
    const { api, columnApi } = params;
    if (apiRef) apiRef(api, columnApi);
  };

  const handleSelectionChanged = (event: SelectionChangedEvent) => {
    const { api } = event;
    if (onCheckRowChanged) {
      onCheckRowChanged(api.getSelectedRows());
    }
  };

  return (
    <TableContainer
      className="ag-theme-custom-alpine"
      width={tableWidth}
      height={tableHeight}
    >
      <AgGridReact
        // @ts-ignore
        modules={[ServerSideRowModelModule]}
        gridOptions={{
          suppressContextMenu: true,
          rowHeight,
          getRowHeight,
          headerHeight: 24,
        }}
        defaultExportParams={{
          allColumns: true,
        }}
        rowData={rowData}
        localeText={AG_GRID_LOCALE_KO}
        rowClass="mode-row-style"
        frameworkComponents={{
          ...frameworkComponents,
          dateRangeFilter: DateRangeFilter,
          textFilter: TextFilter,
          [CR_STATUS_TEXT]: StatusCellText,
        }}
        onSelectionChanged={handleSelectionChanged}
        suppressRowClickSelection
        rowSelection="multiple"
        onGridReady={onGridReady}
        defaultColDef={{
          autoHeight,
          wrapText: autoHeight,
          resizable: true,
          floatingFilter: false,
          suppressMenu: true,
          sortable,
          floatingFilterComponentParams: {
            suppressFilterButton: true,
          },
        }}
        pinnedBottomRowData={pinnedBottomRowData}
        columnDefs={cols}
      >
        {/* {cols.map(({ headerName, field, valueGetter, minWidth, ...rest }) => (
          <AgGridColumn
            valueGetter={valueGetter}
            key={headerName}
            field={field}
            headerName={headerName}
            minWidth={minWidth}
            {...rest}
          />
        ))} */}
      </AgGridReact>
    </TableContainer>
  );
};

ClientRowTableDefs.defaultProps = {
  rowSelection: "single",
  width: "100%",
  height: "100%",
  floatingFilter: true,
  sortable: true,
};
export default ClientRowTableDefs;

const TableContainer = styled.div<any>`
  //padding: 20px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
