export default class CarManagerSurveyReport {
  // 카매니저ID
  public carmanId = "";

  // 카매니저명
  public carmanNm = "";

  // 관리 계약 수
  public carmanCntrCnt = "";

  // 인도 완료 수
  public ldCntrCnt = "";

  // 응답 계약 수
  public replyCntrCnt = "";

  // 친절도 평점
  public avgPoint03 = "";

  // 전문성 평점
  public avgPoint04 = "";

  // 종합 평점
  public avgPoint05 = "";
}
