import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { DiscountMasterAddParam, DiscountMasterEditParam } from "./types";
import DiscountMaster from "../../models/DiscountMaster";

const apiRootUrl = `${ROOT_URL}/admin/dc-master`;

/**
 * 할인 마스터 리스트 조회
 */
export const fetchDiscountMasters = async (): Promise<
  ListItemResponse<DiscountMaster>
> => {
  const res = await request(HttpMethod.Get, apiRootUrl);
  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(DiscountMaster, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 할인 마스터 등록
 */

export const addDiscountMaster = async (params: DiscountMasterAddParam) => {
  await request(HttpMethod.Post, apiRootUrl, undefined, params);
};

/**
 * 할인마스터 수정
 */
export const editDiscountMaster = async (
  dcGrdId: string,
  params: DiscountMasterEditParam
) => {
  await request(HttpMethod.Put, `${apiRootUrl}/${dcGrdId}`, undefined, params);
};
