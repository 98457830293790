import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_HEADER_CAR_INFO_MANAGER: "차량정보관리",
    TITLE_DESCRIPTION_HEADER:
      "고객 커뮤니케이션을 위해 COSS의 정보를 NICE D&R정보 형태로 가공해서 표준화 정보를 구성합니다. 연결되지않은 정보가 발견될 경우 COSS 차량과 수동 연결 후 정보를 가공해 주시기 바랍니다.",
    TITLE_TABLE_HEADER: "차량 리스트",

    COL_REGISTERED_AT: "등록일",
    COL_COSS_CONNECTED_AT: "연동일",
    COL_COSS_CAR_NO: "모델 ID",
    COL_MODEL: "모델",
    COL_DETAIL_MODEL: "세부모델",
    COL_BRAND: "제조사",
    COL_MANUFACTURE_YEAR: "연식",
    COL_TRIM: "트림",
    COL_CAR_TYPE: "외형",
    COL_FUEL_TYPE: "연료",
    COL_CAR_PRICE: "차량 기본가격",

    TITLE_CONNECT_COSS: "연결하기",
  },
});
