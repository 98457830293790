import React, { useState } from "react";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  CooperMaster,
} from "../../../../../apis/auc-master/types";
import AucCooperDetail  from "../../../../../models/AucCooperDetail";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import Button from "../../../../../components/shared/general/antd/Button";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  selectUcCooperCompanyDetail,
} from "../../../../../apis/auc-master";
import ModifyCooperModal from "../ModifyCooperModal";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import {
  createTableCol,
  FilterType,
} from "../../../../../components/shared/data-display/Tables/model/TableColumn";
import { CW_2X_LARGE, CW_SMALL } from "../../../../../utils/table-utils";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import CntrTermMmListModal from "./pages/CntrTermMmListModal";
import PrmsDtcListModal from "./pages/PrmsDtcListModal";
import ImpsnlListModal from "./pages/ImpsnlListModal";
import CrdGrdListModal from "./pages/CrdGrdListModal";

interface Props extends ModalProps {
  exhibitionMaster?: CooperMaster;
}

const CooperDetailModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, exhibitionMaster } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [cooperDetail, setCcooperDetail] = useState<AucCooperDetail>();
  const [masterInfoEditModalVisible, setMasterInfoEditModalVisible] = useState(false);
  const [compCd, setCompCd] = useState<string>();
  const [cntrTermMmListModalVisible, setCntrTermMmListModalVisible] = useState(false);
  const [prmsDtcListModalVisible, setPrmsDtcListModalVisible] = useState(false);
  const [impsnlListModalVisible, setImpsnlListModalVisible] = useState(false);
  const [crdGrdListModalVisible, setCrdGrdListModalVisible] = useState(false);

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchDetail();
    setDataFetching(false);
  };

  const requestFetchDetail = async () => {
    if (exhibitionMaster) {
      try {
        const compCd = exhibitionMaster.compCd;
        setCompCd(compCd);
        const cooperDetail = await selectUcCooperCompanyDetail(compCd);
        setCcooperDetail(cooperDetail);
      } catch (e) {
        alertError(getErrorMessage(e));
        setCcooperDetail(undefined);
      }
    }
  };

  const renderDetail = () => {
    if (cooperDetail) {
      const {
        compCd,
        compNm,
        cntrTermMmList,
        prmsDtcList,
        impsnlList,
        crdGrdList,
      } = cooperDetail;

      const exhibitionInfoSection: SectionFieldInfo[] = [
        {
          label: "제휴사명",
          content: compNm+" ("+compCd+")",
          span: 12,
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <Button
                onClick={() => {
                  setMasterInfoEditModalVisible(true);
                }}
              >
                관리
              </Button>
            );
          },
        },        
      ];

      const cntrTermMmListSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div
                style={{
                  height: "320px",
                }}
              >
                <ClientRowTable
                  cols={[
                    createTableCol("condCd", "조건코드", FilterType.Text, {
                      width: CW_SMALL,
                    }),
                    createTableCol("condCdNm", "조건명", FilterType.Text, {
                      width: CW_2X_LARGE,
                    }),
                    createTableCol("useYn", "사용유무", FilterType.Text, {
                      width: CW_SMALL,
                      flex: 1,
                    }),
                  ]}
                  rowData={cntrTermMmList}
                />
              </div>
            );
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {            
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                {
                  <>
                    <Button
                      onClick={() => {
                        setCntrTermMmListModalVisible(true);
                      }}
                    >
                      수정
                    </Button>
                  </>
                }
              </FormItem>
            )
          },
        }
      ];

      const prmsDtcListSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div
                style={{
                  height: "320px",
                }}
              >
                <ClientRowTable
                  cols={[
                    createTableCol("condCd", "조건코드", FilterType.Text, {
                      width: CW_SMALL,
                    }),
                    createTableCol("condCdNm", "조건명", FilterType.Text, {
                      width: CW_2X_LARGE,
                    }),
                    createTableCol("useYn", "사용유무", FilterType.Text, {
                      width: CW_SMALL,
                      flex: 1,
                    }),
                  ]}
                  rowData={prmsDtcList}
                />
              </div>
            );
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {            
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                {
                  <>
                    <Button
                      onClick={() => {
                        setPrmsDtcListModalVisible(true);
                      }}
                    >
                      수정
                    </Button>
                  </>
                }
              </FormItem>
            )
          },
        }
      ];

      const impsnlListSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div
                style={{
                  height: "220px",
                }}
              >
                <ClientRowTable
                  cols={[
                    createTableCol("condCd", "조건코드", FilterType.Text, {
                      width: CW_SMALL,
                    }),
                    createTableCol("condCdNm", "조건명", FilterType.Text, {
                      width: CW_2X_LARGE,
                    }),
                    createTableCol("useYn", "사용유무", FilterType.Text, {
                      width: CW_SMALL,
                      flex: 1,
                    }),
                  ]}
                  rowData={impsnlList}
                />
              </div>
            );
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {            
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                {
                  <>
                    <Button
                      onClick={() => {
                        setImpsnlListModalVisible(true);
                      }}
                    >
                      수정
                    </Button>
                  </>
                }
              </FormItem>
            )
          },
        }
      ];

      const crdGrdListSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div
                style={{
                  height: "450px",
                }}
              >
                <ClientRowTable
                  cols={[
                    createTableCol("condCd", "조건코드", FilterType.Text, {
                      width: CW_SMALL,
                    }),
                    createTableCol("condCdNm", "조건명", FilterType.Text, {
                      width: CW_2X_LARGE,
                    }),
                    createTableCol("condVal", "%", FilterType.Text, {
                      width: CW_SMALL,
                      flex: 1,
                    }),
                  ]}
                  rowData={crdGrdList}
                />
              </div>
            );
          },
        },
        /*
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {            
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                {
                  <>
                    <Button
                      onClick={() => {
                        setCrdGrdListModalVisible(true);
                      }}
                    >
                      수정
                    </Button>
                  </>
                }
              </FormItem>
            )
          },
        }
        */
      ];

      return (
        <div>
          <DetailSection label="기본 정보" fieldInfos={exhibitionInfoSection} />   
          <DetailSection label="계약 기간" fieldInfos={cntrTermMmListSection} />   
          <DetailSection label="약정주행거리" fieldInfos={prmsDtcListSection} />   
          <DetailSection label="대물 보험" fieldInfos={impsnlListSection} />   
          <DetailSection label="보증금" fieldInfos={crdGrdListSection} hideDivider />
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        visible={visible}
        onCancel={onCancel}
        title="제휴사 상세 (제휴중고차)"
        onOpen={onModalOpen}
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      <ModifyCooperModal
        visible={masterInfoEditModalVisible}
        onCancel={() => setMasterInfoEditModalVisible(false)} 
        compCd={compCd as string} 
        onDataChange={async () => {
          await requestFetchDetail();
        }}        
      />
      <CntrTermMmListModal
        visible={cntrTermMmListModalVisible}
        onCancel={() => setCntrTermMmListModalVisible(false)} 
        aucCooperDetail = {cooperDetail} 
        onDataChange={async () => {
          await requestFetchDetail();
        }}        
      />
      <PrmsDtcListModal
        visible={prmsDtcListModalVisible}
        onCancel={() => setPrmsDtcListModalVisible(false)} 
        aucCooperDetail = {cooperDetail} 
        onDataChange={async () => {
          await requestFetchDetail(); 
        }}        
      />
      <ImpsnlListModal
        visible={impsnlListModalVisible}
        onCancel={() => setImpsnlListModalVisible(false)} 
        aucCooperDetail = {cooperDetail} 
        onDataChange={async () => {
          await requestFetchDetail();
        }}        
      />
      <CrdGrdListModal
        visible={crdGrdListModalVisible}
        onCancel={() => setCrdGrdListModalVisible(false)} 
        aucCooperDetail = {cooperDetail} 
        onDataChange={async () => {
          await requestFetchDetail();
        }}        
      />
    </>
  );
};

export default CooperDetailModal;
