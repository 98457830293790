import React, { useState } from "react";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import {FormElementType, FormInfo} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {alertSuccess, renderLoading} from "../../../../../../../utils/render-utils";
import {FormikProps, FormikValues} from "formik";
import {updateUcCooperCompanyCond} from "../../../../../../../apis/auc-master";
import AucCooperDetail from "../../../../../../../models/AucCooperDetail";
import {  
  CooperDetailRequest,
  commCdList
} from "../../../../../../../apis/auc-master/types";

interface Props extends ModalProps {
  aucCooperDetail?: AucCooperDetail;
}

const ImpsnlListModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, aucCooperDetail, onDataChange } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [defaultCompCd, setDefaultCompCd] = useState<string>();
  const [default1, setDefault1] = useState<boolean>();
  const [default2, setDefault2] = useState<boolean>();


  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);

    if (aucCooperDetail) {
      setDefaultCompCd(aucCooperDetail.compCd);
      setDefault1(aucCooperDetail.impsnlList[0].useYn == "Y" ? true : false);
      setDefault2(aucCooperDetail.impsnlList[1].useYn == "Y" ? true : false);
    } else {
      setDefaultCompCd("");
      setDefault1(false);
      setDefault2(false);
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const forms: Array<FormInfo> = [
    {      key: "A10004",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "1억",        }, 
    {      key: "A10005",   type: FormElementType.Checkbox,   style: { width: "100px", },  required: false,   label: "2억",        },
  ]

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
      setConfirmLoading(true);
      const commCdList: commCdList[] = [];
      if(values.A10004) {  const commCd = { condCd: "A10004" as string, condVal: "0" as string  }; commCdList.push(commCd); }
      if(values.A10005) {  const commCd = { condCd: "A10005" as string, condVal: "0" as string  }; commCdList.push(commCd); }

      const params: CooperDetailRequest = {
        condClsCd: "A17007",
        condCdList: commCdList,
      };

      if (aucCooperDetail) {
        try {
          await updateUcCooperCompanyCond(defaultCompCd as string, params);
          alertSuccess("변경되었습니다."); 
          if (onDataChange) onDataChange();
          if (onCancel) onCancel();
        } catch (e) {          
          console.log(e);
        } finally {
          setConfirmLoading(false);
        }
      } else {
        setConfirmLoading(false);
        if (onCancel) onCancel();
      }
  }; 

  /**
   * Render Helpers
   */


  return (
    <Modal
      size="small"
      title=" 대물보험 변경"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? (
          renderLoading()
      ) : (
          <FormBuilder
              formRef={(ref) => {
                formik = ref;
              }}
              initialValues={
                {
                  A10004: default1,
                  A10005: default2,
                }
              }
              forms={forms}
              onSubmit={handleSubmit}
          />
      )}
    </Modal>
  );
};

ImpsnlListModal.defaultProps = {
  visible: true,
};
export default ImpsnlListModal;
