export default class CarManagerReplacementDetail {
  // 부재자카매니저 설정여부
  public useYn = "";

  // 부재자카매니저 아이디
  public carmanId = "";

  // 부재자카매니저 사용자아이디
  public userId = "";

  // 대무카매니저 아이디
  public daCarmanId = "";

  // 대무카매니저 사용자아이디
  public daUserId = "";

  // 부재자카매니저 부재기간
  public stDt = "";

  // 부재자카매니저 부재기간
  public stEndDt = "";

  // 부재자카매니저 부재 사유 코드
  public rsnCd = "";

  // 부재자카매니저 부재사유
  public rsn = "";

  // 고유번호
  public seq = "";

}
