import { plainToClass } from "class-transformer";
import { ListItemResponse, ListRequestParams } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import TagoTkvReqContract from "../../models/TagoTkvReqContract";

export interface RequestParams extends ListRequestParams {  
  pageIndex: number;
  pageSize: number;
  tkvReqStDt?: string;
  tkvReqEndDt?: string;
  custNm?: string;
}

/**
 * 타고바이(TAGOBUY) - 인수요청 리스트
 */
export const fetchTagoTkvReqContracts = async (
  param: RequestParams,
): Promise<ListItemResponse<TagoTkvReqContract>> => {
  const url = `${ROOT_URL}/admin/tb-tkv-req-list`;
  const res = await request(HttpMethod.Get, url, param as any);

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(TagoTkvReqContract, list as Array<JSON>),
    count: Number(totalCnt),
  };
};