import * as Yup from "yup";
import { FormikValues } from "formik";
import {
  ENUM_PROD_TYPE_ORDER_PLACE,
  ENUM_PROD_TYPE_TAGO_PAY,
  ENUM_VM_DEPOSIT,
  ENUM_VM_DEPOSIT_INSURANCE,
  ENUM_VM_F_DEPOSIT,
} from "../../../../../../constants/enums";
import errorMessages from "../../../../../../constants/errors";
import {
  EstimationParam,
  RentFeeQueryParam,
} from "../../../../../../apis/est-sim/types";
import EstimationCatalogDetail from "../../../../../../models/EstimationSimulator/EstimationCatalogDetail";
import CustomerDetail from "../../../../../../models/CustomerDetail";
import RentFeeResult from "../../../../../../models/RentFeeResult";
import BusinessInfo from "../../../../../../models/BusinessInfo";

export const createInitialValues = () => {
  return {
    // 상품 유형
    prodType: ENUM_PROD_TYPE_ORDER_PLACE,

    // 제조사
    brand: null,

    // 차명
    prodId: null,

    // 트림 인덱스
    trimIndex: null,
    // 외장색상
    carXtnlColor: null,

    // 내장색상
    carInnrColor: null,

    // 추가 옵션
    extraOptions: [],

    // 계약 기간
    cntrTermMm: null,

    /** 보험연령 */
    insrAge: null,

    // 약정 주행거리
    prmsDtc: null,

    // 운전자 범위
    drvSoe: null,

    // 초기납부조건
    depositWay: null,

    // 정비서비스
    fixSrvcId: null,

    // 대차서비스
    lndcarSrvcId: null,

    // 신용등급
    creditRating: null,

    // 담보율
    depositRate: null,

    // 대물
    impsnl: null,
  };
};

export const createValidationSchema = () => {
  return Yup.lazy((form: any) => {
    const { depositWay, prodType } = form;

    const shape = {
      // 차명
      prodId: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),

      // brand
      brand: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),

      // 트림 인덱스
      trimIndex: Yup.number()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),
      // 외장색상
      carXtnlColor: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),

      // 내장색상
      carInnrColor: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),

      // 계약 기간
      cntrTermMm: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),

      // 약정 주행거리
      prmsDtc: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),

      // 운전자 범위
      drvSoe: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),

      // 초기납부조건
      depositWay: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),

      // 정비서비스
      fixSrvcId: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),

      // 대물
      impsnl: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),
    };

    if (prodType === ENUM_PROD_TYPE_TAGO_PAY) {
      // @ts-ignore
      shape.insrAge = Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD);
    }

    if (depositWay === ENUM_VM_DEPOSIT || depositWay === ENUM_VM_F_DEPOSIT) {
      // @ts-ignore
      shape.depositRate = Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD);
    }

    return Yup.object().shape(shape);
  });
};

export const createRentFeeQuery = (
  values: FormikValues,
  customerDetail: CustomerDetail,
  orderPlaceCatalogDetail?: EstimationCatalogDetail,
  businessInfo?: BusinessInfo
): RentFeeQueryParam => {
  const {
    prodType,
    // 차명
    prodId,
    // 트림 인덱스
    trimIndex,
    // 외장색상
    carXtnlColor,

    // 내장색상
    carInnrColor,

    // 추가 옵션
    extraOptions,

    // 계약 기간
    cntrTermMm,

    // 보험연령
    insrAge,

    // 요금제구분
    amtCondCd,

    // 약정 주행거리
    prmsDtc,

    // 운전자 범위
    drvSoe,

    // 초기납부조건
    depositWay,

    // 정비서비스
    fixSrvcId,

    // 대차서비스
    lndcarSrvcId,

    // 담보율
    depositRate,

    // 대물
    impsnl,
  } = values;

  let makerIdParam = "";
  let carTypeIdParam = "";
  let carTypeDtlIdParam = "";
  let modlIdParam = "";
  let cossProdIdParam = "";
  // 대물
  let impsnlParam = "";

  let carAmount = 0;
  let optionAmount = 0;
  let colorAmount = 0;
  let deposit = 0;
  let initamt = 0;

  let trim;

  // 신차
  if (orderPlaceCatalogDetail) {
    trim = orderPlaceCatalogDetail.trimList[trimIndex];
    carAmount = Number(trim.carAmt);
  }

  if (trim) {
    makerIdParam = trim.carMakerId;
    carTypeIdParam = trim.cartypeId;
    carTypeDtlIdParam = trim.cartypeDtlId;
    modlIdParam = trim.cossModlId;
    cossProdIdParam = trim.cossProdId;

    trim.trimOption.carOptionsList.forEach((carOption) => {
      const { optId } = carOption;

      if (extraOptions.indexOf(optId) !== -1) {
        optionAmount += Number(carOption.optAmt);
      }
    });

    trim.trimOption.exteriorColorOptionsList.forEach(({ cossColorId, amt }) => {
      if (carXtnlColor === cossColorId) {
        colorAmount += Number(amt);
      }
    });

    trim.trimOption.interiorColorOptionsList.forEach(({ cossColorId, amt }) => {
      if (carInnrColor === cossColorId) {
        colorAmount += Number(amt);
      }
    });
  }

  if (depositWay === ENUM_VM_DEPOSIT) {
    deposit = (depositRate / 100) * (carAmount + optionAmount + colorAmount);
  } else if (depositWay === ENUM_VM_F_DEPOSIT) {
    initamt = (depositRate / 100) * (carAmount + optionAmount + colorAmount);
  }

  // 대물
  if (impsnl) {
    impsnlParam = impsnl;
  }

  // 고객 식별값
  let custChkValParam = "";

  // 사업자
  if (businessInfo) {
    custChkValParam = businessInfo.busiNo;
  }
  // 개인
  else {
    custChkValParam = customerDetail.ci;
  }

  const params = {
    // 상품 아이디
    modeProdId: prodId,
    // 등급 아이디
    modeGrdId: trim ? trim.modeGrdId : "",
    // 채널 구분
    chlGubun: "MGR",
    // 고객 아이디
    custId: customerDetail.custId,
    // 사업자 고객 아이디
    busrCustId: businessInfo && businessInfo.busrCustId,
    // 계약기간
    cntrTermMm,

    // 보험연령
    insrAge,

    // 요금제구분
    amtCondCd,

    // 제조사 ID
    makerId: makerIdParam,
    // 차종 ID
    cartypeId: carTypeIdParam,

    // 차정 ID
    cartypeDtlId: carTypeDtlIdParam,

    // 모델 ID
    modlId: modlIdParam,

    // COSS 상품 ID
    prodId: cossProdIdParam,

    // 차량 내부 색상
    carInnrColor,

    // 차량 외부 색상
    carXtnlColor,

    // 옵션 I 가격
    optI: optionAmount,

    // 약정 거리
    prmsDtc,

    // 운전자 범위
    drvSoe,

    // 선수금
    initamt,

    // 보증금
    deposit,

    // 보증보험 코드
    grtInsr: depositWay === ENUM_VM_DEPOSIT_INSURANCE ? "7158000" : "",

    // 정비서비스 ID
    fixSrvcId,

    // 특판 여부
    aheadBuyYn: prodType === ENUM_PROD_TYPE_ORDER_PLACE ? "N" : "Y",

    // 신용등급 (법인일경우 기업등급)
    odsRentDcCls:
      businessInfo && businessInfo.isCorp
        ? businessInfo.branchCrdGrd
        : customerDetail.crdGrd,

    // 임직원 여부 (사업자계약시 "N"으로 개인일 경우 임직연여부 필드 전달)
    empYn: businessInfo ? "N" : customerDetail.empYn,

    // 개인: CI
    custChkVal: custChkValParam,

    // 대물
    impsnl: impsnlParam,
  };

  // 대차서비스
  if (lndcarSrvcId) {
    // @ts-ignore
    params.lndcarSrvcId = lndcarSrvcId;
  }

  return params;
};

export const createEstimationParams = (
  rentFeeQuery: RentFeeQueryParam,
  catalogDetail: EstimationCatalogDetail,
  rentFeeResult: RentFeeResult,
  customerDetail: CustomerDetail,
  values: FormikValues,
  businessInfo?: BusinessInfo
): EstimationParam => {
  const {
    prodType,

    // 트림 인덱스
    trimIndex,

    // 추가 옵션
    extraOptions,

    // 초기납부조건
    depositWay,

    // 담보율
    depositRate,

    // Mode 상품 아이디
    prodId,
  } = values;

  const { custId, custNm } = customerDetail;
  const {
    modlId,
    makerId,
    optI,
    carXtnlColor,
    carInnrColor,
    cntrTermMm,
    amtCondCd,
    prmsDtc,
    drvSoe,
    fixSrvcId,
    lndcarSrvcId,
    deposit,
    // 대물
    impsnl,
  } = rentFeeQuery;

  const {
    mmRentAmt,
    mmRentAmtV,
    rglRentAmt,
    rntFeeId,
    odsDcList,
    mmInitamt,
  } = rentFeeResult;
  const { trimList, tkvRtnYn } = catalogDetail;

  let cossProdId = "";
  let carAmt = 0;
  let colorAmt = 0;

  const trim = trimList[trimIndex];
  const extraOptionListParam: Array<any> = [];
  if (trim) {
    cossProdId = trim.cossProdId;
    carAmt = trim.carAmt;

    trim.trimOption.interiorColorOptionsList.forEach(({ cossColorId, amt }) => {
      if (cossColorId === carInnrColor) {
        colorAmt += Number(amt);
      }
    });

    trim.trimOption.exteriorColorOptionsList.forEach(({ cossColorId, amt }) => {
      if (cossColorId === carXtnlColor) {
        colorAmt += Number(amt);
      }
    });

    extraOptions.forEach((id: string) => {
      const filteredOption = trim.trimOption.carOptionsList.filter(
        ({ optId }) => optId === id
      )[0];
      if (filteredOption) {
        extraOptionListParam.push({
          optClsCd: filteredOption.optClsCd,
          optId: filteredOption.optId,
          optNm: filteredOption.optNm,
          optAmt: filteredOption.optAmt,
        });
      }
    });
  }

  let depositRateParam;
  if (depositWay === ENUM_VM_DEPOSIT_INSURANCE) {
    if (businessInfo && businessInfo.isCorp) {
      // 법인 => 법인사업자 최소담보율
      depositRateParam = Number(businessInfo.pldgRt);
    } else {
      // 개인 / 개인사업자 => 개인 최소담보율
      depositRateParam = Number(customerDetail.pldgRt);
    }
  } else if (depositWay === ENUM_VM_DEPOSIT) {
    // 보증금 고객이 선택한 담보율
    depositRateParam = depositRate;
  }
  // 선수금
  else {
    depositRateParam = depositRate;
  }

  const dcListParam = odsDcList.map(({ odsDcAmt, odsDcCd }) => {
    return {
      dcAmt: odsDcAmt,
      dcClsCd: odsDcCd,
    };
  });

  let pldgCondCdParam = "";
  // 보증금
  if (depositWay === ENUM_VM_DEPOSIT) {
    if (depositRateParam === 0) {
      // 면제
      pldgCondCdParam = "201004";
    } else {
      // 보증금
      pldgCondCdParam = "201003";
    }
  }
  // 보증보험
  else if (depositWay === ENUM_VM_DEPOSIT_INSURANCE) {
    // 보증금
    pldgCondCdParam = "201001";
  }
  // 선수금
  else {
    pldgCondCdParam = "201005";
  }

  let rtnParams: EstimationParam = {
    // 고객 ID
    custId,

    // 고객명
    custNm,

    // 상품 ID
    prodId,

    // COSS 상품 ID
    cossProdId,

    // 제조사
    carMakerId: makerId,

    // COSS 모델 ID
    cossModlId: modlId,

    // 차량단가(옵션제외)
    carUnpr: carAmt.toString(),

    // 차량단가(옵션/색상 추가금액 포함)
    totCarAmt: (Number(carAmt) + Number(optI) + colorAmt).toString(),

    // 내부차량색상(COSS ID 사용)
    innrCarColorId: carInnrColor,

    // 외부차량색상(COSS ID 사용)
    xtnlCarColorId: carXtnlColor,

    // 계약기간
    cntrTermMm,

    // 약정거리코드
    prmsDtcClsCd: prmsDtc,

    // 담보율
    pldgRt: depositRateParam.toString(),

    // 월렌트료 (부가세미포함)
    mmRentAmt,

    // 월렌트료 (부가세포함)
    mmRentAmtV,

    // 운전자 연령 (COSS ID 사용)
    drivngAgeCd: "414003", // 26세 고정

    // 운전자범위
    drvSoe,

    // 담보구분코드 (COSS ID 사용) 201001 -> 보증보험, 201003 -> 보증금, 201004 -> 면제, 201005 -> 선수금
    pldgCondCd: pldgCondCdParam,

    // 정비서비스 여부
    fixSrvcYn: fixSrvcId,

    // 사업자번호
    busiNo: businessInfo && businessInfo.busiNo,

    // 대차서비스
    lndcarSrvcId: lndcarSrvcId || "",

    // 특판견적 여부
    aheadEstYn: prodType !== ENUM_PROD_TYPE_ORDER_PLACE ? "Y" : "N",

    // 표준렌탈료
    rglRentAmt,

    // 보증금액 (보증보험 -> 0원)
    pldgAmt: deposit,

    // 할인내역리스트
    dcContList: dcListParam,

    // 추가 옵션 리스트
    extraOptionList: extraOptionListParam,

    // 대물 코드
    impsnlCd: impsnl,

    rntFeeId,

    mmInitamt: mmInitamt || 0,

    // 지역코드
    useAreaCd: trim.useAreaCd ? trim.useAreaCd : "",

    // 요금제구분코드
    ubrAmtCondCd: amtCondCd
  };

  // ! 반납형인 경우, 반납형코드로 전달
  if (tkvRtnYn === "Y") {
    rtnParams.tkvRtrnYnCd = "467002";
  }

  return rtnParams;
};