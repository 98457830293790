export default class CarManagerTransferRequest {
  // 이관요청 카매니저 ID
  public carmanId = "";

  // 이관요청 카매니저 명
  public carmanNm = "";

  // 이관받는 카매니저 ID
  public tgtCarmanId = "";

  // 이관받는 카매니저 명
  public tgtCarmanNm = "";

  // 회원ID
  public custId = "";

  // 회원명
  public custNm = "";

  // 이관상태코드
  public tranSts = "";

  // 이관상태코드 명
  public tranStsNm = "";

  // 요청사유코드
  public reqCd = "";

  // 요청사유코드 명
  public reqNm = "";

  // 요청사유코드
  public rejtCd = "";

  // 요청사유코드 명
  public rejtNM = "";

  // 이관요청일
  public reqDtm = "";

  // 상태변경일
  public tranDtm = "";

  // 이관계약건수
  public cntrCnt = 0;

  public tranSeq = "";
}
