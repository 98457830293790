import CommonOption from "./CommonOption";
import TrimInfo from "./TrimInfo";

export default class EstimationCatalogDetail {
  // 상품 트림 List Count
  public trimListCnt = 0;

  // 상품 트림 List
  public trimList: Array<TrimInfo> = [];

  // 상품 공통 옵션
  public commonOption: CommonOption = new CommonOption();

  /** 인수 반납 여부 */
  public tkvRtnYn?: string;
}