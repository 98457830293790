class EventJoinHistory {
  // 고객아이디
  public custId = "";

  // 고객명
  public custNm = "";

  // 고객해드폰번호
  public hpNo = "";

  // 응모일자
  public evntAgreDtm = "";

  // 이벤트순번
  public evntSeq = "";

  // 이벤트 유형
  public evntTypeCdNm = "";
}

export default EventJoinHistory;
