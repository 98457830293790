class CossUser {
  // 사용자ID
  public userId = "";

  // 사번
  public userNo = "";

  // 사용자명
  public userNm = "";

  // 부서ID
  public deptId = "";

  // 부서명
  public deptNm = "";
}

export default CossUser;
