import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {CW_LARGE, CW_SMALL_M} from "../../../../utils/table-utils";
import {
  dateFormatRenderer,
  numberFormatRenderer,
} from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

export const createCols = (): Array<TableColumn> => {
  return [
    // 가입일
    createServerTableCol("frnRecEntryDt", "가입일", {
      width: CW_SMALL_M,
      flex: 1,
      cellRenderer: dateFormatRenderer("frnRecEntryDt"),
    }),
    // 계약 종료일
    createServerTableCol("lastCntrEndDt", "계약 종료일", {
      width: CW_SMALL_M,
      flex: 1,
      cellRenderer: dateFormatRenderer("lastCntrEndDt"),
    }),
    // 고객명
    createServerTableCol("custNm", "고객명", {
      flex: 1,
      width: CW_SMALL_M,
    }),
    // 사업자명
    createServerTableCol("busrCustNm", "사업자명", {
      flex: 1,
      minWidth: CW_LARGE,
    }),
    // 추천횟수
    createServerTableCol("recCnt", "추천횟수", {
      width: CW_SMALL_M,
      flex: 1,
      cellRenderer: numberFormatRenderer("recCnt"),
    }),
    // 리워드 지급 횟수
    createServerTableCol("recRewardCnt", "리워드 지급 횟수", {
      width: CW_SMALL_M,
      flex: 1,
      cellRenderer: numberFormatRenderer("recRewardCnt"),
    }),
    // 리워드 지급 금액
    createServerTableCol("recRewardSumAmt", "리워드 지급 금액", {
      width: CW_SMALL_M,
      flex: 1,
      cellRenderer: numberFormatRenderer("recRewardSumAmt"),
    }),
  ];
};
