import { createBrowserHistory } from "history";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "../reducers";
import sagas from "../sagas";

export const history = createBrowserHistory();

const INITIAL_STATE = {};

const buildStore = () => {
  const saga = createSagaMiddleware();
  const connectedRouter = routerMiddleware(history);
  const middles = [saga, connectedRouter];
  const enhancers = [];

  if (process.env.NODE_ENV === "development") {
    const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }
  const composedEnhancers = compose(applyMiddleware(...middles), ...enhancers);
  const persistConfig = {
    key: "root",
    storage,
    blacklist: ["router"],
  };
  const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(history)
  );
  const store = createStore(persistedReducer, INITIAL_STATE, composedEnhancers);
  const persistor = persistStore(store);
  saga.run(sagas);
  return { store, persistor };
};

export default buildStore;
