import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import {
  ExhibitionCatalog,
  ExhibitionMaster,
} from "../../../../../../../apis/ex/types";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../../../components/shared/layout/DetailSection";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import { formatDateString } from "../../../../../../../utils/date-utils";
import Button from "../../../../../../../components/shared/general/antd/Button";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import ExhibitionMasterInfoEditModal from "../ExihibitionMasterInfoEditModal";
import ClientRowTable from "../../../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import {
  editExhibitionAddDetail,
  fetchExhibitionCatalogs,
  fetchExhibitionDetail,
  fetchExhibitionDetailWithAddInfo,
  RequestUpdateExhibitionAddDetail,
} from "../../../../../../../apis/ex";
import { createCatalogTagCols } from "../../../../../../car-sales/NewCarCatalogManager/pages/NewCarCatalogModal/utils/table-utils";
import {
  MOBILE_TAG_RENDERER,
  PC_TAG_RENDERER,
} from "../../../../../../car-sales/CatalogTagManager/utils/table-utils";
import {
  mobileTagRenderer,
  pcTagCellRenderer,
} from "../../../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import CatalogTagSetupModal from "../../../../../../car-sales/NewCarCatalogManager/pages/NewCarCatalogModal/pages/CatalogTagSetupModal";
import TagPreviewModal from "../../../../../../car-sales/NewCarCatalogManager/pages/NewCarCatalogModal/pages/TagPreviewModal";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import NewCarCatalogDetail from "../../../../../../../models/NewCarCatalogDetail";
import errorMessages from "../../../../../../../constants/errors";

interface Props extends ModalProps {
  exhibitionMaster: ExhibitionCatalog;
}

const createValidationSchema = () => {
  return Yup.object().shape({
    dcTitle: Yup.string().required(errorMessages.REQUIRED_FIELD),
    dcInfo: Yup.string().required(errorMessages.REQUIRED_FIELD),
    explainTitle: Yup.string().required(errorMessages.REQUIRED_FIELD),
    explain: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });
};

const createInitialValue = () => ({
  dcTitle: "",
  dcInfo: "",
  explainTitle: "",
  explain: "",
});

const TimeDealInfoEditModal: React.FC<Props> = (props: Props) => {
  const { visible, onDataChange, onCancel, exhibitionMaster } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const tagCols = createCatalogTagCols();
  const [masterInfoEditModalVisible, setMasterInfoEditModalVisible] = useState(
    false
  );
  const [exhibitionMasterDetail, setExhibitionMasterDetail] = useState<
    ExhibitionMaster
  >();

  const [tagPreviewModalVisible, setTagPreviewModalVisible] = useState(false);
  const [tagSetupModalVisible, setTagSetupModalVisible] = useState(false);
  const handleTagSetupButtonClick = () => {
    setTagSetupModalVisible(true);
  };
  const [initialValue, setInitialValue] = useState(createInitialValue());

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchExhibitionDetail();
    setDataFetching(false);
  };

  const requestFetchExhibitionDetail = async () => {
    if (exhibitionMaster) {
      try {
        const exhibition = await fetchExhibitionDetail(
          exhibitionMaster.exhMstSeq
        );

        const exhibitionDetail = await fetchExhibitionDetailWithAddInfo(
          exhibition.exhMstSeq,
          "Y",
          exhibition.exhGbnCd,
          exhibitionMaster.prodId
        );

        setExhibitionMasterDetail({
          ...exhibitionDetail,
          ...exhibition,
          prodId: exhibitionMaster.prodId,
          prodNm: exhibitionMaster.prodNm,
          exhViewSeq: exhibitionMaster.exhViewSeq,
        });

        const { dcTitle, dcInfo, explainTitle, explain } = exhibitionDetail;

        if (dcTitle || dcInfo || explainTitle || explain) {
          setInitialValue({
            dcTitle: dcTitle || "",
            dcInfo: dcInfo || "",
            explainTitle: explainTitle || "",
            explain: explain || "",
          });
        }
      } catch (e) {
        alertError(getErrorMessage(e));
      }
    }
  };
  const createFormInfo: () => FormInfo[] = () => [
    {
      key: "dcTitle",
      type: FormElementType.Input,
      label: "할인 타이틀",
      placeholder: "타이틀을 입력해주세요",
      required: true,
      span: 12,
    },
    {
      key: "dcInfo",
      type: FormElementType.Input,
      label: "할인 내역",
      placeholder: "할인 정보를 입력해주세요",
      required: true,
    },
    {
      key: "explainTitle",
      type: FormElementType.Input,
      label: "설명 타이틀",
      placeholder: "설명 타이틀을 입력해 주세요",
      required: true,
    },
    {
      key: "explain",
      type: FormElementType.TextArea,
      label: "설명",
      placeholder: "설명을 입력해주세요",
      required: true,
    },
  ];

  const requestEditAddDetail = async (values: FormikValues) => {
    if (!exhibitionMasterDetail) return;

    try {
      const params: RequestUpdateExhibitionAddDetail = {
        exhDtlSeq: exhibitionMasterDetail.exhDtlSeq,
        exhMstSeq: exhibitionMasterDetail.exhMstSeq,
        prodId: exhibitionMasterDetail.prodId || "",
        exhGbnCd: exhibitionMasterDetail.exhGbnCd,
        useYn: exhibitionMasterDetail.exhUseYn,
        dcTitle: values.dcTitle,
        dcInfo: values.dcInfo,
        explainTitle: values.explainTitle,
        explain: values.explain,
      };

      await editExhibitionAddDetail(params);

      alertSuccess("기획전이 수정되었습니다");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const handleSubmit = (values: FormikValues) => {
    requestEditAddDetail(values);
  };

  const handleTagPreviewButtonClick = () => {
    setTagPreviewModalVisible(true);
  };

  const renderDetail = () => {
    if (exhibitionMasterDetail) {
      const exhibitionInfoSection: SectionFieldInfo[] = [
        {
          label: "상품명",
          content: exhibitionMasterDetail.exhTitle,
          span: 12,
        },
        { label: "모델", content: "타임딜", span: 12 },
        {
          label: "연식",
          content: exhibitionMasterDetail.exhDesc,
          span: 12,
        },
        {
          label: "제조사",
          content: `${formatDateString(
            exhibitionMasterDetail.exhStDt,
            true
          )} ~ ${formatDateString(exhibitionMasterDetail.exhEndDt, true)}`,
          span: 12,
        },
        {
          label: "게시순번",
          content: exhibitionMasterDetail.exhMstViewSeq,
          span: 12,
        },
      ];

      const tagInfoSection: SectionFieldInfo[] = [
        {
          label: "",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <Button
                  style={{
                    marginRight: "8px",
                  }}
                  onClick={handleTagSetupButtonClick}
                >
                  설정
                </Button>
                <Button ghost onClick={handleTagPreviewButtonClick}>
                  미리보기
                </Button>
                <div
                  style={{
                    marginTop: "8px",
                    height: "300px",
                  }}
                >
                  <ClientRowTable
                    cols={tagCols}
                    rowData={exhibitionMasterDetail.tagList}
                    frameworkComponents={{
                      [PC_TAG_RENDERER]: pcTagCellRenderer,
                      [MOBILE_TAG_RENDERER]: mobileTagRenderer,
                    }}
                  />
                </div>
              </div>
            );
          },
        },
      ];

      const timeDealInfoSection: SectionFieldInfo[] = [
        {
          label: "",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <>
                <FormBuilder
                  formRef={(ref) => {
                    formik = ref;
                  }}
                  labelCol={{
                    span: 3,
                  }}
                  forms={createFormInfo()}
                  validationSchema={createValidationSchema()}
                  initialValues={initialValue}
                  onSubmit={handleSubmit}
                />
                <Button
                  style={{
                    marginRight: "8px",
                  }}
                  onClick={() => formik.handleSubmit()}
                >
                  저장하기
                </Button>
              </>
            );
          },
        },
      ];

      return (
        <div>
          <DetailSection label="차량정보" fieldInfos={exhibitionInfoSection} />

          <DetailSection label="태그 관리" fieldInfos={tagInfoSection} />

          <DetailSection label="타임딜 정보" fieldInfos={timeDealInfoSection} />
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        visible={visible}
        onCancel={onCancel}
        title="차량별 타임딜 카드정보"
        description="타임딜에서는 차량별 정보구성을 해주셔야 합니다."
        onOpen={onModalOpen}
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>

      <CatalogTagSetupModal
        visible={tagSetupModalVisible}
        onCancel={() => {
          setTagSetupModalVisible(false);
        }}
        catalogDetail={
          (exhibitionMasterDetail as unknown) as NewCarCatalogDetail
        }
        onDataChange={async () => {
          await requestFetchExhibitionDetail();
          if (onDataChange) onDataChange();
        }}
        isExhibition
      />
      <TagPreviewModal
        visible={tagPreviewModalVisible}
        onCancel={() => {
          setTagPreviewModalVisible(false);
        }}
        catalogDetail={
          (exhibitionMasterDetail as unknown) as NewCarCatalogDetail
        }
        tagMasters={exhibitionMasterDetail && exhibitionMasterDetail.tagList}
      />
      {/* 기획전 기본정보 수정 */}
      <ExhibitionMasterInfoEditModal
        visible={masterInfoEditModalVisible}
        onCancel={() => {
          setMasterInfoEditModalVisible(false);
        }}
        exhibitionMaster={exhibitionMasterDetail}
        onDataChange={async () => {
          await requestFetchExhibitionDetail();
          if (onDataChange) onDataChange();
        }}
      />
    </>
  );
};

export default TimeDealInfoEditModal;
