import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";

export const createSurveyTemplateCols = (): Array<TableColumn> => {
  return [
    // 설문 그룹코드명
    createTableCol("srveGrpNm", "설문 그룹코드명", FilterType.Text, {
      width: CW_LARGE,
    }),
    // 설문 분류코드명
    createTableCol("srveClfNm", "설문 분류코드명", FilterType.Text, {
      minWidth: CW_MEDIUM,
    }),
    // 설문 내용
    createTableCol("srveCntn", "설문 내용", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 설문 문항 수
    createTableCol("srveQitmCnt", "설문 문항 수", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 관리
    {
      ...createActionCol(80),
    },
  ];
};
