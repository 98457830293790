import styled from "styled-components";


export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const PriceLabel = styled.span`
  flex: 1;
  text-align: left;
  font-size: 14px;
`;

export const PriceTitleStressed = styled.span`
  text-align: right;
  font-weight: bold;
  font-size: 18px;
  color: ${(props) => props.theme.common.colors.point};
`;

export const Divider = styled.div`
  margin-bottom: 16px;
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.common.colors.border};
`;
