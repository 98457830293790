/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FormikProps, FormikValues, Formik } from "formik";
import * as Yup from "yup";
import CarManagerTransferRequest from "../../../../../models/CarManagerTransferRequest";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  ENUM_CAR_MANAGER_TRANSFER_REJECT_CD_OPTIONS,
  ENUM_CAR_MANAGER_TRANSFER_STATE_OPTIONS,
  ENUM_CAR_MANAGER_TRANSFER_STATE_REJECT,
} from "../../../../../constants/enums";
import errorMessages from "../../../../../constants/errors";
import { alertError, alertSuccess } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { editCarManagerTransferRequestState } from "../../../../../apis/car-managers";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import { defaultFormItemLayout } from "../../../../../components/shared/data-entry/FormBuilder";
import Select from "../../../../../components/shared/data-entry/antd/Select";
import { getFormError } from "../../../../../utils/form-utils";
import { FlexBox, Label, Content } from "../../style";

interface Props extends ModalProps {
  carManagerTransferRequest?: CarManagerTransferRequest;
}

const CarManagerTransferRequestDetailModal: React.FC<Props> = (
  props: Props
) => {
  const { visible, onCancel, onDataChange, carManagerTransferRequest } = props;
  const createFormInitialValues = () => ({
    tranSts: null,
    rejtCd: null,
  });
  const [formInitialValues, setFormInitialValues] = useState<any>(
    createFormInitialValues()
  );
  const [confirmLoading, setConfirmLoading] = useState(false);

  const validationSchema = Yup.lazy((form: any) => {
    const { tranSts } = form;

    if (tranSts === ENUM_CAR_MANAGER_TRANSFER_STATE_REJECT) {
      return Yup.object().shape({
        tranSts: Yup.string()
          .required(errorMessages.REQUIRED_FIELD)
          .typeError(errorMessages.REQUIRED_FIELD),
        rejtCd: Yup.string()
          .required(errorMessages.REQUIRED_FIELD)
          .typeError(errorMessages.REQUIRED_FIELD),
      });
    }
    return Yup.object().shape({
      tranSts: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),
    });
  });

  let formik: FormikProps<FormikValues>;

  const onModalOpen = () => {
    setFormInitialValues(createFormInitialValues());
  };

  /**
   * Private Functions
   */

  const requestEditTransferState = async (values: FormikValues) => {
    if (carManagerTransferRequest) {
      setConfirmLoading(true);

      const { tranSts, rejtCd } = values;
      const { tranSeq } = carManagerTransferRequest;
      try {
        await editCarManagerTransferRequestState(tranSeq, tranSts, rejtCd);
        alertSuccess("이관상태가 변경되었습니다.");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    if (carManagerTransferRequest?.tranSts === values.tranSts) {
      if (onCancel) onCancel();
    } else {
      await requestEditTransferState(values);
    }
  };

  /**
   * Render Helpers
   */
  const renderForm = () => {
    return (
      <>
        <div
          style={{
            borderRadius: "8px",
            backgroundColor: "#FAFAFC",
            padding: "16px",
            marginBottom: "24px",
          }}
        >
          <FlexBox>
            <Label>이관요청자 성명:</Label>
            <Content>{carManagerTransferRequest?.carmanNm}</Content>
          </FlexBox>
          <FlexBox>
            <Label>이관받는자 성명:</Label>
            <Content>{carManagerTransferRequest?.tgtCarmanNm}</Content>
          </FlexBox>
        </div>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={formInitialValues}
          innerRef={(ref: FormikProps<FormikValues>) => {
            formik = ref;
          }}
        >
          {(renderProps: FormikProps<FormikValues>) => {
            const { values, setFieldValue } = renderProps;
            const { tranSts, rejtCd } = values;
            return (
              <form>
                <FormItem
                  label="요청상태"
                  required
                  style={{
                    marginBottom: "16px",
                  }}
                  {...defaultFormItemLayout}
                  {...getFormError(renderProps, "tranSts")}
                >
                  <Select
                    value={tranSts}
                    options={ENUM_CAR_MANAGER_TRANSFER_STATE_OPTIONS}
                    placeholder="요청처리 상태를 선택해주세요"
                    onChange={(value) => {
                      setFieldValue("tranSts", value);
                      if (value !== ENUM_CAR_MANAGER_TRANSFER_STATE_REJECT) {
                        setFieldValue("rejtCd", null);
                      }
                    }}
                  />
                </FormItem>
                {tranSts === ENUM_CAR_MANAGER_TRANSFER_STATE_REJECT && (
                  <FormItem
                    label="반려사유"
                    required
                    {...defaultFormItemLayout}
                    {...getFormError(renderProps, "rejtCd")}
                  >
                    <Select
                      value={rejtCd}
                      options={ENUM_CAR_MANAGER_TRANSFER_REJECT_CD_OPTIONS}
                      placeholder="반려사유를 선택해주세요"
                      onChange={(value) => {
                        setFieldValue("rejtCd", value);
                      }}
                    />
                  </FormItem>
                )}
              </form>
            );
          }}
        </Formik>
      </>
    );
  };

  return (
    <Modal
      title="처리상태"
      size="small"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      {renderForm()}
    </Modal>
  );
};

CarManagerTransferRequestDetailModal.defaultProps = {};
export default CarManagerTransferRequestDetailModal;
