import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import { CW_LARGE, CW_SMALL } from "../../../../../../utils/table-utils";
import ConsultingDetail from "../../../../../../models/ConsultingData/ConsultingDetail";
import {INT_CONSULTING_CATEGORY_TRIM} from "../../../../../../constants/enums";

export const createCols = (): Array<TableColumn> => {
  return [
    // 노출순위
    createTableCol("mngrRnk", "순위", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 집계항목
    createTableCol("cartypeNm", "집계항목", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({data}) => {
        const { cond2, cond3, cnslCls } = data as ConsultingDetail;

        // 트림
        if (cnslCls === INT_CONSULTING_CATEGORY_TRIM)
          return `${cond3}`;


        return `${cond2}`;
      }
    }),
  ];
};
