import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_MEDIUM,
} from "../../../../utils/table-utils";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { CR_STATUS_TEXT } from "../../../../components/shared/data-display/Tables/components/ServerRowTable";
import { formatDateString } from "../../../../utils/date-utils";

export const createSurveyReportCols = (): Array<TableColumn> => {
  return [
    // 설문기간
    createTableCol("regDtm", "설문기간", undefined, {
      width: CW_DATE_RANGE,
      valueGetter: (params) => {
        const { data } = params;
        const { qstnStDt, qstnEndDt } = data;
        return `${formatDateString(qstnStDt)}~${formatDateString(qstnEndDt)}`;
      },
    }),
    // 설문그룹명
    createTableCol("qstnGrpNm", "설문그룹명", FilterType.Text, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // 설문명
    createTableCol("qstnOrdNm", "설문명", FilterType.Text, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // 설문 문항수
    createTableCol("qstnCnt", "설문 문항수", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 참여 고객수
    createTableCol("replyCustCnt", "참여 고객수", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 관리
    {
      ...createActionCol(80),
    },
  ];
};
