import React from "react";
import { BaseContainer, ContentWrapper } from "./style";
import Aside from "../../navigation/Aside";
import Content from "../Content";

export interface BaseProps {
  children?: React.ReactNode | React.FC;
}

const Base: React.FC<BaseProps> = (props: BaseProps) => {
  const { children } = props;
  return (
    <BaseContainer>
      <Aside />
      <ContentWrapper>
        <Content>{children}</Content>
      </ContentWrapper>
    </BaseContainer>
  );
};

Base.defaultProps = {};
export default Base;
