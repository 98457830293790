import React, { useState } from "react";
import { ModalProps } from "../../../../../../types";
import {
  ExhibitionCatalog,
  ExhibitionMaster,
  LinkExhibitionCatalogParams,
} from "../../../../../../../apis/ex/types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import ModalTableWrapper from "../../../../../../../components/shared/layout/ModalTableWrapper";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import NewCarCatalog from "../../../../../../../models/NewCarCatalog";
import ClientRowTable from "../../../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createCatalogCols } from "./utils/table-utils";
import {
  fetchExhibitionCatalogs,
  linkExhibitionCatalogs,
} from "../../../../../../../apis/ex";
import { ENUM_PROD_TYPE_TAGO_PAY } from "../../../../../../../constants/enums";

/**
 * 기획전 <-> 신차상품 등록/등록해제 모달
 */

interface Props extends ModalProps {
  initialCheckList?: ExhibitionCatalog[];
  exhibitionMaster?: ExhibitionMaster;
}

const ExhibitionCatalogAddModal: React.FC<Props> = (props: Props) => {
  const cols = createCatalogCols();

  const {
    visible,
    onCancel,
    onDataChange,
    initialCheckList = [],
    exhibitionMaster,
  } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoadng] = useState(false);
  const [catalogs, setCatalogs] = useState<ExhibitionCatalog[]>();
  const [checkedRow, setCheckedRows] = useState<NewCarCatalog[]>([]);

  const onModalOpen = async () => {
    setDataFetching(true);
    setCheckedRows([]);
    await requestFetchCatalogs();
    setDataFetching(false);
  };

  const createLinkParams = () => {
    let params: LinkExhibitionCatalogParams[] = [];
    if (exhibitionMaster) {
      const { exhMstSeq } = exhibitionMaster;
      params = checkedRow.map(({ prodId }) => {
        return {
          exhMstSeq,
          prodId,
          useYn: "Y",
        };
      });

      // 기존에 체크되어있던 항목들중 "Y" -> "N"으로 변경되었을경우 "N"을 명시적으로 보내줘야하기때문에 처리
      const paramIdMapped = params.map(({ prodId }) => prodId);
      for (let i = 0; i < initialCheckList.length; i += 1) {
        const { prodId } = initialCheckList[i];
        if (!paramIdMapped.includes(prodId)) {
          params.push({
            exhMstSeq,
            prodId,
            useYn: "N",
          });
        }
      }
    }

    return params;
  };

  const requestFetchCatalogs = async () => {
    try {
      const { list } = await fetchExhibitionCatalogs(
        exhibitionMaster?.exhMstSeq as string,
        exhibitionMaster?.exhGbnCd as string,
        "N"
      );
      const filtered = list.filter(({ ncarClsFlag }) => {
        return ncarClsFlag !== ENUM_PROD_TYPE_TAGO_PAY;
      });
      setCatalogs(filtered);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const requestLinkCatalogs = async () => {
    setConfirmLoadng(true);
    try {
      const params = createLinkParams();
      await linkExhibitionCatalogs(params);
      alertSuccess("타임딜 차량정보가 변경되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoadng(false);
    }
  };

  const handleModalOk = async () => {
    await requestLinkCatalogs();
  };

  const handleCheckRowChanged = (rows: Array<NewCarCatalog>) => {
    setCheckedRows(rows);
  };

  const renderDetail = () => {
    return (
      <ModalTableWrapper headerTitle="차량리스트">
        <ClientRowTable
          apiRef={(api) => {
            api.forEachNode((node) => {
              const data = node.data as NewCarCatalog;
              const filtered = initialCheckList.filter(({ prodId }) => {
                return prodId === data.prodId;
              });
              if (filtered.length > 0) {
                node.setSelected(true);
              }
            });
          }}
          onCheckRowChanged={handleCheckRowChanged}
          cols={cols}
          rowData={catalogs}
        />
      </ModalTableWrapper>
    );
  };

  return (
    <Modal
      size="large"
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="타임딜 차량관리"
      confirmLoading={confirmLoading}
      onOk={handleModalOk}
    >
      {dataFetching ? renderLoading() : renderDetail()}
    </Modal>
  );
};

export default ExhibitionCatalogAddModal;
