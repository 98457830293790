import React, { useState } from "react";

import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import UsedCarCatalogDetail from "../../../../../../../models/UsedCarCatalogDetail";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import { renderLoading } from "../../../../../../../utils/render-utils";
import { insertUsdCarOpt } from "../../../../../../../apis/uc-catalogs";

interface Props extends ModalProps {
  catalogDetail?: UsedCarCatalogDetail;
}

const UsedCarMainOptionAddModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, catalogDetail, onDataChange } = props;

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);

  let formik: FormikProps<FormikValues>;

  const onModalOpen = async () => {
    
  };

  /**
   * Private Functions
   */
  const forms: Array<FormInfo> = [    
    {
      key: "optNm",
      type: FormElementType.Input,
      label: "옵션명",
      required: true,
    },
  ];

  /**
   * Event Actions
   */
  const handleSubmit = async (values: FormikValues) => {
    setConfirmLoading(true);

    if (catalogDetail) {
      const modeGrdId = catalogDetail.modeGrdId;
      const cossModlId = catalogDetail.cossModlId;
      const optNm = values.optNm;

      try {
        await insertUsdCarOpt({
          modeGrdId: modeGrdId,
          cossModlId: cossModlId,
          optNm: optNm,
        });
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
      } catch (e) {
        console.log(e);
      } finally {
        setConfirmLoading(false);
      }
    } else if (onCancel) onCancel();
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      title="주요옵션 생성"
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
      confirmLoading={confirmLoading}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={{ optNm: "" }}
          forms={forms}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

UsedCarMainOptionAddModal.defaultProps = {
  visible: true,
};
export default UsedCarMainOptionAddModal;
