import {
  ENUM_CORP_BUSINESS_CODE,
  ENUM_PRIVATE_BUSINESS_CODE,
} from "../constants/enums";

class BusinessInfo {
  // 사업자고객ID
  public busrCustId = "";

  // 고객번호
  public custId = "";

  // 사업자 번호
  public busiNo = "";

  // 법인사업자 등록번호
  public corRegNo = "";

  // 사업자 명
  public busrCustNm = "";

  // 사업자 구분 코드( A02002:개인사업자 / A02003:법인사업자 )
  public busrClsCd = "";

  // 우편번호
  public zip = "";

  // 주소
  public addr = "";

  // 상세주소
  public dtladdr = "";

  // 업태
  public bzcd = "";

  // 업종
  public bztp = "";

  // COSS 고객 ID
  public cossCustId = "";

  // COSS세금계산서접촉ID
  public cossCntpId = "";

  // 정산담당자명
  public taxCustNm = "";

  // 정산담당전화번호
  public taxTelNo = "";

  // 정산담당휴대폰번호
  public taxHpNo = "";

  // 추가인증여부
  public addVrfYn = "";

  // KIS 인증여부
  public kisAuthYn = "";

  // 정산담당이메일
  public taxEmail = "";

  // 사업자 등록증 이미지 URL
  public regLicFileUrl = "";

  // 개인사업자 추가인증 처리
  public vrfUserNm = "";

  // 개인사업자 추가인증 처리일시
  public vrfReqDtm = "";

  // 담보율
  public pldgRt = "";

  // 담보 코드
  public pldgRtCd = "";
  public pldgRtNm = "";

  public branchCrdDt = "";
  // 기업 등급
  public branchCrdGrd = "";
  public branchCrdQryYn = "";

  get businessTypeText() {
    if (this.busrClsCd === ENUM_PRIVATE_BUSINESS_CODE) return "개인사업자";
    if (this.busrClsCd === ENUM_CORP_BUSINESS_CODE) return "법인사업자";
    return "";
  }

  // 법인사업자 여부
  get isCorp() {
    return this.busrClsCd === ENUM_CORP_BUSINESS_CODE;
  }

  // 법인사업자 견적가능여부
  get canCorpEstimate() {
    return this.pldgRt !== null;
  }

  // 법인사업자 담보율 텍스트
  get corpPldgRtText() {
    if (this.pldgRt !== null) {
      const rate = Number(this.pldgRt);
      if (rate === 0) return "(담보율 : 면제)";

      return `(담보율 : ${this.pldgRt}%)`;
    }
    return "견적진행불가";
  }
}

export default BusinessInfo;
