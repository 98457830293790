import { McCarCatalogImage } from "./McCarCatalogImage";
import { McCarFixHistory } from "./McCarFixHistory";
import { McCarOptionInfo } from "./McCarOptionInfo";
import { McCarAccdHistList } from "./McCarAccdHistList";
import { McCarCheckList } from "./McCarCheckList";
import TagMaster from "./TagMaster";

export default class McCarCatalogDetail {
  // MODE 상품ID
  public prodId = "";

  // MODE 상품명
  public prodNm = "";

  // 대표차명
  public repCarTypeNm = "";

  // COSS 상품ID
  public cossProdId = "";

  // 판매구분
  public ncarClsFlag = "";

  // 판매구분명
  public ncarClsFlagNm = "";

  // 대표 차량 외관 이미지
  public repCarImg = "";

  // 대표 차량등급 ID
  public modeGrdId = "";

  // COSS 모델ID
  public cossModlId = "";

  // (NICE AB)등급명
  public carGradeNm = "";

  // MODE 연료명
  public modeFuelNm = "";

  // 수입차여부
  public importYn = "";

  // 배기량
  public engdisp = "";

  // 연식(연형)
  public yearType = "";

  // 차량가격(신차가격)
  public carAmt = 0;

  // 차종ID
  public cartypeId = "";

  // 차종명
  public cartypeNm = "";

  // 차정ID
  public cartypeDtlId = "";

  // 차정명
  public cartypeDtlNm = "";

  // 연료코드
  public fuelCd = "";

  // 연료명
  public fuelNm = "";

  // 제조사ID
  public carMakerId = "";

  // 제조사명
  public carMakerNm = "";

  // 변속기
  public grbxNm = "";

  // 인승
  public seaterClsNm = "";

  // MD 차량소개
  public mdComment = "";

  // 주행거리
  public curTravelDtc = "";

  // 차량재고
  public prodStock = 0;

  // 전체 옵션 리스트
  public optNms = "";

  // 차량ID
  public carId = "";

  // 차량번호
  public carNo = "";

  // 내부 색상 ID
  public innrCarColorId = "";

  // 내부 색상명
  public innrCarColorNm = "";

  // 내부 차량색상 이미지 URL
  public innrColorImgUrl = "";

  // 외부 색상 ID
  public xtnlCarColorId = "";

  // 외부 색상명
  public xtnlCarColorNm = "";

  // 외부 차량색상 이미지 URL
  public xtnlColorImgUrl = "";

  // 외장색상 이미지 URL
  public xtnlClrImgUrl = "";

  // coss 내부 색상명
  public cossInnrCarColorNm = "";

  // coss 외장 색상명
  public cossXtnlCarColorNm = "";


  // 표준 월 렌탈료
  public rglRentAmt = 0;
  // 1개월 월 렌탈료
  public cntr1mmRentAmtV = 0;
  // 3개월 월 렌탈료
  public cntr3mmRentAmtV = 0;
  // 6개월 월 렌탈료
  public cntr6mmRentAmtV = 0;
  // 9개월 월 렌탈료
  public cntr9mmRentAmtV = 0;
  // 12개월 월 렌탈료
  public cntr12mmRentAmtV = 0;
  
  // 최초 등록일
  public regDt = "";

  // 차량 이미지 리스트 prefix
  public carImgListNm = "";

  // 차량 이미지 정보
  public carImgList: McCarCatalogImage[] = [];

  // 정비이력 리스트 prefix
  public fixContListNm = "";

  // 정비 이력
  public fixContList: McCarFixHistory[] = [];

  // 주요 옵션 리스트 prefix
  public  mainOptionsListNm = "";

  // 주요 옵션 리스트
  public mainOptionsList: McCarOptionInfo[] = [];

  // 총 옵션가
  public totOptAmt = 0;

  // 태그 리스트
  public dtlTagList: TagMaster[] = [];
}