import { History } from "history";
import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import authReducer, { AuthState } from "./auth";

const createRootReducer = (history: History) => {
  return combineReducers({
    router: connectRouter(history),
    auth: authReducer,
  });
};

export default createRootReducer;

export type RootState = {
  router: RouterState;
  auth: AuthState;
};
