import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { createEventCols, USE_YN_STATE_RENDERER } from "./utils/table-utils";
import CarManagerReplacement from "../../../models/CarManagerReplacement";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import CarManagerReplacementInfoModal from "./pages/CarManagerReplacementInfoModal";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import DateRangeFilter from "../../../components/shared/data-display/Tables/components/AgGridFilters/DateRangeFilter";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { renderSwitchCol } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../constants/strings";
import {
  editCarManagerReplacementUseYn,
  fetchCarManagerReplacementInfos,
} from "../../../apis/car-managers";

const CarManagerReplacementManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [discountModalVisible, setDiscountMasterModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<CarManagerReplacement>();
  const [rows, setRows] = useState<Array<CarManagerReplacement> | undefined>(
    undefined
  );
  const [totalCount, setTotalCount] = useState(0);
  const cols = createEventCols();

  useEffect(() => {
    (async function fetchData() {
      await requestFetchCarManagerReplacementInfos();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestFetchCarManagerReplacementInfos = async () => {
    try {
      const replacements = await fetchCarManagerReplacementInfos();
      const { items, count } = replacements;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      setRows([]);
    }
  };

  const requestUpdateDisplayState = async (
    carmanId: string,
    seq: string,
    checked: boolean
  ) => {
    try {
      await editCarManagerReplacementUseYn(carmanId, seq, checked);
      alertSuccess(commonStrings.FEEDBACK_USE_YN_CHANGE);
      await requestFetchCarManagerReplacementInfos();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  const handleAddClick = () => {
    setSelectedData(undefined);
    setDiscountMasterModalVisible(true);
  };

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    const replacement = data as CarManagerReplacement;
    setSelectedData(replacement);
    setDiscountMasterModalVisible(true);
  };

  const createActions = () => {
    return [
      {
        title: commonStrings.TITLE_ADD_NEW,
        onClick: handleAddClick,
      },
    ];
  };

  const handleUseYnChange = (
    checked: boolean,
    replacement: CarManagerReplacement
  ) => {
    showConfirm(
      checked ? commonStrings.CONFIRM_USE_ON : commonStrings.CONFIRM_USE_OFF,
      checked
        ? commonStrings.CONFIRM_CONTENT_USE_ON
        : commonStrings.CONFIRM_CONTENT_USE_OFF,
      async () => {
        await requestUpdateDisplayState(
          replacement.carmanId,
          replacement.seq,
          checked
        );
      }
    );
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout
        filterable={false}
        headerTitle={commonStrings.CAR_MANAGER_REPLACEMENT_MANAGER}
      >
        <TableLayout
          gridApi={gridApi}
          title={commonStrings.CAR_MANAGER_REPLACEMENT_LIST}
          totalCount={totalCount}
          rightActions={createActions()}
          methodName="/admin/carman/dtyagcy"
        >
          <ClientRowTable
            apiRef={(ref) => {
              setGridApi(ref);
            }}
            rowData={rows}
            cols={cols}
            frameworkComponents={{
              [USE_YN_STATE_RENDERER]: renderSwitchCol(
                "useYn",
                handleUseYnChange
              ),
              ...createActionCellRenderers(undefined, handleEditClick, {
                hideDeleteButton: true,
              }),
              dateRangeFilter: DateRangeFilter,
            }}
          />
        </TableLayout>
      </ContentLayout>
      <CarManagerReplacementInfoModal
        carManagerReplacement={selectedData}
        visible={discountModalVisible}
        onCancel={() => {
          setDiscountMasterModalVisible(false);
        }}
        onDataChange={async () => {
          gridApi?.showLoadingOverlay();
          await requestFetchCarManagerReplacementInfos();
          gridApi?.hideOverlay();
        }}
      />
    </>
  );
};

CarManagerReplacementManager.defaultProps = {};
export default CarManagerReplacementManager;
