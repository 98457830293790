import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../../../types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import BannerInfo from "../../../../../../../models/BannerDetail/BannerInfo";
import FormBuilder from "../../../../../../../components/shared/data-entry/FormBuilder";
import {
  createFormInfo,
  createInitialValues,
  createValidationSchema,
  createAddBannerParam,
  createEditBannerParam,
  createInitialValuesFromBannerInfo,
} from "./utils/form-utils";

import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import { addBanner, editBanner } from "../../../../../../../apis/banners";
import { FormInfo } from "../../../../../../../components/shared/data-entry/FormBuilder/types";
import {
  ENUM_BANNER_DMN_MOBILE,
  ENUM_BANNER_DMN_TABLET,
} from "../../../../../../../constants/enums";

interface Props extends ModalProps {
  bannerInfo?: BannerInfo;
  bannerDmnId?: string;
  bannerId?: string;
  isMainBanner?: boolean;
}

const BannerFormModal: React.FC<Props> = (props: Props) => {
  const {
    visible,
    onCancel,
    onDataChange,
    bannerInfo,
    bannerDmnId,
    bannerId,
    isMainBanner,
  } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formInfo, setFormInfo] = useState<FormInfo[]>(createFormInfo());
  const [validationSchema, setValidationSchema] = useState<any>(
    createValidationSchema()
  );

  let formik: FormikProps<FormikValues>;
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  const onModalOpen = () => {
    setDataFetching(true);

    if (bannerInfo) {
      setInitialValues(createInitialValuesFromBannerInfo(bannerInfo));
      setFormInfo(createFormInfo(isMainBanner));
      setValidationSchema(createValidationSchema(isMainBanner));
    } else {
      setInitialValues(createInitialValues());
      setFormInfo(createFormInfo(isMainBanner));
      setValidationSchema(createValidationSchema(isMainBanner));
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */

  const requestAddBanner = async (values: FormikValues) => {
    if (bannerDmnId && bannerId) {
      try {
        setConfirmLoading(true);
        await addBanner(bannerId, bannerDmnId, createAddBannerParam(values));
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
        alertSuccess("배너가 등록되었습니다.");
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const requestEditBanner = async (values: FormikValues) => {
    if (bannerInfo) {
      try {
        setConfirmLoading(true);

        await editBanner(bannerInfo.bnnrSeq, createEditBannerParam(values));
        if (onDataChange) onDataChange();
        if (onCancel) onCancel();
        alertSuccess("배너정보가 변경되었습니다.");
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const getModalTitle = () => {
    let prefix = "[PC]";
    if (bannerDmnId === ENUM_BANNER_DMN_MOBILE) {
      prefix = "[모바일]";
    } else if (bannerDmnId === ENUM_BANNER_DMN_TABLET) {
      prefix = "[태블릿]";
    }

    if (bannerInfo) {
      return `${prefix}배너 수정`;
    }
    return `${prefix}배너 등록`;
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (bannerInfo) {
      await requestEditBanner(values);
    } else {
      await requestAddBanner(values);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      title={getModalTitle()}
      visible={visible}
      onOpen={onModalOpen}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={formInfo}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

BannerFormModal.defaultProps = {};
export default BannerFormModal;
