/* eslint-disable */
import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  ConnectDropTarget,
  ConnectDragSource,
  DropTargetMonitor,
  DragSourceMonitor,
  DragSource,
  DropTarget,
  DropTargetConnector,
  DragSourceConnector,
  XYCoord,
} from "react-dnd";

import { ListItemWrapper, ColumnWrapper } from "./styles";
import { UsedCarCatalogImage } from "../../../../../../../../../models/UsedCarCatalogImage";

export interface CatalogListItemObject {
  id: string;
  index: number;
}

interface CatalogListItemInstance {
  getNode(): HTMLDivElement | null;
}

interface ImageListItemProps {
  id: string;
  image: UsedCarCatalogImage;
  index: number;
  style?: CSSProperties;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
}

const ListItemType = "card";
export const CW_NO = 50;
export const CW_REG_DATE = 80;
export const CW_IMG = 140;
export const CW_PROD_TYPE = 80;
export const CW_BRAND = 80;
export const CW_PROD_NM = 120;
export const CW_MODEL = 120;

const CatalogListItem = forwardRef<HTMLDivElement, ImageListItemProps>(
  function Card(
    {
      image,
      index,
      moveCard,
      isDragging,
      connectDragSource,
      connectDropTarget,
      ...rest
    },
    ref
  ) {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);

    const opacity = isDragging ? 0 : 1;
    useImperativeHandle<any, CatalogListItemInstance>(ref, () => ({
      getNode: () => elementRef.current,
    }));

    return (
      <ListItemWrapper
        style={{
          // backgroundColor: isDragging ? "grey" : "white",
          opacity,
        }}
        ref={elementRef}
        {...rest}
      >
        {/* No */}
        <ColumnWrapper width={CW_NO}>{index + 1}</ColumnWrapper>
        {/* 이미지 */}
        <ColumnWrapper width={CW_IMG}>
          <div
            className={"img-action-disable"}
            // src={image.fileUrl}
            style={{
              backgroundImage: `url(${image.fileUrl})`,
              height: "76px",
              backgroundSize: "110px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
            }}
            // alt="차량이미지"
            // height={70}
          />
        </ColumnWrapper>
      </ListItemWrapper>
    );
  }
);

export default DropTarget(
  ListItemType,
  {
    hover(
      props: ImageListItemProps,
      monitor: DropTargetMonitor,
      component: CatalogListItemInstance
    ) {
      if (!component) {
        return null;
      }

      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem<CatalogListItemObject>().index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = node.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      props.moveCard(dragIndex, hoverIndex);
      monitor.getItem<CatalogListItemObject>().index = hoverIndex;
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    ListItemType,
    {
      beginDrag: (props: ImageListItemProps) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(CatalogListItem)
);

CatalogListItem.defaultProps = {};
