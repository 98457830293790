import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import EstimationNewCarCatalog from "../../models/EstimationSimulator/EstimationNewCarCatalog";
import EstimationCatalogDetail from "../../models/EstimationSimulator/EstimationCatalogDetail";
import { EstimationParam, RentFeeQueryParam } from "./types";
import RentFeeResult from "../../models/RentFeeResult";

const apiRootUrl = `${ROOT_URL}/admin/est-sim`;

/**
 * 견적 시뮬레이션 상품 리스트 조회
 */
export const fetchEstimationCatalogs = async (): Promise<
  ListItemResponse<EstimationNewCarCatalog>
> => {
  const url = `${apiRootUrl}/nc-catalogs`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(EstimationNewCarCatalog, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 렌탈료 조회후 선택 옵션값 저장
 */

export interface ExtraOptionParam {
  optClsCd: string;
  optId: string;
  optNm: string;
  optAmt: number;
}

export const saveCarOptions = async (
  rntFeeId: string,
  optionList: ExtraOptionParam[]
) => {
  const url = `${apiRootUrl}/nc-rent-fee-after`;
  await request(HttpMethod.Post, url, undefined, {
    rntFeeId,
    extraOptionList: optionList,
  });
};

/**
 * 견적 시뮬레이션 일반발주 상품 상세 조회
 */

export const fetchEstimationCatalogDetail = async (
  prodId: string
): Promise<EstimationCatalogDetail> => {
  const url = `${apiRootUrl}/nc-catalogs/${prodId}`;
  const res = await request(HttpMethod.Get, url);
  const { data } = res;
  const { response } = data;

  return plainToClass(EstimationCatalogDetail, response);
};

/**
 * 견적 시뮬레이션 특판 상품 상세 조회
 */

export const fetchEstimationStockCatalogDetail = async (
  prodId: string
): Promise<EstimationCatalogDetail> => {
  const url = `${apiRootUrl}/nc-exist-catalogs/${prodId}`;
  const res = await request(HttpMethod.Get, url);
  const { data } = res;
  const { response } = data;
  return plainToClass(EstimationCatalogDetail, response);
};

/**
 * 견적 시뮬레이션 렌탈료 조회
 */

export const fetchRentFee = async (
  params: RentFeeQueryParam
): Promise<RentFeeResult> => {
  const url = `${apiRootUrl}/nc-rent-fee`;
  const res = await request(HttpMethod.Get, url, params as Record<string, any>);
  const { data } = res;
  const { response } = data;
  return plainToClass(RentFeeResult, response);
};

/**
 * 견적 저장
 */
export const addEstimation = async (params: EstimationParam) => {
  const url = `${apiRootUrl}/nc-estimations`;
  await request(HttpMethod.Post, url, undefined, params);
};
