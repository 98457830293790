import React, { useState } from "react";
import moment from "moment";
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import strings from "./constants/strings";
import {
  ALL_DISPLAY_STATE_SWITCH,
  createEstCols,
  NC_DISPLAY_STATE_SWITCH,
  UC_DISPLAY_STATE_SWITCH,
} from "./utils/table-utils";
import ReviewDetailModal from "./pages/ReviewDetailModal";
import { createActionCellRenderers } from "../../../utils/table-utils";
import { get3MonthDateRange } from "../../../utils/date-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import {
  getPageIndex,
  refreshServerSideTable,
} from "../../../utils/ag-grid-utils";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import {
  createUserDetailRenderer,
  renderSwitchCol,
} from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { editReview, fetchReviews } from "../../../apis/reviews";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import { ReviewEditParam } from "../../../apis/reviews/types";
import { getErrorMessage } from "../../../utils/common-utils";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import Review from "../../../models/Review";
import commonStrings from "../../../constants/strings";

const ReviewManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>();
  const [logInfo, setLogInfo] = useState<string>("");

  const cols = createEstCols();

  const filterInitialValues = {
    stDt: get3MonthDateRange(),
    custClsCd: "",
    custNm: "",
    ncarClsFlag: "",
  };

  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "등록일",
    },
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const { stDt } = appliedFilterValues;
    const stDtParam = moment(stDt[0]).format("YYYYMMDD");
    const endDtParam = moment(stDt[1]).format("YYYYMMDD");

    try {
      const { items, count } = await fetchReviews(
        getPageIndex(startRow, paginationPageSize),
        paginationPageSize,
        stDtParam,
        endDtParam
      );
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
    } catch (e) {
      if (e.status !== 404) {
        setTotalCount(0);
        success({
          rowData: [],
          rowCount: 0,
        });
      }
    }
  };

  /**
   * Event Actions
   */
  const requestUpdateReviewState = async (
    cntrId: string,
    params: ReviewEditParam,
    successMessage?: string,
    gridApi?: GridApi
  ) => {
    try {
      await editReview(cntrId, params);
      alertSuccess(successMessage || "리뷰 정보가 변경되었습니다.");
      refreshServerSideTable(gridApi);
    } catch (e) {
      alertError(getErrorMessage(e));
      refreshServerSideTable(gridApi);
    }
  };

  /**
   * Event Actions
   */

  const handleReviewDisplayState = (
    checked: boolean,
    review: Review,
    api?: GridApi
  ) => {
    showConfirm(
      checked
        ? commonStrings.CONFIRM_DISPLAY_ON
        : commonStrings.CONFIRM_DISPLAY_OFF,
      checked
        ? commonStrings.CONFIRM_TITLE_REVIEW_ALL_DISPLAY_ON
        : commonStrings.CONFIRM_TITLE_REVIEW_ALL_DISPLAY_OFF,
      async () => {
        await requestUpdateReviewState(
          review.cntrId,
          {
            viewYn: checked ? "Y" : "N",
          },
          commonStrings.FEEDBACK_REVIEW_ALL_DISPLAY_STATE_CHANGE,
          api
        );
      }
    );
  };

  const handleNCDisplayState = (
    checked: boolean,
    review: Review,
    api?: GridApi
  ) => {
    showConfirm(
      checked
        ? commonStrings.CONFIRM_DISPLAY_ON
        : commonStrings.CONFIRM_DISPLAY_OFF,
      checked
        ? commonStrings.CONFIRM_TITLE_REVIEW_NC_DISPLAY_ON
        : commonStrings.CONFIRM_TITLE_REVIEW_NC_DISPLAY_OFF,
      async () => {
        await requestUpdateReviewState(
          review.cntrId,
          {
            newcarViewYn: checked ? "Y" : "N",
          },
          commonStrings.FEEDBACK_REVIEW_NC_DISPLAY_STATE_CHANGE,
          api
        );
      }
    );
  };

  const handleUCDisplayState = (
    checked: boolean,
    review: Review,
    api?: GridApi
  ) => {
    showConfirm(
      checked
        ? commonStrings.CONFIRM_DISPLAY_ON
        : commonStrings.CONFIRM_DISPLAY_OFF,
      checked
        ? commonStrings.CONFIRM_TITLE_REVIEW_UC_DISPLAY_ON
        : commonStrings.CONFIRM_TITLE_REVIEW_UC_DISPLAY_OFF,
      async () => {
        await requestUpdateReviewState(
          review.cntrId,
          {
            usdcarViewYn: checked ? "Y" : "N",
          },
          commonStrings.FEEDBACK_REVIEW_UC_DISPLAY_STATE_CHANGE,
          api
        );
      }
    );
  };

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { cntrId } = data;
    setSelectedDataId(cntrId);
    setModalVisible(true);
  };

  const handleUserDetailCellClick = (custId?: string) => {
    // TODO
    alertError("custId 필요");
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <PaginationTableLayout
        headerTitle={strings.TITLE_HEADER_REVIEW_MANAGER}
        tableHeaderTitle={strings.TABLE_HEADER_REVIEW_LIST}
        onDataLoad={onDataLoad}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        totalCount={totalCount}
        hideFilterAction
        frameworkComponents={{
          [ALL_DISPLAY_STATE_SWITCH]: renderSwitchCol(
            "viewYn",
            handleReviewDisplayState
          ),
          [NC_DISPLAY_STATE_SWITCH]: renderSwitchCol(
            "newcarViewYn",
            handleNCDisplayState
          ),
          [UC_DISPLAY_STATE_SWITCH]: renderSwitchCol(
            "usdcarViewYn",
            handleUCDisplayState
          ),
          ...createUserDetailRenderer(handleUserDetailCellClick),
          ...createActionCellRenderers(undefined, handleDetailClick, {
            hideDeleteButton: true,
          }),
        }}
        apiRef={(api) => {
          setGridApi(api);
        }}
        methodName="/admin/reviews"
        logInfo={logInfo}
      />
      <ReviewDetailModal
        cntrId={selectedDataId}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onDataChange={() => {
          refreshServerSideTable(gridApi);
        }}
      />
    </>
  );
};

ReviewManager.defaultProps = {};
export default ReviewManager;
