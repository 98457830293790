import React from "react";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { FormElementType } from "../../../../../../components/shared/data-entry/FormBuilder/types";
import strings from "../../../constants/strings";

import FormItem from "../../../../../../components/shared/data-entry/antd/FormItem";
import HtmlEditor from "../../../../../../components/shared/input/HtmlEditor";

import NoticeDetail from "../../../../../../models/NoticeDetail";
import {
  ENUM_NOTICE_CD_OPTIONS,
  UPLOAD_CODES,
} from "../../../../../../constants/enums";
import {
  getInitialDateRangeFromRaw,
  getParamFromDateRange,
} from "../../../../../../utils/form-utils";
import dompurify from "dompurify";

export const createInitialValues = () => {
  return {
    noticeFixed: false,
    mainYn: false,
    noticeClfCd: null,
    // 제목
    titl: "",
    // 내용
    contents: "",
    // 공지 기간 사용여부
    noticeTerm: null,
  };
};

export const createInitialValuesFromNotice = (notice: NoticeDetail) => {
  const {
    titl,
    noticeClfCd,
    noticeStDtm,
    noticeEndDtm,
    mainYn,
    noticeFixed,
  } = notice;

  return {
    noticeFixed: noticeFixed === "Y",
    mainYn: mainYn === "Y",
    noticeClfCd,
    // 제목
    titl,
    // 내용
    contents: dompurify.sanitize(notice.htmlContent),
    noticeTerm: getInitialDateRangeFromRaw(noticeStDtm, noticeEndDtm),
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    noticeClfCd: Yup.string()
      .required(strings.HINT_NOTICE_CATEGORY)
      .typeError(strings.HINT_NOTICE_CATEGORY),
    titl: Yup.string().required(strings.HINT_NOTICE_TITLE),
    contents: Yup.string().required(strings.HINT_NOTICE_CONTENT),
  });
};

export const createAddNoticeParam = (values: FormikValues) => {
  const {
    noticeClfCd,
    titl,
    contents,
    noticeFixed,
    noticeTerm,
    mainYn,
  } = values;

  let noticeTermYnParam = "N";
  let noticeStDtm;
  let noticeEndDtm;
  if (noticeTerm && noticeTerm.length > 0) {
    const { startDateParam, endDateParam } = getParamFromDateRange(noticeTerm);
    noticeStDtm = startDateParam;
    noticeEndDtm = endDateParam;
    noticeTermYnParam = "Y";
  }

  return {
    noticeClfCd,
    titl,
    contents,
    noticeStDtm,
    noticeEndDtm,
    noticeFixed: noticeFixed ? "Y" : "N",
    mainYn: mainYn ? "Y" : "N",
    noticeTermYn: noticeTermYnParam,
  };
};

export const createEditNoticeParam = (values: FormikValues) => {
  const {
    noticeClfCd,
    titl,
    contents,
    noticeFixed,
    noticeTerm,
    mainYn,
  } = values;

  let noticeTermYnParam = "N";
  let noticeStDtm = null;
  let noticeEndDtm = null;
  if (noticeTerm && noticeTerm.length > 0) {
    const { startDateParam, endDateParam } = getParamFromDateRange(noticeTerm);
    noticeStDtm = startDateParam;
    noticeEndDtm = endDateParam;
    noticeTermYnParam = "Y";
  }

  return {
    noticeClfCd,
    titl,
    contents,
    mainYn: mainYn ? "Y" : "N",
    noticeStDtm: noticeStDtm || null,
    noticeEndDtm: noticeEndDtm || null,
    noticeFixed: noticeFixed ? "Y" : "N",
    noticeTermYn: noticeTermYnParam,
  };
};

export const createFormInfo = () => {
  return [
    {
      // 공지 고정
      key: "noticeFixed",
      type: FormElementType.Checkbox,
      label: strings.LABEL_IS_FIXED,
      required: true,
    },
    {
      // 메인 노출
      key: "mainYn",
      type: FormElementType.Checkbox,
      label: strings.LABEL_IS_MAIN,
      required: true,
    },
    // 공지 구분
    {
      key: "noticeClfCd",
      type: FormElementType.Select,
      label: strings.LABEL_NOTICE_CATEGORY,
      options: ENUM_NOTICE_CD_OPTIONS,
      placeholder: strings.HINT_NOTICE_CATEGORY,
      required: true,
    },
    // 공지 제목
    {
      key: "titl",
      type: FormElementType.Input,
      label: strings.LABEL_NOTICE_TITLE,
      placeholder: strings.HINT_NOTICE_TITLE,
      required: true,
    },
    // 게시일
    {
      key: "noticeTerm",
      type: FormElementType.DateRangePicker,
      label: "팝업 노출기간",
    },
    // 내용
    {
      key: "contents",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue, touched, errors } = renderProps;
        const value = values.contents || "";

        let errorMessage = "";
        if (touched.contents && errors.contents) {
          errorMessage = errors.contents as string;
        }

        return (
          <FormItem
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            help={errorMessage}
            validateStatus={errorMessage && "error"}
            key="contents"
            label={strings.LABEL_NOTICE_CONTENT}
            required
            style={{
              marginBottom: "15px",
            }}
          >
            <HtmlEditor
              uploadInfo={UPLOAD_CODES.NOTICE}
              data={value}
              onChange={(data) => {
                setFieldValue("contents", data);
              }}
            />
          </FormItem>
        );
      },
    },
  ];
};
