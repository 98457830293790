import NiceInfoColor from "./NiceInfoColor";
import NiceCarInfoDetail from "./NiceCarInfoDetail";
import CossColor from "./CossColor";

export class CossOptionDetail {
  // COSS 차량옵션ID
  public cossOptId = "";

  // COSS 옵션명
  public cossOptNm = "";

  // COSS 옵션명
  public cossOptDescr = "";

  // COSS 면세가격
  public cossNoTaxAmt = 0;

  // COSS 과세가격
  public cossTaxnAmt = 0;

  // 커스텀 옵션명(Ezy Direct)
  public cossOptCustomNm = "";

  // 옵션 설명 영상(Ezy Direct)
  public cossOptDescUrl = "";

  // PDF 파일
  public optDescFileUrl = "";
}

class CossCarInfoDetail {
  public niceInfo?: NiceCarInfoDetail = new NiceCarInfoDetail();

  public niceInfoColorList?: Array<NiceInfoColor> = [];

  public niceInfoOptionList?: Array<CossOptionDetail> = [];

  public cossColorList: Array<CossColor> = [];
}

export default CossCarInfoDetail;
