import React, { useCallback, useEffect, useState } from "react";
import { FormikProps, FormikValues } from "formik";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import FormBuilder from "../../../../../components/shared/data-entry/FormBuilder";

import {
  createDiscountMasterParam,
  createFormInfo,
  createInitialValues,
  createInitialValuesFromDcMaster,
  createValidationSchema,
} from "./utils/form-utils";
import { alertError, alertSuccess } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { ModalProps } from "../../../../types";
import DiscountMaster from "../../../../../models/DiscountMaster";
import commonStrings from "../../../../../constants/strings";
import {
  addDiscountMaster,
  editDiscountMaster,
} from "../../../../../apis/dc-master";
import { HttpMethod, request } from "../../../../../network/request";
import { ROOT_URL } from "../../../../../network/api-urls";

interface Props extends ModalProps {
  discountMaster?: DiscountMaster;
}

const DiscountMasterModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, discountMaster, onDataChange } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );
  const [forms, setForms] = useState(createFormInfo());

  useEffect(() => {
    fetchDcList();
  }, []);

  let formik: FormikProps<FormikValues>;

  const fetchDcList = () => {
    request(HttpMethod.Get, `${ROOT_URL}/com/select-code-list`, {
      refCd: "A16000",
    })
      .then(({ data }) => {
        const dcList = data.response.map(({ code, cdnm }: any) => {
          return {
            label: cdnm,
            value: code,
          };
        });
        const newForms = forms.concat();
        newForms[0].options = dcList;
        setForms(newForms);
      })
      .catch(({ data }) => {
        alertError(data.message);
      });
  };

  const onModalOpen = async () => {
    if (discountMaster) {
      // Fetch data
      setInitialValues(createInitialValuesFromDcMaster(discountMaster));
      setForms(createFormInfo(true));
      fetchDcList();
    } else {
      setInitialValues(createInitialValues());
      setForms(createFormInfo(false));
      fetchDcList();
    }
  };

  /**
   * Private Functions
   */

  const requestAddDcMaster = async (values: FormikValues) => {
    setConfirmLoading(true);

    try {
      await addDiscountMaster(createDiscountMasterParam(values));
      alertSuccess(commonStrings.FEEDBACK_ADD_DC_MASTER);
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditDcMaster = async (values: FormikValues) => {
    if (discountMaster) {
      setConfirmLoading(true);
      try {
        await editDiscountMaster(
          discountMaster.dcGrdId,
          createDiscountMasterParam(values)
        );
        alertSuccess(commonStrings.FEEDBACK_EDIT_DC_MASTER);
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const getModalTitle = useCallback(() => {
    if (discountMaster) {
      return commonStrings.MODAL_TITLE_DC_MASTER_EDIT;
    }
    return commonStrings.MODAL_TITLE_DC_MASTER_ADD;
  }, [discountMaster]);

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (discountMaster) {
      await requestEditDcMaster(values);
    } else {
      await requestAddDcMaster(values);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title={getModalTitle()}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
        forms={forms}
        validationSchema={createValidationSchema()}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

DiscountMasterModal.defaultProps = {};
export default DiscountMasterModal;
