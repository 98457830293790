import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import StockInfoReport from "../../models/StockInfoReport";
import StockInfoReportDetail from "../../models/StockInfoReportDetail";
import EstimationReport from "../../models/EstimationReport";

/**
 * 재고 통계
 */
export const fetchStockInfoReport = async (
  pageIndex: number,
  pageSize: number,
  stdDt: string,
  cossModlNm?: string,
  brandNm?: string,
  carClssNm?: string,
  carGradeNm?: string,
  carNo?: string
): Promise<ListItemResponse<StockInfoReport>> => {
  const url = `${ROOT_URL}/admin/stock-info`;
  const res = await request(HttpMethod.Get, url, {
    pageIndex,
    pageSize,
    stdDt,
    cossModlNm,
    brandNm,
    carClssNm,
    carGradeNm,
    carNo,
  });

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(StockInfoReport, list as Array<JSON>),
    count: Number(totalCnt),
  };
};

/**
 * 재고 통계 상세
 */
export const fetchStockInfoReportDetail = async (
  cossModlId: string
): Promise<ListItemResponse<StockInfoReportDetail>> => {
  const url = `${ROOT_URL}/admin/stock-info/${cossModlId}`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(StockInfoReportDetail, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 견적 통계
 */
export const fetchEstimationReport = async (
  pageIndex: number,
  pageSize: number,
  stDt: string,
  endDt: string,
  ncarClsFlag?: string,
  custClsCd?: string
): Promise<ListItemResponse<EstimationReport>> => {
  const url = `${ROOT_URL}/admin/est-info`;
  const res = await request(HttpMethod.Get, url, {
    pageIndex,
    pageSize,
    stDt,
    endDt,
    ncarClsFlag,
    custClsCd,
  });

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(EstimationReport, list as Array<JSON>),
    count: Number(totalCnt),
  };
};
