export class UsedCarCatalog {
  // 상품번호
  public prodId = "";

  // 등록일시
  public regDtm = "";

  // coss id,model id
  public cossId = "";

  // 차량번호
  public carNo = "";

  // 제조사
  public brandNm = "";

  // 모델
  public repCarClassNm = "";

  // 세부모델
  public carClassNm = "";

  // 트림
  public carGradeNm = "";

  // 연형
  public yearType = "";

  // 주행거리
  public lastDtc = "";

  // 대표 연료
  public repFuelNm = "";

  // 장착 변속기
  public istdTrans = "";

  // 차량정보
  public carNmInfo = "";

  // 노츌상태
  public useYn = "";

  public fixCont = "";
}
