import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { Spin } from "antd";
import FullCalendar, { EventInput, EventClickArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { CalendarWrapper } from "./style";
import { alertError } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import { fetchCarManagerDeliverySchedules } from "../../../apis/car-managers";
import CarManagerDeliverySchedule from "../../../models/CarManagerDeliverySchedule";
import ScheduleDetailModal from "./pages/ScheduleDetailModal";
import {
  OPTIONS_PROD_TYPE,
  PROD_TYPE_COLOR_TRANSLATIONS,
  PROD_TYPE_TRANSLATIONS,
  SEARCH_OPTION_ALL,
} from "../../../constants/enums";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";

const CarManagerDeliveryScheduler: React.FC = () => {
  const [events, setEvents] = useState<Array<EventInput>>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<
    CarManagerDeliverySchedule
  >();
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schdStartDate, setSchdStartDate] = useState("");
  const [schdEndDate, setSchdEndDate] = useState("");

  const filterInitialValues = {
    carmanNm: "",
    ncarClsFlag: "",
  };

  const filterForms = [
    {
      key: "carmanNm",
      type: FilterElementType.Input,
      label: "카매니저명",
      placeholder: "카매니저명을 입력해주세요",
    },
    {
      key: "ncarClsFlag",
      type: FilterElementType.Select,
      options: [SEARCH_OPTION_ALL, ...OPTIONS_PROD_TYPE],
      label: "상품유형",
    },
  ];

  const [filterValues, setFilterValues] = useState<any>({});

  useEffect(() => {
    (async function fetchData() {
      if (schdStartDate && schdEndDate)
        await requestFetchSchedules(schdStartDate, schdEndDate);
    })();
  }, [schdStartDate, schdEndDate]);

  /**
   * Private Functions
   */

  const mapEvents = (
    rawData: Array<CarManagerDeliverySchedule>
  ): Array<EventInput> => {
    return rawData.map((data, index) => {
      const arvDate = moment(data.arvDtm, "YYYYMMDD").toDate();
      return {
        id: `${index}`,
        start: arvDate,
        extendedProps: data,
      };
    });
  };

  const requestFetchSchedules = async (
    startDt: string,
    endDt: string,
    carmanNm?: string,
    ncarClsFlag?: string
  ) => {
    setLoading(true);
    try {
      const { items } = await fetchCarManagerDeliverySchedules(
        startDt,
        endDt,
        carmanNm,
        ncarClsFlag
      );
      setEvents(mapEvents(items));
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  /**
   * Event Actions
   */
  const handleEventClick = (clickInfo: EventClickArg) => {
    const { event } = clickInfo;
    setSelectedSchedule(event.extendedProps as CarManagerDeliverySchedule);
    setDetailModalVisible(true);
  };

  /**
   * Render Helpers
   */

  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <ContentLayout
      headerTitle="인도스케줄 관리"
      filterable
      filterInitialValues={filterInitialValues}
      filterForms={filterForms}
      filterValues={filterValues}
      onSearchSubmit={async (filterValues: any) => {
        setFilterValues(filterValues);
        const { carmanNm, ncarClsFlag } = filterValues;
        await requestFetchSchedules(
          schdStartDate,
          schdEndDate,
          carmanNm,
          ncarClsFlag
        );
      }}
    >
      <CalendarWrapper>
        <Spin spinning={loading} indicator={loadingIcon}>
          <FullCalendar
            eventContent={(info) => {
              const { event } = info;
              // eslint-disable-next-line @typescript-eslint/naming-convention
              const { _def } = event;
              const { extendedProps } = _def;

              const schedule = extendedProps as CarManagerDeliverySchedule;
              const {
                custNm,
                userNm,
                modlNm,
                ncarClsFlag,
                carNo,
                consiStsNm,
              } = schedule;
              const color = PROD_TYPE_COLOR_TRANSLATIONS[ncarClsFlag];

              return (
                <div
                  style={{
                    color: "black",
                    textAlign: "left",
                    overflow: "hidden",
                    width: "100%",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "10px",
                        background: color,
                        display: "inline-block",
                        marginRight: "4px",
                      }}
                    />
                    <span
                      style={{
                        color,
                      }}
                    >{`${custNm}님`}</span>
                    {` - ${userNm}`}
                  </div>

                  <div
                    style={{
                      color,
                    }}
                  >
                    {`${modlNm} (${PROD_TYPE_TRANSLATIONS[ncarClsFlag]})`}
                  </div>
                  {carNo && (
                    <div
                      style={{
                        color,
                      }}
                    >
                      {`${carNo}`}
                    </div>
                  )}
                  {consiStsNm && (
                    <div
                      style={{
                        color,
                      }}
                    >
                      {`${consiStsNm}`}
                    </div>
                  )}
                </div>
              );
            }}
            height="auto"
            displayEventTime={false}
            events={(fetchInfo, successCallback) => {
              const { start, end } = fetchInfo;
              const startParam = moment(start).format("YYYYMMDD");
              const endParam = moment(end).format("YYYYMMDD");
              setSchdStartDate(startParam);
              setSchdEndDate(endParam);
              successCallback(events);
            }}
            contentHeight={600}
            eventClick={handleEventClick}
            plugins={[dayGridPlugin]}
            locale="ko"
            headerToolbar={{
              right: "prev today next",
              center: "title",
              left: "dayGridMonth,dayGridWeek",
            }}
            buttonText={{
              today: "오늘날짜",
              week: "주간",
              month: "월간",
            }}
          />
        </Spin>
      </CalendarWrapper>

      <ScheduleDetailModal
        scheduleInfo={selectedSchedule}
        visible={detailModalVisible}
        onDataChange={async () => {
          await requestFetchSchedules(schdStartDate, schdEndDate);
        }}
        onCancel={() => {
          setDetailModalVisible(false);
        }}
      />
    </ContentLayout>
  );
};

CarManagerDeliveryScheduler.defaultProps = {};
export default CarManagerDeliveryScheduler;
