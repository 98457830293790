import { plainToClass } from "class-transformer";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import User from "../../models/User";
import { ENUM_AIM_CD_ADMIN } from "../../constants/enums";

interface LoginResponse {
  user: User;
}

const apiRootUrl = `${ROOT_URL}/admin/session`;

/**
 * 로그인
 */
export const login = async (
  id: string,
  password: string
): Promise<LoginResponse> => {
  const body = {
    loginId: id,
    passwd: password,
  };
  const res = await request(HttpMethod.Post, apiRootUrl, undefined, body);
  const { data } = res;
  const { response } = data;
  const user = plainToClass(User, response);
  return {
    user,
  };
};

/**
 * 로그아웃
 */
export const logout = async () => {
  await request(HttpMethod.Delete, apiRootUrl);
};

/**
 * 로그인 유저정보 조회
 */
export const fetchUserInfo = async (): Promise<LoginResponse> => {
  const res = await request(HttpMethod.Get, apiRootUrl);
  const { data } = res;
  const { response } = data;

  const user = plainToClass(User, {
    ...response,
    menuList: response.sessionDTO.menuList,
  });
  return {
    user,
  };
};

/**
 * 2차인증 인증번호 발송 요청
 */
export const sendPhoneAuthNo = async (hpNo: string) => {
  const url = `${ROOT_URL}/admin/phone-auth`;

  const res = await request(HttpMethod.Post, url, undefined, {
    hpNo,
    aimCd: ENUM_AIM_CD_ADMIN,
  });
  return res.data.response;
};

/**
 * 인증번호 유효성 검사
 */
export const requestValidatePhoneAuthNo = async (
  hpNo: string,
  authNum: string
) => {
  const url = `${ROOT_URL}/admin/phone-auth`;
  const res = await request(HttpMethod.Put, url, undefined, {
    hpNo,
    authNum,
  });
  return res.data.response;
};

/**
 * 2차인증 후 로그인 처리
 */
export const loginAfterPhoneAuth = async (
  hpNo: string,
  authNum: string,
  loginId: string,
  passwd: string
): Promise<User> => {
  const { authKey } = await requestValidatePhoneAuthNo(hpNo, authNum);
  const url = `${ROOT_URL}/admin/session-auth`;
  await request(HttpMethod.Post, url, undefined, {
    loginId,
    passwd,
    hpNo,
    authKey,
  });
  const { user } = await fetchUserInfo();
  return user;
};
