import React from "react";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import errorMessages from "../../../../../../../../constants/errors";
import FormItem from "../../../../../../../../components/shared/data-entry/antd/FormItem";
import Input from "../../../../../../../../components/shared/data-entry/antd/Input";
import TextArea from "../../../../../../../../components/shared/data-entry/antd/TextArea";
import HtmlEditor from "../../../../../../../../components/shared/input/HtmlEditor";
import { FormElementType } from "../../../../../../../../components/shared/data-entry/FormBuilder/types";
import AucCatalogDetail from "../../../../../../../../models/AucCatalogDetail"

export const createInitialValues = (param: AucCatalogDetail) => {
  return {
    carNo: 				  param.carNo,
		brandNm:        param.brandNm, 
		carClassNm:     param.carClassNm,
		carGradeNm:     param.carGradeNm, 
		yearType:       param.yearType,
    lastDtc:        param.lastDtc,
		fuelNm:         param.fuelNm,
		istdTrans:      param.istdTrans,
		engdisp:        param.engdisp,
		seaterCapa:     param.seaterCapa,
    innrCarColorNm: param.innrCarColorNm,
		xtnlCarColorNm: param.xtnlCarColorNm, 
    carGradePrice:  param.carGradePrice,
    carRegDtm:      param.carRegDtm,
		mainOptNms:     param.mainOptNms,
		mdComment:      param.mdComment || "",
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    mdComment: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });
};

export const createFormInfo = () => {
  return [
    {
      key: "carNo",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.carNo;
        return (
          <FormItem
            key="carNo"
            label="차량번호"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "51px",
                width: "200px",
              }}
              value={value}
              //onChange={(data) => {setFieldValue("carNo", data.target.value); }}
            />-차량번호는 수정할 수 없습니다.
          </FormItem>
        );
      },
    },
    {
      key: "carClassNm",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.carClassNm;
        return (
          <FormItem
            key="carClassNm"
            label="차량명"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "65px",
                width: "400px"
              }}
              value={value}
              onChange={(data) => {                
                setFieldValue("carClassNm", data.target.value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "yearType",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.yearType;
        return (
          <FormItem
            key="yearType"
            label="연식"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "77px",
                width: "200px"
              }}
              value={value}
              onChange={(data) => {
                setFieldValue("yearType", data.target.value);
              }}
            />예시) 2022
          </FormItem>
        );
      },
    },
    {
      key: "carRegDtm",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.carRegDtm;
        return (
          <FormItem
            key="carRegDtm"
            label="등록일자"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "51px",
                width: "200px"
              }}
              value={value}
              onChange={(data) => {
                setFieldValue("carRegDtm", data.target.value);
              }}
            />예시) 20221117
          </FormItem>
        );
      },
    },
    {
      key: "lastDtc",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.lastDtc;
        return (
          <FormItem
            key="lastDtc"
            label="주행거리"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "51px",
                width: "200px"
              }}
              value={value}
              onChange={(data) => {
                setFieldValue("lastDtc", data.target.value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "fuelNm",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.fuelNm;
        return (
          <FormItem
            key="fuelNm"
            label="연료"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "77px",
                width: "200px"
              }}
              value={value}
              onChange={(data) => {
                setFieldValue("fuelNm", data.target.value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "engdisp",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.engdisp;
        return (
          <FormItem
            key="engdisp"
            label="배기량"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "65px",
                width: "200px"
              }}
              value={value}
              onChange={(data) => {
                setFieldValue("engdisp", data.target.value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "seaterCapa",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.seaterCapa;
        return (
          <FormItem
            key="seaterCapa"
            label="인승"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "77px",
                width: "200px"
              }}
              value={value}
              onChange={(data) => {
                setFieldValue("seaterCapa", data.target.value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "istdTrans",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.istdTrans;
        return (
          <FormItem
            key="istdTrans"
            label="변속기"
            required
            style={{
              marginBottom: "10px",              
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "65px",
                width: "200px"
              }}
              value={value}
              onChange={(data) => {
                setFieldValue("istdTrans", data.target.value);
              }}
            />
          </FormItem>
        );
      },
    },    
    {
      key: "innrCarColorNm",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.innrCarColorNm;
        return (
          <FormItem
            key="innrCarColorNm"
            label="색상(내)"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "55px",
                width: "400px"
              }}
              value={value}
              onChange={(data) => {
                setFieldValue("innrCarColorNm", data.target.value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "xtnlCarColorNm",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.xtnlCarColorNm;
        return (
          <FormItem
            key="xtnlCarColorNm"
            label="색상(외)"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              style={{
                marginLeft: "55px",
                width: "400px"
              }}
              value={value}
              onChange={(data) => {
                setFieldValue("xtnlCarColorNm", data.target.value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "carGradePrice",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.carGradePrice;
        return (
          <FormItem
            key="carGradePrice"
            label="제조사 신차가격"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              value={value}
              style={{
                marginLeft: "10px",
                width: "400px"
              }}
              onChange={(data) => {
                setFieldValue("carGradePrice", data.target.value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "mainOptNms",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.mainOptNms;
        return (
          <FormItem
            key="mainOptNms"
            label="옵션"
            required
            style={{
              marginBottom: "10px",
            }}
          >
            <TextArea              
              value={value}
              style={{
                marginLeft: "77px",
                width: "90%"
              }}
              onChange={(data) => {
                setFieldValue("mainOptNms", data.target.value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "mdComment",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue, touched, errors } = renderProps;
        const value = values.mdComment || "";

        let errorMessage = "";
        if (touched.mdComment && errors.mdComment) {
          errorMessage = errors.mdComment as string;
        }

        return (
          <FormItem
            help={errorMessage}
            validateStatus={errorMessage && "error"}
            key="mdComment"
            label="차량소개"
            required
            style={{
              marginBottom: "15px",
            }}
          >
            <HtmlEditor
              // uploadInfo={UPLOAD_CODES.EVENT}
              data={value}
              onChange={(data) => {
                setFieldValue("mdComment", data);
              }}
            />
          </FormItem>
        );
      },
    },
  ];
};
