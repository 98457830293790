import * as Parallel from "async-parallel";
import { HttpMethod, request } from "../../network/request";
import {
  ExhibitionCatalog,
  ExhibitionMaster,
  ExhibitionResponse,
  ExhibitionEditParams,
  AddExhibitionParams,
  RequestUpdateExCatalogSeqParams,
  LinkExhibitionCatalogParams,
  ExhibitionShorts,
  ExhibitionEtcVideo,
  EditExhibitionShorts,
  EditExhibitionEtcVideo,
} from "./types";
import { ROOT_URL } from "../../network/api-urls";

/**
 * 기획전 등록
 * @param params
 */
export const addExhibition = async (params: AddExhibitionParams) => {
  await request(HttpMethod.Put, `${ROOT_URL}/admin/ex`, undefined, params);
};

/**
 * 기획전 리스트 조회
 */
export const fetchExhibitions = async (): Promise<
  ExhibitionResponse<ExhibitionMaster>
> => {
  const res = await request(HttpMethod.Get, `${ROOT_URL}/admin/ex`);
  return res.data.response;
};

/**
 * 기획전 상세 조회 (실제 API는 없지만 데이터 갱신용으로 정의)
 */
export const fetchExhibitionDetail = async (
  mstSeq: string
): Promise<ExhibitionMaster> => {
  const res = await fetchExhibitions();
  const { list } = res;
  const filtered = list.filter(({ exhMstSeq }) => {
    return exhMstSeq === mstSeq;
  });
  return filtered[0];
};

/**
 * 기획전 상세 조회 추가정보 포함 (타임딜, 전기차)
 */
export const fetchExhibitionDetailWithAddInfo = async (
  extMstSeq: string,
  exhUseYn: "Y" | "N",
  exhGbnCd: string,
  prodId: string
): Promise<ExhibitionMaster> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/ex-catalogs-detail/${extMstSeq}/${exhUseYn}/${exhGbnCd}/${prodId}`
  );

  return res.data.response;
};

/**
 * 기획전 마스터 수정
 * @param params
 */
export const editExhibition = async (params: ExhibitionEditParams) => {
  await request(
    HttpMethod.Put,
    `${ROOT_URL}/admin/ex-mst-update`,
    undefined,
    params
  );
};

/**
 * 기획전 상세 차량 조회
 */
export const fetchExhibitionCatalogs = async (
  exhMstSeq: string,
  exhGbnCd: string,
  exhUseYn = "Y"
): Promise<ExhibitionResponse<ExhibitionCatalog>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/ex-catalogs/${exhMstSeq}/${exhUseYn}/${exhGbnCd}`
  );
  return res.data.response;
};

/**
 * 기획전 차량 연결
 */
export const linkExhibitionCatalog = async (
  linkInfo: LinkExhibitionCatalogParams
) => {
  await request(
    HttpMethod.Put,
    `${ROOT_URL}/admin/ex-prod-useyn`,
    undefined,
    linkInfo
  );
};

/**
 * 기획전 차량 벌크 연결
 */
export const linkExhibitionCatalogs = async (
  linkInfos: LinkExhibitionCatalogParams[]
) => {
  await Parallel.each(linkInfos, async (linkInfo) => {
    await linkExhibitionCatalog(linkInfo);
  });
};

/**
 * 기획전 상품 게시순번 저장
 * @param list
 */
export const editExhibitionCatalogSeq = async (
  list: Array<RequestUpdateExCatalogSeqParams>
) => {
  await request(
    HttpMethod.Put,
    `${ROOT_URL}/admin/ex-catalogs-seq`,
    undefined,
    list
  );
};

/**
 * 기획전 쇼츠 영상 등록
 */
interface ShortsInfo {
  shortsSeq: string;
  exhMstSeq: string;
  exhGbnCd: string;
  title: string;
  explain: string;
  videoLink: string;
  videoRate: string;
  thumbnail: string;
  useYn: string;
}

export const addExhibitionCatalogShorts = async (shorts: ShortsInfo) => {
  await request(
    HttpMethod.Post,
    `${ROOT_URL}/admin/ex-catalogs-shorts`,
    undefined,
    shorts
  );
};

/**
 * 기획전 상세 내용 카드 정보 수정 (전기차, 타임딜)
 */
export interface ExhibitionElectCarAddDetail {
  // 주행가능거리
  runPossibleKm: string;
  // 전비
  elecEfficiency: string;
  // 배터리용량
  batteryCapacity: string;
}
export interface ExhibitionTimedealAddDetail {
  // 할인 타이틀
  dcTitle: string;
  // 할인 내역
  dcInfo: string;
  // 설명 타이틀
  explainTitle: string;
  // 설명
  explain: string;
}
export interface ExhibitionAddDetail {
  // 기획전 상세 ID
  exhDtlSeq: string;
  // 기획전 마스터 ID
  exhMstSeq: string;
  // 상품ID
  prodId: string;
  // 기획전 구분 코드
  exhGbnCd: string;
  // 사용 여부
  useYn: string;
}

export type RequestUpdateExhibitionAddDetail = ExhibitionAddDetail &
  (ExhibitionTimedealAddDetail | ExhibitionElectCarAddDetail);

export const editExhibitionAddDetail = async (
  params: RequestUpdateExhibitionAddDetail
) => {
  await request(
    HttpMethod.Put,
    `${ROOT_URL}/admin/ex-catalogs-detail-add`,
    undefined,
    params
  );
};

/**
 * 타임딜 기획전 이벤트 진행 시간 수정
 */
interface RequestUpdateTimedeal {
  exhMstSeq: string;
  // 타임딜 시작일
  timedealStDt: string;
  // 타임딜 종료일
  timedealEndDt: string;
}

export const editTimeDealTime = async (params: RequestUpdateTimedeal) => {
  await request(
    HttpMethod.Put,
    `${ROOT_URL}/admin/ex-catalogs-timedeal/${params.exhMstSeq}`,
    undefined,
    {
      timedealStDt: params.timedealStDt,
      timedealEndDt: params.timedealEndDt,
    }
  );
};

/**
 * 기획전 쇼츠, etc 리스트 조회
 */
export const fetchExhibitionVideos = async (
  exhMstSeq: string,
  exhGbnCd: string
): Promise<{
  shorts: ExhibitionResponse<ExhibitionShorts>;
  etcVideos: ExhibitionResponse<ExhibitionEtcVideo>;
}> => {
  const shortsRes = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/ex-catalogs-shorts/${exhMstSeq}/${exhGbnCd}`
  );
  const etcVideoRes = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/ex-catalogs-video/${exhMstSeq}/${exhGbnCd}`
  );
  return {
    shorts: shortsRes.data.response,
    etcVideos: etcVideoRes.data.response,
  };
};

/**
 * 기획전 쇼츠 수정
 * @param params
 */
export const editExhibitionShorts = async (
  params: EditExhibitionShorts,
  seq?: string,
  useYn?: string
) => {
  await request(
    HttpMethod.Put,
    `${ROOT_URL}/admin/ex-catalogs-shorts${seq ? `/${seq}` : ""}${
      seq && useYn ? `/${useYn}` : ""
    }`,
    undefined,
    params
  );
};

/**
 * 기획전 쇼츠 삭제
 * @param params
 */
export const deleteExhibitionShorts = async (seq: string) => {
  await request(
    HttpMethod.Delete,
    `${ROOT_URL}/admin/ex-catalogs-shorts/${seq}`
  );
};

/**
 * 기획전 기타 동영상 수정
 * @param params
 */
export const editExhibitionEtcVideo = async (
  params: EditExhibitionEtcVideo,
  seq?: string,
  useYn?: string
) => {
  await request(
    HttpMethod.Put,
    `${ROOT_URL}/admin/ex-catalogs-video${seq ? `/${seq}` : ""}${
      seq && useYn ? `/${useYn}` : ""
    }`,
    undefined,
    params
  );
};

/**
 * 기획전 기타 동영상 삭제
 * @param params
 */
export const deleteExhibitionEtcVideo = async (seq: string) => {
  await request(
    HttpMethod.Delete,
    `${ROOT_URL}/admin/ex-catalogs-video/${seq}`
  );
};

/**
 * 기획전 상품 게시순번 조회
 * @param list
 */
export const fetchExhibitionCatalog = async (
  extMstSeq: string
): Promise<ExhibitionResponse<ExhibitionCatalog>> => {
  const res = await request(
    HttpMethod.Get,
    `${ROOT_URL}/admin/ex-catalogs-seq/${extMstSeq}`
  );

  return res.data.response;
};
