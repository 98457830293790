import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import { CW_LARGE, CW_SMALL } from "../../../../../../utils/table-utils";

export const createCarManagerSurveyReportDetailCols = (): TableColumn[] => {
  return [
    // 이름
    createTableCol("custNm", "고객명", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 친절도 별점
    createTableCol("point03", "친절도 별점", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 전문성 별점
    createTableCol("point04", "전문성 별점", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 종합 별점
    createTableCol("point05", "종합 별점", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 고객의견
    createTableCol("srveReplyItem", "고객의견", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
  ];
};

export const createCustomerSurveyReportCols = () => {};
