import { plainToClass } from "class-transformer";
import * as Parallel from "async-parallel";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import NewCarCatalog from "../../models/NewCarCatalog";
import NewCarCatalogDetail from "../../models/NewCarCatalogDetail";
// defines
import { NcRentFeeSetResVO, RentFeeSaveVO } from "../../models/NcRentFeeSet";
import { OincRtSet } from "../../models/OincRtSet";

const apiRootUrl = `${ROOT_URL}/admin/nc-catalogs`;

/**
 * 단산 차량 정보 수정
 */
export const updateStopProductionInfo = async (
  modeGrdId: string,
  dnsnYn: string,
  dnsnRsn?: string
) => {
  const url = `${ROOT_URL}/admin/dansan-info/${modeGrdId}`;
  await request(HttpMethod.Put, url, undefined, {
    dnsnYn,
    dnsnRsn,
  });
};

/**
 * 신차장기 상품 리스트 조회
 */
export const fetchNCCatalogs = async (): Promise<
  ListItemResponse<NewCarCatalog>
> => {
  const res = await request(HttpMethod.Get, apiRootUrl);

  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(NewCarCatalog, list as Array<JSON>),
    count: listCnt,
  };
};

/**
 * 신차장기 상세 조회
 */
export const fetchNCCatalogDetail = async (
  prodId: string
): Promise<NewCarCatalogDetail> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/${prodId}`);
  const { data } = res;
  const { response } = data;
  return plainToClass(NewCarCatalogDetail, response);
};

/**
 * 신차장기 노출 상태 변경
 */
export const updateNCCatalogDisplayState = async (
  prodId: string,
  useYn: boolean
) => {
  await request(HttpMethod.Put, ` ${apiRootUrl}/${prodId}`, undefined, {
    useYn: useYn ? "Y" : "N",
  });
};

interface CatalogOrderInfo {
  prodId: string;
  viewSeq: string;
}

/**
 * 신차장기 게시 순번 변경
 */
export const updateCatalogOrder = async (params: CatalogOrderInfo[]) => {
  await request(HttpMethod.Put, ` ${apiRootUrl}-seq`, undefined, params);
};

/**
 * 상품 태그 연결
 */
export const linkTag = async (prodId: string, tagId: string) => {
  const url = `${ROOT_URL}/admin/nc-catalogs-tag`;
  await request(HttpMethod.Post, url, undefined, {
    prodId,
    tagId,
    useYn: "Y",
  });
};

/**
 * 상품 태그 연결해제
 */
export const unlinkTag = async (tagSeq: string) => {
  const url = `${ROOT_URL}/admin/nc-catalogs-tag/${tagSeq}`;
  await request(HttpMethod.Put, url, undefined, {
    useYn: "N",
  });
};

/**
 * 모델 태그 연결
 */
export const linkTagToModl = async (modeGrdId: string, tagId: string) => {
  const url = `${ROOT_URL}/admin/nc-catalogs-tag-grd`;
  await request(HttpMethod.Post, url, undefined, {
    modeGrdId,
    tagId,
    useYn: "Y",
  });
};

/**
 * 모델 태그 연결해제
 */
export const unlinkTagToModl = async (tagSeq: string) => {
  const url = `${ROOT_URL}/admin/nc-catalogs-tag-grd/${tagSeq}`;
  await request(HttpMethod.Put, url, undefined, {
    useYn: "N",
  });
};

/**
 * 상품 태그 벌크 연결
 */
export const linkTags = async (prodId: string, tagIds: string[]) => {
  await Parallel.each(tagIds, async (tagId) => {
    await linkTag(prodId, tagId);
  });
};

/**
 * 상품 태그 벌크 연결해제
 */
export const unlinkTags = async (tagSeqs: string[]) => {
  await Parallel.each(tagSeqs, async (tagSeq) => {
    await unlinkTag(tagSeq);
  });
};

/**
 * 모델 태그 벌크 연결
 */
export const linkTagsToModl = async (modeGrdId: string, tagIds: string[]) => {
  await Parallel.each(tagIds, async (tagId) => {
    await linkTagToModl(modeGrdId, tagId);
  });
};

/**
 * 모델 태그 벌크 연결해제
 */
export const unlinkTagsToModl = async (tagSeqs: string[]) => {
  await Parallel.each(tagSeqs, async (tagSeq) => {
    await unlinkTagToModl(tagSeq);
  });
};

/**
 * 기획전 태그 연결
 */
export const linkExhibitionTag = async (
  prodId: string,
  tagId: string,
  exhGbnCd: string
) => {
  const url = `${ROOT_URL}/admin/ex-catalogs-tag`;
  await request(HttpMethod.Put, url, undefined, {
    prodId,
    tagId,
    exhGbnCd,
    useYn: "Y",
  });
};

/**
 * 기획전 태그 연결해제
 */
export const unlinkExhibitionTag = async (tagSeq: string) => {
  const url = `${ROOT_URL}/admin/ex-catalogs-tag/${tagSeq}`;
  await request(HttpMethod.Put, url, undefined, {
    useYn: "N",
  });
};

/**
 * 기획전 태그 벌크 연결
 */
export const linkExhibitionTags = async (
  prodId: string,
  tagIds: string[],
  exhGbnCd: string
) => {
  await Parallel.each(tagIds, async (tagId) => {
    await linkExhibitionTag(prodId, tagId, exhGbnCd);
  });
};

/**
 * 기획전 태그 벌크 연결해제
 */
export const unlinkExhibitionTags = async (tagSeqs: string[]) => {
  await Parallel.each(tagSeqs, async (tagSeq) => {
    await unlinkExhibitionTag(tagSeq);
  });
};

/**
 * 상품 비디오 등록
 */
export const createNcVideo = async (body: {
  prodId: string;
  videoRate: string;
  videoBtn: string;
  videoUrl: string;
  useYn: string;
}) => {
  const url = `${ROOT_URL}/admin/nc-catalogs-video`;
  await request(HttpMethod.Post, url, undefined, body);
};

/**
 * 상품 비디오 수정
 */
export const updateNcVideo = async (
  seq: string,
  body: {
    prodId: string;
    videoRate: string;
    videoBtn: string;
    videoUrl: string;
    useYn: string;
  }
) => {
  const url = `${ROOT_URL}/admin/nc-catalogs-video/${seq}`;
  await request(HttpMethod.Put, url, undefined, body);
};

// ! 신차 12개월 상품 apis

/**
 * 신차 1년상품 노출여부 수정(PUT: ~​/admin​/trim-view-yn)
 * 
 * @param useYn 
 * @param prodId 
 * @param srSeq 
 * @param modeGrdId 
 */
export const updateNCModlDisplayState = async (
  useYn: string,
  prodId: string,
  srSeq: string,
  modeGrdId: string,
) => {
  await request(HttpMethod.Put, `${ROOT_URL}/admin/trim-view-yn`, undefined, { prodId, srUseYn: useYn, srSeq, modeGrdId });
};

/**
 * 신차 1년 상품 렌탈료 조회(GET: ~​/admin​/sr-rent-fee​/{prodId})
 * 
 * @param prodId 
 * @returns 
 */
export const fetchSrRentFeeDetail = async (prodId: string): Promise<NcRentFeeSetResVO> => {
  const res = await request(HttpMethod.Get, `${ROOT_URL}/admin/sr-rent-fee`, { prodId });
  const { response } = res?.data;
  return plainToClass(NcRentFeeSetResVO, response);
};

/**
 * 신차 1년 상품 렌탈료 수정(PUT: ~​/admin​/sr-rent-fee-save)
 * 
 * @param saveList 
 */
export const updateSrRentFeeState = async (
  saveList: RentFeeSaveVO[]
) => {
  await request(HttpMethod.Put, ` ${ROOT_URL}/admin/sr-rent-fee-save`, undefined, saveList);
};

/**
 * 조정구분코드 조회(GET: ~​/admin​/prod-adj-cls-cd)
 * 
 * @param prodId 
 * @returns 
 */
export const fetchOincRtInfo = async (prodId: string): Promise<OincRtSet> => {
  const res = await request(HttpMethod.Get, `${ROOT_URL}/admin/prod-adj-cls-cd`, { prodId , prodAdjClsCd: "A67001", prodAdjTypCd: "A40001" });
  const { response } = res?.data;
  return plainToClass(OincRtSet, response);
};

/**
 * 조정구분코드 수정(PUT: ~​/admin​/prod-adj-cls-cd)
 * 
 * @param oincRtInfo 
 */
export const updateOincRtState = async (
  oincRtInfo: OincRtSet
) => {
  await request(HttpMethod.Put, ` ${ROOT_URL}/admin/prod-adj-cls-cd`, undefined, oincRtInfo);
};
