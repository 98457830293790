import TableColumn, {
  createTableCol,
  FilterType,
} from "@components/shared/data-display/Tables/model/TableColumn";
import { CW_SMALL, CW_LONG_TEXT, CW_540 } from "@utils/table-utils";
import { ICellRendererParams } from "ag-grid-community";

export const SELECT_USER_BUTTON = "selectUserButton";

export type ActionCellClickListener = (
  renderParams: ICellRendererParams,
  data: any
) => void;

/**
 * 사용자 조회 컬럼 설정
 * 
 * @returns 
 */
export const createUserInfoListCols = (): Array<TableColumn> => {
  return [
    // 선택
    createTableCol("content", "선택", undefined, {
      cellRenderer: SELECT_USER_BUTTON,
      width: CW_SMALL,
      // pinned: "right",
    }),
    // 회원번호
    createTableCol("custId", "회원번호", FilterType.Text, {
      hide: true,
    }),
    // 회원구분
    // createTableCol("custCls", "회원구분", FilterType.Text, {
    //   width: CW_MEDIUM,
    // }),
    // 회원명
    createTableCol("custNm", "회원명", FilterType.Text, {
      width: CW_LONG_TEXT,
    }),
    // 휴대폰번호
    createTableCol("hpNo", "휴대폰번호", FilterType.Text, {
      width: CW_540,
    }),
    // 사업자구분
    // createTableCol("busrClsNm", "사업자구분", FilterType.Text, {
    //   width: CW_MEDIUM,
    // }),
  ];
};