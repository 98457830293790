import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import strings from "./constants/strings";
import { createDefaultRentOptionInfoCols } from "./utils/table-utils";
import { createActionCellRenderers } from "../../../utils/table-utils";
import TableLayout from "../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import { alertError } from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import DefaultRentOptionInfoModal from "./pages/DefaultRentOptionInfoModal";
import DefaultRentOptionInfo from "../../../models/DefaultRentOptionInfo";
import { fetchDefaultRentOptionInfos } from "../../../apis/nc-atmsts";

const DefaultRentOptionInfoManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [modalVisible, setModalVisible] = useState(false);
  const [defaultOptionInfo, setData] = useState<DefaultRentOptionInfo>();
  const cols = createDefaultRentOptionInfoCols();
  const [rows, setRows] = useState<Array<DefaultRentOptionInfo>>();
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    (async function fetchData() {
      await requestFetchDefaultOptionList();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestFetchDefaultOptionList = async () => {
    try {
      const defaultRentOptions = await fetchDefaultRentOptionInfos();
      const { items, count } = defaultRentOptions;
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      if (e.status !== 404) {
        alertError(getErrorMessage(e));
      }
      setRows([]);
    }
  };

  const createActions = () => {
    return [
      {
        title: strings.TITLE_ACTION_ADD,
        onClick: handleAddGradeRateClick,
      },
    ];
  };

  /**
   * Event Actions
   */

  const handleAddGradeRateClick = () => {
    setData(undefined);
    setModalVisible(true);
  };

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    setData(data as DefaultRentOptionInfo);
    setModalVisible(true);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout
        headerTitle={strings.TITLE_HEADER_DEFAULT_OPTION_LIST_MANAGER}
      >
        <TableLayout
          gridApi={gridApi}
          rightActions={createActions()}
          title={strings.TABLE_HEADER_DEFAULT_OPTION_LIST}
          totalCount={totalCount}
          methodName="/admin/nc-atmsts"
        >
          <ClientRowTable
            rowData={rows}
            cols={cols}
            frameworkComponents={createActionCellRenderers(
              undefined,
              handleEditClick,
              {
                hideDeleteButton: true,
              }
            )}
            apiRef={(api) => {
              setGridApi(api);
            }}
          />
        </TableLayout>
      </ContentLayout>
      <DefaultRentOptionInfoModal
        defaultOptionInfo={defaultOptionInfo}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onDataChange={async () => {
          await requestFetchDefaultOptionList();
        }}
      />
    </>
  );
};

DefaultRentOptionInfoManager.defaultProps = {};
export default DefaultRentOptionInfoManager;
