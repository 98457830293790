import { FunctionComponent } from "react";
import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import { formatDateString } from "../../../../utils/date-utils";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";

export const createSMSTemplateCols = (): Array<TableColumn> => {
  return [
    // 차량구분
    createServerTableCol("srvcNm", "차량구분", {
      width: CW_MEDIUM,
    }),
    // 판매유형
    createServerTableCol("ncarClsFlagNm", "판매유형", {
      width: CW_LARGE,
    }),
    // 제조사
    createServerTableCol("repMakerNm", "제조사", {
      width: CW_LARGE,
    }),
    // 차명
    createServerTableCol("cartypeNm", "차명", {
      width: CW_LARGE,
    }),
    // 모델명
    createServerTableCol("modlNm", "모델명", {
      width: CW_LARGE,
    }),
    // NICE 모델
    createServerTableCol("repCartypeNm", "NICE 모델", {
      width: CW_LARGE,
    }),
    // NICE 트림
    createServerTableCol("carGradeNm", "NICE 트림", {
      width: CW_LARGE,
    }),
    // 내장색상
    createServerTableCol("innrCarColorNm", "내장색상", {
      width: CW_LARGE,
    }),
    // 외장색상
    createServerTableCol("xtnlCarColorNm", "외장색상", {
      width: CW_LARGE,
    }),
    // 옵션명
    createServerTableCol("optNm", "옵션명", {
      width: CW_LARGE,
    }),
    // 지역제한
    createServerTableCol("useAreaNm", "지역제한", {
      width: CW_LARGE,
    }),
    // T/M
    createServerTableCol("totFuelNm", "T/M", {
      width: CW_LARGE,
    }),
    // 차형
    createServerTableCol("totShapeNm", "차형", {
      width: CW_LARGE,
    }),
    // 기초SKU(전일)
    createServerTableCol("prodStock", "기초SKU(전일)", {
      width: CW_SMALL,
      pinned: "right",
    }),
    {
      headerName: "반출",
      children: [
        // 계약
        createServerTableCol("totalCntrCnt", "계약", {
          width: CW_SMALL,
          pinned: "right",
        }),
        // 당일취소
        createServerTableCol("todayCnclCnt", "당일취소", {
          width: CW_SMALL,
          pinned: "right",
        }),
        // 실계약
        createServerTableCol("realCntrCnt", "실계약", {
          width: CW_SMALL,
          pinned: "right",
        }),
        // 기타사유
        createServerTableCol("minusCarCnt", "기타사유", {
          width: CW_SMALL,
          pinned: "right",
        }),
      ],
    },
    {
      headerName: "반입",
      children: [
        // 재고등록
        createServerTableCol("addCarCnt", "재고등록", {
          width: CW_SMALL,
          pinned: "right",
        }),
        // 계약취소
        createServerTableCol("otherCntrCnt", "계약취소", {
          width: CW_SMALL,
          pinned: "right",
        }),
      ],
    },
    // 잔여SKU
    createServerTableCol("extraProdStock", "잔여SKU", {
      width: CW_SMALL,
      pinned: "right",
    }),
  ];
};
