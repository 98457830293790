import styled from "styled-components";

export const TableContainer = styled.div<any>`
  //padding: 20px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export const TableHeaderContainer = styled.div<any>`
  height: 48px;
  background: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
`;

export const TableHeaderTitle = styled.h2`
  color: #141626;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: 14px;
  margin-left: 20px;
`;
