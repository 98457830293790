import React, { useEffect, useState } from "react";
import { Formik, FormikProps, FormikValues, Form } from "formik";
import * as Yup from "yup";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import Input from "../../../../../components/shared/data-entry/antd/Input";
import { FlexFormWrapper } from "./style";
import User from "../../../../../models/User";
import errorMessages from "../../../../../constants/errors";
import {
  fetchUserInfo,
  loginAfterPhoneAuth,
  sendPhoneAuthNo,
} from "../../../../../apis/session";
import { alertError, alertSuccess } from "../../../../../utils/render-utils";
import {
  getErrorMessage,
  isProduction,
} from "../../../../../utils/common-utils";
import Button from "../../../../../components/shared/general/antd/Button";
import useAuth from "../../../../../hooks/useAuth";

interface Props extends ModalProps {
  user?: User;
  passwd?: string;
}

const AUTH_DURATION_SEC = 180;

const PhoneAuthModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, user, passwd } = props;

  const formInitialValues = {
    hpNo: "01041287436",
    authNum: "",
  };

  const { setUser } = useAuth();
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);
  const [authNoSent, setAuthNoSent] = useState<boolean>(false);
  const [authNum, setAuthNum] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [authNoSendButtonLoading, setAuthNoSendButtonLoading] = useState<
    boolean
  >(false);
  const [time, setTime] = useState(0);

  const formValidationSchema = Yup.object().shape({
    authNum: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });

  let formik: FormikProps<FormikValues>;

  useEffect(() => {
    if (time > 0) {
      const Counter = setInterval(() => {
        setTime(time - 1);
      }, 1000);
      return () => clearInterval(Counter);
    }
    if (time === 0 && authNoSent) {
      alertError("인증시간이 만료되었습니다");
      setAuthNum("");
      setAuthNoSent(false);
      formik?.setFieldValue("authNum", "");
    }
  }, [time]);

  const onModalOpen = () => {
    if (user) {
      setInitialValues({
        hpNo: user.hpNo,
        authNum: "",
      });
    } else {
      setInitialValues(formInitialValues);
    }
  };
  /**
   * Private Functions
   */

  const timeFormat = (time: number) => {
    const m = Math.floor(time / 60).toString();
    let s = (time % 60).toString();
    if (s.length === 1) s = `0${s}`;
    return `${m}:${s}`;
  };

  const startTimer = () => {
    setTime(AUTH_DURATION_SEC);
  };

  const requestSendAuthNo = async (hpNo: string) => {
    setAuthNoSendButtonLoading(true);
    try {
      const data = await sendPhoneAuthNo(hpNo);
      startTimer();
      setAuthNoSent(true);
      alertSuccess(
        `인증번호가 전송되었습니다.${isProduction() ? "" : data.authNum}`
      );
    } catch (e) {
      alertError(getErrorMessage(e));
      setAuthNoSent(false);
    } finally {
      setAuthNoSendButtonLoading(false);
    }
  };

  const requestAuthLogin = async (
    hpNo: string,
    authNum: string,
    loginId: string,
    passwd: string
  ) => {
    setConfirmLoading(true);
    try {
      await loginAfterPhoneAuth(hpNo, authNum, loginId, passwd);
      // 로그인 응답에는 메뉴데이터가 없어 세션 재조회
      const { user: userRes } = await fetchUserInfo();
      setUser(userRes);
      if (onCancel) onCancel();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    const { hpNo, authNum } = values;
    if (passwd && user) {
      await requestAuthLogin(hpNo, authNum, user.loginId, passwd);
    }
  };

  /**
   * Render Helpers
   */

  const renderFormElements = (renderProps: FormikProps<FormikValues>) => {
    const { values, setFieldValue } = renderProps;
    const { hpNo, authNum } = values;

    return (
      <Form>
        <FormItem
          label="휴대폰번호"
          required
          labelCol={{ span: 24 }}
          name="hpNo"
        >
          <FlexFormWrapper>
            <Input
              size="large"
              style={{
                flex: 1,
              }}
              disabled
              value={hpNo}
            />
            <Button
              style={{
                marginLeft: "8px",
              }}
              size="large"
              loading={authNoSendButtonLoading}
              onClick={async () => {
                await requestSendAuthNo(hpNo);
              }}
            >
              {authNoSent ? "재발송" : "인증번호 발송"}
            </Button>
          </FlexFormWrapper>
        </FormItem>

        {authNoSent && (
          <FormItem
            name="authNum"
            style={{
              marginTop: "24px",
            }}
            label="인증번호 입력"
            required
            labelCol={{ span: 24 }}
            help={
              <div>
                {`다시 시도하려면 상단의 재전송 버튼을 눌러주세요 ${timeFormat(
                  time
                )}`}
              </div>
            }
          >
            <FlexFormWrapper>
              <Input
                placeholder="인증번호입력"
                size="large"
                value={authNum}
                style={{
                  flex: 1,
                }}
                onChange={(e) => {
                  setFieldValue("authNum", e.target.value);
                  setAuthNum(e.target.value);
                }}
              />
            </FlexFormWrapper>
          </FormItem>
        )}
      </Form>
    );
  };

  return (
    <Modal
      title="2차 인증"
      description="2차 인증이 필요한 계정입니다."
      size="small"
      onCancel={onCancel}
      onOpen={onModalOpen}
      visible={visible}
      confirmLoading={confirmLoading}
      okButtonProps={{
        disabled: !authNum,
      }}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      <Formik
        enableReinitialize
        innerRef={(ref: FormikProps<FormikValues>) => {
          formik = ref;
        }}
        initialValues={initialValues}
        validationSchema={formValidationSchema}
        onSubmit={handleSubmit}
      >
        {(renderProps: FormikProps<FormikValues>) => {
          return renderFormElements(renderProps);
        }}
      </Formik>
    </Modal>
  );
};

PhoneAuthModal.defaultProps = {};
export default PhoneAuthModal;
