class TagoTkvReqContract {
  // 계약ID
  public cntrId = "";

  // 계약순번
  public cntrSeq = "";

  // 인수요청 일자
  public tkvReqDt = "";

  // 인수요청 여부
  public tkvReqYn = "";

  // 계약일자
  public cntrDt = "";

  // 고객ID
  public custId = "";

  // 고객명
  public custNm = "";

  // 차량번호
  public carNo = "";

  // 모델(대표차명)
  public repCartypeNm = "";

  // 세부모델(차종명)
  public carClassNm = "";

  // 트림((NICE AB)등급명)
  public carGradeNm = "";

  // 연료명
  public fuelNm = "";

  // 차량가격
  public totCarAmt = "";

  // 차량 인수가
  public carTkvAmt = "";

  // 신차장기 판매구분
  public ncarClsFlag = "";

  // 신차장기 판매구분명
  public ncarClsFlagNm = "";

  // 중도 인수가 (현재 인수가)
  public mdlTkvAmt = "";

  // 월 렌탈료
  public mmRentAmtV = "";

  // 연간약정거리코드
  public prmsDtcClsCd = "";

  // 연간약정거리
  public prmsDtcClsNm = "";
}

export default TagoTkvReqContract;
