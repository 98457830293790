class ConsultingDetail {
  // 상담구분
  public cnslCls = "";

  // 상담구분명
  public cnslClsNm = "";

  // 집계번호
  public btchNo = "";

  // 상담 조건별 순번
  public btchSeq = "";

  // 조건1
  public cond1 = "";

  // 조건2
  public cond2 = "";

  // 조건3
  public cond3 = "";

  // 순위
  public rnk = 0;

  // 건수
  public nmbr = 0;

  // 관리자 순위
  public mngrRnk = 0;

  // 관리자 건수
  public mngrNmbr = 0;

  // 변경자
  public updUser = "";

  // 변경일시
  public updDtm = "";

  // 등록자
  public regUser = "";

  // 등록일시
  public regDtm = "";
}

export default ConsultingDetail;
