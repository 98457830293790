import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../../utils/table-utils";
import {
  currencyFormatRenderer,
  dateFormatRenderer,
} from "../../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../../../../constants/strings";
import { CR_STATUS_TEXT } from "../../../../../../components/shared/data-display/Tables/components/ServerRowTable";

// 견적관리 컬럼
export const createEstimationCols = (): Array<TableColumn> => {
  return [
    // 견적일
    createTableCol("estDt", commonStrings.COL_EST_DATE, FilterType.Date, {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("estDt"),
    }),
    // 만료일
    createTableCol(
      "cntrAvailTermMm",
      commonStrings.COL_EST_EXPIRED_DATE,
      FilterType.Date,
      {
        width: CW_MEDIUM,
        cellRenderer: dateFormatRenderer("cntrAvailTermMm"),
      }
    ),
    // 상품타입
    createTableCol(
      "ncarClsFlagNm",
      commonStrings.COL_SELLING_TYPE,
      FilterType.Text,
      {
        width: CW_SMALL,
      }
    ),
    // 모델
    createTableCol("repCartypeNm", commonStrings.COL_MODEL, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 세부 모델
    createTableCol(
      "carClassNm",
      commonStrings.COL_DETAIL_MODEL,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    // 트림
    createTableCol("carGradeNm", commonStrings.COL_TRIM, FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
    }),
    // 계약기간
    createTableCol(
      "cntrTermMm",
      commonStrings.COL_CONTRACT_TERM,
      FilterType.Text,
      {
        width: CW_MEDIUM,
        valueGetter: (params) => {
          const { data } = params;
          return `${data.cntrTermMm}개월`;
        },
      }
    ),
    // 약정주행거리
    createTableCol(
      "prmsDtcClsNm",
      commonStrings.COL_MILEAGE_LIMIT,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    // 운전자범위
    createTableCol(
      "drvSoeNm",
      commonStrings.COL_DRIVER_RANGE,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    // 담보조건
    createTableCol(
      "pldgCondNm",
      commonStrings.COL_DEPOSIT_WAY,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    // 견적유형
    createTableCol("custClsNm", commonStrings.COL_EST_TYPE, FilterType.Text, {
      width: CW_SMALL,
    }),
    // 견적상태
    createTableCol("estStsNm", commonStrings.COL_EST_STATE, FilterType.Text, {
      width: CW_SMALL,
    }),

    // 관리
    createActionCol(80),
  ];
};

// 계약관리 컬럼
export const createContractCols = (): Array<TableColumn> => {
  return [
    // 견적일
    createTableCol("estDt", commonStrings.COL_EST_DATE, FilterType.Date, {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("estDt"),
    }),
    // 계약일
    createTableCol("cntrDt", commonStrings.COL_CONTRACT_DATE, FilterType.Date, {
      width: CW_MEDIUM,
      cellRenderer: dateFormatRenderer("cntrDt"),
    }),
    // 차량등록번호
    createTableCol("carNo", commonStrings.COL_CAR_NO, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 계약유형
    createTableCol("custClsNm", "계약유형", FilterType.Text, {
      width: CW_SMALL,
    }),
    // 계약진행상태
    createTableCol("cntrStsNm", "계약진행상태", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 담보조건
    createTableCol("pldgCondNm", "담보조건", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 담보납부여부
    createTableCol("pldgAmtPaymentYn", "담보금액 납부여부", undefined, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "납부완료" : "미납부",
          },
        };
      },
    }),
    // 촐고여부
    createTableCol("consiStsNm", "출고여부", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 담당 카매니저
    createTableCol("carmanNm", "담당 카매니저", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 모델
    createTableCol("repCartypeNm", "모델", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 세부모델
    createTableCol("carClassNm", "세부모델", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 트림
    createTableCol("carGradeNm", "트림", FilterType.Text, {
      flex: 1,
      minWidth: CW_LARGE,
    }),
    // 차량가
    createTableCol("totCarAmt", "차량가격", undefined, {
      width: CW_MEDIUM,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: currencyFormatRenderer("totCarAmt"),
    }),
    // 계약종료일
    createTableCol("cntrEndDt", "계약종료일", undefined, {
      width: CW_SMALL,
      cellRenderer: dateFormatRenderer("cntrEndDt"),
    }),
    // 상품유형
    createTableCol("prodPatternNm", "상품유형", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 관리
    createActionCol(80),
  ];
};

// 포인트내역 컬럼
export const createPointHistoryCols = (): Array<TableColumn> => {
  return [
    // 적용일시
    createTableCol("c", "적용일시", FilterType.Date, {
      width: CW_MEDIUM,
    }),
    // 적용주체
    createTableCol("c", "적용주체", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 사유
    createTableCol("c", "사유", FilterType.Text, {
      width: CW_MEDIUM,
      flex: 1,
    }),
    // 적립/소진 여부
    createTableCol("c", "적립/소진 여부", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 포인트
    createTableCol("c", "포인트", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 소멸예정일시
    createTableCol("c", "소멸예정일시", FilterType.Date, {
      width: CW_MEDIUM,
    }),
  ];
};

// MGM 컬럼
export const createMGMHistoryCols = (): Array<TableColumn> => {
  return [
    // 성공일시
    createTableCol("c", "성공일시", FilterType.Date, {
      width: CW_MEDIUM,
    }),
    // 계약고객
    createTableCol("c", "계약고객", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 계약정보
    createTableCol("c", "계약정보", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 소개경로
    createTableCol("c", "소개경로", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 견적상태
    createTableCol("c", "견적상태", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 보상지급여부
    createTableCol("c", "보상지급여부", FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 관리
    // createActionCol(80),
  ];
};
