import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_HEADER: "신차발주 납기관리",
    TITLE_HEADER_DESC:
      "RentOK 게시판에서 확인한 납기 공지를 확인하고 납기일자를 등록해주세요. 고객은 이 정보를 바탕으로 출고예상일자를 확인합니다.",
    TITLE_TABLE_HEADER: "신차발주 납기관리 리스트",

    // 테이블 컬럼
    COL_REGISTERED_AT: "등록일",
    COL_BRAND: "제조사",
    COL_MODEL: "모델",
    COL_DETAIL_MODEL: "세부모델",
    COL_TRIM: "트림",
    COL_ORDER_DELIVERY_DATE: "납기일",
    COL_EFFECTIVE_DATE: "유효기간",
    COL_IS_EXPIRED: "만료여부",

    // Form
    LABEL_DELIVERY_TERM_INFO: "납기정보",
    HINT_DELIVERY_TERM_INFO: "납기정보를 입력해주세요 (주단위)",

    FEEDBACK_ADD: "납기정보가 등록되었습니다",
    FEEDBACK_EDIT: "납기정보가 변경되었습니다",

    TITLE_ACTION_ADD: "신규 등록",
    TITLE_ACTION_DELETE: "선택 삭제",

    CONFIRM_TITLE_DELETE: "납기정보 삭제",
    CONFIRM_MESSAGE_DELETE: "해당 납기정보를 삭제하시겠습니까?",

    TITLE_MODAL_HEADER_ADD: "납기정보 등록",
    TITLE_MODAL_HEADER_EDIT: "납기정보 수정",
  },
});
