import moment from "moment";
import * as Yup from "yup";
import { ExhibitionMaster } from "../../../../../../../../apis/tb-ex/types";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../../../components/shared/data-entry/FormBuilder/types";
import errorMessages from "../../../../../../../../constants/errors";
import { getInitialDateRangeFromRaw } from "../../../../../../../../utils/form-utils";

export const createInitialValues = () => {
  return {
    exhTitle: "",
    exhDesc1: "",
    exhDesc2: "",
    displayTerm: null,
    exhMstViewSeq: "",
    exhLinkUrl: "",
    exhUseYn: false,
  };
};

export const createInitialValuesFromExhibitionMaster = (
  master: ExhibitionMaster
) => {
  const {
    exhStDt,
    exhEndDt,
    exhTitle,
    exhMstSeq,
    exhMstViewSeq,
    exhUseYn,
    exhDesc1,
    exhDesc2,
    exhLinkUrl,
  } = master;
  const format = "YYYYMMDDHHmmss";
  const initialDisplayTerm = [
    moment(exhStDt, format),
    moment(exhEndDt, format),
  ];
  getInitialDateRangeFromRaw(exhStDt, exhEndDt);

  return {
    exhTitle,
    exhMstSeq,
    exhDesc1: exhDesc1 || "",
    exhDesc2: exhDesc2 || "",
    displayTerm: initialDisplayTerm,
    exhLinkUrl: exhLinkUrl || "",
    exhMstViewSeq,
    exhUseYn: exhUseYn === "Y",
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    exhTitle: Yup.string().required(errorMessages.REQUIRED_FIELD),
    exhDesc1: Yup.string().required(errorMessages.REQUIRED_FIELD),
    exhDesc2: Yup.string().required(errorMessages.REQUIRED_FIELD),
    exhMstViewSeq: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    displayTerm: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(2, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createForms = (): FormInfo[] => {
  return [
    {
      key: "exhTitle",
      label: "제목",
      type: FormElementType.Input,
      placeholder: "기획전 제목을 입력해주세요",
      required: true,
    },
    {
      key: "exhDesc1",
      label: "설명1",
      type: FormElementType.Input,
      placeholder: "제목 하단 설명을 입력해주세요",
      required: true,
    },
    {
      key: "exhDesc2",
      label: "설명2",
      type: FormElementType.Input,
      placeholder: "제목 하단 설명을 입력해주세요",
      required: true,
    },
    {
      key: "displayTerm",
      label: "노출기간",
      type: FormElementType.DateRangePicker,
      placeholder: "적용기간을 입력해주세요",
      required: true,
      config: {
        showTime: true,
      },
    },
    {
      key: "exhMstViewSeq",
      label: "게시순번",
      type: FormElementType.InputNumber,
      placeholder: "게시순번을 입력해주세요",
      required: true,
      config: {
        min: 1,
      },
    },
    {
      key: "exhLinkUrl",
      label: "링크 URL",
      type: FormElementType.Input,
      placeholder: "링크 URL을 입력해주세요",
      required: false,
    },
  ];
};
