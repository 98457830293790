import {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../../utils/table-utils";
import { CR_STATUS_TEXT } from "../../../../../../components/shared/data-display/Tables/components/ServerRowTable";
import {
  QuestionInfo,
  ReplyInfo,
} from "../../../../../../models/Survey/SurveyMasterDetail";
import {
  SURVEY_PREFIX_TRANSLATION,
  SURVEY_REPLY_GROUP_CD_TRANSLATIONS,
} from "../../../../../../constants/enums";

export const createQuestionCols = () => {
  return [
    createTableCol("point", "별점", FilterType.Text, {
      width: CW_SMALL,
    }),
    createTableCol("headClsCd", "말머리구분", FilterType.Text, {
      width: CW_MEDIUM,
      valueGetter: ({ data }) => {
        const question = data as QuestionInfo;
        return SURVEY_PREFIX_TRANSLATION[question.headClsCd];
      },
    }),
    createTableCol("srveQitmCont", "설문내용", FilterType.Text, {
      width: CW_LARGE,
      flex: 1,
    }),
    createTableCol("replyAvailCnt", "답변가능수", FilterType.Text, {
      width: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        const { replyAvailCnt } = data;
        if (replyAvailCnt) {
          return replyAvailCnt;
        }

        return "제한없음";
      },
    }),
    {
      ...createActionCol(80),
    },
  ];
};

export const createReplyCols = () => {
  return [
    createTableCol("srveReplyItemNm", "답변내용", FilterType.Text, {
      width: CW_LARGE,
      flex: 1,
    }),
    createTableCol("replyClsCd", "고객 만족도", FilterType.Text, {
      width: CW_MEDIUM,
      valueGetter: ({ data }) => {
        const reply = data as ReplyInfo;
        return SURVEY_REPLY_GROUP_CD_TRANSLATIONS[reply.replyClsCd];
      },
    }),
    createTableCol("txtInsYn", "텍스트입력", FilterType.Text, {
      width: CW_MEDIUM,
      valueGetter: ({ data }) => {
        const reply = data as ReplyInfo;
        return reply.txtInsYn === "Y" ? "입력가능" : "입력불가";
      },
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "입력가능",
            text: value,
          },
        };
      },
    }),
    {
      ...createActionCol(80),
    },
  ];
};
