import React, { useState } from "react";
import { LoadSuccessParams } from "ag-grid-community/dist/lib/rowNodeCache/rowNodeBlock";
import moment from "moment";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import { createEstimationReportCols } from "./utils/table-utils";
import { getPageIndex } from "../../../utils/ag-grid-utils";
import { get1YearDateRange } from "../../../utils/date-utils";
import {
  FilterElementType,
  FilterFormInfo,
} from "../../../components/shared/layout/TableFilterLayout/types";
import { fetchEstimationReport } from "../../../apis/report";
import {
  OPTIONS_CUST_CLASS,
  OPTIONS_PROD_TYPE,
  SEARCH_OPTION_ALL,
} from "../../../constants/enums";

const EstimationReportManager: React.FC = () => {
  const [totalCount, setTotalCount] = useState(0);
  const cols = createEstimationReportCols();
  const [logInfo, setLogInfo] = useState<string>("");

  const filterInitialValues = {
    stDt: get1YearDateRange(),
    ncarClsFlag: "",
    custCls: "",
  };

  const filterForms: FilterFormInfo[] = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "조회기간",
    },
    {
      key: "ncarClsFlag",
      type: FilterElementType.Select,
      label: "판매유형",
      options: [SEARCH_OPTION_ALL, ...OPTIONS_PROD_TYPE],
    },
    {
      key: "custCls",
      type: FilterElementType.Select,
      label: "고객구분",
      options: [SEARCH_OPTION_ALL, ...OPTIONS_CUST_CLASS],
    },
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);
    const { stDt, ncarClsFlag, custCls } = appliedFilterValues;
    const stDtParam = moment(stDt[0]).format("YYYYMMDD");
    const endDtParam = moment(stDt[1]).format("YYYYMMDD");

    try {
      const { items, count } = await fetchEstimationReport(
        getPageIndex(startRow, paginationPageSize),
        paginationPageSize,
        stDtParam,
        endDtParam,
        ncarClsFlag,
        custCls
      );
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
    } catch (e) {
      if (e.status !== 404) {
        setTotalCount(0);
        success({
          rowData: [],
          rowCount: 0,
        });
      }
    }
  };

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <PaginationTableLayout
      cols={cols}
      onDataLoad={onDataLoad}
      headerTitle="견적 집계 순위"
      tableHeaderTitle="견적 순위 리스트"
      totalCount={totalCount}
      filterInitialValues={filterInitialValues}
      filterForms={filterForms}
      hideFilterAction
      methodName="/admin/est-info"
      logInfo={logInfo}
    />
  );
};

EstimationReportManager.defaultProps = {};
export default EstimationReportManager;
