import React, { useState } from "react";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { alertError, renderLoading } from "../../../../../utils/render-utils";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import {
  formatCurrency,
  formatNumber,
  getErrorMessage,
} from "../../../../../utils/common-utils";
import { ModalProps } from "../../../../types";
import { 
  fetchAUCCatalogDetail, 
  fetchAUcCooperCatalogsTagUpdate
} from "../../../../../apis/auc-catalogs";
import AucCatalogDetail from "../../../../../models/AucCatalogDetail";
import { formatDateString } from "../../../../../utils/date-utils";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import {
  createTableCol,
  FilterType,
} from "../../../../../components/shared/data-display/Tables/model/TableColumn";
import { CW_LARGE, CW_MEDIUM, CW_SMALL } from "../../../../../utils/table-utils";
import Button from "../../../../../components/shared/general/antd/Button";
import Image from "../../../../../components/shared/data-display/antd/Image";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import MdCommentFormModal from "./pages/MdCommentFormModal";
import MmRentAmountFormModal from "./pages/MmRentAmountFormModal";
import AucImageManagerModal from "./pages/AucImageManagerModal";
import AucImageSortOrderModal from "./pages/AucImageSortOrderModal";

interface Props extends ModalProps {
  dataId?: string;
}

const AucCarCatalogModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, dataId, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [catalogDetail, setCatalogDetail] = useState<AucCatalogDetail>();
  const [mdCommentFormModalVisible, setMdCommentFormModalVisible] = useState(false);
  const [imageSortOrderModalVisible, setImageSortOrderModalVisible] = useState(false);
  const [imageManagerModalVisible, setImageManagerModalVisible] = useState(false);
  const [tkvAmtSetModalVisible, setTkvAmtSetModalVisible] = useState(false);

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchCatalogDetail();
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const requestFetchCatalogDetail = async () => {
    if (dataId) {
      try {
        const catalogDetail = await fetchAUCCatalogDetail(dataId);
        setCatalogDetail(catalogDetail);
      } catch (e) {
        alertError(getErrorMessage(e));
        setCatalogDetail(undefined);
      }
    }
  };

  /**
   * Event Actions
   */

  const handleImageSortOrderClick = () => {
    setImageSortOrderModalVisible(true);
  };

  const handleImageManagerClick = () => {
    setImageManagerModalVisible(true);
  };

  const handleMdContentClick = () => {
    setMdCommentFormModalVisible(true);
  };

  const handleMdAmountModifyClick = () => {
    setTkvAmtSetModalVisible(true);
  };

  const handleTagModifyClick = async (keyValue : string) => {
    const prodId = catalogDetail?.prodId;
    const tagCd = keyValue;
    if(prodId) {
      await fetchAUcCooperCatalogsTagUpdate(prodId, tagCd);
    }

    setDataFetching(true);
    await requestFetchCatalogDetail();
    setDataFetching(false);
  };

  /**
   * Render Helpers
   */

  const renderDetail = () => {
    if (catalogDetail) {
      const {
        carNo,
        carClassNm,
        yearType,
        lastDtc,
        fuelNm,
        istdTrans,
        engdisp,
        seaterCapa,
        innrCarColorNm,        
        xtnlCarColorNm,
        mdComment,
        mainOptNms,
        carImgList = [],
        rentAmtList = [],
        carGradePrice,
        carRegDtm,
        tagCd,
      } = catalogDetail;

      /**
       * 차량정보
       */
      const carInfoSection = [
        {
          label: "차량이미지",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            if (carImgList && carImgList.length > 0) {
              return carImgList.map(({ fileUrl }) => {
                return (
                  <Image
                    style={{ marginRight: "8px" }}
                    key={fileUrl}
                    src={fileUrl}
                    width={102}
                    height={76}
                  />
                );
              });
            }

            return "-";
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <Button
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={handleImageManagerClick}
                >
                  수정하기
                </Button>
                <Button onClick={handleImageSortOrderClick}>
                  게시순번 관리
                </Button>
              </div>
            );
          },
        },        
      ];

      /**
       * 태그정보
       */
      const carTagInfoSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {            
            return (
              <div>
                <Button
                  type={tagCd==="A66001" ? undefined : "dashed"}
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    handleTagModifyClick("A66001");
                  }}
                >
                  미적용
                </Button>
                <Button
                  type={tagCd==="A66002" ? undefined : "dashed"}
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    handleTagModifyClick("A66002");
                  }}
                >
                  New !
                </Button>
                <Button
                  type={tagCd==="A66003" ? undefined : "dashed"}
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    handleTagModifyClick("A66003");
                  }}
                >
                  신차급
                </Button>
                <Button
                  type={tagCd==="A66004" ? undefined : "dashed"}
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    handleTagModifyClick("A66004");
                  }}
                >
                  월렌트
                </Button>
                <Button
                  type={tagCd==="A66005" ? undefined : "dashed"}
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    handleTagModifyClick("A66005");
                  }}
                >
                  가성비
                </Button>
                <Button
                  type={tagCd==="A66006" ? undefined : "dashed"}
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    handleTagModifyClick("A66006");
                  }}
                >
                  할인특가
                </Button>
              </div>
            )
          },
        }
      ];

      /**
       * 기본정보
       */
      const basicInfoSection = [
        {
          label: "차량명",
          type: FieldType.Text,
          content: carClassNm,
        },
        {
          label: "연식",
          type: FieldType.Text,
          content: yearType,
        },
        {
          label: "차량등록일",
          type: FieldType.Text,
          content: `${formatDateString(carRegDtm)}`,
        },
        {
          label: "주행거리",
          type: FieldType.Custom,
          render: () => {
            return `${formatNumber(lastDtc)}km`;
          },
        },
        {
          label: "연료",
          type: FieldType.Text,
          content: fuelNm,
        },
        {
          label: "배기량",
          type: FieldType.Custom,
          render: () => {
            return `${formatNumber(engdisp)}cc`;
          },
        },
        {
          label: "인승",
          type: FieldType.Text,
          content: seaterCapa,
        },
        {
          label: "변속기",
          type: FieldType.Text,
          content: istdTrans,
        },        
        {
          label: "차량번호",
          type: FieldType.Text,
          content: carNo,
        },
        {
          label: "색상(내)",
          type: FieldType.Text,
          content: innrCarColorNm,
        },
        {
          label: "색상(외)",
          type: FieldType.Text,
          content: xtnlCarColorNm,
        },
        {
          label: "제조사 신차가격",
          type: FieldType.Custom,
          render: () => {
            return formatCurrency(carGradePrice);
          },
        },
        {
          label: "차량소개",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            if (mdComment) {
              return <div dangerouslySetInnerHTML={{ __html: mdComment }} />;
            }

            return "-";
          },
        },
        {
          label: "옵션정보",
          type: FieldType.Text,
          content: mainOptNms,
          span: 24,
        },
        {
          label: "",
          type: FieldType.Custom,
          render: () => {
            return <Button onClick={handleMdContentClick}>수정하기</Button>;
          },
        },
      ];

      /**
       * 월 렌탈료 정보
       */
       const rentAmtInfoSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div
                style={{
                  height: "620px",
                }}
              >
                <ClientRowTable
                  cols={[
                    createTableCol("rentAmtSeq", "순번", FilterType.Text, {
                      width: CW_SMALL,
                      flex: 1,
                    }),
                    createTableCol("cntrTermNm", "계약기간", FilterType.Text, {
                      width: CW_SMALL,
                    }),
                    createTableCol("pldgRt", "담보율", FilterType.Text, {
                      width: CW_SMALL,
                    }),
                    createTableCol("rglRentAmt1", "약정1만", undefined, {
                      width: CW_MEDIUM,
                      cellRenderer: formatCurrency("rglRentAmt1"),
                    }),
                    createTableCol("rglRentAmt2", "약정2만", undefined, {
                      width: CW_MEDIUM,
                      cellRenderer: formatCurrency("rglRentAmt2"),
                    }),
                    createTableCol("rglRentAmt3", "약정3만", undefined, {
                      width: CW_MEDIUM,
                      cellRenderer: formatCurrency("rglRentAmt3"),
                    }),
                    createTableCol("rglRentAmt4", "약정4만", undefined, {
                      width: CW_MEDIUM,
                      cellRenderer: formatCurrency("rglRentAmt4"),
                    }),
                  ]}
                  rowData={rentAmtList}
                />
              </div>
            );
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          render: () => {
            return <Button onClick={handleMdAmountModifyClick}>수정하기</Button>;
          },
        },
      ];

      return (
        <div>
          <DetailSection label="차량정보" fieldInfos={carInfoSection} />
          <DetailSection label="태그정보" fieldInfos={carTagInfoSection} />
          <DetailSection label="기본정보" fieldInfos={basicInfoSection} />
          <DetailSection label="월 렌탈료 정보" fieldInfos={rentAmtInfoSection} hideDivider />
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        onOpen={onModalOpen}
        visible={visible}
        onCancel={onCancel}
        title="상품 상세 정보"
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      <AucImageManagerModal
        visible={imageManagerModalVisible}
        onCancel={() => {
          setImageManagerModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <AucImageSortOrderModal
        visible={imageSortOrderModalVisible}
        onCancel={() => {
          setImageSortOrderModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <MdCommentFormModal
        visible={mdCommentFormModalVisible}
        onCancel={() => {
          setMdCommentFormModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <MmRentAmountFormModal
        visible={tkvAmtSetModalVisible}
        onCancel={() => {
          setTkvAmtSetModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
    </>
  ); 
};

AucCarCatalogModal.defaultProps = {
  onCancel: () => {},
  visible: false,
  dataId: undefined,
};
export default AucCarCatalogModal;
