import { plainToClass } from "class-transformer";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { TagMasterAddParam, TagMasterEditParam } from "./types";
import TagMaster from "../../models/TagMaster";

const apiRootUrl = `${ROOT_URL}/admin/tag-master`;

/**
 * 태그 리스트 조회
 */
export const fetchTagMasters = async (): Promise<
  ListItemResponse<TagMaster>
> => {
  const res = await request(HttpMethod.Get, apiRootUrl);
  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  return {
    items: plainToClass(TagMaster, list as Array<JSON>),
    count: Number(listCnt),
  };
};

/**
 * 태그 등록
 */
export const addTagMaster = async (params: TagMasterAddParam) => {
  await request(HttpMethod.Post, apiRootUrl, undefined, {
    ...params,
  });
};

/**
 * 태그 수정
 */

export const editTagMaster = async (
  tagId: string,
  params: TagMasterEditParam
) => {
  const url = `${apiRootUrl}/${tagId}`;
  await request(HttpMethod.Put, url, undefined, {
    ...params,
  });
};
