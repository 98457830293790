import React, { useCallback, useState } from "react";
import { FormikProps, FormikValues } from "formik";
import strings from "../../constants/strings";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../types";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  addFAQ,
  editFAQ,
  fetchFAQCategories,
  fetchFAQDetail,
} from "../../../../../apis/faqs";
import {
  createAddFAQParam,
  createEditFAQParams,
  createInitialValues,
  createInitialValuesFromFAQ,
  createValidationSchema,
} from "./utils/form-utils";
import FormBuilder, {
  defaultFormItemLayout,
} from "../../../../../components/shared/data-entry/FormBuilder";
import FAQDetail from "../../../../../models/FAQDetail";
import { FormElementType } from "../../../../../components/shared/data-entry/FormBuilder/types";
import FAQCategory from "../../../../../models/FAQCategory";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import Select from "../../../../../components/shared/data-entry/antd/Select";

interface Props extends ModalProps {
  faqNo?: string;
}

const FAQModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, faqNo, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [faq, setFAQ] = useState<FAQDetail | null>();
  const [faqCategories, setFaqCategories] = useState<FAQCategory[]>([]);

  let formik: FormikProps<FormikValues>;
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );

  const onModalOpen = async () => {
    setDataFetching(true);
    const categories = await requestFetchCategories();
    setFaqCategories(categories);
    if (faqNo) {
      const faqDetail = await requestFetchFAQDetail(faqNo);
      if (faqDetail) {
        setInitialValues(createInitialValuesFromFAQ(faqDetail));
        setFAQ(faqDetail);
      }
    } else {
      setInitialValues(createInitialValues());
      setFAQ(undefined);
    }
    setDataFetching(false);
  };

  const firstCategories = () => {
    if (faqCategories) {
      return faqCategories.map((category) => {
        return {
          label: category.llfNm,
          value: category.llfCd,
        };
      });
    }
    return [];
  };

  const secondCategories = (llfCd: string) => {
    if (faqCategories) {
      const category = faqCategories.filter((category) => {
        return category.llfCd === llfCd;
      })[0] as FAQCategory;

      if (category) {
        return category.mlfCdList.map((second) => {
          return {
            label: second.mlfNm,
            value: second.mlfCd,
          };
        });
      }
    }
    return [];
  };

  const createFormInfo = () => {
    return [
      {
        key: "llfCd",
        type: FormElementType.Custom,
        render: (renderProps: FormikProps<FormikValues>) => {
          const { values, setFieldValue, errors, touched } = renderProps;
          const { llfCd } = values;
          let err;
          if (errors.llfCd && touched.llfCd) {
            err = errors.llfCd;
          }
          return (
            <FormItem
              label="대분류"
              required
              help={err}
              validateStatus={err && "error"}
              {...defaultFormItemLayout}
              style={{
                marginBottom: "16px",
              }}
            >
              <Select
                value={llfCd}
                options={firstCategories()}
                placeholder="대분류 코드를 선택해주세요"
                onChange={(value) => {
                  setFieldValue("llfCd", value);
                  setFieldValue("mlfCd", null);
                }}
              />
            </FormItem>
          );
        },
      },
      {
        key: "mlfCd",
        type: FormElementType.Custom,
        render: (renderProps: FormikProps<FormikValues>) => {
          const { values, setFieldValue, errors, touched } = renderProps;
          const { llfCd, mlfCd } = values;
          let err;
          if (errors.mlfCd && touched.mlfCd) {
            err = errors.mlfCd;
          }
          return (
            <FormItem
              label="중분류"
              required
              help={err}
              validateStatus={err && "error"}
              {...defaultFormItemLayout}
              style={{
                marginBottom: "16px",
              }}
            >
              <Select
                value={mlfCd}
                placeholder="중분류 코드를 선택해주세요"
                options={secondCategories(llfCd)}
                disabled={!llfCd}
                onChange={(value) => {
                  setFieldValue("mlfCd", value);
                }}
              />
            </FormItem>
          );
        },
      },
      // 메인노출 여부
      {
        key: "mainYn",
        type: FormElementType.Checkbox,
        label: "메인 노출여부",
        required: true,
      },
      // 질문
      {
        key: "qstn",
        type: FormElementType.Input,
        label: "질문",
        placeholder: "질문을 입력해주세요",
        required: true,
      },
      // 내용
      {
        key: "answer",
        type: FormElementType.TextArea,
        label: "답변",
        placeholder: "답변을 입력해주세요",
        required: true,
      },
    ];
  };

  /**
   * Private Functions
   */

  const requestFetchFAQDetail = async (faqNo: string) => {
    try {
      return await fetchFAQDetail(faqNo);
    } catch (e) {
      alertError(getErrorMessage(e));
      return null;
    }
  };

  const requestFetchCategories = async () => {
    try {
      const { items } = await fetchFAQCategories();
      return items;
    } catch (e) {
      alertError(getErrorMessage(e));
      return [];
    }
  };

  const requestAddFAQ = async (values: FormikValues) => {
    setConfirmLoading(true);
    try {
      const params = createAddFAQParam(values);
      await addFAQ(params);
      alertSuccess("FAQ가 등록되었습니다");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditFAQ = async (values: FormikValues) => {
    if (faqNo) {
      setConfirmLoading(true);
      try {
        const params = createEditFAQParams(values);
        await editFAQ(faqNo, params);
        alertSuccess("FAQ정보가 변경되었습니다");
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const getModalTitle = useCallback(() => {
    if (faqNo) {
      return strings.MODAL_TITLE_FAQ_DETAIL;
    }
    return strings.MODAL_TITLE_ADD_FAQ;
  }, [faqNo]);

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (faqNo) {
      await requestEditFAQ(values);
    } else {
      await requestAddFAQ(values);
    }
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      visible={visible}
      title={getModalTitle()}
      onOpen={onModalOpen}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        formik?.handleSubmit();
      }}
    >
      {dataFetching ? (
        renderLoading()
      ) : (
        <FormBuilder
          formRef={(ref) => {
            formik = ref;
          }}
          initialValues={initialValues}
          forms={createFormInfo()}
          validationSchema={createValidationSchema()}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

FAQModal.defaultProps = {
  faqNo: undefined,
};
export default FAQModal;
