import { plainToClass } from "class-transformer";
import Review from "../../models/Review";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import ReviewDetail from "../../models/ReviewDetail";
import { ReviewEditParam } from "./types";

const apiRootUrl = `${ROOT_URL}/admin/reviews`;

/**
 * 리뷰 리스트 조회
 */
export const fetchReviews = async (
  pageIndex: number,
  pageSize: number,
  stDt: string,
  endDt: string
): Promise<ListItemResponse<Review>> => {
  const res = await request(HttpMethod.Get, apiRootUrl, {
    pageSize,
    pageIndex,
    stDt,
    endDt,
  });

  const { data } = res;
  const { response } = data;
  const { totalCnt, list } = response;

  return {
    items: plainToClass(Review, list as Array<JSON>),
    count: Number(totalCnt),
  };
};

/**
 * Review 상세 조회
 */

export const fetchReviewDetail = async (
  cntrId: string
): Promise<ReviewDetail> => {
  const url = `${apiRootUrl}/${cntrId}`;
  const res = await request(HttpMethod.Get, url);
  const { data } = res;
  const { response } = data;
  return plainToClass(ReviewDetail, response);
};

/**
 * 리뷰 수정
 */
export const editReview = async (cntrId: string, params: ReviewEditParam) => {
  const url = `${apiRootUrl}/${cntrId}`;
  await request(HttpMethod.Put, url, undefined, params);
};
