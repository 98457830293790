export class CooperCarCatalog {
  // 상품ID
  public prodId = "";

  // 신차장기 판매구분
  public ncarClsFlag = "";

  // 신차장기 판매구분명
  public ncarClsFlagNm = "";

  // 대표 제조사명
  public repMakerNm = "";

  // 상품명
  public prodNm = "";

  // 대표 연료명
  public repFuelNm = "";

  // 대표 차량 외관 이미지
  public repCarImg = "";

  // 제조사명
  public brandNm = "";

  // 모델
  public repCarClassNm = "";

  // 세부모델
  public carClassNm = "";

  // 최저 차량가격
  public carAmt = "";

  // 최저 렌탈료
  public limitRentAmtV = "";

  // 최대 계약기간
  public cntrTermMm = "";

  // 재고 수량
  public stockCnt = "";

  // 제휴사 상품 게시 여부
  public useYn = "";

  // 제휴사 상품번호
  public cooperProdNo = "";

  // 제휴사 코드
  public cooperCd = "";

  // 제휴사명
  public cooperCdNm = "";

  // 제휴사 상품 URL [11번가용]
  public cooperProdUrl = "";
}
