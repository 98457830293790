export default class DeliveryTermProduct {
  // 상품ID
  public prodId = "";

  // 상품명
  public prodNm = "";

  // 온라인 차량 등급ID
  public modeGrdId = "";

  // NICE 등급 ID
  public carGradeNbr = "";

  // 브렌드명
  public brandNm = "";

  // 대표차종명
  public repCarClassNm = "";

  // 차종명
  public carClassNm = "";

  // NICE 등급명
  public carGradeNm = "";

  // 연료
  public fuel = "";

  // COSS_연료명 <- 관리자표시
  public cossFuelNm = "";

  // 연식
  public yearType = "";
}
