import TableColumn, {
  createServerTableCol,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import { CW_MEDIUM, CW_SMALL } from "../../../../utils/table-utils";

export const createEstimationReportCols = (): Array<TableColumn> => {
  return [
    // 견적순위
    createServerTableCol("rank", "견적순위", {
      width: CW_SMALL,
    }),
    // 계약유형
    createServerTableCol("custClsNm", "계약유형", {
      width: CW_MEDIUM,
    }),
    // 판매유형
    createServerTableCol("ncarClsFlagNm", "판매유형", {
      width: CW_MEDIUM,
    }),
    // 제조사
    createServerTableCol("brandNm", "제조사", {
      width: CW_MEDIUM,
    }),
    // 모델
    createServerTableCol("repCarClassNm", "모델", {
      width: CW_MEDIUM,
    }),
    // 세부모델
    createServerTableCol("carClassNm", "세부모델", {
      width: CW_MEDIUM,
    }),
    // 트림
    createServerTableCol("carGradeNm", "트림", {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // 견적산출 건수
    createServerTableCol("rntFeeCnt", "견적산출 건수", {
      width: CW_MEDIUM,
    }),
    // 견적저장 건수
    createServerTableCol("estSaveCnt", "견적저장 건수", {
      width: CW_MEDIUM,
    }),
  ];
};
