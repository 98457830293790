/* eslint-disable import/no-cycle */
import { CopyTwoTone } from "@ant-design/icons";
import { ICellRendererParams } from "ag-grid-community";
import React, { ReactNode, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  fetchRentContractDetail,
  fetchSingleContractUpdateRequestList,
  updateUcTagobuyCntrTkvReq,
} from "@apis/rent-contracts";
import ClientRowTable from "@components/shared/data-display/Tables/components/ClientRowTable";
import FormItem from "@components/shared/data-entry/antd/FormItem";
import Modal from "@components/shared/feedback/antd/Modal";
import Button from "@components/shared/general/antd/Button";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "@components/shared/layout/DetailSection";
import ModalTableWrapper from "@components/shared/layout/ModalTableWrapper";
import TabLayout from "@components/shared/layout/TabLayout";
import ContractUpdateRequest from "models/ContractUpdateRequest";
import RentContractDetail from "models/RentContractDetail";
import RentContractMemo from "models/RentContractMemo";
import {
  formatCurrency,
  formatNumber,
  getErrorMessage,
} from "@utils/common-utils";
import { formatDateString } from "@utils/date-utils";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "@utils/render-utils";
import { createActionCellRenderers, CW_LARGE } from "@utils/table-utils";
import { ModalProps } from "@components/shared/composition/types";
import Input from "@components/shared/data-entry/antd/Input";
import { Formik, FormikProps, FormikValues } from "formik";
import Select from "@components/shared/data-entry/antd/Select";
import TableDataActionButton from "@components/shared/composition/TableDataActionButton";
import TextArea from "@components/shared/data-entry/antd/TextArea";
import { FormInfo, FormOption } from "@components/shared/data-entry/FormBuilder/types";
import ProductCondition from "@models/EstimationSimulator/ProductCondition";
import { fetchUcRntFeeCarNos, fetchUcRntFeeConds } from "@apis/rnt-sim";
import commonStrings from "@constants/strings";
import * as Yup from "yup";
import TableColumn, { createTableCol, FilterType } from "@components/shared/data-display/Tables/model/TableColumn";
import { UcRntFeeCarNoParam, UcRntFeeCondParam } from "@apis/rnt-sim/types";
import { RentFeeSetResVO } from "@models/RentFeeSet";
import styled from "styled-components";
import { ValidateStatus } from "antd/lib/form/FormItem";
const deopositOptions: FormOption[] = [
  {
    label: "면제",
    value: 0,
  },
  {
    label: "10%",
    value: 10,
  },
  {
    label: "20%",
    value: 20,
  },
  {
    label: "30%",
    value: 30,
  },
  {
    label: "40%",
    value: 40,
  },
  {
    label: "50%",
    value: 50,
  },
  {
    label: "60%",
    value: 60,
  },
]

interface Props extends ModalProps {
  visible: boolean;
  onCancel: () => void;
}

enum successType {
  NULL,
  SUCCESS,
  FAIL,
}

const RentFeeSimulateModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [carAmt, setCarAmt] = useState("");
  const [carNo, setCarNo] = useState("");
  const [carInfo, setCarInfo] = useState("");
  const [rntFeeSetlist, setRntFeeSetlist] = useState<RentFeeSetResVO[]>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [impsnls, setImpsnls] = useState<FormOption[]>([]);
  const [prmsDtcs, setPrmsDtcs] = useState<FormOption[]>([]);
  const [drvSoes, setDrvSoes] = useState<FormOption[]>([]);
  const [isSuccess, setIsSuccess] = useState<successType>(successType.NULL);

  const createInitialCarValues = () => {
    return {
      carNo: "",
    }
  };

  const createInitialCondsValues = () => {
    return {
      depositRt: "",
      prmsDtc: "",
      drvSoe: "",
      impsnl: ""
    }
  };

  const [initialCarValues, setInitialCarValues] = useState<any>(
    createInitialCarValues()
  );
  const [initialCondsValues, setInitialCondsValues] = useState<any>(
    createInitialCondsValues()
  );

  let carFormik: FormikProps<FormikValues>;
  let condsFormik: FormikProps<FormikValues>;
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
    style: {
      marginBottom: "16px",
    },
  };

  const resetProds = () => {
    setCarAmt("");
    setCarInfo("");
    setImpsnls([]);
    setPrmsDtcs([]);
    setDrvSoes([]);
    setRntFeeSetlist([]);
  };

  const createCarValidationSchema = Yup.object().shape({
    carNo: Yup.string().required(commonStrings.REQUIRED_CAR_NO),
  });

  const createCondsValidationSchema = Yup.object().shape({
    depositRt: Yup.string().required(commonStrings.HINT_DEPOSIT),
    prmsDtc: Yup.string().required(commonStrings.HINT_MILEAGE),
    drvSoe: Yup.string().required(commonStrings.HINT_DRIVER_RANGE),
    impsnl: Yup.string().required(commonStrings.HINT_RENT_LAV_COV),
  });

  const getOptions = (prodCondList: ProductCondition[], modeCd: string) => {
    return prodCondList.find(v => v.modeCd === modeCd)?.prodCondList.map(v => {
      return {
        label: v.condNm,
        value: v.cossCondCd,
      };
    });
  };

  /**
   * 중고장기 상품 견적 조건 조회(GET: ~​/admin​/uc-rnt-fee-cond)
   * 
   * @param params 
   * @param values 
   */
  const requestUcRntFeeConds = async (params: UcRntFeeCondParam) => {
    setConfirmLoading(true);
    try {
      const { carAmt, curTravelDtc, modlNm, repFuelNm, yearType, prodCondList} = await fetchUcRntFeeConds(params);
      // 차량 금액 설정
      setCarAmt(carAmt);
      // 대물
      setImpsnls(getOptions(prodCondList, "A17007") ?? []);
      // 약정주행거리
      setPrmsDtcs(getOptions(prodCondList, "A17002") ?? []);
      // 운전자범위
      setDrvSoes(getOptions(prodCondList, "A17003") ?? []);
      // 차량정보
      setCarInfo(
        `${modlNm}` +
        `\n${yearType}년식  |  ${Number(curTravelDtc).toLocaleString()}km  |  ${repFuelNm}` +
        `\n차량 가격(옵션포함) ${Number(carAmt).toLocaleString()}원`
      );

      setIsSuccess(successType.SUCCESS);
    } catch (e) {
      setIsSuccess(successType.FAIL);
      resetProds();
      console.log(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * 중고장기 상품 차량번호 렌탈료 조회(GET: ~​/admin​/uc-rnt-fee-car-no)
   * 
   * @param params 
   * @param values 
   */
  const requestUcRntFeeCarNos = async (params: UcRntFeeCarNoParam) => {
    setConfirmLoading(true);
    try {
      const { rntFeeSetlist } = await fetchUcRntFeeCarNos(params);
      setRntFeeSetlist(rntFeeSetlist)
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Event Actions
   */
  const handleCarSubmit = async (values: FormikValues) => {
    const { carNo } = values;
    const params: UcRntFeeCondParam = { carNo }
    requestUcRntFeeConds(params);
  };

  const handleCondsSubmit = async (values: FormikValues) => {
    const { prmsDtc, depositRt, impsnl, drvSoe } = values;
    const deposit = Math.ceil(((Number(carAmt) * depositRt) / 100) / 1000) * 1000;
    const params: UcRntFeeCarNoParam = { carNo, impsnl, prmsDtc, deposit, drvSoe }
    requestUcRntFeeCarNos(params);
  };

  /**
   * formik values change event
   * 
   * @param renderProps 
   * @param value 
   */
  const handleChange = (
    renderProps: FormikProps<FormikValues>,
    fieldKey: string,
    value: any
  ) => {
    const { setFieldValue } = renderProps;
    setFieldValue(fieldKey, value);
  };

  /**
   * validation check
   */
  const getFormError = (
    renderProps: FormikProps<FormikValues>,
    key: string
  ): {
    help: any;
    validateStatus: any;
  } => {
    const { errors, touched } = renderProps;
    if (errors[key]) {
      return {
        help: errors[key] as string,
        validateStatus: "error",
      };
    }
    return {
      help: undefined,
      validateStatus: "",
    };
  };

  /**
   * grid 폼 생성
   */
  const createRentFeeCols = (): Array<TableColumn> => {
    return [
      // 계약기간
      createTableCol("monthInfo", "계약기간", FilterType.Text, {
        minWidth: CW_LARGE,
        flex: 1,
        valueGetter: ({ data }) => {
          return data.monthInfo;
        },
      }),
      // 렌탈료
      createTableCol("rntAmt", "렌탈료", FilterType.Text, {
        minWidth: CW_LARGE,
        flex: 1,
        valueGetter: ({ data }) => {
          return formatNumber(data.rntAmt);
        },
      }),
    ]
  };

  const renderContent = () => {
    const carNoSection: Array<SectionFieldInfo> = [
      {
        type: FieldType.Custom,
        render: () => {
          return (
            <Formik
              enableReinitialize
              initialValues={initialCarValues}
              validationSchema={createCarValidationSchema}
              onSubmit={handleCarSubmit}
              innerRef={(ref: FormikProps<FormikValues>) => {
                carFormik = ref;
              }}
            >
              {(renderProps: FormikProps<FormikValues>) => {
                const { setFieldValue, values, errors, touched  } = renderProps;
                const { carNo } = values;
                let err;
                let validateStatus: ValidateStatus = "";
                if (errors.carNo && touched.carNo) {
                  err = errors.carNo;
                  validateStatus = "success"
                } else if (isSuccess === successType.FAIL) {
                  err = commonStrings.FAIL_CAR_NO;
                  validateStatus = "error"
                } else if (isSuccess === successType.SUCCESS) {
                  err = commonStrings.SUCCESS_CAR_NO;
                  validateStatus = "warning"
                }

                return (
                  <form className="estimateForm">
                    <FormItem
                      label={"차량번호"}
                      {...formItemLayout}
                      help={err}
                      validateStatus={err && validateStatus}
                    >
                      <Input
                        value={carNo}
                        placeholder={"차량번호를 입력해주세요."}
                        onChange={(e) => {
                          setFieldValue("carNo", e.target.value);
                          setCarNo(e.target.value);
                          setIsSuccess(successType.NULL);
                        }}
                      />
                    </FormItem>
                    <TableDataActionButton
                      type={"primary"}
                      key={"확인"}
                      style={{
                        marginRight: "8px",
                      }}
                      onClick={() => carFormik.handleSubmit()}
                    >
                      {"확인"}
                    </TableDataActionButton>

                    <TextArea
                      value={carInfo}
                    />
                  </form>
                );
              }}
            </Formik>
          );
        },
      },
    ];

    const carConditionSection: Array<SectionFieldInfo> = [
      {
        type: FieldType.Custom,
        render: () => {
          return (
            <Formik
              enableReinitialize
              initialValues={initialCondsValues}
              validationSchema={createCondsValidationSchema}
              onSubmit={handleCondsSubmit}
              innerRef={(ref: FormikProps<FormikValues>) => {
                condsFormik = ref;
              }}
            >
              {(renderProps: FormikProps<FormikValues>) => {
                const { values } = renderProps;
                
                /**
                 * carAmt
                 * prodCondList - "prodCondList" - "cossCondCd"
                 * - "prodCondList" - "cossCondCd"
                 * - "modeCd": "A17002", // 약정주행거리
                 * - "modeCd": "A17003", // 운전자범위
                 * - "modeCd": "A17007", // 대물
                 */
                const {
                  // 보증금
                  depositRt,
                  // 약정 주행거리
                  prmsDtc,
                  // 운전자 범위
                  drvSoe,
                  // 대물
                  impsnl,
                } = values;

                return (
                  <form className="conditionForm">
                    {/* 약정주행거리 */}
                    <FormItem
                      label={commonStrings.LABEL_MILEAGE}
                      {...formItemLayout}
                      {...getFormError(renderProps, "prmsDtc")}
                    >
                      <Select
                        value={prmsDtc}
                        options={prmsDtcs}
                        onChange={(value) => {
                          handleChange(renderProps, "prmsDtc", value);
                        }}
                      />
                    </FormItem>

                    {/* 운전자범위 */}
                    <FormItem
                      label={commonStrings.LABEL_DRIVER_RANGE}
                      {...formItemLayout}
                      {...getFormError(renderProps, "drvSoe")}
                    >
                      <Select
                        value={drvSoe}
                        options={drvSoes}
                        onChange={(value) => {
                          handleChange(renderProps, "drvSoe", value);
                        }}
                      />
                    </FormItem>

                    {/* 대물 */}
                    <FormItem
                      label={commonStrings.LABEL_RENT_LAB_COV}
                      {...formItemLayout}
                      {...getFormError(renderProps, "impsnl")}
                    >
                      <Select
                        value={impsnl}
                        options={impsnls}
                        onChange={(value) => {
                          handleChange(renderProps, "impsnl", value);
                        }}
                      />
                    </FormItem>

                    {/* 보증금 */}
                    <FormItem
                      label={"보증금"}
                      {...formItemLayout}
                      {...getFormError(renderProps, "depositRt")}
                    >
                      <Select
                        value={depositRt}
                        options={deopositOptions}
                        onChange={(value) => {
                          handleChange(renderProps, "depositRt", value);
                        }}
                      />
                      <span className="carPrice">
                        {`(보증금 ${formatCurrency(Math.ceil((Number(carAmt) * depositRt / 100) / 1000) * 1000)})`}
                      </span>
                    </FormItem>
                    <div className="carButtonWrap">
                      <TableDataActionButton
                        type={"primary"}
                        key={"견적내기"}
                        style={{
                          marginRight: "8px",
                        }}
                        onClick={() => condsFormik.handleSubmit()}
                      >
                        {"견적내기"}
                      </TableDataActionButton>
                    </div>
                    
                  </form>
                );
              }}
            </Formik>
          );
        },
      },
    ];

    const gridSection: Array<SectionFieldInfo> = [
      {
        type: FieldType.Custom,
        render: () => {
          return (
            <ClientRowTable
              cols={createRentFeeCols()}
              rowData={rntFeeSetlist}
              width={600}
              height={320}
            />
          );
        },
      },
    ];

    return (
      <div>
        <DetailSection label="견적 차량" fieldInfos={carNoSection} />
        <DetailSection label="견적 조건" fieldInfos={carConditionSection} />
        <DetailSection label="견적 내용" fieldInfos={gridSection} />
      </div>
    );
  };

  return (
    <>
      <Modal
        size="large"
        title="견적 시뮬레이션"
        visible={visible}
        confirmLoading={confirmLoading}
        onCancel={() => {
          resetProds();
          setIsSuccess(successType.NULL);
          onCancel();
        }}
        footer={null}
        className="newStyle"
      >
        <NewFormStyledWrap>
          {renderContent()}
        </NewFormStyledWrap>
      </Modal>
    </>
  );
};

RentFeeSimulateModal.defaultProps = {
  onCancel: () => {},
  visible: false,
};

export default RentFeeSimulateModal;

const NewFormStyledWrap = styled.div`
  .carPrice{white-space:nowrap; display:inline-block;margin-left:10px;position:relative;top:4px;}
  .carButtonWrap{
    display: flex;
    width: 983px;
    justify-content: center;
    button{

    }
  }
  .estimateForm{
    display:flex;
    width: 912px;
    div{
      flex: none;
      flex-flow: initial;
      label{
        width: 134px;
      }
      .ant-col-18{
        width: 283px;
        margin-right: 10px;
        .ant-form-item-control-input-content{
          width: 100%;
        }
      }
    }
  }
  .conditionForm{
    div{
      flex: none;
      flex-flow: initial;
      label{
        width: 134px;
      }
      .ant-col-18{
        width: 212px;
        margin-right: 10px;
      }
    }
    div{
      .ant-col-18{
        width: 500px;
      }
    }
    .ant-select-selector{
      width: 100%;
      
    }
    .ant-form-item-control-input-content{
      width: 100%;
    }
  }
  .ant-form-item-control-input-content{display:flex;}
  .ant-col-6{
    width: auto;
    max-width: 100%;
    flex: 0 0 20%;
  }
  .ant-col-18{
    width: auto;
    max-width: 100%;
    flex: 0 0 80%;
  }
`
