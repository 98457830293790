import { plainToClass } from "class-transformer";
import FAQ from "../../models/FAQ";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { FAQAddParam, FAQEditParam } from "./types";
import FAQDetail from "../../models/FAQDetail";
import FAQCategory from "../../models/FAQCategory";

const apiRootUrl = `${ROOT_URL}/admin/custcnte/faqs`;

/**
 * FAQ 리스트 조회
 */
export const fetchFAQs = async (): Promise<ListItemResponse<FAQ>> => {
  const res = await request(HttpMethod.Get, apiRootUrl);

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;

  return {
    items: plainToClass(
      FAQ,
      (list.sort((a: any, b: any) => {
        if (a.mainYn === "Y" && (b.mainYn === "N" || !b.mainYn))
          return -1;
        return 0;
      }) as Array<JSON>) as Array<JSON>
    ),
    count: Number(listCnt),
  };
};

/**
 * FAQ 상세 조회
 */

export const fetchFAQDetail = async (faqNo: string): Promise<FAQDetail> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/${faqNo}`);
  const { data } = res;
  const { response } = data;
  return plainToClass(FAQDetail, response);
};

/**
 * FAQ 등록
 */
export const addFAQ = async (params: FAQAddParam) => {
  await request(
    HttpMethod.Post,
    apiRootUrl,
    params as Record<string, any>,
    undefined
  );
};

/**
 * FAQ 수정
 */
export const editFAQ = async (faqNo: string, params: FAQEditParam) => {
  await request(
    HttpMethod.Put,
    `${apiRootUrl}/${faqNo}`,
    params as Record<string, any>
  );
};

/**
 * FAQ 사용유무 수정
 */
export const editFAQUseYn = async (faqNo: string, isUsing: boolean) => {
  await request(
    HttpMethod.Put,
    `${apiRootUrl}/${faqNo}/${isUsing ? "Y" : "N"}`
  );
};

/**
 * FAQ 사용유무 수정
 */
export const fetchFAQCategories = async (): Promise<
  ListItemResponse<FAQCategory>
> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/codes`);
  const { data } = res;
  const { response } = data;
  const { list, listCnt } = response;

  return {
    items: plainToClass(FAQCategory, list as Array<JSON>),
    count: Number(listCnt),
  };
};
