import React, { useState } from "react";
import { ModalProps } from "../../../../types";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import {
  fetchCarManagerCustomers,
  fetchSMSTemplates,
  sendSMS,
} from "../../../../../apis/sms-template";
import SMSTemplate from "../../../../../models/SMSTemplate";
import Select from "../../../../../components/shared/data-entry/antd/Select";
import DetailSection, {
  FieldType,
} from "../../../../../components/shared/layout/DetailSection";
import useAuth from "../../../../../hooks/useAuth";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import Customer from "../../../../../models/Customer";
import TableLayout from "../../../../../components/shared/layout/TableLayout";
import { createTargetCustomerCols } from "./utils/table-utils";

interface Props extends ModalProps {}

const SMSSendFormModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [templates, setTemplates] = useState<SMSTemplate[]>([]);
  const [selectedSMSTemplate, setSelectedSMSTemplate] = useState<SMSTemplate>();
  const { user } = useAuth();
  const [customers, setCustomers] = useState<Customer[]>();
  const [customerCount, setCustomerCount] = useState(0);
  const [checkedRows, setCheckedRows] = useState<Array<Customer>>([]);

  const onModalOpen = async () => {
    setDataFetching(true);
    setSelectedSMSTemplate(undefined);
    setCheckedRows([]);
    await requestFetchSMSTemplates();
    await requestFetchUsers();
    setDataFetching(false);
  };

  /**
   * Private Functions
   */

  const getTemplateOptions = () => {
    return templates.map((template) => {
      return {
        label: template.smsTitle,
        value: template.smsMsgId,
      };
    });
  };

  const getModalTitle = () => {
    return "템플릿 문자 전송";
  };

  const requestFetchUsers = async () => {
    try {
      const { items, count } = await fetchCarManagerCustomers();
      setCustomers(items);
      setCustomerCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
      setCustomerCount(0);
      setCustomers([]);
    }
  };

  const requestSendSMS = async () => {
    try {
      setConfirmLoading(true);
      const params = checkedRows.map(({ custId }) => {
        return {
          chkYn: "Y",
          regId: user.userId,
          smsMsgId: selectedSMSTemplate?.smsMsgId || "",
          smsTitle: selectedSMSTemplate?.smsTitle || "",
          custId,
        };
      });

      await sendSMS(params);
      alertSuccess("문자 전송이 완료되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestFetchSMSTemplates = async () => {
    try {
      const { items } = await fetchSMSTemplates(1, 100);
      setTemplates(items);
    } catch (e) {
      alertError(getErrorMessage(e));
      setTemplates([]);
    }
  };

  /**
   * Event Actions
   */

  const handleCheckRowChanged = (rows: Array<Customer>) => {
    setCheckedRows(rows);
  };

  const handleModalOk = async () => {
    // 템플릿 미선택
    if (!selectedSMSTemplate) {
      alertError("템플릿을 선택해주세요");
    } else if (checkedRows.length === 0) {
      alertError("전송 대상 고객을 1명이상 선택해주세요");
    } else {
      await requestSendSMS();
    }
  };

  /**
   * Render Helpers
   */

  const renderForms = () => {
    return (
      <div>
        <DetailSection
          hideDivider={!selectedSMSTemplate}
          label="템플릿 정보"
          fieldInfos={[
            {
              label: "템플릿 명",
              type: FieldType.Custom,
              span: 24,
              content: selectedSMSTemplate?.smsTitle,
              render: () => {
                return (
                  <Select
                    style={{
                      width: "250px",
                    }}
                    options={getTemplateOptions()}
                    placeholder="메시지 템플릿을 선택해주세요"
                    value={selectedSMSTemplate?.smsMsgId}
                    onChange={(value) => {
                      setSelectedSMSTemplate(
                        templates.filter(
                          ({ smsMsgId }) => smsMsgId === value
                        )[0]
                      );
                    }}
                  />
                );
              },
            },
            {
              label: "템플릿 내용",
              span: 24,
              visible: !!selectedSMSTemplate,
              content: selectedSMSTemplate?.smsMsg,
            },
          ]}
        />
        {selectedSMSTemplate && (
          <div
            style={{
              height: "500px",
            }}
          >
            <TableLayout
              headerHorizontalPadding={0}
              hideExcelAction
              hideFilterAction
              title="발송 고객 선택"
              totalCount={customerCount}
              hideSelectionCountText={false}
              selectionCount={checkedRows.length}
              methodName="/admin/sms-user-info"
            >
              <ClientRowTable
                onCheckRowChanged={handleCheckRowChanged}
                cols={createTargetCustomerCols()}
                rowData={customers}
                floatingFilter={false}
              />
            </TableLayout>
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      title={getModalTitle()}
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      onOk={handleModalOk}
    >
      {dataFetching ? renderLoading() : renderForms()}
    </Modal>
  );
};

SMSSendFormModal.defaultProps = {};
export default SMSSendFormModal;
