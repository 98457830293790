class CustomerConsultingRequest {
  // 상품ID
  public prodId = "";

  // 서비스ID
  public srvcId = "";

  // 상품명
  public prodNm = "";

  // 상품상세
  public prodDesc = "";

  // 판매구분
  public ncarClsFlag = "";

  // 상담신청ID
  public cnslRecptId = "";

  // 상품ID
  public cnslCls = "";

  // 지능형상담유형
  public cnslClsNm = "";

  // 상담예약일시
  public cnslRsvDtm = "";

  // 회원ID
  public custId = "";

  // 이름
  public custNm = "";

  // 가입여부
  public custEntryYn = "";

  // 휴대폰번호
  public hpNo = "";

  // 마케팅활용동의
  public mktAgreYn = "";

  // 고객문의
  public custAsk = "";

  // 상담완료여부
  public cnslFinYn = "";

  // 상담사메모
  public cnslPicMemo = "";

  // 변경자
  public updUser = "";

  // 변경일시
  public updDtm = "";

  // 등록자
  public regUser = "";

  // 등록일시
  public regDtm = "";
}

export default CustomerConsultingRequest;
