import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import {
  createTagMasterCols,
  MOBILE_TAG_RENDERER,
  PC_TAG_RENDERER,
} from "./utils/table-utils";
import TableLayout from "../../../components/shared/layout/TableLayout";
import { alertError } from "../../../utils/render-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createActionCellRenderers } from "../../../utils/table-utils";
import TagMasterFormModal from "./pages/TagMasterFormModal";
import { fetchTagMasters } from "../../../apis/tag-master";
import TagMaster from "../../../models/TagMaster";
import {
  mobileTagRenderer,
  pcTagCellRenderer,
} from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

const CatalogTagManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const cols = createTagMasterCols();
  const [rows, setRows] = useState<Array<TagMaster>>();
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<TagMaster>();

  useEffect(() => {
    (async function fetchData() {
      await requestFetchTagMasters();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestFetchTagMasters = async () => {
    try {
      const { items, count } = await fetchTagMasters();
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      if (e.status !== 404) {
        if (e.getMessage) alertError(e.getMessage());
        else alertError(e.toString());
      }
      setRows([]);
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const tagMaster = data as TagMaster;
    setSelectedData(tagMaster);
    setModalVisible(true);
  };

  /**
   * Render Helpers
   */

  const createTableActions = () => {
    return [
      {
        title: "태그추가",
        onClick: () => {
          setSelectedData(undefined);
          setModalVisible(true);
        },
      },
    ];
  };

  return (
    <>
      <ContentLayout
        headerTitle="상품태그관리(신차장기)"
        description="신차장기 렌터카 상품의 노출될 태그를 관리합니다."
      >
        <TableLayout
          gridApi={gridApi}
          title="태그 리스트"
          totalCount={totalCount}
          hideSelectionCountText
          rightActions={createTableActions()}
          methodName="/admin/tag-master"
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            cols={cols}
            rowData={rows}
            frameworkComponents={{
              ...createActionCellRenderers(undefined, handleDetailClick, {
                hideDeleteButton: true,
              }),
              [PC_TAG_RENDERER]: pcTagCellRenderer,
              [MOBILE_TAG_RENDERER]: mobileTagRenderer,
            }}
          />
        </TableLayout>
        <TagMasterFormModal
          onDataChange={async () => {
            await requestFetchTagMasters();
          }}
          visible={modalVisible}
          tagMaster={selectedData}
          onCancel={() => {
            setModalVisible(false);
          }}
        />
      </ContentLayout>
    </>
  );
};

CatalogTagManager.defaultProps = {};
export default CatalogTagManager;
