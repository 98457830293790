import { useDispatch, useSelector } from "react-redux";
import { plainToClass } from "class-transformer";
import { useCallback } from "react";
import { setUser as setUserAction } from "../redux/actions/auth";
import User from "../models/User";
import { RootState } from "../redux/reducers";

export default function useAuth() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) =>
    plainToClass(User, state.auth.user)
  );
  const setUser = useCallback(
    (userParam?: User) => dispatch(setUserAction(userParam)),
    [dispatch]
  );

  return {
    setUser,
    user,
  };
}
