import React, { useState } from "react";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";

import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import {
  formatCurrency,
  formatNumber,
  getErrorMessage,
} from "../../../../../utils/common-utils";
import { ModalProps } from "../../../../types";

import { formatDateString } from "../../../../../utils/date-utils";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import {
  createTableCol,
  FilterType,
} from "../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_2X_LARGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../utils/table-utils";
import Button from "../../../../../components/shared/general/antd/Button";
import Image from "../../../../../components/shared/data-display/antd/Image";
import { getUcProdType } from "../../../../../constants/enums";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";

import {
  MOBILE_TAG_RENDERER,
  PC_TAG_RENDERER,
} from "../../../CatalogTagManager/utils/table-utils";
import {
  mobileTagRenderer,
  pcTagCellRenderer,
} from "../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { createCatalogTagCols } from "./utils/table-utils";
import McCarMainOptionModifyModal from "./pages/McCarMainOptionModifyModal";
import { fetchMcCatalogDetail,updateMcRentAmt } from "../../../../../apis/mc-catalogs";
import McCarCatalogDetail from "../../../../../models/McCarCatalogDetail";
import CatalogTagSetupModal from "./pages/CatalogTagSetupModal";
import TagPreviewModal from "./pages/TagPreviewModal";

interface Props extends ModalProps {
  dataId?: string;
}

const McCarCatalogModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, dataId, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [catalogDetail, setCatalogDetail] = useState<McCarCatalogDetail>();
  const [mainOptionListModalVisible, setMainOptionListModalVisible] = useState(false);
  const [tagSetupModalVisible, setTagSetupModalVisible] = useState(false);
  const [tagPreviewModalVisible, setTagPreviewModalVisible] = useState(false);

  const tagCols = createCatalogTagCols();

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchCatalogDetail();
    setDataFetching(false);
  };

  const handleTagSetupButtonClick = () => {
    setTagSetupModalVisible(true);
  };

  const handleTagPreviewButtonClick = () => {
    setTagPreviewModalVisible(true);
  };


  const handleMcRentAmt = async (
    prodId: string,
  ) => {
    try {
      await updateMcRentAmt(prodId);
      alertSuccess("기본렌탈료를 가져왔습니다.");

      setDataFetching(true);
      await requestFetchCatalogDetail();
      setDataFetching(false);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };


  /**
   * Private Functions
   */
  const requestFetchCatalogDetail = async () => {
    if (dataId) {
      try {
        let response = await fetchMcCatalogDetail(dataId);
        setCatalogDetail(response);
      } catch (e) {
        alertError(getErrorMessage(e));
        setCatalogDetail(undefined);
      }
    }
  };


  /**
   * Render Helpers
   */

  const renderColorChip = (
    colorName: string,
    colorImgUrl: string,
    cossColorNm: string
  ) => {
    return (
      <div>
        <div>
          {cossColorNm && (
            <div
              style={{
                marginBottom: "8px",
              }}
            >
              {cossColorNm}
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {colorImgUrl && (
              <img
                style={{
                  borderRadius: "9px",
                  marginRight: "8px",
                  overflow: "hidden",
                  display: "inline-block",
                }}
                width={18}
                height={18}
                src={colorImgUrl}
                alt=""
              />
            )}

            <span
              style={{
                color: "#626BFF",
              }}
            >
              {colorName}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (catalogDetail) {
      const {
        prodId,
        yearType,
        curTravelDtc,
        modeFuelNm,
        engdisp,
        seaterClsNm,
        grbxNm,
        regDt,
        carId,
        carNo,
        xtnlCarColorNm,
        xtnlColorImgUrl,
        innrCarColorNm,
        innrColorImgUrl,
        optNms = "",
        fixContList,
        carImgList = [],
        totOptAmt,
        prodNm,
        cossXtnlCarColorNm,
        cossInnrCarColorNm,
        ncarClsFlag,
        dtlTagList,
        mainOptionsList,
        rglRentAmt,
        cntr1mmRentAmtV,
        cntr3mmRentAmtV,
        cntr6mmRentAmtV,
        cntr9mmRentAmtV,
        cntr12mmRentAmtV,
      } = catalogDetail;

      /**
       * 차량정보
       */
      const carInfoSection = [
        {
          label: "차량이미지",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            if (carImgList && carImgList.length > 0) {
              return carImgList.map(({ fileUrl }) => {
                return (
                  <Image
                    style={{ marginRight: "8px" }}
                    key={fileUrl}
                    src={fileUrl}
                    width={102}
                    height={76}
                  />
                );
              });
            }
            return "-";
          },
        },
      ];

      /**
       * 태그 관리
       */
      const tagInfoSection: SectionFieldInfo[] = [
        {
          label: "",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <Button
                  style={{
                    marginRight: "8px",
                  }}
                  onClick={handleTagSetupButtonClick}
                >
                  설정
                </Button>
                <Button ghost onClick={handleTagPreviewButtonClick}>
                  미리보기
                </Button>
                <div
                  style={{
                    marginTop: "8px",
                    height: "300px",
                  }}
                >
                  <ClientRowTable
                    cols={tagCols}
                    rowData={dtlTagList || []}
                    frameworkComponents={{
                      [PC_TAG_RENDERER]: pcTagCellRenderer,
                      [MOBILE_TAG_RENDERER]: mobileTagRenderer,
                    }}
                  />
                </div>
              </div>
            );
          },
        },
      ];

      /**
       * 정비이력
       */
      const fixInfoSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div
                style={{
                  height: "260px",
                }}
              >
                <ClientRowTable
                  cols={[
                    createTableCol("inDt", "입고일", undefined, {
                      width: CW_MEDIUM,
                      cellRenderer: formatDateString("inDt"),
                    }),
                    createTableCol("dtlNm", "정비명", FilterType.Text, {
                      width: CW_LARGE,
                      flex: 1,
                    }),
                  ]}
                  rowData={fixContList}
                />
              </div>
            );
          },
        },
      ];

      /**
       * 기본정보
       */

      const basicInfoSection = [
        {
          label: "차량명",
          type: FieldType.Text,
          content: prodNm,
        },
        {
          label: "연식",
          type: FieldType.Text,
          content: yearType,
        },
        {
          label: "주행거리",
          type: FieldType.Custom,
          render: () => {
            return `${formatNumber(curTravelDtc)}km`;
          },
        },
        {
          label: "연료",
          type: FieldType.Text,
          content: modeFuelNm,
        },
        {
          label: "배기량",
          type: FieldType.Custom,
          render: () => {
            return `${formatNumber(engdisp)}cc`;
          },
        },
        {
          label: "인승",
          type: FieldType.Text,
          content: seaterClsNm,
        },
        {
          label: "변속기",
          type: FieldType.Text,
          content: grbxNm,
        },
        {
          label: "제조사 신차가격",
          type: FieldType.Custom,
          render: () => {
            return formatCurrency(totOptAmt);
          },
        },
        {
          label: "최초 등록일",
          type: FieldType.Custom,
          render: () => {
            return formatDateString(regDt);
          },
        },
        {
          label: "차량번호",
          type: FieldType.Text,
          content: carNo,
        },
        {
          type: FieldType.Custom,
          render: () => {
            return (
              <FormItem label="상품유형" labelCol={{ span: 24, offset: 0 }}>
                <>
                  <p>{getUcProdType(ncarClsFlag)}</p>
                </>
              </FormItem>
            );
          },
        },
      ];

   

      // 주요옵션
      const mainOptionListSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            /* 
              NOTE : B2C 월렌트화면에 뿌려질 주요옵션을 이곳(상품상세-옵션리스트)에서 필터링함  by 20230925 요구사항 from 이학진   
            */
            const whiteList = ["A60003", "A60004", "A60006","A60011","A60012", "A60013"];

            const filteredOptionList = mainOptionsList.filter(x => whiteList.includes(x.commCd as string));
            //console.log("filteredOptionList :"+ JSON.stringify(filteredOptionList));
            return (
              <div
                style={{
                  height: "550px",
                }}
              >
                <ClientRowTable
                  cols={[
                    createTableCol("commCd", "옵션코드", FilterType.Text, {
                      width: CW_SMALL,
                    }),
                    createTableCol("commCdNm", "옵션명", FilterType.Text, {
                      width: CW_2X_LARGE,
                    }),
                    createTableCol("useYn", "유무", FilterType.Text, {
                      width: CW_SMALL,
                      flex: 1,
                    }),
                  ]}
                  rowData={filteredOptionList}
                />
              </div>
            );
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                <>
                  <Button
                    onClick={() => {
                      setMainOptionListModalVisible(true);
                    }}
                  >
                    수정
                  </Button>
                </>
              </FormItem>
            );
          },
        },
      ];


      /**
       * 월 렌탈료
       */
       const rentAmtInfoSection = [
        {
          label: "표준 월렌탈료",
          type: FieldType.Text,
          content: Number(rglRentAmt).toLocaleString(),
        },
        {
          label: "1개월 렌트 월렌탈료",
          type: FieldType.Text,
          content: Number(cntr1mmRentAmtV).toLocaleString(),
        },
        {
          label: "3개월 렌트 월렌탈료",
          type: FieldType.Text,
          content: Number(cntr3mmRentAmtV).toLocaleString(),
        },
        {
          label: "6개월 렌트 월렌탈료",
          type: FieldType.Text,
          content: Number(cntr6mmRentAmtV).toLocaleString(),
        },
        {
          label: "9개월 렌트 월렌탈료",
          type: FieldType.Text,
          content: Number(cntr9mmRentAmtV).toLocaleString(),
        },
        {
          label: "12개월 렌트 월렌탈료",
          type: FieldType.Text,
          content: Number(cntr12mmRentAmtV).toLocaleString(),
        },
        {
          label: "월렌탈료 가져오기",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <Button onClick={() => { handleMcRentAmt(prodId); }}>가져오기</Button>
            );
          },
        },
      ];

      return (
        <div>
          <DetailSection label="기본정보" fieldInfos={basicInfoSection} />
          <DetailSection label="주요옵션" fieldInfos={mainOptionListSection} />
          <DetailSection label="기본렌탈료" fieldInfos={rentAmtInfoSection} />
          <DetailSection label="태그 관리" fieldInfos={tagInfoSection} />
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        onOpen={onModalOpen}
        visible={visible}
        onCancel={onCancel}
        title="상품 상세 정보"
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>

      <McCarMainOptionModifyModal
        visible={mainOptionListModalVisible}
        onCancel={() => {
          setMainOptionListModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <CatalogTagSetupModal
        visible={tagSetupModalVisible}
        onCancel={() => {
          setTagSetupModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          // if (onDataChange) onDataChange();
        }}
      />
      <TagPreviewModal
        visible={tagPreviewModalVisible}
        onCancel={() => {
          setTagPreviewModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        tagMasters={catalogDetail && (catalogDetail.dtlTagList || [])}
      />
    </>
  );
};

McCarCatalogModal.defaultProps = {
  onCancel: () => {},
  visible: false,
  dataId: undefined,
};
export default McCarCatalogModal;
