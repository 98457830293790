import React, { useState } from "react";

import { FormikProps, FormikValues } from "formik";

//types
import { ModalProps } from "../../../../../../types";

// apis
import { updateBusiness } from "@apis/customers";

// const
import { UPLOAD_CODES } from "@constants/enums";
import { ENUM_CORP_BUSINESS_CODE } from "@constants/enums";

// components
import Modal from "@components/shared/feedback/antd/Modal";
import FormBuilder from "@components/shared/data-entry/FormBuilder";
import FormItem from "@components/shared/data-entry/antd/FormItem";
import { FormElementType } from "@components/shared/data-entry/FormBuilder/types";
import UserInfoSearchModal, { UserInfo } from "./pages/UserInfoSearchModal";
import Button from "@components/shared/general/antd/Button";
import FileUpload from "@components/shared/data-entry/FileUpload";
import { BizEditInfo } from "../../types";

interface Props extends ModalProps {
  businessInfo: BizEditInfo;
}

/**
 * 사업자 정보 수정 모달
 * 
 * @param props 
 * @returns 
 */
const BusinessInfoEditModal: React.FC<Props> = (props: Props) => {
  const { visible, businessInfo, onCancel, onDataChange } = props;
  const [ isUserSearchModal, setIsUserSearchModal ] = useState(false);
  const [ initialValues, setInitialValues ] = useState<BizEditInfo>(businessInfo);
  const [ confirmLoading, setConfirmLoading ] = useState(false);
  const [ bizImage, setBizImage ] = useState<any>();

  // 이미지 업로드
  const renderBizImage = (renderProps: FormikProps<FormikValues>) => {
    const { values, setFieldValue } = renderProps;
    const value = values.regLicFileUrl;

    return (
      <>
        {value && <Button
          size="small"
          style={{
            marginRight: "8px",
          }}
          ghost
          onClick={() => {
            window.open(
              value,
              undefined,
              "width=1000,height=800"
            );
          }}
        >
          사업자 등록증 확인
        </Button>}
        <FileUpload
          buttonName="업로드"
          isNotCheckFileExt={true}
          fileList={bizImage}
          uploadCodeInfo={UPLOAD_CODES.BIZ_IMAGE}
          onFileChange={(fileList) => {
            setBizImage(fileList);
            if (confirmLoading) {
              setFieldValue("regLicFileUrl", fileList[0].imageUrl);
            }
          }}
          onUploadStateChange={(loading) => {
            setConfirmLoading(loading);
          }}
        />
      </>
    );
  }

  let formik: FormikProps<FormikValues>;
  const forms = [
    {
      key: "custNm",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue } = renderProps;
        const value = values.custNm;
        return (
          <FormItem
            key="custNm"
            label="대표자명"
            labelCol={{ lg: 5, xl: 5 }}
            style={{ marginBottom: "16px" }}
          >
            <Button
              size="small"
              ghost
              onClick={() => {
                setIsUserSearchModal(true);
              }}
            >
              {value}
            </Button>
          </FormItem>
        )
      },
    },
    {
      key: "bztp",
      type: FormElementType.Input,
      label: "업종",
      placeholder: "업종을 입력해주세요.",
    },
    {
      key: "bzcd",
      type: FormElementType.Input,
      label: "업태",
      placeholder: "업태를 입력해주세요.",
    },
    {
      key: "zip",
      type: FormElementType.Input,
      label: "우편번호",
      placeholder: "우편번호를 입력해주세요.",
    },
    {
      key: "addr",
      type: FormElementType.Input,
      label: "주소",
      placeholder: "주소를 입력해주세요.",
    },
    {
      key: "dtladdr",
      type: FormElementType.Input,
      label: "상세주소",
      placeholder: "상세주소를 입력해주세요.",
    },
    {
      key: "regLicFileUrl",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        return (
          <FormItem
            key="regLicFileUrl"
            label="사업자 등록증"
            labelCol={{ lg: 5, xl: 5 }}
          >
            {renderBizImage(renderProps)}
          </FormItem>
        )
      },
    },
  ];
  if (businessInfo.busrClsCd === ENUM_CORP_BUSINESS_CODE) {
    forms.splice(0, 0, {
      key: "corRegNo",
      type: FormElementType.Input,
      label: "법인번호",
      placeholder: "법인번호를 입력해주세요.",
    },);
  }

  /**
   * Private Functions
   */
  const handleSubmit = async (values: FormikValues) => {
    const { custId, corRegNo, zip, addr, dtladdr, bzcd, bztp, regLicFileUrl } = values;

    const params = {
      busrCustId: businessInfo.busrCustId,
      busrClsCd: businessInfo.busrClsCd,
      custId: custId,
      corRegNo: corRegNo,
      zip: zip,
      addr: addr,
      dtladdr: dtladdr,
      bzcd: bzcd,
      bztp: bztp,
      regLicFileUrl: regLicFileUrl
    };

    await requestUpdateBizInfo(params);
  };
  const requestUpdateBizInfo = async (bizInfo: BizEditInfo) => {
    await updateBusiness(bizInfo);
    if (onCancel) onCancel();
    if (onDataChange) onDataChange();
  }

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <Modal
      size="small"
      confirmLoading={confirmLoading}
      title="사업자정보 수정"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" type="default" onClick={onCancel}>
          취소
        </Button>,
        <Button key="submit" type="primary" onClick={() => {
          formik?.handleSubmit();
        }}>
          확인
        </Button>,
      ]}
    >
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
        forms={forms}
        onSubmit={handleSubmit}
      />
      <UserInfoSearchModal
        visible={isUserSearchModal}
        onSelect={(user: UserInfo ) => {
          setInitialValues({...initialValues, ...user});
        }}
        onCancel={() => setIsUserSearchModal(false)}
      />
    </Modal>
  );
};

BusinessInfoEditModal.defaultProps = {};
export default BusinessInfoEditModal;
