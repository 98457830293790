import ConsultingMaster from "./ConsultingMaster";
import ConsultingDetail from "./ConsultingDetail";
import ConsultingStatistics from "./ConsultingStatistics";

class ConsultingData {
  public aggrList: Array<ConsultingStatistics> = [];

  public aggrListCnt = 0;

  public mstList: Array<ConsultingMaster> = [];

  public mstListCnt = 0;

  public dtlList: Array<ConsultingDetail> = [];

  public dtlListCnt = 0;
}

export default ConsultingData;
