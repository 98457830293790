import React, { useRef, useState } from "react";
import {
  GridApi,
  LoadSuccessParams,
} from "ag-grid-community";
import moment from "moment";
import {
  createNewCarCatalogCols,
} from "./utils/table-utils";
import {
  fetchTagoTkvReqContracts,
} from "../../../apis/tb-tkvreq-contracts";
import PaginationTableLayout from "../../../components/shared/composition/PaginationTableLayout";
import {
  getPageIndex,
} from "../../../utils/ag-grid-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import { getTodayDateRange } from "../../../utils/date-utils";
import useAuth from "@hooks/useAuth";
import { setConnectLogs } from "@apis/logs";

const TagoTkvReqContractManager: React.FC = () => {
  const { user } = useAuth();
  const gridApiRef = useRef<GridApi>();
  const cols = createNewCarCatalogCols();
  const [totalCount, setTotalCount] = useState(0);
  const [logInfo, setLogInfo] = useState<string>("");

  const getInitialDateRange = () => {
    return getTodayDateRange();
  };

  const filterInitialValues = {
    stDt: getInitialDateRange(),
    custNm: "",
  };

  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "인수요청기간",
    },
    {
      key: "custNm",
      type: FilterElementType.Input,
      label: "고객명",
      placeholder: "고객명을 입력해주세요",
    },
  ];

  /**
   * Private Functions
   */

  const onDataLoad = async (
    success: (params: LoadSuccessParams) => void,
    fail: () => void,
    startRow: number,
    appliedFilterValues: any,
    paginationPageSize: number
  ) => {
    // 검색조건 설정
    const appliedFilters = JSON.stringify(appliedFilterValues);
    setLogInfo(appliedFilters);

    const {
      stDt,
      custNm,
    } = appliedFilterValues;
    const tkvReqStDt = moment(stDt[0]).format("YYYYMMDD");
    const tkvReqEndDt = moment(stDt[1]).format("YYYYMMDD");

    try {
      const { items, count } = await fetchTagoTkvReqContracts({
        pageIndex: getPageIndex(startRow, paginationPageSize),
        pageSize: paginationPageSize,
        tkvReqStDt,
        tkvReqEndDt,
        custNm,
      });
      setTotalCount(count);
      success({
        rowData: items,
        rowCount: count,
      });
      setConnectLogs({
        userId: user.userId,
        userIp: user.clientIp,
        methodNm: "/admin/tb-tkv-req-list",
        menuName: "견적/계약 - 인수요청관리(타고바이)",
        actionFlag: "SEARCH",
        reqCntn: appliedFilters
      });
    } catch (e) {      
      setTotalCount(0);
      success({
        rowData: [],
        rowCount: 0,
      });
    }
  };

  /**
   * Event Actions
   */
  
  
  /**
   * Render Helpers
   */

  const createTableActions = () => {
    return [];
  };

  return (
    <>
      <PaginationTableLayout
        tableHeaderTitle=""
        apiRef={(api) => {
          gridApiRef.current = api;
        }}
        headerTitle="인수요청관리(타고바이)" 
        headerDescription="타고바이 인수요청 정보를 조회합니다."
        totalCount={totalCount}
        hideFilterAction
        hideSelectionCountText 
        rightActions={createTableActions()}
        cols={cols}
        filterInitialValues={filterInitialValues}
        filterForms={filterForms}
        onDataLoad={onDataLoad}
        methodName="/admin/tb-tkv-req-list"
        logInfo={logInfo}
      />
    </>
  );
};

TagoTkvReqContractManager.defaultProps = {};
export default TagoTkvReqContractManager;
