import dompurify from "dompurify";
import { unescape } from "lodash";

class NoticeDetail {
  // 공지순번
  public noticeSeq = "";

  // 공지분류코드
  public noticeClfCd = "";

  // 공지번호
  public noticeNo = "";

  // 제목
  public titl = "";

  // 내용
  public contents = "";

  // 공지기간여부
  public noticeTermYn = "";

  // 공지 시작일
  public noticeStDtm = "";

  //   공지종료일시
  public noticeEndDtm = "";

  // 조회횟수
  public readCnt = "";

  // 사용여부
  public useYn = "";

  // 삭제여부
  public delYn = "";

  // 공지글 고정
  public noticeFixed = "";

  // 등록자ID
  public regUser = "";

  // 등록일시
  public regDtm = "";

  // 수정자ID
  public updUser = "";

  // 수정일시
  public updDtm = "";

  // 공지분류명
  public noticeClfNm = "";

  // 메인여부
  public mainYn = "";

  get htmlContent() {
    return dompurify.sanitize(unescape(this.contents));
  }
}
export default NoticeDetail;
