export default class StockInfoReport {
  // 제조사ID
  public carMakerId = "";

  // 제조사명
  public carMakerNm = "";

  // COSS모델ID
  public cossModlId = "";

  // COSS모델명
  public cossModlNm = "";

  // NICE모델
  public carClassNm = "";

  // NICE트림
  public carGradeNm = "";

  // 현재 수량
  public prodStock = "";

  // 입고 수량
  public inCnt = "";

  // 출고 수량
  public outCnt = "";

  // 견적건수
  public estCnt = "";

  // 계약건수
  public cntrCnt = "";

  // 평균보유일수
  public avgHldDays = "";
}
