import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_HEADER_GRADE_RATE_MANAGER: "보증보험 수수료율 관리",
    TABLE_HEADER_GRADE_RATE_LIST: "보증보험 수수료율 리스트",

    COL_REG_DATE: "등록일",
    COL_CATEGORY1: "대분류",
    COL_CATEGORY2: "중분류",
    COL_TITLE: "제목",
    COL_USE_YN: "사용유무",

    TITLE_ACTION_ADD: "신규 등록",
    TITLE_COST_CALCULATOR: "보증보험 수수료 산출",
    TITLE_ACTION_DELETE: "선택 삭제",

    CONFIRM_TITLE_DELETE: "보증보험 수수료율 삭제",
    CONFIRM_MESSAGE_DELETE: "해당 보증보험 수수료율을 삭제하시겠습니까?",

    MODAL_TITLE_ADD: "보증보험 수수료율 등록",
    MODAL_TITLE_DETAIL: "보증보험 수수료율 수정",
  },
});
