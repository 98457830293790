import styled from "styled-components";

export const ReportTotalSection = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ReportTotalWrapper = styled.div`
  position: relative;
  padding: 32px 40px;
  background: #fafafc;
  border-radius: 8px;
  margin-bottom: 14px;

  h3 {
    font-size: 22px;
    font-weight: 700;
  }

  ul {
    padding: 0;
    display: flex;
    align-items: center;

    li {
      list-style: none;

      &.bar {
        width: 1px;
        height: 17px;
        background-color: #77777a;
      }

      span {
        color: #6055eb;
      }

      :not(:last-child) {
        margin-right: 40px;
      }
    }
  }
`;

export const TotalCount = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  margin: 0;

  p {
    margin: 0;
    font-size: 15px;

    span,
    em {
      color: #6055eb;
    }
    span {
      font-size: 40px;
    }
    em {
      font-size: 24px;
    }
  }
`;

export const ReportTotalTableListWrapper = styled.ul`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 0;

  & > li {
    width: 33%;
    list-style: none;
    background: #fafafc;
    border-radius: 8px;

    & > header {
      padding: 34px 24px 24px;
      border-bottom: 1px solid #c4c5c9;

      & > h3 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 8px;
      }

      & > ul {
        display: flex;
        align-items: center;
        padding: 0;

        & > li {
          list-style: none;
          font-size: 16px;
          color: #19191e;
          margin-right: 16px;

          &.bar {
            width: 1px;
            height: 15px;
            background: #77777a;
          }

          &:nth-child(3) {
            margin-right: 24px;
          }
          &:last-child {
            color: #6055eb;
          }
        }
      }
    }

    & > div {
    }
  }
`;

export const TableWrapper = styled.div`
  padding: 0 24px 12px;

  table {
    width: 100%;
    font-size: 14px;

    th {
      color: #77777a;
      padding: 12px 0;

      &:last-child {
        text-align: right;
      }
    }

    td {
      padding: 12px 0;

      &:first-child {
        color: #19191e;
        font-weight: 700;
      }
      &:last-child {
        text-align: right;
      }
    }

    tbody {
      tr {
        &:last-child {
          td {
            font-size: 18px;
            font-weight: 700;
            color: #6055eb;
          }
        }
      }
    }
  }
`;
