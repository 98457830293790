import TableColumn, {
  createServerTableCol,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../../../utils/table-utils";
import strings from "../../../../../customer/CustomerManager/constants/strings";
import { CR_STATUS_TEXT } from "../../../../../../components/shared/data-display/Tables/components/ServerRowTable";

export const createSendHistoryCustomerCols = (): Array<TableColumn> => {
  return [
    // 회원명
    createServerTableCol("custNm", strings.COL_NAME, {
      minWidth: CW_SMALL,
      flex: 1,
    }),
    // 휴대폰번호
    createServerTableCol("hpNo", strings.COL_HP_NO, {
      width: CW_MEDIUM,
    }),
    // 발송유무
    createServerTableCol("sndChk", "발송유무", {
      minWidth: CW_SMALL,
      flex: 1,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "발송완료" : "미발송",
          },
        };
      },
    }),
    // 마케팅 활용 동의
    createServerTableCol("mktAgreYn", strings.COL_MARKETING_AGREEMENT, {
      width: CW_MEDIUM,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "동의" : "미동의",
          },
        };
      },
    }),
    // 담당카매니저
    createServerTableCol("carmanNm", strings.COL_CAR_MANAGER, {
      width: CW_MEDIUM,
    }),
    // 사업자명
    createServerTableCol("busrCustNm", strings.COL_BIZ_NAME, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
    // 회원구분
    createServerTableCol("custCls", strings.COL_CUSTOMER_KIND, {
      width: CW_SMALL,
    }),
    // 본인인증 여부
    createServerTableCol("ciYn", strings.COL_IS_PASS_AUTHED, {
      width: CW_SMALL_M,
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            active: value === "Y",
            text: value === "Y" ? "완료" : "미완료",
          },
        };
      },
    }),
    // 사업자구분
    createServerTableCol("busrClsNm", strings.COL_BIZ_KIND, {
      width: CW_LARGE,
    }),
  ];
};
