/* eslint-disable import/no-cycle */
import React, { ReactNode, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ICellRendererParams } from "ag-grid-community";
import { CopyTwoTone } from "@ant-design/icons";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import Button from "../../../../../components/shared/general/antd/Button";
import {
  formatCurrency,
  formatNumber,
  getErrorMessage,
} from "../../../../../utils/common-utils";
import RentContractDetail from "../../../../../models/RentContractDetail";
import TabLayout from "../../../../../components/shared/layout/TabLayout";
import {
  fetchRentContractDetail,
  fetchSingleContractUpdateRequestList,
  updateUcTagobuyCntrTkvReq,
} from "../../../../../apis/rent-contracts";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createCols, createMemoCols } from "./utils/table-utils";
import ContractUpdateRequest from "../../../../../models/ContractUpdateRequest";
import CustomerModal from "../../../../customer/CustomerManager/pages/CustomerModal";
import { formatDateString } from "../../../../../utils/date-utils";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import ModalTableWrapper from "../../../../../components/shared/layout/ModalTableWrapper";
import MemoFormModal from "./pages/MemoFormModal";
import RentContractMemo from "../../../../../models/RentContractMemo";
import { createActionCellRenderers } from "../../../../../utils/table-utils";
import CSUserSetupModal from "./pages/CSUserSetupModal";
import { ModalProps } from "../../../../types";
import CarManagerSetupModal from "./pages/CarManagerSetupModal";
import DirectPickupModal from "./pages/DirectPickupModal";

interface Props extends ModalProps {
  visible: boolean;
  onCancel?: () => void;
  cntrId?: string;
  cntrSeq?: string;
  disableCustomerSection?: boolean;
}

const RentContractDetailModal: React.FC<Props> = (props: Props) => {
  const {
    visible,
    onCancel,
    cntrId,
    cntrSeq,
    disableCustomerSection = false,
    onDataChange,
  } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [rentContract, setRentContractDetail] = useState<RentContractDetail>();
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const [custId, setCustId] = useState<string>("");
  const [rows, setRows] = useState<Array<ContractUpdateRequest>>();

  const [memoModalVisible, setMemoModalVisible] = useState(false);
  const [selectedMemo, setSelectedMemo] = useState<RentContractMemo>();
  const [csUserSetupModalVisible, setCsUserSetupModalVisible] = useState(false);
  const [csUserId, setCsUserId] = useState<string>();
  const [
    carManagerSetupModalVisible,
    setCarManagerSetupModalVisible,
  ] = useState(false);
  // 직접인수 여부 설정 모달
  const [directPickupModalVisible, setDirectPickupModalVisible] = useState(
    false
  );

  const onModalOpen = async () => {
    if (cntrId && cntrSeq) {
      setDataFetching(true);
      const contractDetail = await requestFetchRentContractDetail(
        cntrId,
        cntrSeq
      );
      await requestFetchRentContractUpdateHistory(cntrId, cntrSeq);
      setRentContractDetail(contractDetail);
      setDataFetching(false);
    } else {
      setRentContractDetail(undefined);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchRentContractDetail = async (
    cntrId: string,
    cntrSeq: string
  ) => {
    try {
      return await fetchRentContractDetail(cntrId, cntrSeq);
    } catch (e) {
      alertError(getErrorMessage(e));
      return undefined;
    }
  };

  const requestFetchRentContractUpdateHistory = async (
    cntrId: string,
    cntrSeq: string
  ) => {
    try {
      const { items } = await fetchSingleContractUpdateRequestList(
        cntrId,
        cntrSeq
      );
      setRows(items);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const updateRentContractDetail = async () => {
    if (cntrId && cntrSeq) {
      const contractDetail = await requestFetchRentContractDetail(
        cntrId,
        cntrSeq
      );
      setRentContractDetail(contractDetail);
      if (onDataChange) onDataChange();
    }
  };

  /**
   * Event Actions
   */

  const handleEditClick = (props: ICellRendererParams) => {
    const { data } = props;
    const memo = data as RentContractMemo;
    setSelectedMemo(memo);
    setMemoModalVisible(true);
  };

  // 타고바이(TAGOBUY) - 인수 요청 취소 업데이트
  const putUcTagobuyCntrTkvReq = async (cntrId: string, cntrSeq: string, tkvReqYn: string) => { 
    try {
      await updateUcTagobuyCntrTkvReq(cntrId, cntrSeq, tkvReqYn);
      const contractDetail = await requestFetchRentContractDetail(
        cntrId,
        cntrSeq
      );
      setRentContractDetail(contractDetail);
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Render Helpers
   */

  const renderClipboardLabel = (text: string, link?: string): ReactNode => {
    if (link) {
      return (
        <div>
          <span
            style={{
              marginRight: "8px",
            }}
          >
            {text}
          </span>
          <CopyToClipboard
            text={link}
            onCopy={() => {
              alertSuccess(`클립보드에 복사되었습니다. ${link} `);
            }}
          >
            <CopyTwoTone twoToneColor="#626DFF" />
          </CopyToClipboard>
        </div>
      );
    }

    return <span>{text}</span>;
  };

  const renderContractDetailTabContent = () => {
    if (rentContract) {
      const extraDriver1Visible = !!rentContract.addDrvNm1;
      const extraDriver2Visible = !!rentContract.addDrvNm2;

      // 회원정보
      const customerInfoSection: Array<SectionFieldInfo> = [
        {
          label: "고객명",
          type: FieldType.Custom,
          render: () => {
            if (disableCustomerSection) return rentContract.custNm;
            return (
              <Button
                type="default"
                onClick={() => {
                  setCustId(rentContract?.custId);
                  setCustomerModalVisible(true);
                }}
              >
                {rentContract.custNm}
              </Button>
            );
          },
        },
      ];

      const isTagoPay = !!rentContract.amtCondCd;
      const isUC = !!rentContract.tkvAmtRtFlag;

      // 할인정보
      const dcList: SectionFieldInfo[] = rentContract.odsDcList.map(
        ({ odsDcAmt, odsDcNm }) => {
          return {
            label: odsDcNm,
            type: FieldType.Text,
            content: formatCurrency(odsDcAmt),
            visible: !isTagoPay,
          };
        }
      );

      const tkvRtrnYnNmVisible =
        !isTagoPay && !isUC && !!rentContract.tkvRtrnYnNm;
      // 계약정보
      const contractInfoSection: SectionFieldInfo[] = [
        /**
         * 계약정보 섹션
         */
        {
          label: "계약정보",
          type: FieldType.LabelOnly,
          content: "",
        },
        {
          type: FieldType.Custom,
          render: () => {
            return (
              <FormItem
                label={renderClipboardLabel(
                  "차량이용 확인서",
                  rentContract?.cfmLinkUrl
                )}
                labelCol={{ span: 24, offset: 0 }}
              >
                {rentContract.cfmLinkUrl ? (
                  <Button
                    onClick={() => {
                      window.open(rentContract?.cfmLinkUrl);
                    }}
                  >
                    다운로드
                  </Button>
                ) : (
                  "-"
                )}
              </FormItem>
            );
          },
        },
        {
          type: FieldType.Custom,
          render: () => {
            return (
              <FormItem
                label={renderClipboardLabel(
                  "계약서",
                  rentContract?.cntrLinkUrl
                )}
                labelCol={{ span: 24, offset: 0 }}
              >
                {rentContract.cntrLinkUrl ? (
                  <Button
                    onClick={() => {
                      window.open(rentContract?.cntrLinkUrl);
                    }}
                  >
                    다운로드
                  </Button>
                ) : (
                  "-"
                )}
              </FormItem>
            );
          },
        },
        {
          type: FieldType.Divider,
        },
        {
          label: "임차인정보",
          type: FieldType.LabelOnly,
        },
        {
          label: "계약유형",
          type: FieldType.Text,
          content: rentContract.custClsNm,
        },
        {
          label: "계약번호",
          type: FieldType.Text,
          content: rentContract.cntrNo,
        },
        {
          label: "담당 카매니저",
          type: FieldType.Custom,
          content: rentContract.carmanNm,
          render: () => {
            // 배송지입력 + 카매니저 없을경우
            if (rentContract.canAssignCarManager) {
              return (
                <Button
                  onClick={() => {
                    setCarManagerSetupModalVisible(true);
                  }}
                >
                  카매니저 배정
                </Button>
              );
            }
            return (
              <div
                style={{
                  color: "#9698a8",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                {rentContract.carmanNm || "-"}
              </div>
            );
          },
        },
        {
          label: "담당 카매니저 전화번호",
          type: FieldType.Text,
          content: rentContract.carmanHpNo,
        },
        {
          label: "이름",
          type: FieldType.Text,
          content: rentContract.custNm,
        },
        {
          label: "성별",
          type: FieldType.Text,
          content: rentContract.gndr,
        },
        {
          label: "생년월일",
          type: FieldType.Text,
          content: rentContract.brthdt,
        },
        {
          label: "면허종별",
          type: FieldType.Text,
          content: rentContract.drvingLcnsKndNm,
        },
        {
          label: "면허번호",
          type: FieldType.Text,
          content: rentContract.drvingLcnsNo,
        },
        {
          label: "면허취득일",
          type: FieldType.Text,
          content: rentContract.drvingLcnsGetDt,
        },
        {
          label: "자택주소",
          type: FieldType.Text,
          content: rentContract.addr,
        },
        {
          label: "배송지주소",
          type: FieldType.Text,
          content: rentContract.poaAddr,
        },
        {
          label: "직접인수 선택",
          type: FieldType.Custom,
          content: rentContract.dpRequestUser,
          render: () => {
            return (
              <div>
                {rentContract.dpRequestUser}
                {/* 배송지 입력상태일때만 노출 */}
                {rentContract.mpCntrStsCd === "A41400" && (
                  <div style={{ marginTop: "10px" }}>
                    <Button
                      onClick={() => {
                        setDirectPickupModalVisible(true);
                      }}
                    >
                      설정
                    </Button>
                  </div>
                )}
              </div>
            );
          },
        },
        /**
         * 차량정보 섹션
         */
        {
          type: FieldType.Divider,
        },
        {
          label: "차량정보",
          type: FieldType.LabelOnly,
        },
        {
          label: "모델",
          type: FieldType.Text,
          content: rentContract.repCartypeNm,
        },
        {
          label: "세부모델",
          type: FieldType.Text,
          content: rentContract.carClassNm,
        },
        {
          label: "트림",
          type: FieldType.Text,
          content: rentContract.carGradeNm,
        },
        {
          label: "내장색상",
          type: FieldType.Text,
          content: rentContract.innrCarColorNm,
        },
        {
          label: "외장색상",
          type: FieldType.Text,
          content: rentContract.xtnlCarColorNm,
        },
        {
          label: "추가옵션",
          type: FieldType.Text,
          content: rentContract.carOpt1,
        },        
        {
          label: "연료",
          type: FieldType.Text,
          content: rentContract.fuelNm,
        },
        {
          label: "배기량",
          type: FieldType.Custom,
          visible: isUC,
          render: () => {
            return `${formatNumber(rentContract.engdisp)}cc`;
          },
        },
        {
          label: "연식",
          type: FieldType.Text,
          visible: isUC,
          content: rentContract.yearType,
        },
        {
          label: "계약시점차령",
          type: FieldType.Text,
          visible: isUC,
          content: rentContract.carAge,
        },
        {
          label: "주행거리",
          type: FieldType.Custom,
          visible: isUC,
          render: () => {
            return `${formatNumber(rentContract.curTravelDtc)}km`;
          },
        },
        {
          label: "계약시점주행거리",
          type: FieldType.Custom,
          visible: isUC,
          render: () => {
            return `${formatNumber(rentContract.cntrStTravelDtc)}km`;
          },
        },        
        {
          label: "최초 등록일",
          type: FieldType.Custom,
          visible: isUC,
          render: () => {
            return formatDateString(rentContract.regDt);
          },
        },
        {
          label: "인승",
          type: FieldType.Text,
          visible: isUC,
          content: rentContract.seaterClsNm,
        },        
        {
          label: "썬팅(전면)",
          type: FieldType.Text,
          content: `${rentContract.suntMakerNm2}(${rentContract.suntConc2}%)`,
        },
        {
          label: "썬팅(측,후면)",
          type: FieldType.Text,
          content: `${rentContract.suntMakerNm1}(${rentContract.suntConc1}%)`,
        },
        {
          label: "차량번호",
          type: FieldType.Text,
          content: rentContract.carNo,
        },
        {
          label: "차량가격",
          type: FieldType.Text,
          content: formatCurrency(rentContract.totCarAmt),
        },
        /**
         * 렌트조건 섹션
         */
        {
          type: FieldType.Divider,
        },
        {
          label: "렌트조건",
          type: FieldType.LabelOnly,
        },
        {
          label: "계약기간",
          type: FieldType.Text,
          content: rentContract.cntrTermMm,
        },
        {
          label: "약정주행거리",
          type: FieldType.Text,
          content: rentContract.prmsDtcClsNm,
        },
        {
          label: "운전자 범위",
          type: FieldType.Text,
          content: rentContract.drvSoeNm,
        },
        {
          label: "운전자 연령",
          type: FieldType.Text,
          content: rentContract.drvingAgeNm,
        },
        {
          label: "대물 배상",
          type: FieldType.Text,
          content: rentContract.impsnlNm,
        },
        {
          label: "표준 렌탈료",
          type: FieldType.Text,
          content: formatCurrency(rentContract.rglRentAmt),
          visible: !isTagoPay,
        },
        ...dcList,
        {
          label: "월 렌탈료",
          type: FieldType.Text,
          content: formatCurrency(rentContract.mmRentAmt2),
          visible: !isTagoPay,
        },
        {
          label: "실 납입금",
          type: FieldType.Text,
          content: formatCurrency(rentContract.realPaymentAmt),
          visible: !isTagoPay,
        },
        {
          label: "요금제",
          type: FieldType.Text,
          content: rentContract.amtCondNm,
          visible: isTagoPay,
        },
        {
          label: "요금제",
          type: FieldType.Custom,
          content: rentContract.amtCondNm,
          visible: isUC,
          render: () => {
            if (rentContract.tkvAmtRtFlag === "H") return "렌탈료 SAVE";
            if (rentContract.tkvAmtRtFlag === "L") return "인수가 SAVE";
            return "-";
          },
        },
        {
          label: "월 기본료",
          type: FieldType.Text,
          content: formatCurrency(rentContract.stdRentAmt),
          visible: isTagoPay,
        },
        {
          label: "주행거리 요금",
          type: FieldType.Text,
          content: `${formatCurrency(rentContract.amtPerMile)}/km`,
          visible: isTagoPay,
        },
        {
          label: "초기 납부 조건",
          type: FieldType.Text,
          content: rentContract.rentInsuranceInfoText,
        },
        {
          label: "납부 완료 금액",
          type: FieldType.Text,
          content: rentContract.rentInsurancePaymentInfoText,
        },
        {
          label: "추가운전자 1",
          type: FieldType.LabelOnly,
          visible: extraDriver1Visible,
        },
        {
          label: "운전자명",
          type: FieldType.Text,
          content: rentContract.addDrvNm1,
          visible: extraDriver1Visible,
        },
        {
          label: "생년월일",
          type: FieldType.Text,
          content: rentContract.addDrvBrthdt1,
          visible: extraDriver1Visible,
        },
        {
          label: "면허종별",
          type: FieldType.Text,
          content: rentContract.addDrvDrvingLcnsKndNm1,
          visible: extraDriver1Visible,
        },
        {
          label: "면허번호",
          type: FieldType.Text,
          content: rentContract.addDrvDrvingLcnsNo1,
          visible: extraDriver1Visible,
        },
        {
          label: "임차인과의 관계",
          type: FieldType.Text,
          content: rentContract.addDrvRelClsNm1,
          visible: extraDriver1Visible,
        },
        {
          label: "추가운전자 2",
          type: FieldType.LabelOnly,
          visible: extraDriver2Visible,
        },
        {
          label: "운전자명",
          type: FieldType.Text,
          content: rentContract.addDrvNm2,
          visible: extraDriver2Visible,
        },
        {
          label: "생년월일",
          type: FieldType.Text,
          content: rentContract.addDrvBrthdt2,
          visible: extraDriver2Visible,
        },
        {
          label: "면허종별",
          type: FieldType.Text,
          content: rentContract.addDrvDrvingLcnsKndNm2,
          visible: extraDriver2Visible,
        },
        {
          label: "면허번호",
          type: FieldType.Text,
          content: rentContract.addDrvDrvingLcnsNo2,
          visible: extraDriver2Visible,
        },
        {
          label: "임차인과의 관계",
          type: FieldType.Text,
          content: rentContract.addDrvRelClsNm2,
          visible: extraDriver2Visible,
        },
        {
          label: "인수옵션",
          type: FieldType.Text,
          content: rentContract.tkvRtrnYnNm,
          visible: tkvRtrnYnNmVisible,
        },
        /**
         * 결제정보 섹션
         */
        {
          type: FieldType.Divider,
        },
        {
          label: "결제정보",
          type: FieldType.LabelOnly,
        },
        {
          label: "렌탈료 결제방법",
          type: FieldType.Text,
          content: rentContract.stmtMeansNm,
        },
        {
          label: "은행명",
          type: FieldType.Text,
          content: rentContract.bankNm,
        },
        {
          label: "계좌번호",
          type: FieldType.Text,
          content: rentContract.custAcntNo,
        },
        {
          label: "결제일",
          type: FieldType.Text,
          content: rentContract.paymentDayText,
        },
        {
          label: "카드사",
          type: FieldType.Text,
          content: rentContract.cardCoNm,
        },

        /**
         * 발주정보 섹션
         */
        {
          type: FieldType.Divider,
        },
        {
          label: "발주정보",
          type: FieldType.LabelOnly,
        },
        {
          label: "제조사 계약번호",
          type: FieldType.Text,
          content: rentContract.carCntrNo,
        },
        {
          label: "생산예정일",
          type: FieldType.Text,
          content: formatDateString(rentContract.mnftIntendDt),
        },
        {
          label: "출고가능일",
          type: FieldType.Text,
          content: formatDateString(rentContract.outIntendDt),
        },
        {
          label: "출고확정일",
          type: FieldType.Text,
          content: formatDateString(rentContract.outDcsnDt),
        },
        {
          label: "출문일",
          type: FieldType.Text,
          content: formatDateString(rentContract.googDt),
        },
        {
          label: "1차 탁송도착일",
          type: FieldType.Text,
          content: formatDateString(rentContract.consi1ArvDt),
        },
        /**
         * 계약진행상태 섹션
         */
        {
          type: FieldType.Divider,
        },
        {
          label: "계약진행상태",
          type: FieldType.LabelOnly,
          content: "",
        },
        {
          label: "계약일시",
          type: FieldType.Text,
          content:
            rentContract.cntrDtm &&
            formatDateString(rentContract.cntrDtm, true),
        },
        {
          label: "계약취소일",
          type: FieldType.Text,
          content: rentContract.cnclDt && formatDateString(rentContract.cnclDt),
        },
        {
          label: "계약상태",
          type: FieldType.Text,
          content: rentContract.mpCntrStsNm,
        },
        {
          type: FieldType.Divider,
        },
         /**
         * 타고바이 섹션
         */
        {
          label: "타고바이",
          type: FieldType.LabelOnly,
          content: "",
        },
        {
          label: "타고바이 계약 여부",
          type: FieldType.Text,
          content: rentContract.tgbyYn,
        },
        {
          label: "상품유형",
          type: FieldType.Custom,
          content: rentContract.tbProductGbn,
          render: () => {
            if (rentContract.tbProductGbn === "A65001") return "바로 인수";
            if (rentContract.tbProductGbn === "A65002") return "타보고 인수";
            return "-";
          },
        },
        {
          label: "인수요청",
          type: FieldType.Text,
          content: rentContract.tkvReqYn,
        },
        {
          label: "인수요청일자",
          type: FieldType.Text,
          content: formatDateString(rentContract.tkvReqDtm),
        },
        {
          label: "차량 인수가",
          type: FieldType.Text,
          content: formatCurrency(rentContract.carTkvAmt),
        },
        {
          label: "누적일수",
          type: FieldType.Text,
          content: formatNumber(rentContract.accumDays),
        },
        {
          label: "미래가치계수",
          type: FieldType.Text,
          content: rentContract.ceft,
        },
        {
          label: "중도 인수가",
          type: FieldType.Text,
          content: formatCurrency(rentContract.mdlTkvAmt),
        },
        {
          label: "미확정 금액",
          type: FieldType.Text,
          content: formatCurrency(rentContract.undcAmt),
        },
        {
          label: "매출 완료 렌탈료",
          type: FieldType.Text,
          content: formatCurrency(rentContract.sellCompRentAmt),
        },
        {
          label: "COSS 인수 금액",
          type: FieldType.Text,
          content: formatCurrency(rentContract.cossTkvAmt),
        },
        {
          label: "초기 납부 조건",
          type: FieldType.Text,
          content: rentContract.rentInsuranceInfoText,
        },
        {
          label: "납부 완료 금액",
          type: FieldType.Text,
          content: rentContract.rentInsurancePaymentInfoText,
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            if(rentContract.tkvReqYn === "Y") {
              return (
                <FormItem>
                  {
                    <>
                      <Button
                        onClick={() => {
                          putUcTagobuyCntrTkvReq(cntrId as string, cntrSeq as string, "N");
                        }}
                      >
                        인수요청취소
                      </Button>
                    </>
                  }
                </FormItem>
              )
            }
          },
        }
      ];
      return (
        <div>
          <DetailSection label="회원정보" fieldInfos={customerInfoSection} />
          <DetailSection
            label="계약정보"
            fieldInfos={contractInfoSection}
            hideDivider
          />
        </div>
      );
    }

    return "";
  };

  const renderContractEditingHistoryTabContent = () => {
    return (
      <div
        style={{
          height: "500px",
        }}
      >
        <ClientRowTable cols={createCols()} rowData={rows} />
      </div>
    );
  };

  const renderCounselHistoryTabContent = () => {
    if (rentContract) {
      const { cnslUserId, cnslUserNm, cnslEndYn } = rentContract;
      const cnslUserSet = !!cnslUserId;
      
      const csUserInfoSection: Array<SectionFieldInfo> = [
        {
          label: "",
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <div
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  {cnslUserSet ? cnslUserNm : "미배정"}
                </div>
                <Button
                  type="primary"
                  onClick={() => {
                    if (cnslUserSet) {
                      setCsUserId(cnslUserId);
                      setCsUserSetupModalVisible(true);
                    } else {
                      setCsUserId(undefined);
                      setCsUserSetupModalVisible(true);
                    }
                  }}
                >
                  {cnslUserSet ? "상담원 변경" : "상담원 배정"}
                </Button>
              </div>
            );
          },
        },
      ];

      const csCompleteSection: Array<SectionFieldInfo> = [
        {
          label: "",
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <div
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  {(cnslEndYn==="Y") ? "완료" : "미완료"}
                </div>
              </div>
            );
          },
        },
      ];

      return (
        <div>
          <DetailSection label="계약상담원" fieldInfos={csUserInfoSection} />
          <DetailSection label="상담완료여부" fieldInfos={csCompleteSection} />
          <ModalTableWrapper
            headerTitle="상담이력"
            style={{
              position: "relative",
            }}
          >
            <ClientRowTable
              height={420}
              cols={createMemoCols()}
              rowData={rentContract?.cntrMemoList || []}
              frameworkComponents={{
                ...createActionCellRenderers(undefined, handleEditClick, {
                  hideDeleteButton: true,
                }),
              }}
            />
            <Button
              style={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
              onClick={() => {
                setSelectedMemo(undefined);
                setMemoModalVisible(true);
              }}
            >
              메모 등록
            </Button>
          </ModalTableWrapper>
        </div>
      );
    }

    return "";
  };

  const renderTabs = () => {
    const tabs = [
      {
        title: "계약상세정보",
        key: "1",
        content: renderContractDetailTabContent(),
      },
      {
        title: "계약변경이력",
        key: "2",
        content: renderContractEditingHistoryTabContent(),
      },
      {
        title: "계약상담",
        key: "3",
        content: renderCounselHistoryTabContent(),
      },
    ];

    return (
      <div>
        <TabLayout tabInfos={tabs} />
      </div>
    );
  };

  return (
    <>
      <Modal
        size="large"
        title="계약정보 상세"
        visible={visible}
        onCancel={onCancel}
        onOpen={onModalOpen}
        footer={null}
      >
        {dataFetching ? renderLoading() : renderTabs()}
      </Modal>
      <MemoFormModal
        visible={memoModalVisible}
        onCancel={() => {
          setMemoModalVisible(false);
        }}
        memo={selectedMemo}
        onDataChange={async () => {
          await updateRentContractDetail();
        }}
        rentContractDetail={rentContract}
      />
      <CSUserSetupModal
        visible={csUserSetupModalVisible}
        onCancel={() => {
          setCsUserSetupModalVisible(false);
        }}
        originCnslUserId={csUserId}
        onDataChange={async () => {
          await updateRentContractDetail();
        }}
        rentContractDetail={rentContract}
      />
      <CarManagerSetupModal
        visible={carManagerSetupModalVisible}
        onCancel={() => {
          setCarManagerSetupModalVisible(false);
        }}
        onDataChange={async () => {
          await updateRentContractDetail();
        }}
        rentContractDetail={rentContract}
      />
      <DirectPickupModal
        visible={directPickupModalVisible}
        onCancel={() => {
          setDirectPickupModalVisible(false);
        }}
        onDataChange={async () => {
          await updateRentContractDetail();
        }}
        rentContractDetail={rentContract}
      />
      {!disableCustomerSection && (
        <CustomerModal
          editable
          cntrId={cntrId}
          cntrSeq={Number(cntrSeq)}
          id={custId}
          onCancel={() => {
            setCustomerModalVisible(false);
          }}
          visible={customerModalVisible}
        />
      )}
    </>
  );
};

RentContractDetailModal.defaultProps = {
  onCancel: () => {},
  visible: false,
};

export default RentContractDetailModal;
