import styled from "styled-components";

export const TableLayoutWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: hidden;
`;

export const TableContentWrapper = styled.div`
  padding: 24px;
  height: calc(100% - 108px);
`;

export const TableFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 16px;
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

export const HighLightText = styled.span`
  margin-left: 8px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const TableHeaderWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.common.colors.border};
  height: 64px;
`;

export const TableHeaderTitleWrapper = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 18px;
`;

export const RightPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`;

export const AddStatisticsWrapper = styled.div`
  background: white;
  overflow: hidden;
  margin-bottom: 24px;

  & > header {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 24px;
    border-bottom: 1px solid #eeeff5;
    box-sizing: border-box;

    & > h2 {
      font-weight: bold;
      font-size: 18px;
    }
  }

  & > main {
    padding: 28px 24px 16px;
    height: calc(100% - 64px);
    box-sizing: border-box;
  }
`;
