class NiceCarInfo {
  // MODE 등급 ID
  public modeGrdId = "";

  // NICE 등급 ID
  public carGradeNbr = "";

  // NICE 등급명
  public carGradeNm = "";

  // 대표차종명
  public repCarClassNm = "";

  // 연형
  public yearType = "";

  // 장착변속기
  public istdTrans = "";

  // NICE 차량가격
  public carGradePrice = 0;

  // 이미지
  public repImageUrl = "";
}

export default NiceCarInfo;
