import React, { useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";
import { 
  selectUcCooperCompanyList,
} from "../../../apis/auc-master";
import {
  CooperMaster,
  CooperResponse,
} from "../../../apis/auc-master/types";
import TableLayout from "../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createActionCellRenderers } from "../../../utils/table-utils";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import { createCooperMasterCols } from "./utils/table-utils";
import {
  alertError,
  renderLoading,
} from "../../../utils/render-utils";
import { getErrorMessage } from "../../../utils/common-utils";
import AddCooperModal from "./pages/AddCooperModal";
import CooperDetailModal from "./pages/CooperDetailModal";

const AucManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [exhibitions, setExhibitions] = useState<CooperResponse<CooperMaster>>();
  const [exFetchProgress, setExFetchProgress] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [selectedExhibition, setSelectedExhibition] = useState<CooperMaster>();
  const [detailModalVisible, setDetailModalVisible] = useState(false);

  useEffect(() => {
    (async function fetchData() {
      await requestFetchMasters();
    })();
  }, []);

  const requestFetchMasters = async () => {
    setExFetchProgress(true);
    try {
      const { list, listCnt } = await selectUcCooperCompanyList();
      setExhibitions({
        listCnt,
        list,
      });
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setExFetchProgress(false);
    }
  };

  const handleDetail = ({ data }: { data: CooperMaster }) => {
    setSelectedExhibition(data);
    setDetailModalVisible(true);
  };

  const renderTableActions = () => {
    return [
      {
        title: "제휴사 등록",
        onClick: () => setShowAddModal(true),
      },
    ];
  };

  return (
    <>
      <ContentLayout filterable={false} headerTitle="제휴사 관리(제휴중고차)">
        {exFetchProgress && renderLoading()}
        {exhibitions && (
          <TableLayout
            gridApi={gridApi}
            title="제휴사 리스트"
            totalCount={exhibitions.listCnt}
            rightActions={renderTableActions()}
            methodName="/admin/auc-company"
          >
            <ClientRowTable
              apiRef={(api) => {
                setGridApi(api);
              }}
              rowData={exhibitions.list}
              cols={createCooperMasterCols()}
              frameworkComponents={{                
                ...createActionCellRenderers(undefined, handleDetail, {
                  hideDeleteButton: true,
                }),
              }}
            />
          </TableLayout>
        )}
      </ContentLayout>
      <AddCooperModal
        visible={showAddModal}
        onCancel={() => setShowAddModal(false)}
        onDataChange={async () => {
          await requestFetchMasters();
        }}
      />
      <CooperDetailModal
        visible={detailModalVisible}
        onCancel={async() => {
          setDetailModalVisible(false);
          await requestFetchMasters();
        }}
        exhibitionMaster={selectedExhibition}
        onDataChange={async () => {
          await requestFetchMasters();
        }}
      />
    </>
  );
};

AucManager.defaultProps = {};
export default AucManager;
