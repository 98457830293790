// 개인
export const ENUM_RENT_APY_CODE_PERSONAL = "A18001";
// 법인
export const ENUM_RENT_APY_CODE_CORP = "A18002";
// 전체
export const ENUM_RENT_APY_CODE_ALL = "A18003";
// 개인사업자
export const ENUM_RENT_APY_CODE_PRIVATE_BIZ = "A18004";

export class ProductConditionDetail {
  // MODE 조건 값 코드
  public modeCondCd = "";

  // COSS 조건 값 코드
  public cossCondCd = "";

  // 조건 값
  public condNm = "";

  // 우선순위
  public priority = "";

  // 적용고객코드 A18001(개인), A18002(법인), A18003(모두), A18004(개인사업자)
  public apyCustCd = "";

  // 적용고객코드명
  public apyCustNm = "";
}

export default class ProductCondition {
  // Mode 코드
  public modeCd = "";

  // 코드명
  public cdnm = "";

  // 디폴트 MODE 코드
  public defCd = "";

  // 우선순위
  public priority = "";

  public prodCondList: Array<ProductConditionDetail> = [];
}
