import styled from "styled-components";
import jumbotron from "../../../assets/images/jumbotron.png";

export const LoginRootFlexContainer = styled.div<any>`
  height: 100vh;
  display: flex;
`;

export const LoginFormContainer = styled.div<any>`
  width: 790px;
  background: white;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
`;

export const LoginFormWrapper = styled.div<any>`
  text-align: center;
  width: 380px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export const Jumbotron = styled.div<any>`
  width: 790px;
  //background: #3f51b5;
  flex-grow: 1;
  background-image: url(${jumbotron});
  background-size: cover;
`;
