import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import strings from "../../../constants/strings";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  CW_SMALL,
  CW_SMALL_M,
  CW_MEDIUM,
  CW_LARGE,
  CW_LONG_TEXT,
} from "../../../../../../utils/table-utils";
import { currencyFormatRenderer } from "../../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { CR_STATUS_TEXT } from "../../../../../../components/shared/data-display/Tables/components/ServerRowTable";
import {
  getTagPositionText,
  MOBILE_TAG_RENDERER,
  PC_TAG_RENDERER,
} from "../../../../CatalogTagManager/utils/table-utils";
import Switch from "../../../../../../components/shared/data-entry/antd/Switch";
import Input from "../../../../../../components/shared/data-entry/antd/Input";

// 태그설정
export const TAG_SETTING_BUTTON = "tagSettingButton";
// 단산설정
export const DANSAN_STATE_SWITCH = "dansanSwitchRenderer";
export const DANSAN_SETTING_BUTTON = "dasanSettingButton";
// 노출제어
export const DISPLAY_STATE_SWITCH = "displayStateSwitchRenderer";
export const DISPLAY_STATE_INPUT = "displayStateInputRenderer";

/**
 * 신차장기 트림(재고) 목록
 * 
 * @param isStockProduct 
 * @param isSlimProduct 
 * @returns 
 */
export const createNewCarCatalogTrimCols = (
  isStockProduct: boolean,
  isSlimProduct: boolean,
  isTpProduct: boolean,
): Array<TableColumn> => {
  let cols = [
    // 노출 여부
    createTableCol("condModlId", "COSS 상품조건", undefined, {
      width: CW_MEDIUM,
      valueGetter: ({ data }) => {
        return data.condModlId ? "Y" : "N";
      },
      cellRendererSelector: (params) => {
        const { value } = params;
        return {
          component: CR_STATUS_TEXT,
          params: {
            isRed: false,
            active: value === "Y",
            text: value === "Y" ? "설정" : "미설정",
          },
        };
      },
    }),
    // 태그관리
    createTableCol("", "태그관리", undefined, {
      width: CW_SMALL_M,
      cellRenderer: TAG_SETTING_BUTTON,
    }),
    // COSS 모델명
    createTableCol("cossModlNm", "COSS 모델명", FilterType.Text, {
      minWidth: CW_LONG_TEXT,
      flex: 1,
    }),
    // 차량가격
    createTableCol("carAmt", "COSS 차량가격", FilterType.Text, {
      width: CW_MEDIUM,
      cellRenderer: currencyFormatRenderer("carAmt"),
    }),
    // 제조사
    createTableCol("brandNm", strings.COL_BRAND, FilterType.Text, {
      width: CW_SMALL,
    }),
    // 트림
    createTableCol("carGradeNm", strings.COL_TRIM, FilterType.Text, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }),
  ];
  if (isStockProduct) {
    cols.push(
      // 옵션정보
      createTableCol("opt1Nm", "옵션정보", FilterType.Text, {
      minWidth: CW_MEDIUM,
      flex: 1,
    }));
    cols.push(
      // 외장색상
      createTableCol("cossXtnlCarColorNm", "외장색상", FilterType.Text, {
      width: CW_MEDIUM,
    }));
    cols.push(
      // 내장색상
      createTableCol("cossInnrCarColorNm", "내장색상", FilterType.Text, {
      width: CW_MEDIUM,
    }));
    cols.push(
      // 재고수
      createTableCol("stockCnt", strings.COL_STOCK_COUNT, FilterType.Text, {
      width: CW_SMALL,
    }));
  } else {
    cols.push(
      // 단산 여부
      createTableCol("dnsnYn", "단산여부", undefined, {
        width: CW_MEDIUM,
        cellRendererSelector: (params) => {
          const { value } = params;
          return {
            component: CR_STATUS_TEXT,
            params: {
              isRed: true,
              active: value === "Y",
              text: value === "Y" ? "단산지정" : "미지정",
            },
          };
        },
      }));
    cols.push(
      // 단산 관리
      createTableCol("contents", "관리", undefined, {
        width: 100,
        pinned: "right",
        cellRenderer: DANSAN_SETTING_BUTTON,
      }));
  }
  // 트림 노출 여부
  cols.splice(1, 0, createTableCol("srUseYn", "사용여부", undefined, {
    cellRenderer: DISPLAY_STATE_SWITCH,
    width: CW_SMALL,
  }));
  // if (isStockProduct) {
  //   cols.splice(1, 0, createTableCol("srUseYn", `${isSlimProduct ? '1년렌트' : isTpProduct ? '타고페이' : '트림'}`+" 노출여부", undefined, {
  //     cellRenderer: DISPLAY_STATE_SWITCH,
  //     width: CW_MEDIUM,
  //   }));
  // }
  return cols;
};

/**
 * TODO: 노출여부 toggle render - id(key) 확인 후, 변경 필요
 * 
 * @param onChange 
 * @param key 
 * @returns 
 */
const renderSwitch = (
  onChange: (checked: boolean, srSeq: string, modeGrdId: string) => void,
  key = "srUseYn"
) => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    const { srSeq, modeGrdId } = data;
    return (
      <Switch
        checked={data[key] === "Y"}
        onClick={(checked) => {
          onChange(checked, srSeq, modeGrdId);
        }}
      />
    );
  };
};

/**
 * create render
 * 
 * @param onChange 
 * @returns 
 */
export const createSwitchRenderer = (
  onChange: (checked: boolean, srSeq: string, modeGrdId: string) => void
) => ({
  [DISPLAY_STATE_SWITCH]: renderSwitch(onChange),
});

export const createCatalogTagCols = (): Array<TableColumn> => {
  return [
    // PC 태그
    createTableCol("pcTagTxt", "PC 태그", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        return data.pcTagTxt;
      },
      cellRenderer: PC_TAG_RENDERER,
    }),
    // PC 태그위치
    createTableCol("pcTagPos", "PC 태그위치", FilterType.Text, {
      width: CW_MEDIUM,
      flex: 1,
      valueGetter: ({ data }) => {
        return getTagPositionText(data.pcTagPos);
      },
    }),
    // 모바일 태그
    createTableCol("mobTagTxt", "모바일 태그", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        return data.mobTagTxt;
      },
      cellRenderer: MOBILE_TAG_RENDERER,
    }),
    // 모바일 태그위치
    createTableCol("mobTagPos", "모바일 태그위치", FilterType.Text, {
      width: CW_MEDIUM,
      flex: 1,
      valueGetter: ({ data }) => {
        return getTagPositionText(data.mobTagPos);
      },
    }),
  ];
};

/**
 * 1년 렌트 렌탈료 설정
 * 
 * @returns 
 */
export const createRentFeeCols = (isEdit?: boolean): Array<TableColumn> => {
  return [
    // 계약구분코드
    createTableCol("custClsCd", "계약구분", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        return data.custClsNm;
      },
    }),
    // 계약기간
    createTableCol("cntrTerm", "계약기간", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        return data.cntrTerm;
      },
    }),
    // 운전자범위
    createTableCol("drvSoe", "운전자범위", FilterType.Text, {
      minWidth: CW_LARGE,
      flex: 1,
      valueGetter: ({ data }) => {
        return data.drvSoeNm;
      },
    }),
    // 렌탈료
    createTableCol("rglRentAmt", "렌탈료", undefined, {
      width: CW_MEDIUM,
      flex: 1,
      cellRenderer: DISPLAY_STATE_INPUT,
    }),
  ];
};
