/* eslint-disable */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "reflect-metadata";
import { Store } from "redux";

import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";

import { LicenseManager } from "@ag-grid-enterprise/core";
const gridKey = process.env.REACT_APP_GRID_LICENSE as string;
LicenseManager.setLicenseKey(gridKey);

import App from "./App";
import GlobalStyles from "./styles/GlobalStyle";
import buildStore, { history } from "./redux/store";

const { store } = buildStore();
export const reduxStore = store as Store;

ReactDOM.render(
  <>
    <GlobalStyles />
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </>,
  document.getElementById("root")
);
