import * as Yup from "yup";

import errorMessages from "../../../../../../constants/errors";

export const createValidationSchema = () => {
  return Yup.object().shape({
    // 11번가 상품코드
    elevenProdId: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD)
      .max(15, "최대 15자까지 입력 가능합니다"),
  });
};
