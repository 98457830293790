import { plainToClass } from "class-transformer";
import Notice from "../../models/Notice";
import { ListItemResponse } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import { NoticeAddParam, NoticeEditParam } from "./types";
import NoticeDetail from "../../models/NoticeDetail";

const apiRootUrl = `${ROOT_URL}/admin/notices`;

/**
 * 공지사항 리스트 조회
 */
export const fetchNotices = async (): Promise<ListItemResponse<Notice>> => {
  const res = await request(HttpMethod.Get, apiRootUrl);

  const { data } = res;
  const { response } = data;
  const { listCnt, list } = response;
  const responseList = list.sort((a: any, b: any) => {
    if (a.noticeFixed === "Y" && (!b.noticeFixed || b.noticeFixed === "N"))
      return -1;
    return 0;
  }) as Array<JSON>;
  return {
    items: plainToClass(Notice, responseList),
    count: listCnt,
  };
};

/**
 * 공지사항 상세 조회
 */

export const fetchNoticeDetail = async (
  noticeSeq: string
): Promise<NoticeDetail> => {
  const res = await request(HttpMethod.Get, `${apiRootUrl}/${noticeSeq}`);
  const { data } = res;
  const { response } = data;
  return plainToClass(NoticeDetail, response);
};

/**
 * 공지사항 등록
 */
export const addNotice = async (params: NoticeAddParam) => {
  await request(HttpMethod.Post, apiRootUrl, undefined, params);
};

/**
 * 공지사항 수정
 */
export const editNotice = async (
  noticeSeq: string,
  params: NoticeEditParam
) => {
  await request(
    HttpMethod.Put,
    `${apiRootUrl}/${noticeSeq}`,
    undefined,
    params
  );
};

/**
 * 공지사항 삭제
 */
export const deleteNotice = async (noticeSeq: string) => {
  await request(HttpMethod.Get, `${apiRootUrl}/${noticeSeq}`);
};
