import moment from "moment";

class GradeRate {
  // 보증보험요율ID
  public insrGrtRtId = "";

  // 고객구분코드
  public custClsCd = "";

  // 적용년
  public apyYr = "";

  // 적용시작일
  public apyStDt = "";

  // 적용종료일
  public apyEndDt = "";

  // 1등급
  public grd1 = "";

  // 2등급
  public grd2 = "";

  // 3등급
  public grd3 = "";

  // 4등급
  public grd4 = "";

  // 5등급
  public grd5 = "";

  // 6등급
  public grd6 = "";

  // 7등급
  public grd7 = "";

  // 등록자
  public regUser = "";

  // 등록일시
  public regDtm = "";

  get isActive() {
    const start = moment(this.apyStDt);
    const end = moment(this.apyEndDt);

    const current = moment();

    if (current.isBetween(start, end)) return "Y";

    return "N";
  }
}

export default GradeRate;
