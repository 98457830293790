import React from "react";
import { RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import { RangeValue } from "rc-picker/lib/interface";
import { Col } from "antd";
import moment, { Moment } from "moment";
import DateRangePicker from "../../../../../data-entry/antd/DateRangePicker";
import Radio, { RadioOption } from "../../../../../data-entry/antd/Radio";
import { filterLayout } from "../../../../../layout/TableFilterLayout/style";
import {
  ENUM_DATE_FILTER_1M,
  ENUM_DATE_FILTER_1W,
  ENUM_DATE_FILTER_1Y,
  ENUM_DATE_FILTER_3M,
  ENUM_DATE_FILTER_TODAY,
  ENUM_DATE_FILTER_YESTERDAY,
  TABLE_DATE_FILTER_OPTIONS,
} from "../../../../../../../constants/enums";
import {
  get1MonthDateRange,
  get1YearDateRange,
  get3MonthDateRange,
  getTodayDateRange,
  getWeekDateRange,
  getYesterdayDateRange,
} from "../../../../../../../utils/date-utils";
import FormItem from "../../../../../data-entry/antd/FormItem";

interface RadioDateRangeFilterProps extends RangePickerDateProps<Moment> {
  label?: string;
  onDateChange: (value: RangeValue<Moment>) => void;
  options?: Array<RadioOption>;
}

const RadioDateRangeFilter: React.FC<RadioDateRangeFilterProps> = (
  props: RadioDateRangeFilterProps
) => {
  const { value, label, onDateChange, options } = props;

  /**
   * Private Functions
   */

  const convertDateValueToRadioValue = (value: RangeValue<Moment>) => {
    if (value && value.length > 0) {
      const [startDate, endDate] = value;
      if (startDate && endDate) {
        // 오늘
        if (
          startDate.isSame(moment(), "day") &&
          endDate.isSame(moment(), "day")
        ) {
          return ENUM_DATE_FILTER_TODAY;
        }

        // 1주
        if (
          startDate.isSame(moment().subtract(1, "week"), "day") &&
          endDate.isSame(moment(), "day")
        ) {
          return ENUM_DATE_FILTER_1W;
        }

        // 1개월
        if (
          startDate.isSame(moment().subtract(1, "M"), "day") &&
          endDate.isSame(moment(), "day")
        ) {
          return ENUM_DATE_FILTER_1M;
        }

        // 3개월
        if (
          startDate.isSame(moment().subtract(3, "M"), "day") &&
          endDate.isSame(moment(), "day")
        ) {
          return ENUM_DATE_FILTER_3M;
        }

        // 1년
        if (
          startDate.isSame(moment().subtract(1, "year"), "day") &&
          endDate.isSame(moment(), "day")
        ) {
          return ENUM_DATE_FILTER_1Y;
        }

        // 어제
        if (
          startDate.isSame(moment().subtract(1, "day"), "day") &&
          endDate.isSame(moment().subtract(1, "day"), "day")
        ) {
          return ENUM_DATE_FILTER_YESTERDAY;
        }
      }
    }

    return "";
  };

  /**
   * Event Actions
   */

  const handleDateRangePickerChange = (value: RangeValue<Moment>) => {
    onDateChange(value);
  };

  const handleRadioChange = (value: string) => {
    switch (value) {
      case ENUM_DATE_FILTER_TODAY:
        onDateChange(getTodayDateRange());
        break;
      case ENUM_DATE_FILTER_1W:
        onDateChange(getWeekDateRange());
        break;
      case ENUM_DATE_FILTER_1M:
        onDateChange(get1MonthDateRange());
        break;
      case ENUM_DATE_FILTER_3M:
        onDateChange(get3MonthDateRange());
        break;
      case ENUM_DATE_FILTER_1Y:
        onDateChange(get1YearDateRange());
        break;
      case ENUM_DATE_FILTER_YESTERDAY:
        onDateChange(getYesterdayDateRange());
        break;
      default:
        break;
    }
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <Col span={6}>
        <FormItem label={label} {...filterLayout}>
          <Radio
            // size="default"
            value={convertDateValueToRadioValue(value as RangeValue<Moment>)}
            options={options}
            onChange={handleRadioChange}
          />
        </FormItem>
      </Col>
      <Col span={6}>
        <FormItem label="기간 수동 설정" {...filterLayout}>
          <DateRangePicker
            value={value}
            inputReadOnly
            format="YY.MM.DD"
            onChange={handleDateRangePickerChange}
          />
        </FormItem>
      </Col>
    </>
  );
};

RadioDateRangeFilter.defaultProps = {
  options: TABLE_DATE_FILTER_OPTIONS,
};
export default RadioDateRangeFilter;
