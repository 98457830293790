import React from "react";
import { Tag } from "antd";
import styled from "styled-components";
import { ModalProps } from "../../../../../../types";
import TagMaster from "../../../../../../../models/TagMaster";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import {
  TAG_POS_CD_BOTTOM,
  TAG_POS_CD_TOP,
} from "../../../../../../../constants/enums";

import {
  getMobileTagStyles,
  getPcTagStyles,
  TagStyleCode,
} from "../../../../../../../components/shared/composition/TagMasterSelect/utils/utils";
import UsedCarCatalogDetail from "../../../../../../../models/UsedCarCatalogDetail";

interface Props extends ModalProps {
  tagMasters?: TagMaster[];
  catalogDetail?: UsedCarCatalogDetail;
}

const PlatformLabel = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  display: inline-block;
`;

const PCProductTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  display: inline-block;
  margin-left: 8px;
`;

const MobileProductTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
  display: inline-block;
  margin-left: 4px;
`;

const MobileListProductTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
`;

const GreyContainer = styled.div`
  background-color: #f6f6fa;
  padding: 16px;
  text-align: center;
`;

const CenterWrapper = styled.div`
  display: inline-block;
  text-align: left;
`;

const PCCardContainer = styled.div<any>`
  position: relative;
  background-color: white;
  width: 280px;
  height: 161px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-image: url(${(props) => props.imageUrl});
  background-size: 172px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const MobileCardContainer = styled.div<any>`
  position: relative;
  background-color: white;
  width: 160px;
  height: 106px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-image: url(${(props) => props.imageUrl});
  background-size: 110px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const TopTagBox = styled.div<any>`
  position: absolute;
  width: ${(props) => (props.platform === "pc" ? "280px" : "160px")};
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
`;

const BottomTagBox = styled.div<any>`
  display: flex;
  width: ${(props) => (props.platform === "pc" ? "280px" : "160px")};
  overflow: hidden;
  flex-flow: row wrap;
`;

const MobileListTopTagBox = styled.div`
  width: 100px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

const MobileListBottomTagBox = styled.div<any>`
  display: flex;
  width: 260px;
  overflow: hidden;
  flex-flow: row wrap;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const MobileListContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0 16px 16px;
  background: white;
`;

const TagPreviewModal: React.FC<Props> = (props: Props) => {
  const { tagMasters = [], catalogDetail, visible, onCancel } = props;

  if (!tagMasters) {
    return null;
  }

  const pcTopTags = tagMasters.filter(({ pcTagPos }) => {
    return pcTagPos === TAG_POS_CD_TOP;
  });
  const pcBottomTags = tagMasters.filter(({ pcTagPos }) => {
    return pcTagPos === TAG_POS_CD_BOTTOM;
  });
  const mobTopTags = tagMasters.filter(({ mobTagPos }) => {
    return mobTagPos === TAG_POS_CD_TOP;
  });
  const mobBottomTags = tagMasters.filter(({ mobTagPos }) => {
    return mobTagPos === TAG_POS_CD_BOTTOM;
  });

  const renderPreview = () => {
    if (catalogDetail) {
      const { prodNm, repCarImg } = catalogDetail;
      return (
        <div>
          <PlatformLabel>PC</PlatformLabel>
          <GreyContainer>
            <CenterWrapper>
              <PCCardContainer imageUrl={repCarImg}>
                <TopTagBox platform="pc">
                  {pcTopTags.map(({ pcTagTxt, pcTagStyl }) => (
                    <Tag
                      style={{
                        ...getPcTagStyles(pcTagStyl as TagStyleCode),
                        marginRight: "4px",
                        marginBottom: "2px",
                      }}
                    >
                      {pcTagTxt}
                    </Tag>
                  ))}
                </TopTagBox>
              </PCCardContainer>
              <PCProductTitle>{prodNm}</PCProductTitle>
              <BottomTagBox platform="pc">
                {pcBottomTags.map(({ pcTagTxt, pcTagStyl }) => (
                  <Tag
                    style={{
                      ...getPcTagStyles(pcTagStyl as TagStyleCode),
                      marginRight: "4px",
                      marginBottom: "2px",
                    }}
                  >
                    {pcTagTxt}
                  </Tag>
                ))}
              </BottomTagBox>
            </CenterWrapper>
          </GreyContainer>
          <PlatformLabel
            style={{
              marginTop: "24px",
            }}
          >
            모바일(카드)
          </PlatformLabel>
          <GreyContainer>
            <CenterWrapper>
              <MobileCardContainer imageUrl={repCarImg}>
                <TopTagBox>
                  {mobTopTags.map(({ mobTagTxt, mobTagStyl }) => (
                    <Tag
                      style={{
                        ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                        marginRight: "4px",
                        marginBottom: "2px",
                      }}
                    >
                      {mobTagTxt}
                    </Tag>
                  ))}
                </TopTagBox>
              </MobileCardContainer>
              <MobileProductTitle>{prodNm}</MobileProductTitle>
              <BottomTagBox>
                {mobBottomTags.map(({ mobTagTxt, mobTagStyl }) => (
                  <Tag
                    style={{
                      ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                      marginRight: "4px",
                      marginBottom: "2px",
                    }}
                  >
                    {mobTagTxt}
                  </Tag>
                ))}
              </BottomTagBox>
            </CenterWrapper>
          </GreyContainer>
          <PlatformLabel
            style={{
              marginTop: "24px",
            }}
          >
            모바일(리스트)
          </PlatformLabel>
          <GreyContainer>
            <CenterWrapper>
              <MobileListContainer>
                <img
                  src={repCarImg}
                  alt="차량이미지"
                  height={60}
                  style={{
                    marginRight: "16px",
                  }}
                />
                <div>
                  <FlexBox>
                    <MobileListProductTitle>{prodNm}</MobileListProductTitle>
                    <MobileListTopTagBox>
                      {mobTopTags.map(({ mobTagTxt, mobTagStyl }) => (
                        <Tag
                          style={{
                            ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                            marginRight: "4px",
                            marginBottom: "2px",
                          }}
                        >
                          {mobTagTxt}
                        </Tag>
                      ))}
                    </MobileListTopTagBox>
                  </FlexBox>
                  <MobileListBottomTagBox>
                    {mobBottomTags.map(({ mobTagTxt, mobTagStyl }) => (
                      <Tag
                        style={{
                          ...getMobileTagStyles(mobTagStyl as TagStyleCode),
                          marginRight: "4px",
                          marginBottom: "2px",
                        }}
                      >
                        {mobTagTxt}
                      </Tag>
                    ))}
                  </MobileListBottomTagBox>
                </div>
              </MobileListContainer>
            </CenterWrapper>
          </GreyContainer>
        </div>
      );
    }

    return "";
  };

  return (
    <Modal
      title="태그 미리보기"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {renderPreview()}
    </Modal>
  );
};

export default TagPreviewModal;
