interface SurveyReportPointInfo {
  // 설문점수
  point: string;
  // 설문점수 갯수
  pointCnt: string;
}

interface SurveyReportReplyInfo {
  // 응답분류코드
  replyClsCd: string;

  // 응답분류코드명
  replyClsNm: string;

  // 설문응답항목코드명
  srveReplyItemNm: string;

  // 응답순서
  replySort: string;
}

export default class ServiceSurveyReportDetail {
  // 계약건수
  public cntrCnt = 0;

  // 응답건수
  public replyCnt = 0;

  // 점수리스트 건수
  public pointListCnt = 0;

  public pointList: SurveyReportPointInfo[] = [];

  // 응답리스트 건수
  public replyListCnt = 0;

  // 응답리스트
  public replyList: SurveyReportReplyInfo[] = [];
}
