import React, { useState } from "react";
import { ModalProps } from "../../../../../../types";
import {
  ExhibitionCatalog,
  ExhibitionMaster,
  LinkExhibitionCatalogParams,
} from "../../../../../../../apis/tb-ex/types";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../../../utils/render-utils";
import ModalTableWrapper from "../../../../../../../components/shared/layout/ModalTableWrapper";
import { getErrorMessage } from "../../../../../../../utils/common-utils";
import { TagoCarCatalog } from "../../../../../../../models/TagoCarCatalog";
import ClientRowTable from "../../../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createCatalogCols } from "./utils/table-utils";
import {
  fetchExhibitionCatalogsAll,
  linkExhibitionCatalogs,
} from "../../../../../../../apis/tb-ex";

/**
 * 기획전 <-> 신차상품 등록/등록해제 모달
 */

interface Props extends ModalProps {
  initialCheckList?: ExhibitionCatalog[];
  exhibitionMaster?: ExhibitionMaster;
}

const ExhibitionCatalogAddModal: React.FC<Props> = (props: Props) => {
  const cols = createCatalogCols();

  const {
    visible,
    onCancel,
    onDataChange,
    initialCheckList = [],
    exhibitionMaster,
  } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoadng] = useState(false);
  const [catalogs, setCatalogs] = useState<ExhibitionCatalog[]>();
  const [checkedRow, setCheckedRows] = useState<TagoCarCatalog[]>([]);

  const onModalOpen = async () => {
    setDataFetching(true);
    setCheckedRows([]);
    await requestFetchCatalogs();
    setDataFetching(false);
  };

  const createLinkParams = () => {
    let params: LinkExhibitionCatalogParams[] = [];
    if (checkedRow.length>0) {
      params = checkedRow.map(({ prodId }) => {
        return {
          prodId,
        };
      });
    }
    return params;
  };

  const requestFetchCatalogs = async () => {
    try {
      const { list } = await fetchExhibitionCatalogsAll(
        exhibitionMaster?.exhMstSeq as string
      );
      setCatalogs(list);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const requestLinkCatalogs = async () => {
    setConfirmLoadng(true);
    try {
      const params = createLinkParams();      
      await linkExhibitionCatalogs(exhibitionMaster?.exhMstSeq as string, params);
      alertSuccess("기획전 차량정보가 변경되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoadng(false);
    }
  };

  const handleModalOk = async () => {
    await requestLinkCatalogs();
  };

  const handleCheckRowChanged = (rows: Array<TagoCarCatalog>) => {
    setCheckedRows(rows);
  };

  const renderDetail = () => {
    return (
      <ModalTableWrapper headerTitle="차량리스트">
        <ClientRowTable
          apiRef={(api) => {
            api.forEachNode((node) => {
              const data = node.data as TagoCarCatalog;
              const filtered = initialCheckList.filter(({ carNo }) => {
                return carNo === data.carNo;
              });
              const useYn = data.useYn as String;
              if (filtered.length > 0) {
                if(useYn == "Y") {
                  node.setSelected(true);
                } else {
                  node.setSelected(false);
                }
                
              }
            });
          }}
          onCheckRowChanged={handleCheckRowChanged}
          cols={cols}
          rowData={catalogs}
        />
      </ModalTableWrapper>
    );
  };

  return (
    <Modal
      size="large"
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="기획전 차량관리"
      confirmLoading={confirmLoading}
      onOk={handleModalOk}
    >
      {dataFetching ? renderLoading() : renderDetail()}
    </Modal>
  );
};

export default ExhibitionCatalogAddModal;
