import RentOption from "./RentOption";
import ProductCondition from "./ProductCondition";

export default class CommonOption {
  // 외관 이미지
  public repCarImg = "";

  // 전면 틴팅
  public frontTintingOptionList: Array<RentOption> = [];

  // 사이드 틴팅
  public sideTintingOptionList: Array<RentOption> = [];

  // 루프 틴팅
  public roofTintingOptionList: Array<RentOption> = [];

  // 담보 구분
  public pldgOptionList: Array<RentOption> = [];

  // 상품조건
  public prodCondList: Array<ProductCondition> = [];

  // 온라인할인구분
  public onlineDcOptionList: Array<RentOption> = [];
}
