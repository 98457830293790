/**
 * 만족도조사 설문
 */

export default class SurveyMaster {
  // 설문질문ID
  public srveQstnId = "";

  // 설문그룹코드(공통코드:A26000)
  public srveGrpCd = "";

  // 설문그룹코드명
  public srveGrpNm = "";

  // 설문분류코드(공통코드:A27000)
  public srveClfCd = "";

  // 설문분류코드명
  public srveClfNm = "";

  // 설문내용
  public srveCntn = "";

  // 말머리구분코드(U:유저명/M:카매니저명/C:법인명/N:미사용)
  public headClsCd = "";

  // 사용여부
  public useYn = "";

  // 우선순위
  public priority = 0;

  // 설문문항개수
  public srveQitmCnt = 0;
}
