import React from "react";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import errorMessages from "../../../../../../../../constants/errors";
import FormItem from "../../../../../../../../components/shared/data-entry/antd/FormItem";
import HtmlEditor from "../../../../../../../../components/shared/input/HtmlEditor";
import { FormElementType } from "../../../../../../../../components/shared/data-entry/FormBuilder/types";

export const createInitialValues = (mdComment: string) => {
  return {
    // 내용
    mdComment: mdComment || "",
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    mdComment: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });
};

export const createFormInfo = () => {
  return [
    // 내용
    {
      key: "mdComment",
      type: FormElementType.Custom,
      render: (renderProps: FormikProps<FormikValues>) => {
        const { values, setFieldValue, touched, errors } = renderProps;
        const value = values.mdComment || "";

        let errorMessage = "";
        if (touched.mdComment && errors.mdComment) {
          errorMessage = errors.mdComment as string;
        }

        return (
          <FormItem
            help={errorMessage}
            validateStatus={errorMessage && "error"}
            key="mdComment"
            label="차량소개"
            required
            style={{
              marginBottom: "15px",
            }}
          >
            <HtmlEditor
              // uploadInfo={UPLOAD_CODES.EVENT}
              data={value}
              onChange={(data) => {
                setFieldValue("mdComment", data);
              }}
            />
          </FormItem>
        );
      },
    },
  ];
};
