import React, { useEffect, useState } from "react";
import moment from "moment";
import { GridApi } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import strings from "./constants/strings";
import { createCols } from "./utils/table-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import TableLayout from "../../../components/shared/layout/TableLayout";
import { alertError } from "../../../utils/render-utils";
import { get1MonthDateRange } from "../../../utils/date-utils";
import { FilterElementType } from "../../../components/shared/layout/TableFilterLayout/types";
import { TABLE_DATE_FILTER_LIGHT_OPTIONS } from "../../../constants/enums";
import { fetchContractUpdateRequestList } from "../../../apis/rent-contracts";
import ContractUpdateRequest from "../../../models/ContractUpdateRequest";
import { createUserDetailRenderer } from "../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import useAuth from "@hooks/useAuth";
import { setConnectLogs } from "@apis/logs";

const ContractUpdateRequestManager: React.FC = () => {
  const { user } = useAuth();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rows, setRows] = useState<Array<ContractUpdateRequest> | undefined>(
    undefined
  );
  const [totalCount, setTotalCount] = useState(0);
  const [logInfo, setLogInfo] = useState<string>("");

  const cols = createCols();

  const filterInitialValues = {
    stDt: get1MonthDateRange(),
  };

  const [filterValues, setFilterValues] = useState<any>(filterInitialValues);

  const filterForms = [
    {
      key: "stDt",
      type: FilterElementType.DateRangePicker,
      label: "요청일",
      options: TABLE_DATE_FILTER_LIGHT_OPTIONS,
    },
  ];

  useEffect(() => {
    (async function fetchData() {
      await requestDataList(filterValues);
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestDataList = async (filterValues: any) => {
    try {
      // 검색조건 설정
      const appliedFilters = JSON.stringify(filterValues);
      setLogInfo(appliedFilters);

      const { stDt } = filterValues;
      const stDtParam = moment(stDt[0]).format("YYYYMMDD");
      const endDtParam = moment(stDt[1]).format("YYYYMMDD");

      const estimations = await fetchContractUpdateRequestList(
        stDtParam,
        endDtParam
      );
      const { items, count } = estimations;
      setRows(items);
      setTotalCount(count);
      setConnectLogs({
        userId: user.userId,
        userIp: user.clientIp,
        methodNm: "/admin/cntr-chg-req-list",
        menuName: "견적/계약 - 계약변경요청 관리",
        actionFlag: "SEARCH",
        reqCntn: appliedFilters
      });
    } catch (e) {
      if (e.status !== 404) {
        if (e.getMessage) alertError(e.getMessage());
        else alertError(e.toString());
      }
      setRows([]);
    }
  };

  /**
   * Event Actions
   */

  const handleSearchSubmit = async (values: any) => {
    setFilterValues(values);
    gridApi?.showLoadingOverlay();
    await requestDataList(values);
    gridApi?.hideOverlay();
  };

  const handleUserDetailCellClick = (custId?: string) => {
    alertError(`custId 필요 ${custId}`);
  };

  /**
   * Render Helpers
   */

  return (
    <>
      <ContentLayout
        filterable
        headerTitle={strings.TITLE_HEADER}
        filterForms={filterForms}
        filterValues={filterValues}
        filterInitialValues={filterInitialValues}
        onSearchSubmit={handleSearchSubmit}
      >
        <TableLayout
          gridApi={gridApi}
          title={strings.TITLE_TABLE_HEADER}
          totalCount={totalCount}
          hideSelectionCountText
          methodName="/admin/cntr-chg-req-list"
          logInfo={logInfo}
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            rowData={rows}
            cols={cols}
            autoHeight
            frameworkComponents={{
              ...createUserDetailRenderer(handleUserDetailCellClick),
            }}
          />
        </TableLayout>
      </ContentLayout>
    </>
  );
};

ContractUpdateRequestManager.defaultProps = {};
export default ContractUpdateRequestManager;
