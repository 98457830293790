import { FormikValues } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { FormElementType } from "../../../../../../components/shared/data-entry/FormBuilder/types";
import {
  CONTRACT_TERM_OPTIONS,
  CREDIT_RATING_OPTIONS,
  DRIVER_RANGE_OPTIONS,
  MILEAGE_OPTIONS
} from "../../../constants/enums";
import errorMessages from "../../../../../../constants/errors";
import DefaultRentOptionInfo from "../../../../../../models/DefaultRentOptionInfo";
import { getParamFromDateRange } from "../../../../../../utils/form-utils";

export const createInitialValues = () => {
  return {
    kcbGrd: null,
    cntrTermMm: null,
    prmsDtc: null,
    drvSoe: null,
    apyDateRange: null,
  };
};

export const createInitialValuesFromDefaultOptionInfo = (
  defaultRentOptionInfo: DefaultRentOptionInfo
) => {
  const {
    kcbGrd,
    cntrTermMm,
    prmsDtc,
    drvSoe,
    apyStDt,
    apyEndDt,
  } = defaultRentOptionInfo;

  const initialApyDate = [moment(apyStDt), moment(apyEndDt)];
  return {
    kcbGrd,
    cntrTermMm,
    prmsDtc,
    drvSoe,
    apyDateRange: initialApyDate,
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    kcbGrd: Yup.number()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    cntrTermMm: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    prmsDtc: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    drvSoe: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    apyDateRange: Yup.array()
      .required(errorMessages.REQUIRED_FIELD)
      .min(2, errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
  });
};

export const createDefaultRentOptionInfoParam = (values: FormikValues) => {
  const { kcbGrd, cntrTermMm, prmsDtc, drvSoe, apyDateRange } = values;

  const { startDateParam, endDateParam } = getParamFromDateRange(apyDateRange);

  return {
    kcbGrd: kcbGrd as number,
    cntrTermMm: cntrTermMm as string,
    prmsDtc: prmsDtc as string,
    drvSoe: drvSoe as string,
    apyStDt: startDateParam as string,
    apyEndDt: endDateParam as string,
  };
};

export const createFormInfo = () => {
  return [
    {
      // 신용등급
      key: "kcbGrd",
      type: FormElementType.Select,
      options: CREDIT_RATING_OPTIONS,
      placeholder: "신용등급을 선택해주세요",
      label: "신용등급",
      required: true,
    },
    // 계약기간
    {
      key: "cntrTermMm",
      type: FormElementType.Select,
      options: CONTRACT_TERM_OPTIONS,
      label: "계약기간",
      placeholder: "계약기간을 선택해주세요",
      required: true,
    },
    // 약정주행거리
    {
      key: "prmsDtc",
      type: FormElementType.Select,
      options: MILEAGE_OPTIONS,
      label: "약정주행거리",
      placeholder: "약정주행거리를 선택해주세요",
      required: true,
    },
    // 운전자범위
    {
      key: "drvSoe",
      type: FormElementType.Select,
      label: "운전자범위",
      options: DRIVER_RANGE_OPTIONS,
      placeholder: "운전자범위를 선택해주세요",
      required: true,
    },
    // 적용기간
    {
      key: "apyDateRange",
      type: FormElementType.DateRangePicker,
      label: "적용기간",
      placeholder: "보증보험 수수료율 입력",
      required: true,
    },
  ];
};
