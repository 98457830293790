import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  ko: {
    TITLE_HEADER_FAQ_MANAGER: "FAQ",
    TABLE_HEADER_FAQ_LIST:"FAQ 리스트",

    COL_REG_DATE : "등록일",
    COL_CATEGORY1:"대분류",
    COL_CATEGORY2:"중분류",
    COL_TITLE:"제목",
    COL_USE_YN:"사용유무",

    TITLE_ACTION_ADD: "FAQ 등록",
    TITLE_ACTION_DELETE: "선택 삭제",

    CONFIRM_TITLE_DELETE_NOTICE: "FAQ 삭제",
    CONFIRM_MESSAGE_DELETE_NOTICE: "FAQ를 삭제하시겠습니까?",

    MODAL_TITLE_ADD_FAQ: "FAQ 등록",
    MODAL_TITLE_FAQ_DETAIL: "FAQ 수정",
  },
});
