import * as Yup from "yup";
import React from "react";
import errorMessages from "../../../../../../constants/errors";
import {
  FormElementType,
  FormInfo,
} from "../../../../../../components/shared/data-entry/FormBuilder/types";
import {
  TAG_POS_CD_BOTTOM,
  TAG_POS_CD_TOP,
} from "../../../../../../constants/enums";

import FormItem from "../../../../../../components/shared/data-entry/antd/FormItem";
import CatalogTagStyleSelect from "../../../../../../components/shared/composition/TagMasterSelect/CatalogTagStyleSelect";
import { verticalFormLayout } from "../index";
import TagMaster from "../../../../../../models/TagMaster";
import Radio from "../../../../../../components/shared/data-entry/antd/Radio";

export const createInitialValues = () => {
  return {
    pcTagPos: TAG_POS_CD_TOP,
    pcTagStyl: undefined,
    pcTagTxt: "",
    mobTagPos: TAG_POS_CD_TOP,
    mobTagStyl: undefined,
    mobTagTxt: "",
  };
};

export const createInitialValuesFromTagMaster = (tagMaster: TagMaster) => {
  return {
    pcTagPos: tagMaster.pcTagPos,
    pcTagStyl: tagMaster.pcTagStyl,
    pcTagTxt: tagMaster.pcTagTxt,
    mobTagPos: tagMaster.mobTagPos,
    mobTagStyl: tagMaster.mobTagStyl,
    mobTagTxt: tagMaster.mobTagTxt,
  };
};

export const createValidationSchema = () => {
  return Yup.object().shape({
    pcTagPos: Yup.string().required(errorMessages.REQUIRED_FIELD),
    pcTagStyl: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    pcTagTxt: Yup.string().required(errorMessages.REQUIRED_FIELD),
    mobTagPos: Yup.string().required(errorMessages.REQUIRED_FIELD),
    mobTagStyl: Yup.string()
      .required(errorMessages.REQUIRED_FIELD)
      .typeError(errorMessages.REQUIRED_FIELD),
    mobTagTxt: Yup.string().required(errorMessages.REQUIRED_FIELD),
  });
};

const TAG_POS_OPTIONS = [
  {
    label: "상단",
    value: TAG_POS_CD_TOP,
  },
  {
    label: "하단",
    value: TAG_POS_CD_BOTTOM,
  },
];

export const createForms = (): FormInfo[] => {
  return [
    {
      key: "pcTagPos",
      type: FormElementType.Custom,
      render: (renderProps) => {
        const { setFieldValue, values } = renderProps;
        return (
          <FormItem
            label="PC 태그 위치"
            required
            style={{
              marginBottom: "15px",
            }}
            {...verticalFormLayout}
          >
            <Radio
              value={values.pcTagPos}
              options={TAG_POS_OPTIONS}
              onChange={(value) => {
                setFieldValue("pcTagStyl", null);
                setFieldValue("pcTagPos", value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "pcTagStyl",
      type: FormElementType.Custom,
      render: (renderProps) => {
        const { values, setFieldValue, errors, touched } = renderProps;
        const value = values.pcTagStyl;
        const pos = values.pcTagPos === TAG_POS_CD_TOP ? "top" : "bottom";
        let errMsg;
        if (errors.pcTagStyl && touched.pcTagStyl) {
          errMsg = errors.pcTagStyl;
        }
        return (
          <FormItem
            label="PC 태그 스타일"
            required
            validateStatus={errMsg && "error"}
            help={errMsg}
            style={{
              marginBottom: "15px",
            }}
            {...verticalFormLayout}
          >
            <CatalogTagStyleSelect
              tagPosition={pos}
              value={value}
              onChange={(value) => {
                setFieldValue("pcTagStyl", value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "pcTagTxt",
      type: FormElementType.Input,
      label: "PC 태그 텍스트",
      required: true,
      placeholder: "태그에 들어갈 텍스트를 입력해주세요",
    },
    {
      key: "mobTagPos",
      type: FormElementType.Custom,
      render: (renderProps) => {
        const { setFieldValue, values } = renderProps;
        return (
          <FormItem
            label="모바일 태그 위치"
            required
            style={{
              marginBottom: "15px",
            }}
            {...verticalFormLayout}
          >
            <Radio
              value={values.mobTagPos}
              options={TAG_POS_OPTIONS}
              onChange={(value) => {
                setFieldValue("mobTagStyl", null);
                setFieldValue("mobTagPos", value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "mobTagStyl",
      type: FormElementType.Custom,
      render: (renderProps) => {
        const { values, setFieldValue, errors, touched } = renderProps;
        const value = values.mobTagStyl;
        const pos = values.mobTagPos === TAG_POS_CD_TOP ? "top" : "bottom";
        let errMsg;
        if (errors.mobTagStyl && touched.mobTagStyl) {
          errMsg = errors.mobTagStyl;
        }
        return (
          <FormItem
            label="모바일 태그 스타일"
            required
            validateStatus={errMsg && "error"}
            help={errMsg}
            style={{
              marginBottom: "15px",
            }}
            {...verticalFormLayout}
          >
            <CatalogTagStyleSelect
              tagPosition={pos}
              value={value}
              onChange={(value) => {
                setFieldValue("mobTagStyl", value);
              }}
            />
          </FormItem>
        );
      },
    },
    {
      key: "mobTagTxt",
      type: FormElementType.Input,
      label: "모바일 태그 텍스트",
      required: true,
      placeholder: "태그에 들어갈 텍스트를 입력해주세요",
    },
  ];
};
