import React, { useCallback, useState } from "react";
import { Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import Modal from "../../../../../components/shared/feedback/antd/Modal";

import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import { ModalProps } from "../../../../types";
import commonStrings from "../../../../../constants/strings";

import CarManagerReplacement from "../../../../../models/CarManagerReplacement";
import {
  addCarManagerReplacementInfo,
  editCarManagerReplacementInfo,
  fetchCarManagerReplacementInfoDetail,
  fetchCarManagers,
} from "../../../../../apis/car-managers";
import CarManagerReplacementDetail from "../../../../../models/CarManagerReplacementDetail";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import { defaultFormItemLayout } from "../../../../../components/shared/data-entry/FormBuilder";

import Select from "../../../../../components/shared/data-entry/antd/Select";
import {
  ENUM_CAR_MANAGER_OFF_REASON_ETC,
  ENUM_CAR_MANAGER_OFF_REASON_OPTIONS,
} from "../../../../../constants/enums";
import DateRangePicker from "../../../../../components/shared/data-entry/antd/DateRangePicker";
import TextArea from "../../../../../components/shared/data-entry/antd/TextArea";
import errorMessages from "../../../../../constants/errors";
import {
  getFormError,
  getInitialDateRangeFromRaw,
  getParamFromDateRange,
} from "../../../../../utils/form-utils";
import CarManager from "../../../../../models/CarManager";

interface Props extends ModalProps {
  carManagerReplacement?: CarManagerReplacement;
}

const CarManagerReplacementInfoModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, carManagerReplacement, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState<boolean>();
  const [replacementDetail, setReplacementDetail] = useState<
    CarManagerReplacementDetail
  >();
  const [carManagers, setCarManagers] = useState<CarManager[]>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const formInitialValues = {
    // 카매니저ID
    carmanId: null,

    // 대무카매니저ID
    daCarmanId: null,

    // 기간
    dateRange: null,

    // 부재사유코드
    rsnCd: null,

    rsn: "",
  };
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);
  let formik: FormikProps<FormikValues>;

  const validationSchema = Yup.lazy((values: any) => {
    const shape = {
      carmanId: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),
      daCarmanId: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),
      dateRange: Yup.array()
        .min(2, errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),
      rsnCd: Yup.string()
        .required(errorMessages.REQUIRED_FIELD)
        .typeError(errorMessages.REQUIRED_FIELD),
    };
    const { rsnCd } = values;
    if (rsnCd === ENUM_CAR_MANAGER_OFF_REASON_ETC) {
      // @ts-ignore
      shape.rsn = Yup.string().required(errorMessages.REQUIRED_FIELD) as any;
    }

    return Yup.object().shape(shape);
  });

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchCarManagers();

    if (carManagerReplacement) {
      const detail = await requestFetchReplacementDetail(
        carManagerReplacement.carmanId,
        carManagerReplacement.seq
      );
      if (detail) {
        const updatedInitialValues = {
          // 카매니저ID
          carmanId: detail.carmanId,

          // 대무카매니저ID
          daCarmanId: detail.daCarmanId,

          // 기간
          dateRange: getInitialDateRangeFromRaw(detail.stDt, detail.stEndDt),

          // 부재사유코드
          rsnCd: detail.rsnCd,

          rsn: detail.rsn || "",
        };
        setInitialValues(updatedInitialValues);
      }
    } else {
      setReplacementDetail(undefined);

      setInitialValues(formInitialValues);
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */

  const carManagerOptions = () => {
    return carManagers.map((carManager) => {
      return {
        label: `${carManager.userNm}(${carManager.deptNm}-${carManager.siteNm})`,
        value: carManager.carmanId,
      };
    });
  };

  const requestFetchCarManagers = async () => {
    try {
      const { items } = await fetchCarManagers();
      setCarManagers(items);
    } catch (e) {
      alertError(getErrorMessage(e));
      setCarManagers([]);
    }
  };

  const requestFetchReplacementDetail = async (
    carmanId: string,
    seq: string
  ) => {
    try {
      const detail = await fetchCarManagerReplacementInfoDetail(carmanId, seq);
      setReplacementDetail(detail);
      return detail;
    } catch (e) {
      alertError(getErrorMessage(e));
      return null;
    }
  };

  const requestAddCarMangerReplacementInfo = async (values: FormikValues) => {
    setConfirmLoading(true);

    const { carmanId, daCarmanId, dateRange, rsnCd, rsn } = values;

    const { startDateParam, endDateParam } = getParamFromDateRange(dateRange);
    try {
      await addCarManagerReplacementInfo(carmanId, {
        daCarmanId,
        rsn,
        rsnCd,
        stDt: startDateParam as string,
        endDt: endDateParam as string,
      });
      alertSuccess(commonStrings.FEEDBACK_ADD_CAR_MANAGER_REPLACEMENT);
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditCarMangerReplacementInfo = async (values: FormikValues) => {
    const { daCarmanId, dateRange, rsnCd, rsn } = values;
    const { startDateParam, endDateParam } = getParamFromDateRange(dateRange);
    if (carManagerReplacement) {
      setConfirmLoading(true);
      try {
        await editCarManagerReplacementInfo(
          carManagerReplacement.carmanId,
          carManagerReplacement.seq,
          startDateParam as string,
          endDateParam as string,
          daCarmanId,
          rsnCd,
          rsn
        );
        alertSuccess(commonStrings.FEEDBACK_ADD_CAR_MANAGER_REPLACEMENT);
        if (onCancel) onCancel();
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const getModalTitle = useCallback(() => {
    if (carManagerReplacement) {
      return commonStrings.MODAL_TITLE_EDIT_CAR_MANAGER_REPLACEMENT;
    }
    return commonStrings.MODAL_TITLE_ADD_CAR_MANAGER_REPLACEMENT;
  }, [carManagerReplacement]);

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (carManagerReplacement) {
      await requestEditCarMangerReplacementInfo(values);
    } else {
      await requestAddCarMangerReplacementInfo(values);
    }
  };

  /**
   * Render Helpers
   */

  const renderForms = () => {
    return (
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        innerRef={(ref: FormikProps<FormikValues>) => {
          formik = ref;
        }}
      >
        {(renderProps) => {
          const { handleSubmit, values, setFieldValue } = renderProps;
          const {
            // 카매니저ID
            carmanId,
            // 대무카매니저ID
            daCarmanId,
            // 기간
            dateRange,
            // 부재사유코드
            rsnCd,
            rsn,
          } = values;

          const formItemLayout = {
            ...defaultFormItemLayout,
            style: {
              marginBottom: "16px",
            },
          };

          return (
            <form onSubmit={handleSubmit}>
              {/* 부재자 */}
              <FormItem
                required
                label={commonStrings.LABEL_OFF_CAR_MANAGER}
                {...formItemLayout}
                {...getFormError(renderProps, "carmanId")}
              >
                <Select
                  disabled={!!replacementDetail}
                  options={carManagerOptions()}
                  value={carmanId}
                  placeholder={commonStrings.HINT_CAR_MANAGER_SELECT}
                  onChange={(value) => {
                    setFieldValue("carmanId", value);
                  }}
                />
              </FormItem>
              {/* 대무자 */}
              <FormItem
                required
                label={commonStrings.LABEL_REPLACE_CAR_MANAGER}
                {...formItemLayout}
                {...getFormError(renderProps, "daCarmanId")}
              >
                <Select
                  options={carManagerOptions()}
                  value={daCarmanId}
                  placeholder={commonStrings.HINT_CAR_MANAGER_SELECT}
                  onChange={(value) => {
                    setFieldValue("daCarmanId", value);
                  }}
                />
              </FormItem>
              {/* 부재기간 */}
              <FormItem
                required
                label={commonStrings.LABEL_OFF_DATE_RANGE}
                {...formItemLayout}
                {...getFormError(renderProps, "dateRange")}
              >
                <DateRangePicker
                  value={dateRange}
                  onChange={(values) => {
                    setFieldValue("dateRange", values);
                  }}
                />
              </FormItem>

              {/* 부재사유 */}
              <FormItem
                required
                label={commonStrings.LABEL_OFF_REASON}
                {...formItemLayout}
                {...getFormError(renderProps, "rsnCd")}
              >
                <Select
                  value={rsnCd}
                  placeholder={commonStrings.HINT_OFF_REASON}
                  options={ENUM_CAR_MANAGER_OFF_REASON_OPTIONS}
                  onChange={(value) => {
                    setFieldValue("rsnCd", value);
                  }}
                />
              </FormItem>
              {/* 기타 부재사유 */}
              {rsnCd === ENUM_CAR_MANAGER_OFF_REASON_ETC && (
                <FormItem
                  required
                  label={commonStrings.LABEL_OFF_ETC_REASON}
                  {...formItemLayout}
                  {...getFormError(renderProps, "rsn")}
                >
                  <TextArea
                    value={rsn}
                    placeholder={commonStrings.HINT_OFF_REASON_ETC}
                    onChange={(e) => {
                      setFieldValue("rsn", e.target.value);
                    }}
                  />
                </FormItem>
              )}
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Modal
      size="small"
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title={getModalTitle()}
      onOk={() => {
        if (formik) formik.handleSubmit();
      }}
    >
      {dataFetching ? renderLoading() : renderForms()}
    </Modal>
  );
};

CarManagerReplacementInfoModal.defaultProps = {};
export default CarManagerReplacementInfoModal;
