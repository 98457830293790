import React, { useState } from "react";
import moment from "moment";
import { ModalProps } from "../../../../types";
import { fetchCarManagerSurveyReportDetailList } from "../../../../../apis/survey";
import CarManagerSurveyReportDetail from "../../../../../models/CarManagerSurveyReportDetail";
import { alertError } from "../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import TableLayout from "../../../../../components/shared/layout/TableLayout";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createCarManagerSurveyReportDetailCols } from "./utils/table-utils";

interface Props extends ModalProps {
  carmanId?: string;
  carmanNm?: string;
  filterValues?: any;
}

const CarManagerSurveyReportDetailModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, carmanId, carmanNm, filterValues } = props;
  const [rows, setRows] = useState<CarManagerSurveyReportDetail[]>();
  const [totalCount, setTotalCount] = useState(0);
  const [logInfo, setLogInfo] = useState<string>("");
  const cols = createCarManagerSurveyReportDetailCols();
  /**
   * Private Functions
   */

  const onModalOpen = async () => {
    if (carmanId) {
      await requestFetchCarManagerReportDetail(carmanId);
    } else {
      await requestFetchCarManagerReportDetail();
    }
  };

  const requestFetchCarManagerReportDetail = async (carmanId?: string) => {
    try {
      let startDtParam;
      let endDtParam;
      if (
        filterValues &&
        filterValues.stDate &&
        filterValues.stDate.length > 1
      ) {
        // 검색조건 설정
        const appliedFilters = JSON.stringify(filterValues);
        setLogInfo(appliedFilters);
        const [start, end] = filterValues.stDate;
        startDtParam = moment(start).format("YYYYMMDD");
        endDtParam = moment(end).format("YYYYMMDD");
      }

      const { items, count } = await fetchCarManagerSurveyReportDetailList(
        carmanId,
        startDtParam,
        endDtParam
      );
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      alertError(getErrorMessage(e));
      setRows([]);
    }
  };

  const getModalTitle = () => {
    return `카매니저 만족도 조사 상세(${carmanNm || "전체"})`;
  };

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <Modal
      title={getModalTitle()}
      visible={visible}
      onCancel={onCancel}
      onOpen={onModalOpen}
      footer={null}
    >
      <div
        style={{
          height: "600px",
        }}
      >
        <TableLayout
          hideExcelAction
          hideFilterAction
          title="고객 설문 참여 결과 리스트"
          totalCount={totalCount}
          methodName={`/admin/surv-aggr-carman/${carmanId}`}
          logInfo={logInfo}
        >
          <ClientRowTable cols={cols} rowData={rows} autoHeight />
        </TableLayout>
      </div>
    </Modal>
  );
};

CarManagerSurveyReportDetailModal.defaultProps = {};
export default CarManagerSurveyReportDetailModal;
