import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../../../utils/table-utils";
import BannerInfo from "../../../../../../models/BannerDetail/BannerInfo";
import { formatDateString } from "../../../../../../utils/date-utils";

export const USE_YN_RENDERER = "useYnRenderer";

export const createBannerCols = (isMainBanner = false): Array<TableColumn> => {
  return [
    // 노출기간
    createTableCol("displayTermText", "노출기간", undefined, {
      width: CW_DATE_RANGE,
      valueGetter: ({ data }) => {
        const bannerInfo = data as BannerInfo;
        return `${formatDateString(bannerInfo.bnnrStDt)}-${formatDateString(
          bannerInfo.bnnrEndDt
        )}`;
      },
    }),
    // 노출순번
    createTableCol("bnnrTitleSeq", "게시순번", undefined, {
      width: CW_MEDIUM,
      hide: !isMainBanner,
    }),
    // 배너명
    createTableCol("bnnrTitle", "배너명", undefined, {
      width: CW_MEDIUM,
      hide: !isMainBanner,
    }),
    // 이미지
    createTableCol("imageUrl", "이미지", undefined, {
      width: CW_MEDIUM,
      cellRenderer: "imageRenderer",
    }),
    // 링크
    createTableCol("bnnrCooperLinkUrl", "링크", FilterType.Text, {
      width: CW_LARGE,
      flex: 1,
    }),
    // 배너 사용 유무
    createTableCol("useYn", "사용유무", FilterType.Text, {
      width: CW_SMALL,
      cellRenderer: USE_YN_RENDERER,
    }),
    {
      ...createActionCol(80),
    },
  ];
};
