import BannerInfo from "./BannerInfo";

class BannerDetail {
  public webListCnt = 0;

  public mobileListCnt = 0;

  public tabletListCnt = 0;

  public webList: Array<BannerInfo> = [];

  public mobileList: Array<BannerInfo> = [];

  public tabletList: Array<BannerInfo> = [];
}

export default BannerDetail;
