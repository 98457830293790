import {
  CW_DATE_RANGE,
  CW_LARGE,
  CW_MEDIUM,
  CW_SMALL,
  CW_SMALL_M,
} from "../../../../utils/table-utils";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import commonStrings from "../../../../constants/strings";
import {
  dateFormatRenderer,
  USER_DETAIL_ACTION,
} from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";

export const STATE_PROCESS_RENDERER = "stateProcessRenderer";

export const createTransferRequestCols = (): Array<TableColumn> => {
  return [
    // 요청일
    createTableCol("reqDtm", commonStrings.COL_REQ_DATE, FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("reqDtm"),
    }),
    // 이관요청자 성명
    createTableCol(
      "carmanNm",
      commonStrings.COL_TRANSFER_REQUEST_CAR_MANAGER_NAME,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    // 이관받는자 성명
    createTableCol(
      "tgtCarmanNm",
      commonStrings.COL_TRANSFER_RECEIVE_CAR_MANAGER_NAME,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    createTableCol("custNm", "이관 회원명", FilterType.Text, {
      width: CW_SMALL,
      cellRenderer: USER_DETAIL_ACTION,
    }),
    // 이관 계약건수
    createTableCol(
      "cntrCnt",
      commonStrings.COL_TRANSFER_CONTRACT_COUNT,
      FilterType.Text,
      {
        width: CW_SMALL_M,
        cellStyle: {
          textAlign: "right",
        },
      }
    ),
    // 이관 사유
    createTableCol(
      "reqNm",
      commonStrings.COL_TRANSFER_REASON,
      FilterType.Text,
      {
        minWidth: CW_LARGE,
        flex: 1,
      }
    ),
    // 반려사유
    createTableCol(
      "rejtNM",
      commonStrings.COL_TRANSFER_REJECT_REASON,
      FilterType.Text,
      {
        minWidth: CW_LARGE,
        flex: 1,
      }
    ),
    // 이관 상태
    createTableCol(
      "tranStsNm",
      commonStrings.COL_TRANSFER_STATE,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    // 처리일
    createTableCol(
      "tranDtm",
      commonStrings.COL_TRANSFER_UPDATED_AT,
      FilterType.Date,
      {
        width: CW_DATE_RANGE,
        cellRenderer: dateFormatRenderer("tranDtm"),
      }
    ),
    {
      headerName: "관리",
      field: "content",
      width: 80,
      cellRenderer: STATE_PROCESS_RENDERER,
      pinned: "right",
    },
  ];
};
