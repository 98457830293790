/* eslint-disable no-plusplus */
import { intersection } from "lodash";
import EstimationCatalogDetail from "../../../../../../models/EstimationSimulator/EstimationCatalogDetail";
import {
  CORP_DEPOSIT_RATE_OPTIONS,
  DEPOSIT_RATE_OPTIONS,
  ENUM_RENT_OPTION_CONTRACT_TERM,
  ENUM_RENT_OPTION_FIX_SERVICE, ENUM_RENT_OPTION_MILEAGE,
  ENUM_VM_DEPOSIT,
  ENUM_VM_DEPOSIT_INSURANCE,
  ENUM_VM_F_DEPOSIT,
} from "../../../../../../constants/enums";
import ProductCondition, {
  ENUM_RENT_APY_CODE_ALL,
  ENUM_RENT_APY_CODE_CORP,
  ENUM_RENT_APY_CODE_PERSONAL,
  ENUM_RENT_APY_CODE_PRIVATE_BIZ,
} from "../../../../../../models/EstimationSimulator/ProductCondition";
import { alertError } from "../../../../../../utils/render-utils";
import CustomerDetail from "../../../../../../models/CustomerDetail";
import { uniqBy } from "lodash";
import OptionLogicInfo, {
  ENUM_OPT_CLR_CD_CLR,
  ENUM_OPT_CLR_CD_OPT,
} from "../../../../../../models/EstimationSimulator/OptionLogicInfo";
import TrimInfo from "../../../../../../models/EstimationSimulator/TrimInfo";
import BusinessInfo from "../../../../../../models/BusinessInfo";

// 계약기간 옵션
export const createContractTermOptions = (
  catalogDetail?: EstimationCatalogDetail
) => {
  try {
    const commonOption = catalogDetail?.commonOption;
    const isTkvRtn = catalogDetail?.tkvRtnYn === "Y";

    if (commonOption) {
      const filtered = commonOption.prodCondList.filter((prodCond) => {
        const { modeCd } = prodCond;
        return modeCd === ENUM_RENT_OPTION_CONTRACT_TERM;
      })[0] as ProductCondition;

      if (filtered) {
        /**
         * #계약기간 목록 설정
         * - 반납형인 경우, 12,18개월만 노출
         * TODO: sort - condNm to priority, 현재 데이터가 12/1, 18/2, 24/1로 되어 있음
         */
        return filtered.prodCondList.filter(v => isTkvRtn ? (Number(v.cossCondCd) < 24) : true).sort((a, b) => {return Number(a.condNm) - Number(b.condNm)}).map((cond) => {
          return {
            label: `${cond.condNm}개월`,
            value: cond.cossCondCd,
          };
        });
      }
    }
    return [];
  } catch (e) {
    alertError(e.toString());
    return [];
  }
};

// 보험연령 옵션
export const createInsrAgeOptions = (
  catalogDetail?: EstimationCatalogDetail,
  index?: any
) => {
  try {
    let ubrOptions;
    if (catalogDetail) {
      ubrOptions = catalogDetail?.trimList[index]?.trimOption?.ubrOptionsList;
    }
    if (ubrOptions) {

      return uniqBy(ubrOptions.map(({ drvingAgeCd, drvingAgeNm }) => {
        return {
          label: drvingAgeNm,
          value: drvingAgeCd,
        };
      }), "value");
    }
    return [];
  } catch (e) {
    alertError(e.toString());
    return [];
  }
};

// 렌트 옵션
export const createRentOptions = (
  filterKey: string,
  catalogDetail?: EstimationCatalogDetail,
  businessInfo?: BusinessInfo
) => {
  try {
    const commonOption = catalogDetail?.commonOption;

    if (commonOption) {
      const filtered = commonOption.prodCondList.filter((prodCond) => {
        const { modeCd } = prodCond;

        return modeCd === filterKey;
      })[0] as ProductCondition;

      if (filtered) {
        let condList = filtered.prodCondList;

        // ["정비서비스"] 중복 cossCondCd 제거
        if (filterKey === ENUM_RENT_OPTION_FIX_SERVICE) {
          condList = uniqBy(filtered.prodCondList, "cossCondCd");
        }

        return condList
          .filter(({ apyCustCd }) => {
            // ["정비서비스", "약정주행거리"]는 필터하지 않음
            if (filterKey === ENUM_RENT_OPTION_FIX_SERVICE ||  filterKey === ENUM_RENT_OPTION_MILEAGE) {
              return true;
            }

            if (businessInfo) {
              // 법인
              if (businessInfo.isCorp) {
                // 적용코드 법인 / 전체 필터
                return (
                  apyCustCd === ENUM_RENT_APY_CODE_ALL ||
                  apyCustCd === ENUM_RENT_APY_CODE_CORP
                );
              }
              // 개인사업자
              else {
                // 적용코드 개인 / 전체 필터
                return (
                  apyCustCd === ENUM_RENT_APY_CODE_ALL ||
                  apyCustCd === ENUM_RENT_APY_CODE_PRIVATE_BIZ
                );
              }
            }

            // 적용코드 개인 / 전체 필터
            return (
              apyCustCd === ENUM_RENT_APY_CODE_ALL ||
              apyCustCd === ENUM_RENT_APY_CODE_PERSONAL
            );
          })
          .map((cond) => {
            return {
              label: cond.condNm,
              value: cond.cossCondCd,
            };
          });
      }
    }
    return [];
  } catch (e) {
    alertError(e.toString());
    return [];
  }
};

// 렌트 옵션 기본값
export const getDefaultRentValue = (
  filterKey: string,
  catalogDetail?: EstimationCatalogDetail,
  businessInfo?: BusinessInfo
) => {
  try {
    const commonOption = catalogDetail?.commonOption;

    if (commonOption) {
      const filtered = commonOption.prodCondList.filter((prodCond) => {
        const { modeCd } = prodCond;
        return modeCd === filterKey;
      })[0] as ProductCondition;

      if (filtered) {
        const applyCustTypeFiltered = filtered.prodCondList.filter(
          ({ apyCustCd }) => {
            if (businessInfo) {
              // 법인
              if (businessInfo.isCorp) {
                // 적용코드 법인 / 전체 필터
                return (
                  apyCustCd === ENUM_RENT_APY_CODE_ALL ||
                  apyCustCd === ENUM_RENT_APY_CODE_CORP
                );
              }
              // 개인사업자
              else {
                // 적용코드 개인 / 전체 필터
                return (
                  apyCustCd === ENUM_RENT_APY_CODE_ALL ||
                  apyCustCd === ENUM_RENT_APY_CODE_PRIVATE_BIZ
                );
              }
            }

            // 적용코드 개인 / 전체 필터
            return (
              apyCustCd === ENUM_RENT_APY_CODE_ALL ||
              apyCustCd === ENUM_RENT_APY_CODE_PERSONAL
            );
          }
        );
        const { defCd } = filtered;
        const defaultOption = applyCustTypeFiltered.filter((cond) => {
          return defCd === cond.modeCondCd;
        })[0];

        if (defaultOption) {
          return defaultOption.cossCondCd;
        }
        if (applyCustTypeFiltered[0]) {
          return applyCustTypeFiltered[0].cossCondCd;
        }
      }
    }
    return null;
  } catch (e) {
    alertError(e.toString());
    return null;
  }
};

// 신용등급 옵션
export const createCreditRatingOptions = () => {
  try {
    const options = [];
    for (let i = 1; i < 8; i++) {
      options.push({
        label: `${i}등급`,
        value: i,
      });
    }
    return options;
  } catch (e) {
    alertError(e.toString());
    return [];
  }
};

// 초기 납부 방법
export const createDepositWayOptions = (
  customerDetail?: CustomerDetail,
  businessInfo?: BusinessInfo,
  isTkvRtn?: boolean
) => {
  // 보증보험 스킵 여부
  let shouldSkipIns = false;

  // 사업자
  if (businessInfo) {
    // 법인
    if (businessInfo.isCorp) {
      if (businessInfo && Number(businessInfo.pldgRt) === 0)
        shouldSkipIns = true;
    }

    // 개인사업자
    else {
      if (customerDetail && Number(customerDetail.pldgRt) === 0)
        shouldSkipIns = true;
    }
  }
  // 개인
  else {
    if (customerDetail) {
      if (customerDetail.empYn === "Y") shouldSkipIns = true;
      else if (Number(customerDetail.pldgRt) === 0) shouldSkipIns = true;
    }
  }

  const options = [
    {
      label: "보증금",
      value: ENUM_VM_DEPOSIT,
    },
  ];

  if (!isTkvRtn) {
    options.push({
      label: "선수금",
      value: ENUM_VM_F_DEPOSIT,
    });
  }

  if (!shouldSkipIns) {
    options.push({
      label: "보증보험",
      value: ENUM_VM_DEPOSIT_INSURANCE,
    });
  }
  return options;
};

// 담보율
export const createDepositOptions = (
  customerDetail?: CustomerDetail,
  businessInfo?: BusinessInfo
) => {
  try {
    // 사업자
    if (businessInfo) {
      // 법인
      if (businessInfo.isCorp) {
        return CORP_DEPOSIT_RATE_OPTIONS.filter(({ value }) => {
          return value >= Number(businessInfo.pldgRt);
        });
      }
      // 개인사업자
      else {
        if (customerDetail) {
          return DEPOSIT_RATE_OPTIONS.filter(({ value }) => {
            // 개인의 담보율을 따라가나 임직원 조건은 제외
            return value >= Number(customerDetail.pldgRt);
          });
        }
      }
    }
    // 개인
    else {
      return DEPOSIT_RATE_OPTIONS.filter(({ value }) => {
        if (customerDetail) {
          // 임직원
          if (customerDetail.empYn !== "Y") {
            return value >= Number(customerDetail.pldgRt);
          }
        }
        return true;
      });
    }
  } catch (e) {
    alertError(e.toString());
    return [];
  }
};

// 선수금
export const createFDepositOptions = (customerDetail?: CustomerDetail) => {
  try {
    let startIndex = 0;
    if (customerDetail) {
      if (customerDetail.empYn === "Y") {
        startIndex = 0;
      } else {
        startIndex = Number(customerDetail.pldgRt) / 10;
      }
    }

    const options = [];
    for (let i = startIndex; i <= 4; i++) {
      options.push({
        label: `${(i + 1) * 10}%`,
        value: (i + 1) * 10,
      });
    }
    return options;
  } catch (e) {
    alertError(e.toString());
    return [];
  }
};

interface OptionError {
  selectedOption: string;
  errorOption: string;
  reasonCode: "dup" | "essential" | "color";
}

export const validateOptions = (
  optionLoginInfos: OptionLogicInfo[],
  extraOptionIds: string[],
  innerColorId: string,
  exColorId: string,
  trimInfo?: TrimInfo
): OptionError[] | undefined => {
  // 트림정보 없을경우 리턴
  if (!trimInfo) return;

  // 로직정보가 없을경우 리턴
  if (optionLoginInfos.length === 0) return;

  // 옵션명 맵 생성
  const trimOptionIds = trimInfo.trimOption.carOptionsList.map(
    ({ optId }) => optId
  );
  const innerColorIds = trimInfo.trimOption.interiorColorOptionsList.map(
    ({ cossColorId }) => cossColorId
  );
  const exColorIds = trimInfo.trimOption.exteriorColorOptionsList.map(
    ({ cossColorId }) => cossColorId
  );
  const optionNameDict: Record<string, string> = {};
  trimInfo.trimOption.carOptionsList.forEach(({ optId, optNm }) => {
    optionNameDict[optId] = optNm;
  });

  const colorNameDict: Record<string, string> = {};
  trimInfo.trimOption.interiorColorOptionsList.forEach(
    ({ cossColorId, cossColorNm }) => {
      colorNameDict[cossColorId] = cossColorNm;
    }
  );
  trimInfo.trimOption.exteriorColorOptionsList.forEach(
    ({ cossColorId, cossColorNm }) => {
      colorNameDict[cossColorId] = cossColorNm;
    }
  );

  const errors: any[] = [];

  optionLoginInfos.forEach(
    ({ optClrId, optClrCd, nodupId, essenId, possibleClr }) => {
      // 옵션
      if (optClrCd === ENUM_OPT_CLR_CD_OPT) {
        // 검증이 필요한 옵션 아이디가 있는지 확인
        const filtered = extraOptionIds.filter(
          (selectedOptionId) => selectedOptionId === optClrId
        );
        if (filtered.length > 0) {
          const checkOptionId = filtered[0];

          // 선택한 옵션내 중복불가 아이디가 포함되었는지 확인
          if (nodupId) {
            // 트림에 없는 옵션 아이디 필터
            const noDupIds = intersection(nodupId.split("^"), trimOptionIds);
            if (noDupIds.length > 0) {
              noDupIds.forEach((noDupId) => {
                extraOptionIds.forEach((selectedOptionId) => {
                  // 중복 불가 옵션 확인
                  if (selectedOptionId === noDupId) {
                    console.log(
                      `선택한 옵션 ${optionNameDict[checkOptionId]}는 ${optionNameDict[noDupId]}와 중복선택 불가합니다.`
                    );
                    errors.push({
                      selectedOption: optionNameDict[checkOptionId],
                      errorOption: optionNameDict[noDupId],
                      reasonCode: "dup",
                    });
                  }
                });
              });
            }
          }

          // 필수 옵션 선택 누락 확인
          if (essenId) {
            const essenIds = intersection(essenId.split("^"), trimOptionIds);
            if (essenIds.length > 0) {
              essenIds.forEach((essenId) => {
                const filtered = extraOptionIds.filter((optionId) => {
                  return essenId === optionId;
                });
                if (filtered.length === 0) {
                  errors.push({
                    selectedOption: optionNameDict[checkOptionId],
                    errorOption: optionNameDict[essenId],
                    reasonCode: "essential",
                  });
                }
              });
            }
          }

          // 선택 가능 색상 확인
          if (possibleClr) {
            let colorIds = possibleClr.split("^");
            if (colorIds.length > 0) {
              // 옵션로직정보내 선택가능한 색상을 선택하지않음
              if (
                colorIds.indexOf(exColorId) === -1 ||
                colorIds.indexOf(innerColorId) === -1
              ) {
                errors.push({
                  selectedOption: optionNameDict[checkOptionId],
                  errorOption: colorIds
                    .map((id) => {
                      return colorNameDict[id];
                    })
                    .join(","),
                  reasonCode: "color",
                });
              }
            }
          }
        }
      }

      // 색상
      if (optClrCd === ENUM_OPT_CLR_CD_CLR) {
        let selectedColorName = "";
        let targetColorId = "";
        if (exColorId === optClrId) {
          selectedColorName = colorNameDict[exColorId];
          targetColorId = innerColorId;
        }

        if (innerColorId === optClrId) {
          selectedColorName = colorNameDict[innerColorId];
          targetColorId = exColorId;
        }
        console.log(selectedColorName, targetColorId);
        // 선택 가능 색상 확인
        if (possibleClr && targetColorId) {
          // 타켓이 선택가능한 색상내 포함되어야함
          let colorIds = intersection(possibleClr.split("^"));
          if (colorIds.indexOf(targetColorId) === -1) {
            errors.push({
              selectedOption: selectedColorName,
              errorOption: colorIds
                .map((id) => {
                  return colorNameDict[id];
                })
                .join(","),
              reasonCode: "color",
            });
          }
        }
      }
    }
  );
  if (errors.length > 0) return errors;
};
